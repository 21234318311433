<div>
  @let _haveErrors = haveErrors(error);
  <settings-header
    title="Account settings"
    description="Here are your personnal account settings."
    (save)="save()"
    [isLoading]="loading"
    [isDisabled]="hasAccountNotChanged() || isInvalid(currentAccount)"
    [error]="_haveErrors"
  ></settings-header>

  <section nz-row [nzGutter]="[32, 16]" *ngIf="currentAccount">
    <div nz-col nzSpan="24">
      <div class="account-preview">
        <div
          class="account-avatar"
          [ngStyle]="{
            'background-image': getProfilePicture(currentAccount),
          }"
          (click)="avatarPicker.upload()"
        >
          <div *ngIf="!loadingAvatar" class="upload-overlay">
            <span nz-icon nzType="upload" nzTheme="outline"></span>
          </div>
          <div *ngIf="loadingAvatar" class="upload-overlay visible">
            <span nz-icon nzType="loading" nzTheme="outline"></span>
          </div>
        </div>
        <div class="account-details">
          <div class="account-details-name">{{ currentAccount.fullname }}</div>
          <div class="account-details-title">
            {{ currentAccount.flags.job_title }}
          </div>
        </div>
      </div>
    </div>

    <file-image-picker
      style="display: none"
      #avatarPicker
      [canDelete]="false"
      [maxSize]="maxAvatarSize"
      [loading]="loadingAvatar"
      [resize]="{ minWidth: 512, minHeight: 512 }"
      [imageUrl]="currentAccount.profile_picture"
      (imageFileChange)="imageAvatarFileChange($event)"
    ></file-image-picker>

    <div nz-col nzXs="24" nzXl="20">
      <section nz-row [nzGutter]="[32, 16]">
        <div nz-col nzXs="24" nzXl="12">
          <div class="label">First name</div>
          <input
            type="text"
            nzSize="large"
            nz-input
            [minLength]="1"
            [maxLength]="56"
            placeholder="Frida"
            [(ngModel)]="currentAccount.firstname"
            [ngClass]="{ invalid: !isValidFirstname(currentAccount) }"
          />
        </div>
        <div nz-col nzXs="24" nzXl="12">
          <div class="label">Last name</div>
          <input
            type="text"
            nzSize="large"
            nz-input
            [minLength]="1"
            [maxLength]="56"
            placeholder="Khalo"
            [(ngModel)]="currentAccount.lastname"
            [ngClass]="{ invalid: !isValidLastname(currentAccount) }"
          />
        </div>
        <div nz-col nzXs="24" nzXl="12">
          <div class="label">Job title</div>
          <nz-select
            class="select-role"
            nzSize="large"
            nzPlaceHolder="Product Manager, UX.."
            [(ngModel)]="currentAccount.flags.job_title"
          >
            <nz-option
              *ngFor="let jobTitle of accountJobTitles"
              [nzValue]="jobTitle"
              [nzLabel]="accountJobTitlesToLabel[jobTitle]"
            ></nz-option>
          </nz-select>
        </div>
        <div nz-col nzXs="24" nzXl="12">
          <div class="label">Email</div>
          <input
            type="email"
            nzSize="large"
            nz-input
            [minLength]="1"
            [maxLength]="56"
            placeholder="frida@khalo.com"
            [(ngModel)]="currentAccount.email"
            [ngClass]="{ invalid: !isValidEmail(currentAccount) }"
          />
        </div>
      </section>
    </div>

    <div nz-col nzXs="24" nzXl="20">
      <account-password
        [error]="error['password']"
        [loading]="loadingPassword"
        (passwordChange)="savePassword($event)"
      ></account-password>
    </div>
    <div nz-col nzXs="24" nzXl="20">
      <ng-container *ngIf="'response_translation' | hasEntitlement">
        <div class="separator"></div>
        <section nz-row [nzGutter]="[32, 16]">
          <div nz-col nzXs="24" nzXl="12">
            <language-translation-option
              [translationLanguage]="accountTranslationLanguage"
              [translationEnabled]="translationEnabled"
              [availableTranslationLanguages]="availableTranslationLanguages"
              (translationLanguageChange)="translationLanguageChange($event)"
              (translationEnabledChange)="translationEnabledChange($event)"
            ></language-translation-option>
          </div>
          <div nz-col nzXs="24" nzXl="12"></div>
          <div nz-col nzXs="24" nzXl="12">
            <button
              (click)="saveTranslation()"
              [nzLoading]="loadingTranslation"
              nz-button
              nzType="primary"
              nzSize="large"
              [disabled]="loadingTranslation || hasTranslationChanged"
            >
              Save preferences
            </button>
            <div class="save-error">{{ error["translation"] }}</div>
          </div>
        </section>
      </ng-container>
    </div>
    <div nz-col nzXs="24" nzXl="20">
      <div class="separator"></div>
      <section
        nz-row
        [nzGutter]="[32, 16]"
        *ngIf="'isMFAEnabled' | hasFeatureFlag"
      >
        <div nz-col nzXs="24" nzXl="12">
          <div class="label">Linked Accounts</div>
          <div class="description">
            Here are all your login methods linked to this Screeb account:
          </div>
          <div *ngIf="accountProvidersError" class="error">
            {{ accountProvidersError }}
          </div>
          <ng-container *ngIf="canLinkAccount">
            <div class="separator"></div>
            <account-provider
              *ngFor="let provider of accountProviderList"
              [provider]="accountProviderStatus[provider]"
              [isConnected]="accountProviderStatus[provider].linked"
              (accountLinkChange)="onConnectChange(provider, $event)"
              [error]="accountProvidersError !== null"
              [readOnly]="!canLinkAccount"
            ></account-provider>
          </ng-container>
        </div>
      </section>
      <section nz-row [nzGutter]="[32, 16]">
        <div nz-col nzXs="24" nzXl="12">
          <div class="title-container">
            <div class="label">
              Two Factor Authenticators
              <span
                class="help"
                nz-popover
                [nzPopoverContent]="TwoFADescriptionTemplate"
                nzPopoverPlacement="right"
              >
                <i nz-icon nzType="question-circle" nzTheme="twotone"></i>
              </span>
            </div>
            <switch
              [ngModel]="accountMFAEnabled || mfaEnforced"
              [disabled]="mfaEnforced"
              (ngModelChange)="onMFAEnabledChange($event)"
            ></switch>
          </div>
          <ng-container *ngIf="accountMFAEnabled || mfaEnforced">
            <div *ngIf="accountMFAsError" class="error">
              {{ accountMFAsError }}
            </div>
            <div class="separator"></div>
            <account-mfa
              [isEnabled]="mfaEnabled"
              [enforced]="mfaEnforced"
              [error]="accountMFAsError !== null"
              (accountMFAChange)="onMFAChange($event)"
            ></account-mfa>
          </ng-container>
        </div>
      </section>
    </div>
  </section>

  <ng-template #TwoFADescriptionTemplate>
    <div style="max-width: 300px">
      <p>
        2-factor authentication (2FA) is a security method based on identity and
        access management that requires two forms of identification to access
        resources and data.
      </p>
    </div>
  </ng-template>
</div>
