import { Injectable } from "@angular/core";
import { TagEditorParameters } from "components/builder/components/BuilderLayout/BuilderLayout.component";
import { NotificationHelper } from "helpers/notification.helper";
import { Survey } from "models/survey.model";
import {
  TagEditorCommunicationEvent,
  TagEditorCommunicationMessage,
} from "models/tag-editor-communication.types";
import { BehaviorSubject, Subject } from "rxjs";
import { BuilderStore } from "stores/builder.store";

const AWAIT_TOKEN_TIMEOUT = 5000;

@Injectable()
class TagEditorService {
  private token: string;
  private currentWindow: Window;
  private currentTitle: string;

  public surveyUpdated = new BehaviorSubject<void>(null);
  public tokenRequestTimeout = new Subject<boolean>();
  public selectorResult = new Subject<string>();

  public awaitTokenRequest: ReturnType<typeof setTimeout>;

  public get isOpen() {
    return this.currentWindow && !this.currentWindow.closed;
  }

  constructor(
    private notificationHelper: NotificationHelper,
    private builderStore: BuilderStore,
  ) {
    window.addEventListener("message", this.onWindowMessage);
  }

  public open(
    type: "editor" | "preview" | "picker",
    token: string,
    url: string,
    parameters: TagEditorParameters = {},
  ) {
    if (!url) {
      this.notificationHelper.trigger("Unable to open preview", null, "error");
      return;
    }

    const title = new URL(`screeb:${type}/tab`);

    Object.entries(parameters).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        title.searchParams.append(key, value.toString());
      }
    });

    this.token = token;

    if (this.currentWindow) {
      this.currentWindow.close();
    }

    this.currentWindow = window.open(url, title.toString());
    this.currentTitle = title.toString();

    if (type === "editor") {
      this.awaitTokenRequest = setTimeout(
        () => this.tokenRequestTimeout.next(false),
        AWAIT_TOKEN_TIMEOUT,
      );
    }
  }

  close() {
    this.currentWindow?.close();
    this.token = undefined;
  }

  private sendMessage(
    target: MessageEventSource,
    event: TagEditorCommunicationEvent,
  ) {
    target.postMessage(
      {
        screeb: {
          to: "core",
          from: "admin",
          event,
        },
      },
      { targetOrigin: "*" },
    );
  }

  private handleWindowMessageAdminQueryToken(
    event: TagEditorCommunicationMessage<TagEditorCommunicationEvent>,
  ) {
    if (this.token) {
      this.sendMessage(event.source, {
        type: "admin.query.token.result",
        token: this.token,
      });
    }
  }

  // handlers

  private onWindowMessage = (
    event: TagEditorCommunicationMessage<TagEditorCommunicationEvent>,
  ) => {
    if (event.origin && event.data.screeb) {
      switch (event.data.screeb.event.type) {
        case "admin.query.token":
          this.handleWindowMessageAdminQueryToken(event);
          break;

        case "admin.query.survey.get":
          this.handleWindowMessageAdminQuerySurveyGet(event);
          this.tokenRequestTimeout.next(true);
          break;

        case "admin.query.survey.update":
          this.handleWindowMessageAdminQuerySurveyUpdate(event);
          break;
        case "admin.query.picker.result":
          this.handleWindowMessageAdminQueryPickerResult(event);
          break;
      }
    }
  };

  private handleWindowMessageAdminQuerySurveyGet(
    event: TagEditorCommunicationMessage<TagEditorCommunicationEvent>,
  ) {
    if (this.builderStore.survey) {
      this.sendMessage(event.source, {
        type: "admin.query.survey.get.result",
        survey: {
          ...this.builderStore.survey,
          settings: this.builderStore.getSurveySettings(),
        } as Survey,
        availableVariables: this.builderStore.availableVariables,
        availableLanguages: this.builderStore.availableLanguages.map(
          ({ value }) => value,
        ),
      });
    }

    clearTimeout(this.awaitTokenRequest);
  }

  private handleWindowMessageAdminQuerySurveyUpdate(
    event: TagEditorCommunicationMessage<TagEditorCommunicationEvent>,
  ) {
    if (event.data.screeb.event.type === "admin.query.survey.update") {
      this.builderStore.setSurveyScenario(
        event.data.screeb.event.survey.scenario,
      );
      this.surveyUpdated.next();
    }
    this.sendMessage(event.source, {
      type: "admin.query.survey.update.result",
    });
  }

  private handleWindowMessageAdminQueryPickerResult(
    event: TagEditorCommunicationMessage<TagEditorCommunicationEvent>,
  ) {
    if (event.data.screeb.event.type === "admin.query.picker.result") {
      this.selectorResult.next(event.data.screeb.event.selector);
    }
  }
}

export { TagEditorService };
