import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { HttpErrorResponse } from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { PageComponentInterface } from "components/PageComponentInterface";
import { autoTips } from "helpers/form-errors.helper";
import { AccountDao } from "models/account.dao";
import { Account, AccountJobTitlesToLabel } from "models/account.model";
import { OrgDao } from "models/org.dao";
import { Org } from "models/org.model";
import { TagSettingsDao } from "models/tag-settings.dao";
import { UploadDao } from "models/upload.dao";
import {
  NzSelectOptionInterface,
  NzSelectComponent,
  NzOptionComponent,
} from "ng-zorro-antd/select";
import { ScreebApiHelper, SessionService } from "services/auth.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { SettingsService } from "services/settings.service";
import { TrackersService } from "services/trackers.service";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { NgIf, NgFor } from "@angular/common";
import {
  NzFormDirective,
  NzFormItemComponent,
  NzFormLabelComponent,
  NzFormControlComponent,
} from "ng-zorro-antd/form";
import { NzInputGroupComponent, NzInputDirective } from "ng-zorro-antd/input";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { ScreebIconComponent } from "../../utils/screeb-icon/screeb-icon.component";
import { FormErrorComponent } from "../../../utils/form-error/form-error.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { OnboardingDesktopPreviewComponent } from "../onboarding-preview/onboarding-desktop-preview/onboarding-desktop-preview";
import { OnboardingMobilePreviewComponent } from "../onboarding-preview/onboarding-mobile-preview/onboarding-mobile-preview";

@Component({
  selector: "page-onboarding-qualification",
  templateUrl: "./onboarding-qualification.component.html",
  styleUrls: [
    "./onboarding-qualification.component.scss",
    "../layout/onboarding-layout.component.scss",
  ],
  imports: [
    NzRowDirective,
    NzColDirective,
    NgIf,
    FormsModule,
    NzFormDirective,
    ReactiveFormsModule,
    NzFormItemComponent,
    NzFormLabelComponent,
    NzFormControlComponent,
    NzInputGroupComponent,
    ɵNzTransitionPatchDirective,
    NzInputDirective,
    ScreebIconComponent,
    NzSelectComponent,
    NgFor,
    NzOptionComponent,
    FormErrorComponent,
    NzButtonComponent,
    NzWaveDirective,
    OnboardingDesktopPreviewComponent,
    OnboardingMobilePreviewComponent,
  ],
})
export class OnBoardingQualificationPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Welcome to Screeb!";
  public name = "Onboarding qualification";

  private obs: any = null;
  public org: Org = null;

  public validateForm: FormGroup = null;
  public autoTips = autoTips;

  public loading: boolean = false;
  public errors: any = null;

  public isInvited: boolean = false;

  public accountJobTitles = Object.keys(AccountJobTitlesToLabel);
  public accountJobTitlesToLabel = AccountJobTitlesToLabel;
  public localAccount: Account = null;
  public profilePictureFileName = null;
  public defaultSurveyAvatar = null;
  public askForFirstAndLastName = false;

  public industries: NzSelectOptionInterface[] = [
    { label: "Automotive", value: "automotive" },
    { label: "SaaS B2B", value: "saas-b2b" },
    { label: "SaaS B2C", value: "saas-b2c" },
    { label: "Appliances", value: "appliances" },
    { label: "Finance", value: "finance" },
    { label: "Retail", value: "retail" },
    { label: "Ecommerce", value: "ecommerce" },
    { label: "Insurance", value: "insurance" },
    { label: "Hospitality", value: "hospitality" },
    { label: "Airlines", value: "airlines" },
    { label: "Parcels delivery", value: "parcels-delivery" },
    { label: "Real Estate", value: "real-estate" },
    { label: "Other", value: "other" },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    public sessionService: SessionService,
    public settingsService: SettingsService,
    private formBuilder: FormBuilder,
    public featureFlaggingService: FeatureFlaggingService,
    public screebApiHelper: ScreebApiHelper,
    public trackersService: TrackersService,
    private uploadDao: UploadDao,
    private accountDao: AccountDao,
    private orgDao: OrgDao,
    private tagSettingsDao: TagSettingsDao,
    private permissionsService: PermissionsService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.localAccount = new Account().fromJson(this.sessionService.session);

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
    });

    this.isInvited = this.sessionService.isOnboardingInvited();

    const nameAttrValidators = {};
    if (
      this.localAccount.firstname === "" ||
      this.localAccount.lastname === ""
    ) {
      this.localAccount.firstname = "";
      this.localAccount.lastname = "";

      nameAttrValidators["firstname"] = [
        this.localAccount.firstname,
        [Validators.required],
      ];

      nameAttrValidators["lastname"] = [
        this.localAccount.lastname,
        [Validators.required],
      ];

      this.askForFirstAndLastName = true;
    }

    this.validateForm = this.formBuilder.group({
      ...nameAttrValidators,
      job_title: [this.localAccount.flags.job_title, [Validators.required]],
      profile_picture: [this.localAccount.profile_picture, []],
      phone_number: [this.localAccount.phone_number, []],
      industry: [
        this.org?.industry,
        this.isInvited ? [] : [Validators.required],
      ],
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public onFileSubmit(file: File) {
    this.uploadDao.uploadAccount("profile_picture", file).then((data) => {
      this.localAccount.profile_picture = data.public_url;
      this.profilePictureFileName = file.name;
    });

    if (this.isInvited || !this.org) return;

    this.uploadDao.uploadOrg(this.org.id, "avatar", file).then((data) => {
      this.defaultSurveyAvatar = data.public_url;
    });
  }

  public getProfileOrCompanyPictureUrl() {
    if (this.localAccount.profile_picture)
      return this.localAccount.profile_picture;

    if (!!this.org && this.org.logo) return this.org.logo;

    return "/assets/logo.png";
  }

  private async patchMe(me: {
    phone_number?: string;
    profile_picture?: string;
  }) {
    me.phone_number =
      !!me.phone_number && me.phone_number.length > 0 ? me.phone_number : null;
    return this.accountDao.update(me);
  }

  private async patchOrg({ industry }: { industry: string }) {
    if (this.isInvited || !this.org) {
      return this.org;
    }

    if (!this.permissionsService.isSuperAllowed("org.update")) {
      // i don't know in which case this would happen, because an non-invited user
      // should always have the permission to update this permission
      return this.org;
    }

    this.orgDao
      .updateOrg(this.org.id, null, null, industry)
      .then((org: Org) => {
        this.org.industry = org.industry;
        return org;
      });

    if (this.defaultSurveyAvatar) {
      this.tagSettingsDao.updateOrgTagSettings(this.org.id, {
        ...this.org.settings,
        cards_identity_fixed_avatar: this.defaultSurveyAvatar,
        conversational_identity_fixed_avatar: this.defaultSurveyAvatar,
      });
    }

    return this.org;
  }

  private async patchFlags(jobTitle: string) {
    return this.accountDao.setFlags({ job_title: jobTitle });
  }

  onSubmit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (!this.validateForm.valid) return;

    const data = this.validateForm.value;
    data["profile_picture"] = this.localAccount.profile_picture;

    this.loading = true;
    this.errors = null;

    const nextStep = "goal";

    Promise.all([
      this.patchMe(data),
      this.patchFlags(data.job_title),
      this.patchOrg({ industry: data.industry }),
    ])
      .then(() => {
        const trackEvent = this.trackersService
          .newEventTrackingBuilder("Screeb Onboarding Qualification Done")
          .withAccount(this.sessionService.session);

        if (this.org) {
          trackEvent.withOrg(this.org);
        }

        trackEvent.build();

        this.trackersService.setVisitorProperties({
          job_title: data.job_title,
          job_title_label: AccountJobTitlesToLabel[data.job_title],
        });

        this.router.navigate(["onboarding", nextStep]);
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.errors = err.error;
        this.loading = false;
      });
  }
}
