import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  NzSelectOptionInterface,
  NzSelectComponent,
} from "ng-zorro-antd/select";

import { ONE_MEGA_OCTET } from "components/utils/file-image-picker/file-image-picker.component";
import { Org } from "models/org.model";
import {
  DistributionInteraction,
  SurveyDistribution,
} from "models/survey-distribution.model";
import { SurveyType } from "models/survey.model";
import { resetDefaultsValuesIfNecessary } from "models/tag-settings.api";
import {
  TagSettings,
  TagSettingsBackgroundType,
  TagSettingsFormat,
} from "models/tag-settings.types";
import { UploadDao } from "models/upload.dao";
import { ConfigService } from "services/config.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { UIService } from "services/ui.service";
import { invertColor } from "utils/color";
import { NgIf, NgClass } from "@angular/common";
import { NzSwitchComponent } from "ng-zorro-antd/switch";
import { FormsModule } from "@angular/forms";
import { NzRadioGroupComponent } from "ng-zorro-antd/radio";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { BigRadio } from "../../../../../utils/big-radio/big-radio.component";
import { RouterLink } from "@angular/router";
import { NzTabSetComponent, NzTabComponent } from "ng-zorro-antd/tabs";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { ScreebIconComponent } from "../../../../../utils/screeb-icon/screeb-icon.component";
import { ColorPickerComponent } from "../../../../../utils/color-picker/color-picker.component";
import { FontSelect } from "../../../../../utils/font-select/font-select.component";
import { NzInputNumberComponent } from "ng-zorro-antd/input-number";
import { FileImagePickerComponent } from "../../../../../utils/file-image-picker/file-image-picker.component";
import { NzInputDirective } from "ng-zorro-antd/input";
import { UpgradeModal } from "../../../../../super-org/billing/banners/upgrade-modal.component";
import { EntitlementPipe } from "pipes/entitlement.pipe";

const isValidColor = (color: string) => {
  return Boolean(color) && Boolean(color.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/));
};

const isValidColorWithAlpha = (color: string) => {
  return Boolean(color) && Boolean(color.match(/^#(?:[0-9a-fA-F]{8})$/));
};

const isValidColorWithOptionalAlpha = (color: string) => {
  return isValidColor(color) || isValidColorWithAlpha(color);
};

const isIntegerBetween = (value: unknown, min: number, max: number) => {
  return (
    Number.isInteger(value) && Number(value) >= min && Number(value) <= max
  );
};

const isStringWithLengthBetween = (
  value: unknown,
  min: number,
  max: number,
) => {
  return (
    Boolean(value) &&
    typeof value === "string" &&
    value.length >= min &&
    value.length <= max
  );
};

const isValidName = (value: string) => isStringWithLengthBetween(value, 1, 56);
const isValidTitle = (value: string) =>
  !value || isStringWithLengthBetween(value, 3, 128);

const isValidPrimaryColor = (value: string) => isValidColor(value);
const isValidPrimaryTextColor = (value: string) => isValidColor(value);
const isValidSecondaryColor = (value: string) => isValidColor(value);
const isValidSecondaryTextColor = (value: string) => isValidColor(value);
const isValidTertiaryColor = (value: string) => isValidColor(value);
const isValidTertiaryTextColor = (value: string) => isValidColor(value);

const isValidBackgroundColor = (value: string) => isValidColor(value);

const isValidFontSize = (value: number) => isIntegerBetween(value, 1, 40);
const isValidFontLineHeight = (value: number) => isIntegerBetween(value, 1, 40);
const isValidFontWeight = (value: number) => isIntegerBetween(value, 100, 900);
const isValidBorderRadius = (value: number) => isIntegerBetween(value, 0, 100);
const isValidBorderLineWidth = (value: number) =>
  isIntegerBetween(value, 0, 20);

const isValidBorderShadowColor = (value: string) =>
  isValidColorWithAlpha(value);
const isValidOverlayColor = (value: string) => isValidColorWithAlpha(value);
const isValidBorderLineColor = (value: string) => isValidColorWithAlpha(value);

export const isConversationalIdentityValid = (tagSettings: TagSettings) => {
  switch (tagSettings.conversational_identity_type) {
    case "fixed":
    case "screeby":
      return (
        isValidName(tagSettings.conversational_identity_type) &&
        isValidTitle(tagSettings.conversational_identity_fixed_title)
      );
  }

  return true;
};

export const isCardIdentityValid = (tagSettings: TagSettings) => {
  switch (tagSettings.cards_identity_type) {
    case "fixed":
    case "screeby":
      return (
        isValidName(tagSettings.cards_identity_type) &&
        isValidTitle(tagSettings.cards_identity_fixed_title)
      );
  }

  return true;
};

export const isFeedbackButtonIdentityValid = (tagSettings: TagSettings) => {
  switch (tagSettings.feedback_button_identity_type) {
    case "fixed":
      return (
        isValidName(tagSettings.feedback_button_identity_type) &&
        isValidTitle(tagSettings.feedback_button_identity_fixed_title)
      );
  }

  return true;
};

export const isMessagesIdentityValid = (tagSettings: TagSettings) => {
  switch (tagSettings.messages_identity_type) {
    case "fixed":
      return (
        isValidName(tagSettings.messages_identity_type) &&
        isValidTitle(tagSettings.messages_identity_fixed_title)
      );
  }

  return true;
};

export const isCardValid = (tagSettings: TagSettings) =>
  tagSettings &&
  isCardIdentityValid(tagSettings) &&
  isValidPrimaryColor(tagSettings.cards_color_primary) &&
  isValidPrimaryTextColor(tagSettings.cards_color_primary_text) &&
  isValidSecondaryColor(tagSettings.cards_color_secondary) &&
  isValidSecondaryTextColor(tagSettings.cards_color_secondary_text) &&
  isValidFontSize(tagSettings.cards_font_size) &&
  isValidFontLineHeight(tagSettings.cards_font_line_height) &&
  isValidBorderRadius(tagSettings.cards_border_radius) &&
  isValidBorderLineWidth(tagSettings.cards_border_line_width) &&
  isValidBorderShadowColor(tagSettings.cards_border_shadow_color) &&
  isValidOverlayColor(tagSettings.cards_overlay_color) &&
  isValidBorderLineColor(tagSettings.cards_border_line_color);

export const isFeedbackButtonValid = (tagSettings: TagSettings) =>
  tagSettings &&
  isFeedbackButtonIdentityValid(tagSettings) &&
  isValidPrimaryColor(tagSettings.feedback_button_color_primary) &&
  isValidPrimaryTextColor(tagSettings.feedback_button_color_primary_text) &&
  isValidSecondaryColor(tagSettings.feedback_button_color_secondary) &&
  isValidSecondaryTextColor(tagSettings.feedback_button_color_secondary_text) &&
  isValidFontSize(tagSettings.feedback_button_font_size) &&
  isValidFontLineHeight(tagSettings.feedback_button_font_line_height) &&
  isValidBorderRadius(tagSettings.feedback_button_border_radius) &&
  isValidBorderLineWidth(tagSettings.feedback_button_border_line_width) &&
  isValidBorderShadowColor(tagSettings.feedback_button_border_shadow_color) &&
  isValidOverlayColor(tagSettings.feedback_button_overlay_color) &&
  isValidBorderLineColor(tagSettings.feedback_button_border_line_color);

export const isInPageValid = (tagSettings: TagSettings) =>
  tagSettings &&
  isValidColorWithOptionalAlpha(tagSettings.in_page_color_primary) &&
  isValidPrimaryTextColor(tagSettings.in_page_color_primary_text) &&
  isValidSecondaryTextColor(tagSettings.in_page_color_secondary_text) &&
  isValidBorderRadius(tagSettings.in_page_border_radius) &&
  isValidBorderLineWidth(tagSettings.in_page_border_line_width) &&
  isValidBorderLineColor(tagSettings.in_page_border_line_color) &&
  isValidBorderShadowColor(tagSettings.in_page_border_shadow_color) &&
  isValidFontSize(tagSettings.in_page_font_size) &&
  isValidFontLineHeight(tagSettings.in_page_font_line_height);

export const isLinkValid = (tagSettings: TagSettings) =>
  tagSettings &&
  isValidColorWithOptionalAlpha(tagSettings.link_color_primary) &&
  isValidPrimaryTextColor(tagSettings.link_color_primary_text) &&
  isValidSecondaryTextColor(tagSettings.link_color_secondary_text) &&
  isValidBorderRadius(tagSettings.link_border_radius) &&
  isValidBorderLineWidth(tagSettings.link_border_line_width) &&
  isValidBorderLineColor(tagSettings.link_border_line_color) &&
  isValidBorderShadowColor(tagSettings.link_border_shadow_color) &&
  isValidFontSize(tagSettings.link_font_size) &&
  isValidFontLineHeight(tagSettings.link_font_line_height);

export const isEmailsValid = (tagSettings: TagSettings) =>
  tagSettings &&
  isValidPrimaryColor(tagSettings.emails_color_primary) &&
  isValidPrimaryTextColor(tagSettings.emails_color_primary_text) &&
  isValidSecondaryTextColor(tagSettings.emails_color_secondary_text) &&
  isValidBorderRadius(tagSettings.emails_border_radius) &&
  isValidBorderLineWidth(tagSettings.emails_border_line_width) &&
  isValidBorderLineColor(tagSettings.emails_border_line_color) &&
  isValidBorderShadowColor(tagSettings.emails_border_shadow_color) &&
  isValidFontSize(tagSettings.emails_font_size) &&
  isValidFontLineHeight(tagSettings.emails_font_line_height);

export const isConversationalValid = (tagSettings: TagSettings) =>
  tagSettings &&
  isConversationalIdentityValid(tagSettings) &&
  isValidPrimaryColor(tagSettings.conversational_color_primary) &&
  isValidPrimaryTextColor(tagSettings.conversational_color_primary_text) &&
  isValidSecondaryColor(tagSettings.conversational_color_secondary) &&
  isValidSecondaryTextColor(tagSettings.conversational_color_secondary_text) &&
  isValidFontSize(tagSettings.conversational_font_size) &&
  isValidFontLineHeight(tagSettings.conversational_font_line_height) &&
  isValidBorderRadius(tagSettings.conversational_border_radius) &&
  isValidBorderLineWidth(tagSettings.conversational_border_line_width) &&
  isValidBorderShadowColor(tagSettings.conversational_border_shadow_color) &&
  isValidOverlayColor(tagSettings.conversational_overlay_color) &&
  isValidBorderLineColor(tagSettings.conversational_border_line_color);

export const isMessagesValid = (tagSettings: TagSettings) =>
  tagSettings &&
  isMessagesIdentityValid(tagSettings) &&
  isValidPrimaryColor(tagSettings.messages_color_primary) &&
  isValidPrimaryTextColor(tagSettings.messages_color_primary_text) &&
  isValidSecondaryColor(tagSettings.messages_color_secondary) &&
  isValidSecondaryTextColor(tagSettings.messages_color_secondary_text) &&
  isValidTertiaryColor(tagSettings.messages_color_tertiary) &&
  isValidTertiaryTextColor(tagSettings.messages_color_tertiary_text) &&
  isValidFontSize(tagSettings.messages_font_size) &&
  isValidFontLineHeight(tagSettings.messages_font_line_height) &&
  isValidFontSize(tagSettings.messages_title_font_size) &&
  isValidFontWeight(tagSettings.messages_title_font_weight) &&
  isValidBorderRadius(tagSettings.messages_border_radius) &&
  isValidBorderLineWidth(tagSettings.messages_border_line_width) &&
  isValidBorderShadowColor(tagSettings.messages_border_shadow_color) &&
  isValidOverlayColor(tagSettings.messages_overlay_color) &&
  isValidBorderLineColor(tagSettings.messages_border_line_color);

export const isValidBackground = (tagSettings: TagSettings) => {
  switch (tagSettings.page_background_type) {
    case "color":
      return isValidColor(tagSettings.page_background_color);
    case "image":
      return Boolean(tagSettings.page_background_image_url);
  }
};

export const isValidEmailsBackground = (tagSettings: TagSettings) => {
  switch (tagSettings.emails_page_background_type) {
    case "color":
      return isValidColor(tagSettings.emails_page_background_color);
    case "image":
      return Boolean(tagSettings.emails_page_background_image_url);
  }
};

export const isInvalid = (
  tagSettings: TagSettings,
  interaction: DistributionInteraction = null,
) => {
  return (
    tagSettings &&
    !(
      isValidBackground(tagSettings) &&
      isCardValid(tagSettings) &&
      isFeedbackButtonValid(tagSettings) &&
      isInPageValid(tagSettings) &&
      isLinkValid(tagSettings) &&
      isEmailsValid(tagSettings) &&
      (interaction !== "email" || isValidEmailsBackground(tagSettings)) &&
      isConversationalValid(tagSettings)
    )
  );
};

@Component({
  selector: "tag-settings-design",
  templateUrl: "./tag-settings-design.component.html",
  styleUrls: ["./tag-settings-design.component.scss"],
  imports: [
    NgIf,
    NzSwitchComponent,
    FormsModule,
    NzRadioGroupComponent,
    NzRowDirective,
    NzColDirective,
    BigRadio,
    RouterLink,
    NzTabSetComponent,
    NzTabComponent,
    NzSelectComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    ScreebIconComponent,
    ColorPickerComponent,
    NgClass,
    FontSelect,
    NzInputNumberComponent,
    FileImagePickerComponent,
    NzInputDirective,
    UpgradeModal,
    EntitlementPipe,
  ],
})
export class TagSettingsDesignComponent implements OnInit, OnChanges {
  @Input() public org: Org = null;
  @Input() public surveyDistribution: SurveyDistribution = null;
  @Input() public tagSettings: TagSettings = null;
  @Input() public tabSelectedIndex = 0;
  @Input() public typeSelected = "survey";
  @Input() public scope: "org" | "survey" | "distribution" = "org";
  @Input() public interaction: DistributionInteraction;

  @Output() public tabSelectedIndexChange = new EventEmitter<number>();
  @Output() public specificDesignSwitchChange = new EventEmitter<boolean>();
  @Output() public tagSettingsFormatChange =
    new EventEmitter<TagSettingsFormat>();

  public tagSettingsDiff: TagSettings = null;

  public isUpgradeModalVisible = false;

  public loadingBackground = false;
  public loadingBackgroundIcon = false;
  public loadingAvatar = false;
  public loadingIcon = false;
  public error: string = null;
  public soundPlaying: boolean = false;
  public canPlayAudio: boolean = !!window.fetch;
  public popSoundsOptions: NzSelectOptionInterface[] = [];

  public maxAvatarSize = ONE_MEGA_OCTET * 5;
  public maxBackgroundSize = ONE_MEGA_OCTET * 3;

  public backgroundTypeOptions: NzSelectOptionInterface[] = [
    { label: "Solid color", value: "color" },
    { label: "Image", value: "image" },
  ];

  public conversationalPlacementOptions: NzSelectOptionInterface[] = [
    { label: "Bottom left", value: "bottom-left" },
    { label: "Bottom center", value: "bottom-center" },
    { label: "Bottom right", value: "bottom-right" },
    { label: "Middle left", value: "center-left" },
    { label: "Middle center", value: "center-center" },
    { label: "Middle right", value: "center-right" },
  ];

  public cardsPlacementOptions: NzSelectOptionInterface[] = [
    { label: "Bottom left", value: "bottom-left" },
    { label: "Bottom center", value: "bottom-center" },
    { label: "Bottom right", value: "bottom-right" },
  ];

  public feedbackButtonPlacementOptions: NzSelectOptionInterface[] = [
    { label: "Middle left", value: "center-left" },
    { label: "Middle right", value: "center-right" },
  ];

  public backgroundIconPositionOptions: NzSelectOptionInterface[] = [
    { label: "Center", value: "center-center" },
    { label: "Left", value: "center-left" },
    { label: "Right", value: "center-right" },
  ];

  public identityTypeOptions: NzSelectOptionInterface[] = [
    { label: "Specific person", value: "fixed" },
    { label: "Screeby", value: "screeby" },
  ];

  public identityTypeOptionalOptions: NzSelectOptionInterface[] = [
    { label: "None", value: "none" },
    { label: "Specific person", value: "fixed" },
    { label: "Screeby", value: "screeby" },
  ];

  public labelIconTypeFeedbackButtonOptions: NzSelectOptionInterface[] = [
    { label: "None", value: "none" },
    { label: "Screeby", value: "screeby" },
    { label: "Custom", value: "custom" },
  ];

  public overlayVisibilityOptions: NzSelectOptionInterface[] = [
    { label: "Always hidden", value: "hidden" },
    { label: "Always visible", value: "always" },
    { label: "On first answer", value: "response" },
  ];

  public messagesOverlayVisibilityOptions: NzSelectOptionInterface[] = [
    { label: "Hidden", value: "hidden" },
    { label: "Visible", value: "always" },
  ];

  public avatarFormatOptions: NzSelectOptionInterface[] = [
    { label: "Round", value: "round" },
    { label: "Square", value: "square" },
    { label: "Rounded square", value: "rounded_square" },
  ];

  public publicPopSoundsOptions: NzSelectOptionInterface[] = [
    { label: "No sound", value: "none" },
    { label: "Pop", value: "pop" },
    { label: "Bell", value: "bell" },
    { label: "Impact", value: "impact" },
  ];

  private featureFlaggedPopSoundsOptions: NzSelectOptionInterface[] = [
    { label: "Oo", value: "oo" },
    { label: "Tchit tchaaa", value: "tchit-tchat" },
  ];

  public getGuardPopSoundsOptions(): NzSelectOptionInterface[] {
    if (
      !this.featureFlaggingService.disableFeatureFlags &&
      this.featureFlaggingService.accountIsScreebTeam()
    ) {
      return [
        ...this.publicPopSoundsOptions,
        ...this.featureFlaggedPopSoundsOptions,
      ];
    }

    return this.publicPopSoundsOptions;
  }

  public sizeOptions: NzSelectOptionInterface[] = [
    70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130,
  ].map((value) => ({
    label: `${value}%`,
    value,
  }));

  isValidName = isValidName;
  isValidTitle = isValidTitle;
  isValidPrimaryColor = isValidPrimaryColor;
  isValidPrimaryTextColor = isValidPrimaryTextColor;
  isValidSecondaryColor = isValidSecondaryColor;
  isValidSecondaryTextColor = isValidSecondaryTextColor;
  isValidTertiaryColor = isValidTertiaryColor;
  isValidTertiaryTextColor = isValidTertiaryTextColor;
  isValidBackgroundColor = isValidBackgroundColor;
  isValidFontSize = isValidFontSize;
  isValidFontLineHeight = isValidFontLineHeight;
  isValidFontWeight = isValidFontWeight;
  isValidBorderRadius = isValidBorderRadius;
  isValidBorderLineWidth = isValidBorderLineWidth;
  isValidBorderShadowColor = isValidBorderShadowColor;
  isValidOverlayColor = isValidOverlayColor;
  isValidBorderLineColor = isValidBorderLineColor;

  isValidColorWithAlpha = isValidColorWithAlpha;

  isInvalid = isInvalid;

  constructor(
    public featureFlaggingService: FeatureFlaggingService,
    public entitlementService: EntitlementService,
    private configService: ConfigService,
    private uploadDao: UploadDao,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    this.tagSettingsDiff = { ...this.tagSettings };

    if (this.tabSelectedIndex !== 2) {
      this.syncTab(this.tagSettings?.format);
    }

    this.popSoundsOptions = this.getGuardPopSoundsOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tagSettings?.currentValue) {
      if (this.tabSelectedIndex !== 2) {
        this.syncTab(changes.tagSettings.currentValue?.format);
      }
    } else if (
      changes.interaction?.currentValue !== changes.interaction?.previousValue
    ) {
      if (this.tabSelectedIndex !== 2) {
        this.syncTab(this.tagSettings?.format);
      }
    }
  }

  syncTab(format: TagSettingsFormat) {
    setTimeout(() => this.changeTab(format === "conversationnal" ? 0 : 1), 0);
  }

  public hasNotChanged() {
    return (
      JSON.stringify(this.tagSettings) === JSON.stringify(this.tagSettingsDiff)
    );
  }

  public onIdentityTypeChange(event, prefix) {
    this.tagSettings[`${prefix}_identity_type`] = event;

    if (event === "none") {
      this.tagSettings[`${prefix}_identity_fixed_avatar`] = null;
      this.tagSettings[`${prefix}_identity_fixed_name`] = null;
      this.tagSettings[`${prefix}_identity_fixed_title`] = null;
    } else {
      // Restore default values when change from none to fixed
      const defaultSettings = resetDefaultsValuesIfNecessary(this.tagSettings);
      const keys = [
        `${prefix}_identity_fixed_avatar`,
        `${prefix}_identity_fixed_name`,
        `${prefix}_identity_fixed_title`,
        `${prefix}_identity_avatar_format`,
      ];

      for (const key of keys) {
        if (
          this.tagSettings[key] === undefined ||
          this.tagSettings[key] === null
        ) {
          this.tagSettings[key] = defaultSettings[key];
        }
      }

      this.tagSettings[`${prefix}_identity_avatar_format`] = "rounded_square";

      if (event === "screeby") {
        this.tagSettings[`${prefix}_identity_fixed_avatar`] = null;
      }
    }
  }

  public async imageAvatarFileChange(
    file: File,
    type: SurveyType,
    format?:
      | TagSettingsFormat
      | "feedback_button"
      | "emails"
      | "link"
      | "in_page",
  ) {
    this.loadingAvatar = true;
    this.error = null;
    this.uploadDao
      .uploadOrg(this.org.id, "avatar", file)
      .then(({ public_url }) => {
        if (type === "survey") {
          switch (format) {
            case "cards":
              if (this.tagSettings.cards_identity_type === "fixed") {
                this.tagSettings.cards_identity_fixed_avatar = public_url;
              }
              break;
            case "feedback_button":
              if (this.tagSettings.feedback_button_identity_type === "fixed") {
                this.tagSettings.feedback_button_identity_fixed_avatar =
                  public_url;
              }
              break;
            case "emails":
              if (this.tagSettings.emails_identity_type === "fixed") {
                this.tagSettings.emails_identity_fixed_avatar = public_url;
              }
              break;
            case "link":
              if (this.tagSettings.link_identity_type === "fixed") {
                this.tagSettings.link_identity_fixed_avatar = public_url;
              }
              break;
            case "in_page":
              if (this.tagSettings.in_page_identity_type === "fixed") {
                this.tagSettings.in_page_identity_fixed_avatar = public_url;
              }
              break;
            case "conversationnal":
              if (this.tagSettings.conversational_identity_type === "fixed") {
                this.tagSettings.conversational_identity_fixed_avatar =
                  public_url;
              }
              break;
          }
        } else if (type === "message") {
          if (this.tagSettings.messages_identity_type === "fixed") {
            this.tagSettings.messages_identity_fixed_avatar = public_url;
          }
        }
      })
      .catch(() => {
        this.error = "Oops! Something went wrong. Please retry.";
      })
      .finally(() => {
        this.loadingAvatar = false;
      });
  }

  public async iconFeedbackButtonCTAFileChange(file: File) {
    this.loadingIcon = true;
    this.error = null;

    if (!file) {
      this.tagSettings.feedback_button_cta_label_icon = "";
      this.loadingIcon = false;
      return;
    }

    this.uploadDao
      .uploadOrg(this.org.id, "avatar", file)
      .then(({ public_url }) => {
        this.tagSettings.feedback_button_cta_label_icon = public_url;
      })
      .catch(() => {
        this.error = "Oops! Something went wrong. Please retry.";
      })
      .finally(() => {
        this.loadingIcon = false;
      });
  }

  public async imageBackgroundFileChange(prefix: "" | "emails_", file: File) {
    this.error = null;

    if (
      !file &&
      this.tagSettings[`${prefix}page_background_type`] === "image"
    ) {
      this.tagSettings[`${prefix}page_background_image_url`] = null;
      return;
    }

    this.loadingBackground = true;
    this.uploadDao
      .uploadOrg(this.org.id, "background", file)
      .then(({ public_url }) => {
        if (this.tagSettings[`${prefix}page_background_type`] === "image") {
          this.tagSettings[`${prefix}page_background_image_url`] = public_url;
        }
      })
      .catch(() => {
        this.error = "Oops! Something went wrong. Please retry.";
      })
      .finally(() => {
        this.loadingBackground = false;
      });
  }

  public async imageBackgroundIconFileChange(file: File) {
    this.error = null;

    if (!file) {
      this.tagSettings.page_background_icon = "";
      return;
    }

    this.loadingBackgroundIcon = true;
    this.uploadDao
      .uploadOrg(this.org.id, "background", file)
      .then(({ public_url }) => {
        this.tagSettings[`page_background_icon`] = public_url;
      })
      .catch(() => {
        this.error = "Oops! Something went wrong. Please retry.";
      })
      .finally(() => {
        this.loadingBackgroundIcon = false;
      });
  }

  public async imageEmailsBackgroundIconFileChange(file: File) {
    this.error = null;

    if (!file) {
      this.tagSettings.emails_background_icon = "";
      return;
    }

    this.loadingBackgroundIcon = true;
    this.uploadDao
      .uploadOrg(this.org.id, "background", file)
      .then(({ public_url }) => {
        this.tagSettings[`emails_background_icon`] = public_url;
      })
      .catch(() => {
        this.error = "Oops! Something went wrong. Please retry.";
      })
      .finally(() => {
        this.loadingBackgroundIcon = false;
      });
  }

  public isWhiteColor(color: string) {
    const formattedColor = color.toUpperCase();

    return (
      formattedColor === "#FFF" ||
      formattedColor === "#FFFFFF" ||
      formattedColor === "var(--screeb-color-white)"
    );
  }

  conversationalPrimaryColorChange(newPrimaryColor: string) {
    this.tagSettings.conversational_color_primary_text =
      invertColor(newPrimaryColor);
  }

  conversationalSecondaryColorChange(newSecondaryColor: string) {
    this.tagSettings.conversational_color_secondary_text =
      invertColor(newSecondaryColor);
  }

  cardsPrimaryColorChange(newPrimaryColor: string) {
    this.tagSettings.cards_color_primary_text = invertColor(newPrimaryColor);

    if (!this.tagSettings.advanced) {
      const style = window.getComputedStyle(document.body);
      this.tagSettings.cards_color_secondary = style.getPropertyValue(
        this.isWhiteColor(newPrimaryColor)
          ? "--screeb-color-background-secondary"
          : "--screeb-color-background",
      );
    }
  }

  feedbackButtonPrimaryColorChange(newPrimaryColor: string) {
    this.tagSettings.feedback_button_color_primary_text =
      invertColor(newPrimaryColor);

    if (!this.tagSettings.advanced) {
      const style = window.getComputedStyle(document.body);
      this.tagSettings.feedback_button_color_secondary = style.getPropertyValue(
        this.isWhiteColor(newPrimaryColor)
          ? "--screeb-color-background-secondary"
          : "--screeb-color-background",
      );
    }
  }

  feedbackButtonCTAPrimaryColorChange(newPrimaryColor: string) {
    this.tagSettings.feedback_button_cta_color_primary_text =
      invertColor(newPrimaryColor);

    if (!this.tagSettings.advanced) {
      const style = window.getComputedStyle(document.body);
      this.tagSettings.feedback_button_cta_color_secondary =
        style.getPropertyValue(
          this.isWhiteColor(newPrimaryColor)
            ? "--screeb-color-background-secondary"
            : "--screeb-color-background",
        );
    }
  }

  emailsPrimaryColorChange(newPrimaryColor: string) {
    this.tagSettings.emails_color_primary_text = invertColor(newPrimaryColor);
  }

  inPagePrimaryColorChange(newPrimaryColor: string) {
    this.tagSettings.in_page_color_primary_text = invertColor(newPrimaryColor);

    if (!this.tagSettings.advanced) {
      const style = window.getComputedStyle(document.body);
      this.tagSettings.in_page_color_secondary = style.getPropertyValue(
        this.isWhiteColor(newPrimaryColor)
          ? "--screeb-color-background-secondary"
          : "--screeb-color-background",
      );
    }
  }

  linkPrimaryColorChange(newPrimaryColor: string) {
    this.tagSettings.link_color_primary_text = invertColor(newPrimaryColor);

    if (!this.tagSettings.advanced) {
      const style = window.getComputedStyle(document.body);
      this.tagSettings.link_color_secondary = style.getPropertyValue(
        this.isWhiteColor(newPrimaryColor)
          ? "--screeb-color-background-secondary"
          : "--screeb-color-background",
      );
    }
  }

  messagesPrimaryColorChange(newPrimaryColor: string) {
    this.tagSettings.messages_color_primary_text = invertColor(newPrimaryColor);

    if (!this.tagSettings.advanced) {
      const style = window.getComputedStyle(document.body);
      this.tagSettings.messages_color_secondary = style.getPropertyValue(
        this.isWhiteColor(newPrimaryColor)
          ? "--screeb-color-background-secondary"
          : "--screeb-color-background",
      );
    }
  }

  cardsSecondaryColorChange(newSecondaryColor: string) {
    this.tagSettings.cards_color_secondary_text =
      invertColor(newSecondaryColor);
  }

  feedbackButtonSecondaryColorChange(newSecondaryColor: string) {
    this.tagSettings.feedback_button_color_secondary_text =
      invertColor(newSecondaryColor);
  }

  emailsSecondaryColorChange(newSecondaryColor: string) {
    this.tagSettings.emails_color_secondary_text =
      invertColor(newSecondaryColor);
  }

  linkSecondaryColorChange(newSecondaryColor: string) {
    this.tagSettings.link_color_secondary_text = invertColor(newSecondaryColor);
  }

  inPageSecondaryColorChange(newSecondaryColor: string) {
    this.tagSettings.in_page_color_secondary_text =
      invertColor(newSecondaryColor);
  }

  messagesSecondaryColorChange(newSecondaryColor: string) {
    this.tagSettings.messages_color_secondary_text =
      invertColor(newSecondaryColor);
  }

  messagesTertiaryColorChange(newTertiaryColor: string) {
    this.tagSettings.messages_color_tertiary_text =
      invertColor(newTertiaryColor);
  }

  formatChange($event: TagSettingsFormat) {
    if ($event === "cards") {
      switch (this.tagSettings.conversational_position) {
        case "center-center":
          this.tagSettings.conversational_position = "bottom-center";
          break;
        case "center-left":
          this.tagSettings.conversational_position = "bottom-left";
          break;
        case "center-right":
          this.tagSettings.conversational_position = "bottom-right";
          break;
      }

      if (this.tabSelectedIndex !== 2) {
        this.changeTab(1);
      }
    } else if (this.tabSelectedIndex !== 2) {
      this.changeTab(0);
    }

    this.tagSettingsFormatChange.emit($event);
  }

  changeTab(newIndex: number) {
    this.tabSelectedIndex = newIndex;
    this.tabSelectedIndexChange.emit(this.tabSelectedIndex);
  }

  playSound(popSound: string) {
    if (!window.fetch || popSound === "none") {
      return;
    }

    this.soundPlaying = true;
    window
      .fetch(
        `${this.configService.config.tagBypassEndpoint}/assets/sounds/${popSound}.mp3`,
      )
      .then((res) => {
        res.blob().then((blob) => {
          const audio = new Audio(URL.createObjectURL(blob));
          audio.addEventListener("ended", () => {
            this.soundPlaying = false;
          });
          audio.play();
        });
      })
      .catch(() => {
        this.soundPlaying = false;
      });
  }

  onPageBackgroundTypeChange(
    prefix: "" | "emails_",
    $event: TagSettingsBackgroundType,
  ) {
    if ($event === "color") {
      this.tagSettings[`${prefix}page_background_color`] = "#F3F4F9";
    }
  }

  onBorderShadowColorChange(color) {
    if (
      (color.length === 6 && color[0] !== "#") ||
      (color.length === 7 && color[0] === "#")
    ) {
      color = color + "40"; // 25% opacity
    }
    this.tagSettings.conversational_border_shadow_color = color;
  }

  advancedCustomisationChanged(checked: boolean) {
    if (
      checked &&
      !this.entitlementService.isAvailable("advanced_survey_customization")
    ) {
      this.isUpgradeModalVisible = true;
      setTimeout(() => {
        this.tagSettings = { ...this.tagSettings, advanced: false };
      }, 100);
      return;
    }

    if (!checked) {
      Object.assign(
        this.tagSettings,
        resetDefaultsValuesIfNecessary(this.tagSettings),
      );

      this.conversationalPrimaryColorChange(
        this.tagSettings.conversational_color_primary,
      );
      this.conversationalSecondaryColorChange(
        this.tagSettings.conversational_color_secondary,
      );
      this.cardsPrimaryColorChange(this.tagSettings.cards_color_primary);
      this.cardsSecondaryColorChange(this.tagSettings.cards_color_secondary);
      this.feedbackButtonPrimaryColorChange(
        this.tagSettings.feedback_button_color_primary,
      );
      this.feedbackButtonSecondaryColorChange(
        this.tagSettings.feedback_button_color_secondary,
      );
      this.feedbackButtonCTAPrimaryColorChange(
        this.tagSettings.feedback_button_cta_color_primary,
      );
      this.inPagePrimaryColorChange(this.tagSettings.in_page_color_primary);
      this.linkPrimaryColorChange(this.tagSettings.link_color_primary);
    }
  }
}
