/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { SessionService } from "services/auth.service";

import { SurveyLanguages } from "models/survey.dao.types";
import { TrackersService } from "services/trackers.service";
import { ConfigService } from "services/config.service";
import { UIService } from "services/ui.service";
import {
  DistributionInteractionsFormatted,
  SurveyDistribution,
} from "models/survey-distribution.model";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { EmailDao } from "models/email.dao";
import { NotificationHelper } from "helpers/notification.helper";
import { NgIf, NgFor } from "@angular/common";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";
import { NzSelectComponent, NzOptionComponent } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { NzRadioGroupComponent, NzRadioComponent } from "ng-zorro-antd/radio";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { EmailPreviewModalComponent } from "../../../surveys/pages/share/email/preview-modal/preview-modal.component";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import { SafePipe } from "pipes/safe.pipe";

@Component({
  selector: "survey-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
  imports: [
    NgIf,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    ScreebIconComponent,
    NzSelectComponent,
    FormsModule,
    NgFor,
    NzOptionComponent,
    NzButtonComponent,
    NzWaveDirective,
    NzRadioGroupComponent,
    NzRadioComponent,
    NzPopoverDirective,
    EmailPreviewModalComponent,
    NzSpinComponent,
    SafePipe,
  ],
})
export class PreviewPanelComponent implements OnInit, OnChanges {
  @Input() surveyId: string = null;
  @Input() initDistributionId: string = null;
  @Input() lang: SurveyLanguages = "en";
  @Input() isFullScreen: boolean = false;

  @Output()
  onClose = new EventEmitter<Event>();

  @Output()
  onSetURL = new EventEmitter<string>();

  @ViewChild("iframePreview")
  iframe: ElementRef;

  public iframePath: string = null;

  public mode: "desktop" | "mobile" = "desktop";
  public forceMobile: boolean = false;
  public distributionId: string = null;

  public emailPreview: string = "in";
  public emailTemplate: string = null;
  public emailActionId: string = null;

  public distributionInteractionsFormatted = DistributionInteractionsFormatted;

  public distributions: SurveyDistribution[] = [];
  public newDistributions: SurveyDistribution[] = [];
  public legacyDistributions: SurveyDistribution[] = [];
  public distributionsByID: { [key: string]: SurveyDistribution } = {};

  constructor(
    public sessionService: SessionService,
    private configService: ConfigService,
    private trackersService: TrackersService,
    public uiService: UIService,
    private featureFlaggingService: FeatureFlaggingService,
    private notificationHelper: NotificationHelper,
    private emailDao: EmailDao,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.newDistributions = this.uiService.currentSurveyDistributions.filter(
      (d) => d.interaction,
    );

    this.legacyDistributions = this.uiService.currentSurveyDistributions.filter(
      (d) => !d.interaction,
    );
    this.distributions = [
      ...this.newDistributions,
      ...this.legacyDistributions,
    ];
    this.distributions.forEach((d) => {
      this.distributionsByID[d.id] = d;
    });

    this.setupDistribution();
    this.setupPath();
  }

  ngOnChanges({ surveyId, initDistributionId }: SimpleChanges) {
    if (surveyId?.currentValue !== surveyId?.previousValue) {
      this.setupPath();
    } else if (
      initDistributionId?.currentValue !== initDistributionId?.previousValue
    ) {
      this.setupDistribution();
      this.setupPath();
    }
  }

  private setupDistribution() {
    // Get first enabled distribution or in_app or widget
    if (!this.initDistributionId || this.initDistributionId === "") {
      this.distributionId = this.distributions.find((d) => {
        return d.enabled;
      })?.id;

      if (!this.distributionId) {
        this.distributionId = this.distributions.find(
          (d) => d.interaction === "in_app",
        )?.id;
      }

      if (!this.distributionId) {
        this.distributionId = this.distributions.find(
          (d) => d.type === "widget",
        )?.id;
      }
    } else {
      this.distributionId = this.initDistributionId;
    }
  }

  public onEmailPreviewChange(emailPreview: string) {
    this.emailPreview = emailPreview;
    this.setupPath();
  }

  private setupPath() {
    const interaction =
      this.distributionsByID?.[this.distributionId]?.interaction;
    if (
      this.distributionsByID[this.distributionId]?.type === "android" ||
      this.distributionsByID[this.distributionId]?.type === "ios"
    ) {
      this.forceMobile = true;
    } else {
      this.forceMobile = false;
    }

    const params = [
      `channel_id=${this.distributionsByID?.[this.distributionId]?.channel_id}`,
      `survey_id=${this.surveyId}`,
      `lang=${this.lang}`,
      // `visitor_id=${this.sessionService.session.id}`,
      // `visitor_firstname=${this.sessionService.session.firstname}`,
      // `visitor_lastname=${this.sessionService.session.lastname}`,
      `visitor_id=screeb-preview`,
      `visitor_firstname=Screeb`,
      `visitor_lastname=Preview`,
      // `visitor_email=${this.sessionService.session.email}`,
      `host=${this.configService.config.tagBypassEndpoint}`,
      `platform=${this.configService.config.platform}`,
      `force_mobile=${this.mode === "mobile" || this.forceMobile ? "true" : "false"}`,
    ];

    params.push(`distribution_id=${this.distributionId}`);
    if (this.distributionsByID?.[this.distributionId]?.type === "hosted-page") {
      params.push(`interaction=hosted-page`);
    } else if (interaction) {
      params.push(`interaction=${interaction}`);
    }

    if (this.emailActionId) {
      params.push(`action_id=${this.emailActionId}`);
    }

    const url = `/preview.html?${params.join("&")}`;
    this.onSetURL?.emit(url);

    // Special case for email "inside"
    if (
      this.distributionsByID[this.distributionId]?.interaction === "email" &&
      this.emailPreview === "in"
    ) {
      this.emailDao
        .getTemplate(
          this.uiService.currentOrgId,
          this.surveyId,
          this.distributionId,
          `${this.configService.config.hostedEndpoint}/2/${this.distributionsByID[this.distributionId].channel_id}/${this.distributionsByID[this.distributionId].id}/${this.distributionsByID[this.distributionId].survey_id}`,
        )
        .then((template) => {
          this.emailTemplate = template.html;
        })
        .catch((error) => {
          this.emailTemplate = null;
          console.error(error);
          this.notificationHelper.trigger(
            "Error",
            "An error occurred while fetching the email template.",
            "error",
          );
        });
      return;
    } else {
      this.emailTemplate = null;
    }

    this.iframePath = url;
    this.changeDetectorRef.detectChanges();
  }

  public onDistributionChange(distributionId: string) {
    this.distributionId = distributionId;
    this.onReload();
  }

  public onModeChange(mode: "desktop" | "mobile") {
    this.mode = mode;
    this.onReload();
  }

  public onEmailAction(url: string) {
    const parsed = new URL(url);
    // get action_id query param
    const actionId = parsed.searchParams.get("action_id");
    if (actionId) {
      this.emailActionId = actionId;
      this.emailTemplate = null;
      this.emailPreview = "out";
    } else if (url.includes("survey.screeb.app")) {
      this.emailActionId = null;
      this.emailTemplate = null;
      this.emailPreview = "out";
    } else {
      this.emailActionId = null;
    }
    this.onReload();
  }

  public onReload() {
    this.setupPath();
    const msg = {
      "screeb-preview": { type: "preview-reload" },
    };
    this.iframe?.nativeElement?.contentWindow.postMessage(
      JSON.parse(JSON.stringify(msg)),
      window.location.href,
    );

    this.trackersService
      .newEventTrackingBuilder("Survey preview reloaded")
      .withProps({
        distribution_id: this.distributionId,
        survey_id: this.surveyId,
        survey_type: "survey",
      })
      .build();
  }
}
