import { Component, Input } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";
import { NgIf, DatePipe } from "@angular/common";
import { ScreebDatePickerComponent } from "../../../../../../../utils/date-picker/screeb-date-picker.component";
import { NzInputDirective } from "ng-zorro-antd/input";
import { EntitlementPipe } from "pipes/entitlement.pipe";

@Component({
  selector: "rule-date",
  templateUrl: "./rule-date.component.html",
  styleUrls: ["./rule-date.component.scss"],
  imports: [
    NgIf,
    ScreebDatePickerComponent,
    NzInputDirective,
    DatePipe,
    EntitlementPipe,
  ],
})
export class TargetingRuleDateComponent {
  @Input()
  public rule: TargetingRule = null;

  constructor() {}
}
