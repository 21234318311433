import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";
import {
  DistributionInteraction,
  DistributionInteractionsFormatted,
  SurveyDistribution,
} from "models/survey-distribution.model";
import { Survey, SurveyIntegrations, SurveyType } from "models/survey.model";

import { TagSettings } from "models/tag-settings.types";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { UIService } from "services/ui.service";
import { format } from "date-fns";
import { NgClass, NgIf } from "@angular/common";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";
import { RouterLink } from "@angular/router";
import { TagSettingsDesignComponent } from "./component/tag-settings-design/tag-settings-design.component";
import { TagSettingsPreviewComponent } from "./component/tag-settings-preview/tag-settings-preview.component";

@Component({
  selector: "tag-settings-with-preview",
  templateUrl: "./tag-settings-with-preview.component.html",
  styleUrls: ["./tag-settings-with-preview.component.scss"],
  imports: [
    NgClass,
    NgIf,
    ScreebIconComponent,
    RouterLink,
    TagSettingsDesignComponent,
    TagSettingsPreviewComponent,
  ],
})
export class TagSettingsWithPreviewPageComponent implements OnInit, OnChanges {
  @Input() public tabSelectedIndex = 0;
  @Input() public org: Org = null;
  @Input() public survey: Survey = null;
  @Input() public surveyDistribution: SurveyDistribution = null;
  @Input() public defaultTagSettings: TagSettings = null;
  @Input() public tagSettings: TagSettings = null;
  @Input() public orgIntegrations: Integration[] = null;
  @Input() public surveyIntegrations: SurveyIntegrations = null;
  @Input() public scope: "org" | "survey" | "distribution" = "org";
  @Input() public surveyType: SurveyType = "survey";
  @Input() public interaction: DistributionInteraction = null;
  @Input() public translationEnabled: boolean = true;

  @Output() public specificDesignSwitchChange = new EventEmitter<boolean>();
  @Output() public specificBehaviourSwitchChange = new EventEmitter<boolean>();
  @Output() public specificTranslationEnabledSwitchChange =
    new EventEmitter<boolean>();

  public mode: "mobile" | "desktop" = "desktop";
  public haveNewDistributions = false;
  public haveSurveyTagSettings = false;
  public distributionInteractionsFormatted = DistributionInteractionsFormatted;

  constructor(
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    if (this.survey) {
      this.surveyType = this.survey.type;
    }

    if (this.surveyDistribution?.interaction) {
      this.interaction = this.surveyDistribution.interaction;
    }

    if (this.surveyDistribution?.type === "hosted-page") {
      this.tabSelectedIndex = 2;
    }

    // Deprecated
    if (this.scope === "survey") {
      this.haveNewDistributions =
        this.uiService.currentSurveyDistributions.some((d) => d.interaction);
      this.haveSurveyTagSettings =
        this.uiService.currentSurvey.settings &&
        Object.keys(this.uiService.currentSurvey.settings).length > 0;
    }
  }

  ngOnChanges({ surveyDistribution }: SimpleChanges): void {
    if (surveyDistribution?.currentValue) {
      this.interaction = surveyDistribution?.currentValue?.interaction;
    }
  }

  shouldForceMobile() {
    return (
      this.scope === "distribution" &&
      (this.surveyDistribution.type === "android" ||
        this.surveyDistribution.type === "ios")
    );
  }

  getFormat() {
    if (this.tagSettings) {
      if (!this.interaction || this.interaction === "in_app") {
        switch (this.tabSelectedIndex) {
          case 0:
            return "conversationnal";
          case 1:
            return "cards";
          case 2:
            return "hosted-page";
        }
      }
      return this.interaction;
    } else {
      if (!this.interaction || this.interaction === "in_app") {
        if (this.surveyDistribution?.type === "hosted-page") {
          return "hosted-page";
        }
        switch (this.defaultTagSettings.format) {
          case "cards":
            return "cards";
          case "conversationnal":
          default:
            return "conversationnal";
        }
      }
    }
    return this.interaction;
  }

  protected readonly format = format;
}
