import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  RegistryEntry,
  RegistryEntrySource,
  RegistryEntrySourceFormatted,
  sortRegistryEntries,
  sortedRegistryEntrySources,
} from "models/registry.model";
import {
  NzSelectComponent,
  NzOptionGroupComponent,
  NzOptionComponent,
} from "ng-zorro-antd/select";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "selector-user-properties",
  templateUrl: "./selector-user-properties.component.html",
  styleUrls: ["./selector-user-properties.component.scss"],
  imports: [
    NzSelectComponent,
    NgClass,
    FormsModule,
    NgFor,
    NzOptionGroupComponent,
    NzOptionComponent,
    NgIf,
  ],
})
export class SelectorUserPropertiesComponent {
  @Input() public registryEntriesIdentityProperty: RegistryEntry[];
  @Input() public registryEntriesIdentityPropertyPaths: Map<string, string[]>;
  @Input() public invalid: boolean = false;
  @Input() public disabled: boolean = false;

  @Input() public propertyKeyId: string;
  @Output() public propertyKeyIdChange = new EventEmitter<string>();

  public sortedRegistryEntrySources = sortedRegistryEntrySources;
  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;

  constructor() {}

  public getPropertyKeysBySource(source: RegistryEntrySource) {
    return (
      this.registryEntriesIdentityProperty
        // .filter((prop) => !prop.hidden)
        .filter((prop) => {
          return prop.type !== "object";
        })
        .filter((prop) => {
          return prop.sources.includes(source);
        })
        .sort(sortRegistryEntries)
    );
  }
}
