import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { Org } from "models/org.model";
import { ScreebApiHelper, SessionService } from "services/auth.service";
import { SettingsService } from "services/settings.service";
import { PageComponentInterface } from "components/PageComponentInterface";
import { RoutingService } from "services/routing.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { Channel } from "models/channel.model";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { BrandCardComponent } from "../../../utils/brand-card/brand-card";
import { IntercomButtonComponent } from "../../utils/intercom-button/intercom-button.component";
import { NgIf } from "@angular/common";
import { OnBoardingInstallationGuideComponent } from "./onboarding-installation-guide/onboarding-installation-guide.component";

@Component({
  selector: "page-onboarding-installation",
  templateUrl: "./onboarding-installation.component.html",
  styleUrls: [
    "./onboarding-installation.component.scss",
    "../layout/onboarding-layout.component.scss",
  ],
  imports: [
    NzRowDirective,
    NzColDirective,
    BrandCardComponent,
    IntercomButtonComponent,
    NgIf,
    OnBoardingInstallationGuideComponent,
  ],
})
export class OnBoardingInstallationPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Welcome to Screeb!";
  public name = "Onboarding installation";

  private obs: any = null;
  public org: Org = null;

  public channels: Channel[] = null;

  public selectedInstall = null;

  public installTitles = {
    segment: "Connect Screeb to Segment",
    rudderstack: "Connect Screeb to Rudderstack",
    gtm: "Connect Screeb to Google Tag Manager",
    screeb: "Install Screeb to your app",
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    public sessionService: SessionService,
    public settingsService: SettingsService,
    public featureFlaggingService: FeatureFlaggingService,
    public screebApiHelper: ScreebApiHelper,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
      this.channels = data["channels"];
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public async setSelectedInstall(install: string) {
    this.selectedInstall = install;
  }
}
