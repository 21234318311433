import { Component } from "@angular/core";
import { Router, RouterLinkActive, RouterLink } from "@angular/router";

import { AuthService, SessionService } from "services/auth.service";
import { NotificationHelper } from "helpers/notification.helper";
import { UIService } from "services/ui.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { TemplateCategories, templateCategorySpecs } from "models/survey.model";
import { NzMenuDirective, NzMenuItemComponent } from "ng-zorro-antd/menu";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NgFor } from "@angular/common";

@Component({
  selector: "layout-back-sidebar-template",
  templateUrl: "./sidebar-template.component.html",
  styleUrls: ["./sidebar-template.component.scss"],
  imports: [
    NzMenuDirective,
    NzMenuItemComponent,
    ɵNzTransitionPatchDirective,
    RouterLinkActive,
    RouterLink,
    NgFor,
  ],
})
export class LayoutBackSidebarTemplateComponent {
  public categories = TemplateCategories;
  public templateCategorySpec = templateCategorySpecs;

  constructor(
    public router: Router,
    public authService: AuthService,
    public sessionService: SessionService,
    public notificationHelper: NotificationHelper,
    public uiService: UIService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {
    if (this.uiService.isMessagePage) {
      this.categories = [];
    }
  }
}
