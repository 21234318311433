import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";

import { HttpErrorResponse } from "@angular/common/http";
import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { WeeklyBriefingDao } from "models/weekly-briefing.dao";
import { WeeklyBriefing } from "models/weekly-briefing.model";
import { SessionService } from "services/auth.service";
import { NzModalComponent } from "ng-zorro-antd/modal";
import {
  NzFormDirective,
  NzFormItemComponent,
  NzFormLabelComponent,
  NzFormControlComponent,
} from "ng-zorro-antd/form";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { NzInputDirective } from "ng-zorro-antd/input";
import { InputSelectComponent } from "../../../utils/input-select/input-select.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";

@Component({
  selector: "add-receiver-modal",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
  imports: [
    NzModalComponent,
    FormsModule,
    NzFormDirective,
    ReactiveFormsModule,
    NzRowDirective,
    NzFormItemComponent,
    NzColDirective,
    NzFormLabelComponent,
    NzFormControlComponent,
    NzInputDirective,
    InputSelectComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
  ],
})
export class AddReceiverModalComponent implements OnInit, OnChanges, OnDestroy {
  private obs: any = null;

  public loading: boolean = false;
  public errors: any = null;

  public validateForm: FormGroup = null;

  @Input() workspace: Org = null;
  @Input() roles: string[] = [];
  @Input() receiver: WeeklyBriefing | null = null;
  @Input() isOpen: boolean = false;
  @Output() modalOpenChange: EventEmitter<void> = new EventEmitter();
  @Output() briefingUpdated: EventEmitter<WeeklyBriefing> = new EventEmitter();

  public searchRole: string = null;
  public newRole: string = null;

  constructor(
    public sessionService: SessionService,
    private weeklyBriefingDao: WeeklyBriefingDao,
    private notificationHelper: NotificationHelper,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges() {
    this.initForm();
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
  }

  private initForm(): void {
    this.newRole = null;
    this.searchRole = null;

    this.validateForm = this.formBuilder.group({
      firstname: [this.receiver?.firstname, [Validators.required]],
      lastname: [this.receiver?.lastname, [Validators.required]],
      email: [this.receiver?.email, [Validators.required, Validators.email]],
      role: [this.receiver?.role, [Validators.required]],
    });
  }

  handleCancel(): void {
    this.isOpen = false;
    this.modalOpenChange.emit();
    this.loading = false;
  }

  private get hasFormChanged(): boolean {
    if (this.receiver === null) return true;

    const value = this.validateForm.value;

    return (
      this.receiver.firstname !== value.firstname ||
      this.receiver.lastname !== value.lastname ||
      this.receiver.email !== value.email ||
      this.receiver.role !== value.role
    );
  }

  public isFormValid(): boolean {
    return this.validateForm.valid && this.hasFormChanged;
  }

  public onSearchRoleChange(value: string): void {
    if (this.searchRole === value) return;

    this.searchRole = value;
  }

  public onNewRoleChange(value: string): void {
    if (this.newRole === value) return;
    if (this.roles.indexOf(value) !== -1) {
      this.searchRole = null;
      this.newRole = null;

      return;
    }

    this.newRole = value;

    this.validateForm.get("role").setValue(value);
  }

  public onSubmit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (!this.validateForm.valid) return;

    this.loading = true;
    this.errors = null;

    const value = this.validateForm.value;

    if (this.receiver !== null) {
      this.weeklyBriefingDao
        .update(
          this.workspace.id,
          this.receiver.id,
          value.firstname,
          value.lastname,
          value.email,
          value.role,
        )
        .then((briefing) => {
          this.notificationHelper.trigger("Receiver updated", null, "success");
          this.errors = null;
          this.isOpen = false;

          this.briefingUpdated.emit(briefing);
          this.modalOpenChange.emit();
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationHelper.trigger(
            err?.error?.message ?? "Failed to update user",
            null,
            "error",
          );
          this.errors = err.error;
          console.error(err.error);
        })
        .then(() => {
          this.loading = false;
        });
    } else {
      this.weeklyBriefingDao
        .create(
          this.workspace.id,
          value.firstname,
          value.lastname,
          value.email,
          value.role,
        )
        .then((briefing) => {
          this.notificationHelper.trigger("Receiver Added", null, "success");
          this.errors = null;
          this.isOpen = false;
          this.briefingUpdated.emit(briefing);
          this.modalOpenChange.emit();
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationHelper.trigger(
            err?.error?.message ?? "Failed to add receiver",
            null,
            "error",
          );
          this.errors = err.error;
          console.error(err.error);
        })
        .then(() => {
          this.loading = false;
        });
    }
  }
}
