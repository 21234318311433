import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { UIService } from "services/ui.service";
import { NzCardComponent } from "ng-zorro-antd/card";
import { NgIf } from "@angular/common";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzProgressComponent } from "ng-zorro-antd/progress";
import { ScreebFormatPipe } from "pipes/format.pipe";

@Component({
  selector: "widget-mau-usage",
  templateUrl: "./mau-usage.component.html",
  styleUrls: ["./mau-usage.component.scss"],
  imports: [
    NzCardComponent,
    NgIf,
    ScreebIconComponent,
    NzPopoverDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NzProgressComponent,
    ScreebFormatPipe,
  ],
})
export class WidgetMAUUsageComponent implements AfterViewInit, OnDestroy {
  private rs: ResizeObserver | null = null;

  constructor(public uiService: UIService) {}

  ngAfterViewInit(): void {
    this.resizeText({
      element: document.querySelector(".mau-usage-body"),
    });

    // listen for parent container resize
    this.rs = new ResizeObserver(() => {
      this.resizeText({
        element: document.querySelector(".mau-usage-body"),
      });
    });
    this.rs.observe(document.querySelector(".mau-usage-container"));
  }

  ngOnDestroy(): void {
    this.rs?.disconnect();
  }

  isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight;

  resizeText = ({
    element,
    elements = null,
    minSize = 12,
    maxSize = 21,
    step = 1,
    unit = "px",
  }) => {
    (elements || [element]).forEach((el) => {
      let i = minSize;
      let overflow = false;

      const parent = el.parentNode;

      while (!overflow && i < maxSize) {
        el.style.fontSize = `${i}${unit}`;
        overflow = this.isOverflown(parent);

        if (!overflow) i += step;
      }

      // revert to last state where no overflow happened
      el.style.fontSize = `${i - step - step}${unit}`;
    });
  };
}
