import { Component, Input, OnInit } from "@angular/core";

import { NgClass } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {
  endOfDay,
  startOfDay,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { Org } from "models/org.model";
import { Survey } from "models/survey.model";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { TrackersService } from "services/trackers.service";
import { filterObject, mapObject } from "utils/object";

@Component({
  selector: "survey-stats-filter-date",
  templateUrl: "./filter-date.component.html",
  styleUrls: ["./filter-date.component.scss"],
  imports: [
    NgClass,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NzDatePickerModule,
    FormsModule,
  ],
})
export class DateFilterStatsSurveyComponent implements OnInit {
  @Input()
  public org: Org = null;
  @Input()
  public survey: Survey = null;
  @Input()
  public reporting: string = null;

  @Input()
  public dropdownVisible = false;
  @Input()
  public theme: "dark" | "light" = "light";

  public selectedDates: Date[];

  public ranges: Record<string, Date[]>;

  public skipDatesUpdated = false;

  constructor(
    private trackersService: TrackersService,
    public analyticsFilterService: AnalyticsFilterService,
  ) {}

  ngOnInit() {
    this.selectedDates = [
      this.analyticsFilterService.get().range.start ?? new Date(),
      this.analyticsFilterService.get().range.end ?? new Date(),
    ];

    this.setRanges();
  }

  private setRanges() {
    const now = new Date();

    const capMinDate = (date: Date): Date =>
      date.getTime() > this.analyticsFilterService.minDate.getTime()
        ? date
        : this.analyticsFilterService.minDate;
    const capMinDates = (dates: Date[]): Date[] => dates.map(capMinDate);

    const ranges = {
      "This week": [startOfWeek(now), now].map(capMinDate),
      "Last week": [subDays(now, 6), now].map(capMinDate), // we don't substract 7 days, because we return to users the result from 00:00 to 23:59 (= 6 days, 23 hours and 59 minutes)
      "Last month": [subMonths(now, 1), now].map(capMinDate),
      "This year so far": [startOfYear(now), now].map(capMinDate),
      "Previous year": [startOfYear(subMonths(now, 12)), startOfYear(now)].map(
        capMinDate,
      ),
      "All time": [this.analyticsFilterService.minDate, now].map(capMinDate),
    };

    this.ranges = filterObject(
      mapObject(ranges, capMinDates),
      ([_, date]) =>
        date.getTime() > this.analyticsFilterService.minDate.getTime(),
    );
  }

  public onChange([eventStartDate, eventEndDate]: Date[]): void {
    this.skipDatesUpdated = true;

    // dates are in user Timezone, we need to convert them to UTC while keeping the same time
    const start = zonedTimeToUtc(eventStartDate, "UTC");
    const end = zonedTimeToUtc(eventEndDate, "UTC");

    this.setDates(start, end);
  }

  public setDates(eventStartDate: Date, eventEndDate: Date) {
    if (!eventStartDate || !eventEndDate) {
      return;
    }

    const startDate = utcToZonedTime(eventStartDate, "UTC");

    // Add One second so that startOfDay() doesn't bring the date back to the previous day
    startDate.setSeconds(startDate.getSeconds() + 1);

    const endDate = utcToZonedTime(eventEndDate, "UTC");

    this.analyticsFilterService.setDateRange(
      startOfDay(startDate),
      endOfDay(endDate),
    );

    this.selectedDates = [eventStartDate, eventEndDate];

    const strRange = `${startDate.getUTCDate()} - ${endDate.getUTCDate()}`;

    this.trackersService
      .newEventTrackingBuilder("Reporting date range changed")
      .withOrg(this.org)
      .withSurvey(this.survey)
      .withProps({
        range_from: startDate.toDateString(),
        range_to: endDate.toDateString(),
        reporting_name: this.reporting,
        range_selected: strRange,
      })
      .build();
  }

  public isDisabledDate = (current: Date): boolean => {
    const now = new Date();
    return current < this.analyticsFilterService.minDate || current > now;
  };
}
