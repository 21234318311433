import { Component, OnDestroy, OnInit } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { PageComponentInterface } from "components/PageComponentInterface";
import { NotificationHelper } from "helpers/notification.helper";
import { OrgDao } from "models/org.dao";
import { Org } from "models/org.model";
import { SurveyLanguages, TranslationLanguage } from "models/survey.dao.types";
import { SessionService } from "services/auth.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import { LanguageWithEmoji, UIService } from "services/ui.service";
import { SettingsHeaderComponent } from "../header/header.component";
import { ScreebIconComponent } from "../../utils/screeb-icon/screeb-icon.component";
import { LanguageSelect } from "../../utils/language-select/language-select.component";
import { FormsModule } from "@angular/forms";
import { NgIf, NgFor } from "@angular/common";
import { SettingsLanguageTranslationOptionComponent } from "./translation-option/language-translation-option.component";
import { SanitizedMessageComponent } from "../../builder/components/Cards/sanitized-message/sanitized-message.component";
import { PluralSingularPipe } from "pipes/pluralSingular.pipe";
import { EntitlementPipe } from "pipes/entitlement.pipe";

@Component({
  selector: "page-settings-language",
  templateUrl: "./language.component.html",
  styleUrls: ["./language.component.scss"],
  imports: [
    SettingsHeaderComponent,
    ScreebIconComponent,
    LanguageSelect,
    FormsModule,
    NgIf,
    SettingsLanguageTranslationOptionComponent,
    NgFor,
    SanitizedMessageComponent,
    PluralSingularPipe,
    EntitlementPipe,
  ],
})
export class SettingsLanguagePageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Language Preferences";
  public name = "Settings language preferences";

  private obs: any = null;

  public org: Org = null;

  public languages: LanguageWithEmoji[] = [];
  public survey_languages: SurveyLanguages[] = [];

  public translationLanguage: TranslationLanguage = null;
  public availableTranslationLanguages: LanguageWithEmoji[] = [];
  public computedTranslations: LanguageWithEmoji[] = [];

  public translationEnabled = false;
  public translationValid = true;

  public loading = false;
  public needsSave = false;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public sessionService: SessionService,
    private notificationHelper: NotificationHelper,
    private orgDao: OrgDao,
    public entitlementService: EntitlementService,
    public featureFlaggingService: FeatureFlaggingService,
    private uiService: UIService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;

      this.survey_languages = this.org.survey_languages;
      this.translationEnabled = this.org.translation_enabled;
      this.translationLanguage = this.translationEnabled
        ? this.org.translation_language
        : null;

      this.languages =
        this.uiService.languagesAndCountries.surveyLanguagesWithEmojis;
      this.availableTranslationLanguages =
        this.uiService.languagesAndCountries.translationLanguagesWithEmojis;

      this.refreshComputedTranslations();
      this.doNeedSave();
    });
  }

  private hasSurveyLanguageChanged() {
    return (
      this.survey_languages.length !== this.org.survey_languages.length ||
      this.survey_languages.some(
        (lang) => !this.org.survey_languages.includes(lang),
      )
    );
  }

  private hasTranslationLanguageChanged() {
    return (
      this.translationLanguage !==
        (this.org.translation_enabled ? this.org.translation_language : null) ||
      this.translationEnabled !== this.org.translation_enabled
    );
  }

  public doNeedSave() {
    this.needsSave =
      this.hasSurveyLanguageChanged() || this.hasTranslationLanguageChanged();
  }

  save() {
    if (this.languages?.length < 1) {
      return;
    }

    if (this.translationEnabled && this.translationLanguage === null) {
      this.notificationHelper.trigger(
        "Please select a Translation Language.",
        null,
        "error",
      );
      return;
    }

    this.loading = true;

    this.orgDao
      .updateAvailableLanguages(
        this.org.id,
        this.survey_languages,
        this.translationEnabled,
        this.translationLanguage,
      )
      .then((org) => {
        this.org.survey_languages = this.survey_languages;
        this.org.translation_language = this.translationLanguage;
        this.org.translation_enabled = this.translationEnabled;
        this.org.computed_translations = org.computed_translations;
        this.refreshComputedTranslations();

        this.notificationHelper.trigger(
          "Organization languages preferences updated!",
          null,
          "success",
        );
        this.doNeedSave();
      })
      .catch(() => {
        this.notificationHelper.trigger(
          "Failed to update organization languages preferences, please retry.",
          null,
          "error",
        );
      })
      .finally(() => (this.loading = false));
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  private refreshComputedTranslations() {
    this.computedTranslations = this.org.computed_translations.map((lang) =>
      this.availableTranslationLanguages.find((l) => l.value === lang),
    );
  }

  isInvalidSurveyLanguage() {
    return (
      this.survey_languages.length > 20 || this.survey_languages.length < 1
    );
  }

  isInvalid() {
    return this.isInvalidSurveyLanguage() || !this.translationValid;
  }

  modelChange($event: SurveyLanguages[]) {
    if (Array.isArray($event)) {
      this.survey_languages = $event;
    }

    this.doNeedSave();
  }

  translationEnabledChange($event: boolean) {
    this.translationEnabled = $event;

    this.doNeedSave();
  }

  translationLanguageChange($event: SurveyLanguages) {
    this.translationLanguage = $event;

    this.doNeedSave();
  }
}
