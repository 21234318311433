import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ChartData, ChartDataset, ChartOptions } from "chart.js";
import { BaseChartDirective } from "ng2-charts";
import { deepCopy } from "utils/object";
import { NgIf } from "@angular/common";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import { NzCheckboxGroupComponent } from "ng-zorro-antd/checkbox";
import { FormsModule } from "@angular/forms";

export type RadarIndicatorLegend = {
  label: string;
  value: string;
  checked: boolean;
  disabled: boolean;
  color: string;
  hoverColor?: string;
  format?: "percent" | "number";
}[];

@Component({
  selector: "survey-stats-indicators-radar-indicator",
  templateUrl: "./radar-indicator.component.html",
  styleUrls: ["./radar-indicator.component.scss"],
  imports: [
    NgIf,
    NzSpinComponent,
    BaseChartDirective,
    NzCheckboxGroupComponent,
    FormsModule,
  ],
})
export class RadarIndicatorStatsSurveyComponent implements OnInit, OnChanges {
  @ViewChild("trendChart")
  private trendChart: BaseChartDirective = null;

  @Input() public loading = false;
  @Input() public legend: RadarIndicatorLegend = [];
  @Input() public labels: string[] = [];
  @Input() public datasets: ChartDataset[] = [];

  public data: ChartData = {
    labels: [],
    datasets: [],
  };

  public displayLegend: boolean = true;
  public reversedLegend: RadarIndicatorLegend = [];
  public options: ChartOptions = {
    responsive: true,
    resizeDelay: 100,
    maintainAspectRatio: true,
    aspectRatio: 1.8,
    layout: {
      padding: {
        left: 28,
        right: 28,
      },
    },
    scales: {
      r: {
        animate: true,
        ticks: {
          stepSize: 1,
          display: false,
        },
        beginAtZero: true,
        min: 0,
        max: 5,
        grid: {
          borderRadius: 6,
        },
        angleLines: {
          display: false,
        },
        pointLabels: {
          font: {
            size: 14,
            weight: "bold",
          },
          color: "#303140",
        },
      },
    },
    elements: {
      line: {
        borderWidth: 0,
        tension: 0.35,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
  };

  ngOnInit() {
    this.data.labels = this.labels;
    this.data.datasets = this.datasets;
    this.reversedLegend = [...this.legend].reverse();
    this.displayLegend = this.legend.filter((a) => !a.disabled).length > 1;
  }

  ngOnChanges({ labels, datasets, legend }: SimpleChanges) {
    if (labels?.currentValue) {
      this.data.labels = labels.currentValue;
    }

    if (datasets?.currentValue) {
      this.data.datasets = datasets.currentValue;
    }

    if (legend?.currentValue) {
      this.reversedLegend = deepCopy(legend.currentValue).reverse();
    }

    this.displayLegend = this.legend.filter((a) => !a.disabled).length > 1;

    setTimeout(() => {
      this.trendChart?.chart?.update();
      [...this.reversedLegend]
        .reverse()
        .forEach(({ checked }, index) =>
          this.trendChart.hideDataset(index, !checked),
        );
    }, 1);
  }

  public onTrendLegendChange(
    legends: Array<{ label: string; value: string; checked?: boolean }>,
  ) {
    if (!this.trendChart?.hideDataset) {
      throw Error("cannot access chart visiblity options");
    }

    // refresh visibility for each dataset
    legends.forEach(
      (legend: { label: string; value: string; checked?: boolean }) => {
        // find the right dataset matching the current legend
        const index = this.data.datasets.findIndex(
          (dataset: ChartDataset): boolean => {
            return dataset.label === legend.value;
          },
        );

        if (index !== -1) {
          this.trendChart.hideDataset(index, !legend.checked);
        }
      },
    );
  }
}
