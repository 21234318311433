import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { NgIf } from "@angular/common";
import { PageComponentInterface } from "components/PageComponentInterface";
import { AccountDao } from "models/account.dao";
import { AccountJobTitlesToLabel } from "models/account.model";
import { Org } from "models/org.model";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { NzColDirective, NzRowDirective } from "ng-zorro-antd/grid";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { SessionService } from "services/auth.service";
import { EntitlementService } from "services/entitlement.service";
import { RoutingService } from "services/routing.service";
import { TrackersService } from "services/trackers.service";
import { pluralize } from "utils/string";
import { IntercomButtonComponent } from "../../utils/intercom-button/intercom-button.component";
import { ScreebIconComponent } from "../../utils/screeb-icon/screeb-icon.component";

@Component({
  selector: "page-onboarding-setup",
  templateUrl: "./onboarding-setup.component.html",
  styleUrls: [
    "./onboarding-setup.component.scss",
    "../layout/onboarding-layout.component.scss",
  ],
  imports: [
    NzRowDirective,
    NzColDirective,
    NgIf,
    ScreebIconComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    IntercomButtonComponent,
  ],
})
export class OnBoardingSetupPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Welcome to Screeb!";
  public name = "Onboarding installation";

  private obs: any = null;
  public org: Org = null;

  public isTagInstalled = false;
  public isInvited = false;

  public accountRoleToLabel = AccountJobTitlesToLabel;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    public sessionService: SessionService,
    private trackersService: TrackersService,
    private analyticsFilterService: AnalyticsFilterService,
    private entitlementservice: EntitlementService,
    private accountDao: AccountDao,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
    });

    this.isInvited = this.sessionService.isOnboardingInvited();
    this.refreshTagInstalled();
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public isFreePlan() {
    return this.entitlementservice.isFreePlan();
  }

  public toPlural(value: string) {
    if (value === AccountJobTitlesToLabel.other) {
      return "People";
    }
    return pluralize(value, "s");
  }

  onSkip() {
    this.trackersService
      .newEventTrackingBuilder(
        this.isTagInstalled
          ? "Screeb Onboarding Installation Done"
          : "Screeb Onboarding Installation Skipped",
      )
      .withOrg(this.org)
      .withAccount(this.sessionService.session)
      .build();

    if (this.isInvited) {
      this.accountDao.updateOnboardingStatus("product-tour").then(() => {
        this.router.navigate(["org", this.org.id]).then(() => {
          // Reload page to update billing status
          window.location.reload();
        });
      });
    } else {
      this.router.navigate(["onboarding", "book-demo"]);
    }
  }

  private async refreshTagInstalled() {
    if (!this.org) {
      return;
    }
    this.isTagInstalled = await this.analyticsFilterService.workspaceHasUsers(
      this.org.id,
    );
  }

  onInstall() {
    this.router.navigate(["onboarding", "installation"]);
  }
}
