/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import {
  planTypeInfos,
  recommendedPlanTypeUpgrade,
} from "components/super-org/billing/components/billing-plan/billing-plan.data";
import {
  couponToString,
  planIdToPlanType,
} from "components/super-org/billing/products.data";
import { format } from "date-fns";
import {
  OrgBilling,
  OrgBillingInvoice,
  OrgBillingPaymentMethods,
  OrgBillingSubscription,
  PlanType,
  scalePlanDefaultWorkspaces,
} from "models/org_billing.model";
import { SuperOrg } from "models/super-org.model";
import { EntitlementService } from "services/entitlement.service";
import { SettingsService } from "services/settings.service";
import { formatNumber } from "utils/number";
import { NgIf, NgFor } from "@angular/common";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { ScreebIconComponent } from "../../../../utils/screeb-icon/screeb-icon.component";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { PricePipe } from "pipes/price.pipe";
import { UppercaseFirstPipe } from "pipes/uppercase-first.pipe";

@Component({
  selector: "billing-plan",
  templateUrl: "billing-plan.component.html",
  styleUrls: ["billing-plan.component.scss"],
  imports: [
    NgIf,
    NzTagComponent,
    NgFor,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    ScreebIconComponent,
    NzIconDirective,
    NzTooltipDirective,
    PricePipe,
    UppercaseFirstPipe,
  ],
})
export class SettingsBillingPlanComponent implements OnChanges {
  @Input() superOrg: SuperOrg;
  @Input() orgBilling: OrgBilling;
  @Input() orgBillingSubscription: OrgBillingSubscription = null;
  @Input() orgBillingPaymentMethods: OrgBillingPaymentMethods = null;
  @Input() orgBillingInvoices: OrgBillingInvoice[] = [];
  @Input() planType: PlanType;

  @Output() onUpgrade = new EventEmitter<PlanType>();
  @Output() onDowngrade = new EventEmitter<unknown>();
  @Output() onCancel = new EventEmitter<unknown>();
  @Output() onUpgradeAnnualBilling = new EventEmitter<unknown>();

  public currentPlanType: PlanType;
  public isCurrentPlanType: boolean = false;
  public freeTrial: boolean = false;

  public price: number = 0;
  public cancelAt: Date = null;

  public planTypeInfos = planTypeInfos;
  public scalePlanDefaultWorkspaces = scalePlanDefaultWorkspaces;
  public couponToString = couponToString;

  public addons: { name: string; value: string }[] = [];

  constructor(
    private settingsService: SettingsService,
    private entitlementService: EntitlementService,
  ) {}

  public ngOnChanges(): void {
    this.currentPlanType = planIdToPlanType(
      this.orgBilling.stripe_product_id,
      this.settingsService.billing,
    );

    this.isCurrentPlanType = this.planType === this.currentPlanType;
    this.freeTrial = this.entitlementService.isTrialing();

    this.cancelAt = this.orgBillingSubscription?.cancel_at
      ? new Date(this.orgBillingSubscription?.cancel_at * 1000)
      : null;
  }

  public isRecommendedPlan(): boolean {
    return recommendedPlanTypeUpgrade[this.currentPlanType] === this.planType;
  }

  public get inferiorPlanType(): string {
    const orderedPlans = Object.keys(planTypeInfos);
    const idx = orderedPlans.indexOf(this.planType);

    if (idx === 0 || idx - 1 === 0) {
      return null;
    }
    return orderedPlans[idx - 1];
  }

  public isSuperiorPlan(): boolean {
    const orderedPlans = Object.keys(planTypeInfos);
    return (
      orderedPlans.indexOf(this.planType) >
      orderedPlans.indexOf(this.currentPlanType)
    );
  }

  public formatHighNumber(num: number): string {
    return formatNumber(num, "number");
  }

  public getFormattedDate(timestamp: number) {
    return format(timestamp * 1000, "MMMM d, y");
  }

  public hasPaymentMethod() {
    return !!this.orgBillingPaymentMethods?.default_payment_method;
  }

  public getPaymentMethodLabel() {
    const defaultPMId =
      this.orgBillingPaymentMethods?.default_payment_method?.id;
    const method = this.orgBillingPaymentMethods?.payment_method.find(
      (pm) => pm.id === defaultPMId,
    );

    if (!method) {
      return "None";
    }

    switch (method.type) {
      case "card":
        return `${method.card.brand} ending by ${method.card.last4}`;
      case "sepa_debit":
        return `Account ending by ${method.sepa_debit.last4}`;
    }
  }

  public upgrade() {
    this.onUpgrade.emit(this.planType);
  }

  public downgrade() {
    this.onDowngrade.emit();
  }

  public cancel() {
    this.onCancel.emit();
  }

  public upgradeAnnualBilling() {
    this.onUpgradeAnnualBilling.emit();
  }

  public typeOf(any: any): string {
    return typeof any;
  }
}
