import { GraphNode, ScenarioGraphBuilder } from "components/builder/flow";
import {
  AnalyticsFilter,
  AnalyticsFilterForResponse,
  AnalyticsFilterResponseAnswer,
  AnalyticsFilterResponseHiddenField,
  AnalyticsQuery,
} from "models/analytics.filters.type";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";
import { AvailableField, valueComponent } from "./filter-criteria.component";
import { KPIKey, allKPIs } from "models/analytics.kpis";
import { UUID, presetsDefaultValue } from "models/survey.dao.types";
import { HttpErrorResponse } from "@angular/common/http";
import { AnalyticsResponse } from "models/analytics.model";
import { Org } from "models/org.model";
import { AnalyticsDao } from "models/analytics.dao";
import { Survey } from "models/survey.model";
import { RegistryEntry } from "models/registry.model";
import {
  FilterGroup,
  operators,
  valueComponents,
} from "./filters/filter-criteria-filters.component";
import { LanguageWithEmoji } from "services/ui.service";

/**
 * Filters CRUD / validation
 */
export function getOperatorOptions(
  filter: AnalyticsFilter,
  nodesByKey: object,
  registryEntriesIdentityProperty: RegistryEntry[],
  isNewFilter?: boolean,
): NzSelectOptionInterface[] {
  if (filter.type === "response") {
    if (filter.key === "respondent_aliases") {
      return operators.response.respondent_aliases;
    } else if (filter.key === "respondent_segments") {
      return operators.response.respondent_segments;
    } else if (filter.key === "raw") {
      return operators.response.raw;
    } else if (filter.key === "channel_id") {
      return operators.response.channel_id;
    } else if (filter.key === "source") {
      return operators.response.source;
    } else if (filter.key === "language") {
      return operators.response.language;
    } else if (filter.key === "completion") {
      return operators.response.completion;
    } else if (filter.key === "emotions") {
      if (isNewFilter) {
        return operators.response.emotions.filter(
          (operator) => operator.value !== "not_in",
        );
      }
      return operators.response.emotions;
    } else if (filter.key === "interaction") {
      if (isNewFilter) {
        return operators.response.interaction.filter(
          (operator) => operator.value !== "not_in",
        );
      }
      return operators.response.interaction;
    }
    // equal/exist operator does not make sense here
  } else if (filter.type === "response.answer") {
    if (filter.key === "tags") {
      if (isNewFilter) {
        return operators.response.answer.tags.filter(
          (operator) => operator.value !== "not_in",
        );
      }
      return operators.response.answer.tags;
    } else if (filter.key === "types") {
      if (isNewFilter) {
        return operators.response.answer.types.filter(
          (operator) => operator.value !== "not_in",
        );
      }
      return operators.response.answer.types;
    }
    // If it is a scoring question with preset (such as nps, ces, csat...), we accept only "numeric" operators
    const node: GraphNode = nodesByKey[filter.key];
    if (node.isPreset) {
      return operators.response.answerPreset;
    } else if (
      node.questionType === "multiple_choice" ||
      node.questionType === "scoring"
    ) {
      return operators.response.multiple_choice;
    } else if (node.questionType === "range") {
      return operators.response.answer.numeric;
    } else {
      // @TODO: add support for numeric + time + boolean
      return operators.response.answer.string;
    }
  } else if (filter.type === "response.hidden_field") {
    switch (filter.key) {
      case "timezone":
      case "locale":
      case "support":
      case "user_agent":
      case "page_title":
      case "url":
        return operators.respondent.property.string;
    }

    const property = registryEntriesIdentityProperty.find(
      (p) => p.slug === filter.key,
    );

    if (property?.type === "string")
      return operators.respondent.property.string;
    else if (property?.type === "number")
      return operators.respondent.property.numeric;
    else if (property?.type === "bool")
      return operators.respondent.property.boolean;
    else if (property?.type === "time")
      return operators.respondent.property.time;
    else return operators.response.hiddenField;
  }

  throw Error("unexpected filter type for response: " + filter.type);
  // return [];
  // return operators.other.string;
}

export function getValueComponent(
  filter: AnalyticsFilterForResponse,
  nodesByKey: object,
  registryEntriesIdentityProperty: RegistryEntry[],
): valueComponent {
  if (filter.operator === "not_null") return "none";

  switch (filter.type) {
    case "response":
      if (filter.key === "respondent_aliases")
        return valueComponents.response.respondent_aliases;
      if (filter.key === "respondent_segments")
        return valueComponents.response.respondent_segments;
      else if (filter.key === "raw") return valueComponents.response.raw;
      else if (filter.key === "language")
        return valueComponents.response.language;
      else if (filter.key === "completion")
        return valueComponents.response.completion;
      else if (filter.key === "channel_id")
        return valueComponents.response.channel_id;
      else if (filter.key === "source") return valueComponents.response.source;
      else if (filter.key === "emotions")
        return valueComponents.response.emotions;
      else if (filter.key === "interaction")
        return valueComponents.response.interaction;
      else break;
    case "response.answer":
      if (filter.key === "tags") {
        return valueComponents.response.answer.tags;
      } else if (filter.key === "types") {
        return valueComponents.response.answer.types;
      }
      const node: GraphNode = nodesByKey[filter.key];
      if (node.isPreset) {
        return valueComponents.response.answerPreset(
          filter as AnalyticsFilterResponseAnswer,
        );
      } else if (
        node.questionType === "multiple_choice" ||
        node.questionType === "scoring"
      ) {
        return valueComponents.response.multipleChoice(
          filter as AnalyticsFilterResponseAnswer,
        );
      } else if (node.questionType === "range") {
        return valueComponents.response.answer.number;
      } else {
        return valueComponents.response.answer.string;
      }
      break;

    // @TODO: add support for numeric + time + boolean
    case "response.hidden_field":
      switch (filter.key) {
        case "timezone":
        case "locale":
        case "support":
        case "user_agent":
        case "page_title":
        case "url":
          return valueComponents.response.answer.string;
      }

      const property = registryEntriesIdentityProperty.find(
        (p) => p.slug === filter.key,
      );

      if (property?.type === "string")
        return valueComponents.response.answer.string;
      else if (property?.type === "number")
        return valueComponents.response.answer.number;
      else if (property?.type === "bool")
        return valueComponents.response.answer.boolean;
      else if (property?.type === "time")
        return valueComponents.response.answer.time;
      else
        return valueComponents.response.hiddenField(
          filter as AnalyticsFilterResponseHiddenField,
        );
      break;
  }

  throw Error(
    "unexpected filter operator " +
      filter["operator"] +
      " for filter of type " +
      filter["type"],
  );
}

export function getAvailableFields(
  survey: Survey,
  selectedKpis: KPIKey[],
  hiddenFields: string[],
): { nodes: GraphNode[]; nodesByKey: object; keysOptions: AvailableField[] } {
  const flow = new ScenarioGraphBuilder(survey.scenario);
  const nodes = flow
    .getNodeGraph()
    .flat()
    .filter((n: GraphNode) => n.hasCTA);
  const nodesByKey = nodes.reduce((acc: object, n: GraphNode) => {
    acc[n.node.correlation_id] = n;
    return acc;
  }, {});

  const options: AvailableField[] = [];

  // Only used by share-lateral-panel
  const avalaibleFieldsNames = selectedKpis
    ?.map((kpiName) => {
      // Get corresponding kpi
      const kpi = allKPIs.find((k) => k.key === kpiName);
      if (kpi?.hasFilters) {
        return kpi.supportedFilterTypes;
      }
    })
    .flat();

  if (!selectedKpis) {
    options.push({
      value: { type: "response", key: "source" },
      label: `Source`,
      groupLabel: FilterGroup.Response,
    });
  }
  if (!selectedKpis) {
    options.push({
      value: { type: "response", key: "channel_id" },
      label: `Channel`,
      groupLabel: FilterGroup.Response,
    });
  }

  if (!selectedKpis) {
    options.push({
      value: { type: "response", key: "respondent_aliases" },
      label: `Respondent`,
      groupLabel: FilterGroup.Response,
    });
  }
  if (!selectedKpis || avalaibleFieldsNames.includes("response.interaction")) {
    options.push({
      value: { type: "response", key: "interaction" },
      label: `Interaction`,
      groupLabel: FilterGroup.Response,
    });
  }
  if (!selectedKpis || avalaibleFieldsNames.includes("response.keyword")) {
    options.push({
      value: { type: "response", key: "raw" },
      label: `Keyword`,
      groupLabel: FilterGroup.Response,
    });
  }
  if (!selectedKpis) {
    options.push({
      value: { type: "response", key: "language" },
      label: `Language`,
      groupLabel: FilterGroup.Response,
    });
  }
  if (!selectedKpis) {
    options.push({
      value: { type: "response", key: "completion" },
      label: `Completion`,
      groupLabel: FilterGroup.Response,
    });
  }
  if (!selectedKpis) {
    options.push({
      value: { type: "response", key: "respondent_segments" },
      label: `Segment`,
      groupLabel: FilterGroup.Response,
    });
  }
  if (!selectedKpis || avalaibleFieldsNames.includes("response.emotion")) {
    options.push({
      value: { type: "response", key: "emotions" },
      label: `Emotions`,
      groupLabel: FilterGroup.Response,
    });
  }
  if (!selectedKpis || avalaibleFieldsNames.includes("response.tag")) {
    options.push({
      value: { type: "response.answer", key: "tags" },
      label: "Tags",
      groupLabel: FilterGroup.Response,
    });
  }

  if (!selectedKpis || avalaibleFieldsNames.includes("response.media_type")) {
    options.push({
      value: { type: "response.answer", key: "types" },
      label: `Type`,
      groupLabel: FilterGroup.Response,
    });
  }

  if (!selectedKpis || avalaibleFieldsNames.includes("response.answer")) {
    options.push(
      ...nodes.map((n: GraphNode) => {
        return {
          value: {
            type: "response.answer" as AnalyticsFilter["type"],
            key: UUID(n.node.correlation_id),
          },
          label: `${n.name}. ${n.description}`,
          groupLabel: FilterGroup.Question,
        };
      }),
    );
  }

  if (!selectedKpis || avalaibleFieldsNames.includes("response.hidden_field")) {
    options.push(
      ...(hiddenFields || []).map((hf: string) => {
        return {
          value: {
            type: "response.hidden_field" as AnalyticsFilter["type"],
            key: hf,
          },
          label: hf,
          groupLabel: FilterGroup.HiddenField,
        };
      }),
    );
  }

  return { nodes, nodesByKey, keysOptions: options };
}
export async function fetchTagsKeys(
  org: Org,
  analyticsDao: AnalyticsDao,
): Promise<NzSelectOptionInterface[]> {
  const query: AnalyticsQuery = {
    org_id: org.id as UUID,
    survey_ids: ["*"],
    filters_bool: "AND",
    type: "response",
    filters: [],
    size: 0,
    with_total: false,
    offset: 0,
    range: {
      field: "created_at",
      start: org.created_at,
      end: new Date(),
    },
    aggregation: [{ by: "by_tags" }],
  };

  return analyticsDao
    .search(query)
    .then((res: AnalyticsResponse) => {
      const tags = res.aggregations.answer.tags.buckets.map((tag) => tag.key);

      // remove duplicate
      const deduplicateTags = tags.filter(
        (item, index) => tags.indexOf(item) === index,
      );

      return deduplicateTags.map((tag) => {
        return { label: tag, value: tag };
      });
    })
    .catch((err: HttpErrorResponse) => {
      console.error(err);
    });
}

export async function fetchHiddenFieldKeys(
  survey: Survey,
  analyticsDao: AnalyticsDao,
): Promise<string[]> {
  const query: AnalyticsQuery = {
    type: "response",
    org_id: UUID(survey.org_id),
    survey_ids: [UUID(survey.id)],
    filters: [],
    filters_bool: "AND",
    range: {
      field: "created_at",
      start: survey.created_at,
      end: new Date(),
    },
    size: 0,
    offset: 0,
    aggregation: [{ by: "by_hidden_field.key" }],
    having_answer_only: false,
    with_total: false,
  };

  return analyticsDao
    .search(query)
    .then((res: AnalyticsResponse) => {
      const buckets =
        res.aggregations?.hidden_field?.hidden_field?.buckets ?? [];
      if (!buckets) {
        throw new Error("Aggregation failed.");
      }

      // sort response by most selected first
      buckets.sort((a: any, b: any) => b.doc_count - a.doc_count);

      return buckets.map((b) => b.key);
    })
    .catch((err: HttpErrorResponse) => {
      console.error(err);
    });
}

export async function fetchLanguageKeys(
  survey: Survey,
  analyticsDao: AnalyticsDao,
  languages: LanguageWithEmoji[],
): Promise<LanguageWithEmoji[]> {
  const query: AnalyticsQuery = {
    type: "response",
    org_id: UUID(survey.org_id),
    survey_ids: [UUID(survey.id)],
    filters: [],
    filters_bool: "AND",
    range: {
      field: "created_at",
      start: survey.created_at,
      end: new Date(),
    },
    size: 0,
    offset: 0,
    aggregation: [{ by: "by_language" }],
    having_answer_only: false,
    with_total: false,
  };

  return analyticsDao
    .search(query)
    .then((res: AnalyticsResponse) => {
      const buckets = res.aggregations?.language?.buckets ?? [];
      if (!buckets) {
        throw new Error("Aggregation failed.");
      }

      // sort response by most selected first
      buckets.sort((a: any, b: any) => b.doc_count - a.doc_count);

      return languages.filter((language) =>
        buckets.some((b) => b.key === language.value),
      );
    })
    .catch((err: HttpErrorResponse) => {
      console.error(err);
      return []; // Add this line to return an empty array in case of error
    });
}

const presetSettingsToOptionsFn = (settings): NzSelectOptionInterface => ({
  value: settings.value,
  label: settings.emoji,
});
const filterValueOptionsNPS = presetsDefaultValue.nps.map(
  presetSettingsToOptionsFn,
);
const filterValueOptionsCES = presetsDefaultValue.ces.map(
  presetSettingsToOptionsFn,
);
const filterValueOptionsCSat = presetsDefaultValue.csat.map(
  presetSettingsToOptionsFn,
);

export function getValuePresetOptions(
  filter: AnalyticsFilter,
  survey: Survey,
  nodesByKey: object,
) {
  if (filter.type !== "response.answer") {
    return null;
  }

  const node: GraphNode = nodesByKey[filter.key];
  if (!node) {
    return null;
  }

  // This is the most awful hack I've ever done.
  // I've done this because of the f.ing troubles I have with infinite loops.
  // I should refacto all this, but no time now.
  if (node.node.question?.call_to_action.type === "multiple_choice") {
    const options = node.node.question?.call_to_action.choices;

    for (const option of options) {
      if (option.type === "button") {
        option["label"] = `${option.payload.emoji ?? ""} ${
          option.payload.label[survey?.scenario.default_language]
        }`;
        option["value"] = option.correlation_id;
      }
    }

    return options as any;
  }

  // Same shit as above
  if (node.node.question?.call_to_action.type === "scoring") {
    const scores = node.node.question?.call_to_action.scores;

    for (const score of scores) {
      if (score.type === "scoring") {
        score["label"] = score.payload.emoji;
        score["value"] = score.payload.value;
      }
    }

    return scores as any;
  }

  if (!node.isPreset) {
    return null;
  }

  if (node.isNPS) {
    return filterValueOptionsNPS;
  }
  if (node.isCES) {
    return filterValueOptionsCES;
  }
  if (node.isCSAT) {
    return filterValueOptionsCSat;
  }
  // if (n.isPMF)
  // return this.filterValueOptionsPMF;

  return null;
}
