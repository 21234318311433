import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { NzProgressComponent } from "ng-zorro-antd/progress";
import { ScreebFormatPipe } from "pipes/format.pipe";

@Component({
  selector: "survey-stats-indicators-progress-indicator",
  templateUrl: "./progress-indicator.component.html",
  styleUrls: ["./progress-indicator.component.scss"],
  imports: [NzProgressComponent, ScreebFormatPipe],
})
export class ProgressIndicatorStatsSurveyComponent implements OnChanges {
  @Input()
  public color: string;
  @Input()
  public label: string;
  @Input()
  public percentValue: number;
  @Input()
  public value: number;
  @Input()
  public innerPercentValue: number = 0;
  @Input()
  public delay: number = 0;

  constructor() {}

  ngOnChanges({ percentValue }: SimpleChanges): void {
    setTimeout(
      () => (this.innerPercentValue = percentValue?.currentValue ?? 0),
      percentValue?.previousValue === undefined ||
        percentValue?.previousValue === null
        ? this.delay
        : 0,
    );
  }
}
