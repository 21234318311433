import { Component, Input } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";
import { Survey } from "models/survey.model";
import { NgIf, NgClass, NgFor } from "@angular/common";
import { NzSelectComponent, NzOptionComponent } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { EntitlementPipe } from "pipes/entitlement.pipe";

@Component({
  selector: "rule-user-answered-survey",
  templateUrl: "./rule-user-answered-survey.component.html",
  styleUrls: ["./rule-user-answered-survey.component.scss"],
  imports: [
    NgIf,
    NzSelectComponent,
    NgClass,
    FormsModule,
    NgFor,
    NzOptionComponent,
    EntitlementPipe,
  ],
})
export class TargetingRuleUserAnsweredSurveyComponent {
  @Input() public rule: TargetingRule = null;
  @Input() public surveys: Survey[] = [];

  constructor() {}
}
