import { Component, Input } from "@angular/core";
import { formatNumber } from "utils/number";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { SanitizedMessageComponent } from "../../../../../../builder/components/Cards/sanitized-message/sanitized-message.component";

@Component({
  selector: "survey-stats-indicators-slider-indicator",
  templateUrl: "./slider-indicator.component.html",
  styleUrls: ["./slider-indicator.component.scss"],
  imports: [NzTooltipDirective, SanitizedMessageComponent],
})
export class SliderIndicatorStatsSurveyComponent {
  @Input()
  public value: number;
  @Input()
  public min: number;
  @Input()
  public max: number;
  @Input()
  public emoji: string;

  constructor() {}

  public get percentage() {
    return (this.value - this.min) / (this.max - this.min);
  }
  public getFormattedValue() {
    return formatNumber(this.value, "percent", 0);
  }
}
