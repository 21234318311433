import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { Survey } from "models/survey.model";
import { Org } from "models/org.model";
import { NotificationHelper } from "helpers/notification.helper";
import { SeoService } from "services/seo.service";
import { PageComponentInterface } from "components/PageComponentInterface";
import { RoutingService } from "services/routing.service";
import { PermissionsService } from "services/permissions.service";
import { NgIf } from "@angular/common";
import { EmptyDashboardStatsSurveyComponent } from "../../empty-dashboard/empty-dashboard.component";
import { PermissionPipe } from "pipes/permission.pipe";

@Component({
  selector: "page-survey-stats-indicators-pmf",
  templateUrl: "./pmf.component.html",
  styleUrls: ["./pmf.component.scss"],
  imports: [NgIf, EmptyDashboardStatsSurveyComponent, PermissionPipe],
})
export class PMFIndicatorStatsSurveyPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Analyze - Product Market Fit";
  public name = "Analyze product market fit";

  private obs: any = null;

  public org: Org = null;
  public survey: Survey = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private notificationHelper: NotificationHelper,
    private seoService: SeoService,
    public permissionsService: PermissionsService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
      this.survey = data["survey"];
    });
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
  }

  public soonCTA(primary: boolean) {
    if (primary)
      this.router.navigate([
        `/org/${this.org.id}/survey/${this.survey.id}/edit`,
      ]);
    else this.router.navigate([`/org/${this.org.id}/survey/create`]);
  }
}
