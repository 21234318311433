import { HttpClient } from "@angular/common/http";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ChartDataset } from "chart.js";
import {
  bucketToCesAggregation,
  CesAggregation,
  computeCesPeriod,
} from "components/surveys/pages/stats/indicators/ces/ces.aggregation";
import {
  bucketToCsatAggregation,
  computeCsatPeriod,
  CsatAggregation,
  csatEmojis,
} from "components/surveys/pages/stats/indicators/csat/csat.aggregation";
import {
  bucketToCompletionRate,
  bucketToDatedAggregation,
  bucketToDisplayNumber,
  bucketToResponseRate,
  CompletionRateAggregation,
  computeCompletionRatePeriod,
  computeDisplayNumberPeriod,
  computeResponseNumberPeriod,
  computeResponseRatePeriod,
  computeVariation,
  DisplayNumberAggregation,
  getDateAnswerValueBuckets,
  getIndustryAverageScore,
  ResponseNumberAggregation,
  ResponseRateAggregation,
} from "components/surveys/pages/stats/indicators/indicator.utils";
import {
  bucketToNpsAggregation,
  computeNpsPeriod,
  NpsAggregation,
} from "components/surveys/pages/stats/indicators/nps/nps.aggregation";
import { differenceInDays, subMonths } from "date-fns";
import { AnalyticsDao } from "models/analytics.dao";
import {
  AnalyticsQueryResponse,
  AnalyticsQueryUsers,
} from "models/analytics.filters.type";
import { SurveyDao } from "models/survey.dao";
import { SurveyScenario, UUID } from "models/survey.dao.types";
import { IndustriesScores } from "resolvers/asset-industries-scores";
import { UIService } from "services/ui.service";
import { formatDateDistanceFromNow } from "utils/date";
import { formatNumber, NumberFormatType } from "utils/number";
import { NgClass, NgIf } from "@angular/common";
import { SingleIndicatorStatsSurveyComponent } from "../../../surveys/pages/stats/indicators/components/single-indicator/single-indicator.component";
import { MiniTrendIndicatorComponent } from "../../../utils/mini-trend-indicator/mini-trend-indicator";
import { RouterLink } from "@angular/router";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";

const MINI_TREND_GRAPH_MAX_BUCKETS = 30;

@Component({
  selector: "widget-graph",
  templateUrl: "./graph.component.html",
  styleUrls: ["./graph.component.scss"],
  imports: [
    NgClass,
    SingleIndicatorStatsSurveyComponent,
    MiniTrendIndicatorComponent,
    NgIf,
    RouterLink,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
  ],
})
export class WidgetGraphComponent implements OnInit, OnChanges {
  @Input() type:
    | "nps"
    | "ces"
    | "csat"
    | "mau"
    | "completionRate"
    | "responseNumber"
    | "responseRate"
    | "displayNumber";
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() userId?: string;

  public isLoading: boolean = true;
  public title: string = "NPS";
  public color: string = "#5e21f1";
  public bgColor: string = "#f5f1ff";
  public diff: number | null = null;
  public score: number | null = null;
  public datasets: ChartDataset[];
  public formattedDiffIndustryAverage: string | null = null;
  public formattedLastDate: string | null = null;
  public indicatorValue: string | null = null;
  public indicatorValueColor: "error" | "success" | "warning" | "emoji";
  public format: NumberFormatType = "number";

  constructor(
    public uiService: UIService,
    private analyticsDao: AnalyticsDao,
    private surveyDao: SurveyDao,
    private httpClient: HttpClient,
  ) {}

  async ngOnInit() {
    this.reload();
  }

  ngOnChanges({ startDate, endDate }: SimpleChanges) {
    if (!startDate?.previousValue || !endDate?.previousValue) {
      return;
    }

    if (
      startDate?.currentValue !== startDate?.previousValue ||
      endDate?.currentValue !== endDate?.previousValue
    ) {
      this.reload();
    }
  }

  async reload() {
    this.isLoading = true;

    try {
      switch (this.type) {
        case "nps":
          this.title = "NPS";
          this.color = "#5e21f1";
          this.bgColor = "#f5f1ff";
          await this.getByType("nps");
          break;
        case "mau":
          this.title = "Users Activity Trend";
          this.color = "#15a179";
          this.bgColor = "#e4f8f2";
          await this.getMonthlyActiveRespondents();
          break;
        case "ces":
          this.title = "CES";
          this.color = "#f6a623";
          this.bgColor = "#fff6e5";
          await this.getByType("ces");
          break;
        case "csat":
          this.title = "CSAT";
          this.color = "#68052f";
          this.bgColor = "#ffd6dd";
          await this.getByType("csat");
          break;
        case "completionRate":
          this.title = "Completion Rate";
          this.color = "#0054b6";
          this.bgColor = "#e5f0ff";
          this.format = "percent";
          await this.getByType("completionRate");
          break;
        case "responseNumber":
          this.title = "Responses Number";
          this.color = "#0054b6";
          this.bgColor = "#e5f0ff";
          await this.getByType("responseNumber");
          break;
        case "responseRate":
          this.title = "Responses Rate";
          this.color = "#0054b6";
          this.bgColor = "#e5f0ff";
          this.format = "percent";
          await this.getByType("responseRate");
          break;
        case "displayNumber":
          this.title = "Displays Number";
          this.color = "#0054b6";
          this.bgColor = "#e5f0ff";
          await this.getByType("displayNumber");
          break;
        default:
          throw new Error(`Unknown type ${this.type}`);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      this.isLoading = false;
    }
  }

  private async getMonthlyActiveRespondents() {
    const response = await this.analyticsDao.search(
      this.getRespondentsCountQuery(
        this.startDate,
        "last_activity_at",
        this.endDate,
      ),
    );
    const responseDiff = await this.analyticsDao.search(
      this.getRespondentsCountQuery(
        subMonths(this.startDate, 1),
        "last_activity_at",
        this.startDate,
      ),
    );

    this.score = response.hits.total;
    this.diff = Math.round(
      computeVariation(responseDiff.hits.total, response.hits.total),
    );

    const query: AnalyticsQueryUsers = {
      type: "respondent",
      org_id: UUID(this.uiService.currentOrgId),
      survey_ids: ["*"],
      filters_bool: "AND",
      filters: this.userId
        ? [
            {
              type: "respondent",
              key: "aliases",
              operator: "eq",
              value: this.userId,
            },
          ]
        : [],
      identified_only: false,
      size: 0,
      range: {
        start: this.startDate,
        end: this.endDate,
        field: "last_activity_at",
      },
      aggregation: [
        {
          by: "by_event.date",
          params: {
            date_histogram_min_interval: "day",
            date_histogram_buckets: this.getNbrDateBucket(),
            // we have to change the sign of timezone, because Javascript sucks
            // this is pure shit
            // https://stackoverflow.com/questions/55564508/pie-chart-js-display-a-no-data-held-message
            date_histogram_timezone_offset: -new Date().getTimezoneOffset(),
          },
        },
      ],
      with_total: false,
    };

    const datasetsResponse = await this.analyticsDao.search(query);

    this.datasets = [
      {
        label: this.title,
        yAxisID: "y",
        data: (datasetsResponse.aggregations?.event?.date?.buckets ?? []).map(
          ({ key_as_string, doc_count }) => ({
            x: +new Date(key_as_string),
            y: doc_count,
          }),
        ),
      },
    ];
  }

  private getRespondentsCountQuery(
    start: Date,
    field: "last_activity_at" | "created_at",
    end: Date,
  ): AnalyticsQueryUsers {
    return {
      type: "respondent",
      org_id: UUID(this.uiService.currentOrgId),
      survey_ids: ["*"],
      identified_only: false,
      filters_bool: "AND",
      filters: [],
      range: {
        start,
        end,
        field,
      },
      size: 0,
    };
  }

  private async getByType(
    type:
      | "nps"
      | "ces"
      | "csat"
      | "completionRate"
      | "responseNumber"
      | "responseRate"
      | "displayNumber",
  ) {
    const scores = await this.httpClient
      .get<IndustriesScores>(`/assets/data/industries.scores.json`)
      .toPromise();
    const industryAverage =
      type === "responseNumber" ||
      type === "responseRate" ||
      type === "displayNumber"
        ? null
        : getIndustryAverageScore(
            scores,
            type,
            this.uiService.currentOrg.industry,
          );

    // Fetch surveys scenarios
    const surveys = await this.surveyDao.getAllByOrgId(
      "survey",
      this.uiService.currentOrgId,
      true,
      false,
    );
    const scenarios = surveys.map(({ scenario }) => scenario);

    const { aggregation, aggregationPerDate } = await this.getForRange(
      type,
      scenarios,
      this.startDate,
      this.endDate,
    );
    const { aggregation: aggregationDiff } = await this.getForRange(
      type,
      scenarios,
      subMonths(this.startDate, 1),
      this.startDate,
    );

    this.score = aggregation.score;
    this.diff = computeVariation(aggregationDiff.score, aggregation.score);
    this.datasets = this.getDatasets(industryAverage, aggregationPerDate);
    this.formattedDiffIndustryAverage =
      this.getFormattedDiffIndustryAverage(industryAverage);
    const lastDate = aggregationPerDate.find(({ total }) => total > 0)?.date;
    this.formattedLastDate = lastDate
      ? formatDateDistanceFromNow(lastDate)
      : "";

    if (type === "nps") {
      if (this.score >= 9) {
        this.indicatorValue = "Promoter";
        this.indicatorValueColor = "success";
      } else if (this.score >= 7) {
        this.indicatorValue = "Passive";
        this.indicatorValueColor = "warning";
      } else {
        this.indicatorValue = "Detractor";
        this.indicatorValueColor = "error";
      }
    } else if (type === "ces") {
      if (this.score >= 7) {
        this.indicatorValue = "Small Effort";
        this.indicatorValueColor = "success";
      } else if (this.score >= 5) {
        this.indicatorValue = "Medium Effort";
        this.indicatorValueColor = "warning";
      } else {
        this.indicatorValue = "High Effort";
        this.indicatorValueColor = "error";
      }
    } else if (type === "csat") {
      this.indicatorValue = csatEmojis[this.score];
      this.indicatorValueColor = "emoji";
    }
  }

  private getDatasets(
    industryAverage: number | null,
    aggregationPerDate:
      | NpsAggregation[]
      | CesAggregation[]
      | CsatAggregation[]
      | CompletionRateAggregation[]
      | ResponseRateAggregation[]
      | ResponseNumberAggregation[]
      | DisplayNumberAggregation[],
  ) {
    return [
      {
        label: this.title,
        yAxisID: "y",
        data: aggregationPerDate.map(({ score, date }) => ({
          x: +date,
          y: score,
        })),
      },
      {
        label: "Industry Average",
        yAxisID: "y",
        data: [
          {
            x: +aggregationPerDate[0]?.date || +new Date(),
            y: industryAverage,
          },
          {
            x:
              +aggregationPerDate[aggregationPerDate.length - 1]?.date ||
              +new Date(),
            y: industryAverage,
          },
        ],
        borderColor: this.color,
        borderWidth: 1,
        borderDash: [4, 8],
        borderDashOffset: 0,
        fill: false,
      },
    ];
  }

  private async getForRange(
    type:
      | "nps"
      | "ces"
      | "csat"
      | "completionRate"
      | "responseNumber"
      | "responseRate"
      | "displayNumber",
    scenarios: SurveyScenario[],
    start: Date,
    end: Date,
  ) {
    const query: AnalyticsQueryResponse = {
      org_id: UUID(this.uiService.currentOrgId),
      survey_ids: ["*"],
      filters_bool: "AND",
      type: "response",
      filters: this.userId
        ? [
            {
              type: "response",
              key: "respondent_aliases",
              operator: "eq",
              value: this.userId,
            },
          ]
        : [],
      range: {
        start,
        end,
        field: "created_at",
      },
      having_answer_only: true,
      aggregation: [
        {
          by: "by_date",
          params: {
            date_histogram_min_interval: "day",
            date_histogram_buckets: this.getNbrDateBucket(),
            // we have to change the sign of timezone, because Javascript sucks
            // this is pure shit
            // https://stackoverflow.com/questions/55564508/pie-chart-js-display-a-no-data-held-message
            date_histogram_timezone_offset: -new Date().getTimezoneOffset(),
          },
        },
        type === "completionRate" ||
        type === "responseNumber" ||
        type === "displayNumber" ||
        type === "responseRate"
          ? {
              by: "by_completion",
            }
          : {
              by: "by_answer.value",
              params: {
                cta_type: type,
              },
            },
      ],
      size: 10,
      with_total: false,
    };

    const response = await this.analyticsDao.search(query);

    if (type === "completionRate") {
      const aggregationPerDate = bucketToDatedAggregation(response, (b) =>
        bucketToCompletionRate(b),
      );
      const aggregation = computeCompletionRatePeriod(aggregationPerDate);

      return {
        aggregation,
        aggregationPerDate,
      };
    }

    if (type === "responseNumber") {
      const aggregationPerDate = bucketToDatedAggregation(response, (b) =>
        bucketToCompletionRate(b),
      );
      const aggregation = computeResponseNumberPeriod(aggregationPerDate);

      return {
        aggregation,
        aggregationPerDate,
      };
    }

    if (type === "displayNumber") {
      const aggregationPerDate = bucketToDatedAggregation(response, (b) =>
        bucketToDisplayNumber(b),
      );
      const aggregation = computeDisplayNumberPeriod(aggregationPerDate);

      return {
        aggregation,
        aggregationPerDate,
      };
    }

    if (type === "responseRate") {
      const aggregationPerDate = bucketToDatedAggregation(response, (b) =>
        bucketToResponseRate(b),
      );
      const aggregation = computeResponseRatePeriod(aggregationPerDate);

      return {
        aggregation,
        aggregationPerDate,
      };
    }

    if (type === "nps") {
      const aggregationPerDate = bucketToDatedAggregation(response, (b) =>
        bucketToNpsAggregation(b, getDateAnswerValueBuckets, scenarios),
      );
      const aggregation = computeNpsPeriod(aggregationPerDate);

      return {
        aggregation,
        aggregationPerDate,
      };
    }

    if (type === "ces") {
      const aggregationPerDate = bucketToDatedAggregation(response, (b) =>
        bucketToCesAggregation(b, getDateAnswerValueBuckets, scenarios),
      );
      const aggregation = computeCesPeriod(aggregationPerDate);

      return {
        aggregation,
        aggregationPerDate,
      };
    }

    if (type === "csat") {
      const aggregationPerDate = bucketToDatedAggregation(response, (b) =>
        bucketToCsatAggregation(b, getDateAnswerValueBuckets, scenarios),
      );
      const aggregation = computeCsatPeriod(aggregationPerDate);

      return {
        aggregation,
        aggregationPerDate,
      };
    }
  }

  private getFormattedDiffIndustryAverage(
    industryAverage: number | null,
  ): string | null {
    if (industryAverage === null || industryAverage === undefined) {
      return null;
    }
    return formatNumber(
      (this.score ?? 0) - industryAverage,
      "points",
      0,
      "force",
    );
  }

  private getNbrDateBucket(): number {
    const nb =
      differenceInDays(this.endDate, this.uiService.currentOrg.created_at) + 1;

    if (nb > MINI_TREND_GRAPH_MAX_BUCKETS) {
      return MINI_TREND_GRAPH_MAX_BUCKETS;
    }

    return nb;
  }
}
