/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, Output } from "@angular/core";

import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";
import { CdkScrollable } from "@angular/cdk/scrolling";

@Component({
  selector: "utils-lateral-panel",
  templateUrl: "./lateral-panel.component.html",
  styleUrls: ["./lateral-panel.component.scss"],
  imports: [CdkScrollable],
})
export class UtilsLateralPanelComponent {
  @Input() withPaddingTop: boolean = true;

  @Output() onClose = new EventEmitter<Event>();

  constructor(
    public builderStore: BuilderStore,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}
}
