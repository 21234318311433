import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { NgClass, NgIf } from "@angular/common";
import { SanitizedMessageComponent } from "../../builder/components/Cards/sanitized-message/sanitized-message.component";

@Component({
  selector: "free-text-block",
  templateUrl: "./free-text-block.component.html",
  styleUrls: ["./free-text-block.component.scss"],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [NgClass, NgIf, SanitizedMessageComponent],
})
export class FreeTextBlockComponent {
  @Input() editing: boolean = false;
  @Input() text: string = "";

  @Output() public textChange: EventEmitter<string> = new EventEmitter();
}
