import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { NotificationHelper } from "helpers/notification.helper";
import { AspectRatio, transformToAspectRatio } from "utils/media";
import { MediaStreamDirective } from "utils/media-stream-directive/media-stream.directive";
import { NzModalComponent } from "ng-zorro-antd/modal";
import { SanitizedMessageComponent } from "../../../Cards/sanitized-message/sanitized-message.component";
import { NgIf, NgFor } from "@angular/common";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { MediaStreamDirective as MediaStreamDirective_1 } from "../../../../../../utils/media-stream-directive/media-stream.directive";
import { NzSelectComponent, NzOptionComponent } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { ScreebIconComponent } from "../../../../../utils/screeb-icon/screeb-icon.component";

@Component({
  selector: "record-video-modal",
  templateUrl: "./video-record-modal.component.html",
  styleUrls: ["./video-record-modal.component.scss"],
  imports: [
    NzModalComponent,
    SanitizedMessageComponent,
    NgIf,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    MediaStreamDirective_1,
    NzSelectComponent,
    FormsModule,
    NgFor,
    NzOptionComponent,
    ScreebIconComponent,
  ],
})
export class SurveyRecordVideoModalComponent implements OnChanges {
  @ViewChild(MediaStreamDirective)
  public mediaStream: MediaStreamDirective;

  @Input() isOpen: boolean = false;
  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter();
  @Output() videoRecorded: EventEmitter<{ media: Blob; aspectRatio: number }> =
    new EventEmitter();

  public mode: "record" | "preview" = "record";

  public selectedAspectRatio = "9:16";
  public aspectRatios = [
    { value: "9:16", label: "9:16", ratio: 9 / 16, soon: false },
    { value: "16:9", label: "16:9", ratio: 16 / 9, soon: true },
    { value: "4:3", label: "4:3", ratio: 4 / 3, soon: true },
    { value: "1:1", label: "1:1", ratio: 1, soon: true },
  ];

  public aspectRatioData: AspectRatio = {
    width: 1280,
    height: 720,
    margin: 0,
  };

  public isLoaded = false;
  public isLoading = false;
  public isRecording = false;
  public record: Blob = null;
  public recordURL: SafeUrl = null;

  public videoRecordConfig: MediaStreamConstraints = {
    audio: {
      deviceId: "default",
    },
    video: {
      deviceId: "default",
      //width: { min: 640, max: 1280, ideal: 1280 },
      height: { min: 360, max: 720, ideal: 720 },
    },
  };

  public selectedDeviceVideo: string = null;
  public selectedDeviceAudio: string = null;
  public mediaDeviceVideo: MediaDeviceInfo[] = [];
  public mediaDeviceAudio: MediaDeviceInfo[] = [];

  public countDown = 3;
  private countDownInterval = null;

  constructor(
    private notificationHelper: NotificationHelper,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnChanges() {
    if (!this.isOpen) {
      return;
    }

    this.record = null;
    this.isRecording = false;
    this.recordURL = null;
    this.mode = "record";

    this.checkPermissions();
    this.setupVideo();
  }

  private get aspectRatio(): number {
    return this.aspectRatios.find((r) => r.value === this.selectedAspectRatio)
      .ratio;
  }

  handleClose(): void {
    this.mediaStream?.stop();
    this.isOpen = false;
    this.modalOpenChange.emit(this.isOpen);
  }

  handleDeviceVideoChange(deviceId: string, reset: boolean = false) {
    this.selectedDeviceVideo = deviceId;
    (this.videoRecordConfig.video as MediaTrackConstraints).deviceId = deviceId;

    if (!reset) return;

    this.setupVideo(reset);
  }

  handleDeviceAudioChange(deviceId: string, reset: boolean = false) {
    this.selectedDeviceAudio = deviceId;
    (this.videoRecordConfig.audio as MediaTrackConstraints).deviceId = deviceId;

    if (!reset) return;

    this.setupVideo(reset);
  }

  handleAspectRatioChange() {
    this.aspectRatioData = transformToAspectRatio(
      this.mediaStream.element.offsetWidth,
      this.mediaStream.element.offsetHeight,
      this.aspectRatio,
    );
  }

  onMediaReady() {
    this.isLoaded = true;
    this.handleAspectRatioChange();
  }

  onVideoStreamError(error: DOMException | ReferenceError) {
    console.error(error);
  }

  onVideoRecorded(blob: Blob) {
    this.mode = "preview";

    this.record = blob;
    this.recordURL = this.sanitizer.bypassSecurityTrustUrl(
      URL.createObjectURL(this.record),
    );
    this.mediaStream.stop();
  }

  formatDeviceLabel(label: string) {
    return label.replace(/\([0-9a-f]{4}:[0-9a-f]{4}\)/i, "");
  }

  private checkPermissions() {
    navigator.mediaDevices
      .getUserMedia(this.videoRecordConfig)
      .then(() => {
        this.setupDevices();
      })
      .catch((error) => {
        this.handleClose();
        this.notificationHelper.trigger(
          "Can't Record Video",
          "Failed to get recording permissions. Please check your browser settings.",
          "error",
        );
        console.error(error);
      });
  }

  private setupDevices() {
    navigator.mediaDevices.enumerateDevices().then((stream) => {
      // if we don't have permissions, stream is "empty"
      const hasPermissions = stream.findIndex((d) => d.deviceId !== "") > -1;

      if (!hasPermissions) {
        return;
      }

      this.mediaDeviceVideo = stream.reduce((acc, device) => {
        if (device.kind !== "videoinput") return acc;

        if (device.deviceId === "default") {
          this.handleDeviceVideoChange(device.deviceId);
        }
        if (acc.find((d) => d.groupId === device.groupId) === undefined) {
          acc.push(device);
        }
        return acc;
      }, []);

      this.mediaDeviceAudio = stream.reduce((acc, device) => {
        if (device.kind !== "audioinput") return acc;

        if (device.deviceId === "default") {
          this.handleDeviceAudioChange(device.deviceId);
        }
        if (acc.find((d) => d.groupId === device.groupId) === undefined) {
          acc.push(device);
        }
        return acc;
      }, []);

      if (
        this.selectedDeviceVideo === null &&
        this.mediaDeviceVideo.length > 0
      ) {
        this.handleDeviceVideoChange(this.mediaDeviceVideo[0].deviceId);
      }

      if (
        this.selectedDeviceAudio === null &&
        this.mediaDeviceAudio.length > 0
      ) {
        this.handleDeviceAudioChange(this.mediaDeviceAudio[0].deviceId);
      }
    });
  }

  private setupVideo(reset: boolean = false) {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    setTimeout(() => {
      if (!this.isLoading) {
        return;
      }

      if (reset) {
        this.mediaStream?.stop().then(() => {
          this.mediaStream?.play();
          this.isLoading = false;
        });
      } else {
        this.mediaStream?.play();
        this.isLoading = false;
      }
    }, 500);
  }

  startRecording() {
    this.isRecording = true;
    this.countDown = 3;
    this.countDownInterval = setInterval(() => {
      this.countDown--;
      if (this.countDown <= 0) {
        clearInterval(this.countDownInterval);
        this.countDownInterval = null;
        this.mediaStream?.recordStart();
      }
    }, 1000);

    // little timeout to prevent from recording mouse click
  }

  stopRecording() {
    this.isRecording = false;
    this.mediaStream?.recordStop();

    if (this.countDownInterval) {
      clearInterval(this.countDownInterval);
      this.countDownInterval = null;
      return;
    }
  }

  retryRecording() {
    this.record = null;
    this.recordURL = null;
    this.mode = "record";

    this.setupVideo(false);
  }

  confirmRecording() {
    this.videoRecorded.emit({
      media: this.record,
      aspectRatio: this.aspectRatio,
    });
    this.handleClose();
  }
}
