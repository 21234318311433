import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  CTALink,
  getI18nTextLabelTranslation,
  SurveyLanguages,
} from "models/survey.dao.types";
import { CardValidator, getLength } from "./CardValidator";

const isValidURL = (url: string) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+@:]*)*" + // validate port and path
      "(\\?[;,:&a-zA-Z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-zA-Z\\d_]*)?$",
    "i",
  ); // validate fragment locator
  const mailtoPattern = new RegExp(
    "^(mailto:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$)?" +
      "(\\?[;,:&a-zA-Z\\d%_.~+=-]*)?", // validate query string (subject, body),
    "i",
  ); // validate fragment locator
  return !!urlPattern.test(url) || !!mailtoPattern.test(url);
};

export const LinkValidator: CardValidator = (
  node: GraphNode,
  currentLanguage: SurveyLanguages,
  defaultLanguage: SurveyLanguages,
) => {
  const isDefaultLanguage = currentLanguage === defaultLanguage;
  const cta = node.node.question.call_to_action;

  if (cta.type !== "link") throw new Error("unexpected CTA type");

  const getLabelText = (ctaIdx: number): string => {
    const cta = node.node.question.call_to_action as CTALink;
    return decode(
      getI18nTextLabelTranslation(
        cta.choices[ctaIdx].payload.label,
        currentLanguage,
        currentLanguage,
      ) || "",
    );
  };

  const getLabelLink = (ctaIdx: number): string => {
    const cta = node.node.question.call_to_action as CTALink;
    return decode(
      getI18nTextLabelTranslation(
        cta.choices[ctaIdx].payload.url,
        currentLanguage,
        currentLanguage,
      ) || "",
    );
  };

  // labels
  const labelErrors = cta.choices
    .map((link, idx) => {
      const text = getLabelText(idx)?.trim() || "";
      const textLength = getLength(text);

      if (textLength < 1) {
        return "Label is required.";
      }
      if (textLength > 70) {
        return "Max 70 characters allowed.";
      }

      return "";
    })
    .filter((error) => error.length > 0);

  // links
  const linkErrors = cta.choices
    .map((link, idx) => {
      const url = getLabelLink(idx) || "";
      const urlLength = getLength(url);

      if (urlLength < 1) {
        return "Missing URL";
      }
      if (urlLength > 1000) {
        return "URL: Max 1000 characters allowed";
      }
      if (!isValidURL(url.trim())) {
        return "Invalid URL";
      }

      return "";
    })
    .filter((error) => error.length > 0);

  if (!isDefaultLanguage) {
    return {
      errors: [],
      warnings: [labelErrors, linkErrors],
    };
  }

  return {
    errors: [labelErrors, linkErrors],
    warnings: [],
  };
};
