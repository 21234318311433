import { Component, EventEmitter, Input, Output } from "@angular/core";

import {
  OrgBilling,
  OrgBillingInvoice,
  OrgBillingPaymentMethods,
  OrgBillingSubscription,
  PlanType,
} from "models/org_billing.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { SettingsService } from "services/settings.service";
import { TrackersService } from "services/trackers.service";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { SettingsBillingPlanComponent } from "../components/billing-plan/billing-plan.component";

@Component({
  selector: "settings-billing-tab-plans",
  templateUrl: "./tab-plans.component.html",
  styleUrls: ["./tab-plans.component.scss"],
  imports: [NzRowDirective, SettingsBillingPlanComponent, NzColDirective],
})
export class SettingsBillingTabPlansComponent {
  @Input() public superOrg: SuperOrg = null;
  @Input() public orgBilling: OrgBilling = null;
  @Input() public orgBillingSubscription: OrgBillingSubscription = null;
  @Input() public orgBillingPaymentMethods: OrgBillingPaymentMethods = null;
  @Input() public orgBillingInvoices: OrgBillingInvoice[] = null;

  @Output() public onUpgrade = new EventEmitter<PlanType>();
  @Output() public onDowngrade = new EventEmitter<void>();
  @Output() public onCancel = new EventEmitter<void>();

  public comparePlanOpened = false;

  constructor(
    public featureFlaggingService: FeatureFlaggingService,
    private superOrgDao: SuperOrgDao,
    private trackersService: TrackersService,
    private settingsService: SettingsService,
  ) {}
}
