import { Component } from "@angular/core";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { NzIconDirective } from "ng-zorro-antd/icon";

@Component({
  selector: "page-no-orga",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"],
  imports: [
    NzButtonComponent,
    ɵNzTransitionPatchDirective,
    RouterLink,
    RouterLinkActive,
    NzIconDirective,
  ],
})
class NoOrgaPageComponent {
  public subtitle: string = "Screeb account disabled";
  public text: string =
    "This error indicates that your Screeb account is not link to any organization. Please contact your administrator or Screeb support team.";

  constructor() {}
}

export { NoOrgaPageComponent };
