<div class="selector-css" *ngIf="interaction === 'in_page'">
  <css-selector
    class="row"
    [channelID]="distribution?.channel_id"
    [selectorCSS]="selectorCSS"
    (selectorCSSChange)="selectorCSSChange.emit($event)"
  />
</div>

<section class="conditions-wrapper">
  <div class="conditions">
    <div class="conditions-logic">AND</div>
    <ng-container *ngFor="let rule of rules; let i = index">
      @let canDisplay = displayRule(rule);
      <div
        *ngIf="canDisplay"
        class="condition-block condition-block--{{ rule.type }}"
        [ngClass]="{
          'rule-disabled':
            !!rule.sequence_id || !('survey_edition' | hasEntitlement),
        }"
      >
        <ng-template #titleTemplate let-thing>
          This condition is predefined and cannot be removed.
          <br />
          You can still edit it to suit your needs.
        </ng-template>

        <ng-template #titleFunnelTemplate let-thing>
          These conditions are predefined based on your funnel settings.
          <br />
          You can add more if you want to further refine the audience that will
          see this survey.
        </ng-template>

        <div class="condition-block__group">
          @let notRemovable = notRemovableRulesTypes.includes(rule.type);
          @let currentGroup = rule.getGroup();
          <ng-template #selectGroupIcon let-selected>
            <div *ngIf="selected?.nzValue" class="condition-block__select">
              @let selectedGroup =
                targetingRuleTypeGroupIcons[selected.nzValue] ||
                targetingRuleTypeGroupIcons["Other"];

              <div
                class="select-icon"
                style="background-color: {{ selectedGroup.bgColor }}; color: {{
                  selectedGroup.fgColor
                }}"
              >
                <screeb-icon
                  [icon]="selectedGroup.icon"
                  [size]="selectedGroup.size"
                ></screeb-icon>
              </div>
              {{ selected.nzValue }}
            </div>
          </ng-template>
          <ng-container *ngIf="notRemovable">
            <div class="fake-select">
              <ng-container
                [ngTemplateOutlet]="selectGroupIcon"
                [ngTemplateOutletContext]="{
                  $implicit: { nzValue: currentGroup },
                }"
              ></ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="!notRemovable">
            <nz-select
              class="rule-type-selector"
              [nzDisabled]="
                !!rule.sequence_id || !('survey_edition' | hasEntitlement)
              "
              nzShowSearch
              [ngModel]="currentGroup"
              [nzCustomTemplate]="selectGroupIcon"
              (ngModelChange)="onRuleGroupChange(rule, $event)"
              [nzDropdownMatchSelectWidth]="false"
              nzPlaceHolder="Select group"
            >
              <nz-option
                *ngFor="let option of ruleGroupSelectorsOptions"
                [nzLabel]="option.label"
                [nzValue]="option.value"
                [nzCustomContent]="true"
              >
                <ng-container
                  [ngTemplateOutlet]="selectGroupIcon"
                  [ngTemplateOutletContext]="{
                    $implicit: { nzValue: option.value },
                  }"
                ></ng-container>
              </nz-option>
            </nz-select>
          </ng-container>
        </div>
        <div class="condition-block__type">
          <ng-template #selectTypeIcon let-selected>
            @let selectedRule = targetingRulesTypesAndLabels[selected.nzValue];

            <div *ngIf="selectedRule" class="condition-block__select">
              @let selectedGroup =
                targetingRuleTypeGroupIcons[selectedRule?.group] ||
                targetingRuleTypeGroupIcons["Other"];
              <div
                class="select-icon"
                style="background-color: {{ selectedGroup.bgColor }}; color: {{
                  selectedGroup.fgColor
                }}"
              >
                <screeb-icon
                  [icon]="selectedRule.icon"
                  [size]="selectedRule.iconSize"
                ></screeb-icon>
              </div>
              {{ selectedRule?.title }}
              <nz-tag
                *ngIf="
                  targetingRulesTypesAndLabels[selectedRule.type]?.mobileSdkOnly
                "
                class="rule-sdk"
              >
                <i
                  nz-icon
                  nzType="question-circle"
                  nzTheme="outline"
                  nzTooltipTitle="This rule will only be effective on the mobile SDK."
                  nzTooltipPlacement="right"
                  nz-tooltip
                ></i>
                Mobile SDK
              </nz-tag>
              <nz-tag
                *ngIf="
                  targetingRulesTypesAndLabels[selectedRule.type]?.webSdkOnly
                "
                class="rule-sdk"
              >
                <i
                  nz-icon
                  nzType="question-circle"
                  nzTheme="outline"
                  nzTooltipTitle="This rule will only be effective on the web SDK."
                  nzTooltipPlacement="right"
                  nz-tooltip
                ></i>
                Web SDK
              </nz-tag>
            </div>
          </ng-template>
          <ng-container *ngIf="notRemovable">
            <div class="fake-select">
              <ng-container
                [ngTemplateOutlet]="selectTypeIcon"
                [ngTemplateOutletContext]="{
                  $implicit: { nzValue: rule.type },
                }"
              ></ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="!notRemovable">
            <nz-select
              class="rule-type-selector"
              [nzDisabled]="
                !!rule.sequence_id || !('survey_edition' | hasEntitlement)
              "
              nzShowSearch
              [ngModel]="rule.type"
              [nzCustomTemplate]="selectTypeIcon"
              (ngModelChange)="onRuleTypeChange(rule, $event)"
              [nzDropdownMatchSelectWidth]="false"
              nzPlaceHolder="Select type"
            >
              <nz-option
                *ngFor="let option of ruleTypeSelectorsOptions[i]"
                [nzLabel]="option.label"
                [nzValue]="option.value"
                [nzCustomContent]="true"
              >
                <ng-container
                  [ngTemplateOutlet]="selectTypeIcon"
                  [ngTemplateOutletContext]="{
                    $implicit: { nzValue: option.value },
                  }"
                ></ng-container>
              </nz-option>
            </nz-select>
          </ng-container>
        </div>

        @let canDisplayOperatorColumn =
          checkCanDisplayOperatorColumn(rule, visibleRuleOperator);
        <div class="column-operator" *ngIf="canDisplayOperatorColumn">
          @let contentDisplayed =
            [
              "visitor_property",
              "visitor_event_count",
              "visitor_event_time",
              "device_visitor_property",
              "device_visitor_event_count",
              "device_visitor_event_time",
            ].includes(rule.type);
          <div *ngIf="contentDisplayed" class="column-operator-content">
            <div
              *ngIf="
                rule.type === 'visitor_property' &&
                registryEntriesIdentityPropertyScreeb.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-properties
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityPropertyScreeb
                "
                [registryEntriesIdentityPropertyPaths]="
                  registryEntriesIdentityPropertyPathsById
                "
                [propertyKeyId]="rule.value.key_id"
                (propertyKeyIdChange)="
                  rule.serializeUserPropertyRuleKey(
                    registryEntriesIdentityPropertyById.get($event)
                  )
                "
                ngDefaultControl
                [invalid]="
                  rule.getRegistryEntrySlug(
                    registryEntriesIdentityPropertyById.get(rule.value.key_id)
                  ) === ''
                "
              ></selector-user-properties>
              <rule-operator
                *ngIf="
                  !!rule.value.key_id && visibleRuleOperator.includes(rule.type)
                "
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [rule]="rule"
                [registryEntriesIdentityPropertyById]="
                  registryEntriesIdentityPropertyById
                "
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>
            <div
              *ngIf="
                rule.type === 'device_visitor_property' &&
                registryEntriesIdentityPropertyDevice.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-properties
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityPropertyDevice
                "
                [registryEntriesIdentityPropertyPaths]="
                  registryEntriesIdentityPropertyPathsById
                "
                [propertyKeyId]="rule.value.key_id"
                (propertyKeyIdChange)="
                  rule.serializeUserPropertyRuleKey(
                    registryEntriesIdentityPropertyById.get($event)
                  )
                "
                ngDefaultControl
                [invalid]="
                  rule.getRegistryEntrySlug(
                    registryEntriesIdentityPropertyById.get(rule.value.key_id)
                  ) === ''
                "
              ></selector-user-properties>
              <rule-operator
                *ngIf="
                  !!rule.value.key_id && visibleRuleOperator.includes(rule.type)
                "
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [rule]="rule"
                [registryEntriesIdentityPropertyById]="
                  registryEntriesIdentityPropertyById
                "
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>

            <div
              *ngIf="
                rule.type === 'visitor_event_time' &&
                registryEntriesEventScreeb.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-events
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [registryEntriesEvent]="registryEntriesEventScreeb"
                [eventNameIds]="rule.value.name_ids"
                (eventNameIdsChange)="rule.setNameIds($event)"
                ngDefaultControl
                [invalid]="!areEventNamesValid(rule.value.name_ids)"
              ></selector-user-events>
              <nz-select
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                *ngIf="!!rule.value.name_ids"
                class="rule-event-first-last"
                [(ngModel)]="rule.value.v_s"
              >
                <nz-option nzValue="latest" nzLabel="Last trigger"></nz-option>
                <nz-option nzValue="oldest" nzLabel="First trigger"></nz-option>
              </nz-select>
              <rule-operator
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                *ngIf="
                  !!rule.value.name_ids &&
                  visibleRuleOperator.includes(rule.type)
                "
                [rule]="rule"
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>

            <div
              *ngIf="
                rule.type === 'device_visitor_event_time' &&
                registryEntriesEventDevice.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-events
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [registryEntriesEvent]="registryEntriesEventDevice"
                [eventNameIds]="rule.value.name_ids"
                (eventNameIdsChange)="rule.setNameIds($event)"
                ngDefaultControl
                [invalid]="!areEventNamesValid(rule.value.name_ids)"
              ></selector-user-events>
              <nz-select
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                *ngIf="!!rule.value.name_ids"
                class="rule-event-first-last"
                [(ngModel)]="rule.value.v_s"
              >
                <nz-option nzValue="latest" nzLabel="Last trigger"></nz-option>
                <nz-option nzValue="oldest" nzLabel="First trigger"></nz-option>
              </nz-select>
              <rule-operator
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                *ngIf="
                  !!rule.value.name_ids &&
                  visibleRuleOperator.includes(rule.type)
                "
                [rule]="rule"
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>

            <div
              *ngIf="
                rule.type === 'visitor_event_count' &&
                registryEntriesEventScreeb.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-events
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [registryEntriesEvent]="registryEntriesEventScreeb"
                [eventNameIds]="rule.value.name_ids"
                (eventNameIdsChange)="rule.setNameIds($event)"
                ngDefaultControl
                [invalid]="!areEventNamesValid(rule.value.name_ids)"
              ></selector-user-events>
              <rule-operator
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                *ngIf="
                  !!rule.value.name_ids &&
                  visibleRuleOperator.includes(rule.type)
                "
                [rule]="rule"
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>

            <div
              *ngIf="
                rule.type === 'device_visitor_event_count' &&
                registryEntriesEventDevice.length > 0
              "
              class="column-operator-visitor-rules"
            >
              <selector-user-events
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                [registryEntriesEvent]="registryEntriesEventDevice"
                [eventNameIds]="rule.value.name_ids"
                (eventNameIdsChange)="rule.setNameIds($event)"
                ngDefaultControl
                [invalid]="!areEventNamesValid(rule.value.name_ids)"
              ></selector-user-events>
              @let visibleRuleOperatorOk =
                visibleRuleOperator.includes(rule.type);
              <rule-operator
                [disabled]="
                  !!rule.sequence_id || !('survey_edition' | hasEntitlement)
                "
                *ngIf="!!rule.value.name_ids && visibleRuleOperatorOk"
                [rule]="rule"
                (ruleChange)="rules[i] = $event"
              ></rule-operator>
            </div>
          </div>

          @let isVisible =
            ![
              "visitor_property",
              "visitor_event_count",
              "visitor_event_time",
              "device_visitor_property",
              "device_visitor_event_count",
              "device_visitor_event_time",
            ].includes(rule.type);
          <ng-container *ngIf="isVisible">
            @let visibleRuleOperatorOk =
              visibleRuleOperator.includes(rule.type);
            <rule-operator
              [disabled]="
                !!rule.sequence_id || !('survey_edition' | hasEntitlement)
              "
              *ngIf="visibleRuleOperatorOk"
              [rule]="rule"
              (ruleChange)="onRuleChange($event, i)"
            ></rule-operator>
          </ng-container>
        </div>

        <div class="column-value">
          <ng-container [ngSwitch]="rule.type">
            <div class="full-width" *ngSwitchCase="'language'">
              <rule-language
                [rule]="rule"
                [languages]="languages"
              ></rule-language>
            </div>

            <div class="full-width" *ngSwitchCase="'country'">
              <rule-country
                [rule]="rule"
                [countries]="countries"
              ></rule-country>
            </div>

            <div class="full-width" *ngSwitchCase="'device'">
              <rule-device [rule]="rule"></rule-device>
            </div>

            <div class="full-width" *ngSwitchCase="'sdk_platform'">
              <rule-sdk-platform [rule]="rule"></rule-sdk-platform>
            </div>

            <div class="full-width" *ngSwitchCase="'url'">
              <rule-url [rule]="rule"></rule-url>
            </div>

            <div class="full-width" *ngSwitchCase="'delay'">
              <rule-delay [rule]="rule"></rule-delay>
            </div>

            <div class="full-width" *ngSwitchCase="'session_duration'">
              <rule-session-duration [rule]="rule"></rule-session-duration>
            </div>

            <div class="full-width" *ngSwitchCase="'scroll'">
              <rule-scroll [rule]="rule"></rule-scroll>
            </div>

            <div class="full-width" *ngSwitchCase="'scroll_reach_element'">
              <rule-scroll-reach-element
                [rule]="rule"
              ></rule-scroll-reach-element>
            </div>

            <div class="full-width" *ngSwitchCase="'dom_element'">
              <rule-dom-element [rule]="rule"></rule-dom-element>
            </div>

            <div class="full-width" *ngSwitchCase="'recurrence'">
              <rule-recurrence
                [ruleRecurrence]="getRuleRecurrence()"
                [ruleIfAnswered]="getRuleRecurrenceNotAnswered()"
              ></rule-recurrence>
            </div>

            <div class="full-width" *ngSwitchCase="'start_date'">
              <rule-date [rule]="rule"></rule-date>
            </div>
            <div class="full-width" *ngSwitchCase="'end_date'">
              <rule-date [rule]="rule"></rule-date>
            </div>

            <!-- <div *ngSwitchDefault>
          <input type="text" nz-input placeholder="{{ rule.getValuePlaceholder() }}" [(ngModel)]="rule.value">
        </div> -->

            <div class="full-width" *ngSwitchCase="'visitor_event_count'">
              <rule-user-event-count
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventScreeb"
              ></rule-user-event-count>
            </div>

            <div class="full-width" *ngSwitchCase="'visitor_event_time'">
              <rule-user-event-time
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventScreeb"
              ></rule-user-event-time>
            </div>

            <div class="full-width" *ngSwitchCase="'visitor_event_sequence'">
              <rule-user-event-sequence
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventScreeb"
              ></rule-user-event-sequence>
            </div>

            <div class="full-width" *ngSwitchCase="'screen'">
              <rule-screen
                [rule]="rule"
                [channelClientScreens]="channelClientScreens"
              ></rule-screen>
            </div>

            <div class="full-width" *ngSwitchCase="'app_version'">
              <rule-app-version
                [rule]="rule"
                [channelClientVersions]="channelClientVersions"
              ></rule-app-version>
            </div>

            <div class="full-width" *ngSwitchCase="'visitor_property'">
              <rule-user-property
                [rule]="rule"
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityPropertyScreeb
                "
                [registryEntriesIdentityPropertyById]="
                  registryEntriesIdentityPropertyById
                "
              ></rule-user-property>
            </div>

            <div class="full-width" *ngSwitchCase="'visitor_group'">
              <rule-user-segment
                [rule]="rule"
                [registryEntriesGroup]="registryEntriesGroup"
              ></rule-user-segment>
            </div>

            <div class="full-width" *ngSwitchCase="'visitor_answered_survey'">
              <rule-user-answered-survey
                [rule]="rule"
                [surveys]="surveys"
              ></rule-user-answered-survey>
            </div>

            <div class="full-width" *ngSwitchCase="'visitor_alias'">
              <rule-user-alias [org]="org" [rule]="rule"></rule-user-alias>
            </div>

            <div class="full-width" *ngSwitchCase="'device_visitor_property'">
              <rule-user-property
                [rule]="rule"
                [registryEntriesIdentityProperty]="
                  registryEntriesIdentityPropertyDevice
                "
                [registryEntriesIdentityPropertyById]="
                  registryEntriesIdentityPropertyById
                "
              ></rule-user-property>
            </div>

            <div
              class="full-width"
              *ngSwitchCase="'device_visitor_event_count'"
            >
              <rule-user-event-count
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventDevice"
              ></rule-user-event-count>
            </div>

            <div class="full-width" *ngSwitchCase="'device_visitor_event_time'">
              <rule-user-event-time
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventDevice"
              ></rule-user-event-time>
            </div>

            <div
              class="full-width"
              *ngSwitchCase="'device_visitor_event_sequence'"
            >
              <rule-user-event-sequence
                [rule]="rule"
                [registryEntriesEvent]="registryEntriesEventDevice"
              ></rule-user-event-sequence>
            </div>
          </ng-container>
        </div>

        <div class="column-actions">
          <ng-template #titleRegex let-thing>
            Regex are sensitive to the case of the characters and compliant with
            the ECMAScript standard.
          </ng-template>
          <i
            *ngIf="rule.operator === 'regex'"
            nz-icon
            class="rule-predefined"
            nzType="question-circle"
            nzTheme="outline"
            [nzTooltipTitle]="titleRegex"
            nzTooltipPlacement="right"
            nz-tooltip
          ></i>
          @let notRemovableAction = notRemovableRulesTypes.includes(rule.type);
          <a
            *ngIf="
              !notRemovableAction && !rule.sequence_id && 'survey_edition'
                | hasEntitlement
            "
            (click)="removeRule(i)"
          >
            <i nz-icon nzType="close"></i>
          </a>
          <i
            *ngIf="rule.sequence_id"
            nz-icon
            class="rule-predefined"
            nzType="question-circle"
            nzTheme="outline"
            [nzTooltipTitle]="titleFunnelTemplate"
            nzTooltipPlacement="right"
            nz-tooltip
          ></i>
          <i
            *ngIf="notRemovable"
            nz-icon
            class="rule-predefined"
            nzType="question-circle"
            nzTheme="outline"
            [nzTooltipTitle]="titleTemplate"
            nzTooltipPlacement="right"
            nz-tooltip
          ></i>
        </div>
      </div>
    </ng-container>
    <button
      *ngIf="'survey_edition' | hasEntitlement"
      class="ant-btn-secondary"
      nz-button
      nzType="default"
      (click)="onAddRule()"
    >
      Add
    </button>
  </div>
</section>
