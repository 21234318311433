import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, ResolveFn } from "@angular/router";

import { ChannelDao } from "models/channel.dao";
import { Channel } from "models/channel.model";
import { ApplicationLoadService } from "services/app.service";
import { UIService } from "services/ui.service";

export const channelsFromQueryOrLastOrgResolver: ResolveFn<Channel[] | null> = (
  route: ActivatedRouteSnapshot,
): Promise<Channel[] | null> => {
  const applicationLoadService = inject(ApplicationLoadService);
  const channelDao = inject(ChannelDao);
  const uiService = inject(UIService);

  let orgId = route.queryParams["org_id"];
  if (!orgId) {
    // Check if we already have the org in the ui service
    const tmp = uiService.orgs[0];
    if (tmp) {
      orgId = tmp.id;
    } else {
      throw new Error("No org id found in query params");
    }
  }

  return applicationLoadService
    .loader()
    .then(() => {
      return channelDao.getAllByOrgId(orgId);
    })
    .catch((err: HttpErrorResponse) => {
      console.error(err.error);
      throw err;
    });
};

@Injectable()
export class ChannelsResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private channelDao: ChannelDao,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Channel[]> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        return this.channelDao.getAllByOrgId(route.params["org_id"]);
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
