import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SwitchComponent } from "../../../../utils/switch/switch.component";

@Component({
  selector: "settings-data-governance-tracking-capability",
  template: `
    <switch
      [ngModel]="value"
      (ngModelChange)="valueChange.emit(value)"
    ></switch>

    {{ what }}
  `,
  styles: [``],
  imports: [SwitchComponent, FormsModule],
})
export class SettingsDataGovernanceTrackingCapabilityComponent {
  @Input() what: string;
  @Input() value: boolean;
  @Output() valueChange = new EventEmitter<boolean>();

  constructor() {}
}
