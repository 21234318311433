import { Component, Input } from "@angular/core";
import { NgClass, NgIf } from "@angular/common";
import { ScreebIconComponent } from "../screeb-icon/screeb-icon.component";

@Component({
  selector: "quickstart-task",
  template: `
    <div class="quickstart-task" [ngClass]="{ completed }">
      <span *ngIf="!completed">{{ indice }}</span>
      <screeb-icon size="sm" icon="check" *ngIf="completed"></screeb-icon>
    </div>
  `,
  styles: [
    `
      :host {
        display: inline-flex;
        width: 26px;
        height: 26px;
        min-width: 26px;
        min-height: 26px;
      }

      :host .quickstart-task {
        display: inline-flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border: 2px solid var(--screeb-color-purple-500);
        border-radius: 16px;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-purple-500);
        background: var(--screeb-color-background);
        transition: all 200ms;
      }

      :host .completed {
        background: var(--screeb-color-success-outline);
        border-color: var(--screeb-color-success-active);
        color: var(--screeb-color-success-active);
      }
    `,
  ],
  imports: [NgClass, NgIf, ScreebIconComponent],
})
export class QuickstartTaskComponent {
  @Input() public completed = false;
  @Input() public indice = "1";
}
