import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PermissionsService } from "services/permissions.service";
import { EntitlementService } from "services/entitlement.service";
import { UIService } from "services/ui.service";
import { NgIf } from "@angular/common";
import { IntercomButtonComponent } from "../../../utils/intercom-button/intercom-button.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";

type FreeTrialMsg = {
  title: string;
  color: string;
  border: string;
};

const FreeTrialMsgPerDay: { [key: number]: FreeTrialMsg } = {
  [0]: {
    title: "🥵 Your Screeb free trial ends today!",
    color: "#FFEEF1",
    border: "#FFEEF1",
  },
  [1]: {
    title: "🥵 Your Screeb free trial will end tomorrow!",
    color: "#FFEEF1",
    border: "#FFEEF1",
  },
  [8]: {
    title: "👋 Your Screeb free trial will end in a few days!",
    color: "#FFF6EA",
    border: "#FFF6EA",
  },
  [14]: {
    title: "🤗 You’re currently testing Screeb for free! We hope you enjoy it!",
    color: "#E4F8F2",
    border: "#E4F8F2",
  },
};

const FreePlanMsg: FreeTrialMsg = {
  title: "🤗 You’re using Screeb for free!",
  color: "#5E21F126",
  border: "#5E21F1",
};

const BillingIssueMsg: FreeTrialMsg = {
  title: "🥵 You have an issue on your last payment!",
  color: "#FFEEF1",
  border: "#FFEEF1",
};

@Component({
  selector: "free-trial-banner",
  template: `
    <div
      *ngIf="isFreeTrial || isFreePlan || hasBillingIssue"
      class="free-trial-banner"
      [style]="{
        'background-color': freeTrialMsg.color,
        'border-color': freeTrialMsg.border,
      }"
    >
      <span class="free-trial-title">{{ freeTrialMsg.title }}</span>

      <intercom-button
        *ngIf="freeTrialDay > 8 && !isFreePlan && !hasBillingIssue"
        textColor="#1ED5A4"
        borderColor="#1ED5A4"
        title="Any Question about your Test? Chat with us!"
      />

      <ng-container *ngIf="canUpgrade">
        <button
          *ngIf="(freeTrialDay <= 8 || isFreePlan) && !hasBillingIssue"
          class="upgrade-btn"
          nz-button
          nzType="primary"
          (click)="upgradeClick()"
        >
          <screeb-icon size="sm" icon="survey" />
          Upgrade now to continue using Screeb
        </button>
        <button
          *ngIf="hasBillingIssue"
          class="upgrade-btn"
          nz-button
          nzType="primary"
          (click)="upgradeClick()"
        >
          <screeb-icon size="sm" icon="redo" />
          Retry payment
        </button>
      </ng-container>
    </div>
  `,
  styles: [
    `
      .free-trial-banner {
        display: flex;
        align-items: center;
        border: 4px solid var(--screeb-color-red-200);
        justify-content: center;
        gap: 16px;
        border-radius: 16px;
        width: 100%;
        min-height: 72px;
        padding: 16px 90px;
        animation: shadeAppear 200ms;
        margin-bottom: 32px;
      }

      .free-trial-title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        color: #303140;
      }

      .free-trial-banner .upgrade-btn {
        height: 40px;
      }

      @keyframes shadeAppear {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `,
  ],
  imports: [
    NgIf,
    IntercomButtonComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    ScreebIconComponent,
  ],
})
export class FreeTrialBannerComponent implements OnInit {
  public freeTrialDay: number = 0;
  public isFreeTrial: boolean = false;
  public isFreePlan: boolean = false;
  public hasBillingIssue: boolean = false;
  public freeTrialMsg: FreeTrialMsg = null;

  public canUpgrade: boolean = false;

  constructor(
    private router: Router,
    private entitlementService: EntitlementService,
    private permissionsService: PermissionsService,
    private uiService: UIService,
  ) {}

  ngOnInit() {
    this.canUpgrade = this.permissionsService.isSuperAllowed("billing.list");

    if (this.entitlementService.isFreePlan()) {
      this.isFreePlan = true;
      this.freeTrialMsg = FreePlanMsg;
      return;
    }

    this.hasBillingIssue =
      this.uiService.currentSuperOrg?.flags?.billing_status === "paused";

    if (this.hasBillingIssue) {
      this.freeTrialMsg = BillingIssueMsg;
      return;
    }

    this.isFreeTrial = this.entitlementService.isTrialing();

    if (!this.isFreeTrial) {
      return;
    }
    this.freeTrialDay =
      this.entitlementService.getRemainingFreeTrialDays() ?? 0;

    this.freeTrialMsg = FreeTrialMsgPerDay["14"];

    const dayId = Object.keys(FreeTrialMsgPerDay).filter(
      (key) => parseInt(key) >= this.freeTrialDay,
    )[0];

    if (dayId !== undefined) {
      this.freeTrialMsg = FreeTrialMsgPerDay[dayId];
    }
  }

  upgradeClick() {
    this.router.navigate([
      "org",
      this.uiService.currentOrgId,
      "settings",
      "billing",
    ]);
  }
}
