import { Component, OnInit } from "@angular/core";
import { Changelog, changelogs } from "./changelogs.type";
import { NzCardComponent } from "ng-zorro-antd/card";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";

const RSS_FEED_URL = "https://changelog.screeb.app/rss.xml";

@Component({
  selector: "widget-changelog",
  templateUrl: "./changelog.component.html",
  styleUrls: ["./changelog.component.scss"],
  imports: [NzCardComponent, NzTooltipDirective, ScreebIconComponent],
})
export class WidgetChangelogComponent implements OnInit {
  public changelogs: Changelog[] = [];
  public newFeatures: Changelog[] = [];

  public currentChangelogIdx: number = 0;
  private totalChangelogsToPreview: number = 5;

  public get currentChangelog(): Changelog {
    return this.changelogs[this.currentChangelogIdx];
  }

  ngOnInit(): void {
    this.fetchRSSFeed().then((newFeatures) => {
      this.newFeatures = newFeatures;

      // merge new features with existing changelogs
      const allChanges = [...this.newFeatures, ...changelogs];
      allChanges.sort((a, b) => a.date.getTime() - b.date.getTime());

      // take last 5 changelogs
      this.changelogs = allChanges.slice(-this.totalChangelogsToPreview);

      // set current changelog to the last one
      this.currentChangelogIdx = this.changelogs.length - 1;
    });
  }

  public nextChangelog(): void {
    this.currentChangelogIdx =
      (this.currentChangelogIdx + 1) % this.changelogs.length;
  }

  public prevChangelog(): void {
    this.currentChangelogIdx =
      (this.currentChangelogIdx - 1 + this.changelogs.length) %
      this.changelogs.length;
  }

  private fetchRSSFeed(): Promise<Changelog[]> {
    return new Promise((resolve, reject) => {
      fetch(RSS_FEED_URL)
        .then((response) => response.text())
        .then((data) => {
          const parser = new DOMParser();
          const xml = parser.parseFromString(data, "text/xml");

          const items = Array.from(xml.querySelectorAll("item"));
          return items.map((item) => {
            const title = item.querySelector("title")?.textContent;
            const url = item.querySelector("link")?.textContent;
            const date = new Date(item.querySelector("pubDate")?.textContent);
            const thumbnail = item
              .querySelector("enclosure")
              ?.getAttribute("url");

            const changelog: Changelog = {
              title,
              url,
              date,
              thumbnail,
            };

            return changelog;
          });
        })
        .then((changelogs) => resolve(changelogs))
        .catch((error) => reject(error));
    });
  }
}
