import { Component, OnInit } from "@angular/core";
import { Router, RouterLink, RouterLinkActive } from "@angular/router";

import { Location, NgFor, NgIf } from "@angular/common";
import { NotificationHelper } from "helpers/notification.helper";
import {
  DistributionInteractions,
  DistributionInteractionsFormatted,
} from "models/survey-distribution.model";
import { NzButtonModule } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import {
  NzMenuDirective,
  NzMenuGroupComponent,
  NzMenuItemComponent,
} from "ng-zorro-antd/menu";
import { EntitlementPipe } from "pipes/entitlement.pipe";
import { FeaturePipe } from "pipes/feature.pipe";
import { PermissionPipe } from "pipes/permission.pipe";
import { SuperPermissionPipe } from "pipes/super-permission.pipe";
import { AuthService, SessionService } from "services/auth.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { UIService } from "services/ui.service";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";

@Component({
  selector: "layout-back-sidebar-settings",
  templateUrl: "./sidebar-settings.component.html",
  styleUrls: ["./sidebar-settings.component.scss"],
  imports: [
    NzMenuDirective,
    NzMenuGroupComponent,
    NzMenuItemComponent,
    ɵNzTransitionPatchDirective,
    RouterLink,
    RouterLinkActive,
    ScreebIconComponent,
    NgIf,
    NgFor,
    NzButtonModule,
    PermissionPipe,
    SuperPermissionPipe,
    EntitlementPipe,
    FeaturePipe,
  ],
})
export class LayoutBackSidebarSettingsComponent implements OnInit {
  public settingsLevel = null;

  constructor(
    public router: Router,
    private location: Location,
    public authService: AuthService,
    public sessionService: SessionService,
    public notificationHelper: NotificationHelper,
    public uiService: UIService,
    public featureFlaggingService: FeatureFlaggingService,
    public entitlementService: EntitlementService,
    public permissionsService: PermissionsService,
  ) {}

  public distributionInteractions = DistributionInteractions;
  public distributionInteractionsFormatted = DistributionInteractionsFormatted;

  public ngOnInit() {
    this.settingsLevel = this.router.url.includes("settings/account")
      ? "account"
      : "organization";
  }

  public navigateBack() {
    this.location.back();
  }
}
