import { Serializable } from "models/serializable.model";
import {
  DistributionInteraction,
  DistributionInteractions,
  SurveyDistribution,
} from "models/survey-distribution.model";
import { Account } from "./account.model";
import { KPIKey } from "./analytics.kpis";
import {
  IntegrationSettingsCobbai,
  IntegrationSettingsWebhook,
} from "./integrations.model";
import { CallToAction, ScenarioNode, SurveyScenario } from "./survey.dao.types";
import { TagSettings } from "./tag-settings.types";
import {
  AnalyticsFilter,
  AnalyticsFilterForResponse,
  AnalyticsFilterOperatorContainsOrNotContains,
  AnalyticsFilterOperatorEqualOrNotEqual,
  AnalyticsFilterOperatorGreaterOrLessThan,
  AnalyticsFilterOperatorIn,
  AnalyticsFilters,
  AnalyticsFiltersOperator,
} from "./analytics.filters.type";
import { channelTypes } from "./channel.model";
import { TargetingRule } from "./targeting-rule.model";

export type TemplateCategory =
  | "product-design"
  | "product-management"
  | "product-marketing"
  // | "user-research"
  | "voice-of-customer"
  | "ecommerce"
  | "marketing"
  | "customer-support";

export const TemplateCategories = [
  "product-design",
  "product-management",
  "product-marketing",
  // "user-research",
  "voice-of-customer",
  "ecommerce",
  "marketing",
  "customer-support",
] as TemplateCategory[];

export type TemplateCategorySpec = {
  category: TemplateCategory;
  name: string;
  color: string;
};

export const templateCategorySpecs: {
  [key in TemplateCategory]: TemplateCategorySpec;
} = {
  "product-design": {
    category: "product-design",
    name: "Product Design",
    color: "#E2D6FF",
  },
  "product-management": {
    category: "product-management",
    name: "Product Management",
    color: "#ABF3E0",
  },
  "product-marketing": {
    category: "product-marketing",
    name: "Product Marketing",
    color: "#DED583",
  },
  // "user-research": {
  //   category: "user-research",
  //   name: "User Research",
  //   color: "#D0E4FD",
  // },
  "voice-of-customer": {
    category: "voice-of-customer",
    name: "Voice of Customer",
    color: "#FFF2D6",
  },
  ecommerce: {
    category: "ecommerce",
    name: "Ecommerce",
    color: "#FFD6DD",
  },
  marketing: {
    category: "marketing",
    name: "Marketing",
    color: "#E6B1C8",
  },
  "customer-support": {
    category: "customer-support",
    name: "Customer Support",
    color: "#F2C594",
  },
};

export const DefaultSurveyName = "New survey";
export const DefaultMessageName = "New message";

export const parseNode = (node: ScenarioNode) => {
  if (node.question.call_to_action.type === "conditional") {
    node.question.call_to_action.conditional.rules =
      node.question.call_to_action.conditional.rules.map((rule) =>
        new TargetingRule().fromJson(rule),
      );
  }

  return node;
};

class Survey extends Serializable {
  constructor(
    public id?: string,
    public org_id?: string,
    public sequence_id?: string,

    public title?: string,

    public scenario?: SurveyScenario,

    public settings?: TagSettings,
    public stats?: SurveyStats,
    public tags?: string[],
    public integrations?: SurveyIntegrations,
    public translation_enabled?: boolean,

    public created_at?: Date,
    public updated_at?: Date,
    public updated_by?: string,

    public survey_distributions?: SurveyDistribution[] | null,
    public type?: SurveyType,

    // computed
    public updated_by_account?: Account,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    if (json["settings"] && !Object.keys(json["settings"]).length) {
      this.settings = null;
    }

    if (json["integrations"])
      this.integrations = new SurveyIntegrations().fromJson(
        json["integrations"],
      );

    this.scenario = null;
    if (json["scenario"]) {
      this.scenario = json["scenario"] as SurveyScenario;

      if (!this.scenario.nodes) {
        // 🤮
        this.scenario.nodes = [];
      }

      // This is shit.
      this.scenario.nodes = this.scenario.nodes.map(parseNode);
    }

    this.survey_distributions = null;
    if (json["survey_distributions"]) {
      this.survey_distributions = json["survey_distributions"]
        .map((data) => {
          const distrib = new SurveyDistribution().fromJson(data);
          // Message survey can't be in a hosted page
          if (distrib.type === "hosted-page" && this.type === "message") {
            return null;
          }
          return distrib;
        })
        .filter((f) => f);

      this.survey_distributions.sort((a, b) => {
        if (a.interaction !== b.interaction) {
          if (a.interaction && !b.interaction) return -1;
          if (!a.interaction && b.interaction) return 1;
          return (
            DistributionInteractions.indexOf(a.interaction) -
            DistributionInteractions.indexOf(b.interaction)
          );
        }
        return channelTypes.indexOf(a.type) - channelTypes.indexOf(b.type);
      });
    }

    return this;
  }

  public getActiveDistributions(): string[] {
    if (!this.survey_distributions) {
      throw new Error(
        "Did not expect to have empty survey.survey_distributions field here.",
      );
    }
    return this.survey_distributions
      .filter((d) => d.enabled === true)
      .map((d) => d.id);
  }
}

export type SurveyType = "survey" | "message";

class SurveyStats extends Serializable {
  constructor(
    // all stats exclude previews
    public response_total?: number, // exclude deleted - should be equal to response_not_deleted
    public response_completion_not_started?: number, // exclude deleted
    public response_completion_partially_completed?: number, // exclude deleted
    public response_completion_fully_completed?: number, // exclude deleted
    public response_rate?: number, // exclude deleted
    public response_completion_rate?: number, // exclude deleted
    public response_deleted?: number,
    public response_not_deleted?: number, // should be equal to response_total
    public response_active?: number, // exclude deleted
    public response_not_active?: number, // exclude deleted
    public response_translation_total?: number,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

class SurveyIntegrations extends Serializable {
  constructor(
    public webhook?: IntegrationSettingsWebhook,
    public cobbai?: IntegrationSettingsCobbai,
    public reports?: IntegrationSettingsReports,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    if (json["webhook"])
      this.webhook = new IntegrationSettingsWebhook().fromJson(json["webhook"]);

    if (json["cobbai"])
      this.cobbai = new IntegrationSettingsCobbai().fromJson(json["cobbai"]);

    return this;
  }
}

export type ShareReportType =
  | "users"
  | "survey-analytics"
  | "all-responses"
  | "all-displays"
  | "content-analysis"
  | "nps"
  | "csat"
  | "ces";

export type ShareRepeatInterval = "no-repeat" | "day" | "week" | "month";

export type IntegrationSettingsReport = {
  kpis: KPIKey[];
  distributions: {
    slack?: {
      channel: string;
    };
    notion?: {
      database_id: string;
      version: string;
    };
    atlassian?: {
      cloud_id: string;
      project_id: string;
    };
    email?: {
      emails: string[];
    };
  };
  repeatInterval: ShareRepeatInterval;
  filters: SurveyResponseFilterGroup;
};

export type IntegrationSettingsReports = {
  [key in ShareReportType]?: IntegrationSettingsReport;
};

export { Survey, SurveyIntegrations, SurveyStats };

export type SurveyResponseFilterGroup = {
  type: "filter_group";
  condition: {
    operator: AnalyticsFiltersOperator;
    children: SurveyResponseFilter[];
  };
};

type SurveyResponseFilter =
  | SurveyResponseFilterKeyword
  | SurveyResponseFilterEmotion
  | SurveyResponseFilterTag
  | SurveyResponseFilterInteraction
  | SurveyResponseFilterAnswerType
  | SurveyResponseFilterAnswer;

type SurveyResponseFilterKeyword = {
  type: "response.keyword";
  condition: {
    operator: "contains"; //| "not_contains";
    keyword: string;
  };
};

type SurveyResponseFilterTag = {
  type: "response.tag";
  condition: {
    operator: "in" | "not_in";
    tags: string[];
  };
};

type SurveyResponseFilterInteraction = {
  type: "response.interaction";
  condition: {
    operator: "in" | "not_in";
    interactions: DistributionInteraction[];
  };
};

type SurveyResponseFilterEmotion = {
  type: "response.emotion";
  condition: {
    operator: "in" | "not_in";
    emotions: Emotion[];
  };
};
export type Emotion = "joy" | "sadness" | "anger" | "fear";

type SurveyResponseFilterAnswerType = {
  type: "response.media_type";
  condition: {
    operator: "in" | "not_in";
    media_types: SurveyResponseMediaType[];
  };
};

type SurveyResponseMediaType = "text" | "audio" | "video";

type SurveyResponseFilterAnswer = {
  type: "response.answer";
  condition: {
    node_id: string;
    operator: "not_null" | "in" | "gt" | "lt" | "contains" | "eq";
    node_correlation_id: string;
    action_correlation_ids?: string[];
    type: CallToAction["type"];
    by: "id" | "correlation_id" | "value";
    answer?: SurveyResponseFilterAnswerConditionAnswer;
  };
};
type SurveyResponseFilterAnswerConditionAnswer = {
  values: SurveyResponseFilterAnswerConditionAnswerValues;
};
type SurveyResponseFilterAnswerConditionAnswerValues = {
  type: "string" | "boolean" | "number" | "time";
  value_string?: string;
  value_boolean?: boolean;
  value_number?: number;
  value_time?: Date;
};

export type SurveyResponseFilterType = SurveyResponseFilter["type"];

export function esFiltersToSurveyResponseFilterGroup(
  filters: AnalyticsFilter[] | null,
  survey: Survey,
  filterOperator: AnalyticsFiltersOperator,
): SurveyResponseFilterGroup {
  const filterGroup: SurveyResponseFilterGroup = {
    type: "filter_group",
    condition: {
      operator: filterOperator,
      children: [],
    },
  };

  filters?.forEach((filter) => {
    if (filter.key === "raw") {
      filterGroup.condition.children.push({
        type: "response.keyword",
        condition: {
          operator: (filter as AnalyticsFilterOperatorContainsOrNotContains)
            .operator,
          keyword: (filter as AnalyticsFilterOperatorContainsOrNotContains)
            .value,
        },
      });
    } else if (filter.key === "tags") {
      filterGroup.condition.children.push({
        type: "response.tag",
        condition: {
          operator: (filter as AnalyticsFilterOperatorIn).operator,
          tags: (filter as AnalyticsFilterOperatorIn).values,
        },
      });
    } else if (filter.key === "interactions") {
      filterGroup.condition.children.push({
        type: "response.interaction",
        condition: {
          operator: (filter as AnalyticsFilterOperatorIn).operator,
          interactions: (filter as AnalyticsFilterOperatorIn)
            .values as DistributionInteraction[],
        },
      });
    } else if (filter.key === "emotions") {
      filterGroup.condition.children.push({
        type: "response.emotion",
        condition: {
          operator: (filter as AnalyticsFilterOperatorIn).operator,
          emotions: (filter as AnalyticsFilterOperatorIn).values as Emotion[],
        },
      });
    } else if (filter.key === "types") {
      filterGroup.condition.children.push({
        type: "response.media_type",
        condition: {
          operator: (filter as AnalyticsFilterOperatorIn).operator,
          media_types: (filter as AnalyticsFilterOperatorIn)
            .values as SurveyResponseMediaType[],
        },
      });
    } else if (filter.type === "response.answer") {
      const node = survey.scenario.nodes.find(
        (n) => n.correlation_id === filter.key,
      );
      const type = node.question.call_to_action.type;

      const by = (filter as unknown as AnalyticsFilterOperatorIn)
        .action_correlation_ids
        ? "correlation_id"
        : (filter as any)?.value
          ? "value"
          : "id";

      let operator = filter.operator;

      if (operator === "preset.gt") {
        operator = "gt";
      } else if (operator === "preset.lt") {
        operator = "lt";
      }

      switch (operator) {
        case "gt":
        case "lt":
          filterGroup.condition.children.push({
            type: "response.answer",
            condition: {
              node_id: node.id,
              operator:
                operator as SurveyResponseFilterAnswer["condition"]["operator"],
              node_correlation_id: filter.key,
              type: type,
              by: by,
              answer: {
                values: {
                  type: "number",
                  value_number: (
                    filter as AnalyticsFilterOperatorGreaterOrLessThan
                  ).value,
                },
              },
            },
          });
          break;
        case "contains":
          filterGroup.condition.children.push({
            type: "response.answer",
            condition: {
              node_id: node.id,
              operator:
                operator as SurveyResponseFilterAnswer["condition"]["operator"],
              node_correlation_id: filter.key,
              type: type,
              by: by,
              answer: {
                values: {
                  type: "string",
                  value_string: (
                    filter as AnalyticsFilterOperatorContainsOrNotContains
                  ).value,
                },
              },
            },
          });
          break;
        case "eq":
          let values: SurveyResponseFilterAnswerConditionAnswerValues;
          if (
            typeof (filter as AnalyticsFilterOperatorEqualOrNotEqual).value ===
            "string"
          ) {
            values = {
              type: "string",
              value_string: (filter as AnalyticsFilterOperatorEqualOrNotEqual)
                .value as string,
            };
          } else if (
            typeof (filter as AnalyticsFilterOperatorEqualOrNotEqual).value ===
            "number"
          ) {
            values = {
              type: "number",
              value_number: (filter as AnalyticsFilterOperatorEqualOrNotEqual)
                .value as number,
            };
          } else if (
            typeof (filter as AnalyticsFilterOperatorEqualOrNotEqual).value ===
            "boolean"
          ) {
            values = {
              type: "boolean",
              value_boolean: (filter as AnalyticsFilterOperatorEqualOrNotEqual)
                .value as boolean,
            };
          }

          filterGroup.condition.children.push({
            type: "response.answer",
            condition: {
              node_id: node.id,
              operator:
                operator as SurveyResponseFilterAnswer["condition"]["operator"],
              node_correlation_id: filter.key,
              type: type,
              by: by,
              answer: {
                values: values,
              },
            },
          });

          break;
        default:
          filterGroup.condition.children.push({
            type: "response.answer",
            condition: {
              node_id: node.id,
              operator:
                operator as SurveyResponseFilterAnswer["condition"]["operator"],
              node_correlation_id: filter.key,
              action_correlation_ids: (
                filter as unknown as AnalyticsFilterOperatorIn
              ).action_correlation_ids,
              type: type,
              by: by,
            },
          });
          break;
      }
    }
  });

  return filterGroup;
}

export function surveyResponseFilterGroupToEsFilters(
  filterGroup: SurveyResponseFilterGroup,
): AnalyticsFilters {
  return filterGroup?.condition?.children?.map((filter) => {
    if (filter.type === "response.keyword") {
      return {
        key: "raw",
        operator: filter.condition.operator,
        value: filter.condition.keyword,
        type: "response",
      } as AnalyticsFilterForResponse;
    } else if (filter.type === "response.tag") {
      return {
        key: "tags",
        operator: filter.condition.operator,
        values: filter.condition.tags,
        type: "response.answer",
      } as AnalyticsFilterForResponse;
    } else if (filter.type === "response.interaction") {
      return {
        key: "interactions",
        operator: filter.condition.operator,
        values: filter.condition.interactions,
        type: "response",
      } as AnalyticsFilterForResponse;
    } else if (filter.type === "response.emotion") {
      return {
        key: "emotions",
        operator: filter.condition.operator,
        values: filter.condition.emotions,
        type: "response",
      } as AnalyticsFilterForResponse;
    } else if (filter.type === "response.media_type") {
      return {
        key: "types",
        operator: filter.condition.operator,
        values: filter.condition.media_types,
        type: "response.answer",
      } as AnalyticsFilterForResponse;
    } else if (filter.type === "response.answer") {
      if (filter.condition.operator === "not_null") {
        return {
          key: filter.condition.node_correlation_id,
          operator: filter.condition.operator,
          type: "response.answer",
        } as AnalyticsFilterForResponse;
      } else if (filter.condition.operator === "in") {
        return {
          key: filter.condition.node_correlation_id,
          operator: filter.condition.operator,
          action_correlation_ids: filter.condition.action_correlation_ids,
          type: "response.answer",
        } as AnalyticsFilterForResponse;
      } else if (filter.condition.operator === "gt") {
        return {
          key: filter.condition.node_correlation_id,
          operator: "preset.gt",
          value: getValueFromAnswer(filter.condition.answer),
          type: "response.answer",
        } as AnalyticsFilterForResponse;
      } else if (filter.condition.operator === "lt") {
        return {
          key: filter.condition.node_correlation_id,
          operator: "preset.lt",
          value: getValueFromAnswer(filter.condition.answer),
          type: "response.answer",
        } as AnalyticsFilterForResponse;
      } else if (filter.condition.operator === "contains") {
        return {
          key: filter.condition.node_correlation_id,
          operator: filter.condition.operator,
          value: getValueFromAnswer(filter.condition.answer),
          type: "response.answer",
        } as AnalyticsFilterForResponse;
      } else if (filter.condition.operator === "eq") {
        return {
          key: filter.condition.node_correlation_id,
          operator: filter.condition.operator,
          type: "response.answer",
          value: getValueFromAnswer(filter.condition.answer),
        } as AnalyticsFilterForResponse;
      }
    }
  });
}

function getValueFromAnswer(answer: SurveyResponseFilterAnswerConditionAnswer) {
  switch (answer.values.type) {
    case "string":
      return answer.values.value_string;
    case "boolean":
      return answer.values.value_boolean;
    case "number":
      return answer.values.value_number;
    case "time":
      return answer.values.value_time;
  }
}

export type Variable = {
  slug: string;
  title: string;
};
