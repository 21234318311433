import { Component, Input } from "@angular/core";

import { OrgBillingInvoice } from "models/org_billing.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";
import {
  NzListComponent,
  NzListItemComponent,
  NzListItemMetaComponent,
  NzListItemMetaTitleComponent,
  NzListEmptyComponent,
} from "ng-zorro-antd/list";
import { NgFor, NgIf } from "@angular/common";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { FormatPipeModule } from "ngx-date-fns";

@Component({
  selector: "billing-lateral-panel-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
  imports: [
    NzListComponent,
    NgFor,
    NzListItemComponent,
    NzListItemMetaComponent,
    NzListItemMetaTitleComponent,
    NgIf,
    NzTagComponent,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NzListEmptyComponent,
    FormatPipeModule,
  ],
})
export class SettingsBillingLateralPanelInvoicesComponent {
  @Input() superOrg: SuperOrg = null;
  @Input() orgBillingInvoices: OrgBillingInvoice[] = null;

  constructor(private superOrgDao: SuperOrgDao) {}

  public getInvoiceURL(invoice: OrgBillingInvoice): string {
    return this.superOrgDao.getInvoiceURL(this.superOrg.id, invoice.id);
  }
}
