import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Org } from "models/org.model";
import { NzModalComponent } from "ng-zorro-antd/modal";
import { SanitizedMessageComponent } from "../../../../../builder/components/Cards/sanitized-message/sanitized-message.component";
import { NzSelectComponent, NzOptionComponent } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { NgFor } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";

@Component({
  selector: "duplicate-survey-modal",
  templateUrl: "./duplicate-survey-modal.component.html",
  styleUrls: ["./duplicate-survey-modal.component.scss"],
  imports: [
    NzModalComponent,
    SanitizedMessageComponent,
    NzSelectComponent,
    FormsModule,
    NgFor,
    NzOptionComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
  ],
})
export class DuplicateSurveyModalComponent {
  @Input() isOpen: boolean = false;
  @Input() orgs: Org[] = [];
  @Input() workspaceId: string = null;
  @Output() workspaceChange: EventEmitter<string> = new EventEmitter();
  @Output() duplicate: EventEmitter<void> = new EventEmitter();
  @Output() modalClose: EventEmitter<void> = new EventEmitter();

  constructor() {}

  handleClose(): void {
    this.isOpen = false;
    this.modalClose.emit();
  }

  onWorkspaceChange(workspaceId: string) {
    this.workspaceId = workspaceId;
    this.workspaceChange.emit(workspaceId);
  }

  onDuplicate() {
    this.duplicate.emit();
    this.handleClose();
  }
}
