import format from "date-fns/format";
import { Serializable } from "models/serializable.model";
import { assert } from "utils/assertion";
import { RegistryEntrySource, RegistryEntrySubject } from "./registry.model";
import { CTAType } from "./survey.dao.types";
import {
  USER_PROPERTY_EMAIL,
  USER_PROPERTY_FIRSTNAME,
  USER_PROPERTY_FIRST_NAME,
  USER_PROPERTY_LASTNAME,
  USER_PROPERTY_LAST_NAME,
  USER_PROPERTY_NAME,
  USER_PROPERTY_USERNAME,
  UserNormalizedPropertyValueType,
  getNameByCascadingProperties,
  getUserMainAlias,
} from "./user.model";
import { UserRecord } from "./user-record.model";

export enum AnalyticsResponseItemResponseSubFieldType {
  Unexpected = -1,
  String = 0,
  Boolean = 1,
  Double = 2,
  Long = 3,
  Time = 4,
  File = 5,
}

export enum AnalyticsResponseItemUserEventType {
  Unexpected = -1,
  Tracking = 0,
  Pageview = 1,
  Screen = 2,
}

export class AnalyticsResponseItemResponseHiddenField2 {
  public subject: RegistryEntrySubject;
  public slug: string;
  public path: string;
  public registry_id: string;
  public type: AnalyticsResponseItemResponseSubFieldType;
  public v_s: string | null;
  public v_b: boolean | null;
  public v_d: number | null;
  public v_l: number | null;
  public v_t: Date | null;

  // public toJson(): any {
  //     return {
  //         subject: this.subject,
  //         slug: this.slug,
  //         path: this.path,
  //         registry_id: this.registry_id,
  //         type: this.type,
  //         v_s: this.v_s,
  //         v_b: this.v_b,
  //         v_d: this.v_d,
  //         v_l: this.v_l,
  //         v_t: this.v_t,
  //     };
  // }

  public static fromJson(
    json: Record<string, any>,
  ): AnalyticsResponseItemResponseHiddenField2 {
    const subject = json.subject;
    const slug = json.slug;
    const path = json.path;
    const registry_id = json.registry_id;
    const type = json.t;
    const v_s = json.v_s;
    const v_b = json.v_b;
    const v_d = json.v_d;
    const v_l = json.v_l;
    const v_t = json.v_t;

    assert(
      subject !== null && subject !== undefined && typeof subject === "string",
      "Response item: Malformed hidden field subject",
    );
    assert(
      slug !== null && slug !== undefined && typeof slug === "string",
      "Response item: Malformed hidden field slug",
    );
    assert(
      path !== null && path !== undefined && typeof path === "string",
      "Response item: Malformed hidden field path",
    );
    assert(
      registry_id !== null &&
        registry_id !== undefined &&
        typeof registry_id === "string",
      "Response item: Malformed hidden field registry id",
    );
    assert(
      type === null || type === undefined || typeof type === "number",
      "Response item: Malformed hidden field type",
    );
    assert(
      v_s === null || v_s === undefined || typeof v_s === "string",
      "Response item: Malformed hidden field string value",
    );
    assert(
      v_b === null || v_b === undefined || typeof v_b === "boolean",
      "Response item: Malformed hidden field boolean value",
    );
    assert(
      v_d === null || v_d === undefined || typeof v_d === "number",
      "Response item: Malformed hidden field double value",
    );
    assert(
      v_l === null || v_l === undefined || typeof v_l === "number",
      "Response item: Malformed hidden field long value",
    );
    assert(
      v_t === null ||
        v_t === undefined ||
        (typeof v_t === "string" && Serializable.isDate(v_t)),
      "Response item: Malformed hidden field time value",
    );

    return Object.assign(new AnalyticsResponseItemResponseHiddenField2(), {
      subject,
      slug,
      path,
      registry_id,
      type: type as AnalyticsResponseItemResponseSubFieldType,
      v_s,
      v_b,
      v_d,
      v_l,
      v_t: v_t !== null && v_t !== undefined ? new Date(v_t) : null,
    } as AnalyticsResponseItemResponseHiddenField2);
  }

  public toText(): string | null {
    // the primary data type is used for user printing
    // secondary data type is mostly for developer things (eg: filtering responses NPS > 5 instead of NPS > :five:)
    switch (this.type) {
      case AnalyticsResponseItemResponseSubFieldType.String:
        return this.v_s;
      case AnalyticsResponseItemResponseSubFieldType.Boolean:
        return "" + this.v_b;
      case AnalyticsResponseItemResponseSubFieldType.Double:
        return "" + this.v_d;
      case AnalyticsResponseItemResponseSubFieldType.Long:
        return "" + this.v_l;
      case AnalyticsResponseItemResponseSubFieldType.Time:
        return format(this.v_t, "PPP p");
      default:
        return null;
    }
  }
}

type EmotionSentiment = "positive" | "negative" | "neutral" | "unknown";

export class AnalyticsResponseItemResponseEmotions {
  public sadness: number | null;
  public anger: number | null;
  public fear: number | null;
  public joy: number | null;

  public sentiment: EmotionSentiment | null;

  public static fromJson(
    json: Record<string, any>,
  ): AnalyticsResponseItemResponseEmotions {
    const sadness = json.sadness;
    const anger = json.anger;
    const fear = json.fear;
    const joy = json.joy;
    const sentiment = json.sentiment || null;

    assert(
      sadness === null ||
        (sadness !== undefined && typeof sadness === "number"),
      "Response item: Malformed emotions sadness property",
    );
    assert(
      anger === null || (anger !== undefined && typeof anger === "number"),
      "Response item: Malformed emotions anger property",
    );
    assert(
      fear === null || (fear !== undefined && typeof fear === "number"),
      "Response item: Malformed emotions fear property",
    );
    assert(
      joy === null || (joy !== undefined && typeof joy === "number"),
      "Response item: Malformed emotions joy property",
    );
    assert(
      sentiment === null ||
        (sentiment !== undefined && typeof sentiment === "string"),
      "Response item: Malformed emotions sentiment property",
    );

    return Object.assign(new AnalyticsResponseItemResponseEmotions(), {
      sadness,
      anger,
      fear,
      joy,
      sentiment: sentiment as EmotionSentiment,
    } as AnalyticsResponseItemResponseEmotions);
  }
}

export enum ResponseAnswerValueFileType {
  None = "none",
  Audio = "audio",
  Video = "video",
}

export class ResponseAnswerValueFile {
  public id: string;
  public type: ResponseAnswerValueFileType;

  public static fromJson(json: Record<string, any>): ResponseAnswerValueFile {
    if (!json) {
      return null;
    }

    return Object.assign(new ResponseAnswerValueFile(), {
      id: json.id,
      type: json.type as ResponseAnswerValueFileType,
    } as ResponseAnswerValueFile);
  }
}

export type ResponseAnswerValueTranslation = {
  lang: string;
  text: string;
};

export class AnalyticsResponseItemResponseAnswer {
  public cta_type: CTAType;
  public key: string; // @DEPRECATED
  public question_id: string;
  public question_correlation_id: string;
  public action_id: string;
  public action_correlation_id: string;
  public answer_id: string;
  public type: AnalyticsResponseItemResponseSubFieldType;
  public time: Date | null;
  public lang: string | null;
  public tags: string[] | null;
  public translations: Array<ResponseAnswerValueTranslation> | null;
  public v_s: string | null;
  public v_b: boolean | null;
  public v_d: number | null;
  public v_l: number | null;
  public v_t: Date | null;
  public v_f: ResponseAnswerValueFile | null;

  // public toJson(): any {
  //     return {
  //         cta_type: this.key,
  //         key: this.key, // @DEPRECATED
  //         question_id: this.question_id,
  //         question_correlation_id: this.question_correlation_id,
  //         action_id: this.action_id,
  //         action_correlation_id: this.action_correlation_id,
  //         answer_id: this.answer_id,
  //         type: this.type,
  //         time: this.time,
  //         v_s: this.v_s,
  //         v_b: this.v_b,
  //         v_d: this.v_d,
  //         v_l: this.v_l,
  //         v_t: this.v_t,
  //     };
  // }

  public static fromJson(
    json: Record<string, any>,
  ): AnalyticsResponseItemResponseAnswer {
    const cta_type = json.cta_type;
    const key = json.key; // @DEPRECATED
    const question_id = json.question_id;
    const question_correlation_id = json.question_correlation_id;
    const action_id = json.action_id;
    const action_correlation_id = json.action_correlation_id;
    const answer_id = json.answer_id;
    const lang = json.lang;
    const tags = json.tags;
    const translations = json.translations;
    const type = json.t;
    const time = json.time;
    const v_s = json.v_s;
    const v_b = json.v_b;
    const v_d = json.v_d;
    const v_l = json.v_l;
    const v_t = json.v_t;
    const v_f = ResponseAnswerValueFile.fromJson(json.v_f);

    assert(
      cta_type !== null &&
        cta_type !== undefined &&
        typeof cta_type === "string",
      "Response item: Malformed CTA type",
    );
    assert(
      key !== null && key !== undefined && typeof key === "string",
      "Response item: Malformed answer property name",
    ); // @DEPRECATED
    assert(
      question_id !== null &&
        question_id !== undefined &&
        typeof question_id === "string",
      "Response item: Malformed answer question id",
    );
    assert(
      question_correlation_id !== null &&
        question_correlation_id !== undefined &&
        typeof question_correlation_id === "string",
      "Response item: Malformed answer question correlation id",
    );
    assert(
      action_id !== null &&
        action_id !== undefined &&
        typeof action_id === "string",
      "Response item: Malformed answer action id",
    );
    assert(
      action_correlation_id !== null &&
        action_correlation_id !== undefined &&
        typeof action_correlation_id === "string",
      "Response item: Malformed answer action correlation id",
    );
    assert(
      answer_id !== null &&
        answer_id !== undefined &&
        typeof answer_id === "string",
      "Response item: Malformed answer answer id",
    );

    assert(
      lang === null || typeof lang === "string",
      "Response item: Malformed answer lang",
    );

    // assert(
    //   tags !== null &&
    //     tags !== undefined &&
    //     translations instanceof Object &&
    //     Array.isArray(tags),
    //   "Response item: Malformed answer tags",
    // );

    assert(
      translations !== null &&
        translations !== undefined &&
        translations instanceof Object &&
        Array.isArray(translations),
      "Response item: Malformed answer translations",
    );
    assert(
      type === null || type === undefined || typeof type === "number",
      "Response item: Malformed answer type",
    );
    assert(
      time === null ||
        time === undefined ||
        (typeof time === "string" && Serializable.isDate(time)),
      "Response item: Malformed answer date",
    );
    assert(
      v_s === null || v_s === undefined || typeof v_s === "string",
      "Response item: Malformed answer string value",
    );
    assert(
      v_b === null || v_b === undefined || typeof v_b === "boolean",
      "Response item: Malformed answer boolean value",
    );
    assert(
      v_d === null || v_d === undefined || typeof v_d === "number",
      "Response item: Malformed answer double value",
    );
    assert(
      v_l === null || v_l === undefined || typeof v_l === "number",
      "Response item: Malformed answer long value",
    );
    assert(
      v_t === null ||
        v_t === undefined ||
        (typeof v_t === "string" && Serializable.isDate(v_t)),
      "Response item: Malformed answer time value",
    );

    assert(
      v_f === null || v_f === undefined || typeof v_f === "object",
      "Response item: Malformed answer file value",
    );

    return Object.assign(new AnalyticsResponseItemResponseAnswer(), {
      cta_type: cta_type as CTAType,
      key, // @DEPRECATED
      question_id,
      question_correlation_id,
      action_id,
      action_correlation_id,
      answer_id,
      lang,
      tags,
      translations,
      type: type as AnalyticsResponseItemResponseSubFieldType,
      time: time !== null && time !== undefined ? new Date(time) : null,
      v_s,
      v_b,
      v_d,
      v_l,
      v_t: v_t !== null && v_t !== undefined ? new Date(v_t) : null,
      v_f,
    } as AnalyticsResponseItemResponseAnswer);
  }

  private toTextTranslated(lang: string): string | null {
    switch (this.type) {
      case AnalyticsResponseItemResponseSubFieldType.String:
        return this.translations.find((t) => t.lang === lang)?.text ?? this.v_s;
      case AnalyticsResponseItemResponseSubFieldType.File:
        return this.v_s
          ? (this.translations.find((t) => t.lang === lang)?.text ?? this.v_s)
          : "Transcription not available yet.";
      default:
        return null;
    }
  }

  public toText(translation: string | null = null): string | null {
    // the primary data type is used for user printing
    // secondary data type is mostly for developer things (eg: filtering responses NPS > 5 instead of NPS > :five:)
    switch (this.type) {
      case AnalyticsResponseItemResponseSubFieldType.String:
        return translation ? this.toTextTranslated(translation) : this.v_s;
      case AnalyticsResponseItemResponseSubFieldType.Boolean:
        return "" + this.v_b;
      case AnalyticsResponseItemResponseSubFieldType.Double:
        return "" + this.v_d;
      case AnalyticsResponseItemResponseSubFieldType.Long:
        return "" + this.v_l;
      case AnalyticsResponseItemResponseSubFieldType.Time:
        return format(this.v_t, "PPP p");
      case AnalyticsResponseItemResponseSubFieldType.File:
        return this.v_s
          ? translation
            ? this.toTextTranslated(translation)
            : this.v_s
          : "Transcription not available yet.";
      default:
        return null;
    }
  }
}

export class AnalyticsResponseItemUserProperty {
  public cta_type: CTAType;
  public key: string;
  public key_name: string;
  public type: AnalyticsResponseItemResponseSubFieldType;
  public time: Date | null;
  public v_s: string | null;
  public v_b: boolean | null;
  public v_d: number | null;
  // public v_l: number | null;
  public v_t: Date | null;

  // public toJson(): any {
  //     return {
  //         key: this.key,
  //         key_name: this.key_name,
  //         type: this.type,
  //         time: this.time,
  //         v_s: this.v_s,
  //         v_b: this.v_b,
  //         v_d: this.v_d,
  //         // v_l: this.v_l,
  //         v_t: this.v_t,
  //     };
  // }

  public static fromJson(
    json: Record<string, any>,
  ): AnalyticsResponseItemUserProperty {
    const key = json.key ?? json.k;
    const key_name = json.key_name ?? json.k_n;
    const type = json.t ?? json.t;
    const time = json.time;
    const v_s = json.v_s;
    const v_b = json.v_b;
    const v_d = json.v_d;
    // const v_l = json.v_l;
    const v_t = json.v_t;

    assert(
      key !== null && key !== undefined && typeof key === "string",
      "User item: Malformed property key",
    );
    assert(
      key_name !== null && key !== undefined && typeof key_name === "string",
      "User item: Malformed property name",
    );
    assert(
      type === null || type === undefined || typeof type === "number",
      "User item: Malformed property type",
    );
    assert(
      time === null ||
        time === undefined ||
        (typeof time === "string" && Serializable.isDate(time)),
      "User item: Malformed property date",
    );
    assert(
      v_s === null || v_s === undefined || typeof v_s === "string",
      "User item: Malformed string value",
    );
    assert(
      v_b === null || v_b === undefined || typeof v_b === "boolean",
      "User item: Malformed property boolean value",
    );
    assert(
      v_d === null || v_d === undefined || typeof v_d === "number",
      "User item: Malformed property double value",
    );
    assert(
      v_t === null ||
        v_t === undefined ||
        (typeof v_t === "string" && Serializable.isDate(v_t)),
      "User item: Malformed property time value",
    );
    // assert(v_l === null || v_l == undefined || typeof (v_l) === 'number', 'User item: Malformed property long value');

    return Object.assign(new AnalyticsResponseItemUserProperty(), {
      key,
      key_name,
      type: type as AnalyticsResponseItemResponseSubFieldType,
      time: time !== null && time !== undefined ? new Date(time) : null,
      v_s,
      v_b,
      v_d,
      // v_l,
      v_t: v_t !== null && v_t !== undefined ? new Date(v_t) : null,
    } as AnalyticsResponseItemUserProperty);
  }

  public toText(): string | null {
    // the primary data type is used for user printing
    // secondary data type is mostly for developer things (eg: filtering responses NPS > 5 instead of NPS > :five:)
    switch (this.type) {
      case AnalyticsResponseItemResponseSubFieldType.String:
        return this.v_s;
      case AnalyticsResponseItemResponseSubFieldType.Boolean:
        return "" + this.v_b;
      case AnalyticsResponseItemResponseSubFieldType.Double:
        return "" + this.v_d;
      // case AnalyticsResponseItemResponseSubFieldType.Long:
      //     return '' + this.v_l;
      case AnalyticsResponseItemResponseSubFieldType.Time:
        return format(this.v_t, "PPP p");
      default:
        return null;
    }
  }
}

export class AnalyticsResponseItemUserEvent {
  public key: string;
  public key_name: string;
  public type: AnalyticsResponseItemUserEventType;
  public count: number;
  public first_at: Date;
  public last_at: Date;

  // public toJson(): any {
  //     return {
  //         key: this.key,
  //         key_name: this.key_name,
  //         type: this.type,
  //         count: this.count,
  //         first_at: this.first_at,
  //         last_at: this.last_at,
  //     };
  // }

  public static fromJson(json: object): AnalyticsResponseItemUserEvent {
    const key = json["key"] ?? json["k"];
    const key_name = json["key_name"] ?? json["k_n"];
    const type = json["type"] ?? json["t"];
    const count = json["count"] ?? json["c"];
    const first_at = json["first_at"] ?? json["f_at"];
    const last_at = json["last_at"] ?? json["l_at"];

    assert(
      key !== null && key !== undefined && typeof key === "string",
      "User item: Malformed event key",
    );
    assert(
      key_name !== null &&
        key_name !== undefined &&
        typeof key_name === "string",
      "User item: Malformed event name",
    );
    assert(
      type === null || type === undefined || typeof type === "number",
      "User item: Malformed subfield type",
    );
    assert(
      count !== null && count !== undefined && typeof count === "number",
      "User item: Malformed event count",
    );
    assert(
      first_at !== null &&
        first_at !== undefined &&
        typeof first_at === "string" &&
        Serializable.isDate(first_at),
      "User item: Malformed event date",
    );
    assert(
      last_at !== null &&
        last_at !== undefined &&
        typeof last_at === "string" &&
        Serializable.isDate(last_at),
      "User item: Malformed event date",
    );

    return Object.assign(new AnalyticsResponseItemUserEvent(), {
      key,
      key_name,
      type: type as AnalyticsResponseItemUserEventType,
      count,
      first_at: new Date(first_at),
      last_at: new Date(last_at),
    } as AnalyticsResponseItemUserEvent);
  }
}

export class AnalyticsResponseItemResponse {
  public id: string;
  public org_id: string;
  public channel_id: string;
  public respondent_id: string;
  public respondent_aliases: string[];
  public survey_id: string;
  public scenario_id: string;
  public source: RegistryEntrySource;
  public language: string;
  public locale: string;
  public question_id_path: string[];
  public question_correlation_id_path: string[];
  public action_id_path: string[];
  public action_correlation_id_path: string[];
  public hidden_fields2: AnalyticsResponseItemResponseHiddenField2[];
  public emotions: AnalyticsResponseItemResponseEmotions;
  public answers: AnalyticsResponseItemResponseAnswer[];
  public completion: string;
  public active: boolean;
  public end_reason: string | null;
  public ended_at: Date | null;
  public created_at: Date;
  public updated_at: Date;
  public last_answer_at: Date | null;
  public expired_at: Date | null;

  public static fromJson(
    json: Record<string, any>,
  ): AnalyticsResponseItemResponse {
    const id = json.id;
    const org_id = json.org_id;
    const channel_id = json.channel_id;
    const respondent_id = json.respondent_id;
    const respondent_aliases = json.respondent_aliases;
    const survey_id = json.survey_id;
    const scenario_id = json.scenario_id;
    const source = json.source;
    const language = json.language;
    const locale = json.locale;
    const question_id_path = json.question_id_path;
    const question_correlation_id_path = json.question_correlation_id_path;
    const action_id_path = json.action_id_path;
    const action_correlation_id_path = json.action_correlation_id_path;
    const hidden_fields2 = json.hidden_fields2;
    const emotions = json.emotions;
    const answers = json.answers;
    const completion = json.completion;
    const active = json.active;
    const end_reason = json.end_reason;
    const ended_at = json.ended_at;
    const created_at = json.created_at;
    const updated_at = json.updated_at;
    const last_answer_at = json.last_answer_at;
    const expired_at = json.expired_at;

    assert(
      id !== null &&
        id !== undefined &&
        typeof id === "string" &&
        id.length === 36,
      "Response item: Malformed id",
    );
    assert(
      org_id !== null &&
        org_id !== undefined &&
        typeof org_id === "string" &&
        org_id.length === 36,
      "Response item: Malformed org id",
    );
    assert(
      channel_id !== null &&
        channel_id !== undefined &&
        typeof channel_id === "string" &&
        channel_id.length === 36,
      "Response item: Malformed channel id",
    );
    assert(
      respondent_id !== null &&
        respondent_id !== undefined &&
        typeof respondent_id === "string" &&
        respondent_id.length === 36,
      "Response item: Malformed user id",
    );
    assert(
      respondent_aliases !== null &&
        respondent_aliases !== undefined &&
        Array.isArray(respondent_aliases),
      "Response item: Malformed user visitor aliases",
    );
    assert(
      survey_id !== null &&
        survey_id !== undefined &&
        typeof survey_id === "string" &&
        survey_id.length === 36,
      "Response item: Malformed survey id",
    );
    assert(
      scenario_id !== null &&
        scenario_id !== undefined &&
        typeof scenario_id === "string" &&
        scenario_id.length === 36,
      "Response item: Malformed scenario id",
    );
    assert(
      source !== null && source !== undefined && typeof source === "string",
      "Response item: Malformed source",
    );
    assert(
      language !== null &&
        language !== undefined &&
        typeof language === "string" &&
        ((language.length >= 2 && language.length <= 4) ||
          language === "unknown"),
      "Response item: Malformed language",
    );
    assert(
      locale !== null &&
        locale !== undefined &&
        typeof locale === "string" &&
        ((locale.length >= 2 && locale.length <= 20) || language === "unknown"),
      "Response item: Malformed locale",
    );
    assert(
      question_id_path !== null &&
        question_id_path !== undefined &&
        typeof question_id_path === "string",
      "Response item: Malformed question_id_path",
    );
    assert(
      question_correlation_id_path !== null &&
        question_correlation_id_path !== undefined &&
        typeof question_correlation_id_path === "string",
      "Response item: Malformed question_correlation_id_path",
    );
    assert(
      action_id_path !== null &&
        action_id_path !== undefined &&
        typeof action_id_path === "string",
      "Response item: Malformed action_id_path",
    );
    assert(
      action_correlation_id_path !== null &&
        action_correlation_id_path !== undefined &&
        typeof action_correlation_id_path === "string",
      "Response item: Malformed action_correlation_id_path",
    );
    assert(
      hidden_fields2 !== null &&
        hidden_fields2 !== undefined &&
        Array.isArray(hidden_fields2),
      "Response item: Malformed hidden field2",
    );
    assert(
      emotions !== null &&
        emotions !== undefined &&
        typeof emotions === "object" &&
        !Array.isArray(emotions),
      "Response item: Malformed emotions",
    );
    assert(
      answers !== null && answers !== undefined && Array.isArray(answers),
      "Response item: Malformed answer",
    );
    assert(
      completion !== null &&
        completion !== undefined &&
        typeof completion === "string" &&
        [
          "inserted",
          "not_started",
          "partially_completed",
          "fully_completed",
        ].includes(completion),
      "Response item: Malformed completion",
    );
    assert(
      active !== null && active !== undefined && typeof active === "boolean",
      "Response item: Malformed active state",
    );
    assert(
      end_reason === null ||
        end_reason === undefined ||
        (typeof end_reason === "string" &&
          ["completed", "closed", "expired", "interrupted", "deleted"].includes(
            end_reason,
          )),
      "Response item: Malformed end reason",
    );
    assert(
      ended_at === null ||
        ended_at === undefined ||
        (typeof ended_at === "string" && Serializable.isDate(ended_at)),
      "Response item: Malformed end date",
    );
    assert(
      created_at !== null &&
        created_at !== undefined &&
        typeof created_at === "string" &&
        Serializable.isDate(created_at),
      "Response item: Malformed create date",
    );
    assert(
      updated_at !== null &&
        updated_at !== undefined &&
        typeof updated_at === "string" &&
        Serializable.isDate(updated_at),
      "Response item: Malformed update date",
    );
    assert(
      last_answer_at === null ||
        last_answer_at === undefined ||
        (typeof last_answer_at === "string" &&
          Serializable.isDate(last_answer_at)),
      "Response item: Malformed last answer date",
    );
    assert(
      expired_at === null ||
        expired_at === undefined ||
        (typeof expired_at === "string" && Serializable.isDate(expired_at)),
      "Response item: Malformed expiration date",
    );

    return Object.assign(new AnalyticsResponseItemResponse(), {
      id,
      org_id,
      channel_id,
      respondent_id,
      respondent_aliases,
      survey_id,
      scenario_id,
      source: source as RegistryEntrySource,
      language,
      locale,
      question_id_path: question_id_path.split("#"),
      question_correlation_id_path: question_correlation_id_path.split("#"),
      action_id_path: action_id_path.split("#"),
      action_correlation_id_path: action_correlation_id_path.split("#"),
      hidden_fields2: hidden_fields2.map(
        AnalyticsResponseItemResponseHiddenField2.fromJson,
      ),
      emotions: AnalyticsResponseItemResponseEmotions.fromJson(emotions),
      answers: answers.map(AnalyticsResponseItemResponseAnswer.fromJson),
      completion,
      active,
      end_reason,
      ended_at:
        ended_at !== null && ended_at !== undefined ? new Date(ended_at) : null,
      created_at: new Date(created_at),
      updated_at: new Date(updated_at),
      last_answer_at:
        last_answer_at !== null && last_answer_at !== undefined
          ? new Date(last_answer_at)
          : null,
      expired_at:
        expired_at !== null && expired_at !== undefined
          ? new Date(expired_at)
          : null,
    } as AnalyticsResponseItemResponse);
  }

  public toJson(): any {
    return {
      id: this.id,
      org_id: this.org_id,
      channel_id: this.channel_id,
      respondent_id: this.respondent_id,
      respondent_aliases: this.respondent_aliases,
      survey_id: this.survey_id,
      scenario_id: this.scenario_id,
      source: this.source,
      language: this.language,
      locale: this.locale,
      question_id_path: this.question_id_path,
      question_correlation_id_path: this.question_correlation_id_path,
      action_id_path: this.action_id_path,
      action_correlation_id_path: this.action_correlation_id_path,
      hidden_fields2: Object.fromEntries(
        this.hidden_fields2.map((i) => [
          i.registry_id,
          {
            t: "string",
            v: i.toText(),
          },
        ]),
      ),
      emotions: this.emotions,
      answers: this.answers,
      completion: this.completion,
      active: this.active,
      end_reason: this.end_reason,
      ended_at: this.ended_at,
      created_at: this.created_at,
      updated_at: this.updated_at,
      last_answer_at: this.last_answer_at,
      expired_at: this.expired_at,
    };
  }

  public getAnswers(
    questionCorrelationId: string,
  ): AnalyticsResponseItemResponseAnswer[] {
    const longestAnswer = this.answers.filter(
      (a: AnalyticsResponseItemResponseAnswer) =>
        a.key === questionCorrelationId,
    )[0];

    if (!longestAnswer) {
      return [];
    }

    return this.answers.filter(
      (a: AnalyticsResponseItemResponseAnswer) =>
        a.question_correlation_id === longestAnswer.question_correlation_id,
    );
  }
  public getAnswersToText(questionCorrelationId: string): string | null {
    return this.getAnswers(questionCorrelationId)
      ?.map((a) => a.toText())
      .join(", ");
  }

  public getHiddenFieldByRegistryId(
    registry_id: string,
  ): AnalyticsResponseItemResponseHiddenField2 | null {
    return this.hidden_fields2.find(
      (a: AnalyticsResponseItemResponseHiddenField2) =>
        a.registry_id === registry_id,
    );
  }
  public getHiddenFieldByPath(
    path: string,
  ): AnalyticsResponseItemResponseHiddenField2 | null {
    return this.hidden_fields2.find(
      (a: AnalyticsResponseItemResponseHiddenField2) => a.path === path,
    );
  }
  public getHiddenField2ToText(registryId: string): string | null {
    return this.getHiddenFieldByRegistryId(registryId)?.toText();
  }
  public getAvatarURL(): string | null {
    return this.getHiddenFieldByPath("avatar")?.v_s;
  }
}

export class AnalyticsResponseItemUser {
  public id: string;
  public org_id: string;
  public aliases: string[];
  public is_anonymous: boolean;
  public properties: AnalyticsResponseItemUserProperty[];
  public events: AnalyticsResponseItemUserEvent[];
  public events_count: number;
  public created_at: Date;
  public updated_at: Date;
  public last_activity_at: Date;
  public group_ids: string[] = [];

  public get isActive(): boolean {
    return +new Date() - +this.last_activity_at <= 5 * 60 * 1000;
  }

  public toJson(): any {
    return {
      id: this.id,
      org_id: this.org_id,
      aliases: this.aliases,
      is_anonymous: this.is_anonymous,
      properties: Object.fromEntries(
        this.properties.map((property) => [
          property.key_name,
          {
            t: "string",
            v: property.toText(),
          },
        ]),
      ),
      events: Object.fromEntries(
        this.events.map((event) => [
          event.key_name,
          { t: event.type, v: event.count },
        ]),
      ),
      events_count: this.events_count,
      created_at: this.created_at,
      updated_at: this.updated_at,
      last_activity_at: this.last_activity_at,
      group_ids: this.group_ids,
    };
  }

  public static fromJson(json: object): AnalyticsResponseItemUser {
    const id = json["id"];
    const org_id = json["org_id"] ?? json["o_id"];
    const aliases = json["aliases"] ?? json["a"];
    const is_anonymous = json["is_anonymous"] ?? json["is_a"];
    const properties = json["properties"] ?? json["p"];
    const events = json["events"] ?? json["e"];
    const events_count = json["events_count"] ?? json["e_c"];
    const created_at = json["created_at"] ?? json["c_at"];
    const updated_at = json["updated_at"] ?? json["u_at"];
    const last_activity_at = json["last_activity_at"] ?? json["la_at"];
    const group_ids = json["group_ids"] ?? json["g_ids"];

    assert(
      id !== null &&
        id !== undefined &&
        typeof id === "string" &&
        id.length === 36,
      "User item: Malformed id",
    );
    assert(
      org_id !== null &&
        org_id !== undefined &&
        typeof id === "string" &&
        id.length === 36,
      "User item: Malformed id",
    );
    assert(
      aliases !== null && aliases !== undefined && Array.isArray(aliases),
      "User item: Malformed user aliases",
    );
    assert(
      is_anonymous !== null && is_anonymous !== undefined,
      "User item: Malformed user is_anonymous",
    );
    assert(
      properties !== null &&
        properties !== undefined &&
        Array.isArray(properties),
      "User item: Malformed properties",
    );
    assert(
      events !== null && events !== undefined && Array.isArray(events),
      "User item: Malformed events",
    );
    assert(
      events_count !== null &&
        events_count !== undefined &&
        typeof events_count === "number",
      "User item: Malformed events count",
    );
    assert(
      created_at !== null &&
        created_at !== undefined &&
        typeof created_at === "string" &&
        Serializable.isDate(created_at),
      "User item: Malformed create date",
    );
    assert(
      updated_at !== null &&
        updated_at !== undefined &&
        typeof updated_at === "string" &&
        Serializable.isDate(updated_at),
      "User item: Malformed update date",
    );
    assert(
      last_activity_at === null ||
        last_activity_at === undefined ||
        (typeof last_activity_at === "string" &&
          Serializable.isDate(last_activity_at)),
      "User item: Malformed last activity date",
    );
    assert(
      group_ids !== null && group_ids !== undefined && Array.isArray(group_ids),
      "User item: Malformed group ids",
    );

    return Object.assign(new AnalyticsResponseItemUser(), {
      id,
      org_id,
      aliases: aliases,
      is_anonymous: is_anonymous,
      properties: properties?.map(AnalyticsResponseItemUserProperty.fromJson),
      events: events?.map(AnalyticsResponseItemUserEvent.fromJson),
      events_count,
      created_at: new Date(created_at),
      updated_at: new Date(updated_at),
      last_activity_at: new Date(last_activity_at),
      group_ids: group_ids,
    } as AnalyticsResponseItemUser);
  }

  public getAvatarURL(): string | null {
    return this.properties.find(
      (p: AnalyticsResponseItemUserProperty) => p.key_name === "avatar",
    )?.v_s;
  }
}

export class AnalyticsResponse {
  public took: number; // ms
  public hits: {
    total: number;
    respondents: AnalyticsResponseItemUser[] | null;
    responses: AnalyticsResponseItemResponse[] | null;
    sessions: UserRecord[] | null;
    suggested_session_ids: string[] | null;
  };
  public aggregations: Record<string, any>;

  public static fromJson(json: Record<string, any>): AnalyticsResponse {
    const took = json.took;
    const total = json.hits.total.value;
    const users = json.hits.respondents?.map(
      AnalyticsResponseItemUser.fromJson,
    );
    const responses = json.hits.responses?.map(
      AnalyticsResponseItemResponse.fromJson,
    );
    const sessions = json.hits.sessions?.map((r) =>
      new UserRecord().fromJson(r),
    );
    const suggested_session_ids = json.hits.suggested_session_ids;
    const aggregations = json.aggregations;

    assert(
      took !== null && took !== undefined && typeof took === "number",
      "Response item: Malformed query time",
    );
    assert(
      total !== null && total !== undefined && typeof total === "number",
      "Response item: Malformed total number of items",
    );
    assert(
      users === null || users === undefined || Array.isArray(users),
      "Response item: Malformed user item",
    );
    assert(
      responses === null || responses === undefined || Array.isArray(responses),
      "Response item: Malformed response item",
    );
    assert(
      sessions === null || sessions === undefined || Array.isArray(sessions),
      "Response item: Malformed session item",
    );
    assert(
      aggregations === null ||
        aggregations === undefined ||
        typeof aggregations === "object",
      "Response item: Malformed aggregation",
    );

    return {
      took,
      hits: {
        total,
        respondents: users,
        responses,
        sessions,
        suggested_session_ids,
      },
      aggregations,
    };
  }
}

const getUserProperty = (
  user: AnalyticsResponseItemUser,
  propertyKey: string,
): UserNormalizedPropertyValueType =>
  user.properties.find(
    (a) => a.key_name.toLowerCase() === propertyKey.toLowerCase(),
  )?.v_s;

// @TODO: this is really messy, refacto this with a proper API and maybe merge some types between analytics and this
export function getFormattedUserNameOrID(
  user: AnalyticsResponseItemUser,
): string {
  const firstName =
    getUserProperty(user, USER_PROPERTY_FIRST_NAME) ??
    getUserProperty(user, USER_PROPERTY_FIRSTNAME);
  const lastName =
    getUserProperty(user, USER_PROPERTY_LAST_NAME) ??
    getUserProperty(user, USER_PROPERTY_LASTNAME);

  const name = getUserProperty(user, USER_PROPERTY_NAME);
  const username = getUserProperty(user, USER_PROPERTY_USERNAME);
  const email = getUserProperty(user, USER_PROPERTY_EMAIL);
  const mainAlias = getUserMainAlias(user.id, user.aliases);

  return getNameByCascadingProperties({
    firstName,
    lastName,
    name,
    username,
    email,
    alias: mainAlias,
    id: user.id,
  });
}

const getHiddenField = (
  response: AnalyticsResponseItemResponse,
  path: string,
): UserNormalizedPropertyValueType =>
  response.hidden_fields2.find(
    (a) => a.path.toLowerCase() === path.toLowerCase(),
  )?.v_s;

export function getFormatedUserFromResponse(
  response: AnalyticsResponseItemResponse,
): string {
  const firstName =
    getHiddenField(response, USER_PROPERTY_FIRST_NAME) ??
    getHiddenField(response, USER_PROPERTY_FIRSTNAME);
  const lastName =
    getHiddenField(response, USER_PROPERTY_LAST_NAME) ??
    getHiddenField(response, USER_PROPERTY_LASTNAME);

  const name = getHiddenField(response, USER_PROPERTY_NAME);
  const username = getHiddenField(response, USER_PROPERTY_USERNAME);
  const email = getHiddenField(response, USER_PROPERTY_EMAIL);
  const mainAlias = getUserMainAlias(
    response.respondent_id,
    response.respondent_aliases,
  );

  return getNameByCascadingProperties({
    firstName,
    lastName,
    name,
    username,
    email,
    alias: mainAlias,
    id: response.respondent_id,
  });
}
