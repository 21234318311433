import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
} from "@angular/core";
import { ScreebIcon, ScreebIconSize } from "./screeb-icon.types";

@Component({
  selector: "screeb-icon",
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: inline-flex;
      }
    `,
  ],
})
export class ScreebIconComponent implements OnInit {
  @Input() public size: ScreebIconSize;
  @Input() public icon: ScreebIcon;

  constructor(private host: ElementRef<HTMLDivElement>) {}

  ngOnInit(): void {
    this.resize();
    this.fetchSvg();
  }

  resize() {
    const size = this.size === "sm" ? 16 : this.size === "lg" ? 40 : 24;

    this.host.nativeElement.style.width = `${size}px`;
    this.host.nativeElement.style.height = `${size}px`;
  }

  async fetchSvg() {
    const response = await fetch(
      `/assets/icons/${this.size}/${this.icon}.svg`,
      {
        cache: "force-cache",
      },
    );
    const svg = await response.text();
    this.host.nativeElement.innerHTML = svg;
  }
}
