/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { BuilderLateralPanelEmojiPickerConfig } from "components/builder/components/LateralPanel/builder-lateral-panel.component";
import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  ActionButton,
  ActionSkip,
  CTALink,
  I18nTextLabel,
  SurveyLanguages,
  UUID,
  getI18nTextLabelTranslation,
  newCTASkip,
  setI18nTextLabelTranslation,
} from "models/survey.dao.types";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";
import { randomString } from "utils/random";
import { uuidv4 } from "utils/uuid";
import { QuestionValidatorFactory } from "../../BuilderLayout/Validators/CardValidator";
import { NgFor, NgIf, NgClass } from "@angular/common";
import { SanitizedMessageComponent } from "../../Cards/sanitized-message/sanitized-message.component";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { FormsModule } from "@angular/forms";
import { LateralPanelStepPickerComponent } from "../step-picker/step-picker.component";
import { FormErrorComponent } from "../../../../../utils/form-error/form-error.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { NzSwitchComponent } from "ng-zorro-antd/switch";

const RANDOM_EMOJI = ["💪", "🥰", "😎", "🥳", "🤯", "😝", "👏", "🤘", "👌"];

@Component({
  selector: "lateral-panel-edit-link",
  templateUrl: "./edit-link.component.html",
  styleUrls: ["./edit-link.component.scss"],
  imports: [
    NgFor,
    NgIf,
    SanitizedMessageComponent,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    FormsModule,
    NgClass,
    LateralPanelStepPickerComponent,
    FormErrorComponent,
    NzButtonComponent,
    NzWaveDirective,
    NzSwitchComponent,
  ],
})
export class LateralPanelEditLinkComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() public language: SurveyLanguages = "en";
  @Output() onOptionAdded = new EventEmitter();
  @Output() onEmojiPickerOpen =
    new EventEmitter<BuilderLateralPanelEmojiPickerConfig>();
  @Output() errorChange = new EventEmitter<boolean>();

  public labelErrors: string[] = [];
  public linkErrors: string[] = [];

  public uniqHash: string;

  constructor(
    public builderStore: BuilderStore,
    public entitlementService: EntitlementService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public ngOnInit() {
    this.uniqHash = randomString(5);
    this.validateData();
  }

  public getError(ctaIndex: number): string[] {
    return [
      ...(this.labelErrors[ctaIndex] ? [this.labelErrors[ctaIndex]] : []),
      ...(this.linkErrors[ctaIndex] ? [this.linkErrors[ctaIndex]] : []),
    ];
  }

  public get cta(): CTALink {
    const cta = this.node.node.question.call_to_action;
    if (cta.type !== "link") throw Error("unexpected action type for link");
    return cta;
  }

  public get skipAction(): ActionSkip {
    return (this.node.node.question.skip_action ??= newCTASkip());
  }

  /**
   * Data validation
   */

  public validateData() {
    const { errors, warnings } = QuestionValidatorFactory.getValidatorFromNode(
      this.node,
    )(
      this.node,
      this.language,
      this.builderStore.survey.scenario.default_language,
    );

    if (errors.length === 2) [this.labelErrors, this.linkErrors] = errors;
    else [this.labelErrors, this.linkErrors] = warnings;

    this.errorChange.emit(
      this.labelErrors.length > 0 || this.linkErrors.length > 0,
    );
  }

  public addOption() {
    if (this.node.isPreset) {
      throw Error("cannot add multiple choices in preset");
    }

    // default values
    const emoji = this.getRandomEmoji();
    const label = {} as I18nTextLabel;
    const link = {} as I18nTextLabel;
    setI18nTextLabelTranslation(label, "Your answer", this.language);
    setI18nTextLabelTranslation(link, "", this.language);

    this.cta.choices.push({
      id: uuidv4() as UUID,
      correlation_id: uuidv4() as UUID,
      type: "button",
      payload: {
        emoji,
        label,
        url: link,
      },
    });

    this.onOptionAdded.emit();
    this.validateData();
  }

  public removeOption(index: number) {
    if (this.node.isPreset) {
      throw Error("cannot add multiple choices in preset");
    }

    if (this.cta.choices.length <= 1) {
      return;
    }
    this.cta.choices.splice(index, 1);

    this.validateData();
  }

  private getRandomEmoji(): string {
    return RANDOM_EMOJI[Math.floor(Math.random() * RANDOM_EMOJI.length)];
  }

  /**
   * Emoji picker
   */
  public openEmojiPicker(
    buttonWithEmoji: ActionButton,
    btnElement: HTMLElement,
  ) {
    const buttonRect = btnElement.getBoundingClientRect();
    this.onEmojiPickerOpen.emit({
      action: buttonWithEmoji,
      positionTop: buttonRect.top + window.pageYOffset - 20,
      positionRight:
        window.innerWidth - buttonRect.left + window.pageXOffset + 10,
      canBeNull: false,
      whitelist: [],
    });
  }

  public getLabelText(ctaIdx: number): string {
    const cta = this.node.node.question.call_to_action as CTALink;
    return decode(
      getI18nTextLabelTranslation(
        cta.choices[ctaIdx].payload.label,
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelText(ctaIdx: number, text: string) {
    const cta = this.node.node.question.call_to_action as CTALink;
    setI18nTextLabelTranslation(
      cta.choices[ctaIdx].payload.label,
      text,
      this.language,
    );
  }

  public getLabelLink(ctaIdx: number): string {
    const cta = this.node.node.question.call_to_action as CTALink;
    return decode(
      getI18nTextLabelTranslation(
        cta.choices[ctaIdx].payload.url,
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelLink(ctaIdx: number, text: string) {
    const cta = this.node.node.question.call_to_action as CTALink;
    setI18nTextLabelTranslation(
      cta.choices[ctaIdx].payload.url,
      text,
      this.language,
    );
  }

  public setIsRandomize(randomize: boolean) {
    this.cta.randomize = randomize;
    this.cta.responsive = false;
    this.validateData();
  }

  public setIsResponsive(responsive: boolean) {
    this.cta.responsive = responsive;
    this.cta.randomize = false;
    this.validateData();
  }
}
