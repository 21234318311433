import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  NzSelectComponent,
  NzSelectOptionInterface,
} from "ng-zorro-antd/select";

import { NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { Org } from "models/org.model";
import { TagSettings } from "models/tag-settings.types";
import { EntitlementPipe } from "pipes/entitlement.pipe";
import { FeaturePipe } from "pipes/feature.pipe";
import { ScreebFormatPipe } from "pipes/format.pipe";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { UIService } from "services/ui.service";
import { SwitchComponent } from "utils/switch/switch.component";
import { ScreebIconComponent } from "../../../../../utils/screeb-icon/screeb-icon.component";

@Component({
  selector: "tag-settings-behaviour",
  templateUrl: "./tag-settings-behaviour.component.html",
  styleUrls: ["./tag-settings-behaviour.component.scss"],
  imports: [
    ScreebIconComponent,
    SwitchComponent,
    FormsModule,
    NzSelectComponent,
    NgIf,
    RouterLink,
    ScreebFormatPipe,
    EntitlementPipe,
    FeaturePipe,
  ],
})
export class TagSettingsBehaviourComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public tagSettings: TagSettings = null;

  @Output() public update: EventEmitter<TagSettings> = new EventEmitter();

  public responseExpirationOptions: NzSelectOptionInterface[] = [
    { label: "1 minute", value: 60 },
    { label: "5 minutes", value: 300 },
    { label: "15 minutes", value: 900 },
    { label: "1 hour", value: 3600 },
    { label: "1 day", value: 86400 },
    { label: "1 week", value: 604800 },
    { label: "1 month", value: 2419200 },
  ];

  public tagSettingsDiff: TagSettings = null;

  public error: string = null;

  constructor(
    public featureFlaggingService: FeatureFlaggingService,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    this.tagSettingsDiff = { ...this.tagSettings };
  }
}
