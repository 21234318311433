import { Component, EventEmitter, Input, Output } from "@angular/core";
import { OrgAnswerTags } from "models/org.model";
import { NzSelectComponent, NzOptionComponent } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { NgFor, NgClass } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { PermissionPipe } from "pipes/permission.pipe";
import { SuperPermissionPipe } from "pipes/super-permission.pipe";

@Component({
  selector: "page-settings-tagging-edit",
  templateUrl: "./tagging-edit.component.html",
  styleUrls: ["./tagging-edit.component.scss"],
  imports: [
    NzSelectComponent,
    FormsModule,
    NgFor,
    NzOptionComponent,
    NzButtonComponent,
    ɵNzTransitionPatchDirective,
    NzTooltipDirective,
    NgClass,
    NzIconDirective,
    PermissionPipe,
    SuperPermissionPipe,
  ],
})
export class SettingsTaggingEditComponent {
  @Input() public groupTag: OrgAnswerTags;

  @Input() public allOrgTags: string[] = [];

  @Output() public removeGroupTag = new EventEmitter();

  onEventRemove() {
    this.removeGroupTag.emit(this.groupTag);
  }
}
