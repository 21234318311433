/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { NzMarks, NzSliderComponent } from "ng-zorro-antd/slider";

import { NotificationHelper } from "helpers/notification.helper";
import { SettingsService } from "services/settings.service";
import { formatNumber } from "utils/number";
import { assert } from "utils/assertion";
import { NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IntercomButtonComponent } from "../../../utils/intercom-button/intercom-button.component";

@Component({
  selector: "billing-subscription-mtu-slider",
  template: `
    <ng-container *ngIf="ready && minMtu < maxMtu">
      <nz-slider
        [nzMin]="minStep"
        [nzMax]="maxStep"
        [nzStep]="1"
        [nzMarks]="steps"
        [nzDots]="false"
        [nzTooltipVisible]="tooltipVisible ? 'always' : 'never'"
        [nzTooltipPlacement]="'top'"
        [nzTipFormatter]="formatter()"
        [ngModel]="currentStep"
        (ngModelChange)="onStepChange($event)"
        (nzOnAfterChange)="onMouseUp($event)"
      ></nz-slider>
    </ng-container>

    <ng-container *ngIf="ready && minMtu >= maxMtu">
      <div class="overflow">
        {{ formatter()(0) }} MAUs
        <intercom-button title="Talk to our team for more MAUs!" />
      </div>
    </ng-container>
  `,
  styles: [
    `
      nz-slider {
        width: 100%;
      }

      nz-slider::ng-deep .ant-slider {
        margin-top: 50px;
      }

      nz-slider ::ng-deep .ant-slider-rail {
        height: 4px;
        background-color: #e5e5ed !important;
        border-radius: 32px;
      }

      nz-slider ::ng-deep .ant-slider-track {
        height: 4px;
        background-color: #5e21f1 !important;
        border-radius: 32px;
      }

      nz-slider ::ng-deep.ant-slider-mark {
        margin-top: 10px;
      }

      nz-slider ::ng-deep.ant-slider-mark span {
        font-weight: 400;
        font-size: 10px;
        line-height: 150%;
        color: #303140;
      }

      nz-slider ::ng-deep .ant-slider-step {
        display: none;
      }

      nz-slider ::ng-deep .ant-slider-step.ant-slider-dot {
        width: 18px;
        height: 18px;
      }

      nz-slider ::ng-deep .ant-slider-handle {
        width: 18px;
        height: 18px;
        border: 4px solid #5e21f1 !important;
        margin-top: -7px;
      }

      .overflow {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    `,
  ],
  imports: [NgIf, NzSliderComponent, FormsModule, IntercomButtonComponent],
})
export class SettingsBillingSubscriptionMTUSliderComponent implements OnInit {
  @Input() minMtu: number;
  @Input() maxMtu: number;
  @Input() planType: "scale" | "advanced";
  @Input() mtu: number;
  @Output() mtuChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() onClickUp: EventEmitter<number> = new EventEmitter<number>();

  public ready = false;
  public tooltipVisible = false;

  public steps: NzMarks;
  public stepsToMTU: { [key: number]: number };
  public mtuToStep: { [key: number]: number };

  constructor(
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit(): void {
    this.ready = false;

    assert(["scale", "advanced"].includes(this.planType), "Invalid plan type");

    const availableMTU = this.settingsService.billing.plans[
      this.planType
    ].products
      .map((product) => product.mtu)
      .filter(
        // (mtu) => mtu <= (this.maxMtu ?? Infinity)
        (mtu) => mtu >= (this.minMtu ?? 0) && mtu <= (this.maxMtu ?? Infinity),
      )
      .sort((a, b) => a - b);

    this.steps = availableMTU.reduce((agg: object, curr: number, i: number) => {
      agg[i] =
        i === 0 || i === availableMTU.length - 1
          ? formatNumber(curr, "number")
          : "";
      return agg;
    }, {});
    this.stepsToMTU = availableMTU.reduce(
      (agg: object, curr: number, i: number) => {
        agg[i] = curr;
        return agg;
      },
      {},
    );
    this.mtuToStep = availableMTU.reduce(
      (agg: object, curr: number, i: number) => {
        agg[curr] = i;
        return agg;
      },
      {},
    );

    this.ready = true;

    // tooltip is not able to position itself correctly on first render, because of panel animation
    setTimeout(() => {
      this.tooltipVisible = true;
    }, 1000);
  }

  public onStepChange(step: number) {
    this.mtu = this.stepsToMTU[step];
    this.mtuChange.emit(this.mtu);
  }

  public onMouseUp(step: number) {
    this.mtu = this.stepsToMTU[step];
    this.onClickUp.emit(this.mtu);
  }

  public get currentStep(): number {
    return this.mtuToStep[this.mtu];
  }

  public get minStep(): number {
    // return this.mtuToStep[this.minMtu];
    return 0;
  }

  public get maxStep(): number {
    return Object.keys(this.steps).length - 1;
  }

  public formatter() {
    return (step: number): string => {
      return formatNumber(this.stepsToMTU[step], "number");
    };
  }
}
