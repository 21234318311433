/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgClass } from "@angular/common";
import { NzCheckboxComponent } from "ng-zorro-antd/checkbox";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "big-checkbox2", // sorry for this (not so) original name, but i was not inspired
  template: `
    <div
      class="big-checkbox2"
      [ngClass]="{ checked: checked, disabled: this.disabled }"
    >
      <label
        nz-checkbox
        [ngModel]="checked"
        [disabled]="disabled"
        (ngModelChange)="checkedChange.emit($event)"
      ></label>
    </div>
  `,
  styles: [``],
  imports: [NgClass, NzCheckboxComponent, FormsModule],
})
export class BigCheckbox2 {
  @Input() public checked = false;
  @Input() public disabled = false;

  @Output() public checkedChange: EventEmitter<boolean> = new EventEmitter();
}
