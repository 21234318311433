import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NzInputNumberComponent } from "ng-zorro-antd/input-number";
import { SwitchComponent } from "utils/switch/switch.component";

@Component({
  selector: "settings-data-governance-retention-selector",
  template: `
    <div class="label">
      {{ what }} retention
      <switch
        [ngModel]="value"
        (ngModelChange)="valueChange.emit(value ? null : 365)"
      ></switch>
    </div>

    <nz-input-number
      ngDefaultControl
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="value"
      (ngModelChange)="valueChange.emit($event)"
      [nzMin]="min"
      [nzMax]="max"
      [nzStep]="1"
      [disabled]="!value"
    ></nz-input-number>

    days
  `,
  styles: [
    `
      .label {
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
        margin: 4px 0;
      }
    `,
  ],
  imports: [SwitchComponent, FormsModule, NzInputNumberComponent],
})
export class SettingsDataGovernanceRetentionSelectorComponent {
  @Input() what: string;
  @Input() value: number;
  @Input() min: number = 90;
  @Input() max: number = 3650;
  @Output() valueChange = new EventEmitter<number>();

  constructor() {}
}
