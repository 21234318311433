import { Component, Input } from "@angular/core";
import { Org } from "models/org.model";
import { ScreebIconComponent } from "../../../../../utils/screeb-icon/screeb-icon.component";

@Component({
  selector: "org-rules-banner",
  template: `
    <div
      class="org-rules-banner"
      [style]="{ background: 'rgb(255, 240, 218)' }"
    >
      <span class="org-rules-title">
        <screeb-icon size="sm" icon="warning"></screeb-icon>
        Global rules settings are defined and are impacting the targeting of
        this survey.
        <br />
        <a [href]="'/org/' + org.id + '/settings/survey-capping'">
          See global targeting rules
        </a>
      </span>
    </div>
  `,
  styles: [
    `
      screeb-icon {
        position: relative;
        top: 2px;
        margin-right: 0.25rem;
      }

      .org-rules-banner {
        display: flex;
        align-items: center;
        gap: 16px;
        border-radius: 16px;
        width: 100%;
        min-height: 72px;
        padding: 16px 32px;
        animation: shadeAppear 200ms;
        margin-bottom: 32px;
        border: 2px solid var(--screeb-color-warning);
      }

      .org-rules-title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        color: #303140;
      }

      .test-mode-banner .upgrade-btn {
        height: 40px;
      }

      @keyframes shadeAppear {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `,
  ],
  imports: [ScreebIconComponent],
})
export class OrgRulesBannerComponent {
  @Input() public org: Org;
  constructor() {}
}
