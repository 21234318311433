import { Component, Input } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";
import { NgIf, NgClass } from "@angular/common";
import { NzInputDirective } from "ng-zorro-antd/input";
import { FormsModule } from "@angular/forms";
import { EntitlementPipe } from "pipes/entitlement.pipe";

@Component({
  selector: "rule-scroll-reach-element",
  templateUrl: "./rule-scroll-reach-element.component.html",
  styleUrls: ["./rule-scroll-reach-element.component.scss"],
  imports: [NgIf, NzInputDirective, FormsModule, NgClass, EntitlementPipe],
})
export class TargetingRuleScrollReachElementComponent {
  @Input()
  public rule: TargetingRule = null;

  constructor() {}

  public checkValidQuerySelector(r: TargetingRule): boolean {
    try {
      document.querySelector(r.value.v_s);
      return true;
    } catch (e) {
      return false;
    }
  }
}
