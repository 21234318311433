import { Component, Input, OnChanges } from "@angular/core";
import { htmlSanitizer, htmlToMarkdown, SanitizeOptions } from "./sanitizer";
import { Marked, Tokens } from "marked";
import { NgClass } from "@angular/common";

const newlineBreaksExtension = {
  name: "newlineBreaks",
  start(src) {
    return src.indexOf("\n");
  },
  tokenizer(src) {
    const match = src.match(/^\n+/);
    if (match) {
      return {
        type: "newlineBreaks",
        raw: match[0],
        text: match[0],
      };
    }
  },
  renderer(token) {
    return token.text.replace(/\n/g, "<br>\n");
  },
};

const newlineBreaks = {
  extensions: [
    {
      ...newlineBreaksExtension,
      level: "block",
    },
    {
      ...newlineBreaksExtension,
      level: "inline",
    },
  ],
};

const imageRenderer = (baseUrl: string, toText: boolean) => ({
  renderer: {
    image({ href, title, text }: Tokens.Image): string {
      if (toText) {
        return `[${text}]`;
      }

      const sanitizedFileName = href.replace(/[^a-zA-Z0-9-_.]/g, "");

      return `<img src="${baseUrl}/${sanitizedFileName}" alt="${text}" title="${title}"/>`;
    },
  },
});

const getMarkedInstance = (options: SanitizeOptions) => {
  const instance = new Marked();

  instance.use(newlineBreaks);
  instance.use(imageRenderer(options.baseFileUrl, !options.files));

  return instance;
};

export const parseContent = (content: string, options: SanitizeOptions) =>
  htmlSanitizer(
    getMarkedInstance(options).parse(
      htmlToMarkdown(content, options.styling, options.links),
      {
        async: false,
        breaks: false,
      },
    ),
    options,
  );

@Component({
  selector: "sanitized-message-component",
  templateUrl: "./sanitized-message.component.html",
  styleUrls: ["./sanitized-message.component.scss"],
  imports: [NgClass],
})
export class SanitizedMessageComponent implements OnChanges {
  @Input()
  public content: string;
  @Input()
  public options: SanitizeOptions;
  @Input()
  public rotate: "left" | "right" | undefined = undefined;

  public _content: string = "";

  constructor() {}

  ngOnChanges() {
    this._content = parseContent(this.content, this.options);
  }
}
