<header>
  <div class="header-top">
    <h1 class="header-left">
      {{ uiService.isMessagePage ? "Message" : "Survey" }} breakdown
    </h1>
    <div class="analytics-filters">
      <survey-stats-filters
        reportType="all-responses"
        [canExport]="true"
        [canShare]="false"
        [org]="org"
        [survey]="survey"
        [reporting]="'Individual Responses'"
        type="response"
      ></survey-stats-filters>
    </div>
  </div>

  <div class="top-indicators" #topIndicatorsContainer>
    <section nz-row [nzGutter]="[32, 16]" class="top-indicators-inner">
      <div nz-col nzSpan="8">
        <survey-stats-indicators-single-indicator
          title="Total number of displays"
          [value]="overallPerformancesCurrentPeriod?.totalDisplays"
          [diffPercent]="overallPerformancesVariation?.totalDisplays"
          format="number"
          diffFormat="percent"
          [loading]="this.loading"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzSpan="8">
        <survey-stats-indicators-single-indicator
          title="Partial completion"
          [value]="overallPerformancesCurrentPeriod?.responseRate"
          [diffPercent]="overallPerformancesVariation?.responseRate"
          format="percent"
          diffFormat="percent"
          [loading]="this.loading"
        ></survey-stats-indicators-single-indicator>
      </div>
      <div nz-col nzSpan="8">
        <survey-stats-indicators-single-indicator
          title="Full completion"
          [value]="overallPerformancesCurrentPeriod?.completionRate"
          [diffPercent]="overallPerformancesVariation?.completionRate"
          format="percent"
          diffFormat="percent"
          [loading]="this.loading"
        ></survey-stats-indicators-single-indicator>
      </div>
    </section>
  </div>
  <div class="legend-steps-title">Displays at each steps</div>
  <div class="legend-steps-container">
    <div class="legend-steps" #legendStepsContainer>
      <div
        class="legend-step"
        *ngFor="let step of legendSteps; let index = index"
      >
        <div class="legend-step-number">
          {{ index + 1 }}
        </div>
        <survey-stats-indicators-single-indicator
          [value]="step.responsesCount"
          format="number"
          diffFormat="percent"
        ></survey-stats-indicators-single-indicator>
        @let isDisplayed = shouldDisplayVariation(step.variation);
        <div class="legend-step-arrow" *ngIf="isDisplayed">
          <svg
            width="102"
            height="64"
            viewBox="0 0 102 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0H78L99.9011 26.9553C102.289 29.8945 102.289 34.1055 99.9011 37.0447L78 64H0V0Z"
              [style]="{ fill: step.color }"
            />
          </svg>
          <div class="legend-step-arrow-value">
            @let formatted = formatNumber(step.variation, "percent", 1);
            {{ formatted }}
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="loader" *ngIf="loadingGraph">
  <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
</div>
<builder-layout
  *ngIf="!loadingGraph"
  class="builder-layout"
  [draggableZone]
  [columnsWithCards]="columnsWithCards"
  [defaultLanguageWarning]="false"
  (scrollChange)="onBuilderScroll($event)"
></builder-layout>
