import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { UserNormalizedProperty } from "models/user.model";
import { formatNumber } from "utils/number";
import { NgIf, NgFor } from "@angular/common";
import { SquareIconComponent } from "../../../utils/square-icon/square-icon.component";
import { TagRegistryEntrySourceComponent } from "../../../common/user/tag-registry-entry-source/tag-registry-entry-source.component";
import { NzEmptyComponent } from "ng-zorro-antd/empty";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { FormatDistanceToNowPipeModule } from "ngx-date-fns";
import { ToLocaleStringPipe } from "pipes/to-locale-string.pipe";

@Component({
  selector: "user-properties",
  templateUrl: "./user-properties.component.html",
  styleUrls: ["./user-properties.component.scss"],
  imports: [
    NgIf,
    NgFor,
    SquareIconComponent,
    TagRegistryEntrySourceComponent,
    NzEmptyComponent,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    FormatDistanceToNowPipeModule,
    ToLocaleStringPipe,
  ],
})
export class UserPropertiesComponent implements OnChanges {
  @Input() public userProperties: UserNormalizedProperty[] = [];

  @Output() public eventClicked: EventEmitter<object> = new EventEmitter();

  public formatNumber = formatNumber;

  public userPropertiesWithoutObjects: UserNormalizedProperty[] = [];

  constructor() {}

  ngOnChanges(_: SimpleChanges): void {
    this.userPropertiesWithoutObjects = this.userProperties.filter(
      (p) => p.type !== "object",
    );
  }
}
