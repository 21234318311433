import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { SessionService } from "services/auth.service";
import { EventService, ScreebEventType } from "services/event.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";

import { Account, AccountJobTitlesToLabel } from "models/account.model";
import {
  AnalyticsFilter,
  AnalyticsFilters,
  AnalyticsIndex,
} from "models/analytics.filters.type";
import { AnalyticsResponseItemResponseAnswer } from "models/analytics.model";
import { Channel } from "models/channel.model";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";
import { Sequence } from "models/sequence.types";
import { SuperOrg } from "models/super-org.model";
import { Survey } from "models/survey.model";
import { User } from "models/user.model";
import { SettingsService } from "services/settings.service";
import { getCookieByName } from "utils/cookies";
import { uuidv4 } from "utils/uuid";
import { ConfigService } from "./config.service";
import {
  TrackingEventName,
  gtmConversionId,
  linkedinConversionId,
} from "./trackers.events";

@Injectable()
export class TrackersService {
  private isGTMLoading: boolean = false;
  private isLinkedinLoading: boolean = false;
  private isIntercomLoading: boolean = false;
  private isScreebLoading: boolean = false;
  private isSegmentLoading: boolean = false;
  private isFullstoryLoading: boolean = false;
  private isHubspotLoading: boolean = false;

  constructor(
    private sessionService: SessionService,
    private eventService: EventService,
    private screebApiHelper: ScreebApiHelper,
    private featureFlagging: FeatureFlaggingService,
    private settingsService: SettingsService,
    private configService: ConfigService,
  ) {
    this.eventService
      .subscribe(ScreebEventType.ScreebLoaded)
      .subscribe(this.onAppLoaded.bind(this));
    this.eventService
      .subscribe(ScreebEventType.AuthLogin)
      .subscribe(this.onLogin.bind(this));
    this.eventService
      .subscribe(ScreebEventType.AuthLogout)
      .subscribe(this.onLogout.bind(this));
  }

  /**
   * App events
   */
  private async onAppLoaded() {
    if (this.sessionService.isAuth()) {
      // this.intercomLoadTag();
      // this.screebLoadTag();
      // this.segmentLoadTag();
      // this.fullstoryLoadTag();
      this.onLogin();
    } else {
      // nothing
    }

    this.linkedinLoadTag();
    this.gtmLoadTag();
  }

  public onLogin() {
    this.intercomIdentify();
    this.screebIdentify();
    this.segmentIdentify();
    // this.fullstoryIdentify();
    this.hubspotIdentify();
    this.linkedinIdentify();
    this.gtmIdentify();
  }

  public onLogout() {
    this.intercomClose();
    this.screebAnonymous();
    this.segmentAnonymous();
    this.hubspotAnonymous();
    this.linkedinAnonymous();
    this.gtmAnonymous();
  }

  public newEventTrackingBuilder(
    name: TrackingEventName,
    properties?: object | null,
  ): EventTracking {
    if (!properties) properties = {};

    return new EventTracking(this, name, properties);
  }

  public newEventPageViewBuilder(
    name: string,
    properties?: object | null,
  ): EventPageView {
    if (!properties) {
      properties = {};
    }

    return new EventPageView(this, name, properties);
  }

  public setVisitorProperties(properties: object) {
    return Promise.all([this.segmentProperties(properties)]);
  }

  public async trackEvent(name: TrackingEventName, properties: object) {
    if (this.sessionService.isConnectedAs()) {
      return;
    }
    return Promise.all([
      // this.screebTrack(name, properties),
      this.segmentTrack(name, properties),
    ]);
  }

  public async pageViewEvent(name: string, properties: object) {
    if (this.sessionService.isConnectedAs()) {
      return;
    }
    return Promise.all([this.segmentPageView(name, properties)]);
  }

  /**
   * HUBSPOT
   */
  public getHubspotVisitorId(): string | null {
    return getCookieByName("hubspotutk");
  }
  /**
   * INTERCOM
   */
  private intercomLoadTag(): Promise<any> {
    // check it's time to load
    if (!this.configService.config.intercomTagAppId) return Promise.resolve();
    if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    // if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check already loaded
    if ("Intercom" in window) return Promise.resolve();
    if (this.isIntercomLoading) return Promise.resolve();

    this.isIntercomLoading = true;
    const intercomTagAppId = this.configService.config.intercomTagAppId;
    return new Promise((resolve, reject) => {
      (function () {
        const w = window;
        const ic = w["Intercom"];
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w["intercomSettings"]);
        } else {
          const d = document;
          const i = function () {
            i["c"](arguments); // eslint-disable-line
          };
          i["q"] = [];
          i["c"] = function (args: any) {
            i["q"].push(args);
          };
          w["Intercom"] = i;
          const l = function () {
            const s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + intercomTagAppId;
            s.onload = () => resolve(true);
            s.onerror = (err) => reject(err);
            const x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          // @samber 2020-12-08
          // if (w['attachEvent']) {
          //   w['attachEvent']('onload', l);
          // } else {
          //   w.addEventListener('load', l, false);
          // }
          l();
        }
      })();
    });
  }

  private async intercomIdentify(): Promise<any> {
    // check auth'd
    if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    // if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("Intercom" in window)) await this.intercomLoadTag();
    if (!("Intercom" in window)) return Promise.resolve();

    // need to wait for intercom full setup because we had a race condition with Intercom('boot', ...) command and identity verification
    // await delayPromise(3000)();

    const intercomTagAppId = this.configService.config.intercomTagAppId;

    try {
      const userIntercomConfig = await this.screebApiHelper
        .get<object>("/account/me/intercom")
        .toPromise();

      const hmac = userIntercomConfig["hmac"];

      (window["Intercom"] as any)("boot", {
        app_id: intercomTagAppId,
        user_id: this.sessionService.session?.id,
        user_hash: hmac,
        name: this.sessionService.session?.fullname,
        email: this.sessionService.session?.email,
        // custom_launcher_selector: "#intercom-help-i-need-somebody-help",
      });
    } catch (err) {
      console.error("Failed to load Intercom", err);
    }
  }

  public async intercomOpenConversation(): Promise<any> {
    // check auth'd
    if (!this.sessionService.isAuth() || this.sessionService.isConnectedAs()) {
      window.location.assign("mailto:michael@screeb.app");
      return Promise.resolve();
    }
    // if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("Intercom" in window)) await this.intercomIdentify();
    if (!("Intercom" in window)) {
      window.location.assign("mailto:michael@screeb.app");
      return Promise.resolve();
    }

    (window["Intercom"] as any)("showNewMessage");
  }

  // Commented, because it does not work well.
  // public intercomGetVisitorId(): string | undefined {
  //   return "Intercom" in window && (window["Intercom"] as any)("getVisitorId");
  // }
  public intercomIsBooted(): boolean {
    return "Intercom" in window && (window["Intercom"] as any)?.booted === true;
  }

  private async intercomClose(): Promise<any> {
    // check tag is loaded
    if ("Intercom" in window) {
      (window["Intercom"] as any)("shutdown");
    }
  }

  /**
   * SCREEB
   */
  private async screebLoadTag(): Promise<any> {
    // check it's time to load
    if (!this.configService.config.screebTagChannelId) return Promise.resolve();
    if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    // if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check already loaded
    if ("$screeb" in window) return Promise.resolve();
    if (this.isScreebLoading) return Promise.resolve();

    this.isScreebLoading = true;
    return new Promise((resolve) => {
      (function (w, d, s, o, f, js, fjs) {
        w["ScreebObject"] = o;
        w[o] =
          w[o] ||
          function () {
            const d = arguments; // eslint-disable-line
            return new Promise(function (a, b) {
              (w[o].q = w[o].q || []).push({
                v: 1,
                args: d,
                ok: a,
                ko: b,
              });
            });
          };
        // eslint-disable-next-line
        (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
        js.type = "text/javascript";
        js.id = o;
        js.src = f;
        js.async = 1;
        d.getElementsByTagName("head")[0].appendChild(js);
      })(
        window,
        document,
        "script",
        "$screeb",
        // t2.screeb.app has been added to bypass adblockers in preview
        this.configService.config.tagBypassEndpoint + "/tag.js",
      );

      resolve(null);
    });
  }

  private async screebIdentify(): Promise<any> {
    // check auth'd
    if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    // if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("$screeb" in window)) await this.screebLoadTag();
    if (!("$screeb" in window)) return Promise.resolve();

    (window["$screeb"] as any)(
      "init",
      this.configService.config.screebTagChannelId,
      {
        identity: {
          id: this.sessionService.session?.id,
          properties: {
            email: this.sessionService.session?.email,
            firstname: this.sessionService.session?.firstname,
            lastname: this.sessionService.session?.lastname,
            created_at: this.sessionService.session?.created_at,
          },
        },
      },
    );
  }

  private async screebAnonymous(): Promise<any> {
    if ("$screeb" in window) {
      (window["$screeb"] as any)("close");
    }
  }
  public async screebSurveyStartFeedback(): Promise<any> {
    this.screebSurveyStart("8fc985c8-da03-4bae-8fab-35903edcf843");
  }
  public async screebSurveyStart(surveyID: string): Promise<any> {
    // check auth'd
    if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    // if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("$screeb" in window)) await this.screebIdentify();
    if (!("$screeb" in window)) return Promise.resolve();

    return (window["$screeb"] as any)("survey.start", surveyID, {
      allow_multiple_responses: true,
      ignore_survey_status: true,
    });
  }
  public async screebSurveyClose(): Promise<any> {
    // check auth'd
    if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    // if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("$screeb" in window)) return Promise.resolve();

    return (window["$screeb"] as any)("survey.close");
  }

  private async screebTrack(name: string, properties: object): Promise<any> {
    // check auth'd
    if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("$screeb" in window)) await this.screebIdentify();
    if (!("$screeb" in window)) return Promise.resolve();

    return (window["$screeb"] as any)("event.track", name, properties);
  }

  /**
   * SEGMENT
   */
  private async segmentLoadTag(): Promise<any> {
    // check it's time to load
    if (!this.configService.config.segmentTagId) return Promise.resolve();
    if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    // commented, because we allow identification, but not tracking
    // if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check already loaded
    if ("analytics" in window) return Promise.resolve();
    if (this.isSegmentLoading) return Promise.resolve();

    this.isSegmentLoading = true;
    const segmentTagId = this.configService.config.segmentTagId;
    return new Promise((resolve) => {
      (function () {
        const analytics = (window["analytics"] = window["analytics"] || []);
        if (!analytics.initialize)
          if (analytics.invoked)
            window.console &&
              console.error &&
              console.error("Segment snippet included twice.");
          else {
            analytics.invoked = !0;
            analytics.methods = [
              "trackSubmit",
              "trackClick",
              "trackLink",
              "trackForm",
              "pageview",
              "identify",
              "reset",
              "group",
              "track",
              "ready",
              "alias",
              "debug",
              "page",
              "once",
              "off",
              "on",
              "addSourceMiddleware",
              "addIntegrationMiddleware",
              "setAnonymousId",
              "addDestinationMiddleware",
            ];
            analytics.factory = function (e) {
              return function () {
                const t = Array.prototype.slice.call(arguments); // eslint-disable-line
                t.unshift(e);
                analytics.push(t);
                return analytics;
              };
            };
            for (let e = 0; e < analytics.methods.length; e++) {
              const key = analytics.methods[e];
              analytics[key] = analytics.factory(key);
            }
            analytics.load = function (key, e) {
              const t = document.createElement("script");
              t.type = "text/javascript";
              t.async = !0;
              t.src =
                "https://cdn.segment.com/analytics.js/v1/" +
                key +
                "/analytics.min.js";
              const n = document.getElementsByTagName("script")[0];
              n.parentNode.insertBefore(t, n);
              analytics._loadOptions = e;
            };
            analytics._writeKey = segmentTagId;
            analytics.SNIPPET_VERSION = "4.15.3";
            analytics.load(segmentTagId);
            analytics.page();
            resolve(null);
          }
      })();

      // this.segment.load(this.segmentTagId);
    });
  }

  private async segmentIdentify(): Promise<any> {
    // check auth'd
    if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    // if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("analytics" in window)) await this.segmentLoadTag();
    if (!("analytics" in window)) return Promise.resolve();

    await (window["analytics"] as any).identify(
      this.sessionService.session?.id,
      {
        // name: this.sessionService.session?.fullname,
        // email: this.sessionService.session?.email,
      },
    );
  }

  private async segmentAnonymous(): Promise<any> {
    this.settingsService.setAdminAnonymousId(uuidv4());

    if ("analytics" in window) {
      return (window["analytics"] as any).reset();
    }

    return Promise.resolve();
  }

  // Data are pushed to the server in the first place, instead of Segment.
  private async segmentProperties(properties: object): Promise<any> {
    // check auth'd
    if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("analytics" in window)) await this.segmentLoadTag();
    if (!("analytics" in window)) return Promise.resolve();

    try {
      await this.screebApiHelper
        .post<object>("/s", {
          properties,
          anonymous_id: this.settingsService.getOrCreateAdminAnonymousId(),
        })
        .toPromise();
    } catch (err) {
      console.error(err);
    }
  }

  // Data are pushed to the server in the first place, instead of Segment.
  private async segmentPageView(
    name: string,
    properties: object,
  ): Promise<any> {
    // check auth'd
    if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("analytics" in window)) await this.segmentLoadTag();
    if (!("analytics" in window)) return Promise.resolve();

    // // 'last_visit_pageXYZ_at' property
    // const pageSerial = name
    //   .replace(/\W/g, " ")
    //   .toLowerCase()
    //   .trim()
    //   .replace(/\s\s+/g, " ")
    //   .replace(/ /g, "_");
    // const propName = `last_visit_${pageSerial}_at`;

    // (window["analytics"] as any).page();

    // try {
    //   await this.screebApiHelper
    //     .post<object>("/s", {
    //       properties: { [propName]: new Date() },
    //       page_view: [{ name, properties }],
    //       anonymous_id: this.settingsService.getOrCreateAdminAnonymousId(),
    //     })
    //     .toPromise();
    // } catch (err) {
    //   console.error(err);
    // }
  }

  // Data are pushed to the server in the first place, instead of Segment.
  private async segmentTrack(name: string, properties: object): Promise<any> {
    try {
      await this.screebApiHelper
        .post<object>("/s", {
          track: [{ name, properties }],
          anonymous_id: this.settingsService.getOrCreateAdminAnonymousId(),
        })
        .toPromise();
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * FULLSTORY
   */
  private async fullstoryLoadTag() {
    // check it's time to load
    if (!this.configService.config.fullstoryOrgId) return Promise.resolve();
    if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    // if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check already loaded
    if ("FS" in window) return Promise.resolve();
    if (this.isFullstoryLoading) return Promise.resolve();

    this.isFullstoryLoading = true;
    return new Promise((resolve) => {
      window["_fs_debug"] = false;
      window["_fs_host"] = "fullstory.com";
      window["_fs_script"] = "edge.fullstory.com/s/fs.js";
      window["_fs_org"] = this.configService.config.fullstoryOrgId;
      window["_fs_namespace"] = "FS";

      (function (m, n, e, t, l, o, g, y) {
        if (e in m) {
          if (m.console && m.console.log) {
            m.console.log(
              'FullStory namespace conflict. Please set window["_fs_namespace"].',
            );
          }
          return;
        }
        g = (m as any)[e] = function (a, b, s) {
          g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
        };
        g.q = [];
        o = n.createElement(t);
        o.async = 1;
        o.crossOrigin = "anonymous";
        o.src = "https://" + window["_fs_script"];
        y = n.getElementsByTagName(t)[0];
        y.parentNode.insertBefore(o, y);
        g.identify = function (i, v, s) {
          g(l, { uid: i }, s);
          if (v) g(l, v, s);
        };
        g.setUserVars = function (v, s) {
          g(l, v, s);
        };
        g.event = function (i, v, s) {
          g("event", { n: i, p: v }, s);
        };
        g.anonymize = function () {
          g.identify(!!0);
        };
        g.shutdown = function () {
          g("rec", !1);
        };
        g.restart = function () {
          g("rec", !0);
        };
        g.log = function (a, b) {
          g("log", [a, b]);
        };
        g.consent = function (a) {
          g("consent", !arguments.length || a);
        };
        g.identifyAccount = function (i, v) {
          o = "account";
          v = v || {};
          v.acctId = i;
          g(o, v);
        };
        g.clearUserCookie = function () {};
        g.setVars = function (n, p) {
          g("setVars", [n, p]);
        };
        g._w = {};
        y = "XMLHttpRequest";
        g._w[y] = m[y];
        y = "fetch";
        g._w[y] = m[y];
        if (m[y])
          (m as any)[y] = function () {
            return g._w[y].apply(this, arguments); // eslint-disable-line
          };
        g._v = "1.3.0";
      })(window, document, window["_fs_namespace"], "script", "user");

      resolve(null);
    });
  }

  private async fullstoryIdentify() {
    // check auth'd
    if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    // if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("FS" in window)) await this.fullstoryLoadTag();
    if (!("FS" in window)) return Promise.resolve();

    (window["FS"] as any).identify(this.sessionService.session?.id, {
      email: this.sessionService.session?.email,
      firstname: this.sessionService.session?.firstname,
      lastname: this.sessionService.session?.lastname,
      created_at: this.sessionService.session?.created_at,
    });
  }

  /**
   * HUBSPOT
   */
  private async hubspotLoadTag() {
    // check it's time to load
    if (!this.configService.config.hubspotId) return Promise.resolve();

    if ("_hsq" in window) return Promise.resolve();
    if (this.isHubspotLoading) return Promise.resolve();

    this.isHubspotLoading = true;
    const r = document.createElement("script");
    r.id = "hs-script-loader";
    r.type = "text/javascript";
    r.async = !0;
    r.src = `https://js.hs-scripts.com/${this.configService.config.hubspotId}.js`;

    const s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(r, s);
  }

  private async hubspotIdentify() {
    // check auth'd
    if (!this.sessionService.isAuth()) return Promise.resolve();
    // if (this.sessionService.isConnectedAs()) return Promise.resolve();
    // if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("_hsq" in window)) await this.hubspotLoadTag();
    if (!("_hsq" in window)) return Promise.resolve();

    (window["_hsq"] as any).push([
      "identify",
      {
        id: this.sessionService.session.id,
        email: this.sessionService.session.email,
      },
    ]);
  }

  private async hubspotAnonymous() {
    // check auth'd
    if (!this.sessionService.isAuth()) return Promise.resolve();
    // if (this.sessionService.isConnectedAs()) return Promise.resolve();
    // if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("_hsq" in window)) await this.hubspotLoadTag();
    if (!("_hsq" in window)) return Promise.resolve();

    // window["_hsq"].identify();
  }

  /**
   * LINKEDIN
   */
  private async linkedinLoadTag() {
    // check it's time to load
    if (!this.configService.config.linkedinPartnerId) return Promise.resolve();
    if ("_linkedin_partner_id" in window) return Promise.resolve();
    if (this.isLinkedinLoading) return Promise.resolve();

    this.isLinkedinLoading = true;
    window["_linkedin_partner_id"] =
      this.configService.config.linkedinPartnerId;
    window["_linkedin_data_partner_ids"] =
      window["_linkedin_data_partner_ids"] || [];
    window["_linkedin_data_partner_ids"].push(window["_linkedin_partner_id"]);

    (function (l) {
      if (!l) {
        window["lintrk"] = function (a, b) {
          window["lintrk"].q.push([a, b]);
        };
        window["lintrk"].q = [];
      }
      const s = document.getElementsByTagName("script")[0];
      const b = document.createElement("script");
      b.type = "text/javascript";
      b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);
    })(window["lintrk"]);
  }

  private async linkedinIdentify() {
    // check auth'd
    // if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("_linkedin_partner_id" in window)) await this.linkedinLoadTag();
    if (!("_linkedin_partner_id" in window)) return Promise.resolve();
  }

  private async linkedinAnonymous() {
    // check auth'd
    // if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("_linkedin_partner_id" in window)) await this.linkedinLoadTag();
    if (!("_linkedin_partner_id" in window)) return Promise.resolve();
  }

  public async linkedinTrackConversion(conversionId: linkedinConversionId) {
    // check auth'd
    // if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("_linkedin_partner_id" in window)) await this.linkedinLoadTag();
    if (!("_linkedin_partner_id" in window)) return Promise.resolve();

    window["lintrk"]("track", { conversion_id: conversionId });
  }

  /**
   * GTM aka "je t'aime" 🤮
   */
  private async gtmLoadTag() {
    // check it's time to load
    if (!this.configService.config.gtmId) return Promise.resolve();
    if ("dataLayer" in window) return Promise.resolve();
    if (this.isGTMLoading) return Promise.resolve();

    this.isGTMLoading = true;
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement("script"),
        dl = l !== "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(
      window,
      document,
      "script",
      "dataLayer",
      this.configService.config.gtmId,
    );
  }

  private async gtmIdentify() {
    // check auth'd
    // if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("dataLayer" in window)) await this.gtmLoadTag();
    if (!("dataLayer" in window)) return Promise.resolve();
  }

  private async gtmAnonymous() {
    // check auth'd
    // if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("dataLayer" in window)) await this.gtmLoadTag();
    if (!("dataLayer" in window)) return Promise.resolve();
  }

  public async gtmTrackConversion(conversionId: gtmConversionId) {
    // check auth'd
    // if (!this.sessionService.isAuth()) return Promise.resolve();
    if (this.sessionService.isConnectedAs()) return Promise.resolve();
    if (this.featureFlagging.accountIsScreebTeam()) return Promise.resolve();

    // check tag is loaded
    if (!("dataLayer" in window)) await this.gtmLoadTag();
    if (!("dataLayer" in window)) return Promise.resolve();

    // window["gtag"]?.("event", conversionId, {});
    (window["dataLayer"] as any)?.push({ event: conversionId });
  }
}

class EventTracking {
  constructor(
    protected eventAdapter: TrackersService,
    protected name: TrackingEventName,
    protected properties: object,
  ) {}

  public withSuperOrg(superOrg: SuperOrg): EventTracking {
    if (!superOrg) return this;

    Object.assign(this.properties, {
      org_id: superOrg.id,
      org_name: superOrg.name,
      org_last_seen_at: superOrg.last_seen_at,
      org_created_at: superOrg.created_at,
      org_updated_at: superOrg.updated_at,
      org_user_role: superOrg.role,
      org_user_permissions: superOrg.permissions,

      // @TODO: Commented because i'm tired to sync all the properties between the back and the front.
      // The properties does not need to be updated in the group properties, but it would be useful in event properties.
      // Add these properties from the backend ?
      //
      // stats
      // org_total_respondent_count: superOrg.stats.total_respondents,
      // org_total_display_count: null, // deprecated
      // org_total_response_count: null, // deprecated
      // total_events_track_count: null, // deprecated
      // total_events_screen_count: null, // deprecated
      // total_identity_properties_count: null, // deprecated
      // total_group_assignations_count: null, // deprecated
      // total_response_answer_media_uploaded: null, // deprecated
      // total_response_answer_media_size_uploaded: null, // deprecated
      // org_total_events_screen_count: superOrg.stats.total_events_screen,
      // org_total_identity_properties_count:
      //   superOrg.stats.total_identity_properties,
      // org_total_group_assignations_count:
      //   superOrg.stats.total_group_assignations,
      // org_total_response_answer_media_uploaded:
      //   superOrg.stats.total_response_answer_media_uploaded,
      // org_total_response_answer_media_size_uploaded:
      //   superOrg.stats.total_response_answer_media_size_uploaded,
      // org_total_survey_responses: superOrg.stats.total_survey_responses,
      // org_total_survey_responses_not_started:
      //   superOrg.stats.total_survey_responses_not_started,
      // org_total_survey_responses_partially_completed:
      //   superOrg.stats.total_survey_responses_partially_completed,
      // org_total_survey_responses_fully_completed:
      //   superOrg.stats.total_survey_responses_fully_completed,
      // org_total_survey_responses_started:
      //   superOrg.stats.total_survey_responses_started,
      // org_total_message_responses: superOrg.stats.total_message_responses,
      // org_total_message_responses_not_started:
      //   superOrg.stats.total_message_responses_not_started,
      // org_total_message_responses_partially_completed:
      //   superOrg.stats.total_message_responses_partially_completed,
      // org_total_message_responses_fully_completed:
      //   superOrg.stats.total_message_responses_fully_completed,
      // org_total_message_responses_started:
      //   superOrg.stats.total_message_responses_started,

      // org_rolling_month_respondent_count:
      //   superOrg.stats.rolling_month_respondents,
      // org_rolling_month_display_count: null, // deprecated
      // org_rolling_month_response_count: null, // deprecated
      // rolling_month_events_track_count: null, // deprecated
      // rolling_month_events_screen_count: null, // deprecated
      // rolling_month_identity_properties_count: null, // deprecated
      // rolling_month_group_assignations_count: null, // deprecated
      // org_rolling_month_events_track_count:
      //   superOrg.stats.rolling_month_events_track,
      // org_rolling_month_events_screen_count:
      //   superOrg.stats.rolling_month_events_screen,
      // org_rolling_month_identity_properties_count:
      //   superOrg.stats.rolling_month_identity_properties,
      // org_rolling_month_group_assignations_count:
      //   superOrg.stats.rolling_month_group_assignations,
      // org_rolling_month_survey_responses:
      //   superOrg.stats.rolling_month_survey_responses,
      // org_rolling_month_survey_responses_not_started:
      //   superOrg.stats.rolling_month_survey_responses_not_started,
      // org_rolling_month_survey_responses_partially_completed:
      //   superOrg.stats.rolling_month_survey_responses_partially_completed,
      // org_rolling_month_survey_responses_fully_completed:
      //   superOrg.stats.rolling_month_survey_responses_fully_completed,
      // org_rolling_month_survey_responses_started:
      //   superOrg.stats.rolling_month_survey_responses_started,
      // org_rolling_month_message_responses:
      //   superOrg.stats.rolling_month_message_responses,
      // org_rolling_month_message_responses_not_started:
      //   superOrg.stats.rolling_month_message_responses_not_started,
      // org_rolling_month_message_responses_partially_completed:
      //   superOrg.stats.rolling_month_message_responses_partially_completed,
      // org_rolling_month_message_responses_fully_completed:
      //   superOrg.stats.rolling_month_message_responses_fully_completed,
      // org_rolling_month_message_responses_started:
      //   superOrg.stats.rolling_month_message_responses_started,

      // org_rolling_week_respondent_count:
      //   superOrg.stats.rolling_week_respondents,
      // org_rolling_week_display_count: null, // deprecated
      // org_rolling_week_response_count: null, // deprecated
      // rolling_week_events_track_count: null, // deprecated
      // rolling_week_events_screen_count: null, // deprecated
      // rolling_week_identity_properties_count: null, // deprecated
      // rolling_week_group_assignations_count: null, // deprecated
      // org_rolling_week_events_track_count:
      //   superOrg.stats.rolling_week_events_track,
      // org_rolling_week_events_screen_count:
      //   superOrg.stats.rolling_week_events_screen,
      // org_rolling_week_identity_properties_count:
      //   superOrg.stats.rolling_week_identity_properties,
      // org_rolling_week_group_assignations_count:
      //   superOrg.stats.rolling_week_group_assignations,
      // org_rolling_week_survey_responses:
      //   superOrg.stats.rolling_week_survey_responses,
      // org_rolling_week_survey_responses_not_started:
      //   superOrg.stats.rolling_week_survey_responses_not_started,
      // org_rolling_week_survey_responses_partially_completed:
      //   superOrg.stats.rolling_week_survey_responses_partially_completed,
      // org_rolling_week_survey_responses_fully_completed:
      //   superOrg.stats.rolling_week_survey_responses_fully_completed,
      // org_rolling_week_survey_responses_started:
      //   superOrg.stats.rolling_week_survey_responses_started,
      // org_rolling_week_message_responses:
      //   superOrg.stats.rolling_week_message_responses,
      // org_rolling_week_message_responses_not_started:
      //   superOrg.stats.rolling_week_message_responses_not_started,
      // org_rolling_week_message_responses_partially_completed:
      //   superOrg.stats.rolling_week_message_responses_partially_completed,
      // org_rolling_week_message_responses_fully_completed:
      //   superOrg.stats.rolling_week_message_responses_fully_completed,
      // org_rolling_week_message_responses_started:
      //   superOrg.stats.rolling_week_message_responses_started,

      // org_rolling_day_respondent_count: superOrg.stats.rolling_day_respondents,
      // org_rolling_day_display_count: null, // deprecated
      // org_rolling_day_response_count: null, // deprecated
      // rolling_day_events_track_count: null, // deprecated
      // rolling_day_events_screen_count: null, // deprecated
      // rolling_day_identity_properties_count: null, // deprecated
      // rolling_day_group_assignations_count: null, // deprecated
      // org_rolling_day_events_track_count:
      //   superOrg.stats.rolling_day_events_track,
      // org_rolling_day_events_screen_count:
      //   superOrg.stats.rolling_day_events_screen,
      // org_rolling_day_identity_properties_count:
      //   superOrg.stats.rolling_day_identity_properties,
      // org_rolling_day_group_assignations_count:
      //   superOrg.stats.rolling_day_group_assignations,
      // org_rolling_day_survey_responses:
      //   superOrg.stats.rolling_day_survey_responses,
      // org_rolling_day_survey_responses_not_started:
      //   superOrg.stats.rolling_day_survey_responses_not_started,
      // org_rolling_day_survey_responses_partially_completed:
      //   superOrg.stats.rolling_day_survey_responses_partially_completed,
      // org_rolling_day_survey_responses_fully_completed:
      //   superOrg.stats.rolling_day_survey_responses_fully_completed,
      // org_rolling_day_survey_responses_started:
      //   superOrg.stats.rolling_day_survey_responses_started,
      // org_rolling_day_message_responses:
      //   superOrg.stats.rolling_day_message_responses,
      // org_rolling_day_message_responses_not_started:
      //   superOrg.stats.rolling_day_message_responses_not_started,
      // org_rolling_day_message_responses_partially_completed:
      //   superOrg.stats.rolling_day_message_responses_partially_completed,
      // org_rolling_day_message_responses_fully_completed:
      //   superOrg.stats.rolling_day_message_responses_fully_completed,
      // org_rolling_day_message_responses_started:
      //   superOrg.stats.rolling_day_message_responses_started,

      // org_current_month_respondent_count:
      //   superOrg.stats.current_month_respondents,
      // org_current_month_display_count: null, // deprecated
      // org_current_month_response_count: null, // deprecated
      // current_month_events_track_count: null, // deprecated
      // current_month_events_screen_count: null, // deprecated
      // current_month_identity_properties_count: null, // deprecated
      // current_month_group_assignations_count: null, // deprecated
      // current_month_survey_question_video_uploaded: null, // deprecated
      // current_month_survey_question_video_size_uploaded: null, // deprecated
      // current_month_response_answer_media_uploaded: null, // deprecated
      // current_month_response_answer_media_size_uploaded: null, // deprecated
      // org_current_month_events_track_count:
      //   superOrg.stats.current_month_events_track,
      // org_current_month_events_screen_count:
      //   superOrg.stats.current_month_events_screen,
      // org_current_month_identity_properties_count:
      //   superOrg.stats.current_month_identity_properties,
      // org_current_month_group_assignations_count:
      //   superOrg.stats.current_month_group_assignations,
      // org_current_month_survey_question_video_uploaded:
      //   superOrg.stats.current_month_survey_question_video_uploaded,
      // org_current_month_survey_question_video_size_uploaded:
      //   superOrg.stats.current_month_survey_question_video_size_uploaded,
      // org_current_month_response_answer_media_uploaded:
      //   superOrg.stats.current_month_response_answer_media_uploaded,
      // org_current_month_response_answer_media_size_uploaded:
      //   superOrg.stats.current_month_response_answer_media_size_uploaded,
      // org_current_month_survey_responses:
      //   superOrg.stats.current_month_survey_responses,
      // org_current_month_survey_responses_not_started:
      //   superOrg.stats.current_month_survey_responses_not_started,
      // org_current_month_survey_responses_partially_completed:
      //   superOrg.stats.current_month_survey_responses_partially_completed,
      // org_current_month_survey_responses_fully_completed:
      //   superOrg.stats.current_month_survey_responses_fully_completed,
      // org_current_month_survey_responses_started:
      //   superOrg.stats.current_month_survey_responses_started,
      // org_current_month_message_responses:
      //   superOrg.stats.current_month_message_responses,
      // org_current_month_message_responses_not_started:
      //   superOrg.stats.current_month_message_responses_not_started,
      // org_current_month_message_responses_partially_completed:
      //   superOrg.stats.current_month_message_responses_partially_completed,
      // org_current_month_message_responses_fully_completed:
      //   superOrg.stats.current_month_message_responses_fully_completed,
      // org_current_month_message_responses_started:
      //   superOrg.stats.current_month_message_responses_started,

      // org_monthly_respondent_count: null, // deprecated
      // org_monthly_display_count: null, // deprecated
      // org_monthly_response_count: null, // deprecated
    });
    return this;
  }

  public withOrg(org: Org): EventTracking {
    if (!org) return this;

    Object.assign(this.properties, {
      workspace_id: org.id,
      workspace_tenant_region: org.tenant_region,
      workspace_name: org.name,
      workspace_industry: org.industry,
      workspace_last_seen_at: org.last_seen_at,
      workspace_created_at: org.created_at,
      workspace_updated_at: org.updated_at,
      // workspace_deleted_at: org.deleted_at,
      workspace_user_role: org.role,
      workspace_user_permissions: org.permissions,

      workspace_answer_tags: org.answer_tags,

      // stats
      workspace_total_respondent_count: org.stats.total_respondents,
      workspace_total_display_count: null, // DEPRECATED
      workspace_total_response_count: null, // DEPRECATED
      workspace_total_events_track_count: org.stats.total_events_track,
      workspace_total_events_screen_count: org.stats.total_events_screen,
      workspace_total_identity_properties_count:
        org.stats.total_identity_properties,
      workspace_total_group_assignations_count:
        org.stats.total_group_assignations,
      workspace_total_response_answer_media_uploaded:
        org.stats.total_response_answer_media_uploaded,
      workspace_total_response_answer_media_size_uploaded:
        org.stats.total_response_answer_media_size_uploaded,
      workspace_total_survey_responses: org.stats.total_survey_responses,
      workspace_total_survey_responses_not_started:
        org.stats.total_survey_responses_not_started,
      workspace_total_survey_responses_partially_completed:
        org.stats.total_survey_responses_partially_completed,
      workspace_total_survey_responses_fully_completed:
        org.stats.total_survey_responses_fully_completed,
      workspace_total_survey_responses_started:
        org.stats.total_survey_responses_started,
      workspace_total_message_responses: org.stats.total_message_responses,
      workspace_total_message_responses_not_started:
        org.stats.total_message_responses_not_started,
      workspace_total_message_responses_partially_completed:
        org.stats.total_message_responses_partially_completed,
      workspace_total_message_responses_fully_completed:
        org.stats.total_message_responses_fully_completed,
      workspace_total_message_responses_started:
        org.stats.total_message_responses_started,

      workspace_rolling_month_respondent_count:
        org.stats.rolling_month_respondents,
      workspace_rolling_month_display_count: null, // DEPRECATED
      workspace_rolling_month_response_count: null, // DEPRECATED
      workspace_rolling_month_events_track_count:
        org.stats.rolling_month_events_track,
      workspace_rolling_month_events_screen_count:
        org.stats.rolling_month_events_screen,
      workspace_rolling_month_identity_properties_count:
        org.stats.rolling_month_identity_properties,
      workspace_rolling_month_group_assignations_count:
        org.stats.rolling_month_group_assignations,
      workspace_rolling_month_survey_responses:
        org.stats.rolling_month_survey_responses,
      workspace_rolling_month_survey_responses_not_started:
        org.stats.rolling_month_survey_responses_not_started,
      workspace_rolling_month_survey_responses_partially_completed:
        org.stats.rolling_month_survey_responses_partially_completed,
      workspace_rolling_month_survey_responses_fully_completed:
        org.stats.rolling_month_survey_responses_fully_completed,
      workspace_rolling_month_survey_responses_started:
        org.stats.rolling_month_survey_responses_started,
      workspace_rolling_month_message_responses:
        org.stats.rolling_month_message_responses,
      workspace_rolling_month_message_responses_not_started:
        org.stats.rolling_month_message_responses_not_started,
      workspace_rolling_month_message_responses_partially_completed:
        org.stats.rolling_month_message_responses_partially_completed,
      workspace_rolling_month_message_responses_fully_completed:
        org.stats.rolling_month_message_responses_fully_completed,
      workspace_rolling_month_message_responses_started:
        org.stats.rolling_month_message_responses_started,

      workspace_rolling_week_respondent_count:
        org.stats.rolling_week_respondents,
      workspace_rolling_week_display_count: null, // DEPRECATED
      workspace_rolling_week_response_count: null, // DEPRECATED
      workspace_rolling_week_events_track_count:
        org.stats.rolling_week_events_track,
      workspace_rolling_week_events_screen_count:
        org.stats.rolling_week_events_screen,
      workspace_rolling_week_identity_properties_count:
        org.stats.rolling_week_identity_properties,
      workspace_rolling_week_group_assignations_count:
        org.stats.rolling_week_group_assignations,
      workspace_rolling_week_survey_responses:
        org.stats.rolling_week_survey_responses,
      workspace_rolling_week_survey_responses_not_started:
        org.stats.rolling_week_survey_responses_not_started,
      workspace_rolling_week_survey_responses_partially_completed:
        org.stats.rolling_week_survey_responses_partially_completed,
      workspace_rolling_week_survey_responses_fully_completed:
        org.stats.rolling_week_survey_responses_fully_completed,
      workspace_rolling_week_survey_responses_started:
        org.stats.rolling_week_survey_responses_started,
      workspace_rolling_week_message_responses:
        org.stats.rolling_week_message_responses,
      workspace_rolling_week_message_responses_not_started:
        org.stats.rolling_week_message_responses_not_started,
      workspace_rolling_week_message_responses_partially_completed:
        org.stats.rolling_week_message_responses_partially_completed,
      workspace_rolling_week_message_responses_fully_completed:
        org.stats.rolling_week_message_responses_fully_completed,
      workspace_rolling_week_message_responses_started:
        org.stats.rolling_week_message_responses_started,

      workspace_rolling_day_respondent_count: org.stats.rolling_day_respondents,
      workspace_rolling_day_display_count: null, // DEPRECATED
      workspace_rolling_day_response_count: null, // DEPRECATED
      workspace_rolling_day_events_track_count:
        org.stats.rolling_day_events_track,
      workspace_rolling_day_events_screen_count:
        org.stats.rolling_day_events_screen,
      workspace_rolling_day_identity_properties_count:
        org.stats.rolling_day_identity_properties,
      workspace_rolling_day_group_assignations_count:
        org.stats.rolling_day_group_assignations,
      workspace_rolling_day_survey_responses:
        org.stats.rolling_day_survey_responses,
      workspace_rolling_day_survey_responses_not_started:
        org.stats.rolling_day_survey_responses_not_started,
      workspace_rolling_day_survey_responses_partially_completed:
        org.stats.rolling_day_survey_responses_partially_completed,
      workspace_rolling_day_survey_responses_fully_completed:
        org.stats.rolling_day_survey_responses_fully_completed,
      workspace_rolling_day_survey_responses_started:
        org.stats.rolling_day_survey_responses_started,
      workspace_rolling_day_message_responses:
        org.stats.rolling_day_message_responses,
      workspace_rolling_day_message_responses_not_started:
        org.stats.rolling_day_message_responses_not_started,
      workspace_rolling_day_message_responses_partially_completed:
        org.stats.rolling_day_message_responses_partially_completed,
      workspace_rolling_day_message_responses_fully_completed:
        org.stats.rolling_day_message_responses_fully_completed,
      workspace_rolling_day_message_responses_started:
        org.stats.rolling_day_message_responses_started,

      workspace_current_month_respondent_count:
        org.stats.current_month_respondents,
      workspace_current_month_display_count: null, // DEPRECATED
      workspace_current_month_response_count: null, // DEPRECATED
      workspace_current_month_events_track_count:
        org.stats.current_month_events_track,
      workspace_current_month_events_screen_count:
        org.stats.current_month_events_screen,
      workspace_current_month_identity_properties_count:
        org.stats.current_month_identity_properties,
      workspace_current_month_group_assignations_count:
        org.stats.current_month_group_assignations,
      workspace_current_month_survey_question_video_uploaded:
        org.stats.current_month_survey_question_video_uploaded,
      workspace_current_month_survey_question_video_size_uploaded:
        org.stats.current_month_survey_question_video_size_uploaded,
      workspace_current_month_response_answer_media_uploaded:
        org.stats.current_month_response_answer_media_uploaded,
      workspace_current_month_response_answer_media_size_uploaded:
        org.stats.current_month_response_answer_media_size_uploaded,
      workspace_current_month_survey_responses:
        org.stats.current_month_survey_responses,
      workspace_current_month_survey_responses_not_started:
        org.stats.current_month_survey_responses_not_started,
      workspace_current_month_survey_responses_partially_completed:
        org.stats.current_month_survey_responses_partially_completed,
      workspace_current_month_survey_responses_fully_completed:
        org.stats.current_month_survey_responses_fully_completed,
      workspace_current_month_survey_responses_started:
        org.stats.current_month_survey_responses_started,
      workspace_current_month_message_responses:
        org.stats.current_month_message_responses,
      workspace_current_month_message_responses_not_started:
        org.stats.current_month_message_responses_not_started,
      workspace_current_month_message_responses_partially_completed:
        org.stats.current_month_message_responses_partially_completed,
      workspace_current_month_message_responses_fully_completed:
        org.stats.current_month_message_responses_fully_completed,
      workspace_current_month_message_responses_started:
        org.stats.current_month_message_responses_started,

      workspace_monthly_respondent_count: null, // deprecated
      workspace_monthly_display_count: null, // deprecated
      workspace_monthly_response_count: null, // deprecated

      // data governance
      workspace_data_governance_retention_policy_identity_days: null, // deprecated
      workspace_data_governance_retention_policy_group_days: null, // deprecated
      workspace_data_governance_retention_policy_event_days: null, // deprecated
      workspace_data_governance_retention_policy_response_days: null, // deprecated
      workspace_data_governance_retention_policy_tracking_days:
        org.data_governance.retention_policy_tracking_days,
      workspace_data_governance_retention_policy_sessions_days:
        org.data_governance.retention_policy_sessions_days,
      workspace_data_governance_retention_policy_responses_days:
        org.data_governance.retention_policy_responses_days,
      workspace_data_governance_registry_limit_context_property:
        org.data_governance.registry_limit_context_property,
      workspace_data_governance_registry_limit_identity_property:
        org.data_governance.registry_limit_identity_property,
      workspace_data_governance_registry_limit_group_type:
        org.data_governance.registry_limit_group_type,
      workspace_data_governance_registry_limit_group_type_property:
        org.data_governance.registry_limit_group_type_property,
      workspace_data_governance_registry_limit_group:
        org.data_governance.registry_limit_group,
      workspace_data_governance_registry_limit_track:
        org.data_governance.registry_limit_track,
      workspace_data_governance_registry_limit_track_property:
        org.data_governance.registry_limit_track_property,
      workspace_data_governance_registry_limit_screen:
        org.data_governance.registry_limit_screen,
      workspace_data_governance_registry_limit_screen_property:
        org.data_governance.registry_limit_screen_property,
      workspace_data_governance_tracking_capability_cohort_enabled:
        org.data_governance.tracking_capability_cohort_enabled,
      workspace_data_governance_tracking_capability_page_enabled:
        org.data_governance.tracking_capability_page_enabled,
      workspace_data_governance_tracking_capability_screen_enabled:
        org.data_governance.tracking_capability_screen_enabled,
      workspace_data_governance_tracking_capability_device_enabled:
        org.data_governance.tracking_capability_device_enabled,
      workspace_data_governance_tracking_capability_ip_enabled:
        org.data_governance.tracking_capability_ip_enabled,
      workspace_data_governance_tracking_capability_geoip_enabled:
        org.data_governance.tracking_capability_geoip_enabled,
      workspace_data_governance_tracking_capability_click_enabled:
        org.data_governance.tracking_capability_click_enabled,
      workspace_data_governance_tracking_capability_tap_enabled:
        org.data_governance.tracking_capability_tap_enabled,
      workspace_data_governance_tracking_capability_copy_enabled:
        org.data_governance.tracking_capability_copy_enabled,
      workspace_data_governance_tracking_capability_paste_enabled:
        org.data_governance.tracking_capability_paste_enabled,
      workspace_data_governance_tracking_capability_highlight_enabled:
        org.data_governance.tracking_capability_highlight_enabled,
      workspace_data_governance_tracking_capability_zoom_enabled:
        org.data_governance.tracking_capability_zoom_enabled,
      workspace_data_governance_tracking_capability_rage_click_enabled:
        org.data_governance.tracking_capability_rage_click_enabled,
      workspace_data_governance_tracking_capability_dead_click_enabled:
        org.data_governance.tracking_capability_dead_click_enabled,
      workspace_data_governance_tracking_capability_thrashing_click_enabled:
        org.data_governance.tracking_capability_thrashing_click_enabled,
    });
    return this;
  }

  public withAccount(account: Account): EventTracking {
    if (!account) {
      return this;
    }

    Object.assign(this.properties, {
      user_id: account.id,
      user_email: account.email,
      user_firstname: account.firstname,
      user_lastname: account.lastname,
      user_job_title: account.flags.job_title,
      user_job_title_label:
        AccountJobTitlesToLabel[account.flags.job_title] ?? null,
      user_role: account.role,
      user_article_read: account.flags.article_read,
      user_onboarding_status: account.flags.onboarding_status,
      user_language: account.language,
      user_phone_number: account.phone_number,
      user_avatar: account.profile_picture,
      user_created_at: account.created_at,
      user_last_seen_at: account.last_seen_at,
    });
    return this;
  }

  public withUser(user: User): EventTracking {
    if (!user) return this;

    Object.assign(this.properties, {
      respondent_id: user.id,
      respondent_aliases: user.aliases,
      respondent_last_activity_at: user.last_activity_at,
      respondent_created_at: user.created_at,
      respondent_updated_at: user.updated_at,
    });
    return this;
  }

  public withAnswer(
    answer: AnalyticsResponseItemResponseAnswer,
  ): EventTracking {
    if (!answer) return this;

    Object.assign(this.properties, {
      answer_id: answer.answer_id,
      answer_type: answer.type,
      answer_value_string: answer.v_s,
      answer_value_boolean: answer.v_b,
      answer_value_number: answer.v_d,
      answer_value_long: answer.v_l,
      answer_value_date: answer.v_t,
      answer_value_file:
        answer.v_f && answer.v_s
          ? (answer.v_s ?? "Transcription not available")
          : null,
      answer_time: answer.time,
      answer_question_id: answer.question_id,
      answer_question_correlation_id: answer.question_correlation_id,
      answer_cta_type: answer.cta_type,
    });
    return this;
  }

  public withSurvey(survey: Survey): EventTracking {
    if (!survey) return this;

    Object.assign(this.properties, {
      survey_id: survey.id,
      survey_name: survey.title,
      survey_type: survey.type,

      survey_scenario_id: survey.scenario?.id,
      survey_scenario_version: survey.scenario?.version,
      survey_scenario_default_language: survey.scenario?.default_language,
      survey_scenario_author_id: survey.scenario?.author_id,
      survey_scenario_created_at: survey.scenario?.created_at,

      survey_created_at: survey.created_at,
      survey_updated_at: survey.updated_at,
      // survey_deleted: !!survey.deleted_at,
      // survey_deleted_at: survey.deleted_at,

      // optional
      survey_display_count: survey.stats.response_total, // @DEPRECATED
      survey_response_count:
        survey.stats.response_total -
        survey.stats.response_completion_not_started, // @DEPRECATED
      survey_response_total: survey.stats.response_total,
      survey_response_completion_not_started:
        survey.stats.response_completion_not_started,
      survey_response_completion_partially_completed:
        survey.stats.response_completion_partially_completed,
      survey_response_completion_fully_completed:
        survey.stats.response_completion_fully_completed,
      survey_response_rate: survey.stats.response_rate,
      survey_response_completion_rate: survey.stats.response_completion_rate,
      survey_response_deleted: survey.stats.response_deleted,
      survey_response_not_deleted: survey.stats.response_not_deleted,
      survey_response_active: survey.stats.response_active,
      survey_response_not_active: survey.stats.response_not_active,
      survey_response_translation_total:
        survey.stats.response_translation_total,
    });
    return this;
  }

  public withChannel(channel: Channel): EventTracking {
    if (!channel) return this;

    Object.assign(this.properties, {
      channel_id: channel.id,
      channel_name: channel.name,
      channel_type: channel.type,
      channel_created_at: channel.created_at,
      channel_updated_at: channel.updated_at,
    });
    return this;
  }

  public withIntegration(integration: Integration): EventTracking {
    if (!integration) return this;

    Object.assign(this.properties, {
      integration_id: integration.id,
      integration_type: integration.type,
      integration_created_at: integration.created_at,
      integration_updated_at: integration.updated_at,

      // optional
      integrations_settings: integration.settings,
    });
    return this;
  }

  public withAnalyticsFilters(
    filters: AnalyticsFilters,
    index: AnalyticsIndex,
  ): EventTracking {
    if (!filters) return this;

    const filtersCount = {};

    filters.forEach((filter: AnalyticsFilter) => {
      const key = `filter_${filter.type.toString()}_count`;
      if (filtersCount[key]) {
        return;
      }

      filtersCount[key] = [...filters].filter(
        (value: any) => String(value.value) === filter.type.toString(),
      ).length;
    });

    const filtersFormatted = filters.map((filter, index) => {
      let value: string;

      if (filter.value) {
        value = filter.value?.toString();
      } else if (filter.values) {
        value = filter.values.join(", ");
      }

      return `filter_${
        filter.type
      }_${index} ${filter.operator.toString()} ${value}`;
    });

    Object.assign(this.properties, {
      analytics_filters_count: filtersCount,
      analytics_filters: filtersFormatted,
      analytics_index: index,
    });
    return this;
  }

  public withProps(props: object): EventTracking {
    if (!props) return this;

    Object.assign(this.properties, props);
    return this;
  }

  public withSequence(sequence: Sequence): EventTracking {
    if (!sequence) return this;

    Object.assign(this.properties, {
      sequence_id: sequence.id,
      sequence_title: sequence.title,
      sequence_type: sequence.type,
      sequence_created_at: sequence.created_at,
      sequence_updated_at: sequence.updated_at,
    });
    return this;
  }

  public build() {
    return this.eventAdapter.trackEvent(this.name, this.properties);
  }
}

class EventPageView extends EventTracking {
  constructor(eventAdapter: TrackersService, name: string, properties: object) {
    super(eventAdapter, name as TrackingEventName, properties);
  }

  public build() {
    return this.eventAdapter.pageViewEvent(this.name, this.properties);
  }
}
