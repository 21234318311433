import { Component, Input } from "@angular/core";
import { ShareReportType } from "models/survey.model";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NgIf } from "@angular/common";
import { ShareLateralPanelComponent } from "../../../share-lateral-panel/share-lateral-panel.component";

@Component({
  selector: "share-reporting",
  templateUrl: "./share-reporting.component.html",
  styleUrls: ["./share-reporting.component.scss"],
  imports: [
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NgIf,
    ShareLateralPanelComponent,
  ],
})
export class ShareReportingComponent {
  @Input() reportType: ShareReportType = null;

  public lateralPanelOpened = false;

  constructor() {}

  openPanel() {
    this.lateralPanelOpened = true;
  }

  closePanel() {
    this.lateralPanelOpened = false;
  }
}
