import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";

import { Response } from "models/response.model";
import { UserDao } from "models/user.dao";
import { User } from "models/user.model";
import { RouterLink } from "@angular/router";
import { NgIf, NgFor } from "@angular/common";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import {
  NzTimelineComponent,
  NzTimelineItemComponent,
} from "ng-zorro-antd/timeline";
import { TrackByPropertyDirective } from "../../../../helpers/track-by-property";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { TagRegistryEntrySourceComponent } from "../../../common/user/tag-registry-entry-source/tag-registry-entry-source.component";
import { NzEmptyComponent } from "ng-zorro-antd/empty";
import { FormatDistanceToNowPipeModule } from "ngx-date-fns";
import { ResponseCompletionPipe } from "pipes/response-completion.pipe";
import { ResponseCompletionColorPipe } from "pipes/response-completion-color.pipe";
import { ToLocaleStringPipe } from "pipes/to-locale-string.pipe";

@Component({
  selector: "user-timeline",
  templateUrl: "./user-timeline.component.html",
  styleUrls: ["./user-timeline.component.scss"],
  imports: [
    RouterLink,
    NgIf,
    NzSpinComponent,
    NzTimelineComponent,
    NgFor,
    TrackByPropertyDirective,
    NzTimelineItemComponent,
    NzTagComponent,
    NzTooltipDirective,
    TagRegistryEntrySourceComponent,
    NzEmptyComponent,
    FormatDistanceToNowPipeModule,
    ResponseCompletionPipe,
    ResponseCompletionColorPipe,
    ToLocaleStringPipe,
  ],
})
export class UserTimelineComponent implements OnInit, OnChanges {
  @Input() public user: User;

  public userResponsesPG: Response[] = [];
  public loadingResponsesPG = false;
  public errorResponsesPG = null;
  public initialFetchResponsesPG = true;

  constructor(private userDao: UserDao) {}

  ngOnInit(): void {
    this.fetchResponses();
  }

  ngOnChanges({ user }: SimpleChanges): void {
    if (user?.currentValue?.id !== user?.previousValue?.id) {
      this.fetchResponses();
    }
  }

  private fetchResponses() {
    this.loadingResponsesPG = true;
    this.errorResponsesPG = null;

    return this.userDao
      .getUserResponses(this.user.org_id, this.user.id, 25)
      .then((responses) => {
        this.userResponsesPG = responses.reverse();
      })
      .catch((error) => {
        this.errorResponsesPG = error;
        console.error(error);
      })
      .finally(() => {
        this.loadingResponsesPG = false;
        this.initialFetchResponsesPG = false;
      });
  }
}
