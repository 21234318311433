import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  ActionButton,
  CTAMultipleChoice,
  getI18nTextLabelTranslation,
  SurveyLanguages,
} from "models/survey.dao.types";
import { CardValidator, getLength } from "./CardValidator";
export const ButtonValidator: CardValidator = (
  node: GraphNode,
  currentLanguage: SurveyLanguages,
  defaultLanguage: SurveyLanguages,
) => {
  const cta = node.node.question.call_to_action;
  if (!["multiple_choice", "pmf"].includes(cta.type))
    throw new Error("unexpected CTA type");
  if (node.isPreset) {
    throw Error("cannot add multiple choices in preset");
  }
  const getLabelText = (index: number): string => {
    const cta = node.node.question.call_to_action as CTAMultipleChoice;
    return decode(
      getI18nTextLabelTranslation(
        cta.choices[index].payload.label,
        currentLanguage,
        currentLanguage,
      ) || "",
    );
  };
  const textBtnErrors = (cta as CTAMultipleChoice).choices
    .map((btn: ActionButton, index: number) => {
      const text = getLabelText(index)?.trim() || "";
      if (getLength(text) < 1 && currentLanguage === defaultLanguage) {
        return ["Text is missing."];
      }
      if (getLength(text) > 70) {
        return ["Max 70 characters permitted."];
      }
      return [];
    })
    .filter((error) => error.length > 0);
  return {
    errors: textBtnErrors,
    warnings: [],
  };
};
