import { Component, Input } from "@angular/core";
import localeEmoji from "locale-emoji";
import { UserEvent } from "models/user-event.model";
import { UserRecord } from "models/user-record.model";
import { SquareIconComponent } from "../../../utils/square-icon/square-icon.component";
import { NgIf } from "@angular/common";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";
import { FormatDistanceToNowPipeModule } from "ngx-date-fns";
import { ToLocaleStringPipe } from "pipes/to-locale-string.pipe";

@Component({
  selector: "user-context",
  templateUrl: "./user-context.component.html",
  styleUrls: ["./user-context.component.scss"],
  imports: [
    SquareIconComponent,
    NgIf,
    ScreebIconComponent,
    FormatDistanceToNowPipeModule,
    ToLocaleStringPipe,
  ],
})
export class UserContextComponent {
  @Input() public userRecord: UserRecord;
  @Input() public userEvents: UserEvent[];

  constructor() {}

  public getLocaleEmoji(locale: string): string {
    return localeEmoji(locale) || "🏳️";
  }
}
