import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ActivatedRoute,
  CanDeactivate,
  Router,
  RouterLink,
} from "@angular/router";

import { Org } from "models/org.model";
import { PageComponentInterface } from "components/PageComponentInterface";
import { RoutingService } from "services/routing.service";
import { SequenceFunnel } from "models/sequence.types";
import { deepCopy } from "utils/object";
import { SequenceDao } from "models/sequence.dao";
import { NotificationHelper } from "helpers/notification.helper";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { SettingsService } from "services/settings.service";
import { RegistryEntry } from "models/registry.model";
import {
  NzBreadCrumbComponent,
  NzBreadCrumbItemComponent,
} from "ng-zorro-antd/breadcrumb";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { EditableTextComponent } from "../../../utils/editable-text/editable-text.component";
import { NgIf } from "@angular/common";
import { FunnelGridComponent } from "../../components/funnel-grid/funnel-grid.component";
import { FunnelHelpComponent } from "../../components/funnel-help/funnel-help.component";

@Component({
  selector: "page-funnel-edit",
  templateUrl: "./funnel-edit.component.html",
  styleUrls: ["./funnel-edit.component.scss"],
  imports: [
    NzBreadCrumbComponent,
    NzBreadCrumbItemComponent,
    RouterLink,
    NzButtonComponent,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NzWaveDirective,
    EditableTextComponent,
    NgIf,
    FunnelGridComponent,
    FunnelHelpComponent,
  ],
})
export class FunnelEditPageComponent
  implements
    PageComponentInterface,
    OnInit,
    OnDestroy,
    CanDeactivate<FunnelEditPageComponent>
{
  public title = "Edit funnel";
  public name = "Edit funnel";

  private obs: any = null;
  public org: Org = null;
  public funnel: SequenceFunnel = null;
  public surveys: Survey[] = null;
  public surveysById: Record<UUID, Survey> = {};
  public baseFunnel: SequenceFunnel = null;
  public registryEntriesEvent: RegistryEntry[] = [];
  public needHelpVisible = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    private sequenceDao: SequenceDao,
    private notificationHelper: NotificationHelper,
    private settingsService: SettingsService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.funnel = data.funnel;
      this.baseFunnel = deepCopy(this.funnel);
      this.registryEntriesEvent = data.registryEntriesEvent;
      this.surveys = data.surveys;
      this.surveysById = this.surveys.reduce(
        (surveysById, survey) => ({
          ...surveysById,
          [survey.id]: survey,
        }),
        {},
      );
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  canDeactivate(): boolean {
    return true;
  }

  hasChanged() {
    return JSON.stringify(this.baseFunnel) !== JSON.stringify(this.funnel);
  }

  saveFunnel() {
    this.sequenceDao
      .patch(
        this.org.id,
        this.funnel.id,
        this.removeImpossibleSurveys(this.funnel),
      )
      .then((sequence) => {
        this.notificationHelper.trigger(
          "Funnel successfully updated!",
          null,
          "success",
        );
        this.baseFunnel = sequence;
        this.funnel = deepCopy(sequence);
      })
      .catch(() =>
        this.notificationHelper.trigger(
          "Failed to update funnel, please retry.",
          null,
          "error",
        ),
      );
  }

  removeImpossibleSurveys(funnel: SequenceFunnel): SequenceFunnel {
    funnel.funnel.steps[funnel.funnel.steps.length - 1].survey_on_drop = null;

    return funnel;
  }

  async onDisplaySurveyClick(url: string) {
    await this.saveFunnel();
    this.settingsService.setAdminSettingsKey(
      "funnel_create_survey_from",
      "edit",
    );
    this.router.navigateByUrl(url);
  }
}
