import { Component, Input, OnInit } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";
import { NgIf, NgClass, NgFor } from "@angular/common";
import {
  NzSelectComponent,
  NzOptionGroupComponent,
  NzOptionComponent,
} from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { EntitlementPipe } from "pipes/entitlement.pipe";

@Component({
  selector: "rule-country",
  templateUrl: "./rule-country.component.html",
  styleUrls: ["./rule-country.component.scss"],
  imports: [
    NgIf,
    NzSelectComponent,
    NgClass,
    FormsModule,
    NzOptionGroupComponent,
    NgFor,
    NzOptionComponent,
    EntitlementPipe,
  ],
})
export class TargetingRuleCountryComponent implements OnInit {
  @Input() public rule: TargetingRule = null;
  @Input() public countries: string[][] = [];

  public mainCountries: string[][] = [];

  constructor() {}

  ngOnInit() {
    this.mainCountries = this.countries.filter((country: string[]) => {
      return ["US", "GB", "FR", "ES", "DE", "CN"].includes(country[0]);
    });
  }
}
