import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import validateEmail from "all-good-emails";

import { ScreebApiHelper } from "services/auth.service";
import { NotificationHelper } from "helpers/notification.helper";
import { EntitlementService } from "services/entitlement.service";
import { BigCheckbox } from "../../../../../utils/big-checkbox/big-checkbox.component";
import { FormsModule } from "@angular/forms";
import { TagInputComponent } from "../../../../../utils/tag-input/tag-input.component";
import { NgIf } from "@angular/common";

@Component({
  selector: "share-lateral-panel-email",
  templateUrl: "./share-via-email.component.html",
  styleUrls: ["./share-via-email.component.scss"],
  imports: [BigCheckbox, FormsModule, TagInputComponent, NgIf],
})
export class EmailShareLateralPanelComponent implements OnInit, OnDestroy {
  @Input() emails: string[];
  @Input() enabled: boolean;

  @Output() emailsChange = new EventEmitter<string[]>();
  @Output() enabledChange = new EventEmitter<boolean>();
  @Output() validChange = new EventEmitter<boolean>();

  public quotaOk: boolean;

  private obs: any;

  constructor(
    private route: ActivatedRoute,
    private screebApiHelper: ScreebApiHelper,
    private entitlementService: EntitlementService,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit(): void {
    this.quotaOk = this.entitlementService.isIntegrationAvailable("email");

    this.isEmailValid();
  }

  ngOnDestroy(): void {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public isEmailValid() {
    let valid = true;
    if (this.enabled && this.emails) {
      valid = this.emails.length > 0 && this.emails.every(validateEmail);
    }

    this.validChange.emit(valid);
    return valid;
  }

  public onEnabledChange($event: boolean) {
    this.enabledChange.emit($event);
    this.isEmailValid();
  }
}
