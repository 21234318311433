/* eslint-disable @angular-eslint/no-output-on-prefix */
import { CdkOverlayOrigin } from "@angular/cdk/overlay";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgClass } from "@angular/common";

export type OnClickEvent = {
  event: Event;
  origin: any;
};

@Component({
  selector: "action-dot",
  templateUrl: "./ActionDot.component.html",
  styleUrls: ["./ActionDot.component.scss"],
  imports: [CdkOverlayOrigin, NgClass],
})
export class ActionDotComponent {
  constructor() {}

  @Input()
  displayPlainState: boolean = false;

  @Output()
  onClick = new EventEmitter<OnClickEvent>();

  @Input()
  hasMenuOpen: boolean = false;

  public internalOnClick(event: Event, origin: CdkOverlayOrigin) {
    this.onClick.emit({
      event: event,
      origin: origin,
    });
  }

  public get classes(): string {
    return `action-dot-button ${
      this.displayPlainState ? "force-hover-state" : ""
    } ${this.hasMenuOpen ? "enforce-hover" : ""}`;
  }
}
