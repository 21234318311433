import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  NzSelectOptionInterface,
  NzSelectComponent,
} from "ng-zorro-antd/select";

import { AnalyticsIndex, AnalyticsQuery } from "models/analytics.filters.type";
import { Org } from "models/org.model";
import { UUID } from "models/survey.dao.types";
import { ShareReportType, Survey } from "models/survey.model";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";
import { LanguageWithEmoji, UIService } from "services/ui.service";
import { arrayToSet } from "utils/array";
import { deepCopy } from "utils/object";
import { RegistryDao } from "models/registry.dao";
import { ActivatedRoute } from "@angular/router";
import { RegistryEntry } from "models/registry.model";
import { getFormattedUserGroupNameOrID } from "models/user-group.types";
import { NgIf, NgClass } from "@angular/common";
import { FiltersCSVExportComponent } from "./csv-export/csv-export.component";
import { ShareReportingComponent } from "./share/reporting/share-reporting.component";
import { DateFilterStatsSurveyComponent } from "./filter-date/filter-date.component";
import { FormsModule } from "@angular/forms";
import { NzBadgeComponent } from "ng-zorro-antd/badge";
import { CriteriaFilterStatsResponseSurveyComponent } from "./filter-criteria/filter-criteria-response.component";
import { CriteriaFilterStatsUserSurveyComponent } from "./filter-criteria/filter-criteria-user.component";
import { NzCheckboxComponent } from "ng-zorro-antd/checkbox";
import { CriteriaFilterSessionComponent } from "./filter-criteria/filter-criteria-session.component";
import { PermissionPipe } from "pipes/permission.pipe";
import { FeaturePipe } from "pipes/feature.pipe";

@Component({
  selector: "survey-stats-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"],
  imports: [
    NgIf,
    FiltersCSVExportComponent,
    ShareReportingComponent,
    DateFilterStatsSurveyComponent,
    NzSelectComponent,
    FormsModule,
    NzBadgeComponent,
    CriteriaFilterStatsResponseSurveyComponent,
    CriteriaFilterStatsUserSurveyComponent,
    NzCheckboxComponent,
    NgClass,
    CriteriaFilterSessionComponent,
    PermissionPipe,
    FeaturePipe,
  ],
})
export class FiltersStatsSurveyComponent implements OnInit, OnDestroy {
  @Input() public org: Org = null;
  @Input() public survey: Survey = null;
  @Input() public reporting: string = null;
  @Input() public canExport = true;
  @Input() public canShare = true;
  @Input() public canFilterByDate = true;
  @Input() public type: AnalyticsIndex;
  @Input() public reportType: ShareReportType = null;
  @Input() public isDisplay: boolean = false;
  @Input() public hideUserFilters: boolean = false;

  private filtersObs$: any = null;
  public lastFilters: AnalyticsQuery;
  public defaultFilters: AnalyticsQuery;

  public userGroupsOptions: NzSelectOptionInterface[] = [];
  public selectedUserGroups: string[] = null;

  public isLoading: boolean = false;
  public registryEntriesGroup: RegistryEntry[] = [];
  public registryEntriesEvent: RegistryEntry[] = [];
  public registryEntriesIdentityProperty: RegistryEntry[] = [];
  public languages: LanguageWithEmoji[] = null;

  constructor(
    private analyticsFilterService: AnalyticsFilterService,
    private trackersService: TrackersService,
    public featureFlaggingService: FeatureFlaggingService,
    public uiService: UIService,
    public permissionsService: PermissionsService,
    private registryDao: RegistryDao,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQuery) => {
        this.lastFilters = this.addCustomParamsForCSVExport(deepCopy(filters));
        this.refreshUserGroupsOptions();
      });

    this.languages =
      this.uiService.languagesAndCountries.surveyLanguagesWithEmojis;

    this.defaultFilters = this.addCustomParamsForCSVExport(
      this.getInitialFilters(),
    );

    this.onDisplay();
  }

  ngOnDestroy() {
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  public async onDisplay() {
    this.isLoading = true;
    try {
      const allLocations =
        this.route.snapshot.queryParamMap.get("allLocations") === "true";
      await this.registryDao
        .getGroups(this.uiService.currentOrgId, allLocations)
        .then((registryEntriesGroup) => {
          this.registryEntriesGroup = registryEntriesGroup.groups;
          this.refreshUserGroupsOptions();
        });

      await this.registryDao
        .getEvents(this.uiService.currentOrgId)
        .then((registryEntriesEvent) => {
          this.registryEntriesEvent = registryEntriesEvent;
        });

      await this.registryDao
        .getIdentityProperties(this.uiService.currentOrgId)
        .then((registryEntriesIdentityProperty) => {
          this.registryEntriesIdentityProperty =
            registryEntriesIdentityProperty;
        });
    } catch (e) {
      console.error(e);
    }

    this.isLoading = false;
  }

  refreshUserGroupsOptions() {
    if (
      this.lastFilters.type === "respondent" ||
      this.lastFilters.type === "session"
    ) {
      this.selectedUserGroups = arrayToSet(
        this.lastFilters.filters.flatMap((filter) => {
          if (
            filter.type === "respondent" &&
            filter.key === "segment" &&
            filter.operator === "in"
          ) {
            return filter.values;
          }
          return [];
        }),
      );
    }

    this.userGroupsOptions = this.registryEntriesGroup.map((userGroup) => ({
      value: userGroup.id,
      label: getFormattedUserGroupNameOrID(userGroup),
    }));
  }

  selectedUserGroupsChange($event: string[]) {
    this.analyticsFilterService.setSelectedUserGroups($event);
  }

  private addCustomParamsForCSVExport(filters: AnalyticsQuery): AnalyticsQuery {
    filters = deepCopy(filters); // probably not needed, but just in case...

    if (filters.type === "response") {
      filters.having_answer_only = true;
    }

    return filters;
  }

  public onExportWithFiltersClick() {
    // export event is sent into Segment from server side
    this.track("Export with filters", this.lastFilters);
  }
  public onExportAllClick() {
    // export event is sent into Segment from server side
    this.track("Export all", this.defaultFilters);
  }
  private track(eventName: TrackingEventName, filters: AnalyticsQuery) {
    // export event is sent into Segment from server side
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withAnalyticsFilters(filters.filters, filters.type)
      .build();
  }

  private getInitialFilters() {
    return this.analyticsFilterService.getInitialFilters(
      this.type,
      UUID(this.org.id),
      "AND",
      this.survey ? [UUID(this.survey.id)] : [],
      this.survey?.created_at || this.org?.created_at,
      undefined,
      undefined,
      undefined,
    );
  }

  public hideAnonymousOnChange(value: boolean) {
    this.analyticsFilterService.setIdentifiedOnly(value);

    this.trackersService
      .newEventTrackingBuilder("Respondent anonymous switched")
      .withOrg(this.org)
      .withProps({
        identified_only: value,
      })
      .build();
  }

  public hideViewedOnChange(value: boolean) {
    this.analyticsFilterService.setUnviewedOnly(value);

    this.trackersService
      .newEventTrackingBuilder("Session viewed switched")
      .withOrg(this.org)
      .withProps({
        viewed_only: value,
      })
      .build();
  }
}
