import { utcToZonedTime } from "date-fns-tz";
import {
  AnalyticsResponse,
  AnalyticsResponseItemResponseEmotions,
} from "models/analytics.model";
import { SurveyScenario } from "models/survey.dao.types";
import {
  IndustriesScores,
  IndustriesScoresType,
} from "resolvers/asset-industries-scores";
import { deepCopy } from "utils/object";
import { RepartitionIndicatorValues } from "./components/repartition-indicator/repartition-indicator.component";

export function computeVariation(
  previousValue: number,
  currentValue: number,
): number {
  if (!previousValue && !currentValue) {
    return 0;
  }

  return ((currentValue - previousValue) / Math.abs(previousValue)) * 100;
}

export type SimpleBucket = {
  doc_count: number;
  key: string;
  key_as_string?: string;
  correlation_id?: string;
};

export function replaceBucketsKeys(
  buckets: SimpleBucket[],
  scenarios: SurveyScenario[],
): SimpleBucket[] {
  buckets = deepCopy(buckets);

  return buckets.map((bucket) => {
    scenarios.forEach((scenario) => {
      scenario.nodes.forEach((node) => {
        const cta = node.question?.call_to_action;

        if (cta) {
          switch (cta.type) {
            case "multiple_choice":
            case "pmf":
            case "link":
              cta.choices.forEach((choice) => {
                if (choice.correlation_id === bucket.key) {
                  bucket.correlation_id = choice.correlation_id;
                  bucket.key = [
                    choice.payload.emoji,
                    choice.payload.label[scenario.default_language],
                  ]
                    .filter(Boolean)
                    .join(" ");
                  bucket.key_as_string = bucket.key;
                }
              });
              break;
            case "scoring":
            case "ces":
            case "csat":
            case "nps":
              cta.scores.forEach((score) => {
                if (score.correlation_id === bucket.key) {
                  bucket.correlation_id = score.correlation_id;
                  bucket.key = score.payload.value.toString();
                  bucket.key_as_string = score.payload.emoji;
                }
              });
              break;
            case "none":
            default:
          }
        }
      });
    });
    return bucket;
  });
}

export function getIndustryAverageScore(
  industriesScores: IndustriesScores = {},
  type: IndustriesScoresType,
  industry: string,
) {
  if (type === "ces") {
    return null;
  }

  const allIndustriesAverage = Math.round(
    Object.values(industriesScores).reduce(
      (sum, { scores: { [type]: score } }) => sum + score,
      0,
    ) / Object.entries(industriesScores).length,
  );

  if (!industry) {
    return allIndustriesAverage;
  }

  return industriesScores[industry]?.scores[type] ?? allIndustriesAverage;
}

export function computeIndicatorVariation(
  previousValue: number,
  previousTotal: number,
  currentValue: number,
  currentTotal: number,
): number {
  return computeVariation(
    previousValue / previousTotal,
    currentValue / currentTotal,
  );
}

export function computePercentage(count: number, total: number) {
  return total ? (count * 100) / total : 0;
}

export function computeRepartition(
  periodBucket: AnalyticsResponse,
  keys: readonly string[],
  getColor: (index: number) => string,
  getLabel: (index: number) => string,
  scenario: SurveyScenario,
): RepartitionIndicatorValues {
  return periodBucket.aggregations.date.buckets
    .reduce(
      (
        repartition,
        {
          answer: {
            answer: {
              answer: { buckets },
            },
          },
        },
      ) => {
        replaceBucketsKeys(buckets, [scenario]).forEach(
          ({ key, doc_count }) => {
            // This is dirty (not side effect free)
            repartition[keys.indexOf(key)] += doc_count;
          },
        );
        return repartition;
      },
      [...Array(keys.length).keys()].map(() => 0),
    )
    .map((value, index) => ({
      value,
      color: getColor(index),
      label: getLabel(index),
    }));
}

export function bucketToDatedAggregation<T>(
  periodBucket: AnalyticsResponse,
  bucketToAggregationFn: (oneDayCsat: Record<string, any>) => T,
): T[] {
  if (!periodBucket) {
    return [];
  }

  const buckets = periodBucket.aggregations?.date?.buckets ?? [];

  if (!buckets) {
    throw new Error("Aggregation failed.");
  }

  // sort response by most selected first
  return buckets
    .sort((a: Record<string, any>, b: Record<string, any>) => b.key - a.key)
    .map((bucket: Record<string, any>) => ({
      ...bucket,
      date: utcToZonedTime(new Date(bucket.key_as_string), "UTC"),
    }))
    .map(bucketToAggregationFn);
}

export function getDateAnswerValueBuckets(record: Record<string, any>) {
  return record?.answer?.answer?.answer?.buckets ?? [];
}

export function getDateCompletionBuckets(record: Record<string, any>) {
  return record?.completion?.buckets ?? [];
}

export function getDateCompletionSupportBuckets(
  record: Record<string, any>,
  completion:
    | "inserted"
    | "not_started"
    | "partially_completed"
    | "fully_completed",
) {
  return getDateCompletionBuckets(record)
    .map(
      ({
        key: completion,
        hidden_field: {
          hidden_field: {
            hidden_field: { buckets },
          },
        },
      }) => ({ completion, buckets }),
    )
    .filter((a) => a.completion === completion)
    .map(({ buckets }) => buckets)
    .flat();
}

export function bucketToCountPerKey<T extends string>(
  buckets: {
    key: string;
    doc_count: number;
  }[],
  keys: readonly T[],
): {
  total: number;
  countPerKey: Record<T, number>;
} {
  const countPerKey = keys.reduce((acc: Record<string, number>, b: string) => {
    acc[b] = 0;
    return acc;
  }, {});

  let total = 0;

  buckets.forEach(({ key, doc_count }) => {
    if (keys.includes(key as T) && doc_count) {
      countPerKey[key] += doc_count;
      total += doc_count;
    }
  });

  return {
    countPerKey,
    total,
  };
}

export type CompletionRateAggregation = {
  score: number;
  fullyCompleted: number;
  partiallyCompleted: number;
  notStarted: number;
  total: number;
  date: Date | null;
};

export type ResponseNumberAggregation = {
  score: number;
  total: number;
  date: Date | null;
};

export type DisplayNumberAggregation = {
  score: number;
  total: number;
  date: Date | null;
};

export type ResponseRateAggregation = {
  score: number;
  fullyCompleted: number;
  partiallyCompleted: number;
  notStarted: number;
  total: number;
  date: Date | null;
};

export function bucketToCompletionRate(
  record: Record<string, any>,
): CompletionRateAggregation {
  const completionBuckets = getDateCompletionBuckets(record);

  const date = record.date;

  const {
    countPerKey: { fully_completed, partially_completed, not_started },
    total,
  } = bucketToCountPerKey(completionBuckets, [
    "fully_completed",
    "partially_completed",
    "not_started",
  ]);

  return {
    score: computePercentage(fully_completed, total),
    fullyCompleted: fully_completed,
    partiallyCompleted: partially_completed,
    notStarted: not_started,
    total,
    date,
  };
}

export function bucketToResponseRate(
  record: Record<string, any>,
): ResponseRateAggregation {
  const completionBuckets = getDateCompletionBuckets(record);

  const date = record.date;

  const {
    countPerKey: { fully_completed, partially_completed, not_started },
    total,
  } = bucketToCountPerKey(completionBuckets, [
    "fully_completed",
    "partially_completed",
    "not_started",
  ]);

  return {
    score: computePercentage(fully_completed + partially_completed, total),
    fullyCompleted: fully_completed,
    partiallyCompleted: partially_completed,
    notStarted: not_started,
    total,
    date,
  };
}

export function computeCompletionRatePeriod(
  completionRatePerDate: CompletionRateAggregation[],
): CompletionRateAggregation {
  const fullyCompleted = completionRatePerDate.reduce(
    (acc: number, completionRate: CompletionRateAggregation): number =>
      acc + completionRate.fullyCompleted,
    0,
  );
  const partiallyCompleted = completionRatePerDate.reduce(
    (acc: number, completionRate: CompletionRateAggregation): number =>
      acc + completionRate.partiallyCompleted,
    0,
  );
  const notStarted = completionRatePerDate.reduce(
    (acc: number, completionRate: CompletionRateAggregation): number =>
      acc + completionRate.notStarted,
    0,
  );
  const total = completionRatePerDate.reduce(
    (acc: number, completionRate: CompletionRateAggregation): number =>
      acc + completionRate.total,
    0,
  );
  const score = computePercentage(fullyCompleted, total);

  return {
    fullyCompleted,
    partiallyCompleted,
    notStarted,
    total,
    score,
    date: null,
  };
}

export function bucketToResponseNumber(
  record: Record<string, any>,
): ResponseNumberAggregation {
  const completionBuckets = getDateCompletionBuckets(record);

  const date = record.date;

  const {
    countPerKey: { fully_completed, partially_completed },
  } = bucketToCountPerKey(completionBuckets, [
    "fully_completed",
    "partially_completed",
    "not_started",
  ]);

  const score = fully_completed + partially_completed;

  return {
    score,
    total: score,
    date,
  };
}

export function bucketToDisplayNumber(
  record: Record<string, any>,
): DisplayNumberAggregation {
  const completionBuckets = getDateCompletionBuckets(record);

  const date = record.date;

  const { total } = bucketToCountPerKey(completionBuckets, [
    "fully_completed",
    "partially_completed",
    "not_started",
  ]);

  return {
    score: total,
    total,
    date,
  };
}

export function computeResponseRatePeriod(
  completionRatePerDate: ResponseRateAggregation[],
): ResponseRateAggregation {
  const fullyCompleted = completionRatePerDate.reduce(
    (acc: number, completionRate: ResponseRateAggregation): number =>
      acc + completionRate.fullyCompleted,
    0,
  );
  const partiallyCompleted = completionRatePerDate.reduce(
    (acc: number, completionRate: ResponseRateAggregation): number =>
      acc + completionRate.partiallyCompleted,
    0,
  );
  const notStarted = completionRatePerDate.reduce(
    (acc: number, completionRate: ResponseRateAggregation): number =>
      acc + completionRate.notStarted,
    0,
  );
  const total = completionRatePerDate.reduce(
    (acc: number, completionRate: ResponseRateAggregation): number =>
      acc + completionRate.total,
    0,
  );
  const score = computePercentage(fullyCompleted + partiallyCompleted, total);

  return {
    fullyCompleted,
    partiallyCompleted,
    notStarted,
    total,
    score,
    date: null,
  };
}

export function computeResponseNumberPeriod(
  completionRatePerDate: ResponseNumberAggregation[],
): ResponseNumberAggregation {
  const score = completionRatePerDate.reduce(
    (acc: number, completionRate: ResponseNumberAggregation): number =>
      acc + completionRate.score,
    0,
  );

  return {
    score,
    total: score,
    date: null,
  };
}

export function computeDisplayNumberPeriod(
  completionRatePerDate: DisplayNumberAggregation[],
): DisplayNumberAggregation {
  const score = completionRatePerDate.reduce(
    (acc: number, completionRate: DisplayNumberAggregation): number =>
      acc + completionRate.score,
    0,
  );

  return {
    score,
    total: score,
    date: null,
  };
}

export const scopeToOne = (numbers: number[]) => {
  const max = Math.max(...numbers, 1);

  return numbers.map((number) => number / max);
};

export const computeEmotionsDataset = (
  response: AnalyticsResponse,
): AnalyticsResponseItemResponseEmotions => {
  const { emotions } = response.aggregations;

  const [sadness, joy, anger, fear] = scopeToOne([
    emotions.sadness?.avg?.value ?? 0,
    emotions.joy?.avg?.value ?? 0,
    emotions.anger?.avg?.value ?? 0,
    emotions.fear?.avg?.value ?? 0,
  ]).map((a) => a * 5);

  return { sadness, joy, anger, fear, sentiment: "neutral" };
};
