import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  htmlSanitizer,
  stripHtml,
} from "components/builder/components/Cards/sanitized-message/sanitizer";
import { GraphNode } from "components/builder/flow";
import { NotificationHelper } from "helpers/notification.helper";
import { decode } from "html-entities";
import {
  I18FileData,
  MessageVideo,
  SurveyLanguages,
  getI18nVideoLabelTranslation,
  setI18nVideoLabelTranslation,
} from "models/survey.dao.types";
import { Subscription } from "rxjs";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import {
  MediaUploadService,
  UploadState,
  VideoMIMETypeToExt,
} from "services/media-upload.service";
import { TrackersService } from "services/trackers.service";
import { BuilderStore } from "stores/builder.store";
import { randomString } from "utils/random";
import { BigCardValidatorFactory } from "../../BuilderLayout/Validators/CardValidator";
import { NgIf, NgClass } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { LanguageSelect } from "../../../../utils/language-select/language-select.component";
import { FormsModule } from "@angular/forms";
import { ScreebIconComponent } from "../../../../utils/screeb-icon/screeb-icon.component";
import { NzProgressComponent } from "ng-zorro-antd/progress";
import { NzSwitchComponent } from "ng-zorro-antd/switch";
import { FormErrorComponent } from "../../../../../utils/form-error/form-error.component";
import { LateralPanelStepPickerComponent } from "../step-picker/step-picker.component";
import { SurveyRecordVideoModalComponent } from "./video-record-modal/video-record-modal.component";
import { SurveyPreviewVideoModalComponent } from "./video-preview-modal/video-preview-modal.component";
import { EntitlementPipe } from "pipes/entitlement.pipe";

@Component({
  selector: "lateral-panel-edit-video",
  templateUrl: "./edit-video.component.html",
  styleUrls: ["./edit-video.component.scss"],
  imports: [
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzPopoverDirective,
    NzIconDirective,
    LanguageSelect,
    FormsModule,
    ScreebIconComponent,
    NzProgressComponent,
    NzSwitchComponent,
    NgClass,
    FormErrorComponent,
    LateralPanelStepPickerComponent,
    SurveyRecordVideoModalComponent,
    SurveyPreviewVideoModalComponent,
    EntitlementPipe,
  ],
})
export class LateralPanelEditVideoComponent
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild("VideoInput") videoInput: ElementRef<HTMLInputElement>;
  @ViewChild("videoThumbnail", { static: false })
  videoThumbnail: ElementRef<HTMLVideoElement>;
  @Input() node: GraphNode = null;
  @Input() message: MessageVideo = null;
  @Input() nextQuestionPickerEnabled: boolean = false;
  @Input() language: SurveyLanguages = "en";

  @Output() suggest = new EventEmitter<unknown>();
  @Output() languageChange = new EventEmitter<SurveyLanguages>();
  @Output() errorChange = new EventEmitter<boolean>();

  public uniqHash: string;
  public recordModalOpened = false;
  public previewModalOpened = false;

  private uploadId: string = null;
  public file: File = null;
  public videoPlaying: boolean = false;
  public uploadState: UploadState = { state: "PENDING", progress: 0 };

  private subscriptionStart: Subscription;
  private subscriptionProgress: Subscription;
  private subscriptionComplete: Subscription;

  public videoErrors: string[] = [];
  public overlayErrors: string[] = [];

  constructor(
    public builderStore: BuilderStore,
    public featureFlaggingService: FeatureFlaggingService,
    public entitlementService: EntitlementService,
    private mediaUploadService: MediaUploadService,
    private notificationHelper: NotificationHelper,
    private trackersService: TrackersService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.uniqHash = randomString(5);
    if (this.message.type !== "video") throw Error("unexpected message type");

    this.subscribeToUploadStart();
    this.validateData();
  }

  ngOnDestroy() {
    this.subscriptionStart?.unsubscribe();
    this.subscriptionProgress?.unsubscribe();
    this.subscriptionComplete?.unsubscribe();
  }

  ngOnChanges({ language }: SimpleChanges) {
    if (!!language && language.previousValue !== language.currentValue) {
      if (
        !getI18nVideoLabelTranslation(
          this.message.video,
          this.language,
          this.language,
        )
      ) {
        setI18nVideoLabelTranslation(this.message.video, {}, this.language);
      }

      this.validateData();
    }
  }

  subscribeToUploadStart() {
    this.subscriptionStart = this.mediaUploadService
      .subscribeStart()
      .subscribe((uploadId) => {
        if (uploadId) {
          this.uploadId = uploadId;
          this.subscribeToUploadProgress();
          this.subscribeToUploadComplete();
        }
      });
  }

  subscribeToUploadProgress() {
    this.subscriptionProgress = this.mediaUploadService
      .subscribeProgress()
      .subscribe((status) => {
        if (!!status && this.uploadState.state !== "DONE") {
          this.uploadState = status;
        }
      });
  }

  subscribeToUploadComplete() {
    this.subscriptionComplete = this.mediaUploadService
      .subscribeComplete()
      .subscribe((result) => {
        if (result === null) {
          // upload cancelled
          this.resetUpload(true);
          return;
        }
        this.video.url = result.public_url;
        this.video.video_id = result.video_id;
        if (this.videoThumbnail) {
          this.videoThumbnail.nativeElement.load();
        }
        this.resetUpload(false);
      });
  }

  public get video(): I18FileData {
    return (
      getI18nVideoLabelTranslation(
        this.message.video,
        this.language,
        this.language,
      ) || {}
    );
  }

  public set video(fileData: I18FileData) {
    setI18nVideoLabelTranslation(this.message.video, fileData, this.language);
  }

  public get overlay(): string {
    return decode(this.video.overlay || "");
  }

  public set overlay(overlay: string) {
    setI18nVideoLabelTranslation(
      this.message.video,
      {
        ...this.video,
        overlay,
      },
      this.language,
    );
  }

  public get disabledSubtitle(): boolean {
    return this.video.disabled_subtitle || false;
  }

  public set disabledSubtitle(disabled_subtitle: boolean) {
    setI18nVideoLabelTranslation(
      this.message.video,
      {
        ...this.video,
        disabled_subtitle,
      },
      this.language,
    );
  }

  /**
   * Data validation
   */
  public validateData() {
    const { errors } = BigCardValidatorFactory.getValidatorFromNode(this.node)(
      this.node,
      this.language,
      this.builderStore.survey.scenario.default_language,
    );

    [this.videoErrors, this.overlayErrors] = errors;

    this.errorChange.emit(
      this.videoErrors.length > 0 || this.overlayErrors.length > 0,
    );
  }

  public getMessagePlaceholder(): string | null {
    if (
      this.getLength(this.overlay) < 1 &&
      this.language !== this.builderStore.survey.scenario.default_language
    ) {
      return "Missing translation";
    }
    return "";
  }

  getLength(text: string) {
    return stripHtml(
      htmlSanitizer(text, {
        styling: true,
        CR: false,
        nativeCR: true,
        links: true,
      }),
    ).length;
  }

  /** Video Upload Related **/

  private getFileName(contentType: string = "video/mp4") {
    const ext = VideoMIMETypeToExt[contentType] || "mp4";

    if (this.file === null && this.video.url === null) {
      return `${this.node.id}.${ext}`;
    }

    if (this.video.url) {
      const fileNames = this.video.url.split("/");
      return fileNames[fileNames.length - 1];
    } else if (this.file) {
      const originalFileName = this.file?.name;
      const parts = originalFileName.split(".");
      const extension = parts[parts.length - 1];

      return `${this.node.id}.${extension}`;
    }

    return `${this.node.id}.${ext}`;
  }

  private resetUpload(resetFile: boolean = false) {
    this.subscriptionStart?.unsubscribe();
    this.subscriptionProgress?.unsubscribe();
    this.subscriptionComplete?.unsubscribe();

    this.uploadState = { id: 0, progress: 0, state: "PENDING" };
    this.videoPlaying = false;
    this.uploadId = null;

    if (resetFile) {
      this.file = null;
      if (this.videoInput) {
        this.videoInput.nativeElement.value = "";
        this.videoInput.nativeElement.files = null;
      }

      this.video.video_id = null;
      this.video.url = null;
      this.video.thumbnail_urls = null;
    }

    this.validateData();
  }

  uploadVideo(video: File) {
    if (video === undefined) {
      return;
    }
    this.file = video;

    this.subscriptionStart?.unsubscribe(); // unsubscribe from previous upload, in case sidebar was closed and reopened
    this.subscribeToUploadStart();

    this.mediaUploadService
      .uploadMultipart(
        "survey_question",
        {
          orgId: this.builderStore.org.id,
          surveyId: this.builderStore.survey.id,
        },
        video,
        this.node.id,
      )
      .catch(() => {
        this.onVideoUploadCancel();
        this.notificationHelper.trigger(
          "Something went wrong",
          "Failed to upload file",
          "error",
        );
      });
  }

  /** Event Handlers **/
  onVideoSubmit(video: File) {
    if (video === undefined) {
      return;
    }

    if (!video.type.startsWith("video/") && !VideoMIMETypeToExt[video.type]) {
      this.notificationHelper.trigger(
        "Invalid file type",
        "Please upload a video file",
        "error",
      );
      return;
    }

    const file = new File([video], this.getFileName(video.type), {
      type: video.type,
    });
    this.uploadVideo(file);

    this.trackersService
      .newEventTrackingBuilder("Survey question video uploaded")
      .withOrg(this.builderStore.org)
      .withSurvey(this.builderStore.survey)
      .withProps({
        file_name: video.name,
        file_size: video.size,
        file_type: video.type,
      })
      .build();
  }

  onVideoUploadCancel() {
    this.resetUpload(true);
    if (!this.file && !this.video.url) {
      return;
    }

    if (this.uploadId !== null) {
      this.mediaUploadService.cancelUpload(
        "survey_question",
        {
          orgId: this.builderStore.org.id,
          surveyId: this.builderStore.survey.id,
        },
        this.uploadId,
      );
    }

    this.trackersService
      .newEventTrackingBuilder("Survey question video canceled")
      .withOrg(this.builderStore.org)
      .withSurvey(this.builderStore.survey)
      .withProps({
        file_name: this.file.name,
        file_size: this.file.size,
        file_type: this.file.type,
      })
      .build();
  }

  setRecordModalOpened(opened: boolean) {
    this.recordModalOpened = opened;
  }

  setPreviewModalOpened(opened: boolean) {
    this.previewModalOpened = opened;

    if (opened) {
      this.trackersService
        .newEventTrackingBuilder("Survey question video previewed")
        .withOrg(this.builderStore.org)
        .withSurvey(this.builderStore.survey)
        .withProps({
          url: this.video.url,
        })
        .build();
    }
  }

  onVideoRecorded({ media }: { media: Blob }) {
    if (media === undefined) {
      return;
    }

    const file = new File(
      [media],
      this.getFileName(media.type.split(";")[0].trim()),
      {
        type: media.type,
      },
    );
    this.uploadVideo(file);

    this.trackersService
      .newEventTrackingBuilder("Survey question video recorded")
      .withOrg(this.builderStore.org)
      .withSurvey(this.builderStore.survey)
      .withProps({
        file_name: file.name,
        file_size: file.size,
        file_type: file.type,
      })
      .build();
  }

  upgradeClick() {
    this.router.navigate([
      "org",
      this.builderStore.org.id,
      "settings",
      "billing",
    ]);
  }
}
