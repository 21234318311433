import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, ResolveFn } from "@angular/router";

import { OrgDao } from "models/org.dao";
import { Org } from "models/org.model";
import { ApplicationLoadService } from "services/app.service";
import { UIService } from "services/ui.service";

export const orgFromQueryOrNullResolver: ResolveFn<Org | null> = (
  route: ActivatedRouteSnapshot,
): Promise<Org | null> => {
  const orgDao = inject(OrgDao);
  const uiService = inject(UIService);

  const orgId = route.queryParams["org_id"];
  if (!orgId) {
    // Check if we already have the org in the ui service
    const tmp = uiService.orgs[0];
    if (tmp) {
      return Promise.resolve(tmp);
    }

    return Promise.resolve(null);
  }

  return orgDao.getById(orgId);
};

@Injectable()
export class OrgResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private orgDao: OrgDao,
    private uiService: UIService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Org> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        // Check if we already have the org in the ui service
        const tmp = this.uiService.orgs.find(
          (org) => org.id === route.params["org_id"],
        );
        if (tmp) {
          return tmp;
        }
        return this.orgDao.getById(route.params["org_id"]);
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
