import { Component } from "@angular/core";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { NzIconDirective } from "ng-zorro-antd/icon";

@Component({
  selector: "page-not-found",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"],
  imports: [
    NzButtonComponent,
    ɵNzTransitionPatchDirective,
    RouterLink,
    RouterLinkActive,
    NzIconDirective,
  ],
})
class NotFoundPageComponent {
  public subtitle: string = "404 - Page not found";
  public text: string =
    "The page you are looking for might have been removed had its name changed or is temporarily unavailable.";

  constructor() {}
}

export { NotFoundPageComponent };
