import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Router, RouterLinkActive, RouterLink } from "@angular/router";
import { NgIf } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
// import {ObservableWrapper} from 'angular2/src/facade/async';

@Component({
  selector: "error-message",
  templateUrl: "./error-message.component.html",
  styleUrls: ["./error-message.component.scss"],
  imports: [
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    RouterLinkActive,
    RouterLink,
  ],
})
export class ErrorMessageComponent {
  @Input() public message = "There was an error";
  @Input() public details = "No details.";
  @Input() public gotoLink: string = null;
  @Input() public gotoLabel: string = null;

  @Output() public reload = new EventEmitter();
  // @Output() public onReload = new EventEmitter();

  constructor(private router: Router) {}

  public reloadPage() {
    if (this.reload.observed) {
      this.reload.emit();
    } else {
      // window.location.reload();

      const currentUrl = this.router.url;
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    }
  }
}
