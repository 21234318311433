import { Injectable } from "@angular/core";
import { routingSetCase } from "components/builder/routing";
import { ChannelDao } from "models/channel.dao";
import { RegistryEntrySource } from "models/registry.model";
import { Response, ResponseCompletion } from "models/response.model";
import { Serializable } from "models/serializable.model";
import { SurveyDao } from "models/survey.dao";
import {
  ActionButton,
  ActionScoring,
  CTAAction,
  CTAConditional,
  CTAMultipleChoiceAny,
  CTAScoring,
  CTAScoringAny,
  CTAType,
  MessageText,
  SurveyLanguages,
  SurveyScenario,
  UUID,
  getActionsFromQuestion,
  newCTASkip,
} from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { TagSettingsDao } from "models/tag-settings.dao";
import { User } from "models/user.model";
import { BuilderStore } from "stores/builder.store";
import { uuidv4 } from "utils/uuid";
import { ScreebApiHelper } from "./auth.service";
import { LanguageWithEmoji, UIService } from "./ui.service";

// Just a survey class with only the fields we need for importing
class ImportSurvey extends Serializable {
  constructor(
    public title?: string,
    public language?: SurveyLanguages,
    public tags?: string[],
    public questions?: ImportQuestion[],
    public responses?: ImportSurveyResponse[],
  ) {
    super();
  }
}

// Just a question class with only the fields we need for importing
class ImportQuestion extends Serializable {
  constructor(
    public id?: string,
    public type?: CTAType,
    public description?: string,
    public skip_id?: string,
    public options?: ImportQuestionOption[],
  ) {
    super();
  }
}

// Just a question option class with only the fields we need for importing
class ImportQuestionOption extends Serializable {
  constructor(
    public label?: string,
    public emoji?: string,
    public next_id?: string,
  ) {
    super();
  }
}

// Just a response class with only the fields we need for importing
class ImportSurveyResponse extends Serializable {
  constructor(
    public user_id?: string,
    public from?: RegistryEntrySource,
    public completion?: ResponseCompletion,
    public answers?: ImportSurveyResponseAnswer[],
    public hiddenFields?: Record<string, string>,
  ) {
    super();
  }
}

// Just a response answer class with only the fields we need for importing
class ImportSurveyResponseAnswer extends Serializable {
  constructor(
    public question_id?: string,
    public question_correlation_id?: string,
    public questionType?: CTAType,
    public type?: "none" | "string" | "number" | "boolean",
    public values?: (string | number | boolean)[],
  ) {
    super();
  }
}

class ImportedSurveyScenario extends Serializable {
  constructor(
    public scenario?: SurveyScenario,
    public questionsIdsMappping?: Record<string, [string, string]>,
  ) {
    super();
  }
}

// This service is used to import surveys from other platforms
@Injectable()
class ImportService {
  private _channelId?: string | null;

  constructor(
    private uiService: UIService,
    private surveyDao: SurveyDao,
    private tagSettingsDao: TagSettingsDao,
    private builderStore: BuilderStore,
    private screebApiHelper: ScreebApiHelper,
    private channelDao: ChannelDao,
  ) {}

  /**
   * The function `importSurvey` imports a survey along with its tags, questions,
   * and responses.
   * @param {ImportSurvey} survey - The `survey` parameter is an object that
   * contains information about the survey being imported. It has the following
   * properties:
   * @param {LanguageWithEmoji[]} langs - An array of objects representing
   * languages with emojis. Each object has two properties: "language" (string) and
   * "emoji" (string).
   * @returns a Promise that resolves to a Survey object.
   */
  public async importSurvey(
    survey: ImportSurvey,
    langs: LanguageWithEmoji[],
  ): Promise<Survey> {
    // First, create the survey
    const importedSurvey = await this.surveyDao.create(
      this.uiService.currentOrgId,
      "survey",
      survey.title,
      survey.language,
    );

    // If we have tags, add them
    if (survey.tags?.length) {
      await this.tagSettingsDao.updateSurveyTagSettings(
        this.uiService.currentOrgId,
        importedSurvey.id,
        {},
        { title: survey.title, tags: survey.tags },
        importedSurvey.integrations,
        importedSurvey.translation_enabled,
      );
    }

    // If there is a scenario, import it
    let questionsIdsMappping: Record<string, [string, string]> = null;
    if (survey.questions?.length) {
      const importedScenario = await this.importSurveyQuestions(
        importedSurvey.id,
        survey.questions,
        langs,
      );
      questionsIdsMappping = importedScenario.questionsIdsMappping;
      importedSurvey.scenario = importedScenario.scenario;

      // If there are responses, import them
      // we only import responses if we have a scenario
      if (survey.responses?.length) {
        for (const response of survey.responses) {
          await this.importSurveyResponse(
            importedSurvey,
            response,
            questionsIdsMappping,
          );
        }
      }
    }

    return importedSurvey;
  }

  /**
   * The `importSurveyQuestions` function is used to import survey questions,
   * initialize the builder, update the nodes and routing, and bind the first node
   * to the entrypoint.
   * @param {string} surveyId - The surveyId parameter is a string that represents
   * the unique identifier of the survey.
   * @param {ImportQuestion[]} questions - An array of objects representing the
   * survey questions to be imported. Each object should have the following
   * properties:
   * @param {LanguageWithEmoji[]} langs - An array of objects representing
   * languages with emojis. Each object has two properties: "language" (string) and
   * "emoji" (string).
   */
  public async importSurveyQuestions(
    surveyId: string,
    questions: ImportQuestion[],
    langs: LanguageWithEmoji[],
  ): Promise<ImportedSurveyScenario> {
    // We need to fetch the survey again to get the full scenario
    const survey = await this.surveyDao.getById(
      this.uiService.currentOrgId,
      surveyId,
    );

    // Then we can initialize the builder
    this.builderStore.initBuilder(
      this.uiService.currentOrg,
      survey,
      [],
      langs,
      [],
      () => undefined,
      true,
      false,
    );

    // Then we can build the scenario
    const importedScenario = this.buildSurveyScenario(questions);
    survey.scenario = importedScenario.scenario;

    // And finally update the survey flow with the new scenario
    await this.surveyDao.updateFlow(
      this.uiService.currentOrgId,
      surveyId,
      survey.scenario,
      true,
    );

    return importedScenario;
  }

  /**
   * The `buildSurveyScenario` function takes an array of questions and builds a
   * survey scenario by creating nodes and updating their properties based on the
   * questions.
   * @param {ImportQuestion[]} questions - An array of objects representing the
   * questions to be included in the survey scenario. Each question object should
   * have the following properties:
   * @returns The function `buildSurveyScenario` returns a `ImportedSurveyScenario` object.
   */
  public buildSurveyScenario(
    questions: ImportQuestion[],
  ): ImportedSurveyScenario {
    // Remove all nodes if any
    this.builderStore.nodes?.forEach((node) => {
      this.builderStore.removeNode(node);
    });

    // Begin by the end
    const reversedScenarioQuestions = [...questions].reverse();

    // Keep track of the last node ID to properly link the questions
    let lastNodeId = null;
    const mappedIds: Record<string, [string, string]> = {};
    const mappedOptionsToNextIds: Record<string, string> = {};
    for (let i = 0; i < reversedScenarioQuestions.length; i++) {
      const question = reversedScenarioQuestions[i];

      // Find the best type if not defined
      if (!question.type?.length) {
        if (question.options?.length) {
          const haveEmoji = question.options?.every((o) => o.emoji?.length);

          if (!haveEmoji) {
            if (question.options?.length === 11) {
              question.type = "nps";
            } else if (question.options?.length === 7) {
              question.type = "ces";
            } else if (question.options?.length === 5) {
              question.type = "csat";
            } else {
              question.type = "multiple_choice";
            }
          } else {
            const isLabelNumber = question.options?.every((o) =>
              /^\d+$/.test(o.label),
            );
            if (isLabelNumber) {
              question.type = "scoring";
            } else {
              question.type = "multiple_choice";
            }
          }
        } else {
          question.type = "input";
        }
      }

      // Add the node
      const oldNodeId = lastNodeId;
      lastNodeId = this.builderStore.addNode(question.type, oldNodeId);

      // Find the added node
      const node = this.builderStore.survey.scenario.nodes.find(
        (n) => n.id === lastNodeId,
      );
      if (!node) {
        this.builderStore.removeNode(lastNodeId);
        continue;
      }

      mappedIds[question.id] = [
        node.id.toString(),
        node.correlation_id.toString(),
      ];

      // Update description if defined
      if (question.description?.length) {
        node.question.description = question.description;
        if (
          node.question.type === "survey" &&
          node.question.messages[0].type === "text"
        ) {
          (node.question.messages[0] as MessageText).text[
            this.builderStore.survey.scenario.default_language
          ] = question.description;
        }
      }

      // Let's update the call to action
      if (question.options?.length) {
        switch (question.type) {
          case "scoring":
            let score = 0;
            (node.question.call_to_action as CTAScoring).scores =
              question.options.map((c: any) => {
                const id = uuidv4() as UUID;
                mappedOptionsToNextIds[id] = c.next_id;
                return {
                  id,
                  correlation_id: uuidv4() as UUID,
                  type: "scoring",
                  payload: {
                    emoji:
                      c.emoji?.length && c.emoji?.length <= 10 ? c.emoji : "🤷‍♀️",
                    value: score++,
                  },
                } as ActionScoring;
              });
            break;
          case "multiple_choice":
          case "link":
            (node.question.call_to_action as CTAMultipleChoiceAny).choices =
              question.options.map((c: any) => {
                const id = uuidv4() as UUID;
                mappedOptionsToNextIds[id] = c.next_id;
                const label = c.label?.length ? c.label : "To be defined";
                return {
                  id,
                  correlation_id: uuidv4() as UUID,
                  type: "button",
                  payload: {
                    label: {
                      [this.builderStore.survey.scenario.default_language]:
                        label,
                    },
                    emoji:
                      c.emoji?.length && c.emoji?.length <= 10
                        ? c.emoji
                        : undefined,
                  },
                } as ActionButton;
              });
            break;
          case "conditional":
            (
              node.question.call_to_action as CTAConditional
            ).conditional.conditions = question.options.map((c: any) => {
              const id = uuidv4() as UUID;
              mappedOptionsToNextIds[id] = c.next_id;
              const label = c.label?.length ? c.label : "To be defined";
              return {
                id,
                correlation_id: uuidv4() as UUID,
                type: "button",
                payload: {
                  label: {
                    [this.builderStore.survey.scenario.default_language]: label,
                  },
                  emoji:
                    c.emoji?.length && c.emoji?.length <= 10
                      ? c.emoji
                      : undefined,
                },
              } as ActionButton;
            });
            break;
          case "nps":
          case "csat":
          case "ces":
            (node.question.call_to_action as CTAScoringAny).scores.forEach(
              (score: ActionScoring, index: number) => {
                mappedOptionsToNextIds[score.id] =
                  question.options[index]?.next_id;
              },
            );
            break;
        }
      }

      // Update routing
      getActionsFromQuestion(node.question).forEach((action: CTAAction) => {
        if (mappedOptionsToNextIds[action.id] !== "none") {
          routingSetCase(
            node.routing,
            action.id,
            mappedIds[mappedOptionsToNextIds[action.id]]?.[0] || oldNodeId,
            "next_node",
          );
        } else {
          routingSetCase(node.routing, action.id, null, "next_node");
        }
      });

      // Add skip action if defined or default to the next question unless question type is none
      if (
        question.type !== "none" &&
        (!question.skip_id?.length ||
          (question.skip_id?.length && mappedIds[question.skip_id]?.[0]))
      ) {
        node.question.skip_action = newCTASkip();
        routingSetCase(
          node.routing,
          node.question.skip_action.id,
          question.skip_id?.length
            ? mappedIds[question.skip_id]?.[0]
            : oldNodeId,
          "skip_to_node",
        );
      }
    }

    if (lastNodeId === null) {
      throw new Error("No node generated");
    }

    // Bind the first node to the entrypoint
    this.builderStore.survey.scenario.node_entrypoint_id = lastNodeId;

    // Reverse the order of the nodes to properly import them
    this.builderStore.survey.scenario.nodes.reverse();
    const res = new ImportedSurveyScenario();
    res.scenario = this.builderStore.survey.scenario;
    res.questionsIdsMappping = mappedIds;
    return res;
  }

  /**
   * The function `importSurveyResponse` imports a survey response by creating a
   * user and then creating the response associated with that user.
   * @param {Survey} survey - The `survey` parameter is an object that represents
   * the survey for which the response is being imported.
   * @param {ImportSurveyResponse} response - The `response` parameter is an object
   * that represents the survey response to be imported.
   * @param [questionsIdsMappping] - The `questionsIdsMappping` parameter is an
   * optional parameter of type `Record<string, string>`. It is used to map the
   * question IDs from the `response` object to the corresponding question IDs in
   * the Screeb system. If not provided, the function will assume that the question
   * IDs provided in the `response` object are Screeb UUIDs.
   * @returns a Promise that resolves to a Response object.
   */
  public async importSurveyResponse(
    survey: Survey,
    response: ImportSurveyResponse,
    questionsIdsMappping?: Record<string, [string, string]> | null,
  ): Promise<Response> {
    // First create the user or fetch it if it already exists with the same alias
    const user = await this.screebApiHelper
      .post<object>(`/org/${this.uiService.currentOrgId}/user`, {
        alias: response.user_id,
      })
      .toPromise()
      .then((data: Record<string, any>): User => {
        return new User().fromJson(data);
      });

    // Fetch widget channel
    const widgetChannelId = await this.getWidgetChannelId();

    // Then create the response
    return this.screebApiHelper
      .post<object>(
        `/org/${this.uiService.currentOrgId}/user/${user.id}/response`,
        {
          survey_id: survey.id,
          scenario_id: survey.scenario.id,
          channel_id: widgetChannelId,
          source: response.from ?? "sdk-js",
          response_completion: response.completion ?? "fully_completed",
          answers: response.answers
            ?.map((answer) => {
              try {
                // Use the mapping if provided otherwise we'll assume that provided id are a Screeb UUID
                const question_id = questionsIdsMappping
                  ? questionsIdsMappping[answer.question_id]?.[0]
                  : answer.question_id;
                const [action_id, action_correlation_id] =
                  this.getAnswerActionIds(survey, question_id, answer);
                if (!action_id || !action_correlation_id) {
                  return null;
                }
                return {
                  // Use the mapping if provided otherwise we'll assume that provided id are a Screeb UUID
                  question_id,
                  question_correlation_id: questionsIdsMappping
                    ? questionsIdsMappping[
                        answer.question_correlation_id ?? answer.question_id
                      ]?.[1]
                    : answer.question_correlation_id,
                  action_id,
                  action_correlation_id,
                  question_type: answer.questionType ?? "none",
                  type: answer.type ?? "string",
                  values: answer.values,
                };
              } catch (e) {
                console.error(e);
                return null;
              }
            })
            .filter((a) => a !== null),
        },
      )
      .toPromise()
      .then((data: Record<string, any>): Response => {
        return new Response().fromJson(data);
      });
  }

  private getAnswerActionIds(
    survey: Survey,
    question_id: string,
    answer: ImportSurveyResponseAnswer,
  ): [string | null, string | null] {
    const question = survey.scenario.nodes.find((n) => n.id === question_id);
    if (!question) {
      throw new Error("Question not found");
    }

    if (answer.type === "none") {
      return [null, null];
    }

    const action = getActionsFromQuestion(question.question).find((a) => {
      if (a.type === "scoring") {
        return (a as ActionScoring).payload.value === +answer.values[0];
      } else if (a.type === "button") {
        return (
          (a as ActionButton).payload.label[
            this.builderStore.survey.scenario.default_language
          ] === answer.values?.[0]
        );
      }
      return true;
    });

    if (!action) {
      throw new Error("Action not found");
    }

    return [action.id, action.correlation_id];
  }

  private async getWidgetChannelId(): Promise<string> {
    if (this._channelId) {
      return this._channelId;
    }

    const channels = await this.channelDao.getAllByOrgId(
      this.uiService.currentOrgId,
    );
    const widgetChannelId = channels.find(
      (channel) => channel.type === "widget",
    )?.id;
    if (!widgetChannelId) {
      throw new Error("No widget channel found");
    }

    this._channelId = widgetChannelId;
    return widgetChannelId;
  }
}

export {
  ImportQuestion,
  ImportQuestionOption,
  ImportService,
  ImportSurvey,
  ImportSurveyResponse,
  ImportSurveyResponseAnswer,
  ImportedSurveyScenario,
};
