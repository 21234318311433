import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { SurveyDistribution } from "models/survey-distribution.model";
import { DateFormatType, formatDate } from "utils/date";
import { formatNumber, NumberFormatType } from "utils/number";
import { NgIf, NgClass, NgStyle } from "@angular/common";
import { SanitizedMessageComponent } from "../../../../../../builder/components/Cards/sanitized-message/sanitized-message.component";
import { TextShimmerComponent } from "../../../../../../common/text-shimmer/text-shimmer.component";
import { RouterLink } from "@angular/router";
import { SurveyDistributionsStatusComponent } from "../../../../list/components/survey-distributions-status/survey-distributions-status.component";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";

@Component({
  selector: "survey-stats-indicators-single-indicator",
  templateUrl: "./single-indicator.component.html",
  styleUrls: ["./single-indicator.component.scss"],
  imports: [
    NgIf,
    NgClass,
    SanitizedMessageComponent,
    TextShimmerComponent,
    NgStyle,
    RouterLink,
    SurveyDistributionsStatusComponent,
    NzTooltipDirective,
  ],
})
export class SingleIndicatorStatsSurveyComponent implements OnInit, OnChanges {
  @Input()
  public title: string;
  @Input()
  public boldTitle: boolean = false;
  @Input()
  public value: number | Date | SurveyDistribution[];
  @Input()
  public diffPercent: number;
  @Input()
  public imageSrc: string;
  @Input()
  public format: NumberFormatType = "number";
  @Input()
  public dateFormat: DateFormatType = "date-only";
  @Input()
  public diffFormat: NumberFormatType = "percent";
  @Input()
  public maximumFractionDigits = 0;
  @Input()
  public thousandsSuffixes = false;
  @Input()
  public scoreMax = 0;
  @Input()
  public diffIndustryAverage: number = null;
  @Input()
  public size: "large" | "medium" | "small" = "large";
  @Input()
  public theme: "dark" | "light" = "light";
  @Input()
  public loading = false;
  @Input()
  public loadingDiff = false;
  @Input()
  public color: string = null;
  @Input()
  public titleLink: string = null;
  @Input()
  public link: string = null;
  @Input()
  public boldLink: boolean = false;

  public diffColorClass: string = "";
  public roundedDiffPercent: number = 0;
  public formatedDiff: string = "";
  public isFiniteDiff: boolean = false;
  public formattedValue: string = "";
  public averageDiffColorClass: string = "";
  public formattedDiffIndustryAverage: string = "";
  public isStatus: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.setup();
  }

  ngOnChanges({
    diffIndustryAverage,
    value,
    diffPercent,
  }: SimpleChanges): void {
    if (
      diffIndustryAverage?.currentValue !==
        diffIndustryAverage?.previousValue ||
      value?.currentValue !== value ||
      diffPercent?.currentValue !== diffPercent?.previousValue
    ) {
      this.setup();
    }
  }

  private setup() {
    this.diffColorClass = this.getDiffColorClass();
    this.formattedValue = this.getFormattedValue();
    this.roundedDiffPercent = this.round(this.diffPercent);
    this.formatedDiff = this.getFormattedDiff();
    this.isFiniteDiff = isFinite(this.diffPercent);

    this.averageDiffColorClass = this.getAverageDiffColorClass();
    this.formattedDiffIndustryAverage = this.getFormattedDiffIndustryAverage();
    this.isStatus = this.getIsStatus();
  }

  public getFormattedValueNumber(rawValue: number) {
    const value = formatNumber(
      rawValue,
      this.format,
      this.maximumFractionDigits,
      "auto",
      this.thousandsSuffixes,
    );

    return this.scoreMax ? `${value}/${this.scoreMax}` : value;
  }

  public getIsStatus() {
    return Array.isArray(this.value);
  }

  public getStatusLabel(running: boolean) {
    if (Array.isArray(this.value)) {
      return this.value.filter((d) => d.enabled === running);
    }
    return [];
  }

  public getFormattedValue() {
    if (typeof this.value === "number") {
      return this.getFormattedValueNumber(this.value);
    } else if (this.value instanceof Date) {
      return formatDate(this.value, this.dateFormat);
    }
  }

  public getFormattedDiff() {
    return formatNumber(this.diffPercent, this.diffFormat, 0, "hide");
  }

  public getFormattedDiffIndustryAverage() {
    return formatNumber(this.diffIndustryAverage, "points", 0, "force");
  }

  public abs(nbr: number): number {
    return Math.abs(nbr);
  }
  public round(nbr: number): number {
    return Math.round(nbr);
  }
  public isFinite(nbr: number): boolean {
    return isFinite(nbr);
  }

  public getDiffColorClass(): string {
    if (this.round(this.diffPercent) > 0) {
      return "green";
    } else if (this.round(this.diffPercent) < 0) {
      return "red";
    }
    return "grey";
  }

  public getAverageDiffColorClass(): string {
    if (this.round(this.diffIndustryAverage) > 0) {
      return "green";
    } else if (this.round(this.diffIndustryAverage) < 0) {
      return "red";
    }
    return "grey";
  }
}
