import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TrackersService } from "services/trackers.service";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";

@Component({
  selector: "page-survey-stats-connect-no-integration",
  template: `
    <div class="banner">
      <div class="content">
        No integration available for this In-app Message. Please tell us what
        would be the connector of your dreams.
      </div>
      <div class="actions">
        <button
          nz-button
          class="actions-save ant-btn-secondary"
          nzSize="large"
          (click)="feedback()"
        >
          Feedbacks
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .banner {
        display: flex;
        align-items: center;
        background: var(--screeb-color-purple-200);
        border-radius: 8px;
        padding: 32px 40px;
        width: 100%;
        animation: shadeAppear 200ms;
      }
      .content {
        flex: 1;

        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: var(--screeb-color-black);
      }
      .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
      }

      .banner.danger {
        background: var(--screeb-color-warning);
      }

      @keyframes shadeAppear {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `,
  ],
  imports: [NzButtonComponent, NzWaveDirective, ɵNzTransitionPatchDirective],
})
export class ConnectStatsSurveyNoIntegrationComponent implements OnInit {
  constructor(
    private router: Router,
    private trackersService: TrackersService,
  ) {}

  ngOnInit() {}

  feedback() {
    this.trackersService.screebSurveyStartFeedback();
    event.preventDefault();
  }
}
