import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { PageComponentInterface } from "components/PageComponentInterface";
import { autoTips } from "helpers/form-errors.helper";
import { NotificationHelper } from "helpers/notification.helper";

import { Survey } from "models/survey.model";
import { ScreebApiHelper } from "services/auth.service";
import {
  ImportQuestion,
  ImportQuestionOption,
  ImportService,
  ImportSurvey,
  ImportSurveyResponse,
  ImportSurveyResponseAnswer,
} from "services/import.service";
import { TrackersService } from "services/trackers.service";
import { LanguageWithEmoji, UIService } from "services/ui.service";
import { GetFeedbackDao } from "./getfeedback.dao";
import {
  NzFormDirective,
  NzFormItemComponent,
  NzFormControlComponent,
} from "ng-zorro-antd/form";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { NzInputGroupComponent, NzInputDirective } from "ng-zorro-antd/input";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { NgIf, NgFor, DatePipe } from "@angular/common";
import {
  NzTableComponent,
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzThSelectionComponent,
  NzTbodyComponent,
  NzTdAddOnComponent,
} from "ng-zorro-antd/table";
import { NzModalComponent } from "ng-zorro-antd/modal";
import { NzProgressComponent } from "ng-zorro-antd/progress";

@Component({
  selector: "getfeedback-import-modal",
  templateUrl: "./getfeedback-import.component.html",
  styleUrls: ["./getfeedback-import.component.scss"],
  imports: [
    FormsModule,
    NzFormDirective,
    ReactiveFormsModule,
    NzRowDirective,
    NzFormItemComponent,
    NzColDirective,
    NzFormControlComponent,
    NzInputGroupComponent,
    ɵNzTransitionPatchDirective,
    NzInputDirective,
    NzButtonComponent,
    NzWaveDirective,
    NgIf,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzThSelectionComponent,
    NzTbodyComponent,
    NgFor,
    NzTdAddOnComponent,
    NzModalComponent,
    NzProgressComponent,
    DatePipe,
  ],
})
export class GetFeedbackImportPageComponent
  implements PageComponentInterface, OnInit
{
  public title = "Import surveys from GetFeedback";
  public name = "Import surveys";

  public autoTips = autoTips;
  public validateForm: FormGroup = null;
  public languages: LanguageWithEmoji[] = [];

  public isLoadSurveysLoading = false;
  public checked = false;
  public indeterminate = false;
  public surveys = [];
  public setOfCheckedId = new Set<number>();

  public isImportModalVisible = false;
  public processedSurveys = 0;
  public importedSurveys = 0;
  public importedResponses = 0;
  public erroredSurveys = 0;
  public erroredResponses = 0;
  public processingMessage = "";

  constructor(
    private uiService: UIService,
    private trackersService: TrackersService,
    public screebApiHelper: ScreebApiHelper,
    private notificationHelper: NotificationHelper,
    private formBuilder: FormBuilder,
    private getFeedbackDao: GetFeedbackDao,
    private importService: ImportService,
  ) {}

  ngOnInit() {
    this.languages =
      this.uiService.languagesAndCountries.surveyLanguagesWithEmojis;

    this.validateForm = this.formBuilder.group({
      token: ["", [Validators.required]],
    });
  }

  // Let's fetch the surveys from GetFeedback and display them in a table
  public onLoadSurveys(): void {
    if (!this.validateForm.valid) {
      this.notificationHelper.trigger(
        "Please fill in the API token field",
        null,
        "error",
      );
      return;
    }

    this.getFeedbackDao
      .getSurveys(this.validateForm.value.token)
      .then((surveys) => {
        this.surveys = surveys;
        this.trackersService
          .newEventTrackingBuilder("Getfeedback surveys searched")
          .withOrg(this.uiService.currentOrg)
          .build();
      })
      .catch((error) => {
        console.error("Error:", error);
        this.notificationHelper.trigger(
          "Unable to load surveys",
          null,
          "error",
        );
      })
      .finally(() => {
        this.isLoadSurveysLoading = false;
      });
  }

  public updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  public refreshCheckedStatus(): void {
    const listOfEnabledData = this.surveys.filter(({ disabled }) => !disabled);
    this.checked = listOfEnabledData.every(({ id }) =>
      this.setOfCheckedId.has(id),
    );
    this.indeterminate =
      listOfEnabledData.some(({ id }) => this.setOfCheckedId.has(id)) &&
      !this.checked;
  }

  public onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  public onAllChecked(checked: boolean): void {
    this.surveys
      .filter(({ disabled }) => !disabled)
      .forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  // Import the selected surveys
  // We'll create a new survey for each one, and then import the questions
  public async onImportSurveys(withResponse: boolean = true): Promise<void> {
    this.processingMessage = "Starting import...";
    this.isImportModalVisible = true;
    this.erroredSurveys = 0;
    this.erroredResponses = 0;
    this.importedSurveys = 0;
    this.importedResponses = 0;
    this.processedSurveys = 0;

    for (const surveyId of this.setOfCheckedId) {
      this.processedSurveys += 1;
      const gfSurvey = this.surveys.find((s) => s.id === surveyId);
      if (!gfSurvey) {
        continue;
      }
      try {
        this.processingMessage = `Importing survey "${gfSurvey.name}"...`;
        const survey = await this.getFeedbackDao.getSurvey(
          surveyId,
          this.validateForm.value.token,
        );

        this.processingMessage = `Creating survey "${survey.name}"...`;
        const importedSurvey = new ImportSurvey();
        importedSurvey.title = survey.name;
        importedSurvey.language = survey.language;
        importedSurvey.tags = ["Imported from GetFeedback"];
        const newSurvey = await this.importService.importSurvey(
          importedSurvey,
          this.languages,
        );

        // Import the questions
        this.processingMessage = `Importing questions for survey "${survey.name}"...`;
        const [formattedQuestions, optionsIdsMapping] =
          this.formatQuestions(survey);
        const importedScenario = await this.importService.importSurveyQuestions(
          newSurvey.id,
          formattedQuestions,
          this.languages,
        );
        newSurvey.scenario = importedScenario.scenario;

        // We still want to import the survey even if importing the responses fails
        if (withResponse) {
          try {
            this.processingMessage = `Import responses for survey "${survey.name}"...`;
            await this.importResponses(
              newSurvey,
              survey,
              importedScenario.questionsIdsMappping,
              optionsIdsMapping,
            );
            this.importedSurveys += 1;
          } catch (error) {
            console.error("Error:", error);
            this.erroredResponses += 1;
            this.notificationHelper.trigger(
              `Failed to import responses for survey "${survey.name}"`,
              null,
              "error",
            );
            this.processingMessage = `Failed to import responses for survey "${survey.name}"`;
          }
        } else {
          this.importedSurveys += 1;
        }
      } catch (error) {
        console.error("Error:", error);
        this.erroredSurveys += 1;
        this.notificationHelper.trigger(
          `Failed to import survey "${gfSurvey.name}"`,
          null,
          "error",
        );
        this.processingMessage = `Failed to import survey "${gfSurvey.name}"`;
      }
    }

    this.trackersService
      .newEventTrackingBuilder("Getfeedback surveys imported")
      .withOrg(this.uiService.currentOrg)
      .build();
  }

  private formatQuestions(
    gfSurvey: any,
  ): [ImportQuestion[], Record<string, string>] {
    // Map getfeedback types to our import types
    const optionsIdsMapping: Record<string, string> = {};
    const questions = gfSurvey.ordered_components
      .map((question) => {
        const newQuestion = new ImportQuestion();
        newQuestion.id = question.id;

        // Map GetFeedback types to our types when can be supported
        newQuestion.type = {
          ThankYouPage: "none",
          SectionBreak: "none",
          CoverPage: "none",
          ShortAnswer: "input",
          NetPromoter: "nps",
          Rating: "range",
          CustomerSatisfaction: "csat",
          CustomerEffort: "ces",
          MultipleChoice: "multiple_choice",
          LikeDislike: "scoring",
        }[question.type];
        if (!newQuestion.type) {
          return null;
        }

        // We don't support multiple choice grid
        if (question.type === "MultipleChoice" && question.grid_items?.length) {
          return null;
        }

        if (question.title?.length) {
          newQuestion.description = question.title;
        }

        if (question.description?.length) {
          if (newQuestion.description?.length) {
            newQuestion.description += "\n\n";
          } else {
            newQuestion.description = "";
          }
          newQuestion.description += question.description;
        }

        // Add valid options
        if (question.type === "LikeDislike") {
          const diskile = new ImportQuestionOption();
          diskile.emoji = "👎";
          diskile.label = "0";
          const like = new ImportQuestionOption();
          like.emoji = "👍";
          like.label = "1";
          newQuestion.options = [diskile, like];
        } else {
          newQuestion.options = question.choices
            ?.map((choice) => {
              if (!choice.text?.length) {
                return null;
              }
              if (choice.id) {
                optionsIdsMapping[choice.id] = choice.text;
              }
              const newOption = new ImportQuestionOption();
              newOption.label = choice.text;
              return newOption;
            })
            .filter((c) => c !== null);
        }
        return newQuestion;
      })
      .filter((c) => c !== null);
    return [questions, optionsIdsMapping];
  }

  private async importResponses(
    survey: Survey,
    gfSurvey: any,
    questionsIdsMapping: Record<string, [string, string]>,
    optionsIdsMapping: Record<string, string>,
  ) {
    let haveMoreResponses = true;
    let page = 1;
    do {
      // Fetch and imports responses by batch of 100
      const responses = await this.getFeedbackDao.getResponses(
        gfSurvey.id,
        page++,
        this.validateForm.value.token,
      );

      for (const response of responses) {
        await this.importService.importSurveyResponse(
          survey,
          this.formatResponse(response, optionsIdsMapping),
          questionsIdsMapping,
        );
        this.importedResponses += 1;
      }

      if (responses.length < 100) {
        haveMoreResponses = false;
        break;
      }
    } while (haveMoreResponses);
  }

  private formatResponse(
    response: any,
    optionsIdsMapping: Record<string, string>,
  ): ImportSurveyResponse {
    const formatedResponse = new ImportSurveyResponse();
    formatedResponse.user_id = response.token;
    formatedResponse.from = "getfeedback";
    formatedResponse.completion =
      response.status === "completed"
        ? "fully_completed"
        : "partially_completed";
    const allowedFields = ["language", "platform", "browser"];
    formatedResponse.hiddenFields = {};
    for (const field of allowedFields) {
      if (response[field]?.length) {
        formatedResponse.hiddenFields[field] = response[field];
      }
    }
    formatedResponse.answers = response.answers
      .map((answer) => {
        const newAnswer = new ImportSurveyResponseAnswer();
        newAnswer.question_id = answer.component_id;
        newAnswer.question_correlation_id = answer.component_id;
        switch (answer.type) {
          case "ShortAnswer":
            newAnswer.questionType = "input";
            newAnswer.values = [answer.text];
            break;
          case "RatingAnswer":
            newAnswer.questionType = "range";
            newAnswer.type = "number";
            newAnswer.values = [(answer.number / answer.scale) * 100];
            break;
          case "CustomerSatisfactionAnswer":
            newAnswer.questionType = "csat";
            newAnswer.values = [answer.number.toString()];
            break;
          case "CustomerEffortAnswer":
            newAnswer.questionType = "ces";
            newAnswer.values = [answer.number.toString()];
            break;
          case "NetPromoterAnswer":
            newAnswer.questionType = "nps";
            newAnswer.values = [answer.number.toString()];
            break;
          case "LikeDislikeAnswer":
            newAnswer.questionType = "scoring";
            newAnswer.values = [answer.number.toString()];
            break;
          case "MultipleChoiceAnswer":
            newAnswer.questionType = "multiple_choice";
            newAnswer.values = answer.choice_ids
              .map((id) => optionsIdsMapping[id])
              .filter((id) => !!id);
            break;
          case "ThankYouPageAnswer":
          case "SectionBreakAnswer":
          case "CoverPageAnswer":
            newAnswer.questionType = "none";
            newAnswer.type = "none";
            break;
          default:
            return null;
        }
        return newAnswer;
      })
      .filter((a) => a !== null);
    return formatedResponse;
  }
}
