<label class="lateral-panel-subtitle">Rules</label>
<p class="lateral-panel-tip">
  Define a set of rules that will be checked when the scenario reaches this step
</p>

<survey-share-targeting-advanced
  [isOrgTargeting]="true"
  [org]="org"
  [rules]="
    node.node.question.call_to_action.type === 'conditional'
      ? node.node.question.call_to_action.conditional.rules
      : []
  "
  (rulesChange)="onRulesChange($event)"
  [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
  [registryEntriesGroup]="registryEntriesGroup"
  [registryEntriesEvent]="registryEntriesEvent"
  [surveys]="surveys"
></survey-share-targeting-advanced>

<label class="lateral-panel-subtitle">Conditions</label>
<p class="lateral-panel-tip">If the rules are met, or else:</p>
<div
  class="panel-button-line"
  *ngFor="
    let condition of node.node.question.call_to_action.type === 'conditional'
      ? node.node.question.call_to_action.conditional.conditions
      : [];
    let ctaIndex = index
  "
>
  <div class="emoji-button">
    <sanitized-message-component
      [content]="condition.payload.emoji"
      [options]="{
        styling: true,
        emojiTranscoder: true,
      }"
    ></sanitized-message-component>
  </div>

  <lateral-panel-step-picker
    [routing]="node.node.routing"
    [chainableNodeIds]="node.chainableNodeIds"
    [actions]="[condition]"
  ></lateral-panel-step-picker>
</div>
