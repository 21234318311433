<div class="container">
  <div class="loading-container {{ loading ? 'is-loading' : '' }}">
    <nz-spin size="medium" *ngIf="loading"></nz-spin>
    <div>
      <canvas
        style="position: relative; width: auto"
        #trendChart="base-chart"
        baseChart
        [data]="data"
        [options]="options"
        [type]="'radar'"
        [legend]="false"
      ></canvas>
    </div>
    <nz-checkbox-group
      *ngIf="displayLegend"
      [(ngModel)]="reversedLegend"
      (ngModelChange)="onTrendLegendChange($event)"
    ></nz-checkbox-group>
  </div>
</div>
