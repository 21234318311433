import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { getNumericValueWithLimits } from "components/surveys/pages/share/targeting/advanced/components/utils/rule-change";

import {
  surveyCappingRuleTypePerCappingType,
  SurveyCappingTimePeriod,
  SurveyCappingType,
  surveyCappingTypeToTimePeriod,
} from "models/survey-targeting-rule-capping.model";
import {
  TargetingRule,
  TargetingRuleOperator,
  TargetingRuleType,
} from "models/targeting-rule.model";
import { NgIf, NgFor } from "@angular/common";
import { NzSelectComponent, NzOptionComponent } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import {
  NzInputDirective,
  NzInputGroupComponent,
  NzInputGroupWhitSuffixOrPrefixDirective,
} from "ng-zorro-antd/input";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { SettingsSurveyCappingSummaryComponent } from "../survey-capping-summary/survey-capping-summary.component";

@Component({
  selector: "survey-capping-config",
  templateUrl: "./survey-capping-config.component.html",
  styleUrls: ["./survey-capping-config.component.scss"],
  imports: [
    NgIf,
    NzSelectComponent,
    FormsModule,
    NgFor,
    NzOptionComponent,
    NzInputDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NzTooltipDirective,
    NzInputGroupComponent,
    NzInputGroupWhitSuffixOrPrefixDirective,
    SettingsSurveyCappingSummaryComponent,
  ],
})
export class SettingsSurveyCappingConfigComponent implements OnInit {
  @Input() public orgId?: string = null;
  @Input() public rules: TargetingRule[] = null;
  @Input() public surveyCappingType: SurveyCappingType = null;

  @Output() public rulesChange = new EventEmitter<TargetingRule[]>();

  public targetingRuleDisplay: TargetingRule = null;
  public targetingRuleResponse: TargetingRule = null;
  public targetingRuleTimeBetweenDisplay: TargetingRule = null;

  public timePeriod: SurveyCappingTimePeriod;
  public timeBetweenSurveyDisplays: number = null;

  public surveyCappingTypeToTimePeriod = surveyCappingTypeToTimePeriod;

  public getNumericValueWithLimits = getNumericValueWithLimits;

  constructor() {}

  ngOnInit() {
    this.createCappingRulesIfNotExist();

    if (this.targetingRuleTimeBetweenDisplay?.value?.v_n) {
      this.timeBetweenSurveyDisplays =
        this.targetingRuleTimeBetweenDisplay.value.v_n / 60;
    }
    this.timePeriod =
      this.targetingRuleDisplay?.value.v_n_p !== SurveyCappingTimePeriod.OneDay
        ? this.targetingRuleDisplay?.value.v_n_p
        : this.targetingRuleResponse?.value.v_n_p;

    this.onTimePeriodChange(this.timePeriod);
  }

  public onTimePeriodChange(value: number) {
    this.timePeriod = value;

    this.targetingRuleDisplay.value.v_n_p = value;
    this.targetingRuleResponse.value.v_n_p = value;
  }

  public onCappingCountValueChange(
    rule: TargetingRule,
    value: number,
    min: number,
    max: number,
  ) {
    rule.value.v_n = this.getNumericValueWithLimits(value, min, max);
  }

  public onCappingTimeBetweenDisplayValueChange(
    value: number,
    min: number,
    max: number,
  ) {
    this.timeBetweenSurveyDisplays = this.getNumericValueWithLimits(
      value,
      min,
      max,
    );
    this.targetingRuleTimeBetweenDisplay.value.v_n =
      this.timeBetweenSurveyDisplays * 60;
  }

  private addRule(
    type: TargetingRuleType,
    operator?: TargetingRuleOperator,
  ): TargetingRule {
    const r = new TargetingRule();
    r.survey_distribution_id = null;
    r.org_id = this.orgId;
    r.type = type;
    r.operator = operator || r.getAvailableOperators()?.[0]?.type;
    r.value = r.getDefaultValue();

    this.rules = Array.from(this.rules).concat(r);
    this.rulesChange.emit(this.rules);

    return r;
  }

  private createCappingRulesIfNotExist() {
    this.targetingRuleDisplay = this.rules.find(
      (r: TargetingRule) =>
        r.type ===
        surveyCappingRuleTypePerCappingType[this.surveyCappingType].display,
    );
    if (!this.targetingRuleDisplay) {
      this.targetingRuleDisplay = this.addRule(
        surveyCappingRuleTypePerCappingType[this.surveyCappingType].display,
      );
    }

    this.targetingRuleResponse = this.rules.find(
      (r: TargetingRule) =>
        r.type ===
        surveyCappingRuleTypePerCappingType[this.surveyCappingType].response,
    );
    if (!this.targetingRuleResponse) {
      this.targetingRuleResponse = this.addRule(
        surveyCappingRuleTypePerCappingType[this.surveyCappingType].response,
      );
    }

    if (this.surveyCappingType === "per-respondent") {
      this.targetingRuleTimeBetweenDisplay = this.rules.find(
        (r: TargetingRule) =>
          r.type === "capping_respondent_time_between_survey_display",
      );
      if (!this.targetingRuleTimeBetweenDisplay) {
        this.targetingRuleTimeBetweenDisplay = this.addRule(
          "capping_respondent_time_between_survey_display",
        );
      }
    }
  }
}
