import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  getTimeUnit,
  getTime,
  DropIfSelectPopoverComponent,
} from "./drop-if-select-popover.component";
import { NgIf } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";

@Component({
  selector: "drop-if-select",
  template: `
    <span>Drop</span>
    <span class="text-bold" *ngIf="readOnly">
      after {{ getTime(value, getTimeUnit(value)) }}
      {{ getTimeUnit(value) }}
    </span>
    <a
      *ngIf="!readOnly"
      nz-button
      nzType="link"
      nz-popover
      nzPopoverPlacement="bottomLeft"
      nzPopoverTrigger="click"
      [nzPopoverContent]="contentTemplate"
      (nzPopoverVisibleChange)="onPopoverVisibleChange($event)"
      [(nzPopoverVisible)]="popoverVisible"
    >
      <span>
        after {{ getTime(value, getTimeUnit(value)) }}
        {{ getTimeUnit(value) }}
      </span>
      <svg
        width="16"
        height="16"
        nzSize="large"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.77815 11.4379C8.38763 11.8284 7.75446 11.8284 7.36394 11.4379L2.70708 6.78101C2.07712 6.15105 2.52329 5.07391 3.41419 5.07391L12.7279 5.07391C13.6188 5.07391 14.065 6.15105 13.435 6.78101L8.77815 11.4379Z"
          fill="#5E21F1"
        />
      </svg>
    </a>
    <ng-template #contentTemplate>
      <drop-if-select-popover [(value)]="tempValue"></drop-if-select-popover>
      <div
        [style]="{
          margin: '0 12px 12px 0',
          display: 'flex',
          justifyContent: 'flex-end',
        }"
      >
        <button
          title="Save"
          nz-button
          class="ant-btn-secondary"
          nzSize="large"
          (click)="onSubmit()"
        >
          Save
        </button>
      </div>
    </ng-template>
  `,
  styles: [
    `
      :host {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
        display: block;
      }

      :host a {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        display: inline-flex;
        align-items: center;
        padding: 4px;
      }

      :host a svg {
        margin-left: 4px;
      }

      :host .text-bold {
        font-weight: 500;
      }
    `,
  ],
  imports: [
    NgIf,
    NzButtonComponent,
    ɵNzTransitionPatchDirective,
    NzPopoverDirective,
    DropIfSelectPopoverComponent,
    NzWaveDirective,
  ],
})
export class DropIfSelectComponent implements OnInit {
  @Input() public value = 0;
  @Input() public readOnly = false;

  @Output() public valueChange = new EventEmitter<number>();

  public tempValue = 0;
  public popoverVisible = false;

  getTime = getTime;
  getTimeUnit = getTimeUnit;

  ngOnInit(): void {
    this.tempValue = this.value;
  }

  onPopoverVisibleChange(visible: boolean): void {
    if (visible) {
      this.tempValue = this.value;
    }
  }

  onSubmit() {
    this.value = this.tempValue;
    this.valueChange.emit(this.value);
    this.popoverVisible = false;
  }
}
