import { Component, Input } from "@angular/core";
import { NgFor } from "@angular/common";

@Component({
  selector: "text-shimmer",
  template: `
    @let tmp = [].constructor(lines);
    <div
      class="has-shimmer"
      style="font-size: {{ fontSize }}px; margin-bottom: {{
        fontSize * lineHeight - fontSize
      }}px; width: {{ widths[idx % widths.length] }}"
      *ngFor="let i of tmp; let idx = index"
    >
      &nbsp;
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
      }

      div {
        line-height: 1;
        border-radius: 4px;
      }
    `,
  ],
  imports: [NgFor],
})
export class TextShimmerComponent {
  @Input() public lines: number = 5;
  @Input() public fontSize: number = 14;
  @Input() public lineHeight: number = 1.5715;

  public widths: string[] = [];

  constructor() {
    this.widths = Array.from({ length: this.lines }, () => {
      return `${Math.floor(Math.random() * (100 - 45 + 1) + 45)}%`;
    });
  }
}
