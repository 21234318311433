import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { SurveyDao } from "models/survey.dao";
import { AuthService, SessionService } from "services/auth.service";
import { UIService } from "services/ui.service";
import { LayoutBackHeaderService } from "./header.service";
import { Router, RouterLink } from "@angular/router";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { NgIf, NgTemplateOutlet } from "@angular/common";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";
import { StepsComponent } from "../../../../utils/steps/steps.component";

@Component({
  selector: "layout-back-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  imports: [
    NgIf,
    NzTooltipDirective,
    RouterLink,
    ScreebIconComponent,
    StepsComponent,
    NgTemplateOutlet,
  ],
})
export class LayoutBackHeaderComponent implements AfterContentInit {
  @Input()
  public isMenuCollapsed: boolean;

  @Output()
  public collapseMenu: EventEmitter<any> = new EventEmitter();

  // when not null, we enter in editing mode
  public editingSurveyTitle: string = null;
  public editingSurveyTitleLoading: boolean = false;
  public editingSurveyTitleError = null;

  public currentStepIdx: number = -1;

  public steps: string[] = ["Build", "Settings", "Share", "Analyze"];
  public stepRoutes: string[] = ["edit", "settings", "share", "stats"];
  constructor(
    public authService: AuthService,
    public sessionService: SessionService,
    private router: Router,
    public uiService: UIService,
    private surveyDao: SurveyDao,
    public headerService: LayoutBackHeaderService,
    private featureFlaggingService: FeatureFlaggingService,
  ) {
    router.events.subscribe(() => {
      this.setupMenu();
    });
  }

  ngAfterContentInit() {
    this.setupMenu();
  }

  private setupMenu() {
    this.currentStepIdx = this.getStepIdx();

    // Deprecated
    const haveNewDistributions =
      this.uiService.currentSurveyDistributions?.some((d) => d.interaction);
    if (
      haveNewDistributions &&
      (!this.uiService.currentSurvey.settings ||
        Object.keys(this.uiService.currentSurvey.settings).length === 0)
    ) {
      this.steps = this.steps.filter((e) => e !== "Settings");
      this.stepRoutes = this.stepRoutes.filter((e) => e !== "settings");
    }
  }

  public getStepIdx() {
    const route = this.uiService.currentPageRoutePath;
    if (!route) return -1;

    const splitUrl = route.split("?")[0].split("/");

    if (splitUrl.length < 6) return -1;
    const step = splitUrl[5];

    return this.stepRoutes.findIndex((s) => s === step);
  }

  public surveyTitleValidate(): boolean {
    if (this.editingSurveyTitle.trim().length === 0) {
      this.editingSurveyTitleError = "Empty title";
      return false;
    }

    return true;
  }

  public onSurveyTitleSubmit() {
    if (!this.surveyTitleValidate()) return;

    this.editingSurveyTitleLoading = true;
    this.editingSurveyTitleError = null;

    return this.surveyDao
      .updateTitleAndTags(
        this.uiService.currentOrgId,
        this.uiService.currentSurveyId,
        this.editingSurveyTitle,
        this.uiService.currentSurvey.tags,
      )
      .then(() => {
        this.uiService.currentSurvey.title = this.editingSurveyTitle;
        this.editingSurveyTitleLoading = false;
        this.editingSurveyTitleError = null;
        this.editingSurveyTitle = null;

        // async
        this.uiService.fetchEverything();
      })
      .catch((err: HttpErrorResponse) => {
        this.editingSurveyTitleLoading = false;
        this.editingSurveyTitleError = err.error;
        console.error(err.error);
        throw err;
      });
  }

  public onClickMenuCollapse() {
    this.collapseMenu.emit();
  }

  public onStepClicked(stepIdx: number) {
    this.currentStepIdx = stepIdx;
    const route = this.stepRoutes[stepIdx];

    if (route) {
      let routes = [route];

      // For message, when going to share, we must have a distribution id
      if (this.uiService.isMessagePage && route === "share") {
        // Get first edited distribution
        const distribution = this.uiService.currentSurveyDistributions.find(
          (d) => Number(d.updated_at) !== Number(d.created_at),
        );

        if (!distribution) {
          routes = ["edit"];
        } else {
          routes = ["share", distribution.id];
        }
      }

      this.router.navigate([
        "org",
        this.uiService.currentOrgId,
        this.uiService.surveyTypePath,
        this.uiService.currentSurveyId,
        ...routes,
      ]);
    }
  }
}
