<nz-select
  class="selectpicker"
  nzShowSearch
  nzAllowClear
  nzPlaceHolder="Select a variable"
  [ngModel]="ngModel"
  (ngModelChange)="ngModelChange.emit($event)"
  (nzOnSearch)="onSearchInputChange($event)"
  [nzStatus]="haveError ? 'error' : 'default'"
>
  <nz-option
    *ngIf="searchInput?.length > 0"
    [nzLabel]="searchInput"
    [nzValue]="searchInput"
  ></nz-option>
  <nz-option
    *ngFor="let option of nzOptions"
    [nzValue]="option.value"
    [nzLabel]="option.label"
  ></nz-option>
</nz-select>
