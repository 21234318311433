/* eslint-disable @angular-eslint/no-output-on-prefix */

import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { RangeSelect } from "components/utils/range-button/range-button.component";

import { autoTips } from "helpers/form-errors.helper";
import { NotificationHelper } from "helpers/notification.helper";
import { HookType, REGEXP_VALIDATION_WEBHOOK } from "models/hook.model";
import { IntegrationDao } from "models/integration.dao";
import { IntegrationSettingsCobbaiItem } from "models/integrations.model";
import { Org } from "models/org.model";
import { Survey } from "models/survey.model";
import { TrackersService } from "services/trackers.service";
import { NgIf } from "@angular/common";
import {
  NzFormDirective,
  NzFormItemComponent,
  NzFormLabelComponent,
  NzFormControlComponent,
} from "ng-zorro-antd/form";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { NzInputDirective, NzInputGroupComponent } from "ng-zorro-antd/input";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { RangeButtonComponent } from "../../../../../../utils/range-button/range-button.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";

@Component({
  selector: "integration-settings-cobbai-edit",
  templateUrl: "./cobbai-settings-edit.component.html",
  styleUrls: ["./cobbai-settings-edit.component.scss"],
  imports: [
    NgIf,
    FormsModule,
    NzFormDirective,
    ReactiveFormsModule,
    NzRowDirective,
    NzFormItemComponent,
    NzColDirective,
    NzFormLabelComponent,
    NzFormControlComponent,
    NzInputDirective,
    NzInputGroupComponent,
    ɵNzTransitionPatchDirective,
    RangeButtonComponent,
    NzButtonComponent,
    NzWaveDirective,
  ],
})
export class IntegrationSettingsCobbaiEditComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public survey: Survey = null;
  @Input() public hook: IntegrationSettingsCobbaiItem = null;

  @Output() public onSave = new EventEmitter<IntegrationSettingsCobbaiItem>();
  @Output() public onCancel = new EventEmitter<unknown>();
  @Output() public onRemove = new EventEmitter<unknown>();

  private initialURL: string = null;
  public validateForm: FormGroup = null;
  public autoTips = autoTips;
  public replaying: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private integrationDao: IntegrationDao,
    private notificationHelper: NotificationHelper,
    private trackersService: TrackersService,
  ) {}

  ngOnInit() {
    // backup initial value, in order to trigger a sync on value change
    this.initialURL = this.hook?.path;

    this.validateForm = this.formBuilder.group({
      version: [this.hook.version, [Validators.required]],
      name: [this.hook.name, [Validators.required]],
      path: [
        this.hook.path,
        [
          Validators.required,
          Validators.pattern(REGEXP_VALIDATION_WEBHOOK),
          // Validators.pattern(REGEXP_VALIDATION_WEBHOOK_COBBAI),  // commented because cobbai team cannot test easily in dev and staging env
        ],
      ],
    });

    this.validate();
  }

  private validate() {}

  public save() {
    this.validate();

    if (!this.validateForm.valid) return;

    const item = {
      id: this.hook.id,
      version: this.validateForm.value["version"],
      name: this.validateForm.value["name"],
      path: this.validateForm.value["path"],
    } as IntegrationSettingsCobbaiItem;

    this.onSave.emit(item);

    // if (this.initialURL !== this.validateForm.value["path"]) {
    //   this.triggerHookReplay();
    // }
  }

  public cancel() {
    this.onCancel.emit();
  }

  public remove() {
    this.onRemove.emit();
  }

  public triggerHookReplay(range: RangeSelect) {
    const hook: HookType = "response.ended";
    const params = {
      cobbai_endpoint: this.validateForm.value["path"],
      cobbai_version: this.validateForm.value["version"],
    };

    this.replaying = true;

    if (range === null) {
      range = {
        from: new Date(2021, 1, 2),
        to: new Date(),
      };
    }

    this.integrationDao
      .triggerHookReplay(
        this.org.id,
        "cobbai",
        hook,
        this.survey?.id ? [this.survey.id] : [],
        null,
        params,
        range,
      )
      .then(() => {
        this.notificationHelper.trigger(
          "A synchronisation of your Cobbai project has been ordered!",
          null,
          "success",
        );

        this.trackersService
          .newEventTrackingBuilder("Integration Cobbai sync requested")
          .withOrg(this.org)
          .withSurvey(this.survey)
          .withProps(params)
          .build();
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);

        this.notificationHelper.trigger(
          err?.error?.message ?? "Failed to synchronize to Cobbai.",
          null,
          "error",
        );
      })
      .finally(() => {
        this.replaying = false;
      });
  }
}
