<free-trial-banner />
<header>
  <h1>Users</h1>

  <div class="analytics-filters">
    <survey-stats-filters
      reportType="users"
      [canFilterByDate]="false"
      [canShare]="false"
      [canExport]="false"
      [org]="org"
      [reporting]="'Users'"
      type="respondent"
    ></survey-stats-filters>
  </div>
</header>

<error-message
  *ngIf="error && !listLoading"
  message="An error occured when fetching data."
  [details]="error.message"
  (reload)="analyticsFilterService.triggerChange()"
></error-message>

<div *ngIf="!error">
  <section nz-row [nzGutter]="[32, 16]" class="indicators">
    <div nz-col nzSpan="8">
      <survey-stats-indicators-single-indicator
        title="Total Number of Users"
        [value]="total"
        format="number"
        [loading]="listLoading"
      ></survey-stats-indicators-single-indicator>
    </div>
    <div nz-col nzSpan="8">
      <survey-stats-indicators-single-indicator
        title="Daily Active Users"
        [value]="dailyActive"
        format="number"
        [loading]="dayLoading"
      ></survey-stats-indicators-single-indicator>
    </div>
    <div nz-col nzSpan="8">
      <survey-stats-indicators-single-indicator
        title="Monthly Active Users"
        [value]="monthlyActive"
        format="number"
        [loading]="monthLoading"
      ></survey-stats-indicators-single-indicator>
    </div>
  </section>
  <section class="content d100">
    <div class="filters-bar">
      <div class="left">
        <!-- {{ total }} users -->
      </div>
      <div class="right">
        <filters-save-segment
          *ngIf="'segment.update' | hasPermission"
          class="save-segment"
          [org]="org"
        ></filters-save-segment>
        <filters-csv-export [org]="org" type="respondent"></filters-csv-export>
        <div class="search-bar">
          <form
            nz-form
            (ngSubmit)="onSearchChangeImmediate(lastFilters.keyword)"
          >
            <nz-input-group
              nzSize="large"
              [nzPrefix]="prefixIcon"
              [nzSuffix]="inputClearButton"
            >
              <input
                type="text"
                nz-input
                placeholder="Search a User"
                [ngModel]="lastFilters.keyword"
                (ngModelChange)="onSearchChange($event)"
                [ngModelOptions]="{ standalone: true }"
              />
            </nz-input-group>
            <ng-template #prefixIcon>
              <i nz-icon nzType="search"></i>
            </ng-template>
            <ng-template #inputClearButton>
              <nz-spin *ngIf="searching"></nz-spin>
              <i
                nz-icon
                class="ant-input-clear-icon"
                nzTheme="fill"
                nzType="close-circle"
                *ngIf="lastFilters.keyword"
                (click)="onSearchChangeImmediate('')"
              ></i>
            </ng-template>
          </form>
        </div>
      </div>
    </div>

    <div class="user-table-group">
      <ng-template #notFound>
        <div *ngIf="listLoading" style="height: 100px"></div>
        <nz-empty
          *ngIf="!listLoading"
          nzNotFoundContent="No users found"
        ></nz-empty>
      </ng-template>

      <nz-table
        class="user-table"
        #table
        [nzData]="users"
        [nzPageSize]="pageSize"
        [nzPageIndex]="pageIndex"
        [nzTotal]="maxOffset"
        [nzLoading]="listLoading"
        [nzShowSizeChanger]="true"
        [nzFrontPagination]="false"
        [nzHideOnSinglePage]="true"
        [nzPageSizeOptions]="[10, 25, 100]"
        (nzQueryParams)="onQueryParamsChange($event)"
        [nzScroll]="{ x: '1100px' }"
        [nzNoResult]="notFound"
      >
        <thead>
          <tr>
            <th
              *ngFor="let column of listOfColumns"
              [(nzSortOrder)]="column.sortOrder"
              [nzSortFn]="column.sortFn"
              [nzSortDirections]="column.sortDirections"
              [nzFilterMultiple]="column.filterMultiple"
              [nzColumnKey]="column.sortKey"
              [nzWidth]="column.width"
              [nzAlign]="column.align"
            >
              {{ column.name }}
            </th>
            <th [nzWidth]="'70px'" nzRight></th>
          </tr>
        </thead>
        <tr *ngIf="trackingCTAVisible()" class="cta-install">
          <td colspan="5">
            <a
              nz-button
              nzSize="large"
              nzType="default"
              class="ant-btn-secondary"
              routerLink="/org/{{ org.id }}/settings/install"
            >
              Install Screeb SDK for feeding your user list.
            </a>
          </td>
        </tr>
        <tbody *ngIf="!trackingCTAVisible()">
          <ng-container *ngFor="let user of table.data">
            <!--
                HACK: using (click), we cannot right-click on a row and select "open in a new table".
                So we added a "<a>" html tag between tbody and tr.
              -->
            <a
              class="hack"
              routerLink="/org/{{ org.id }}/people/respondent/{{ user.id }}"
              (click)="onRowClick()"
            >
              <tr>
                <td nzAlign="left" class="user-icon">
                  <img
                    alt="User's avatar"
                    nz-icon
                    loading="lazy"
                    [src]="getUserIcon(user.id, user.getAvatarURL())"
                  />
                </td>
                <td nzAlign="left" class="bold-text" nzEllipsis="true">
                  {{ getFormattedUserNameOrID(user) }}
                  <ng-container *ngIf="user.is_anonymous">
                    <br />
                    <nz-tag
                      nz-popover
                      nzPopoverTitle="Anonymous users"
                      [nzPopoverContent]="anonymousHelp"
                    >
                      Anonymous
                    </nz-tag>

                    <ng-template #anonymousHelp>
                      This user has not been identified in Javascript tag and
                      mobile SDKs.
                      <br />
                      <a
                        rel="noopener"
                        class=""
                        href="https://help.screeb.app"
                        target="_blank"
                      >
                        <i nz-icon nzType="link"></i>
                        See our help section.
                      </a>
                    </ng-template>
                  </ng-container>
                </td>
                <td
                  nzAlign="center"
                  [title]="user.created_at.toLocaleString('en')"
                >
                  {{
                    user.created_at
                      | dfnsFormatDistanceToNow: { addSuffix: true }
                  }}
                </td>
                <td
                  nzAlign="center"
                  [title]="user.last_activity_at.toLocaleString('en')"
                >
                  {{
                    user.last_activity_at
                      | dfnsFormatDistanceToNow: { addSuffix: true }
                  }}
                </td>
                <td nzAlign="right" class="actions" nzRight>
                  <a
                    routerLink="/org/{{ org.id }}/people/respondent/{{
                      user.id
                    }}"
                    title="User"
                    nz-button
                    class="button-create"
                    nzSize="large"
                    nzType="default"
                    nzShape="round"
                  >
                    <img
                      alt="Stats"
                      nz-icon
                      src="/assets/icons/homepage/graph.svg"
                    />
                  </a>
                </td>
              </tr>
            </a>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </section>
</div>
