import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { PageComponentInterface } from "components/PageComponentInterface";
import { Org } from "models/org.model";
import { SuperOrg } from "models/super-org.model";
import { RoutingService } from "services/routing.service";
import { SettingsService } from "services/settings.service";

@Component({
  selector: "page-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomePageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Welcome to Screeb! 👋";
  public name = "Welcome";

  private obs: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private settingsService: SettingsService,
  ) {}

  private workspaceExists(orgs: Org[], workspaceId?: string) {
    return (
      workspaceId && orgs.length && orgs.some(({ id }) => id === workspaceId)
    );
  }

  private organizationExists(orgs: SuperOrg[], organizationId?: string) {
    return (
      organizationId &&
      orgs.length &&
      orgs.some(({ id }) => id === organizationId)
    );
  }

  private navigateToWorkspace(
    workspaceId: string,
    redirectTo: string[],
    queryParams?: Params,
  ) {
    if (redirectTo[0] === "redirect-home") {
      this.router.navigate(["org", workspaceId], {
        queryParams,
        replaceUrl: true,
      });
    } else {
      this.router.navigate(["org", workspaceId, ...redirectTo], {
        queryParams,
        replaceUrl: true,
      });
    }
  }

  private navigateToOrg(
    organizationId: string,
    redirectTo: string[],
    queryParams?: Params,
  ) {
    if (redirectTo[0] === "redirect-home") {
      this.router.navigate(["overview", organizationId], {
        queryParams,
        replaceUrl: true,
      });
    } else {
      this.router.navigate(["overview", organizationId, ...redirectTo], {
        queryParams,
        replaceUrl: true,
      });
    }
  }

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      false,
    );
    this.obs = this.route.data.subscribe((data) => {
      const orgs = data["orgs"];
      const superOrgs = data["superOrgs"];
      const { last_organization_id, last_super_organization_id } =
        this.settingsService.getAdminSettings();
      const redirectTo = this.route.snapshot.url.map(({ path }) => path);

      // if user has history of last navigated workspace, navigate to it
      if (
        orgs?.length &&
        last_organization_id &&
        this.workspaceExists(orgs, last_organization_id)
      ) {
        this.navigateToWorkspace(
          last_organization_id,
          redirectTo,
          this.route.snapshot.queryParams,
        );
      } else if (!!orgs && orgs.length > 0) {
        // if user is in multiple workspaces but doesn't have history, navigate to the first one
        this.navigateToWorkspace(
          orgs[0].id,
          redirectTo,
          this.route.snapshot.queryParams,
        );
      } else if (
        superOrgs?.length &&
        last_super_organization_id &&
        this.organizationExists(superOrgs, last_super_organization_id)
      ) {
        // if user has history of last navigated organization, navigate to it
        this.navigateToOrg(
          last_super_organization_id,
          redirectTo,
          this.route.snapshot.queryParams,
        );
      } else if (!!superOrgs && superOrgs.length > 0) {
        // if user is in multiple organizations but doesn't have history, navigate to the first one
        this.navigateToOrg(
          superOrgs[0].id,
          redirectTo,
          this.route.snapshot.queryParams,
        );
      } else {
        // if no workspaces or organizations, navigate to missing-org page
        this.router.navigate(["missing-org"]);
      }
    });
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
  }
}
