import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgIf } from "@angular/common";
import { ScreebIconComponent } from "../../utils/screeb-icon/screeb-icon.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";

@Component({
  selector: "settings-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  imports: [
    NgIf,
    ScreebIconComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
  ],
})
export class SettingsHeaderComponent implements OnInit {
  @Input() public title: string;
  @Input() public description: string;
  @Input() public isLoading: boolean = false;
  @Input() public isDisabled: boolean = false;
  @Input() public error: boolean | string | null = null;

  @Output() public save = new EventEmitter<void>();

  public isSaveUsed = false;

  ngOnInit() {
    this.isSaveUsed = this.save.observers.length > 0;
  }

  constructor() {}
}
