import { HttpClient } from "@angular/common/http";
import { Injectable, TemplateRef } from "@angular/core";
import { ActivationEnd, Router } from "@angular/router";

import { ENV } from "environment";
import { channelTypes } from "models/channel.model";
import { OrgDao } from "models/org.dao";
import { Org } from "models/org.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";
import { SurveyDistributionDao } from "models/survey-distribution.dao";
import {
  DistributionInteractions,
  SurveyDistribution,
} from "models/survey-distribution.model";
import { SurveyDao } from "models/survey.dao";
import { SurveyLanguages } from "models/survey.dao.types";
import { Survey, SurveyType } from "models/survey.model";
import { Subject } from "rxjs";
import { SessionService } from "services/auth.service";
import { SettingsService } from "services/settings.service";
import localeEmoji from "locale-emoji";

export enum AppTheme {
  AUTO = "auto",
  LIGHT = "light",
  DARK = "dark",
}

type NotificationBlockKey =
  | "upgrade"
  | "indexing"
  | "degraded"
  | "sdk-update"
  | "mtu";

export type LanguageWithEmoji = {
  value: SurveyLanguages;
  label: string;
  emoji: string;
};

export type LanguagesAndCountries = {
  surveyLanguages: string[][];
  translationLanguages: string[][];
  countries: string[][];
  surveyLanguagesWithEmojis: LanguageWithEmoji[];
  translationLanguagesWithEmojis: LanguageWithEmoji[];
};

function languagesToEmoji(languages: string[][]): LanguageWithEmoji[] {
  return languages.map(
    ([value, label]): LanguageWithEmoji => ({
      value,
      label,
      emoji: localeEmoji(value) || "🏳️",
    }),
  );
}

@Injectable()
class UIService {
  // All user super orgs
  public superOrgs: SuperOrg[] = null;

  // All user workspaces not filtered by super org
  public orgs: Org[] = null;

  // All user org workspaces
  public orgWorkspaces: Org[] = null;

  // Most users will only have one super org
  public currentSuperOrg: SuperOrg = null;
  public currentSuperOrgId: string = null;

  public currentOrg: Org = null;
  public currentOrgId: string = null;

  public currentSurvey: Survey = null;
  public currentSurveyId: string = null;

  public currentSurveyDistributions: SurveyDistribution[];

  public theme: AppTheme = AppTheme.LIGHT;

  public languagesAndCountries: LanguagesAndCountries | null = null;

  public surveyType: SurveyType = "survey";
  public surveyTypePath: string = "survey";

  public isLayoutEmbbeded = false;
  public isSuperOrgPage = false;
  public isAuthPage = false;
  public isOverviewPage = false;
  public isSurveyPage = false;
  public isMessagePage = false;
  public isStatsPage = false;
  public isSharingPage = false;
  public isWorkspaceSettingsPage = false;
  public isIntegrationListPage = false;
  public isTemplatePage = false;
  public isOnBoardingPage = false;
  public isFullSiderPage = false;
  public isBillingPage = false;
  public isPeoplePage = false;

  public updateSubject: Subject<any>;

  public notificationBlockTemplates: {
    [key in NotificationBlockKey]: TemplateRef<object>;
  } = {} as {
    [key in NotificationBlockKey]: TemplateRef<object>;
  };
  public notificationBlockKeys: NotificationBlockKey[] = [];
  private closedNotificationBlocks: NotificationBlockKey[] = [];

  public currentPageRoutePath: string = null;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private orgDao: OrgDao,
    private superOrgDao: SuperOrgDao,
    private surveyDao: SurveyDao,
    private surveyDistributionDao: SurveyDistributionDao,
    private settingsService: SettingsService,
  ) {
    this.updateSubject = new Subject<any>();
    this.router.events.subscribe((e) => this.onRouterEvent(e));

    // Handle theme
    this.refreshTheme();
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", () => {
        this.refreshTheme();
      });
  }

  public setNotificationBlockTemplate(key: NotificationBlockKey) {
    if (
      this.closedNotificationBlocks.includes(key) ||
      this.notificationBlockKeys.includes(key)
    ) {
      return;
    }
    this.notificationBlockKeys.unshift(key);
  }

  public closeNotificationBlock() {
    const key = this.notificationBlockKeys.shift();
    this.closedNotificationBlocks.push(key);
  }

  private onRouterEvent(event) {
    switch (true) {
      case event instanceof ActivationEnd: {
        if (event.snapshot.params.org_id) {
          this.settingsService.setAdminSettingsKey(
            "last_organization_id",
            event.snapshot.params.org_id,
          );
        }

        if (event.snapshot.params.super_org_id) {
          this.settingsService.setAdminSettingsKey(
            "last_super_organization_id",
            event.snapshot.params.super_org_id,
          );
        }

        break;
      }
      default:
        break;
    }
  }

  private checkAuth(): boolean {
    if (!this.sessionService.isAuth()) {
      this.currentSuperOrg = null;
      this.currentSuperOrgId = null;
      this.orgWorkspaces = null;
      this.currentOrg = null;
      this.currentOrgId = null;
      this.currentSurvey = null;
      this.currentSurveyId = null;
      this.currentSurveyDistributions = null;
      this.currentPageRoutePath = null;
      this.isLayoutEmbbeded = false;
      this.isSuperOrgPage = false;
      this.isOverviewPage = false;
      this.isSurveyPage = false;
      this.isMessagePage = false;
      this.surveyType = "survey";
      this.surveyTypePath = "survey";
      this.isStatsPage = false;
      this.isSharingPage = false;
      this.isWorkspaceSettingsPage = false;
      this.isIntegrationListPage = false;
      this.isTemplatePage = false;
      this.isOnBoardingPage = false;
      return false;
    }
    return true;
  }

  public async fetchEverything(url?: string, force = false) {
    // Refresh layout as soon as possible
    this.refreshLayout(url);

    if (!this.languagesAndCountries) {
      this.languagesAndCountries = await this.fetchLanguagesAndCountries();
    }

    if (this.checkAuth()) {
      await Promise.all([
        this.fetchSuperOrgs(force),
        this.fetchOrgs(force),
        this.fetchSurvey(),
        this.fetchSurveyDistributions(),
      ]);

      this.refreshCurrentDatas();
    }

    const mtuPercent = (
      this.currentSuperOrg?.entitlements?.mtu_mode === "auto"
        ? this.currentSuperOrg
        : this.currentOrg
    )?.usedMTUPercent;
    if (mtuPercent && mtuPercent >= 90) {
      this.setNotificationBlockTemplate("mtu");
    } else {
      this.notificationBlockKeys = this.notificationBlockKeys.filter(
        (key) => key !== "mtu",
      );
    }

    this.updateSubject.next(true);
  }

  private async fetchLanguagesAndCountries() {
    return Promise.all([
      this.httpClient.get<object>(`/assets/data/languages.json`).toPromise(),
      this.httpClient
        .get<object>(`/assets/data/translation_languages.json`)
        .toPromise(),
      this.httpClient.get<object>(`/assets/data/countries.json`).toPromise(),
    ]).then((data) => {
      const languages: string[][] = Object.entries(data[0]);
      const translationLanguages: string[][] = Object.entries(data[1]);
      const countries: string[][] = Object.entries(data[2]);
      const surveyLanguagesWithEmojis = languagesToEmoji(languages);
      const translationLanguagesWithEmojis =
        languagesToEmoji(translationLanguages);

      languages.sort((a, b) => a[1].localeCompare(b[1]));
      translationLanguages.sort((a, b) => a[1].localeCompare(b[1]));
      countries.sort((a, b) => a[1].localeCompare(b[1]));

      return {
        surveyLanguages: languages,
        translationLanguages,
        countries,
        surveyLanguagesWithEmojis: surveyLanguagesWithEmojis,
        translationLanguagesWithEmojis,
      };
    });
  }

  private async fetchSuperOrgs(force?: boolean): Promise<boolean> {
    try {
      if (this.superOrgs?.length && !force) {
        return true;
      }

      const superOrgs = await this.superOrgDao.getAll();

      // order by name
      superOrgs.sort((a: SuperOrg, b: SuperOrg) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });

      this.superOrgs = superOrgs;
    } catch (err) {
      console.error(err);
      return false;
    }
    return true;
  }

  private async fetchOrgs(force?: boolean): Promise<boolean> {
    try {
      if (this.orgs?.length && !force) {
        return true;
      }

      const orgs = await this.orgDao.getAll();

      // order by name
      orgs.sort((a: Org, b: Org) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });

      this.orgs = orgs;
    } catch (err) {
      console.error(err);
      return false;
    }
    return true;
  }
  private async fetchSurvey(): Promise<boolean> {
    try {
      const currentOrgId = this.getOrgIdFromURL();
      const currentSurveyId = this.getSurveyIdFromURL();
      if (!!currentOrgId && !!currentSurveyId) {
        this.currentSurvey = await this.surveyDao.getById(
          currentOrgId,
          currentSurveyId,
        );
      } else {
        this.currentSurvey = null;
      }
    } catch (err) {
      console.error(err);
      return false;
    }

    return true;
  }

  private async fetchSurveyDistributions(): Promise<boolean> {
    try {
      const currentOrgId = this.getOrgIdFromURL();
      const currentSurveyId = this.getSurveyIdFromURL();
      if (!!currentOrgId && !!currentSurveyId) {
        this.currentSurveyDistributions =
          await this.surveyDistributionDao.getAllBySurveyId(
            currentOrgId,
            currentSurveyId,
          );

        // Sort distributions to always follow this order
        this.currentSurveyDistributions.sort((a, b) => {
          if (a.interaction !== b.interaction) {
            if (a.interaction && !b.interaction) return -1;
            if (!a.interaction && b.interaction) return 1;
            return (
              DistributionInteractions.indexOf(a.interaction) -
              DistributionInteractions.indexOf(b.interaction)
            );
          }
          return channelTypes.indexOf(a.type) - channelTypes.indexOf(b.type);
        });
      } else {
        this.currentSurveyDistributions = null;
      }
    } catch (err) {
      console.error(err);
      return false;
    }

    return true;
  }

  private refreshLayout(url?: string) {
    this.refreshCurrentPageComponent(url);
    this.refreshIsLayoutPageEmbbeded();
    this.refreshIsAuthPage();
    this.refreshIsSuperOrgPage();
    this.refreshIsOverviewPage();
    this.refreshIsMessagePage();
    this.refreshIsSurveyPage();
    this.refreshIsStatsPage();
    this.refreshIsSharingPage();
    this.refreshIsSettingsPage();
    this.refreshIsIntegrationListPage();
    this.refreshIsTemplatePage();
    this.refreshIsOnBoardingPage();
    this.refreshIsBillingPage();
    this.refreshIsPeoplePage();
    this.refreshFullSiderPage();
  }

  private refreshCurrentDatas() {
    this.refreshCurrentOrg();
    if (this.currentOrgId) {
      this.settingsService.setAdminSettingsKey(
        "last_organization_id",
        this.currentOrgId,
      );
    }

    this.refreshCurrentSuperOrg();
    if (this.currentSuperOrgId) {
      this.settingsService.setAdminSettingsKey(
        "last_super_organization_id",
        this.currentSuperOrgId,
      );
    }

    this.refreshCurrentOrgWorkspaces();

    this.refreshCurrentSurvey();
  }

  private refreshCurrentPageComponent(url?: string) {
    this.currentPageRoutePath = url ?? this.router.url;
  }

  private refreshCurrentOrg() {
    this.currentOrgId = this.getOrgIdFromURL();
    this.currentOrg = this.orgs?.find((o: Org) => o.id === this.currentOrgId);

    if (!this.currentOrg && this.orgs?.length === 1) {
      this.currentOrg = this.orgs[0];
      this.currentOrgId = this.orgs[0].id;
    }
  }

  private refreshCurrentSuperOrg() {
    if (!this.currentOrg) {
      this.currentSuperOrgId = this.getSuperOrgIdFromURL();
      this.currentSuperOrg = this.superOrgs?.find(
        (o: SuperOrg) => o.id === this.currentSuperOrgId,
      );
    } else {
      this.currentSuperOrg = this.superOrgs?.find(
        (o: SuperOrg) => o.id === this.currentOrg.super_org_id,
      );
      this.currentSuperOrgId = this.currentSuperOrg?.id;
    }

    if (!this.currentSuperOrg && this.superOrgs?.length === 1) {
      this.currentSuperOrg = this.superOrgs[0];
      this.currentSuperOrgId = this.superOrgs[0].id;
    }
  }

  private refreshCurrentOrgWorkspaces() {
    // When we know the current super org, we can filter workspaces
    // Why doing it in that way? Cause i want to alter previous workspaces logic a minimum
    if (this.currentSuperOrgId) {
      this.orgWorkspaces =
        this.orgs?.filter(
          (o: Org) => o.super_org_id === this.currentSuperOrgId,
        ) || [];
    } else {
      // Assuming that this should never happen, unless we mannually remove an account from super org
      this.orgWorkspaces = this.orgs;
    }

    // Be sure that current workspace is in org
    if (
      this.currentSuperOrgId &&
      this.currentOrg?.super_org_id !== this.currentSuperOrgId
    ) {
      this.currentOrg = this.orgWorkspaces?.[0] ?? null;
      this.currentOrgId = this.orgWorkspaces?.[0]?.id ?? null;
    }
  }

  private async refreshCurrentSurvey() {
    this.currentSurveyId = this.getSurveyIdFromURL();
    if (!this.currentSurveyId) {
      this.currentSurvey = null;
      this.currentSurveyDistributions = null;
    }
  }

  public setTheme(theme: AppTheme): void {
    window.localStorage?.setItem(`x-screeb-theme-${ENV.ENV}`, theme);
    this.refreshTheme();
  }

  private async refreshTheme() {
    // Get theme from localstorage
    const data = window.localStorage?.getItem(`x-screeb-theme-${ENV.ENV}`);
    const theme = (data || AppTheme.LIGHT) as AppTheme;

    if (theme === AppTheme.AUTO) {
      this.theme =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
          ? AppTheme.DARK
          : AppTheme.LIGHT;
    } else {
      this.theme = theme;
    }

    document.documentElement.setAttribute("data-theme", this.theme);
  }

  private refreshIsLayoutPageEmbbeded(): boolean {
    return (this.isLayoutEmbbeded = ["sdk-changelogs", "user-recruitment"].some(
      (p) => this.currentPageRoutePath.indexOf(p) !== -1,
    ));
  }
  private refreshIsAuthPage(): boolean {
    return (this.isAuthPage =
      this.currentPageRoutePath.indexOf("/auth/") !== -1);
  }
  private refreshIsSuperOrgPage(): boolean {
    return (this.isSuperOrgPage =
      this.currentPageRoutePath.indexOf("/overview/") !== -1);
  }
  private refreshIsOverviewPage(): boolean {
    return (this.isOverviewPage =
      this.currentPageRoutePath.indexOf("/home") !== -1);
  }
  private refreshIsSurveyPage(): boolean {
    return (
      (this.isSurveyPage =
        this.currentPageRoutePath.indexOf("/survey/") !== -1) ||
      this.currentSurvey?.type === "survey"
    );
  }
  private refreshIsMessagePage(): boolean {
    this.isMessagePage =
      this.currentPageRoutePath.indexOf(`/message/`) !== -1 ||
      this.currentSurvey?.type === "message";

    if (this.isMessagePage) {
      this.surveyType = "message";
      this.surveyTypePath = "message";
    } else {
      this.surveyType = "survey";
      this.surveyTypePath = "survey";
    }
    return this.isMessagePage;
  }
  private refreshIsStatsPage(): boolean {
    return (this.isStatsPage =
      this.currentPageRoutePath.indexOf("/stats/") !== -1);
  }
  private refreshIsSharingPage(): boolean {
    return (this.isSharingPage =
      this.currentPageRoutePath.indexOf("/share/") !== -1);
  }
  private refreshIsSettingsPage(): boolean {
    if (
      this.currentPageRoutePath.indexOf("/settings/") !== -1 &&
      this.currentPageRoutePath.indexOf("/overview/") !== -1
    ) {
      return (this.isWorkspaceSettingsPage = false);
    }
    return (this.isWorkspaceSettingsPage =
      this.currentPageRoutePath.indexOf("/settings/") !== -1);
  }
  private refreshIsIntegrationListPage(): boolean {
    return (this.isIntegrationListPage =
      this.currentPageRoutePath.indexOf("/integrations") !== -1);
  }
  private refreshIsTemplatePage(): boolean {
    return (this.isTemplatePage =
      this.currentPageRoutePath.indexOf("/survey/create") !== -1 ||
      this.currentPageRoutePath.indexOf("/message/create") !== -1);
  }
  private refreshIsOnBoardingPage(): boolean {
    return (this.isOnBoardingPage =
      this.currentPageRoutePath.indexOf("/onboarding/") !== -1);
  }
  private refreshFullSiderPage(): boolean {
    // Avoid sidebar junking on redirect to home
    if (this.currentPageRoutePath === "/redirect-home") {
      return (this.isFullSiderPage = true);
    }

    if (
      this.currentPageRoutePath.indexOf("/settings/") !== -1 &&
      this.currentPageRoutePath.indexOf("/overview/") !== -1
    ) {
      return (this.isFullSiderPage = true);
    }
    return (this.isFullSiderPage = [
      "/create",
      "/list",
      "people/",
      "/home",
      "/quickstart",
      "/workspaces",
      "/users",
      "/settings",
      "/releases",
      "/integrations",
    ].some((p) => this.currentPageRoutePath.indexOf(p) !== -1));
  }

  private refreshIsBillingPage(): boolean {
    return (this.isBillingPage =
      this.currentPageRoutePath.indexOf("/billing/") !== -1);
  }

  private refreshIsPeoplePage(): boolean {
    return (this.isPeoplePage =
      this.currentPageRoutePath.indexOf("people") !== -1);
  }

  private getSuperOrgIdFromURL(): string {
    return this.getSuperOrgIdFromURLFallback();
  }

  private getSuperOrgIdFromURLFallback(): string {
    if (this.currentPageRoutePath.startsWith("/overview/"))
      return this.currentPageRoutePath.split("/")?.[2];
    return null;
  }

  private getOrgIdFromURL(): string {
    return this.getOrgIdFromURLFallback();
  }

  private getOrgIdFromURLFallback(): string {
    if (this.currentPageRoutePath.startsWith("/org/"))
      return this.currentPageRoutePath.split("/")?.[2];

    // Or from workspace query param
    const queryParams = this.router.parseUrl(
      this.currentPageRoutePath,
    ).queryParams;
    if (queryParams?.workspace) return queryParams.workspace;
    return null;
  }

  private getSurveyIdFromURL(): string {
    return this.getSurveyIdFromURLFallback();
  }

  private getSurveyIdFromURLFallback(): string {
    const orgId = this.getOrgIdFromURL();
    if (!orgId) return null;
    if (
      this.currentPageRoutePath.startsWith(`/org/${orgId}/survey/`) ||
      this.currentPageRoutePath.startsWith(`/org/${orgId}/message/`)
    ) {
      const id = this.currentPageRoutePath.split("/")?.[4];
      return id === "list" || id === "create" || id.length !== 36 ? null : id;
    }
    return null;
  }
}

export { UIService };
