import {
  Component,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ChartData, ChartOptions, LineOptions } from "chart.js";
import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
} from "date-fns";
import { BaseChartDirective } from "ng2-charts";
import { NgIf, NgTemplateOutlet, DecimalPipe } from "@angular/common";

type DataFormat = object | string | number | Date;

const ChartFormat = {
  day: { max: 365, diff: differenceInDays },
  week: { max: 52, diff: differenceInWeeks },
  month: { max: 12, diff: differenceInMonths },
};
type ChartFormatKey = keyof typeof ChartFormat;
@Component({
  selector: "trend-chart",
  template: `
    <div class="line-chart-container">
      <div class="soon-cover" *ngIf="soon">
        <div class="soon-text">Soon</div>
      </div>
      <div class="chart-header-container">
        <div class="chart-header">
          <span class="canvas-title">
            <ng-container
              *ngIf="!isTypeString(title)"
              [ngTemplateOutlet]="title"
            ></ng-container>
            <ng-container *ngIf="isTypeString(title)">{{ title }}</ng-container>
          </span>
          <ng-container
            *ngIf="!isTypeString(subHeader)"
            class="chart-sub-header"
            [ngTemplateOutlet]="subHeader"
          ></ng-container>
          <div class="chart-sub-header">
            <ng-container *ngIf="isTypeString(subHeader)">
              {{ subHeader }}
            </ng-container>
          </div>
        </div>
        <div class="chart-value">
          <span class="current-value">{{ sum | number }}</span>
          <ng-container *ngIf="total !== null">
            <span *ngIf="total > -1" class="max-value">
              / {{ total | number }}
            </span>
            <span *ngIf="total === -1" class="max-value">/ Unlimited</span>
          </ng-container>
        </div>
      </div>
      <div class="chart-footer">
        <ng-container
          *ngIf="!isTypeString(footer)"
          [ngTemplateOutlet]="footer"
        ></ng-container>
        <ng-container *ngIf="isTypeString(footer)">{{ footer }}</ng-container>
      </div>
      <canvas
        baseChart
        [data]="chartData"
        [options]="chartOptions"
        [legend]="false"
        type="line"
        id="line-chart"
      ></canvas>
    </div>
  `,
  styleUrls: ["./trend-chart.component.scss"],
  imports: [NgIf, BaseChartDirective, NgTemplateOutlet, DecimalPipe],
})
export class TrendChartComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  // When set to False, the chart will be hidden and a loader will be displayed
  @Input() public ready: boolean = true;

  @Input() public title: string | TemplateRef<void> = "";
  // When set to True, total will be average of all labels score.
  @Input() public isTotalAverage = false;
  // When set to True, labels scores will be cumulated and create a growing line.
  @Input() public isDataLineCumulating = false;

  // Can't use css variable here for these two colors
  // So let's get the computed value from the body on init
  @Input() public lineColor = "";
  @Input() public backgroundColor = "";
  @Input() public subHeader: string | TemplateRef<void> = null;
  @Input() public footer: string | TemplateRef<void> = null;
  @Input() public soon: boolean = false;

  // Data required to fill the chart. can be Object of Date.
  @Input() data: DataFormat[] = [];
  // In case of Object, providing dateKey prop is required
  @Input() dataKey: string = "";

  // default to the start of the year
  @Input() from: Date = new Date(new Date().getFullYear(), 0, 1);
  //default to now()
  @Input() to: Date = new Date();
  @Input() format: ChartFormatKey = "month";

  // current value to display
  @Input() value: number = null;

  // total value to display. null will hide it, -1 will display Unlimited
  @Input() total: number = null;

  public sum = 0;

  chartData: ChartData;

  public chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: [],
    layout: {
      padding: {
        top: 5,
        bottom: 2,
      },
    },
    scales: {
      y: {
        display: false, // Hide Y axis labels
      },
      x: {
        display: false, // Hide X axis labels
      },
    },
  };
  constructor() {}
  ngOnInit() {
    // Get computed color from body
    const style = getComputedStyle(document.body);
    if (this.lineColor === "") {
      this.lineColor = style.getPropertyValue("--screeb-color-purple-500");
    }

    if (this.backgroundColor === "") {
      this.backgroundColor = style.getPropertyValue(
        "--screeb-color-purple-alpha",
      );
    }

    // Setup Chart gradient Background
    const canvas: HTMLCanvasElement = <HTMLCanvasElement>(
      document.getElementById("line-chart")
    );
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(405 / 2, 0, 405 / 2, 152);

    gradient.addColorStop(0.5, this.backgroundColor);
    gradient.addColorStop(1, "transparent");

    this.chartData = {
      labels: [],
      datasets: [
        {
          data: [],
          pointRadius: 0,
          fill: true,
          tension: 0.5,
          borderColor: this.lineColor,
        },
      ],
    };
    this.chartData.datasets.forEach((dataset) => {
      dataset.backgroundColor = gradient;

      (dataset as LineOptions).tension = this.isDataLineCumulating ? 0 : 0.5;
    });
  }

  ngOnChanges() {
    if (!this.ready) {
      return;
    }
    // Setup Time data in Chart
    this.loadData();
  }

  loadData() {
    if (this.data.length === 0) {
      if (this.value !== null && this.value !== undefined) {
        this.sum = this.value;
      }
      return;
    }

    const dateData = this.data.map((d) => {
      if (d instanceof Date) {
        return d;
      } else if (typeof d === "object") {
        return new Date(d[this.dataKey]);
      } else {
        return new Date(d);
      }
    });

    const chartFormat = ChartFormat[this.format];
    const labelSize = chartFormat.diff(this.to, this.from) + 1;

    const labels = Array.from(new Array(labelSize), (val, idx) => `${idx + 1}`);
    const sumData = new Array(labelSize).fill(0);

    dateData
      .filter(
        (d) =>
          d.getTime() >= this.from.getTime() &&
          d.getTime() <= this.to.getTime(),
      )
      .forEach((d) => {
        sumData[chartFormat.diff(d, this.from)]++;
      });

    if (this.value !== null && this.value !== undefined) {
      this.sum = this.value;
    } else {
      // Calculate Sum
      if (this.isTotalAverage) {
        this.sum = Math.round(sumData.reduce((a, b) => a + b) / sumData.length);
      } else {
        this.sum = sumData.reduce((a, b) => a + b);
      }
    }

    if (this.isDataLineCumulating) {
      sumData.reduce((previousValue, currentValue, idx) => {
        sumData[idx] += previousValue;

        return sumData[idx];
      }, 0);
    }

    setTimeout(() => {
      if (this.chart && this.chart.chart && this.chart.chart.config) {
        this.chart.chart.config.data.labels = labels;
        this.chart.chart.config.data.datasets[0].data = sumData;
        this.chart.chart.update();
      }
    });
  }

  public isTypeString(stuff: string | TemplateRef<any>): boolean {
    return typeof stuff === "string";
  }
}
