<div class="widget-wrapper" [ngClass]="editing ? 'editing' : ''">
  <button
    *ngIf="editing"
    class="delete-button"
    (click)="deleteClicked.emit($event)"
  >
    <screeb-icon size="md" icon="delete" />
  </button>

  <widget-graph
    *ngIf="widget.type === 'nps'"
    type="nps"
    [startDate]="startDate"
    [endDate]="endDate"
  />
  <widget-graph
    *ngIf="widget.type === 'ces'"
    type="ces"
    [startDate]="startDate"
    [endDate]="endDate"
  />
  <widget-graph
    *ngIf="widget.type === 'csat'"
    type="csat"
    [startDate]="startDate"
    [endDate]="endDate"
  />
  <widget-graph
    *ngIf="widget.type === 'mau'"
    type="mau"
    [startDate]="startDate"
    [endDate]="endDate"
  />
  <widget-graph
    *ngIf="widget.type === 'completionRate'"
    type="completionRate"
    [startDate]="startDate"
    [endDate]="endDate"
  />
  <widget-graph
    *ngIf="widget.type === 'responseNumber'"
    type="responseNumber"
    [startDate]="startDate"
    [endDate]="endDate"
  />
  <widget-graph
    *ngIf="widget.type === 'responseRate'"
    type="responseRate"
    [startDate]="startDate"
    [endDate]="endDate"
  />
  <widget-graph
    *ngIf="widget.type === 'displayNumber'"
    type="displayNumber"
    [startDate]="startDate"
    [endDate]="endDate"
  />
  <widget-activity
    *ngIf="widget.type === 'activity'"
    [startDate]="startDate"
    [endDate]="endDate"
    [org]="org"
    [orgHasRespondents]="orgHasRespondents"
  />
  <widget-emotions
    *ngIf="widget.type === 'emotions'"
    [startDate]="startDate"
    [endDate]="endDate"
  />
  <widget-device-type
    *ngIf="widget.type === 'device_type'"
    [startDate]="startDate"
    [endDate]="endDate"
    [org]="org"
    [surveys]="surveys"
  />
  <widget-identification
    *ngIf="widget.type === 'identification'"
    [startDate]="startDate"
    [endDate]="endDate"
    [org]="org"
  />
  <widget-changelog
    *ngIf="widget.type === 'changelog'"
    (click)="editing ? $event.preventDefault() : ''"
  />
  <widget-last-surveys *ngIf="widget.type === 'last_surveys'" />
  <widget-mau-usage *ngIf="widget.type === 'entitlement_mtu'" />
  <widget-quote-of-the-day *ngIf="widget.type === 'quote_of_the_day'" />
  <widget-help *ngIf="widget.type === 'help'" />
  <free-text-block
    *ngIf="widget.type === 'free_text_block'"
    [editing]="editing"
    [text]="widget.text"
    (textChange)="widget.text = $event; widgetChange.emit(widget)"
    class="free-text-block"
  />
</div>
