/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-output-rename */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { integrationUpdate } from "components/integration/settings/integration-settings.component";
import { NotificationHelper } from "helpers/notification.helper";

import {
  Integration,
  IntegrationSettings,
  IntegrationSettingsTypeform,
  IntegrationSettingsTypeformItem,
} from "models/integrations.model";
import { Org } from "models/org.model";
import { RegistryDao } from "models/registry.dao";
import { RegistryEntry } from "models/registry.model";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import {
  NzTableComponent,
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzTbodyComponent,
} from "ng-zorro-antd/table";
import { IntegrationSettingsTypeformEditComponent } from "./edit/typeform-settings-edit.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";

@Component({
  selector: "integration-settings-typeform",
  templateUrl: "./typeform-settings.component.html",
  styleUrls: ["./typeform-settings.component.scss"],
  imports: [
    NgIf,
    NzSpinComponent,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzTbodyComponent,
    NgFor,
    NgClass,
    IntegrationSettingsTypeformEditComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
  ],
})
export class IntegrationSettingsTypeformComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public integration: Integration = null;

  @Output("onSettingsChange") public settings =
    new EventEmitter<integrationUpdate>();

  private currentlyExpandedItem: number = -1;
  private newItemId: string = null;

  public registryEntriesIdentityProperty: RegistryEntry[] = [];
  public registryEntriesIdentityPropertyLoading: boolean = true;

  constructor(
    private trackersService: TrackersService,
    private registryDao: RegistryDao,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit() {
    this.fetchRegistryIdentityProperties();

    if (this.integration.settings.typeform.items.length === 0) {
      this.onItemAdd();
    }
  }

  public fetchRegistryIdentityProperties() {
    this.registryDao
      .getIdentityProperties(this.org.id)
      .then((keys) => {
        this.registryEntriesIdentityProperty = keys;
        this.registryEntriesIdentityPropertyLoading = false;
      })
      .catch((err) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      });
  }

  public pruneNewItems() {
    if (!this.integration.settings?.typeform?.items) {
      return;
    }

    this.integration.settings.typeform.items =
      this.integration.settings.typeform.items.filter(
        (item) => item.id !== this.newItemId,
      );
  }

  public onItemAdd() {
    if (!this.integration.settings)
      this.integration.settings = new IntegrationSettings();
    if (!this.integration.settings.typeform)
      this.integration.settings.typeform = IntegrationSettingsTypeform.New();

    this.pruneNewItems();

    const newItem = IntegrationSettingsTypeformItem.New();
    this.integration.settings.typeform.items.push(newItem);
    this.integration.settings.typeform.items = [].concat(
      ...this.integration.settings.typeform.items,
    ); // triggers @Input() refresh

    this.expandItem(this.integration.settings.typeform.items.length - 1);

    this.newItemId = newItem.id; // mark item as new, in order to remove it if we cancel edition
  }

  public onItemSaved(index: number, item: IntegrationSettingsTypeformItem) {
    const createMode =
      this.integration.settings.typeform.items[index].id === this.newItemId;
    if (createMode) {
      this.newItemId = null;
    }

    this.integration.settings.typeform.items[index].id = item.id;
    this.integration.settings.typeform.items[index].name = item.name;
    this.integration.settings.typeform.items[index].typeform = item.typeform;
    this.integration.settings.typeform.items[index].screeb = item.screeb;

    this.propagateItemChange();
    this.noExpand();

    this.trackChange(
      createMode
        ? "Integration Typeform webhook added"
        : "Integration Typeform webhook updated",
      item,
    );
  }

  public onItemCanceled() {
    this.noExpand();
  }

  public onItemRemoved(index: number) {
    this.noExpand();

    this.integration.settings.typeform.items.splice(index, 1);
    this.integration.settings.typeform.items = [].concat(
      ...this.integration.settings.typeform.items,
    ); // triggers @Input() refresh

    this.propagateItemChange();

    this.trackChange("Integration Typeform webhook removed", null);
  }

  public onWebhookCopy(item: IntegrationSettingsTypeformItem) {
    this.trackChange("Integration Typeform webhook copied", item);
  }

  private propagateItemChange() {
    this.pruneNewItems();
    this.settings.emit({ settings: this.integration.settings });
  }

  private trackChange(
    eventName: TrackingEventName,
    item?: IntegrationSettingsTypeformItem,
  ) {
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withIntegration(this.integration)
      .withProps({
        webhook_item_id: item?.id,
        webhook_item_name: item?.name,
        webhook_item_typeform_mode: item?.typeform?.mode,
        webhook_item_typeform_hidden_field: item?.typeform?.hidden_field,
        webhook_item_typeform_ref: item?.typeform?.ref,
        webhook_item_screeb_mode: item?.screeb?.mode,
        webhook_item_screeb_property_key: item?.screeb?.property_key,
      })
      .build();
  }

  /**
   * Collapsible
   */
  public isItemExpanded(i: number): boolean {
    return this.currentlyExpandedItem === i;
  }

  public expandItem(i: number) {
    if (this.currentlyExpandedItem !== -1) this.pruneNewItems();

    this.currentlyExpandedItem = this.isItemExpanded(i) ? -1 : i;
  }

  public noExpand() {
    this.currentlyExpandedItem = -1;
    this.pruneNewItems();
  }
}
