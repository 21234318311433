import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { NzSelectComponent, NzOptionComponent } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { NgFor, NgIf } from "@angular/common";

@Component({
  selector: "lateral-panel-variable-picker",
  templateUrl: "./variable-picker.component.html",
  styleUrls: ["./variable-picker.component.scss"],
  imports: [NzSelectComponent, FormsModule, NzOptionComponent, NgFor, NgIf],
})
export class LateralPanelVariablePickerComponent implements OnInit, OnChanges {
  @Input() ngModel: string = "";
  @Input() nzOptions: { value: string; label: string }[] = [];
  @Input() nzPlaceHolder: string = "";

  @Output() ngModelChange = new EventEmitter<string>();

  public searchInput: string = "";
  public haveError: boolean = false;

  ngOnInit(): void {
    this.onInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ngModel || changes.nzOptions) {
      this.onInit();
    }
  }

  private onInit(): void {
    const options = this.nzOptions.map((option) => option.value);
    if (!options.includes(this.ngModel)) {
      this.searchInput = this.ngModel;
    }
  }

  public onSearchInputChange(value: string): void {
    if (this.searchInput === value) return;

    this.haveError = value.length >= 128;
    this.searchInput = value;
  }
}
