import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { emojiTranscoder } from "components/builder/components/Cards/sanitized-message/emojis";
import { AnalyticsResponseItemUser } from "models/analytics.model";
import { Org } from "models/org.model";
import { RegistryDao } from "models/registry.dao";
import { RegistryEntry } from "models/registry.model";
import { UserRecord, UserRecordSnapshot } from "models/user-record.model";
import rrwebPlayer from "rrweb-player";
import { RouterLink } from "@angular/router";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";
import { NgIf, NgFor } from "@angular/common";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { UserSimpleListComponent } from "../../../user/common/user-simple-list/user-simple-list.component";
import { UserRecordDao } from "models/user-record.dao";
import { AnalyticsQueryUsers } from "models/analytics.filters.type";
import { UUID } from "models/survey.dao.types";
import { AnalyticsDao } from "models/analytics.dao";

@Component({
  selector: "user-record-preview",
  templateUrl: "./user-record-preview.component.html",
  styleUrls: ["./user-record-preview.component.scss"],
  imports: [
    RouterLink,
    ScreebIconComponent,
    NgIf,
    NgFor,
    NzTooltipDirective,
    UserSimpleListComponent,
  ],
})
export class UserRecordPreviewComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() public loading: boolean = false;
  @Input() public recordID: string | null;
  @Input() public org: Org;

  @ViewChild("rrwebPlayerWrapper", { static: false, read: ElementRef })
  rrwebPlayerWrapper;

  public session: UserRecord = null;
  public user: AnalyticsResponseItemUser = null;
  public isUserLoading = true;
  public player: rrwebPlayer = null;
  public initialized = false;

  public emojiTranscoder = emojiTranscoder;

  private rObs: ResizeObserver;

  public registryEntriesGroup: RegistryEntry[] = [];

  constructor(
    private registryDao: RegistryDao,
    private userRecordDao: UserRecordDao,
    private analyticsDao: AnalyticsDao,
  ) {}

  ngAfterViewInit() {
    this.init();
  }

  ngOnChanges({ recordID }: SimpleChanges) {
    if (!this.rrwebPlayerWrapper?.nativeElement) {
      return;
    }

    if (recordID && recordID.currentValue !== recordID.previousValue) {
      this.init();
    }
  }

  ngOnDestroy() {
    this.rObs?.disconnect();
  }

  public async init() {
    if (!this.recordID) {
      return;
    }

    this.initialized = false;
    this.session = await this.userRecordDao.getPreviewById(
      this.org.id,
      this.recordID,
    );

    try {
      await this.registryDao.getGroups(this.org.id).then((res) => {
        this.registryEntriesGroup = res.groups;
      });
    } catch (e) {
      console.error(e);
    }

    // Get first snapshot
    const snapshots = await this.userRecordDao.getSnapshostsByID(
      this.org.id,
      this.session.id,
      0,
      2,
    );

    const decoded =
      snapshots?.map((s: UserRecordSnapshot) => {
        const data = s.unpackData();
        return {
          type: s.type,
          source: s.source,
          data: data,
          timestamp: new Date(s.timestamp).getTime(),
          delay: s.delay,
        };
      }) ?? [];

    const wrapper = this.rrwebPlayerWrapper.nativeElement as HTMLElement;
    const maxSize = Math.max(wrapper.clientWidth, wrapper.clientHeight);

    try {
      this.player = new rrwebPlayer({
        target: this.rrwebPlayerWrapper.nativeElement,
        props: {
          events: decoded,
          width: maxSize,
          height: maxSize,
          showController: false,
          autoPlay: false,
          useVirtualDom: true,
          mouseTail: {
            strokeStyle: "#5e21f1",
          },
        },
      });
    } catch (e) {
      console.error(e);
    }

    this.rObs = new ResizeObserver(() => this.onResize());
    this.rObs.observe(wrapper);

    // Let player initialize
    setTimeout(() => {
      this.initialized = true;
    }, 250);

    this.isUserLoading = false;
    const query: AnalyticsQueryUsers = {
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      filters_bool: "OR",
      type: "respondent",
      filters: [
        {
          type: "respondent",
          key: "aliases",
          operator: "eq",
          value: this.session.user_id,
        },
      ],
      range: {
        start: this.org.created_at,
        end: new Date(),
        field: "created_at",
      },
      identified_only: false,
      size: 1,
      with_total: false,
    };
    const response = await this.analyticsDao.search(query);
    this.user = response.hits.respondents[0];
    this.isUserLoading = false;
  }

  // Ok this is a bit hacky but it works
  // as only triggerResize is not working
  public onResize() {
    setTimeout(() => {
      const replayer = this.player?.getReplayer();
      const root = replayer?.config.root as HTMLElement;
      if (!root) {
        return;
      }
      const wrapper = this.rrwebPlayerWrapper.nativeElement as HTMLElement;
      const maxSize = Math.max(wrapper.clientWidth, wrapper.clientHeight);
      root.style.width = maxSize + "px";
      root.style.height = maxSize + "px";
      root.parentElement.style.width = root.style.width;
      // @ts-ignore - not in the type
      this.player.$$set({
        width: maxSize,
        height: maxSize,
      });
      this.player.triggerResize();
    }, 0);
  }

  public getComment(session: UserRecord, id: string) {
    return session.comments.find((h) => h.id === id);
  }
}
