import { Component, Input } from "@angular/core";

@Component({
  selector: "banner",
  template: `
    <div
      class="banner"
      [style]="{
        'background-color': color,
        'border-color': borderColor,
      }"
    >
      <span class="title">{{ message }}</span>
    </div>
  `,
  styles: [
    `
      .banner {
        display: flex;
        align-items: center;
        border: 4px solid var(--screeb-color-red-200);
        justify-content: center;
        gap: 16px;
        border-radius: 16px;
        width: 100%;
        min-height: 72px;
        padding: 16px 90px;
        animation: shadeAppear 200ms;
        margin-bottom: 32px;
      }

      .title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        color: #303140;
      }

      .banner .upgrade-btn {
        height: 40px;
      }

      @keyframes shadeAppear {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `,
  ],
})
export class BannerComponent {
  @Input() public color: string = null;
  @Input() public borderColor: string = "transparent";
  @Input() public message: string = null;

  constructor() {}
}
