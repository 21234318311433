import { Component, EventEmitter, Output } from "@angular/core";
import { UtilsLateralPanelComponent } from "../../../utils/lateral-panel/lateral-panel.component";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { Card101LinkComponent } from "../../../utils/card-101-link/card-101-link.component";
import { IntercomButtonComponent } from "../../../utils/intercom-button/intercom-button.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";

@Component({
  selector: "funnel-help",
  template: `
    <utils-lateral-panel
      [withPaddingTop]="false"
      (onClose)="closePanel.emit($event)"
    >
      <div class="lateral-header-left">
        <button (click)="closePanel.emit($event)" class="close">
          <i nz-icon nzType="close" nzTheme="outline"></i>
        </button>
        <h2 class="title">I need help on funnels</h2>
      </div>

      <div class="lateral-body">
        <h3>Watch this quick video about Funnels</h3>

        <iframe
          width="461"
          height="259"
          src="https://www.youtube.com/embed/BMIsPf88ofE"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>

        <h3>Read our complete documentation</h3>

        <card-101-link
          title="How to Set Up a Funnel Report"
          description="A step by step guide to analyse and increase your funnel's performances"
          minuteRead="3"
          url="https://help.screeb.app/en/articles/6980118-how-to-set-up-a-funnel-report"
          color="rgb(255, 240, 218)"
        ></card-101-link>

        <h3>Any other question?</h3>

        <intercom-button
          class="ask-us-a-question"
          title="Ask us a question"
          backgroundColor="var(--screeb-color-primary)"
          borderColor="var(--screeb-color-primary)"
          textColor="var(--screeb-color-background)"
        />
      </div>

      <div class="lateral-footer-right">
        <button
          nz-button
          nzType="default"
          nzSize="large"
          (click)="closePanel.emit($event)"
        >
          Close
        </button>
      </div>
    </utils-lateral-panel>
  `,
  styles: [
    `
      :host h2 {
        margin-bottom: 0 !important;
      }

      :host h3 {
        margin-top: 32px;
      }

      :host .lateral-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      :host iframe {
        border-radius: 16px;
      }

      :host .ask-us-a-question {
        border-radius: 16px;
        height: 48px;
        justify-content: center;
        margin: 0 auto;
      }
    `,
  ],
  imports: [
    UtilsLateralPanelComponent,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    Card101LinkComponent,
    IntercomButtonComponent,
    NzButtonComponent,
    NzWaveDirective,
  ],
})
export class FunnelHelpComponent {
  @Output() closePanel = new EventEmitter<Event>();

  constructor() {}
}
