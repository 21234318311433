<error-message
  *ngIf="error"
  message="An error occured when fetching data."
  [details]="error.message"
  (reload)="analyticsFilterService.triggerChange()"
></error-message>

<section *ngIf="!error" class="content d100">
  <div class="filters-bar">
    <div class="bar-left">
      <survey-stats-filters
        [canShare]="false"
        [canExport]="false"
        [hideUserFilters]="!!user"
        [org]="org"
        type="session"
      ></survey-stats-filters>
    </div>
    <div class="bar-right">
      <div class="tags-filter">
        <nz-select
          nzShowSearch
          nzAllowClear
          nzPlaceHolder="All Tags"
          nzSize="large"
          nzMode="multiple"
          [nzLoading]="tagsLoading"
          [ngModel]="filteredTags"
          [nzShowArrow]="true"
          nzSuffixIcon="caret-down"
          (nzFocus)="onFetchTags()"
          (ngModelChange)="onTagsFilterChange($event)"
        >
          @if (!tagsLoading) {
            <ng-container *ngFor="let tag of sessionTags">
              <nz-option [nzLabel]="tag" [nzValue]="tag"></nz-option>
            </ng-container>
          } @else {
            <nz-option nzDisabled nzCustomContent>
              <span nz-icon nzType="loading" class="loading-icon"></span>
              Loading Data...
            </nz-option>
          }
        </nz-select>
      </div>
      <div class="search-bar">
        <nz-input-group
          nzSize="large"
          [nzPrefix]="prefixIcon"
          [nzSuffix]="inputClearButton"
        >
          <input
            type="text"
            nz-input
            placeholder="Search a session"
            [(ngModel)]="keyword"
            (ngModelChange)="onSearchChange()"
          />
        </nz-input-group>
        <ng-template #prefixIcon>
          <i nz-icon nzType="search"></i>
        </ng-template>
        <ng-template #inputClearButton>
          <i
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="keyword"
            (click)="keyword = ''; onSearchChange()"
          ></i>
        </ng-template>
      </div>
    </div>
  </div>
  <div
    class="ai-suggest-container"
    *ngIf="suggestedSessionIDs.length || loading"
  >
    <div class="ai-suggest-sessions">
      <h3>
        ✨&nbsp;
        <b>Priority Replays to Watch</b>
        <small>(Suggested by AI)</small>
      </h3>
      <div class="ai-suggest-list">
        @let topSuggestedSessions = suggestedSessionIDs.slice(0, 2);
        <div
          class="ai-suggest-item"
          *ngFor="let recordID of topSuggestedSessions; trackBy: trackByFn"
        >
          <user-record-preview
            [recordID]="recordID"
            [loading]="loading"
            [org]="org"
          ></user-record-preview>
        </div>
      </div>
    </div>
    <div class="ai-suggest-summary">
      <h3>✨&nbsp; AI Summary</h3>
      <div class="ai-suggest-summary-block" [class.is-error]="!!summaryError">
        <text-shimmer *ngIf="isSummaryLoading"></text-shimmer>
        <ng-container *ngIf="!isSummaryLoading">
          <p *ngIf="summaryError">
            {{ summaryError.message }}
          </p>
          <ng-container *ngIf="!summaryError">
            @let trimmed = summary.slice(0, summaryTrimLength);
            @let trimmedWithEllipsis =
              summary.length > summaryTrimLength ? trimmed + "..." : trimmed;
            <markdown
              ngPreserveWhitespaces
              [data]="viewMoreSummary ? summary : trimmedWithEllipsis"
            ></markdown>
          </ng-container>
        </ng-container>
        <button
          *ngIf="
            !isSummaryLoading &&
            !summaryError &&
            summary.length > summaryTrimLength
          "
          nz-button
          nzType="link"
          (click)="viewMoreSummary = !viewMoreSummary"
        >
          {{ !viewMoreSummary ? "View more" : "View less" }}
        </button>
      </div>
    </div>
  </div>

  <div class="session-table-group">
    <h3>
      <b>All Sessions</b>
      <nz-tag *ngIf="maxOffset > 0" nzColor="blue">
        {{ maxOffset | number }}
      </nz-tag>
    </h3>
    <ng-template #notFound>
      <div *ngIf="loading" style="height: 100px"></div>
      <nz-empty
        *ngIf="!loading"
        nzNotFoundContent="No sessions found"
      ></nz-empty>
    </ng-template>
    <nz-table
      class="session-table"
      #table
      [nzData]="sessions"
      [nzPageSize]="pageSize"
      [nzHideOnSinglePage]="true"
      [nzPageIndex]="pageIndex"
      [nzTotal]="maxOffset"
      [nzLoading]="loading"
      [nzShowSizeChanger]="true"
      [nzFrontPagination]="false"
      [nzHideOnSinglePage]="true"
      [nzPageSizeOptions]="[10, 25, 100]"
      (nzQueryParams)="onQueryParamsChange($event)"
      [nzNoResult]="notFound"
    >
      <thead>
        <tr>
          <th
            *ngFor="let column of listOfColumns"
            [(nzSortOrder)]="column.sortOrder"
            [nzSortFn]="column.sortFn"
            [nzSortDirections]="column.sortDirections"
            [nzFilterMultiple]="column.filterMultiple"
            [nzColumnKey]="column.sortKey"
            [nzWidth]="column.width"
            [nzAlign]="column.align"
          >
            {{ column.name }}
          </th>
        </tr>
      </thead>
      <tr
        *ngIf="org.stats.total_respondents <= 0 && sessions.length <= 0"
        class="cta-install"
      >
        <td colspan="5">
          <a
            nz-button
            nzSize="large"
            nzType="default"
            class="ant-btn-secondary"
            routerLink="/org/{{ org.id }}/settings/install"
          >
            Install Screeb SDK for feeding your sessions list.
          </a>
        </td>
      </tr>
      <tbody *ngIf="org.stats.total_respondents > 0 || sessions.length > 0">
        <ng-container *ngFor="let session of table.data">
          <!--
                    HACK: using (click), we cannot right-click on a row and select "open in a new table".
                    So we added a "<a>" html tag between tbody and tr.
                  -->
          <a
            class="hack"
            routerLink="/org/{{ org.id }}/people/sessions/{{ session.id }}"
          >
            <tr>
              <td nzAlign="left">
                <div class="session-icon {{ session.seen_at ? 'seen' : '' }}">
                  <screeb-icon size="md" icon="play" />
                </div>
              </td>
              <td nzAlign="left" nzEllipsis="true">
                <span *ngIf="!session.highlights?.length">No highlights</span>
                <div
                  *ngIf="session.highlights?.length"
                  class="session-highlights"
                >
                  @let topHighlight = session.highlights.slice(0, 5);
                  <ng-template #highlightTitle let-highlight="highlight">
                    <span [innerHTML]="highlight.title"></span>
                  </ng-template>
                  <div
                    class="session-highlight session-highlight__{{
                      highlight.type
                    }}"
                    *ngFor="let highlight of topHighlight"
                    nz-tooltip
                    [nzTooltipTitle]="highlightTitle"
                    [nzTooltipTitleContext]="{ highlight: highlight }"
                  >
                    <screeb-icon
                      *ngIf="highlight.type === 'response'"
                      size="sm"
                      icon="survey"
                    ></screeb-icon>
                    <screeb-icon
                      *ngIf="highlight.type === 'event'"
                      size="md"
                      icon="events"
                    ></screeb-icon>
                    <ng-container *ngIf="highlight.type === 'comment'">
                      @let comment = getComment(session, highlight.data);
                      <ng-container *ngIf="comment.emoji?.length">
                        @let emoji = emojiTranscoder(comment.emoji);
                        <span [innerHtml]="emoji"></span>
                      </ng-container>
                      <screeb-icon
                        *ngIf="!comment.emoji?.length"
                        size="sm"
                        icon="comment"
                      ></screeb-icon>
                    </ng-container>
                    <screeb-icon
                      *ngIf="highlight.type === 'console'"
                      size="sm"
                      style="color: {{
                        highlight.data === 'error'
                          ? '#F11672'
                          : highlight.data === 'warn'
                            ? '#faad14'
                            : undefined
                      }} !important"
                      icon="{{
                        highlight.data === 'error'
                          ? 'cross-circle'
                          : highlight.data === 'warn'
                            ? 'warning'
                            : 'info-circle'
                      }}"
                    ></screeb-icon>
                  </div>
                  <div
                    class="session-highlight session-highlight__more"
                    *ngIf="session.highlights.length > 5"
                  >
                    <screeb-icon size="md" icon="response" />
                    <span>{{ session.highlights.length - 5 }}</span>
                  </div>
                </div>
                <div class="tags" *ngIf="session.tags?.length">
                  <tags [tags]="session.tags" size="small"></tags>
                </div>
              </td>
              <td nzAlign="left" *ngIf="!user">
                <div class="respondent">
                  <user-simple-list
                    [users]="
                      session && users[session.user_id]
                        ? [users[session.user_id]]
                        : []
                    "
                    [loading]="isUsersLoading"
                    userNameSize="small"
                    [shouldShowArrow]="false"
                    [shouldShowPresence]="true"
                    [showEmptyState]="false"
                    [maxTagsCount]="2"
                    iconSize="small"
                  ></user-simple-list>
                </div>
              </td>
              <td nzAlign="left">
                @let dur =
                  duration(session.duration)
                    | dfnsFormatDuration
                      : { format: ["hours", "minutes", "seconds"] };
                {{ dur }}
              </td>
              <td nzAlign="left">
                <div>
                  {{
                    session.started_at
                      | dfnsFormatDistanceToNow: { addSuffix: true }
                  }}
                </div>
                <div>
                  <span
                    *ngIf="
                      session.context?.os?.name || session.context?.device?.name
                    "
                  >
                    {{
                      session.context?.os?.name || session.context?.device?.name
                    }}
                    <span *ngIf="session.context?.browser?.name">
                      &nbsp;•&nbsp;
                    </span>
                  </span>
                  <span *ngIf="session.context?.browser?.name">
                    {{ session.context.browser.name }}
                  </span>
                </div>
              </td>
              <td nzAlign="left">
                <div
                  class="session-score"
                  nz-tooltip
                  [nzTooltipTitle]="
                    session.completion_status >= 3
                      ? session.score + '%'
                      : 'Processing...'
                  "
                >
                  @let scoring = [].constructor(10);
                  <span
                    *ngFor="let item of scoring; let i = index"
                    class="{{ (i + 1) * 10 < session.score ? 'active' : '' }}"
                    [class.has-shimmer]="session.completion_status < 3"
                  ></span>
                </div>
              </td>
            </tr>
          </a>
        </ng-container>
      </tbody>
    </nz-table>
  </div>
</section>
