import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";

import { autoTips } from "helpers/form-errors.helper";
import { IntegrationSettingsTypeformItem } from "models/integrations.model";
import { RegistryEntry } from "models/registry.model";
import { ConfigService } from "services/config.service";
import {
  NzFormDirective,
  NzFormItemComponent,
  NzFormLabelComponent,
  NzFormControlComponent,
} from "ng-zorro-antd/form";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { NzInputDirective } from "ng-zorro-antd/input";
import { TextSnippetCopyComponent } from "../../../../../../utils/text-snippet-copy/text-snippet-copy.component";
import { NzRadioGroupComponent, NzRadioComponent } from "ng-zorro-antd/radio";
import { SelectorUserPropertiesComponent } from "../../../../../../common/user/selector-user-properties/selector-user-properties.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";

@Component({
  selector: "integration-settings-typeform-edit",
  templateUrl: "./typeform-settings-edit.component.html",
  styleUrls: ["./typeform-settings-edit.component.scss"],
  imports: [
    FormsModule,
    NzFormDirective,
    ReactiveFormsModule,
    NzRowDirective,
    NzFormItemComponent,
    NzColDirective,
    NzFormLabelComponent,
    NzFormControlComponent,
    NzInputDirective,
    TextSnippetCopyComponent,
    NzRadioGroupComponent,
    NzRadioComponent,
    SelectorUserPropertiesComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
  ],
})
export class IntegrationSettingsTypeformEditComponent implements OnInit {
  @Input() public orgId: string = null;
  @Input() public integrationId: string = null;
  @Input() public item: IntegrationSettingsTypeformItem = null;
  @Input() public registryEntriesIdentityProperty: RegistryEntry[] = [];

  /* eslint-disable @angular-eslint/no-output-on-prefix */
  @Output() public onSave = new EventEmitter<IntegrationSettingsTypeformItem>();
  @Output() public onCancel = new EventEmitter<unknown>();
  @Output() public onRemove = new EventEmitter<unknown>();
  @Output() public onWebhookCopy =
    new EventEmitter<IntegrationSettingsTypeformItem>();

  public validateForm: FormGroup = null;
  public autoTips = autoTips;

  constructor(
    private formBuilder: FormBuilder,
    private configService: ConfigService,
  ) {}

  ngOnInit() {
    this.validateForm = this.formBuilder.group({
      name: [this.item.name, [Validators.required]],

      typeform_mode: [this.item.typeform.mode, [Validators.required]],
      typeform_hidden_field: [
        this.item.typeform.hidden_field,
        [Validators.required],
      ],
      typeform_ref: [this.item.typeform.ref, [Validators.required]],

      screeb_mode: [this.item.screeb.mode, [Validators.required]],
      screeb_property_key: [
        this.item.screeb.property_key,
        [Validators.required],
      ],
    });

    this.onTypeformModeChange();
    this.onScreebModeChange();
    this.validate();
  }

  public onTypeformModeChange() {
    if (this.validateForm.value["typeform_mode"] === "hidden_field") {
      this.validateForm.get("typeform_hidden_field").enable();
      this.validateForm.get("typeform_ref").disable();
    } else if (this.validateForm.value["typeform_mode"] === "ref") {
      this.validateForm.get("typeform_hidden_field").disable();
      this.validateForm.get("typeform_ref").enable();
    } else {
      throw new Error("unexpected typeform mode");
    }
  }

  public onScreebModeChange() {
    if (this.validateForm.value["screeb_mode"] === "alias") {
      this.validateForm.get("screeb_property_key").disable();
    } else if (this.validateForm.value["screeb_mode"] === "property") {
      this.validateForm.get("screeb_property_key").enable();
    } else {
      throw new Error("unexpected screeb mode");
    }
  }

  private validate() {}

  public save() {
    this.validate();

    if (!this.validateForm.valid) return;

    const typeformMode = this.validateForm.value["typeform_mode"];
    const screebMode = this.validateForm.value["screeb_mode"];

    const item = {
      id: this.item.id, // can be null on first save
      name: this.validateForm.value["name"],
      typeform: {
        mode: typeformMode,
        hidden_field:
          typeformMode === "hidden_field"
            ? this.validateForm.value["typeform_hidden_field"]
            : null,
        ref:
          typeformMode === "ref"
            ? this.validateForm.value["typeform_ref"]
            : null,
      },
      screeb: {
        mode: screebMode,
        property_key:
          screebMode === "property"
            ? this.validateForm.value["screeb_property_key"]
            : null,
      },
    } as IntegrationSettingsTypeformItem;

    this.onSave.emit(item);
  }

  public cancel() {
    this.onCancel.emit();
  }

  public remove() {
    this.onRemove.emit();
  }

  public get url(): string {
    return `${this.configService.config.webhookEndpoint}/typeform/${this.integrationId}/${this.item.id}`;
  }
}
