/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input, TemplateRef } from "@angular/core";
import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import { NzRadioComponent } from "ng-zorro-antd/radio";

@Component({
  selector: "big-radio",
  template: `
    <div
      class="big-radio"
      [ngClass]="{ 'has-content': !!content, disabled: this.disabled }"
    >
      <div class="header">
        <label nz-radio nzSize="large" [nzValue]="nzValue">
          <span class="label-text">
            <ng-container
              *ngIf="!isTypeString(label)"
              [ngTemplateOutlet]="label"
            ></ng-container>
            <ng-container *ngIf="isTypeString(label)">{{ label }}</ng-container>
          </span>
        </label>
        <span *ngIf="badgeText" class="label-badge">{{ badgeText }}</span>
      </div>
      <div class="content" *ngIf="!!content">
        <ng-container
          [ngTemplateOutlet]="content"
          [ngTemplateOutletContext]="contentContext"
        ></ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      .big-radio {
        position: relative;
        background: var(--screeb-color-background);
        box-sizing: border-box;
        padding: 18px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .big-radio .ant-radio-wrapper:after {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 18px;
        border: 2px solid var(--screeb-color-border-secondary);
      }
      .big-radio .ant-radio-wrapper-checked:after {
        border-radius: 16px;
        border: 2px solid var(--screeb-color-purple-500);
        pointer-events: none;
      }
      .big-radio .ant-radio-wrapper {
        display: inline !important;
        position: initial !important;
        font-family: Rubik !important;
        font-style: normal !important;
        line-height: 150% !important;
        color: var(--screeb-color-body-text) !important;
        width: 100% !important;
      }
      .big-radio .ant-radio-wrapper * {
        width: 100% !important;
      }
      .big-checkbox.has-content label {
        font-size: 16px !important;
        font-weight: 500 !important;
      }
      .big-radio .disabled {
        opacity: 0.66;
      }
      .big-radio .content {
        margin-top: 8px;
      }
      .big-radio .label-text {
        font-weight: 500;
      }
      .big-radio .label-badge {
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;

        color: var(--screeb-color-grey-700);
        background: var(--screeb-color-green-200);

        display: inline-flex;
        align-items: center;
        height: 24px;
        padding: 4px 8px;
        border-radius: 4px;
        width: max-content;
      }
    `,
  ],
  imports: [NgClass, NzRadioComponent, NgIf, NgTemplateOutlet],
})
export class BigRadio {
  @Input() public label: string | TemplateRef<void>;
  @Input() public content?: string | TemplateRef<void>;
  @Input() public disabled = false;
  @Input() public badgeText: string = null;
  @Input() public nzValue: unknown = null;
  @Input() public contentContext?: object = null;

  public isTypeString(stuff: string | TemplateRef<any>): boolean {
    return typeof stuff === "string";
  }
}
