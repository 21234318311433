<label class="lateral-panel-subtitle">Field type</label>

<nz-select class="selectpicker" [(ngModel)]="cta.input.payload.validation">
  <nz-option nzValue="text" nzLabel="Text" selected></nz-option>
  <nz-option nzValue="email" nzLabel="Email"></nz-option>
</nz-select>

<label class="lateral-panel-subtitle">Next step</label>

<lateral-panel-step-picker
  [routing]="node.node.routing"
  [chainableNodeIds]="node.chainableNodeIds"
  [actions]="[cta.input]"
></lateral-panel-step-picker>

<ng-container>
  <label class="lateral-panel-subtitle skip-to-step-title">Skip to step</label>
  <p class="lateral-panel-tip">Allowing users to skip to a next step</p>

  <lateral-panel-step-picker
    [routing]="node.node.routing"
    [chainableNodeIds]="node.chainableNodeIds"
    [actions]="[skipAction]"
    [isSkipAction]="true"
  ></lateral-panel-step-picker>
</ng-container>

<ng-container *ngIf="'isSaveToVariableEnabled' | hasFeatureFlag">
  <label class="lateral-panel-subtitle">
    Assign this question to a property
  </label>
  <p class="lateral-panel-tip">
    Allows you to reuse the answer in other questions or in your integrations.
  </p>
  <lateral-panel-variable-picker
    nzPlaceHolder="Select a property"
    [(ngModel)]="variable"
    [nzOptions]="variables"
  ></lateral-panel-variable-picker>
  <small>Only string properties supported for this question</small>
</ng-container>
