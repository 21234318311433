import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { adaptUserEventsToCheckboxSelectItems } from "components/funnels/components/funnel-add-event/funnel-add-event-popover.component";
import { CheckboxSelectItem } from "components/utils/checkbox-select/checkbox-select.component";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { SequenceDao } from "models/sequence.dao";
import { UUID } from "models/survey.dao.types";
import { TrackersService } from "services/trackers.service";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzInputDirective } from "ng-zorro-antd/input";
import { FormsModule } from "@angular/forms";
import { NgIf } from "@angular/common";
import { CheckboxSelectComponent } from "../../../utils/checkbox-select/checkbox-select.component";
import { TagsComponent } from "../../../utils/tags/tags.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";

@Component({
  selector: "page-funnel-create",
  templateUrl: "./funnel-create.component.html",
  styleUrls: ["./funnel-create.component.scss"],
  imports: [
    CdkScrollable,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NzInputDirective,
    FormsModule,
    NgIf,
    CheckboxSelectComponent,
    TagsComponent,
    NzButtonComponent,
    NzWaveDirective,
  ],
})
export class FunnelCreatePageComponent implements OnInit {
  @Output() closePanel = new EventEmitter<Event>();

  @Input() public registryEntriesEvent: RegistryEntry[] = [];
  @Input() public org: Org;

  public availableEvents: CheckboxSelectItem[] = [];
  public selectedEvents: UUID[] = [];
  public funnelTitle = "";

  public submitLoading = false;
  public submitError = false;

  constructor(
    private sequenceDao: SequenceDao,
    private router: Router,
    private trackersService: TrackersService,
  ) {}

  ngOnInit(): void {
    this.availableEvents = adaptUserEventsToCheckboxSelectItems(
      this.registryEntriesEvent,
    );
  }

  getSelectedEventsLabels() {
    return this.selectedEvents
      .map((id) => this.availableEvents.find(({ key }) => key === id)?.label)
      .filter(Boolean);
  }

  isTitleValid() {
    return this.funnelTitle.length >= 1 && this.funnelTitle.length <= 255;
  }

  isSelectedEventsValid() {
    return this.selectedEvents.length >= 2 && this.selectedEvents.length <= 32;
  }

  isValid() {
    return this.isTitleValid() && this.isSelectedEventsValid();
  }

  submit() {
    this.submitError = false;
    this.submitLoading = true;
    this.sequenceDao
      .create(this.org.id, "funnel", this.funnelTitle, this.selectedEvents)
      .then(async ({ id }) => {
        await this.trackersService
          .newEventTrackingBuilder("Funnel created")
          .withOrg(this.org)
          .build();

        this.router.navigate(["org", this.org.id, "funnel", id, "edit"]);
      })
      .catch(() => {
        this.submitError = true;
      })
      .finally(() => {
        this.submitLoading = false;
      });
  }
}
