import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  RegistryEntrySource,
  RegistryEntrySubject,
} from "models/registry.model";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { SquareIconComponent } from "../../../utils/square-icon/square-icon.component";
import { TagRegistryEntrySourceComponent } from "../../../common/user/tag-registry-entry-source/tag-registry-entry-source.component";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { NzEmptyComponent } from "ng-zorro-antd/empty";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { FormatDistanceToNowPipeModule } from "ngx-date-fns";
import { ScreebFormatPipe } from "pipes/format.pipe";
import { ToLocaleStringPipe } from "pipes/to-locale-string.pipe";

export type UserNormalizedEvent = {
  id?: string;
  name_id?: string;
  org_id?: string;
  type?: RegistryEntrySubject;
  name?: string;
  created_at?: Date;
  updated_at?: Date;
  triggered_at?: Date;
  sources?: RegistryEntrySource[];
  count?: number;
  raw_properties?: unknown;
  properties?: unknown;
};

@Component({
  selector: "user-events",
  templateUrl: "./user-events.component.html",
  styleUrls: ["./user-events.component.scss"],
  imports: [
    NgIf,
    NgFor,
    NgClass,
    SquareIconComponent,
    TagRegistryEntrySourceComponent,
    NgxJsonViewerModule,
    NzEmptyComponent,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    FormatDistanceToNowPipeModule,
    ScreebFormatPipe,
    ToLocaleStringPipe,
  ],
})
export class UserEventsComponent {
  @Input() public userEvents: UserNormalizedEvent[] = [];

  @Output() public eventClicked: EventEmitter<object> = new EventEmitter();

  public opened: object = {};

  constructor() {}

  onEventClicked() {
    this.eventClicked.emit();
  }

  shouldDisplayProperties(properties: unknown) {
    if (properties && typeof properties === "object") {
      return Boolean(Object.values(properties).length);
    }
    return Boolean(properties);
  }
}
