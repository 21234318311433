<upgrade-modal
  [centeredModal]="true"
  [orgId]="org.id"
  [withOverlay]="true"
  [canCancel]="false"
  position="absolute"
  class="upgrade-modal"
  *ngIf="!('content_analysis' | hasEntitlement)"
></upgrade-modal>

<div
  [ngClass]="{
    'is-locked': !('content_analysis' | hasEntitlement),
  }"
>
  <header>
    <h1>
      ✨ Content Analysis
      <span class="powered-by-tag">Powered by Screeb AI</span>
    </h1>

    <div class="analytics-filters">
      <survey-stats-filters
        reportType="content-analysis"
        [org]="org"
        [survey]="survey"
        [reporting]="'Content Analysis'"
        type="response"
      ></survey-stats-filters>
    </div>
  </header>

  <ng-container
    *ngIf="
      !('hideOpenAIContentAnalysis' | hasFeatureFlag) &&
      !contentError &&
      (filteredResponseCount > 0 || contentLoading)
    "
  >
    <header class="summary">
      <p>
        <strong>Summary & Findings</strong>
        (made from
        <span [class.has-shimmer]="filteredResponseCount === 0">
          {{ filteredResponseCount }}
        </span>
        feedback)
        <ng-component
          [ngTemplateOutlet]="tooltip"
          [ngTemplateOutletContext]="{
            content: summaryTooltip,
            placement: 'rightTop',
          }"
        ></ng-component>
      </p>
      <p *ngIf="contentLoading" class="loading-info">
        Estimated Loading Time:
        <strong>~{{ loadgingTimeMessage }}</strong>
      </p>

      <div class="summary-content">
        <text-shimmer *ngIf="contentLoading"></text-shimmer>
        <span *ngIf="!contentLoading">{{ contentAnalysis?.summary }}</span>
      </div>
    </header>

    <section class="highlights-section">
      <div class="highlights">
        <ng-component
          *ngTemplateOutlet="
            summary;
            context: {
              title: 'Opportunities',
              tooltipContent: opportunitiesTooltip,
              content: contentAnalysis?.opportunities,
              background: '#FFF6EA',
            }
          "
        ></ng-component>
        <ng-component
          *ngTemplateOutlet="
            summary;
            context: {
              title: 'Strengths',
              tooltipContent: strengthsTooltip,
              content: contentAnalysis?.strengths,
              background: '#E4F8F2',
            }
          "
        ></ng-component>
        <ng-component
          *ngTemplateOutlet="
            summary;
            context: {
              title: 'Trends',
              tooltipContent: trendsTooltip,
              content: contentAnalysis?.trends,
              background: '#F5F1FF',
            }
          "
        ></ng-component>
        <ng-component
          *ngTemplateOutlet="
            summary;
            context: {
              title: 'Weakness',
              tooltipContent: weaknessTooltip,
              content: contentAnalysis?.weakness,
              background: '#FFEEF1',
            }
          "
        ></ng-component>
      </div>
      <div class="keywords">
        <h3 class="title">
          Keywords & Highlights
          <ng-component
            [ngTemplateOutlet]="tooltip"
            [ngTemplateOutletContext]="{
              content: keywordsAndHighlightTooltip,
              placement: 'rightTop',
            }"
          ></ng-component>
        </h3>
        <div class="body" *ngIf="!contentLoading">
          <tree-map *ngIf="keywords.length > 0" [data]="keywords"></tree-map>
          <div
            class="latest-answers"
            *ngIf="!contentLoading && contentAnalysis?.answers?.length > 0"
          >
            <div
              class="keyword-answer"
              *ngFor="let item of contentAnalysis.answers; let idx = index"
            >
              <a
                routerLink="/org/{{ org.id }}/people/respondent/{{
                  item.respondent_id
                }}"
              >
                @let avatarUrl =
                  getUserIcon(item.respondent_id, item.getAvatarURL());
                <img class="user-avatar" [src]="avatarUrl" />
              </a>

              <div class="answer">
                <p class="content">{{ contentAnalysis.feedbacks[idx] }}</p>
                <p class="date">
                  {{ item.created_at | date: "MMMM d, y" }}
                </p>
              </div>
            </div>
          </div>

          <div
            class="latest-answers"
            *ngIf="
              !contentLoading &&
              contentAnalysis?.answers?.length === 0 &&
              contentAnalysis?.feedbacks?.length > 0
            "
          >
            <div
              class="keyword-answer"
              *ngFor="let item of contentAnalysis.feedbacks; let idx = index"
            >
              <div class="answer">
                <p class="content">{{ contentAnalysis.feedbacks[idx] }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="body loading" *ngIf="contentLoading">
          <span
            nz-icon
            nzType="loading"
            class="circle-loader"
            nzTheme="outline"
          ></span>
        </div>
      </div>
    </section>

    <section *ngIf="!hideGraphs" class="emotion-section">
      <h3 class="title">Emotions</h3>
      <div nz-col nzSpan="24">
        <div
          class="indicator-with-border d100"
          *ngIf="
            !overallPerformancesPerDateCurrentPeriod ||
            overallPerformancesPerDateCurrentPeriod.length <= 1
          "
        >
          <nz-empty nzNotFoundContent="No data available"></nz-empty>
        </div>
        <div
          class="indicator-with-border trend-indicator d200"
          *ngIf="
            overallPerformancesPerDateCurrentPeriod &&
            overallPerformancesPerDateCurrentPeriod.length > 1
          "
        >
          <survey-stats-indicators-trend-indicator
            theme="emotions"
            [datasets]="emotionTrendChartDataset"
            [legend]="emotionTrendChartLegend"
            [releases]="releases"
            [orgAccounts]="orgAccounts"
            [orgId]="org.id"
          ></survey-stats-indicators-trend-indicator>
        </div>
      </div>
    </section>

    <section *ngIf="!hideGraphs" class="sentiment-section">
      <h3 class="title">Sentiment</h3>
      <div nz-col nzSpan="24">
        <div
          class="indicator-with-border d100"
          *ngIf="
            !overallPerformancesPerDateCurrentPeriod ||
            overallPerformancesPerDateCurrentPeriod.length <= 1
          "
        >
          <nz-empty nzNotFoundContent="No data available"></nz-empty>
        </div>
        <div
          class="indicator-with-border trend-indicator d200"
          *ngIf="
            overallPerformancesPerDateCurrentPeriod &&
            overallPerformancesPerDateCurrentPeriod.length > 1
          "
        >
          <survey-stats-indicators-trend-indicator
            theme="sentiments"
            [datasets]="sentimentTrendChartDataset"
            [legend]="sentimentTrendChartLegend"
            [releases]="releases"
            [orgAccounts]="orgAccounts"
            [orgId]="org.id"
          ></survey-stats-indicators-trend-indicator>
        </div>
      </div>
    </section>

    <section class="qa-section">
      <div class="qa">
        <h3 class="title">
          Q&A
          <ng-component
            [ngTemplateOutlet]="tooltip"
            [ngTemplateOutletContext]="{
              content: QATooltip,
              placement: 'rightTop',
            }"
          ></ng-component>
        </h3>
        <div
          class="content"
          *ngIf="!contentLoading && contentAnalysis?.qa?.length > 0"
        >
          <ng-container *ngFor="let item of contentAnalysis.qa">
            <ng-component
              *ngTemplateOutlet="
                qa;
                context: {
                  title: item.question,
                  content: item.answer,
                }
              "
            ></ng-component>
          </ng-container>
        </div>
        <div class="content loading" *ngIf="contentLoading">
          <span
            nz-icon
            nzType="loading"
            class="circle-loader"
            nzTheme="outline"
          ></span>
        </div>
      </div>

      <div class="next-steps">
        <h3 class="title">
          Next Steps
          <ng-component
            [ngTemplateOutlet]="tooltip"
            [ngTemplateOutletContext]="{
              content: nextStepsTooltip,
              placement: 'rightTop',
            }"
          ></ng-component>
        </h3>
        <div class="body" *ngIf="contentLoading">
          <text-shimmer></text-shimmer>
        </div>
        <div
          class="body"
          *ngIf="!contentLoading && contentAnalysis?.nextSteps.length > 0"
        >
          <ng-container
            *ngFor="let item of contentAnalysis.nextSteps; let idx = index"
          >
            <p>
              <strong>{{ idx + 1 }}. {{ item.title }}:</strong>
              {{ item.detail }}
            </p>
          </ng-container>
        </div>
      </div>
    </section>

    <ng-container *ngIf="nodes.length > 0"></ng-container>
    <ng-container *ngIf="nodes.length === 0"></ng-container>
  </ng-container>

  <div *ngIf="contentError" class="error-container">
    <p class="error">
      An Error occured: {{ contentError?.message || "unknow Error" }}
    </p>
    <p>Reload the page. If this error persist, please contact us.</p>
    <intercom-button title="Contact us"></intercom-button>
  </div>

  <div class="big-empty-screen" *ngIf="!filteredResponseCount">
    <img
      class="big-screen-illustration"
      src="/assets/illustrations/analytics-opportunities.png"
      alt="Empty"
    />
    <div class="big-empty-screen-right">
      <div class="big-empty-screen-title">Start Analyzing Feedback Now</div>
      <div class="big-empty-screen-description">
        Analyze key feedbacks of your app, spot friction points and find
        opportunities to improve your user experience.
        <br />
        With Screeb's Content Analysis, get better insights out of your
        feedbacks and empower your work.
        <br />
        <br />
        <i>(Content Analysis requires Text Feedback to work)</i>
      </div>
    </div>
  </div>
</div>

<ng-template
  #summary
  let-title="title"
  let-tooltipContent="tooltipContent"
  let-content="content"
  let-background="background"
>
  <div class="summary-card" style="background-color: {{ background }}">
    <text-shimmer *ngIf="contentLoading"></text-shimmer>
    <ng-container *ngIf="!contentLoading">
      <h3>
        {{ title }}
        <ng-component
          [ngTemplateOutlet]="tooltip"
          [ngTemplateOutletContext]="{
            content: tooltipContent,
            placement: 'rightTop',
          }"
        ></ng-component>
      </h3>
      <p class="content">
        {{ content }}
      </p>
    </ng-container>
  </div>
</ng-template>

<ng-template #qa let-title="title" let-content="content">
  <div class="qa-card">
    <h3>{{ title }}</h3>
    <p class="content">
      {{ content }}
    </p>
  </div>
</ng-template>

<ng-template #summaryTooltip>
  <p style="max-width: 500px; word-break: break-word; margin: 0">
    The summary is generated from the different topics discussed in the
    feedbacks. It gives you a quick overview of the main points of interest.
  </p>
</ng-template>

<ng-template #opportunitiesTooltip>
  <p style="max-width: 500px; word-break: break-word; margin: 0">
    Opportunities are suggested from feature requests or notes made by users.
    They are potential areas of improvement or new features to consider.
  </p>
</ng-template>

<ng-template #strengthsTooltip>
  <p style="max-width: 500px; word-break: break-word; margin: 0">
    Strengths are the positive aspects of your product or service that users
    appreciate. They are the things you are doing well.
  </p>
</ng-template>

<ng-template #trendsTooltip>
  <p style="max-width: 500px; word-break: break-word; margin: 0">
    Trends are the recurring topics or issues that are mentioned in the
    feedbacks. They are the things that are happening often. It's important to
    keep an eye on them.
  </p>
</ng-template>

<ng-template #weaknessTooltip>
  <p style="max-width: 500px; word-break: break-word; margin: 0">
    Weaknesses are the negative aspects of your product or service that users
    are not happy with. They are the things you need to improve.
  </p>
</ng-template>

<ng-template #keywordsAndHighlightTooltip>
  <p style="max-width: 500px; word-break: break-word; margin: 0">
    Keywords and Highlights are the most mentioned words or phrases in the
    feedbacks. They are the topics that are discussed the most. The color
    intensity and the score represents the relevance of the word.
    <br />
    The highlights are some of the sentences or phrases in the feedbacks that
    represent the keywords and topics.
  </p>
</ng-template>

<ng-template #QATooltip>
  <p style="max-width: 500px; word-break: break-word; margin: 0">
    Q&A are the main questions asked by users and the answers you could provide
    based on the feedbacks. They are the most common questions or concerns
    raised by users.
  </p>
</ng-template>

<ng-template #nextStepsTooltip>
  <p style="max-width: 500px; word-break: break-word; margin: 0">
    Next Steps are the suggested actions or recommendations based on the
    feedbacks. They are the things you could do to improve your product or
    service.
  </p>
</ng-template>

<ng-template #tooltip let-content="content" let-placement="placement">
  <span
    nz-popover
    [nzPopoverContent]="content"
    [nzPopoverPlacement]="placement"
  >
    <i nz-icon nzType="info-circle" nzTheme="outline"></i>
  </span>
</ng-template>
