import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { computeVariation } from "components/surveys/pages/stats/indicators/indicator.utils";
import { subDays, subMinutes, subMonths, subWeeks } from "date-fns";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryUsers } from "models/analytics.filters.type";
import { Org } from "models/org.model";
import { UUID } from "models/survey.dao.types";
import { UIService } from "services/ui.service";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { RouterLink } from "@angular/router";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NgClass } from "@angular/common";
import { SingleIndicatorStatsSurveyComponent } from "../../../surveys/pages/stats/indicators/components/single-indicator/single-indicator.component";

@Component({
  selector: "widget-activity",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.scss"],
  imports: [
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    RouterLink,
    NzIconDirective,
    NgClass,
    SingleIndicatorStatsSurveyComponent,
  ],
})
export class WidgetActivityComponent implements OnInit, OnChanges {
  @Input() org: Org;
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() orgHasRespondents: boolean;

  public isLoading: boolean = true;
  public currentActiveRespondents = 0;
  public dailyActiveRespondents = 0;
  public dailyActiveRespondentsDiff = 0;
  public weeklyActiveRespondents = 0;
  public weeklyActiveRespondentsDiff = 0;
  public newRespondents = 0;
  public newRespondentsDiff = 0;

  constructor(
    public uiService: UIService,
    private analyticsDao: AnalyticsDao,
  ) {}

  async ngOnInit() {
    this.reload();
  }

  async ngOnChanges() {
    this.reload();
  }

  async reload() {
    if (!this.orgHasRespondents) {
      this.isLoading = false;
      return;
    }

    try {
      await Promise.all([
        this.getCurrentActiveRespondents(),
        this.getNewRespondents(),
        this.getDailyActiveRespondents(),
        this.getWeeklyActiveRespondents(),
      ]);

      this.isLoading = false;
    } catch (error) {
      console.log("error", error);
    } finally {
      this.isLoading = false;
    }
  }

  private async getCurrentActiveRespondents() {
    const response = await this.analyticsDao.search(
      this.getRespondentsCountQuery(
        subMinutes(this.endDate, 5),
        "last_activity_at",
        this.endDate,
      ),
    );

    this.currentActiveRespondents = response.hits.total;
  }

  private async getNewRespondents() {
    const response = await this.analyticsDao.search(
      this.getRespondentsCountQuery(this.startDate, "created_at", this.endDate),
    );
    const responseDiff = await this.analyticsDao.search(
      this.getRespondentsCountQuery(
        subMonths(this.startDate, 1),
        "created_at",
        this.startDate,
      ),
    );

    this.newRespondents = response.hits.total;
    this.newRespondentsDiff = Math.round(
      computeVariation(responseDiff.hits.total, response.hits.total),
    );
  }

  private async getDailyActiveRespondents() {
    const response = await this.analyticsDao.search(
      this.getRespondentsCountQuery(
        this.startDate,
        "last_activity_at",
        this.endDate,
      ),
    );
    const responseDiff = await this.analyticsDao.search(
      this.getRespondentsCountQuery(
        subDays(this.startDate, 1),
        "last_activity_at",
        this.startDate,
      ),
    );

    this.dailyActiveRespondents = response.hits.total;
    this.dailyActiveRespondentsDiff = Math.round(
      computeVariation(responseDiff.hits.total, response.hits.total),
    );
  }

  private async getWeeklyActiveRespondents() {
    const response = await this.analyticsDao.search(
      this.getRespondentsCountQuery(
        this.startDate,
        "last_activity_at",
        this.endDate,
      ),
    );
    const responseDiff = await this.analyticsDao.search(
      this.getRespondentsCountQuery(
        subWeeks(this.startDate, 1),
        "last_activity_at",
        this.startDate,
      ),
    );

    this.weeklyActiveRespondents = response.hits.total;
    this.weeklyActiveRespondentsDiff = Math.round(
      computeVariation(responseDiff.hits.total, response.hits.total),
    );
  }

  private getRespondentsCountQuery(
    start: Date,
    field: "last_activity_at" | "created_at",
    end: Date,
  ): AnalyticsQueryUsers {
    return {
      type: "respondent",
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      identified_only: false,
      filters_bool: "AND",
      filters: [],
      range: {
        start,
        end,
        field,
      },
      size: 0,
    };
  }
}
