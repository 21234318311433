import { Component, Input, TemplateRef } from "@angular/core";

import {
  NzTableSortOrder,
  NzTableComponent,
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzThAddOnComponent,
  NzTbodyComponent,
  NzCellAlignDirective,
} from "ng-zorro-antd/table";

import { GraphNode } from "components/builder/flow";
import { CTAType } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { NzEmptyComponent } from "ng-zorro-antd/empty";
import {
  NgFor,
  NgClass,
  NgIf,
  NgTemplateOutlet,
  DecimalPipe,
} from "@angular/common";
import { CardTypeComponent } from "../../../../../builder/components/Cards/CardType/CardType.component";
import { SanitizedMessageComponent } from "../../../../../builder/components/Cards/sanitized-message/sanitized-message.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";

export type QuestionDetails = {
  _node: GraphNode;
  survey: Survey;
  node: {
    id: string;
    correlationId: string;
    letter: string;
    type: CTAType;
    text: string;
  };
  index: number;
};

@Component({
  selector: "survey-stats-individual-responses-questions-table",
  templateUrl: "./questions-table.component.html",
  styleUrls: ["./questions-table.component.scss"],
  imports: [
    NzEmptyComponent,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzThAddOnComponent,
    NzTbodyComponent,
    NgFor,
    NgClass,
    NzCellAlignDirective,
    CardTypeComponent,
    SanitizedMessageComponent,
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NgTemplateOutlet,
    DecimalPipe,
  ],
})
export class QuestionsTableComponent {
  @Input()
  nodes: QuestionDetails[] = [];
  @Input()
  responsesCount: { [key: string]: number } = {};
  @Input()
  displaySurveyTitle: boolean = false;
  @Input()
  questionBodyView: TemplateRef<any>;
  @Input()
  isDisplay: boolean = false;

  @Input()
  expandedNodes: { [key: string]: boolean } = {};

  public questionOrder: NzTableSortOrder = "ascend";

  constructor() {}

  public onSortChange(colName: string, order: NzTableSortOrder) {
    const multiply = order === "ascend" ? 1 : -1;
    this.nodes.sort((a: QuestionDetails, b: QuestionDetails): number => {
      return (a.index - b.index) * multiply;
    });
  }

  public getCardType(node: GraphNode) {
    if (node.isCES) {
      return "[CES] ";
    }
    if (node.isCSAT) {
      return "[CSAT] ";
    }
    if (node.isNPS) {
      return "[NPS] ";
    }

    return "";
  }
}
