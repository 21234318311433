import { Component, Input } from "@angular/core";
import { NgIf, NgFor } from "@angular/common";

@Component({
  selector: "utils-form-error",
  templateUrl: "./form-error.component.html",
  styleUrls: ["./form-error.component.scss"],
  imports: [NgIf, NgFor],
})
export class FormErrorComponent {
  @Input() public errors: string[] = null;

  constructor() {}
}
