import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TranslationLanguage } from "models/survey.dao.types";
import { LanguageWithEmoji } from "services/ui.service";
import { SwitchComponent } from "utils/switch/switch.component";
import { LanguageSelect } from "../../../utils/language-select/language-select.component";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";

@Component({
  selector: "language-translation-option",
  templateUrl: "./language-translation-option.component.html",
  styleUrls: ["./language-translation-option.component.scss"],
  imports: [
    ScreebIconComponent,
    SwitchComponent,
    FormsModule,
    NgIf,
    LanguageSelect,
  ],
})
export class SettingsLanguageTranslationOptionComponent implements OnInit {
  @Input()
  public availableTranslationLanguages: LanguageWithEmoji[] = [];
  @Input()
  public translationLanguage: TranslationLanguage = null;
  @Input()
  public translationEnabled = false;

  @Output()
  public translationLanguageChange = new EventEmitter<TranslationLanguage>();
  @Output()
  public translationEnabledChange = new EventEmitter<boolean>();
  @Output()
  public translationValidChange = new EventEmitter<boolean>();

  public selectedLanguage: TranslationLanguage = null;

  constructor() {}

  ngOnInit() {
    this.selectedLanguage = this.translationLanguage;
    this.translationValidChange.emit(!this.isInvalid());
  }

  selectedLanguageChange($event: TranslationLanguage) {
    this.selectedLanguage = $event;
    this.translationLanguageChange.emit($event);
    this.translationValidChange.emit(!this.isInvalid());
  }

  translationEnableChange($event: boolean) {
    this.translationEnabled = $event;
    this.translationEnabledChange.emit($event);

    if (this.translationEnabled) {
      this.selectedLanguage = this.translationLanguage;
      this.translationLanguageChange.emit(this.translationLanguage);
    } else {
      this.selectedLanguage = null;
      this.translationLanguageChange.emit(null);
    }

    this.translationValidChange.emit(!this.isInvalid());
  }

  isInvalid() {
    return this.translationEnabled && !this.selectedLanguage;
  }
}
