import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";

import { HttpErrorResponse } from "@angular/common/http";
import { NotificationHelper } from "helpers/notification.helper";
import { Account } from "models/account.model";
import { Org } from "models/org.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";
import {
  NzSelectOptionInterface,
  NzSelectComponent,
  NzOptionComponent,
} from "ng-zorro-antd/select";
import { SessionService } from "services/auth.service";
import { NzModalComponent } from "ng-zorro-antd/modal";
import { NgIf, NgFor } from "@angular/common";
import { BadgeComponent } from "../../../../utils/badge/badge.component";
import {
  NzFormDirective,
  NzFormItemComponent,
  NzFormLabelComponent,
  NzFormControlComponent,
} from "ng-zorro-antd/form";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { NzInputDirective } from "ng-zorro-antd/input";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";

@Component({
  selector: "add-user-modal",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
  imports: [
    NzModalComponent,
    NgIf,
    BadgeComponent,
    FormsModule,
    NzFormDirective,
    ReactiveFormsModule,
    NzRowDirective,
    NzFormItemComponent,
    NzColDirective,
    NzFormLabelComponent,
    NzFormControlComponent,
    NzInputDirective,
    NzSelectComponent,
    NgFor,
    NzOptionComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    ScreebIconComponent,
  ],
})
export class AddUserModalComponent implements OnInit, OnChanges, OnDestroy {
  private obs: any = null;

  public loading: boolean = false;
  public errors: any = null;

  public validateForm: FormGroup = null;

  @Input() superOrg: SuperOrg = null;
  @Input() workspaces: Org[] = [];
  @Input() user: Account | null = null;
  @Input() isOpen: boolean = false;
  @Input() tags: string[] = [];
  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter();

  public orgsRoles: NzSelectOptionInterface[] = [
    { label: "Admin", value: "admin" },
    { label: "Member", value: "member" },
  ];

  // Admin is ommited here, as we don't want to make someone admin of one workspace but not of the org
  public workspacesRoles: NzSelectOptionInterface[] = [
    { label: "Editor", value: "editor" },
    { label: "Analyst", value: "analyst" },
  ];

  public dynamicWorkspacesRoles: NzSelectOptionInterface[] =
    this.workspacesRoles;

  // As new entry will filter previous ones, we need to keep track of the original list
  public usedWorkspaces: string[] = [];

  constructor(
    public sessionService: SessionService,
    private superOrgDao: SuperOrgDao,
    private notificationHelper: NotificationHelper,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges() {
    this.initForm();
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
  }

  private initForm(): void {
    const grouppedWorkspacesByRole = this.user?.workspaces_accesses?.reduce(
      (acc, curr) => {
        if (!acc[curr.role]) acc[curr.role] = [];
        acc[curr.role].push(curr);
        return acc;
      },
      {},
    );
    this.validateForm = this.formBuilder.group({
      email: [this.user?.email, [Validators.required, Validators.email]],
      role: [this.user?.role, [Validators.required]],
      tags: [this.user?.tags, []],
      workspaces: this.user?.workspaces_accesses?.length
        ? this.formBuilder.array(
            Object.values(grouppedWorkspacesByRole).map((v: any) =>
              this.formBuilder.group({
                workspace: [
                  v.map((e) => e.workspace_id),
                  [Validators.required],
                ],
                role: [v[0].role, [Validators.required]],
              }),
            ),
          )
        : this.formBuilder.array([
            this.formBuilder.group({
              workspace: [null, [Validators.required]],
              role: ["admin", [Validators.required]],
            }),
          ]),
    });

    this.usedWorkspaces = this.workspaces
      .filter((v) =>
        this.user?.workspaces_accesses?.some((e) => e.workspace_id === v.id),
      )
      .map((v) => v.id);

    if (this.validateForm.get("role").value === "admin") {
      this.dynamicWorkspacesRoles = this.orgsRoles.filter(
        (v) => v.value === "admin",
      );
    } else {
      this.dynamicWorkspacesRoles = this.workspacesRoles;
    }
  }

  // Force workspace role to be the same as the org role if admin
  public onOrgRoleChange(role: string): void {
    if (role === "admin") {
      this.dynamicWorkspacesRoles = this.orgsRoles.filter(
        (v) => v.value === "admin",
      );
    } else {
      this.dynamicWorkspacesRoles = this.workspacesRoles;
    }

    this.userWorkspacesFormArray.controls.forEach((control) => {
      const currentValue = control.get("role").value;
      if (role === "admin") {
        if (currentValue !== "admin") {
          control.get("role").setValue(role);
        }
      } else if (currentValue === "admin") {
        control.get("role").setValue("editor");
      }
    });
  }

  public get userWorkspacesFormArray(): FormArray {
    return this.validateForm.get("workspaces") as FormArray;
  }

  public onWorkspaceChange(_: string): void {
    this.usedWorkspaces = this.workspaces
      .filter((v) =>
        this.userWorkspacesFormArray.value.some(
          (e) => (e.workspace?.indexOf(v.id) ?? -1) !== -1,
        ),
      )
      .map((v) => v.id);
  }

  private createNewUserWorkspaces(required: boolean = false): FormGroup {
    return this.formBuilder.group({
      workspace: [null, required ? [Validators.required] : []],
      role: ["admin", required ? [Validators.required] : []],
    });
  }

  public addNewWorkspace(): void {
    this.validateForm.controls["workspaces"] = this.formBuilder.array([
      ...this.validateForm.controls["workspaces"].value.map((v) =>
        this.formBuilder.group({
          workspace: [v.workspace, [Validators.required]],
          role: [v.role, [Validators.required]],
        }),
      ),
      this.createNewUserWorkspaces(),
    ]);
  }

  public removeWorkspace(index: number): void {
    this.validateForm.controls["workspaces"] = this.formBuilder.array([
      ...this.validateForm.controls["workspaces"].value
        .filter((_, i) => i !== index)
        .map((v) =>
          this.formBuilder.group({
            workspace: [v.workspace, [Validators.required]],
            role: [v.role, [Validators.required]],
          }),
        ),
    ]);
  }

  handleCancel(): void {
    this.isOpen = false;
    this.modalOpenChange.emit(false);
    this.loading = false;
  }

  public isFormValid(): boolean {
    return this.validateForm.valid;
  }

  public onSubmit() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    if (!this.validateForm.valid) return;

    this.loading = true;
    this.errors = null;

    const value = this.validateForm.value;

    // Filter empty workspaces
    value.workspaces = value.workspaces.filter(
      (v) => v.workspace !== null && v.workspace !== undefined,
    );

    if (this.user !== null) {
      this.superOrgDao
        .editAccount(
          this.superOrg.id,
          this.user.id,
          value.role,
          value.tags,
          value.workspaces,
        )
        .then((_) => {
          this.notificationHelper.trigger("User updated", null, "success");
          this.errors = null;
          this.isOpen = false;
          this.modalOpenChange.emit(true);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationHelper.trigger(
            err?.error?.message ?? "Failed to update user",
            null,
            "error",
          );
          this.errors = err.error;
          console.error(err.error);
        })
        .then(() => {
          this.loading = false;
        });
    } else {
      this.superOrgDao
        .invite(
          this.superOrg.id,
          value.email,
          value.role,
          value.tags,
          value.workspaces,
        )
        .then((_) => {
          this.notificationHelper.trigger("User Invited", null, "success");
          this.errors = null;
          this.isOpen = false;
          this.modalOpenChange.emit(true);
        })
        .catch((err: HttpErrorResponse) => {
          const reasons = Object.values(err.error?.fields ?? {});

          const message = reasons.length > 0 ? reasons[0] : err.error?.message;
          this.notificationHelper.trigger(
            message ?? "Failed to invite user",
            null,
            "error",
          );
          this.errors = err.error;
          console.error(err.error);
        })
        .then(() => {
          this.loading = false;
        });
    }
  }
}
