import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";

import { TagSettings } from "models/tag-settings.types";
import { isDarkColor } from "utils/color";
import { NgClass, NgIf } from "@angular/common";
import { SanitizedMessageComponent } from "../../../../../../builder/components/Cards/sanitized-message/sanitized-message.component";

@Component({
  selector: "feedback-button-preview",
  templateUrl: "./feedback-button-preview.component.html",
  styleUrls: ["./feedback-button-preview.component.scss"],
  imports: [NgClass, NgIf, SanitizedMessageComponent],
})
export class FeedbackButtonPreviewComponent implements OnInit, OnChanges {
  @Input() public tagSettings: TagSettings = null;
  @Input() public forceMobile = false;
  @Input() public scope: "org" | "survey" | "distribution" = "org";

  @Input() public mode: "mobile" | "desktop" = "desktop";

  @Input() public feedbackButtonPreview: "closed" | "opened";
  @Output() public feedbackButtonPreviewChange = new EventEmitter<
    "closed" | "opened"
  >();

  constructor() {}

  ngOnInit() {
    this.setupForceMobile();
  }

  ngOnChanges() {
    this.setupForceMobile();
  }

  private setupForceMobile() {
    if (this.forceMobile) {
      this.mode = "mobile";
    }
  }

  public onImgError(event) {
    isDarkColor(this.tagSettings.feedback_button_cta_color_primary)
      ? (event.target.src = "/assets/screeby-white-fallback.png")
      : (event.target.src = "/assets/screeby-fallback.png");
  }

  isDarkColor = isDarkColor;

  getFeedbackButtonCssVars() {
    return {
      "--feedback_button-border-line-color":
        this.tagSettings.feedback_button_border_line_color,
      "--feedback_button-border-line-width":
        this.tagSettings.feedback_button_border_line_width + "px",
      "--feedback_button-border-line-style": this.tagSettings
        .feedback_button_border_line
        ? "solid"
        : "none",
      "--feedback_button-border-line":
        this.tagSettings.feedback_button_border_line,
      "--feedback_button-border-radius":
        this.tagSettings.feedback_button_border_radius + "px",
      "--feedback_button-border-shadow-color": this.tagSettings
        .feedback_button_border_shadow
        ? this.tagSettings.feedback_button_border_shadow_color
        : "transparent",
      "--feedback_button-border-shadow":
        this.tagSettings.feedback_button_border_shadow,
      "--feedback_button-color-primary-text":
        this.tagSettings.feedback_button_color_primary_text,
      "--feedback_button-color-primary":
        this.tagSettings.feedback_button_color_primary,
      "--feedback_button-color-secondary-text":
        this.tagSettings.feedback_button_color_secondary_text,
      "--feedback_button-color-secondary":
        this.tagSettings.feedback_button_color_secondary,

      "--feedback_button-font-family":
        this.tagSettings.feedback_button_font_family,
      "--feedback_button-font-line-height":
        this.tagSettings.feedback_button_font_line_height + "px",
      "--feedback_button-font-size":
        this.tagSettings.feedback_button_font_size + "px",
      "--feedback_button-identity-fixed-avatar": `url('${this.tagSettings["feedback_button_identity_fixed_avatar"]}')`,
      "--feedback_button-identity-fixed-name":
        this.tagSettings["feedback_button_identity_fixed_name"],
      "--feedback_button-identity-fixed-title":
        this.tagSettings["feedback_button_identity_fixed_title"],
      "--feedback_button-identity-type":
        this.tagSettings.feedback_button_identity_type,
      "--feedback_button-overlay-color":
        this.tagSettings.feedback_button_overlay_color,
      "--feedback_button-overlay-visibility":
        this.tagSettings.feedback_button_overlay_visibility,
      "--feedback_button-position": this.tagSettings.feedback_button_position,
      "--feedback_button-size":
        this.tagSettings.feedback_button_size - 15 + "%",

      // Feedback button CTA
      "--feedback_button_cta-label": this.tagSettings.feedback_button_cta_label,

      "--feedback_button_cta-border-line-style": this.tagSettings
        .feedback_button_cta_border_line
        ? "solid"
        : "none",
      // "--feedback_button_cta-border-line":
      //   this.tagSettings.feedback_button_cta_border_line,
      "--feedback_button_cta-border-line-width":
        this.tagSettings.feedback_button_cta_border_line_width + "px",
      "--feedback_button_cta-border-line-color":
        this.tagSettings.feedback_button_cta_border_line_color,
      "--feedback_button_cta-border-radius":
        this.tagSettings.feedback_button_cta_border_radius + "px",
      "--feedback_button_cta-border-shadow":
        this.tagSettings.feedback_button_cta_border_shadow,
      "--feedback_button_cta-border-shadow-color":
        this.tagSettings.feedback_button_cta_border_shadow_color,

      "--feedback_button_cta-color-primary":
        this.tagSettings.feedback_button_cta_color_primary,
      "--feedback_button_cta-color-primary-text":
        this.tagSettings.feedback_button_cta_color_primary_text,

      "--feedback_button_cta-font-size":
        this.tagSettings.feedback_button_cta_font_size + "px",
      "--feedback_button_cta-font-line-height":
        this.tagSettings.feedback_button_cta_font_line_height + "px",
      "--feedback_button_cta-font-family":
        this.tagSettings.feedback_button_cta_font_family,
    };
  }
}
