import { Component, Input } from "@angular/core";
import {
  RegistryEntrySource,
  RegistryEntrySourceFormatted,
  sortedRegistryEntrySources,
} from "models/registry.model";
import { NgFor } from "@angular/common";
import { NzTagComponent } from "ng-zorro-antd/tag";

@Component({
  selector: "tag-registry-entry-source",
  templateUrl: "./tag-registry-entry-source.component.html",
  styleUrls: ["./tag-registry-entry-source.component.scss"],
  imports: [NgFor, NzTagComponent],
})
export class TagRegistryEntrySourceComponent {
  @Input() public size: "small" | "medium" | "large";
  @Input() public sources: RegistryEntrySource[];

  public sortedRegistryEntrySources = sortedRegistryEntrySources;
  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;

  constructor() {}
}
