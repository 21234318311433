import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { NgIf } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { PageComponentInterface } from "components/PageComponentInterface";
import { NotificationHelper } from "helpers/notification.helper";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { AuthService } from "services/auth.service";
import { RoutingService } from "services/routing.service";
import { IntercomButtonComponent } from "../../../utils/intercom-button/intercom-button.component";

type AuthError = {
  code: string;
  message: string;
  data: any;
};

@Component({
  selector: "page-auth0-authorize",
  template: `
    <img
      *ngIf="loading"
      id="screeb-global-loader"
      src="/assets/loader-screeb.gif"
      title="Loader"
      alt="Loader"
    />
    <div *ngIf="!loading" class="error-container">
      <img
        id="screeb-logo"
        src="/assets/screeb-logo.png"
        title="Loader"
        alt="Loader"
      />
      <img
        id="screeby"
        src="/assets/screeby.png"
        title="Screeby"
        alt="Screeby"
      />
      <h1>{{ titleMessage }}</h1>
      <h2 *ngIf="infoMessage" class="alert alert-info">
        {{ infoMessage }}
      </h2>
      <p *ngIf="errorMessage" class="alert alert-danger">
        {{ errorMessage }}
      </p>
      <button
        *ngIf="showResendEmailButton"
        class="resend-email-button"
        nz-button
        nzType="text"
        (click)="sendVerificationEmail()"
      >
        Resend Account Activation Email
      </button>

      <intercom-button *ngIf="showSupportButton"></intercom-button>
      <button nz-button nzType="primary" (click)="logout()">Login</button>
    </div>
  `,
  styles: [
    `
      .error-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
      }

      .error-container img {
        width: 300px;
        margin-bottom: 1rem;
      }

      .error-container h1 {
        font-size: 2rem;
        margin-bottom: 1rem;
      }

      .error-container .alert-danger {
        margin-bottom: 1rem;
        color: var(--screeb-color-error);
      }

      .error-container .alert-info {
        margin-bottom: 1rem;
        color: var(--screeb-color-primary);
      }

      .error-container button {
        margin-top: 1rem;
        height: 40px;
      }

      .resend-email-button {
        color: var(--screeb-color-primary);
        text-decoration: underline;
      }
    `,
  ],
  imports: [
    NgIf,
    NzButtonComponent,
    ɵNzTransitionPatchDirective,
    IntercomButtonComponent,
    NzWaveDirective,
  ],
})
export class Auth0AuthorizePageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Login to Screeb 🚀";
  public name = "Login";

  private sub: any = null;

  public loading: boolean = true;
  public errorMessage: string = null;
  public infoMessage: string = null;
  public titleMessage: string = "Login to Screeb";

  public showSupportButton = false;
  public showResendEmailButton = false;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private authService: AuthService,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit() {
    this.authService.authStatus = "authorizing";
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.authService
      .handleRedirectCallback()
      .catch((err: HttpErrorResponse) => {
        this.loading = false;
        // this.authService.logout();

        const isRealError = this.parseAuthError(err);
        if (isRealError) {
          this.notificationHelper.trigger("Error", err.message, "error", 3600); // 1 hour
        }
      });
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  private parseAuthError(err: HttpErrorResponse) {
    if (typeof err.error === "string") {
      return this.parseInternalAuthError(err.error, err.message);
    }
    const authErr = err.error as AuthError;
    const msg = authErr?.message || err.message;
    const data = authErr?.data || {};

    if (!authErr) {
      this.errorMessage = msg;
      return true;
    }

    switch (authErr.code) {
      case "invalid_account":
        this.errorMessage = "Invalid account. Please contact support.";
        this.showSupportButton = true;
        break;
      case "account_blocked":
        this.errorMessage = "Account is blocked. Please contact support.";
        this.showSupportButton = true;
        break;
      case "failed_to_verify_email":
        this.errorMessage =
          "Your Account is not verified. please check your inbox.";
        this.showResendEmailButton = true;

        return false;
      case "failed_to_verify_email_first_time":
        this.errorMessage = null;
        this.infoMessage = `We have sent you an email at ${data.email} to verify your account. Please check your inbox.`;
        this.showResendEmailButton = true;

        return false;
      case "invalid_invitation":
        this.errorMessage =
          "Your invitation is invalid or has expired. Ask your organization to resend the invitation.";
        break;
      default:
        this.errorMessage = msg;

        break;
    }

    return true;
  }

  private parseInternalAuthError(errorCode: string, errMsg: string): boolean {
    console.log("Internal error", errorCode, errMsg);

    switch (errorCode) {
      case "missing_transaction":
        this.errorMessage = "Incomplete login process. Please try again.";
        break;
      case "access_denied":
        this.titleMessage = "Login with a Business Email";
        this.errorMessage = errMsg;

        return false;
      default:
        this.errorMessage = errMsg;
        break;
    }

    return true;
  }

  public sendVerificationEmail() {
    this.authService
      .resendEmailVerification()
      .then(() => {
        this.notificationHelper.trigger(
          "Email sent",
          "Verification email sent successfully",
          "success",
          3600,
        ); // 1

        this.showResendEmailButton = false;
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.trigger("Error", err.message, "error", 3600); // 1 hour
      });
  }

  logout() {
    this.authService.logout();
  }
}
