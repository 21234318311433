import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { ETag } from "services/media-upload.service";
import { PreSignedUrlResult, UploadResult, UploadType } from "./upload.types";
import { ConfigService } from "services/config.service";

@Injectable()
class UploadDao {
  constructor(
    private screebApiHelper: ScreebApiHelper,
    private configService: ConfigService,
  ) {}

  public async uploadOrg(
    orgId: string,
    type: UploadType,
    file: File,
  ): Promise<UploadResult> {
    return this.screebApiHelper
      .post<UploadResult>(
        `/org/${orgId}/upload/${type}`,
        await adaptFileToFileToUpload(file),
      )
      .toPromise();
  }

  public async uploadAccount(
    type: UploadType,
    file: File,
  ): Promise<UploadResult> {
    return this.screebApiHelper
      .post<UploadResult>(
        `/account/upload/${type}`,
        await adaptFileToFileToUpload(file),
      )
      .toPromise();
  }

  public async uploadSurvey(
    orgId: string,
    surveyId: string,
    file: File,
  ): Promise<UploadResult> {
    return this.screebApiHelper
      .post<UploadResult>(
        `/org/${orgId}/survey/${surveyId}/scenario/upload/image`,
        await adaptFileToFileToUpload(file),
      )
      .toPromise();
  }

  public async getSurveyFilePreSignedUrl<T extends UploadType>(
    orgId: string,
    surveyId: string,
    contentType: string,
    size: number,
    filename: string,
    type: T,
  ): Promise<PreSignedUrlResult> {
    const body = {
      content_type: contentType,
      type: type,
      size: size,
      file_name: filename,
    };
    return this.screebApiHelper
      .post<PreSignedUrlResult>(
        `/org/${orgId}/survey/${surveyId}/scenario/upload/presign`,
        body,
      )
      .toPromise();
  }

  public async confirmSurveyFileMultipartUpload(
    orgId: string,
    surveyId: string,
    file_name: string,
    upload_id: string,
    etags: ETag[],
  ): Promise<UploadResult> {
    const body = { upload_id, file_name, etags };
    return this.screebApiHelper
      .post<UploadResult>(
        `/org/${orgId}/survey/${surveyId}/scenario/upload/confirm`,
        body,
      )
      .toPromise();
  }

  public async abortSurveyFileMultipartUpload(
    orgId: string,
    surveyId: string,
    file_name: string,
    upload_id: string,
  ): Promise<void> {
    const body = { upload_id, file_name };
    return this.screebApiHelper
      .patch<void>(
        `/org/${orgId}/survey/${surveyId}/scenario/upload/cancel`,
        body,
      )
      .toPromise();
  }
}

const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(String(reader.result));
    reader.onerror = (error) => reject(error);
  });

const adaptFileToFileToUpload = async (file: File) => {
  return {
    filename: file.name.replace(/ /g, "-"),
    mime: file.type,
    file: await fileToBase64(file),
  };
};

const resizeImageWithRatio = (
  file: File,
  minWidth: number,
  minHeight: number,
): Promise<File> => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = () => {
      const ratio = Math.max(minWidth / img.width, minHeight / img.height);

      // Initialize the canvas and it's size
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = img.width * ratio;
      canvas.height = img.height * ratio;

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // convert back to File
      canvas.toBlob((blob) => {
        resolve(new File([blob], file.name, { type: "image/png" }));
      }, "image/png");
    };

    img.src = URL.createObjectURL(file);
  });
};

export {
  UploadDao,
  adaptFileToFileToUpload,
  fileToBase64,
  resizeImageWithRatio,
};
