import { Component, Input } from "@angular/core";
import { formatNumber, NumberFormatType } from "utils/number";
import { NgIf } from "@angular/common";

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angle: number,
) => ({
  x: centerX + radius * Math.cos(angle),
  y: centerY + radius * Math.sin(angle),
});

const describeArc = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= Math.PI ? "0" : "1";

  return [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");
};

@Component({
  selector: "survey-stats-indicators-vu-meter-indicator",
  templateUrl: "./vu-meter-indicator.component.html",
  styleUrls: ["./vu-meter-indicator.component.scss"],
  imports: [NgIf],
})
export class VuMeterIndicatorStatsSurveyComponent {
  @Input()
  public mediumRangeStart: number;
  @Input()
  public highRangeStart: number;
  @Input()
  public value: number;
  @Input()
  public min: number;
  @Input()
  public max: number;
  @Input()
  public ideal?: number;
  @Input()
  public format: NumberFormatType = "number";
  @Input()
  public diffFormat: NumberFormatType = "points";
  @Input()
  public maximumFractionDigits = 0;
  @Input()
  public inverted = false;

  private pieGutter = 0.02;
  public pieLineWidth = 14;
  public pieWidth = 374;
  public pieHeight = 187;

  private idealValueDiffColors = ["#F11672", "#727387", "#086554"];
  private idealValueDiffColorsInverted = [
    ...this.idealValueDiffColors,
  ].reverse();

  private rangeColors = ["#F11672", "#FFC046", "#1ED5A4"];
  private rangeColorsInverted = [...this.rangeColors].reverse();

  constructor() {}

  public getFormattedValue() {
    return formatNumber(this.value, this.format, this.maximumFractionDigits);
  }

  private toPolar(value: number) {
    return ((value - this.min) * Math.PI) / (this.max - this.min) - Math.PI;
  }

  private getPiePart(
    startAngle: number,
    endAngle: number,
    radius = this.pieHeight,
  ) {
    return [
      describeArc(
        this.pieWidth / 2,
        this.pieHeight,
        radius,
        startAngle,
        endAngle,
      ),
      `L ${this.pieWidth / 2} ${this.pieHeight}`,
    ].join(" ");
  }

  private computeVariation(
    previousValue: number,
    currentValue: number,
  ): number {
    if (!previousValue && !currentValue) {
      return 0;
    }

    if (this.inverted) {
      return ((previousValue - currentValue) / Math.abs(currentValue)) * 100;
    }

    return ((currentValue - previousValue) / Math.abs(previousValue)) * 100;
  }

  private getIdealValueDiff() {
    const value =
      this.diffFormat === "percent"
        ? this.computeVariation(this.value, this.ideal)
        : this.value - this.ideal;

    if (this.maximumFractionDigits === 0) {
      return Math.round(value);
    }
    return value;
  }

  public getIdealValueDiffText() {
    return formatNumber(
      this.getIdealValueDiff(),
      this.diffFormat,
      this.maximumFractionDigits,
      "force",
    );
  }

  public getRangeColor(index: number) {
    return (this.inverted ? this.rangeColorsInverted : this.rangeColors)[index];
  }

  public getIdealValueDiffColor() {
    const [lessThanZero, equalZero, moreThanZero] = this.inverted
      ? this.idealValueDiffColorsInverted
      : this.idealValueDiffColors;

    if (this.getIdealValueDiff() < 0) {
      return lessThanZero;
    } else if (this.getIdealValueDiff() > 0) {
      return moreThanZero;
    }
    return equalZero;
  }

  public getLowRangePiePart() {
    return this.getPiePart(
      -Math.PI,
      this.toPolar(this.mediumRangeStart) - this.pieGutter,
    );
  }

  public getMediumRangePiePart() {
    return this.getPiePart(
      this.toPolar(this.mediumRangeStart) + this.pieGutter,
      this.toPolar(this.highRangeStart) - this.pieGutter,
    );
  }

  public getHighRangePiePart() {
    return this.getPiePart(
      this.toPolar(this.highRangeStart) + this.pieGutter,
      0,
    );
  }

  public getValuePath() {
    return describeArc(
      this.pieWidth / 2,
      this.pieHeight,
      this.pieHeight - this.pieLineWidth + 2,
      -Math.PI,
      this.toPolar(this.value),
    );
  }

  public getPointX() {
    return (
      this.pieWidth / 2 +
      Math.cos(this.toPolar(this.value)) *
        (this.pieHeight - this.pieLineWidth - 1)
    );
  }

  public getPointY() {
    return (
      this.pieHeight +
      Math.sin(this.toPolar(this.value)) *
        (this.pieHeight - this.pieLineWidth - 1)
    );
  }

  public getOpacity(rangeIndex: number) {
    const defaultOpacity = 0.3;

    switch (rangeIndex) {
      case 0:
        return this.value >= this.min && this.value < this.mediumRangeStart
          ? 1
          : defaultOpacity;
      case 1:
        return this.value >= this.mediumRangeStart &&
          this.value < this.highRangeStart
          ? 1
          : defaultOpacity;
      case 2:
        return this.value >= this.highRangeStart && this.value <= this.max
          ? 1
          : defaultOpacity;
      default:
        return 0;
    }
  }
}
