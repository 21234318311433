<label class="lateral-panel-subtitle">Slider emoji</label>

<div class="panel-button-line">
  <button
    #btnElement
    title="Open emoji picker"
    class="emoji-button"
    [disabled]="node.isPreset"
    (click)="openEmojiPicker(btnElement)"
  >
    <sanitized-message-component
      [content]="cta.range.payload.emoji"
      [options]="{
        styling: true,
        emojiTranscoder: true,
      }"
    ></sanitized-message-component>
  </button>
</div>
<lateral-panel-emoji-picker-popin
  [topPosition]="emojiPickerPositionTop"
  [rightPosition]="emojiPickerPositionRight"
  [emoji]="cta.range.payload.emoji"
  (onClickOutside)="emojiPickerVisible = false"
  (onEmojiSelected)="
    cta.range.payload.emoji = $event; emojiPickerVisible = false
  "
  *ngIf="emojiPickerVisible"
></lateral-panel-emoji-picker-popin>

<ng-container>
  <label class="lateral-panel-subtitle">Rating Scale</label>
  <div class="input-wrapper">
    <div>
      <p class="lateral-panel-tip">
        <span>
          For min value
          <screeb-icon
            size="sm"
            icon="info-circle"
            nz-popover
            nzType="primary"
            nzPopoverPlacement="bottomLeft"
            [nzPopoverContent]="contentTemplate"
          ></screeb-icon>
          <ng-template #contentTemplate>
            The rating scale is the text that will be displayed on the slider
            for the minimum value, leave empty if you don't want to display
            anything.
          </ng-template>
        </span>
      </p>
      <input
        type="text"
        class="panel-input"
        placeholder="Min rating scale"
        [ngModel]="getLabelLegendMin()"
        (ngModelChange)="setLabelLegendMin($event); validateData()"
      />
    </div>
    <div>
      <p class="lateral-panel-tip">
        <span>
          For max value
          <screeb-icon
            size="sm"
            icon="info-circle"
            nz-popover
            nzType="primary"
            nzPopoverPlacement="bottomLeft"
            [nzPopoverContent]="contentTemplate"
          ></screeb-icon>
          <ng-template #contentTemplate>
            The legend is the text that will be displayed on the slider for the
            maximum value, leave empty if you don't want to display anything.
          </ng-template>
        </span>
      </p>
      <input
        type="text"
        class="panel-input"
        placeholder="Max rating scale"
        [ngModel]="getLabelLegendMax()"
        (ngModelChange)="setLabelLegendMax($event); validateData()"
      />
    </div>
  </div>
</ng-container>

<label class="lateral-panel-subtitle">Next step</label>

<lateral-panel-step-picker
  [routing]="node.node.routing"
  [chainableNodeIds]="node.chainableNodeIds"
  [actions]="[cta.range]"
></lateral-panel-step-picker>

<ng-container>
  <label class="lateral-panel-subtitle skip-to-step-title">Skip to step</label>
  <p class="lateral-panel-tip">Allowing users to skip to a next step</p>

  <lateral-panel-step-picker
    [routing]="node.node.routing"
    [chainableNodeIds]="node.chainableNodeIds"
    [actions]="[skipAction]"
    [isSkipAction]="true"
  ></lateral-panel-step-picker>
</ng-container>

<ng-container *ngIf="'isSaveToVariableEnabled' | hasFeatureFlag">
  <label class="lateral-panel-subtitle">
    Assign this question to a property
  </label>
  <p class="lateral-panel-tip">
    Allows you to reuse the answer in other questions or in your integrations.
  </p>
  <lateral-panel-variable-picker
    nzPlaceHolder="Select a property"
    [(ngModel)]="variable"
    [nzOptions]="variables"
  ></lateral-panel-variable-picker>
  <small>Only string properties supported for this question</small>
</ng-container>
