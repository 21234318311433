import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";
import { NotificationHelper } from "helpers/notification.helper";

import {
  hookIntegrationsSpec,
  REGEXP_VALIDATION_WEBHOOK,
} from "models/hook.model";
import { IntegrationDao } from "models/integration.dao";
import {
  Integration,
  IntegrationSettingsWebhook,
  IntegrationSettingsWebhookItem,
  IntegrationType,
  scenarioNodeLevelIntegrationType,
} from "models/integrations.model";
import { Org } from "models/org.model";
import {
  ScenarioNode,
  ScenarioNodeIntegrations,
} from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { EntitlementService } from "services/entitlement.service";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";
import { BuilderStore } from "stores/builder.store";
import { uuidv4 } from "utils/uuid";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NgFor, NgIf, NgClass } from "@angular/common";
import {
  NzInputGroupComponent,
  NzInputGroupWhitSuffixOrPrefixDirective,
} from "ng-zorro-antd/input";
import { FormsModule } from "@angular/forms";
import {
  NzDropDownDirective,
  NzDropdownMenuComponent,
} from "ng-zorro-antd/dropdown";
import { NzMenuDirective, NzMenuItemComponent } from "ng-zorro-antd/menu";
import { FormErrorComponent } from "../../../../../utils/form-error/form-error.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { UpgradeBanner } from "../../../../super-org/billing/banners/upgrade-banner.component";
import { EntitlementPipe } from "pipes/entitlement.pipe";
import { FeaturePipe } from "pipes/feature.pipe";

type hook = {
  integrationType: IntegrationType;
  settings: IntegrationSettingsWebhookItem; // list every items
  error?: string;
};

@Component({
  selector: "lateral-panel-integrations",
  templateUrl: "./integrations.component.html",
  styleUrls: ["./integrations.component.scss", "../common.scss"],
  imports: [
    NzPopoverDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NgFor,
    NgIf,
    NzInputGroupComponent,
    NzInputGroupWhitSuffixOrPrefixDirective,
    FormsModule,
    NgClass,
    NzDropDownDirective,
    NzDropdownMenuComponent,
    NzMenuDirective,
    NzMenuItemComponent,
    FormErrorComponent,
    NzButtonComponent,
    NzWaveDirective,
    UpgradeBanner,
    EntitlementPipe,
    FeaturePipe,
  ],
})
export class LateralPanelIntegrationsComponent implements OnInit {
  @Input()
  public org: Org;
  @Input()
  public survey: Survey;
  @Input()
  public node: ScenarioNode;
  @Input()
  public integrations: ScenarioNodeIntegrations;

  @Output()
  public integrationsChange = new EventEmitter<ScenarioNodeIntegrations>();
  @Output()
  public validChange = new EventEmitter<boolean>();

  public hooks: hook[];

  constructor(
    private integrationDao: IntegrationDao,
    public featureFlaggingService: FeatureFlaggingService,
    private notificationHelper: NotificationHelper,
    private trackersService: TrackersService,
    private builderStore: BuilderStore,
    public entitlementService: EntitlementService,
  ) {}

  ngOnInit() {
    this.hooks = this.integrationsToHooks();
    this.validateHooks();
  }

  private integrationsToHooks(): hook[] {
    const hooks = [];

    if (this.integrations?.webhook?.items?.length > 0) {
      hooks.push(
        ...(this.integrations?.webhook?.items.map((item) => {
          return { integrationType: "webhook", settings: item };
        }) || []),
      );
    }

    return hooks;
  }

  private hooksToIntegration(): ScenarioNodeIntegrations {
    const webhookItems = this.hooks
      .filter((hook) => hook.integrationType === "webhook")
      .map((hook) => {
        hook.settings.path = hook.settings.path.trim();
        return hook.settings;
      });

    const zapierItems = this.hooks
      .filter((hook) => hook.integrationType === "zapier")
      .map((hook) => {
        hook.settings.path = hook.settings.path.trim();
        return hook.settings;
      });

    const integrations: ScenarioNodeIntegrations = {};
    if (webhookItems.length > 0)
      integrations.webhook = new IntegrationSettingsWebhook().fromJson({
        items: webhookItems,
      } as IntegrationSettingsWebhook);

    return integrations;
  }

  public getHooksByType(type: scenarioNodeLevelIntegrationType): hook[] {
    return this.hooks.filter((hook) => hook.integrationType === type);
  }

  public onHookAdd(type: scenarioNodeLevelIntegrationType) {
    this.trackChange(`Integration ${type} scenario node hook added`);

    if (type === "webhook") {
      this.onWebhookAdd();
    } else {
      throw Error("unsupported integration");
    }

    this.onHookChange();
  }

  public onHookRemove(index: number, type: scenarioNodeLevelIntegrationType) {
    this.trackChange(`Integration ${type} scenario node hook removed`);

    this.hooks.splice(index, 1);

    this.onHookChange();
  }

  public onHookChange() {
    this.validateHooks();
    const integrations = this.hooksToIntegration();
    this.integrationsChange.emit(integrations);
  }

  public onHookSendSample(hook: hook) {
    this.integrationDao.triggerHookSample(
      this.org.id,
      hook.integrationType,
      "response.answered",
      hook.settings.path,
      hook.settings.version,
    );
  }

  public onHookReplay(hook: hook) {
    const params = {
      webhook_endpoint: hook.settings.path,
      webhook_version: hook.settings.version,
    };

    this.integrationDao
      .triggerHookReplay(
        this.org.id,
        hook.integrationType,
        "response.answered",
        [this.survey.id],
        this.node.correlation_id,
        params,
      )
      .then(() => {
        this.notificationHelper.trigger(
          "A synchronisation has been ordered!",
          null,
          "success",
        );

        let eventName: TrackingEventName;
        if (hook.integrationType === "webhook") {
          eventName = "Integration webhook sync requested";
        } else {
          // 🤮
          throw Error("event name missing");
        }

        this.trackersService
          .newEventTrackingBuilder(eventName)
          .withOrg(this.org)
          .withSurvey(this.survey)
          .withProps(params)
          .build();
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);

        this.notificationHelper.trigger(
          err?.error?.message ?? "Failed to synchronize.",
          null,
          "error",
        );
      });
  }

  public validateHooks() {
    let nbrInvalid = 0;

    for (const hook of this.hooks) {
      if (hook.integrationType === "webhook") {
        this.isWebhookValid(hook) ? 0 : nbrInvalid++;
      } else {
        throw Error("unsupported integration");
      }
    }

    this.validChange.emit(nbrInvalid === 0);
  }

  public integrationIsEnableInOrganization(type: IntegrationType): boolean {
    return !!this.builderStore.orgIntegrations?.find(
      (integration: Integration) => integration.type === type,
    );
  }

  /**
   * Tracking
   */
  private trackChange(eventName: TrackingEventName) {
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withSurvey(this.survey)
      .build();
  }

  /**
   * Webhook
   */
  private onWebhookAdd() {
    this.hooks.push({
      integrationType: "webhook",
      settings: new IntegrationSettingsWebhookItem().fromJson({
        id: uuidv4(),
        version: hookIntegrationsSpec.webhook.currentVersion,
        enabled: true,
        name: "Question webhook",
        path: "",
        hooks: ["response.answered"],
      } as IntegrationSettingsWebhookItem),
    });
  }

  public isWebhookValid(hook: hook): boolean {
    const url = hook?.settings?.path?.trim() || "";

    hook.error = null;

    if (!url.match(REGEXP_VALIDATION_WEBHOOK)) {
      hook.error = "Malformed url";
      return false;
    }

    if (url.indexOf("https://") !== 0) {
      hook.error = "This URL is not secured.";
      return false;
    }

    return true;
  }
}
