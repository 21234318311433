import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";

import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";
import { ApplicationLoadService } from "services/app.service";
import { UIService } from "services/ui.service";

@Injectable()
export class SuperOrgResolver implements Resolve<any> {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private superOrgDao: SuperOrgDao,
    private uiService: UIService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<SuperOrg> {
    return this.applicationLoadService
      .loader()
      .then(() => {
        // Check if we already have the org in the ui service
        const tmp = this.uiService.superOrgs.find(
          (org) => org.id === route.params["super_org_id"],
        );
        if (tmp) {
          return tmp;
        }

        if (route.params["super_org_id"]) {
          return this.superOrgDao.getById(route.params["super_org_id"]);
        } else if (route.parent.data?.org?.super_org_id) {
          return this.superOrgDao.getById(route.parent.data?.org?.super_org_id);
        } else {
          throw new Error("No super org id found");
        }
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        throw err;
      });
  }
}
