import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ApplicationLoadService } from "services/app.service";
import { NgIf } from "@angular/common";
import { MaintenanceComponent } from "./maintenance.component";
import { RouterOutlet } from "@angular/router";

@Component({
  selector: "parent-layout",
  template: `
    <app-maintenance *ngIf="!appLoaded"></app-maintenance>
    <router-outlet *ngIf="appLoaded"></router-outlet>
  `,
  styleUrls: ["./parent-layout.component.scss", "./datepicker.component.scss"],
  encapsulation: ViewEncapsulation.None,
  imports: [NgIf, MaintenanceComponent, RouterOutlet],
})
class ParentLayoutComponent implements OnInit, OnDestroy {
  private obs: any = null;

  constructor(private applicationLoadService: ApplicationLoadService) {}

  public appLoaded: boolean = true;
  private forceMaintenance: boolean = false;

  ngOnInit() {
    if (
      this.forceMaintenance === true ||
      this.applicationLoadService.isMaintenance
    ) {
      this.appLoaded = false;
    } else {
      this.appLoaded = true;
    }
  }

  ngOnDestroy() {
    this.obs?.unsubscribe();
  }
}

export { ParentLayoutComponent };
