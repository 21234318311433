import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { Column } from "components/builder/page-builder.component";
import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { SurveyDao } from "models/survey.dao";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import {
  ImportQuestion,
  ImportQuestionOption,
  ImportService,
} from "services/import.service";
import { BuilderStore } from "stores/builder.store";
import loadingMessages from "./loading-messages";
import { stringToHash } from "utils/string";
import { SessionService } from "services/auth.service";
import { UIService } from "services/ui.service";
import { NgIf } from "@angular/common";
import { BuilderLayoutComponent } from "../../../builder/components/BuilderLayout/BuilderLayout.component";
import { DraggableZoneDirective } from "../../../builder/draggable-zone.directive";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";

@Component({
  selector: "page-auth-create-with-ai",
  templateUrl: "./create-with-ai.component.html",
  styleUrls: ["../common.scss", "./create-with-ai.component.scss"],
  imports: [
    NgIf,
    BuilderLayoutComponent,
    DraggableZoneDirective,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    ScreebIconComponent,
  ],
})
export class CreateWithAIPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Create Screeb with AI";
  public name = "Create with AI";

  public columnsWithCards: Column[] = [];

  public fakeOrg = new Org(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    [],
  );
  public survey = new Survey(undefined, undefined, undefined, undefined, {
    id: "fake-id",
    author_id: UUID("fake-author_id"),
    version: 0,
    default_language: "en",
    node_entrypoint_id: UUID(""),
    nodes: [],
    created_at: new Date(),
  });

  public loadingMessage = "";

  // Modal
  public isGenerating = false;
  public hasData = false;
  public scenario: any = null;

  private query = "";

  private obs: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notificationHelper: NotificationHelper,
    private surveyDao: SurveyDao,
    private builderStore: BuilderStore,
    private importService: ImportService,
    private sessionService: SessionService,
    private uiService: UIService,
  ) {}

  ngOnInit(): void {
    window.document.body.style.background =
      "var(--screeb-color-background-secondary)";

    this.changeLoadingMessage();

    this.obs = this.route.queryParams.subscribe((queryparams) => {
      this.builderStore.initBuilder(
        this.fakeOrg,
        this.survey,
        [],
        this.uiService.languagesAndCountries.surveyLanguagesWithEmojis,
        [],
        () => undefined,
        true,
        false,
      );

      this.refreshCards();

      this.query = queryparams["query"];

      this.onGenerateScenario(this.query);
    });
  }

  changeLoadingMessage() {
    this.loadingMessage =
      loadingMessages[Math.ceil(Math.random() * loadingMessages.length)];

    setTimeout(
      () => this.changeLoadingMessage(),
      Math.round(Math.random() * 4000 + 1000),
    );
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
    this.builderStore.resetBuilder();
  }

  public onGenerateScenario(query: string | undefined) {
    if (!query) {
      return;
    }

    this.hasData = false;
    this.isGenerating = true;

    this.surveyDao
      .generateScenarioPublic(query)
      .then((data: any) => {
        localStorage.setItem(
          "screeb-generate-" + stringToHash(query),
          JSON.stringify(data),
        );

        const { scenario } = data ?? {};

        if (!scenario) {
          this.notificationHelper.trigger(
            "Failed to generate scenario",
            "Please try again later",
            "error",
          );
          return;
        }

        this.scenario = data;

        // Format the questions to the format expected by the import service
        const formattedQuestions = scenario?.map((q) => {
          const question = new ImportQuestion();
          question.id = q.id;
          question.type = q.type;
          question.description = q.question;
          question.skip_id = q.skip_id;
          question.options = q.options?.map((o) => {
            const option = new ImportQuestionOption();
            option.label = o.label;
            option.emoji = o.emoji;
            option.next_id = o.next_id;
            return option;
          });
          return question;
        });

        this.importService.buildSurveyScenario(formattedQuestions);

        this.refreshCards();

        this.hasData = true;
      })
      .catch((err) => {
        console.log(err);
        this.notificationHelper.trigger(
          "An error occured while generating the scenario, please try again.",
          null,
          "error",
        );
      })
      .finally(() => {
        this.isGenerating = false;
      });
  }

  private refreshCards() {
    const cards = this.builderStore.refreshCards();
    if (!cards) return;

    this.columnsWithCards = cards.columnsWithCards;
  }

  regenerate() {
    localStorage.removeItem("screeb-generate-" + stringToHash(this.query));

    this.onGenerateScenario(this.query);
  }

  onNextClick() {
    localStorage.setItem(
      "screeb-restore-scenario",
      JSON.stringify(this.scenario),
    );

    if (this.sessionService.isAuth()) {
      this.router.navigate(
        ["org", "last", this.uiService.surveyTypePath, "create"],
        {
          queryParams: {
            fromScenario: true,
          },
        },
      );
    } else {
      this.router.navigateByUrl("/auth/register");
    }
  }
}
