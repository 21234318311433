import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AnalyticsQuery } from "models/analytics.filters.type";
import { Org } from "models/org.model";
import { RegistryDao } from "models/registry.dao";
import { RegistryEntry } from "models/registry.model";
import { getFormattedUserGroupNameOrID } from "models/user-group.types";
import { UserDao } from "models/user.dao";
import {
  NzSelectComponent,
  NzSelectOptionInterface,
} from "ng-zorro-antd/select";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { TrackersService } from "services/trackers.service";
import { deepCopy } from "utils/object";
import { NgIf } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzModalComponent, NzModalContentDirective } from "ng-zorro-antd/modal";
import { FeaturePipe } from "pipes/feature.pipe";
import { NzRadioGroupComponent } from "ng-zorro-antd/radio";
import { BigRadio } from "components/utils/big-radio/big-radio.component";
import { FormsModule } from "@angular/forms";
import { NzInputDirective } from "ng-zorro-antd/input";

@Component({
  selector: "filters-save-segment",
  templateUrl: "./save-segment.component.html",
  styleUrls: ["./save-segment.component.scss"],
  imports: [
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NzModalComponent,
    NzModalContentDirective,
    NzRadioGroupComponent,
    BigRadio,
    FeaturePipe,
    NzSelectComponent,
    FormsModule,
    NzInputDirective,
  ],
})
export class FiltersSaveSegmentComponent implements OnInit, OnDestroy {
  private filtersObs$: any = null;
  public lastFilters: AnalyticsQuery;
  public defaultFilters: AnalyticsQuery;

  public isModalVisible = false;
  public loading = false;
  public error: string = null;

  public userGroupOptions: NzSelectOptionInterface[] = [];
  public selectedUserGroups: { type: string; name: string }[] = [];

  public createNew = true;
  public isSegmentsValid = false;

  @Input() public org: Org;

  public registryEntriesGroup: RegistryEntry[];

  @ViewChild("segmentNameElement")
  segmentNameElement: ElementRef<HTMLInputElement>;

  public getFormattedUserGroupNameOrID = getFormattedUserGroupNameOrID;

  constructor(
    private analyticsFilterService: AnalyticsFilterService,
    private userDao: UserDao,
    private trackersService: TrackersService,
    private registryDao: RegistryDao,
  ) {}

  ngOnInit() {
    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQuery) => {
        this.lastFilters = deepCopy(filters);
      });
  }

  ngOnDestroy() {
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  isValid() {
    if (!this.selectedUserGroups.length) {
      return false;
    }

    this.isSegmentsValid = this.selectedUserGroups.every(
      this.isSegmentNameValid,
    );

    return this.isSegmentsValid;
  }

  isSegmentNameValid({ name }) {
    return name && name.length < 255 && name.match(/^[0-9a-zA-Z_ -]+$/);
  }

  public async showModal() {
    this.error = null;
    this.selectedUserGroups = [];
    this.isModalVisible = true;
    this.loading = true;

    try {
      await this.registryDao.getGroups(this.org.id).then((res) => {
        this.registryEntriesGroup = res.groups;
      });
    } catch (e) {
      console.error(e);
    }

    this.loading = false;
    this.userGroupOptions = this.registryEntriesGroup
      .map((group) => ({
        value: { type: group.parent?.slug, name: group.slug },
        label: getFormattedUserGroupNameOrID(group),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    setTimeout(() => {
      this.segmentNameElement?.nativeElement?.focus();
    }, 500);
  }

  handleOk(): void {
    if (!this.isValid()) {
      return;
    }

    if (this.lastFilters.type === "respondent") {
      this.loading = true;
      this.userDao
        .saveSegment(this.org.id, this.selectedUserGroups, this.lastFilters)
        .then(() => this.closeModal())
        .catch(() => (this.error = "Something went wrong. Please try again."))
        .finally(() => (this.loading = false));

      this.trackersService
        .newEventTrackingBuilder("Segment created")
        .withOrg(this.org)
        .withAnalyticsFilters(this.lastFilters.filters, "respondent")
        .withProps({ segments: this.selectedUserGroups })
        .build();
    }
  }

  closeModal() {
    this.isModalVisible = false;
  }

  handleCancel(): void {
    this.closeModal();
  }

  keyDown($event: KeyboardEvent) {
    if ($event.keyCode === 13 && this.isValid()) {
      this.handleOk();
    }
  }

  onCreateNewChange(createNew: boolean) {
    this.selectedUserGroups = [];
    if (createNew) {
      setTimeout(() => {
        this.segmentNameElement?.nativeElement?.focus();
      }, 100);
    }
  }

  onSegmentNameChange(name: string) {
    this.selectedUserGroups = [{ type: "default", name }];
    this.isValid();
  }

  onSelectedSegmentChange($event: { type: string; name: string }) {
    this.selectedUserGroups = [$event];
    this.isValid();
  }
}
