/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { differenceInDays, endOfDay, format } from "date-fns";
import { Org } from "models/org.model";
import {
  AddonKey,
  BillingSettings,
  OrgBilling,
  OrgBillingInvoice,
  OrgBillingPaymentMethods,
  OrgBillingSubscription,
  PlanType,
  notFreeSubscriptionOptions,
  subscriptionOptions,
} from "models/org_billing.model";
import { SuperOrg } from "models/super-org.model";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { SettingsService } from "services/settings.service";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { deepCopy } from "utils/object";
import {
  addonInfos,
  billingCycleDurationLabels,
  planTypeInfos,
} from "../components/billing-plan/billing-plan.data";
import {
  couponToString,
  defaultScaleSubscriptionOptions,
  lateralPanelMode,
  planIdToPlanType,
} from "../products.data";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";
import { NgIf, NgTemplateOutlet } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzProgressComponent } from "ng-zorro-antd/progress";
import { ɵEmptyOutletComponent } from "@angular/router";
import { HubspotComponent } from "../../../utils/hubspot/hubspot.component";
import { IntercomComponent } from "../../../utils/intercom/intercom.component";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { NzRowDirective } from "ng-zorro-antd/grid";
import { SettingsBillingAddonComponent } from "../components/addon-upgrade.component";
import { PricePipe } from "pipes/price.pipe";
import { ScreebFormatPipe } from "pipes/format.pipe";
import { PluralSingularPipe } from "pipes/pluralSingular.pipe";
import { EntitlementPipe } from "pipes/entitlement.pipe";

export type onSubscribePayload = {
  mode: lateralPanelMode;
  currentSubscription: subscriptionOptions;
  targetedSubscription: subscriptionOptions;
};

@Component({
  selector: "settings-billing-tab-subscription",
  templateUrl: "./tab-subscription.component.html",
  styleUrls: ["./tab-subscription.component.scss"],
  imports: [
    ScreebIconComponent,
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzPopoverDirective,
    NzIconDirective,
    NzProgressComponent,
    NgTemplateOutlet,
    ɵEmptyOutletComponent,
    HubspotComponent,
    IntercomComponent,
    NzTooltipDirective,
    NzRowDirective,
    SettingsBillingAddonComponent,
    PricePipe,
    ScreebFormatPipe,
    PluralSingularPipe,
    EntitlementPipe,
  ],
})
export class SettingsBillingTabSubscriptionComponent
  implements OnInit, OnChanges
{
  @Input() superOrg: SuperOrg = null;
  @Input() workspaces: Org[] = [];
  @Input() orgBilling: OrgBilling = null;
  @Input() orgBillingSubscription: OrgBillingSubscription = null;
  @Input() orgBillingPaymentMethods: OrgBillingPaymentMethods = null;
  @Input() orgBillingInvoices: OrgBillingInvoice[] = [];
  @Input() billingSettings: BillingSettings;
  @Input() countries: string[][] = null;
  @Input() currentSubscriptionOptions: subscriptionOptions;

  @Output() onSubscribe: EventEmitter<onSubscribePayload> =
    new EventEmitter<onSubscribePayload>();
  @Output() openPanel: EventEmitter<string> = new EventEmitter<string>();
  @Output() onUpgrade: EventEmitter<string> = new EventEmitter<string>();
  @Output() onDowngrade: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCancel: EventEmitter<string> = new EventEmitter<string>();
  @Output() goToTab: EventEmitter<string> = new EventEmitter<string>();

  public currentPlanType: PlanType;

  public data = [];

  public couponToString = couponToString;
  public planTypeInfos = planTypeInfos;
  public cycleDurationToLabel = billingCycleDurationLabels;

  public remainingTrialDays = 0;

  public get now(): Date {
    return new Date();
  }

  constructor(
    private trackersService: TrackersService,
    private settingsService: SettingsService,
    public featureFlaggingService: FeatureFlaggingService,
    public uiService: UIService,
    public entitlementService: EntitlementService,
  ) {}

  ngOnInit(): void {
    this.refresh();
  }

  ngOnChanges(): void {
    this.refresh();
  }

  refresh(): void {
    this.currentPlanType = planIdToPlanType(
      this.orgBilling.stripe_product_id,
      this.settingsService.billing,
    );
    if (this.entitlementService.isTrialing()) {
      this.refreshFreeTrialRemaningDays();
    }
  }

  public getFullAddress(orgBilling: OrgBilling): string {
    const parts = [];

    if (orgBilling.address_line_1) {
      parts.push(orgBilling.address_line_1);
    }

    if (orgBilling.address_line_2) {
      parts.push(orgBilling.address_line_2);
    }

    if (orgBilling.postal_code) {
      parts.push(orgBilling.postal_code);
    }

    if (orgBilling.city) {
      parts.push(orgBilling.city);
    }

    if (orgBilling.country) {
      parts.push(this.isoCountry2ToLabel(orgBilling.country));
    }

    return parts.join(", ");
  }

  private refreshFreeTrialRemaningDays() {
    this.remainingTrialDays =
      this.entitlementService.getRemainingFreeTrialDays() ?? 0;
  }

  public isoCountry2ToLabel(iso: string): string {
    return this.countries.find((c) => c[0] === iso)?.[1];
  }

  public activateAddon(addonKey: AddonKey) {
    const event = this.trackersService
      .newEventTrackingBuilder(
        ("Organization billing addon " +
          addonInfos[addonKey].name +
          " requested") as TrackingEventName,
      )
      .withSuperOrg(this.superOrg)
      .withProps({
        current_subscription_options: this.currentSubscriptionOptions,
      });

    if (this.currentPlanType === "custom") {
      event.withProps({ action_result: "contact_us" }).build();
      this.contactOurCSM();
      return;
    }

    const targetedSubscriptionOptions = deepCopy(
      ["scale", "advanced"].includes(this.currentPlanType)
        ? this.currentSubscriptionOptions
        : defaultScaleSubscriptionOptions,
    ) as notFreeSubscriptionOptions;

    switch (addonKey) {
      case "white-label":
        targetedSubscriptionOptions.addons.whiteLabel = true;
        break;
      case "additional-workspaces":
        targetedSubscriptionOptions.addons.additionalWorkspaces += 1;
        break;
      case "response-translation":
        targetedSubscriptionOptions.addons.responseTranslation = true;
        break;
      case "audio-video-survey":
        targetedSubscriptionOptions.addons.audioVideoSurvey = true;
        break;
      case "survey-inapp-mobile":
        targetedSubscriptionOptions.addons.surveyInAppMobile = true;
        break;
      case "graphical-survey-customization":
        targetedSubscriptionOptions.addons.graphicalSurveyCustomization = true;
        break;
      // case "message-inapp":
      //   targetedSubscriptionOptions.addons.messageInApp = true;
      //   break;
      // case "ai-content-analysis":
      //   targetedSubscriptionOptions.addons.aiContentAnalysis = true;
      //   break;
      case "iam-mfa":
        targetedSubscriptionOptions.addons.iamMFA = true;
        break;
    }

    event
      .withProps({
        action_result: "open_panel",
        upgrade_to: targetedSubscriptionOptions.planType,
      })
      .withProps({ targeted_subscription_options: targetedSubscriptionOptions })
      .build();
    this.onSubscribe.emit({
      mode: "activate_addon",
      currentSubscription: this.currentSubscriptionOptions,
      targetedSubscription: targetedSubscriptionOptions,
    });
  }

  public activateAdditionalMTU() {
    const event = this.trackersService
      .newEventTrackingBuilder(
        "Organization billing addon additional MTU requested",
      )
      .withSuperOrg(this.superOrg)
      .withProps({
        current_subscription_options: this.currentSubscriptionOptions,
      });

    if (this.currentPlanType === "custom") {
      this.contactOurCSM();
      event.withProps({ action_result: "contact_us" }).build();
      return;
    }

    this.onUpgrade.emit(this.currentSubscriptionOptions.planType);
    event
      .withProps({
        action_result: "open_panel",
        upgrade_to: this.currentSubscriptionOptions.planType,
      })
      .build();
  }

  public increaseRetention() {
    this.askUsAQuestion();
  }

  private askUsAQuestion() {
    if (this.trackersService.intercomIsBooted()) {
      this.trackersService.intercomOpenConversation();
    } else {
      window.location.assign("mailto:michael@screeb.app");
    }
  }

  private contactOurCSM() {
    window.open("https://meetings.hubspot.com/salome-cousseau", "_blank");
  }

  public getFormattedDate(timestamp: number) {
    return format(timestamp * 1000, "MMMM d, y");
  }

  public getFreeTrialTotalDuration(): number {
    const trialDuration = this.orgBilling.billing_trial_duration;
    return differenceInDays(endOfDay(trialDuration * 1000), 0);
  }

  public getFormattedFreeTrialDuration(): string {
    if (this.entitlementService.debugFlags["force-free-trial"]) return "∞ days";
    const trialDuration = this.orgBilling.billing_trial_duration;
    const days = differenceInDays(endOfDay(trialDuration * 1000), 0);
    return `${days} days`;
  }

  public isAddonAvailableForPlan(addonKey: AddonKey): boolean {
    return addonInfos[addonKey]?.suggestedForPlans.includes(
      this.currentPlanType,
    );
  }

  public getRetentionDuration(
    feature: "sessions" | "responses" | "tracking",
  ): string | null {
    const conversion = (days: number) => {
      if (days <= 0) {
        return null;
      }

      if (days === 365) {
        return "1 year";
      }

      return Math.round(days / 30) + " months";
    };

    switch (feature) {
      case "tracking":
        return conversion(
          this.orgBilling.expected_metadata.retention_tracking_days,
        );
      case "sessions":
        return conversion(
          this.orgBilling.expected_metadata.retention_sessions_days,
        );
      case "responses":
        return conversion(
          this.orgBilling.expected_metadata.retention_responses_days,
        );
    }

    return null;
  }
}
