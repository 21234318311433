<header>
  <free-trial-banner />
  <div class="header-top-line">
    <div>
      <div class="hello-message">
        <org-account-avatar
          [account]="sessionService.session"
          size="small"
          [ctaWhenNoPicture]="true"
        ></org-account-avatar>
        <div class="hello-message-text">
          <span>
            {{ helloMessage }}
          </span>
          <span class="hello-message-name">
            {{ sessionService.session?.firstname }}
          </span>
          <span>!</span>
        </div>
      </div>
    </div>

    <div class="right">
      <div class="dashboard-edit">
        <org-account-avatar-list
          [accounts]="orgAccounts"
          [maxDisplayed]="8"
          theme="dark"
          size="medium"
        ></org-account-avatar-list>
        <button
          *ngIf="'org.update' | hasSuperPermission"
          class="add-widget-button"
          nz-button
          nzType="{{ !editingDashboard ? 'primary' : 'default' }}"
          nzSize="medium"
          (click)="setEditingDashboard()"
        >
          <screeb-icon *ngIf="!editingDashboard" size="sm" icon="edit" />
          <screeb-icon *ngIf="editingDashboard" size="sm" icon="cross" />
        </button>
      </div>
      <div class="range-picker" *ngIf="!editingDashboard">
        <button nz-button nz-dropdown [nzDropdownMenu]="menu">
          Range period:
          {{ gridRangeNames[this.orgReport.range ?? "month"] }}
          <screeb-icon size="md" icon="arrow-bottom"></screeb-icon>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <!-- <li nz-menu-item (click)="changeRange('day')">Day</li> -->
            <li nz-menu-item (click)="changeRange('week')">Week</li>
            <li nz-menu-item (click)="changeRange('month')">Month</li>
            <li nz-menu-item (click)="changeRange('quarter')">Quarter</li>
            <li nz-menu-item (click)="changeRange('year')">Year</li>
          </ul>
        </nz-dropdown-menu>
      </div>
      <div *ngIf="editingDashboard" class="grid-actions">
        <button
          class="add-widget-button"
          nz-button
          nzType="default"
          nzSize="medium"
          (click)="resetHomeGrid()"
        >
          <span>Reset&nbsp;</span>
          <screeb-icon size="sm" icon="reset" />
        </button>
        <button
          class="add-widget-button"
          nz-button
          nzType="primary"
          nzSize="medium"
          (click)="isAddWidgetOpen = true"
        >
          <span>Add widget&nbsp;</span>
          <screeb-icon size="sm" icon="plus" />
        </button>
        <button
          class="add-widget-button"
          nz-button
          nzType="primary"
          nzSize="medium"
          (click)="onSave()"
        >
          <span>Save&nbsp;</span>
          <screeb-icon size="sm" icon="save" />
        </button>
      </div>
    </div>
  </div>
</header>

<div class="grid-container" [ngClass]="gridVisible ? 'visible' : 'not-visible'">
  <div class="grid">
    <div
      *ngFor="let n of items; let i = $index"
      [id]="n.id ?? n.type"
      class="item"
      [ngClass]="'item-' + n.w"
      [ngStyle]="{
        height: n.h ? 'calc(' + n.h + ' - 10px)' : '',
      }"
    >
      <div class="item-content">
        <widget-wrapper
          [editing]="editingDashboard"
          [widget]="n"
          (widgetChange)="onWidgetChange(i, $event)"
          [range]="this.orgReport.range ?? 'month'"
          (deleteClicked)="removeWidget(n.id ?? n.type)"
          [org]="org"
          [orgHasRespondents]="orgHasRespondents"
          [surveys]="surveys"
        ></widget-wrapper>
      </div>
    </div>
  </div>
</div>

<utils-lateral-panel
  *ngIf="isAddWidgetOpen"
  (onClose)="isAddWidgetOpen = false"
  [withPaddingTop]="false"
>
  <div class="lateral-header-left">
    <button class="previous" (click)="isAddWidgetOpen = false">
      <i nz-icon nzType="close"></i>
    </button>
    <h2 class="title">Add Content</h2>
  </div>
  <div class="lateral-header-right">
    <div class="next"></div>
  </div>

  <div class="lateral-body">
    <h3 class="checkbox-title">
      Which data do you want to add to this report?
    </h3>
    <checkbox-select
      [items]="checkboxItems"
      [selectedItems]="checkboxSelectedItems"
      (selectedItemsChange)="this.onReportChange($event)"
      [fullHeight]="true"
      [withBorderRadius]="true"
      placeholder="Search"
    ></checkbox-select>

    <div class="add-free-text-bock" (click)="AddTextWidget()">
      <screeb-icon size="sm" icon="plus" />
      <p class="add-free-text-bock-text">Add a free text bloc to this report</p>
    </div>
  </div>

  <div class="lateral-footer-left"></div>
  <div class="lateral-footer-right">
    <button
      (click)="isAddWidgetOpen = false"
      nz-button
      nzType="default"
      nzSize="large"
    >
      Cancel
    </button>
    <button
      (click)="onCheckboxConfirm()"
      class="ant-btn-secondary"
      nz-button
      nzType="default"
      nzSize="large"
    >
      Ok
    </button>
  </div>
</utils-lateral-panel>
