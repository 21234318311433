import { NgFor } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { WorkspaceAccountRole } from "models/account-permissions";
import { Org } from "models/org.model";
import { SuperOrgFlagEmailDomainAuthDefaultWorkspace } from "models/super-org.model";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzOptionComponent, NzSelectComponent } from "ng-zorro-antd/select";
import {
  NzTableModule
} from "ng-zorro-antd/table";

@Component({
  imports: [
    NzSelectComponent,
    NzOptionComponent,
    NzTableModule,
    NzButtonComponent,
    NzIconDirective,
    NgFor,
    FormsModule,
  ],
  selector: "settings-workspace-access",
  template: `
    <nz-table
      #table
      [nzData]="workspaceAccesses"
      nzNoResult="No Workspaces defined"
      [nzShowPagination]="false"
    >
      <thead>
        <tr>
          <th nzWidth="200px">Workspace</th>
          <th nzWidth="200px">Permission</th>
          <th nzWidth="50px"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let access of table.data; let i = index">
          <td>
            <nz-select
              nzShowSearch
              nzPlaceHolder="Select a workspace"
              [(ngModel)]="access.workspace_id"
              (ngModelChange)="onUpdate()"
            >
              <nz-option
                *ngFor="let workspace of workspaces"
                [nzLabel]="workspace.name"
                [nzValue]="workspace.id"
              ></nz-option>
            </nz-select>
          </td>
          <td>
            <nz-select
              nzPlaceHolder="Select a permission"
              [(ngModel)]="access.permission"
              (ngModelChange)="onUpdate()"
            >
              <nz-option
                *ngFor="let permission of permissions"
                [nzLabel]="permission"
                [nzValue]="permission"
              ></nz-option>
            </nz-select>
          </td>
          <td>
            <button
              nz-button
              nz-tooltip="Remove"
              nzType="text"
              nzShape="circle"
              (click)="deleteAccess(i)"
            >
              <i nz-icon nzType="close"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
    <button
      class="add-access-btn"
      nz-button
      nzType="primary"
      (click)="addAccess()"
    >
      Add access
    </button>
  `,
  styleUrl: "./workspace-access-edit.component.scss",
})
export class SettingsWorkspaceAccessComponent {
  @Input() workspaceAccesses: SuperOrgFlagEmailDomainAuthDefaultWorkspace[];
  @Input() permissions: WorkspaceAccountRole[] = ["analyst", "editor"];
  @Input() workspaces: Org[] = [];

  @Output() workspacesAccessChange: EventEmitter<
    SuperOrgFlagEmailDomainAuthDefaultWorkspace[]
  > = new EventEmitter();

  deleteAccess(index: number) {
    this.workspaceAccesses.splice(index, 1);

    // refresh the table
    this.workspaceAccesses = [...this.workspaceAccesses];

    this.workspacesAccessChange.emit(this.workspaceAccesses);
  }

  addAccess() {
    this.workspaceAccesses.push({
      workspace_id: "",
      permission: this.permissions[0] ?? "analyst",
    });

    // refresh the table
    this.workspaceAccesses = [...this.workspaceAccesses];

    this.workspacesAccessChange.emit(this.workspaceAccesses);
  }

  onUpdate() {
    this.workspacesAccessChange.emit(this.workspaceAccesses);
  }
}
