<label class="lateral-panel-subtitle">Options</label>

<nz-table
  [nzData]="cta.choices"
  [nzFrontPagination]="false"
  [nzShowPagination]="false"
>
  @let bindedSort = sortPredicate.bind(this);
  <tbody
    cdkDropList
    (cdkDropListDropped)="drop($event)"
    [cdkDropListSortPredicate]="bindedSort"
  >
    <tr
      *ngFor="let button of cta.choices; let ctaIndex = index"
      cdkDrag
      [cdkDragDisabled]="button.payload.other_option"
    >
      <td *cdkDragPlaceholder class="drag-placeholder"></td>
      <td>
        <div class="panel-button-line">
          <span
            *ngIf="
              this.node.node.question.call_to_action.type ===
                'multiple_choice' && !button.payload.other_option
            "
            class="holder-icon"
            nz-icon
            nzType="holder"
            nzTheme="outline"
            cdkDragHandle
          ></span>
          <button
            *ngIf="button.payload.emoji"
            #btnElement
            class="emoji-button"
            [disabled]="node.isPreset"
            (click)="openEmojiPicker(button, btnElement)"
          >
            <span *ngIf="button.payload.emoji">
              <sanitized-message-component
                [content]="button.payload.emoji"
                [options]="{
                  styling: true,
                  emojiTranscoder: true,
                }"
              ></sanitized-message-component>
            </span>
            <span *ngIf="!button.payload.emoji" class="no-emoji">no emoji</span>
          </button>
          <button
            *ngIf="!button.payload.emoji"
            #btnElement
            class="emoji-button-no-emoji"
            title="Open emoji picker"
            [disabled]="node.isPreset"
            (click)="openEmojiPicker(button, btnElement)"
          >
            <i nz-icon nzType="plus"></i>
          </button>
          <input
            type="text"
            class="panel-input"
            placeholder="Your answer"
            [ngClass]="{ invalid: (textBtnErrors[ctaIndex]?.length ?? 0) > 0 }"
            [ngModel]="getLabelText(ctaIndex)"
            (ngModelChange)="setLabelText(ctaIndex, $event); validateData()"
          />
          <lateral-panel-step-picker
            *ngIf="!cta.multiple"
            [routing]="node.node.routing"
            [chainableNodeIds]="node.chainableNodeIds"
            [actions]="[button]"
          ></lateral-panel-step-picker>
          <button
            class="remove-button"
            title="Remove option"
            *ngIf="
              !node.isPreset &&
              cta.choices.length > 1 &&
              !button.payload.other_option
            "
            (click)="removeOption(ctaIndex)"
          >
            <i nz-icon nzType="close-circle"></i>
          </button>
        </div>
        <utils-form-error [errors]="textBtnErrors[ctaIndex]"></utils-form-error>
      </td>
    </tr>
  </tbody>
</nz-table>

<button
  *ngIf="!node.isPreset && cta.choices.length < 10"
  nz-button
  nzType="default"
  nzSize="large"
  (click)="addOption()"
>
  + Add an option
</button>

<ng-container *ngIf="cta.multiple">
  <label class="lateral-panel-subtitle">Next step</label>
  <lateral-panel-step-picker
    [routing]="node.node.routing"
    [chainableNodeIds]="node.chainableNodeIds"
    [actions]="cta.choices"
  ></lateral-panel-step-picker>
</ng-container>

<ng-container *ngIf="'isOtherOptionEnabled' | hasFeatureFlag">
  <label class="lateral-panel-subtitle skip-to-step-title">
    Add an 'Other' option
  </label>
  <p class="lateral-panel-tip">
    It will always appear as the last option in the list, regardless of any
    randomization. Users can then precise their response.
  </p>
  <nz-switch
    ngDefaultControl
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="cta.other_option"
    (ngModelChange)="setIsOtherOption($event)"
  ></nz-switch>
</ng-container>

<ng-container>
  <label class="lateral-panel-subtitle skip-to-step-title">Skip to step</label>
  <p class="lateral-panel-tip">Allowing users to skip to a next step</p>

  <lateral-panel-step-picker
    [routing]="node.node.routing"
    [chainableNodeIds]="node.chainableNodeIds"
    [actions]="[skipAction]"
    [isSkipAction]="true"
  ></lateral-panel-step-picker>
</ng-container>

<ng-container *ngIf="!node.isPreset">
  <label class="lateral-panel-subtitle multi-select-title">Multi select</label>
  <p class="lateral-panel-tip">Allowing users to select multiple options</p>
  <nz-switch
    ngDefaultControl
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="cta.multiple"
    (ngModelChange)="setIsMultiple($event)"
  ></nz-switch>
</ng-container>

<ng-container *ngIf="!node.isPreset">
  <label class="lateral-panel-subtitle randomize-title">Randomize</label>
  <p class="lateral-panel-tip">Display options in a randomized order</p>
  <nz-switch
    ngDefaultControl
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="cta.randomize"
    (ngModelChange)="setIsRandomize($event)"
  ></nz-switch>
</ng-container>

<ng-container>
  <label class="lateral-panel-subtitle">Responsive</label>
  <p class="lateral-panel-tip">Display options in reverse order on mobile</p>
  <nz-switch
    ngDefaultControl
    [ngModelOptions]="{ standalone: true }"
    [ngModel]="cta.responsive"
    (ngModelChange)="setIsResponsive($event)"
  ></nz-switch>
</ng-container>

<ng-container *ngIf="'isSaveToVariableEnabled' | hasFeatureFlag">
  <label class="lateral-panel-subtitle">
    Assign this question to a property
  </label>
  <p class="lateral-panel-tip">
    Allows you to reuse the answer in other questions or in your integrations.
  </p>
  <lateral-panel-variable-picker
    nzPlaceHolder="Select a property"
    [(ngModel)]="variable"
    [nzOptions]="variables"
  ></lateral-panel-variable-picker>
  <small>Only string properties supported for this question</small>
</ng-container>
