import "chartjs-adapter-date-fns";
import {
  enableProdMode,
  APP_INITIALIZER,
  ErrorHandler,
  importProvidersFrom,
} from "@angular/core";
import { environment } from "./environment";
import {
  HttpBackend,
  withInterceptorsFromDi,
  provideHttpClient,
} from "@angular/common/http";
import { provideCharts, withDefaultRegisterables } from "ng2-charts";
import { ConfigService } from "services/config.service";
import { startLogging, LoggerService } from "services/logging.service";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { ApplicationLoadService } from "services/app.service";
import { AuthService, SessionService } from "services/auth.service";
import { EventService } from "services/event.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import { SeoService } from "services/seo.service";
import { SettingsService } from "services/settings.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { EntitlementService } from "services/entitlement.service";
import { MediaUploadService } from "services/media-upload.service";
import { PermissionsService } from "services/permissions.service";
import { OAuth2Service } from "services/oauth2.service";
import { UpgradeAdminService } from "services/upgrade.service";
import { TagEditorService } from "services/tag-editor.service";
import { ImportService } from "services/import.service";
import { NZ_I18N, en_US } from "ng-zorro-antd/i18n";
import { HIGHLIGHT_OPTIONS, HighlightModule } from "ngx-highlightjs";
import { LayoutBackHeaderService } from "components/layouts/back/header/header.service";
import { IsNotAuthGuard, EnsureNotAuthGuard } from "guards/auth.guard";
import { GenericCanDeactivate } from "guards/generic-can-deactivate.guard";
import { IsIntegrationSlugValidGuard } from "guards/integration.guard";
import { DoOrgHasSurveysGuard } from "guards/survey.guard";
import { OnBoardingGuard } from "guards/onboarding.guard";
import { CanCreateSuperOrgGuard } from "guards/can-create-super-org.guard";
import { AssetIndustriesScoresResolver } from "resolvers/asset-industries-scores";
import { SuperOrgWorkspacesResolver } from "resolvers/super-org-workspaces";
import { ChannelsResolver } from "resolvers/channels";
import {
  ChannelClientScreensResolver,
  ChannelClientVersionsResolver,
  ChannelsClientVersionsResolver,
} from "resolvers/channel-screen";
import { SurveyTagsResolver } from "resolvers/survey-tags";
import { SurveyResolver } from "resolvers/survey";
import { SurveysResolver } from "resolvers/surveys";
import { SurveyDistributionResolver } from "resolvers/survey-distribution";
import { SurveyScenarioNodeResolver } from "resolvers/survey-scenario-node";
import { OrgTargetingRulesResolver } from "resolvers/org-targeting-rules";
import { OrgReportResolver } from "resolvers/org-report";
import { RecordTargetingRulesResolver } from "resolvers/record-targeting-rules";
import { OrgAccountsResolver } from "resolvers/org-accounts";
import { SuperOrgAccountsResolver } from "resolvers/super-org-accounts";
import { SuperOrgResolver } from "resolvers/super-org";
import { SuperOrgsResolver } from "resolvers/super-orgs";
import { OrgResolver } from "resolvers/org";
import { WorkspacesResolver } from "resolvers/orgs";
import {
  OrgBillingInvoicesResolver,
  OrgBillingPaymentMethodsResolver,
  OrgBillingResolver,
  OrgBillingSubscriptionResolver,
} from "resolvers/org-billing";
import { UserResolver } from "resolvers/user";
import { UserRecordResolver } from "resolvers/user-record";
import {
  RegistryIdentityPropertyResolver,
  RegistryGroupResolver,
  RegistryEventsResolver,
} from "resolvers/registry";
import {
  OrgIntegrationsResolver,
  OrgIntegrationOrNullResolver,
} from "resolvers/integrations";
import { IntegrationAtlassianProjectsResolver } from "resolvers/integration-atlassian-projects";
import { SequenceResolver } from "resolvers/sequence";
import { SequencesResolver } from "resolvers/sequences";
import { OrgReleasesResolver } from "resolvers/releases";
import { OrgIndustryStatsResolver } from "resolvers/org-industry";
import { TagEditorTokenResolver } from "resolvers/tag-editor-token";
import { WeeklyBriefingResolver } from "resolvers/weekly-briefing";
import { AnalyticsDao } from "models/analytics.dao";
import { UserDao } from "models/user.dao";
import { UserRecordDao } from "models/user-record.dao";
import { ResponseDao } from "models/response.dao";
import { SurveyDao } from "models/survey.dao";
import { SurveyDistributionDao } from "models/survey-distribution.dao";
import { SurveyScenarioNodeDao } from "models/survey-scenario-node.dao";
import { ChannelDao } from "models/channel.dao";
import { SuperOrgDao } from "models/super-org.dao";
import { OrgDao } from "models/org.dao";
import { RegistryDao } from "models/registry.dao";
import { AccountDao } from "models/account.dao";
import { IntegrationDao } from "models/integration.dao";
import { TagSettingsDao } from "models/tag-settings.dao";
import { UploadDao } from "models/upload.dao";
import { EmailDao } from "models/email.dao";
import { SequenceDao } from "models/sequence.dao";
import { ReleaseDao } from "models/release.dao";
import { GetFeedbackDao } from "components/surveys/pages/import/tools/getfeedback/getfeedback.dao";
import { TagEditorTokenDao } from "models/tag-editor-token.dao";
import { DebugDao } from "models/debug.dao";
import { WeeklyBriefingDao } from "models/weekly-briefing.dao";
import { BuilderStore } from "stores/builder.store";
import { ScreebApiHelper } from "helpers/screeb-api.helper";
import { NotificationHelper } from "helpers/notification.helper";
import { RouterModule, ExtraOptions } from "@angular/router";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { screebRoutes } from "./app/app.routes";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OverlayModule } from "@angular/cdk/overlay";
import { CookieModule } from "ngx-cookie";
import { DateFnsModule } from "ngx-date-fns";
import { ClipboardModule } from "ngx-clipboard";
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { ColorPickerModule } from "@iplab/ngx-color-picker";
import { TranslateModule } from "@ngx-translate/core";
import { KeyboardShortcutsModule } from "ng-keyboard-shortcuts";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { NgxStripeModule } from "ngx-stripe";
import { ENV } from "environment";
import { MarkdownModule } from "ngx-markdown";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzTimelineModule } from "ng-zorro-antd/timeline";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzListModule } from "ng-zorro-antd/list";
import { NzSliderModule } from "ng-zorro-antd/slider";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzImageModule } from "ng-zorro-antd/image";
import { IconsProviderModule } from "./app/icons-provider.module";
import { AppComponent } from "./app/app.component";

const routingOpts: ExtraOptions = {
  initialNavigation: "enabledNonBlocking",
  onSameUrlNavigation: "reload",
  useHash: false,
  urlUpdateStrategy: "eager",
  paramsInheritanceStrategy: "always", // childRoutes from /org/:org_id can access $org_id
};

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      // angular modules
      BrowserModule,
      RouterModule.forRoot(screebRoutes, routingOpts),
      FormsModule,
      ReactiveFormsModule,
      OverlayModule,
      // external modules
      CookieModule.forRoot(),
      DateFnsModule.forRoot(),
      ClipboardModule,
      PickerModule, // emoji picker
      ColorPickerModule,
      TranslateModule.forRoot({
        defaultLanguage: "en",
      }),
      KeyboardShortcutsModule.forRoot(),
      HighlightModule,
      NgxJsonViewerModule,
      NgxStripeModule.forRoot(ENV["STRIPE_PUBLISHABLE_KEY"]),
      MarkdownModule.forRoot(),
      DragDropModule,
      // ng-zorro
      NzLayoutModule,
      NzGridModule,
      NzPopconfirmModule,
      NzMenuModule,
      NzFormModule,
      NzInputModule,
      NzButtonModule,
      NzRadioModule,
      NzCheckboxModule,
      NzSelectModule,
      NzSwitchModule,
      NzStepsModule,
      NzBadgeModule,
      NzDividerModule,
      NzToolTipModule,
      NzPopoverModule,
      NzDropDownModule,
      NzCardModule,
      NzProgressModule,
      NzTableModule,
      NzTagModule,
      NzModalModule,
      NzEmptyModule,
      NzSpinModule,
      NzDatePickerModule,
      NzTabsModule,
      NzTimelineModule,
      NzCollapseModule,
      NzListModule,
      NzSliderModule,
      NzBreadCrumbModule,
      NzInputNumberModule,
      NzImageModule,
      // internal module
      IconsProviderModule,
    ),
    {
      provide: APP_INITIALIZER,
      useFactory:
        (handler: HttpBackend, configService: ConfigService) => () => {
          // Let's initialize the config service
          return configService.loadConfig(handler).then(() => {
            startLogging(configService.config);
          });
        },
      deps: [HttpBackend, ConfigService],
      multi: true,
    },
    /* SERVICES */
    AnalyticsFilterService,
    ApplicationLoadService,
    ConfigService,
    AuthService,
    SessionService,
    EventService,
    LoggerService,
    FeatureFlaggingService,
    RoutingService,
    SeoService,
    SettingsService,
    TrackersService,
    UIService,
    EntitlementService,
    MediaUploadService,
    PermissionsService,
    OAuth2Service,
    UpgradeAdminService,
    TagEditorService,
    ImportService,
    /* OTHER LIBS SERVICES */
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import("highlight.js/lib/core"),
        languages: {
          javascript: () => import("highlight.js/lib/languages/javascript"),
          xml: () => import("highlight.js/lib/languages/xml"),
          kotlin: () => import("highlight.js/lib/languages/kotlin"),
          swift: () => import("highlight.js/lib/languages/swift"),
          csp: () => import("highlight.js/lib/languages/csp"),
        },
      },
    },
    /* OTHER SERVICES */
    LayoutBackHeaderService,
    /* GUARDS */
    IsNotAuthGuard,
    EnsureNotAuthGuard,
    GenericCanDeactivate,
    IsIntegrationSlugValidGuard,
    DoOrgHasSurveysGuard,
    OnBoardingGuard,
    CanCreateSuperOrgGuard,
    /* RESOLVERS */
    AssetIndustriesScoresResolver,
    SuperOrgWorkspacesResolver,
    ChannelsResolver,
    ChannelClientScreensResolver,
    ChannelClientVersionsResolver,
    ChannelsClientVersionsResolver,
    SurveyTagsResolver,
    SurveyResolver,
    SurveysResolver,
    SurveyDistributionResolver,
    SurveyScenarioNodeResolver,
    OrgTargetingRulesResolver,
    OrgReportResolver,
    RecordTargetingRulesResolver,
    OrgAccountsResolver,
    SuperOrgAccountsResolver,
    SuperOrgResolver,
    SuperOrgsResolver,
    OrgResolver,
    WorkspacesResolver,
    OrgBillingInvoicesResolver,
    OrgBillingPaymentMethodsResolver,
    OrgBillingResolver,
    OrgBillingSubscriptionResolver,
    UserResolver,
    UserRecordResolver,
    RegistryIdentityPropertyResolver,
    RegistryGroupResolver,
    RegistryEventsResolver,
    OrgIntegrationsResolver,
    OrgIntegrationOrNullResolver,
    IntegrationAtlassianProjectsResolver,
    SequenceResolver,
    SequencesResolver,
    OrgReleasesResolver,
    OrgIndustryStatsResolver,
    TagEditorTokenResolver,
    WeeklyBriefingResolver,
    /* DAO */
    AnalyticsDao,
    UserDao,
    UserRecordDao,
    ResponseDao,
    SurveyDao,
    SurveyDistributionDao,
    SurveyScenarioNodeDao,
    ChannelDao,
    SuperOrgDao,
    OrgDao,
    RegistryDao,
    AccountDao,
    IntegrationDao,
    TagSettingsDao,
    UploadDao,
    EmailDao,
    SequenceDao,
    ReleaseDao,
    GetFeedbackDao,
    TagEditorTokenDao,
    DebugDao,
    WeeklyBriefingDao,
    /* STORES */
    BuilderStore,
    /* HELPERS */
    ScreebApiHelper,
    NotificationHelper,
    /* UTILS */
    // page load error (first load)
    // commented in order to use sentry instead
    {
      provide: ErrorHandler,
      useClass: RoutingService,
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideCharts(withDefaultRegisterables()),
  ],
}).catch((err) => console.error(err));
