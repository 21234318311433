import { Component } from "@angular/core";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { NzIconDirective } from "ng-zorro-antd/icon";

@Component({
  selector: "page-internal-error",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"],
  imports: [
    NzButtonComponent,
    ɵNzTransitionPatchDirective,
    RouterLink,
    RouterLinkActive,
    NzIconDirective,
  ],
})
class InternalErrorPageComponent {
  public subtitle: string = "500 - Internal error";
  public text: string =
    "This error indicates that the server encountered an unexpected condition that prevented it from fulfilling the task.";

  constructor() {}
}

export { InternalErrorPageComponent };
