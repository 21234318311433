import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  getDay,
  nextFriday,
  setHours,
  startOfDay,
} from "date-fns";
import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { WeeklyBriefingDao } from "models/weekly-briefing.dao";
import { WeeklyBriefing } from "models/weekly-briefing.model";
import { NzModalService } from "ng-zorro-antd/modal";
import {
  NzTableSortFn,
  NzTableSortOrder,
  NzTableComponent,
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzThAddOnComponent,
  NzCellAlignDirective,
  NzCellFixedDirective,
  NzTbodyComponent,
} from "ng-zorro-antd/table";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { NgIf, NgFor } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { ScreebIconComponent } from "../utils/screeb-icon/screeb-icon.component";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { AddReceiverModalComponent } from "./add/add.component";
import { PermissionPipe } from "pipes/permission.pipe";

interface ColumnItem {
  name: string;
  sortKey?: string;
  width?: string;
  align: "left" | "right" | "center";
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null | true;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: "page-weekly-briefs-list",
  templateUrl: "./weekly-briefs.component.html",
  styleUrls: ["./weekly-briefs.component.scss"],
  imports: [
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    ScreebIconComponent,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NgFor,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzThAddOnComponent,
    NzCellAlignDirective,
    NzCellFixedDirective,
    NzTbodyComponent,
    NzIconDirective,
    AddReceiverModalComponent,
    PermissionPipe,
  ],
})
export class WeeklyBriefsListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Weekly Briefs";
  public name = "Weekly Briefs list";

  private obs: any = null;

  public receivers: WeeklyBriefing[] = [];

  public listOfColumns: ColumnItem[] = [
    {
      name: "Full Name",
      width: "250px",
      align: "center",
      sortOrder: "ascend",
      sortFn: (a: WeeklyBriefing, b: WeeklyBriefing) =>
        `${a.firstname} ${a.lastname}`
          .toLowerCase()
          .localeCompare(`${b.firstname} ${b.lastname}`.toLowerCase()),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Email Address",
      width: "250px",
      align: "center",
      sortOrder: "ascend",
      sortFn: (a: WeeklyBriefing, b: WeeklyBriefing) =>
        a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Role",
      width: "200px",
      align: "center",
      sortOrder: "ascend",
      sortFn: (a: WeeklyBriefing, b: WeeklyBriefing) =>
        (a.role || "").localeCompare(b.role || ""),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
  ];

  public workspace: Org = null;
  public editReceiver: WeeklyBriefing = null;
  public addOpened = false;

  public roles: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private weeklyBriefingDao: WeeklyBriefingDao,
    public permissionsService: PermissionsService,
    private notificationHelper: NotificationHelper,
    private modalService: NzModalService,
  ) {}

  @Input() public org: Org = null;

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.workspace = data.org;

      this.receivers = data.weeklyBriefings;

      this.refreshRoles();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public get nextBriefingRemainingTime(): string {
    const now = new Date();
    const nowStartOfDay = startOfDay(now);

    const nextFridayAt10AM =
      getDay(now) === 5 && now.getHours() < 10
        ? setHours(nowStartOfDay, 10)
        : setHours(nextFriday(now), 10);
    const nextFridayStartOfDay = startOfDay(nextFridayAt10AM);

    const days = differenceInDays(nextFridayStartOfDay, nowStartOfDay);
    const hours = differenceInHours(nextFridayAt10AM, now);
    const minutes = differenceInMinutes(nextFridayAt10AM, now);

    if (days > 1 || hours >= 24) {
      return `${days} day${days > 1 ? "s" : ""}`;
    } else if (hours > 1 || minutes >= 60) {
      return `${hours} hour${hours > 1 ? "s" : ""}`;
    } else {
      return `${minutes} minute${minutes > 1 ? "s" : ""}`;
    }
  }

  private refreshRoles() {
    this.roles = this.receivers
      .map((r) => r.role)
      .filter((r, i, a) => a.indexOf(r) === i);
  }

  public onAddWeeklyBriefing() {
    this.editReceiver = null;
    this.addOpened = true;
  }

  public onUpdateWeeklyBriefing(receiver: WeeklyBriefing) {
    this.editReceiver = receiver;
    this.addOpened = true;
  }

  public onClose() {
    this.addOpened = false;
  }

  public onBriefingUpdated(briefing: WeeklyBriefing) {
    if (this.editReceiver) {
      const index = this.receivers.findIndex((r) => r.id === briefing.id);
      if (index >= 0) {
        this.receivers[index] = briefing;
      }
    } else {
      this.receivers.push(briefing);
    }

    // This is necessary to trigger the change detection
    this.receivers = [...this.receivers];
    this.refreshRoles();
  }

  public onDeleteWeeklyBriefing(briefing: WeeklyBriefing) {
    new Promise((resolve) => {
      this.modalService.warning({
        nzTitle: "Do you want to delete this receiver?",
        // nzContent: msgPrefix + "<br>This operation cannot be undone.",
        nzStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
        },
        nzMaskClosable: true,
        nzCloseOnNavigation: false,
        nzOkType: "default",
        nzOkDanger: true,
        nzOkText: "Confirm",
        nzCancelText: "Cancel",
        nzOnOk: () => resolve(true),
        nzOnCancel: () => resolve(false),
      });
    })
      .then((remove) => {
        if (!remove) return;

        this.weeklyBriefingDao
          .deleteById(this.workspace.id, briefing.id)
          .then(() => {
            this.notificationHelper.trigger(
              "Receiver deleted",
              null,
              "success",
            );
          });

        this.receivers = this.receivers.filter((r) => r.id !== briefing.id);

        // This is necessary to trigger the change detection
        this.receivers = [...this.receivers];
        this.refreshRoles();
      })
      .catch(() => {
        this.notificationHelper.trigger(
          "Error deleting receiver",
          null,
          "error",
        );
      });
  }
}
