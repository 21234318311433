import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { OrgDao } from "models/org.dao";
import { Org, OrgStats } from "models/org.model";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { entriesObject } from "utils/object";
import { NgIf } from "@angular/common";
import { BenchmarkSummaryComponent } from "./components/benchmark-summary/benchmark-summary.component";
import { ScreebIconComponent } from "../utils/screeb-icon/screeb-icon.component";
import { MarkdownComponent } from "ngx-markdown";
import { TextShimmerComponent } from "../common/text-shimmer/text-shimmer.component";
import { Card101LinkComponent } from "../utils/card-101-link/card-101-link.component";

type ScoreType =
  | "NPS"
  | "CES"
  | "CSAT"
  | "RESPONSE_RATE"
  | "RESPONSE_COMPLETION_RATE";

type TopPerformer = Record<ScoreType, number>;

@Component({
  selector: "page-benchmark",
  templateUrl: "./benchmark.component.html",
  styleUrls: ["./benchmark.component.scss"],
  imports: [
    NgIf,
    BenchmarkSummaryComponent,
    ScreebIconComponent,
    MarkdownComponent,
    TextShimmerComponent,
    Card101LinkComponent,
  ],
})
export class BenchmarkPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Benchmark";
  public name = "Benchmark";

  private obs: any = null;

  private superOrgStats: OrgStats[];
  public industryStats: OrgStats;
  public topPerformers: TopPerformer = {
    NPS: 0,
    CES: 0,
    CSAT: 0,
    RESPONSE_RATE: 0,
    RESPONSE_COMPLETION_RATE: 0,
  };

  public analytic: string = "";

  public ready: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public permissionsService: PermissionsService,
    private orgDao: OrgDao,
  ) {}

  @Input() public org: Org = null;

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];

      this.superOrgStats = data["orgIndustrisStats"];

      this.computeStats();

      this.orgDao.getIndustryAnalyze(this.org.id).then((analytic) => {
        this.analytic = analytic;
      });
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  private computeStats() {
    const emptyStats = new OrgStats().fromJson({
      total_response_displayed: 0,

      total_nps_score: 0,
      total_average_nps_promoters: 0,
      total_average_nps_passives: 0,
      total_average_nps_detractors: 0,
      total_nps_score_repartition: {},

      total_ces_score: 0,
      total_average_ces_high_effort: 0,
      total_average_ces_medium_effort: 0,
      total_average_ces_low_effort: 0,
      total_ces_score_repartition: {},

      total_csat_score: 0,
      total_average_csat_low_score: 0,
      total_csat_score_repartition: {},

      total_response_not_started: 0,
      total_response_partially_completed: 0,
      total_response_fully_completed: 0,

      total_survey_response_started_rate: 0,
      total_survey_response_not_started_rate: 0,
      total_survey_response_partially_completed_rate: 0,
      total_survey_response_fully_completed_rate: 0,

      total_survey_responses: 0,
    });

    const stats = this.superOrgStats.reduce((prev, curr) => {
      entriesObject(curr?.total_nps_score_repartition || []).map((entry) => {
        if (!prev.total_nps_score_repartition[entry[0]]) {
          prev.total_nps_score_repartition[entry[0]] = entry[1];
        }
        prev.total_nps_score_repartition[entry[0]] += entry[1];
      });

      Object.entries(curr?.total_ces_score_repartition || []).map((entry) => {
        if (!prev.total_ces_score_repartition[entry[0]]) {
          prev.total_ces_score_repartition[entry[0]] = entry[1];
        }
        prev.total_ces_score_repartition[entry[0]] += entry[1];
      });

      Object.entries(curr?.total_csat_score_repartition || []).map((entry) => {
        if (!prev.total_csat_score_repartition[entry[0]]) {
          prev.total_csat_score_repartition[entry[0]] = entry[1];
        }
        prev.total_csat_score_repartition[entry[0]] += entry[1];
      });

      prev.total_survey_responses += curr?.total_survey_responses || 0;
      prev.total_response_not_started += curr?.total_response_not_started || 0;
      prev.total_response_partially_completed +=
        curr?.total_response_partially_completed || 0;
      prev.total_response_fully_completed +=
        curr?.total_response_fully_completed || 0;

      return prev;
    }, emptyStats);

    const nps = this.calculateNPSByScoreRepartition(
      stats.total_nps_score_repartition || {},
    );

    stats.total_nps_score = nps.nps;
    stats.total_average_nps_promoters = nps.promotersRate;
    stats.total_average_nps_passives = nps.passivesRate;
    stats.total_average_nps_detractors = nps.detractorsRate;

    const ces = this.calculateCESByScoreRepartition(
      stats.total_ces_score_repartition || {},
    );

    stats.total_ces_score = ces.ces;
    stats.total_average_ces_high_effort = ces.highEffortRate;
    stats.total_average_ces_medium_effort = ces.mediumEffortRate;
    stats.total_average_ces_low_effort = ces.lowEffortRate;

    const csat = this.calculateCSATByScoreRepartition(
      stats.total_csat_score_repartition || {},
    );

    stats.total_csat_score = csat.csat;
    stats.total_average_csat_low_score = csat.lowScoreRate;

    if (stats.total_survey_responses === 0) {
      stats.total_survey_response_started_rate = 0;
      stats.total_survey_response_not_started_rate = 0;
      stats.total_survey_response_partially_completed_rate = 0;
      stats.total_survey_response_fully_completed_rate = 0;
    } else {
      stats.total_survey_response_started_rate =
        ((stats.total_response_partially_completed +
          stats.total_response_fully_completed) /
          stats.total_survey_responses) *
        100;
      stats.total_survey_response_not_started_rate =
        (stats.total_response_not_started / stats.total_survey_responses) * 100;
      stats.total_survey_response_partially_completed_rate =
        (stats.total_response_partially_completed /
          stats.total_survey_responses) *
        100;
      stats.total_survey_response_fully_completed_rate =
        (stats.total_response_fully_completed / stats.total_survey_responses) *
        100;
    }

    this.industryStats = stats;

    // find top performers

    const topPerformers: TopPerformer = {
      NPS: this.org.stats.total_nps_score,
      CES: this.org.stats.total_ces_score,
      CSAT: this.org.stats.total_csat_score,
      RESPONSE_RATE: this.org.stats.total_survey_response_started_rate,
      RESPONSE_COMPLETION_RATE:
        this.org.stats.total_survey_response_fully_completed_rate,
    };

    this.superOrgStats.forEach((orgStats) => {
      if (
        orgStats.total_nps_score > topPerformers.NPS &&
        orgStats.total_nps_score < 100
      ) {
        topPerformers.NPS = orgStats.total_nps_score;
      }
      if (
        orgStats.total_ces_score > topPerformers.CES &&
        orgStats.total_ces_score < 7
      ) {
        topPerformers.CES = orgStats.total_ces_score;
      }
      if (
        orgStats.total_csat_score > topPerformers.CSAT &&
        orgStats.total_csat_score < 5
      ) {
        topPerformers.CSAT = orgStats.total_csat_score;
      }
      if (
        orgStats.total_survey_response_started_rate >
          topPerformers.RESPONSE_RATE &&
        orgStats.total_survey_response_started_rate < 100
      ) {
        topPerformers.RESPONSE_RATE =
          orgStats.total_survey_response_started_rate;
      }
      if (
        orgStats.total_survey_response_fully_completed_rate >
          topPerformers.RESPONSE_COMPLETION_RATE &&
        orgStats.total_survey_response_fully_completed_rate < 100
      ) {
        topPerformers.RESPONSE_COMPLETION_RATE =
          orgStats.total_survey_response_fully_completed_rate;
      }
    });

    this.topPerformers = topPerformers;

    this.ready = true;
  }

  private calculateNPSByScoreRepartition(
    values: { [key: number]: number } = {},
  ) {
    const totalNPS = entriesObject(values).reduce((prev, curr) => {
      prev += curr[0] * curr[1];
      return prev;
    }, 0);

    if (totalNPS === 0) {
      return {
        nps: 0,
        passivesRate: 0,
        promotersRate: 0,
        detractorsRate: 0,
      };
    }

    const passivesRate =
      (entriesObject(values).reduce((prev, curr) => {
        if (curr[0] >= 7 && curr[0] <= 8) {
          prev += curr[0] * curr[1];
        }
        return prev;
      }, 0) /
        totalNPS) *
      100;

    const promotersRate =
      (entriesObject(values).reduce((prev, curr) => {
        if (curr[0] >= 9 && curr[0] <= 10) {
          prev += curr[0] * curr[1];
        }
        return prev;
      }, 0) /
        totalNPS) *
      100;

    const detractorsRate =
      (entriesObject(values).reduce((prev, curr) => {
        if (curr[0] >= 0 && curr[0] <= 6) {
          prev += curr[0] * curr[1];
        }
        return prev;
      }, 0) /
        totalNPS) *
      100;

    const nps = Math.round(promotersRate - detractorsRate);

    return {
      nps,
      passivesRate,
      promotersRate,
      detractorsRate,
    };
  }

  private calculateCESByScoreRepartition(
    values: { [key: number]: number } = {},
  ) {
    const totalCES = entriesObject(values).reduce((prev, curr) => {
      prev += curr[0] * curr[1];
      return prev;
    }, 0);
    const answerLength = Object.values(values).reduce((prev, curr) => {
      prev += curr;
      return prev;
    }, 0);

    if (answerLength === 0) {
      return {
        ces: 0,
        highEffortRate: 0,
        mediumEffortRate: 0,
        lowEffortRate: 0,
      };
    }

    const highEffortRate =
      (entriesObject(values).reduce((prev, curr) => {
        if (curr[0] >= 7) {
          prev += curr[1];
        }
        return prev;
      }, 0) /
        totalCES) *
      100;

    const mediumEffortRate =
      (entriesObject(values).reduce((prev, curr) => {
        if (curr[0] >= 4 && curr[0] <= 6) {
          prev += curr[1];
        }
        return prev;
      }, 0) /
        totalCES) *
      100;

    const lowEffortRate =
      (entriesObject(values).reduce((prev, curr) => {
        if (curr[0] >= 0 && curr[0] <= 3) {
          prev += curr[1];
        }
        return prev;
      }, 0) /
        totalCES) *
      100;

    const ces = totalCES / answerLength;

    return {
      ces,
      highEffortRate,
      mediumEffortRate,
      lowEffortRate,
    };
  }

  private calculateCSATByScoreRepartition(
    values: { [key: number]: number } = {},
  ) {
    const totalCSAT = entriesObject(values).reduce((prev, curr) => {
      prev += curr[0] * curr[1];
      return prev;
    }, 0);
    const answerLength = Object.values(values).reduce((prev, curr) => {
      prev += curr;
      return prev;
    }, 0);

    if (answerLength === 0) {
      return {
        csat: 0,
        lowScoreRate: 0,
      };
    }

    const lowScoreRate =
      (entriesObject(values).reduce((prev, curr) => {
        if (curr[0] >= 0 && curr[0] <= 2) {
          prev += curr[1];
        }
        return prev;
      }, 0) /
        totalCSAT) *
      100;

    const csat = totalCSAT / answerLength;

    return {
      csat,
      lowScoreRate,
    };
  }
}
