/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-output-rename */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { integrationUpdate } from "components/integration/settings/integration-settings.component";
import { hookIntegrationsSpec } from "models/hook.model";

import {
  Integration,
  IntegrationSettings,
  IntegrationSettingsStitch,
  IntegrationSettingsStitchItem,
} from "models/integrations.model";
import { Org } from "models/org.model";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";
import { NgIf, NgFor, NgClass } from "@angular/common";
import {
  NzTableComponent,
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzTbodyComponent,
} from "ng-zorro-antd/table";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { NzSwitchComponent } from "ng-zorro-antd/switch";
import { FormsModule } from "@angular/forms";
import { IntegrationSettingsStitchEditComponent } from "./edit/stitch-settings-edit.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";

@Component({
  selector: "integration-settings-stitch",
  templateUrl: "./stitch-settings.component.html",
  styleUrls: ["./stitch-settings.component.scss"],
  imports: [
    NgIf,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzTbodyComponent,
    NgFor,
    NgClass,
    NzTagComponent,
    NzSwitchComponent,
    FormsModule,
    IntegrationSettingsStitchEditComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
  ],
})
export class IntegrationSettingsStitchComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public integration: Integration = null;

  @Output("onSettingsChange") public settings =
    new EventEmitter<integrationUpdate>();

  private currentlyExpandedItem: number = -1;
  private newItemId: string = null;

  constructor(private trackersService: TrackersService) {}

  ngOnInit() {
    if (this.integration.settings.stitch.items.length === 0) this.onItemAdd();
  }

  public pruneNewItems() {
    this.integration.settings.stitch.items =
      this.integration.settings.stitch.items.filter(
        (item) => item.id !== this.newItemId,
      );
  }

  public onItemAdd() {
    if (!this.integration.settings)
      this.integration.settings = new IntegrationSettings();
    if (!this.integration.settings.stitch)
      this.integration.settings.stitch = IntegrationSettingsStitch.New();

    this.pruneNewItems();

    const newItem = IntegrationSettingsStitchItem.New(
      hookIntegrationsSpec.stitch.currentVersion,
    );
    this.integration.settings.stitch.items.push(newItem);
    this.integration.settings.stitch.items = [].concat(
      ...this.integration.settings.stitch.items,
    ); // triggers @Input() refresh

    this.expandItem(this.integration.settings.stitch.items.length - 1);

    this.newItemId = newItem.id; // mark item as new, in order to remove it if we cancel edition
  }

  public onItemSaved(index: number, item: IntegrationSettingsStitchItem) {
    const createMode =
      this.integration.settings.stitch.items[index].id === this.newItemId;
    if (createMode) this.newItemId = null;

    // We don't override 'enabled' param here
    this.integration.settings.stitch.items[index].id = item.id;
    this.integration.settings.stitch.items[index].version = item.version;
    this.integration.settings.stitch.items[index].name = item.name;
    this.integration.settings.stitch.items[index].path = item.path;
    this.integration.settings.stitch.items[index].hook = item.hook;

    this.propagateItemChange();
    this.noExpand();

    this.trackChange(
      createMode
        ? "Integration Stitch hook added"
        : "Integration Stitch hook updated",
      item,
    );
  }

  public onItemStatusChange(index: number) {
    this.trackChange(
      this.integration.settings.stitch.items[index].enabled
        ? "Integration Stitch hook disabled"
        : "Integration Stitch hook enabled",
      this.integration.settings.stitch.items[index],
    );

    this.integration.settings.stitch.items[index].enabled =
      !this.integration.settings.stitch.items[index].enabled;
    this.propagateItemChange();
  }

  public onItemCanceled() {
    this.noExpand();
  }

  public onItemRemoved(index: number) {
    this.noExpand();

    this.integration.settings.stitch.items.splice(index, 1);
    this.integration.settings.stitch.items = [].concat(
      ...this.integration.settings.stitch.items,
    ); // triggers @Input() refresh

    this.propagateItemChange();

    this.trackChange("Integration Stitch hook removed", null);
  }

  private propagateItemChange() {
    this.pruneNewItems();
    this.settings.emit({ settings: this.integration.settings });
  }

  private trackChange(
    eventName: TrackingEventName,
    item?: IntegrationSettingsStitchItem,
  ) {
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withIntegration(this.integration)
      .withProps({
        hook_item_id: item?.id,
        hook_item_version: item?.version,
        hook_item_enabled: item?.enabled,
        hook_item_name: item?.name,
        hook_item_path: item?.path,
        hook_item_hook: item?.hook,
      })
      .build();
  }

  /**
   * Collapsible
   */
  public isItemExpanded(i: number): boolean {
    return this.currentlyExpandedItem === i;
  }

  public expandItem(i: number) {
    if (this.currentlyExpandedItem !== -1) this.pruneNewItems();

    this.currentlyExpandedItem = this.isItemExpanded(i) ? -1 : i;
  }

  public noExpand() {
    this.currentlyExpandedItem = -1;
    this.pruneNewItems();
  }
}
