<section class="page-content">
  <section class="header">
    <h1>Weekly Briefs</h1>

    <div class="indicators">
      <div class="card briefing-description">
        <p>
          Keep your entire organization aligned with your product's evolution
          through automated weekly email updates. Weekly Brief compiles the most
          relevant user insights, trends, and actionable recommendations,
          delivering them directly to your team's inbox. By sharing these
          tailored insights across departments, from Product to Marketing and
          Sales, you'll foster data-driven decisions and build a more
          user-centric culture throughout your company.
        </p>
      </div>

      <div class="card countdown">
        <h3 class="title">Next Brefing in:</h3>
        <div class="trigger-counter-inner">
          <div class="counter">
            <h2>{{ nextBriefingRemainingTime }}</h2>
          </div>
          <p class="note">Briefing sent every Friday at 10AM UTC</p>
        </div>
      </div>
    </div>
  </section>

  <section class="content iAppear d100">
    <div class="filters-bar">
      <div class="left"></div>
      <div class="right">
        <button
          *ngIf="'weekly-briefing.create' | hasPermission"
          title="Add a new receiver"
          nz-button
          nzType="primary"
          nzSize="large"
          (click)="onAddWeeklyBriefing()"
        >
          <screeb-icon size="sm" icon="plus"></screeb-icon>
          <span>Add a new receiver</span>
        </button>
      </div>
    </div>

    <div class="weekly-briefing-table-group">
      <nz-table
        class="weekly-briefing-table"
        #table
        [nzData]="receivers"
        [nzPageSize]="10"
        nzNoResult="No receivers found"
        [nzShowSizeChanger]="true"
        [nzFrontPagination]="true"
        [nzHideOnSinglePage]="true"
        [nzPageSizeOptions]="[10, 25, 100]"
        [nzScroll]="{ x: '1100px' }"
      >
        <thead>
          <tr>
            <th
              *ngFor="let column of listOfColumns"
              [(nzSortOrder)]="column.sortOrder"
              [nzSortFn]="column.sortFn"
              [nzSortDirections]="column.sortDirections"
              [nzFilterMultiple]="column.filterMultiple"
              [nzColumnKey]="column.sortKey"
              [nzWidth]="column.width"
              [nzAlign]="column.align"
            >
              {{ column.name }}
            </th>
            <th [nzWidth]="'140px'" nzRight></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let receiver of table.data">
            <!--
            HACK: using (click), we cannot right-click on a row and select "open in a new table".
            So we added a "<a>" html tag between tbody and tr.
          -->
            <tr>
              <td nzAlign="center">
                {{ receiver.firstname }} {{ receiver.lastname }}
              </td>
              <td nzAlign="center">
                {{ receiver.email }}
              </td>
              <td nzAlign="center">
                {{ receiver.role }}
              </td>
              <td nzAlign="right" class="actions" nzRight>
                <div>
                  <button
                    *ngIf="'weekly-briefing.update' | hasPermission"
                    title="Edit receiver"
                    nz-button
                    nzSize="large"
                    nzType="primary"
                    (click)="onUpdateWeeklyBriefing(receiver)"
                  >
                    <img
                      alt="Edit"
                      nz-icon
                      src="/assets/icons/homepage/pen.svg"
                    />
                  </button>
                  <button
                    *ngIf="'weekly-briefing.delete' | hasPermission"
                    title="Remove member"
                    nz-button
                    nzType="default"
                    nzSize="large"
                    nzDanger
                    (click)="onDeleteWeeklyBriefing(receiver)"
                  >
                    <i nz-icon nzType="delete"></i>
                  </button>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </section>
</section>

<add-receiver-modal
  [roles]="roles"
  [isOpen]="addOpened"
  (modalOpenChange)="onClose()"
  (briefingUpdated)="onBriefingUpdated($event)"
  [workspace]="workspace"
  [receiver]="editReceiver"
/>
