import { Pipe, PipeTransform } from "@angular/core";
import { FeatureFlaggingService } from "services/feature-flagging.service";

@Pipe({ name: "hasFeatureFlag" })
export class FeaturePipe implements PipeTransform {
  constructor(private featureFlaggingService: FeatureFlaggingService) {}

  transform(value: string): boolean {
    if (this.featureFlaggingService[value]) {
      return this.featureFlaggingService[value]();
    }
    return false;
  }
}
