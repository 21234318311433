import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";

import { NotificationHelper } from "helpers/notification.helper";
import { OrgDao } from "models/org.dao";
import {
  GridRangeNames,
  Org,
  OrgFlagsHomeGridCell,
  OrgFlagsHomeGridCellType,
  OrgFlagsHomeGridRange,
  OrgReport,
} from "models/org.model";
import { Survey } from "models/survey.model";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { SessionService } from "services/auth.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { UIService } from "services/ui.service";
import { WidgetSpec, widgetsSpecs } from "./widget-specs";
import Muuri from "muuri";
import Grid from "muuri";
import { Account } from "models/account.model";
import { CheckboxSelectItem } from "components/utils/checkbox-select/checkbox-select.component";
import { KPIKey } from "models/analytics.kpis";
import { uuidv4 } from "utils/uuid";
import { FreeTrialBannerComponent } from "../../super-org/billing/banners/free-trial-banner.component";
import { OrgAccountAvatarComponent } from "../../utils/org-account-avatar/org-account-avatar.component";
import { OrgAccountAvatarListComponent } from "../../utils/org-account-avatar-list/org-account-avatar-list.component";
import { NgIf, NgClass, NgFor, NgStyle } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { ScreebIconComponent } from "../../utils/screeb-icon/screeb-icon.component";
import {
  NzDropdownButtonDirective,
  NzDropDownDirective,
  NzDropdownMenuComponent,
} from "ng-zorro-antd/dropdown";
import { NzMenuDirective, NzMenuItemComponent } from "ng-zorro-antd/menu";
import { WidgetWrapperComponent } from "../../home/widgets/widget-wrapper.component";
import { UtilsLateralPanelComponent } from "../../utils/lateral-panel/lateral-panel.component";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { CheckboxSelectComponent } from "../../utils/checkbox-select/checkbox-select.component";
import { SuperPermissionPipe } from "pipes/super-permission.pipe";

@Component({
  selector: "page-org-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss", "./dashboard.scss"],
  imports: [
    FreeTrialBannerComponent,
    OrgAccountAvatarComponent,
    OrgAccountAvatarListComponent,
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    ScreebIconComponent,
    NzDropdownButtonDirective,
    NzDropDownDirective,
    NzDropdownMenuComponent,
    NzMenuDirective,
    NzMenuItemComponent,
    NgClass,
    NgFor,
    NgStyle,
    WidgetWrapperComponent,
    UtilsLateralPanelComponent,
    NzIconDirective,
    CheckboxSelectComponent,
    SuperPermissionPipe,
  ],
})
export class OverviewOrgPageComponent
  implements
    PageComponentInterface,
    OnInit,
    OnDestroy,
    AfterViewInit,
    AfterViewChecked
{
  public title = "Workspace overview";
  public name = "Workspace overview";

  private obs: any = null;

  public error: Error;

  public org: Org = null;
  public orgAccounts: Account[] = [];
  public helloMessage: string = "";

  public editingDashboard = false;
  public isAddWidgetOpen = false;

  public widgetsSpecs = widgetsSpecs;

  public orgHasRespondents = true;
  public surveys: Survey[] = [];

  public focusedType: WidgetSpec = null;

  public orgReport: OrgReport = null;

  public gridRangeNames = GridRangeNames;

  public items: {
    id: string;
    type: OrgFlagsHomeGridCellType;
    w: string;
    h: string;
    text: string | undefined;
  }[] = [];
  public tmpItems: {
    id: string;
    type: OrgFlagsHomeGridCellType;
    w: string;
    h: string;
    text: string | undefined;
  }[] = [];

  public gridVisible: boolean = false;

  public checkboxItems: CheckboxSelectItem[] = widgetsSpecs.filter((i) => {
    return i.type !== "free_text_block";
  });

  public checkboxSelectedItems: CheckboxSelectItem["key"][] = [];

  grid: Grid;
  dragEnabled = false;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public sessionService: SessionService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    private orgDao: OrgDao,
    private notificationHelper: NotificationHelper,
    public uiService: UIService,
    private analyticsFilterService: AnalyticsFilterService,
  ) {}

  ngAfterViewInit() {
    this.instantiateGrid();
    setTimeout(() => {
      this.gridVisible = true;
    }, 150);
  }

  ngAfterViewChecked() {
    if (this.grid) {
      this.grid.refreshItems();
      this.grid.layout();
    } else {
      this.instantiateGrid();
      setTimeout(() => {
        this.gridVisible = true;
      }, 300);
    }
  }

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.helloMessage = this.getHelloMessage();

    this.obs = this.route.data.subscribe(async (data) => {
      this.grid?.destroy();
      this.grid = null;

      this.org = data.org;
      this.orgAccounts = data.orgAccounts;
      this.surveys = data.surveys;

      this.getStats();

      this.focusedType = this.widgetsSpecs.find(
        (w) => !this.isItemAlreadyAdded(w.type),
      );

      this.orgReport = data.orgReports.find(
        (report) => report.report_type === "home",
      );
      const cells = this.orgReport?.widgets;
      if (!cells.length) {
        this.setDefaultHomeGrid();
      } else {
        this.items = cells.map((cell) => {
          const spec = this.widgetsSpecs.find((w) => w.type === cell.type);
          return {
            id: cell.id,
            type: cell.type,
            w: spec?.width,
            h: spec?.height,
            text: cell.text,
          };
        });
      }

      this.checkboxSelectedItems = this.items.map((i) => i.type);
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  private setDefaultHomeGrid() {
    const cells = [
      {
        type: "help",
        width: "full",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      {
        type: "quote_of_the_day",
        width: "small",
        height: "265px",
      } as OrgFlagsHomeGridCell,
      {
        type: "nps",
        width: "small",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      {
        type: "mau",
        width: "small",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      // {
      //   type: "entitlement_mtu",
      //   width: 4,
      //   height: 2,
      // } as OrgFlagsHomeGridCell,
      {
        type: "activity",
        width: "full",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      {
        type: "device_type",
        width: "medium",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      {
        type: "identification",
        width: "medium",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      {
        type: "emotions",
        width: "small",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      {
        type: "changelog",
        width: "small",
        height: "265px",
      } as OrgFlagsHomeGridCell,
      {
        type: "last_surveys",
        width: "small",
        height: undefined,
      } as OrgFlagsHomeGridCell,
      {
        type: "entitlement_mtu",
        width: "small",
        height: undefined,
      } as OrgFlagsHomeGridCell,
    ];
    this.items = cells.map((cell) => {
      const spec = this.widgetsSpecs.find((w) => w.type === cell.type);
      return {
        id: uuidv4(),
        type: cell.type,
        w: spec.width,
        h: spec.height,
        text: cell.text,
      };
    });
  }

  private async getStats() {
    this.error = null;

    try {
      this.orgHasRespondents =
        this.org.stats.total_respondents > 0 ||
        (await this.analyticsFilterService.workspaceHasUsers(this.org.id));
    } catch (err) {
      this.error = err;
      console.error(err);
    }
  }

  private getHelloMessage = () => {
    const hours = new Date().getHours();

    if (hours >= 18 || hours < 5) {
      return "Good evening";
    } else if (hours >= 5 && hours < 12) {
      return "Good morning";
    } else {
      return "Good afternoon";
    }
  };

  public setEditingDashboard() {
    this.editingDashboard = !this.editingDashboard;

    this.gridVisible = false;
    setTimeout(() => {
      if (this.editingDashboard) {
        this.grid.destroy();
        this.dragEnabled = true;
        this.instantiateGrid();
        this.tmpItems = [...this.items];
      } else {
        this.items = [...this.tmpItems];
        this.tmpItems = [];
        this.grid.destroy();
        this.dragEnabled = false;
        this.instantiateGrid();
      }
      this.gridVisible = true;
    }, 300);
  }

  public resetHomeGrid() {
    this.gridVisible = false;
    setTimeout(() => {
      this.items = [];
      this.setDefaultHomeGrid();
      this.onSave(true, true);
      this.dragEnabled = false;
    }, 300);
  }

  public changeRange(range: OrgFlagsHomeGridRange) {
    this.orgReport.range = range;

    if (this.permissionsService.isSuperAllowed("org.update")) {
      this.onSave(false);
    }
  }

  public onSave(notify = true, reset = false) {
    this.editingDashboard = false;

    if (!reset) {
      const gridItems = this.grid.getItems().map((gridItem) => {
        const id = gridItem.getElement().id as OrgFlagsHomeGridCellType;
        const item = this.items.find((item) => item.id === id);
        if (!item) {
          return {
            type: id,
          };
        }
        return {
          id: id,
          type: item?.type ?? id,
        };
      });

      this.items.sort((a, b) => {
        const indexA = gridItems.findIndex((v) => {
          if (v.id) {
            return v.id === a.id;
          }
          return v.type === a.type;
        });
        const indexB = gridItems.findIndex((v) => {
          if (v.id) {
            return v.id === b.id;
          }
          return v.type === b.type;
        });

        return indexA - indexB;
      });
    }

    const itemsCell = this.items.map((item) => {
      return {
        type: item.type,
        text: item.text,
        id: item.id === "" ? uuidv4() : item.id,
      };
    });

    this.dragEnabled = false;
    this.grid.destroy();
    this.grid = undefined;
    this.gridVisible = false;

    this.orgDao
      .updateReport(
        this.org.id,
        this.orgReport.id,
        itemsCell as OrgFlagsHomeGridCell[],
        this.orgReport.range,
      )
      .then(() => {
        if (notify) {
          this.notificationHelper.trigger(
            "Success",
            "Dashboard updated",
            "success",
          );
        }
      })
      .catch((err) => {
        console.error(err);
        if (notify) {
          this.notificationHelper.trigger(
            "Error",
            "Error while saving the dashboard. Please try again later.",
            "error",
          );
        }
      });
  }

  public removeWidget(widgetId: string) {
    const gridItems = this.grid.getItems();

    this.grid.remove(gridItems.filter((i) => i.getElement().id === widgetId));

    this.items = this.items.filter((w) => w.id !== widgetId);
  }

  public isItemAlreadyAdded(type: string): boolean {
    return this.items.some((w) => w.type === type);
  }

  public instantiateGrid() {
    this.grid = new Muuri(".grid", {
      dragEnabled: this.dragEnabled,
      layout: { fillGaps: false },
    });
  }

  public onReportChange($event: KPIKey[]) {
    this.checkboxSelectedItems = $event;
  }

  public onCheckboxConfirm() {
    this.gridVisible = false;
    setTimeout(() => {
      this.items = this.items.filter((item) => {
        return (
          this.checkboxSelectedItems.findIndex((i) => i === item.type) !== -1
        );
      });

      const tmpItems = this.checkboxSelectedItems
        .filter(
          (selectedItem) =>
            this.items.findIndex((i) => i.type === selectedItem) === -1,
        )
        .map((selectedItem) => {
          const spec = this.widgetsSpecs.find((w) => w.type === selectedItem);
          return {
            id: uuidv4(),
            type: selectedItem as OrgFlagsHomeGridCellType,
            w: spec.width,
            h: spec.height,
            text: undefined,
          };
        });

      this.items.push(...tmpItems);

      this.isAddWidgetOpen = false;
      this.grid.destroy();
      this.grid = undefined;
    }, 20);
  }

  public AddTextWidget() {
    this.isAddWidgetOpen = false;
    this.items.push({
      id: uuidv4(),
      w: "full",
      h: undefined,
      type: "free_text_block" as OrgFlagsHomeGridCellType,
      text: "Enter your text",
    });
    this.grid.destroy();
    this.grid = undefined;
  }

  public onWidgetChange(
    index: number,
    $event: {
      id: string;
      type: string;
      w: string;
      h: string;
      text: string | undefined;
    },
  ) {
    this.items[index] = $event as {
      id: string;
      type: OrgFlagsHomeGridCellType;
      w: string;
      h: string;
      text: string | undefined;
    };
  }
}
