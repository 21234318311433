/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-output-rename */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { integrationUpdate } from "components/integration/settings/integration-settings.component";

import { autoTips } from "helpers/form-errors.helper";
import { Integration, IntegrationSettings } from "models/integrations.model";
import { Org } from "models/org.model";
import { NgIf } from "@angular/common";
import {
  NzFormDirective,
  NzFormItemComponent,
  NzFormControlComponent,
} from "ng-zorro-antd/form";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { NzInputGroupComponent, NzInputDirective } from "ng-zorro-antd/input";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";

@Component({
  selector: "integration-settings-monday",
  templateUrl: "./monday-settings.component.html",
  styleUrls: ["./monday-settings.component.scss"],
  imports: [
    NgIf,
    FormsModule,
    NzFormDirective,
    ReactiveFormsModule,
    NzRowDirective,
    NzFormItemComponent,
    NzColDirective,
    NzFormControlComponent,
    NzInputGroupComponent,
    ɵNzTransitionPatchDirective,
    NzInputDirective,
  ],
})
export class IntegrationSettingsMondayComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public integration: Integration = null;

  @Output() public valid = new EventEmitter<boolean>();
  @Output("onSettingsChange") public settings =
    new EventEmitter<integrationUpdate>();

  public validateForm: FormGroup = null;
  public autoTips = autoTips;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.validateForm = this.formBuilder.group({
      email: [
        this.integration?.settings?.monday?.email,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(/^.*@.*\.monday\.com$/),
        ],
      ],
    });
    this.validateSettings();

    this.validateForm.valueChanges.subscribe(() => {
      this.validateSettings();
    });
  }

  public validateSettings() {
    this.valid.emit(this.validateForm.valid);
    this.settings.emit({
      settings: {
        monday: {
          email: this.validateForm.value["email"],
        },
      } as IntegrationSettings,
    });
  }
}
