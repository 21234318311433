import { Component, Input, TemplateRef } from "@angular/core";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NgTemplateOutlet } from "@angular/common";

type PopoverPlacment =
  | "top"
  | "left"
  | "right"
  | "bottom"
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight"
  | "leftTop"
  | "leftBottom"
  | "rightTop"
  | "rightBottom";

@Component({
  selector: "help-popover",
  templateUrl: "./help-popover.component.html",
  styleUrls: ["./help-popover.component.scss"],
  imports: [
    NzPopoverDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NgTemplateOutlet,
  ],
})
export class HelpPopoverComponent {
  @Input() public placement: PopoverPlacment = "top";
  @Input() content: TemplateRef<any>;

  constructor() {}
}
