/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  StripeElementsOptions,
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";

import { ENV } from "environment";
import { NotificationHelper } from "helpers/notification.helper";
import { OrgBilling, OrgBillingPaymentMethods } from "models/org_billing.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";
import { StripePaymentElementComponent, StripeService } from "ngx-stripe";
import { TrackersService } from "services/trackers.service";
import { NgIf } from "@angular/common";
import { SettingsBillingTestingPaymentMethodHelperComponent } from "../../components/testing-payment-method-helper.component";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";

@Component({
  selector: "billing-lateral-panel-payment-method",
  template: `
    <billing-testing-payment-method-helper
      *ngIf="isDevMode"
    ></billing-testing-payment-method-helper>

    <div *ngIf="setupIntentLoading || !isStripeReady">
      <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
    </div>
    <div *ngIf="!setupIntentLoading && isStripeReady">
      <ngx-stripe-payment
        [options]="options"
        [elementsOptions]="elementsOptions"
        (change)="onStripePaymentInputChange($event)"
      ></ngx-stripe-payment>
    </div>

    <p class="payment-message next-step" *ngIf="hasNextStep">
      You’ll be able to review your subscription one more time at the next step.
    </p>

    <footer class="panel-button-container">
      <button
        class="cancel-btn"
        nz-button
        nzType="default"
        nzSize="large"
        (click)="onClose.emit($event)"
      >
        Cancel
      </button>

      <button
        nz-button
        nzType="primary"
        nzSize="large"
        [nzLoading]="validationLoading"
        [disabled]="!paymentMethodIsValid"
        (click)="savePaymentMethod()"
      >
        {{ hasNextStep ? "Next" : "Save payment method" }}
      </button>
    </footer>
  `,
  styles: [
    `
      nz-spin {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
      }

      .payment-message {
        margin-top: 20px;
        margin-bottom: 0;
      }

      .payment-message .next-step {
        font-size: 16px;
      }
    `,
  ],
  styleUrls: ["../footer.component.scss"],
  imports: [
    NgIf,
    SettingsBillingTestingPaymentMethodHelperComponent,
    NzSpinComponent,
    StripePaymentElementComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
  ],
})
export class SettingsBillingLateralPanelPaymentMethodComponent
  implements OnInit
{
  @Input() superOrg: SuperOrg = null;
  @Input() orgBilling: OrgBilling = null;
  @Input() orgBillingPaymentMethods: OrgBillingPaymentMethods = null;
  @Input() hasNextStep: boolean = false;

  @Output() orgBillingPaymentMethodsChange =
    new EventEmitter<OrgBillingPaymentMethods>();
  @Output() onClose = new EventEmitter<boolean>();

  @ViewChild(StripePaymentElementComponent)
  public paymentElement: StripePaymentElementComponent;
  public elementsOptions: StripeElementsOptions = {};
  public options: StripePaymentElementOptions = {};
  public setupIntentLoading: boolean = false;
  public paymentMethodIsValid: boolean = false;

  public validationLoading: boolean = false;

  constructor(
    private notificationHelper: NotificationHelper,
    private superOrgDao: SuperOrgDao,
    private trackersService: TrackersService,
    private stripeService: StripeService,
  ) {}

  ngOnInit(): void {
    this.elementsOptions.clientSecret = null;
    this.options = {};

    this.initModePaymentMethod();
  }

  private async initModePaymentMethod() {
    this.options = {
      business: { name: this.superOrg.name },
      fields: {
        billingDetails: {
          name: "auto",
          email: "auto",
          // address: "never",
        },
      },

      terms: {
        card: "always",
        sepaDebit: "always", // needed, because it shows to customer the payment mandate will be accepted automatically
      },
    };
    this.elementsOptions.appearance = {
      theme: "flat",
      variables: {
        colorPrimary: "#5E21F1",
      },
    };
    this.paymentMethodIsValid = false;
    await this.getSetupIntentClientSecret();
  }

  public getSetupIntentClientSecret() {
    this.setupIntentLoading = true;

    this.superOrgDao
      .newPaymentSetupIntentBySuperOrgId(this.superOrg.id)
      .then((response) => {
        this.elementsOptions.clientSecret = response.intent_client_secret;
        this.setupIntentLoading = false;
      })
      .catch((err) => {
        this.notificationHelper.trigger(
          "An error occured while preparing payment.",
          null,
          "error",
        );
        console.error(err);
        this.setupIntentLoading = false;
      });
  }

  public onStripePaymentInputChange(event: StripePaymentElementChangeEvent) {
    this.paymentMethodIsValid = event.complete === true;
  }

  public savePaymentMethod() {
    if (!this.paymentMethodIsValid) return;

    this.validationLoading = true;

    this.stripeService
      .confirmSetup({
        elements: this.paymentElement.elements,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            billing_details: {
              email: this.orgBilling.email,
              name: this.orgBilling.name,
              address: {
                line1: this.orgBilling.address_line_1,
                line2: this.orgBilling.address_line_2,
                city: this.orgBilling.city,
                postal_code: this.orgBilling.postal_code,
                country: this.orgBilling.country,
              },
              phone: this.orgBilling.phone_number, // @TODO
            },
          },
        },
      })
      .toPromise()
      .then(async (result) => {
        this.validationLoading = false;
        if (result.setupIntent?.status === "succeeded") {
          this.trackersService
            .newEventTrackingBuilder(
              "Organization billing payment method added",
            )
            .withSuperOrg(this.superOrg)
            .withProps({
              stripe_default_payment_method: result.setupIntent.payment_method,
            })
            .build();

          // set the default payment method to the newly added PM
          this.orgBillingPaymentMethods = await this.changeDefaultPaymentMethod(
            String(result.setupIntent.payment_method),
          );
          this.orgBillingPaymentMethodsChange.emit(
            this.orgBillingPaymentMethods,
          );
        } else {
          this.notificationHelper.trigger(
            "Failed to add payment method",
            result.error.message,
            "error",
          );
          console.error(result.error);
        }
      })
      .catch((err) => {
        this.validationLoading = false;
        console.error(err);
      });
  }

  private async changeDefaultPaymentMethod(
    defaultPaymentMethodId: string,
  ): Promise<OrgBillingPaymentMethods> {
    return this.superOrgDao.updateDefaultPaymentMethod(
      this.superOrg.id,
      defaultPaymentMethodId,
    );
  }

  public get isDevMode(): boolean {
    return ENV["ENV"] === "dev";
  }

  public get isStripeReady() {
    return !!this.elementsOptions.clientSecret;
  }
}
