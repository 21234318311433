import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NumberFormatType, formatNumber } from "utils/number";

@Pipe({
  name: "format",
  pure: true,
})
export class ScreebFormatPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: number | null, type: NumberFormatType) {
    if (value === null) return null;
    return formatNumber(value, type);
  }
}
