<settings-header
  title="Settings"
  description="Manage your organization settings"
  (save)="save()"
  [isLoading]="isLoading"
  [isDisabled]="!canSave"
></settings-header>

<section class="org-info">
  <h2>organization Info</h2>

  <div class="label">
    <label>Name</label>
    <input
      nz-input
      type="text"
      id="orgName"
      class="form-control"
      [value]="superOrg.name"
      readonly
      disabled
    />
  </div>
</section>

<hr />
<div class="label">
  <screeb-icon size="sm" icon="newest-user"></screeb-icon>
  Email domain authentication
  <nz-switch
    [(ngModel)]="emailDomainAuthSave.enabled"
    [nzCheckedChildren]="checkedTemplate"
    [nzUnCheckedChildren]="unCheckedTemplate"
    (ngModelChange)="updateCanSave()"
  ></nz-switch>
  <ng-template #checkedTemplate>
    <span nz-icon nzType="check"></span>
  </ng-template>
  <ng-template #unCheckedTemplate>
    <span nz-icon nzType="close"></span>
  </ng-template>
</div>
<p>
  Allow new users matching the following email domains
  <strong>{{ emailDomainAuthSave.default_domain }}</strong>
  to automatically join this organization.
</p>
<p>
  You can add extra domains to the list below. New users with email addresses
  matching any of the domains in the list will be able to join this organization
  without needing an invitation.
</p>
<tag-input
  [placeHolder]="emailDomainAuthSave.default_domain"
  size="large"
  [ngModel]="emailDomainAuthSave.extra_domains"
  (ngModelChange)="emailDomainAuthSave.extra_domains = $event; updateCanSave()"
></tag-input>

<section class="settings-section">
  <p>
    Additionnaly, you can provide a default workspace for new users joining this
    organization. This will improve your onboarding process by automatically
    assigning new users to the right workspace without needing admin
    intervention.
  </p>

  <settings-workspace-access
    [workspaces]="workspaces"
    [permissions]="workspacePermissions"
    [workspaceAccesses]="emailDomainAuthSave.default_workspaces"
    (workspacesAccessChange)="updateDefaultWorkspaces($event)"
  ></settings-workspace-access>
</section>
