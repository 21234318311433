export type WidgetSpec = {
  type: string;
  title: string;
  description: string;
  backgroundColor: string;
  icon: Icon;
  iconSize: "sm" | "md";

  height: string | undefined;

  width: "small" | "medium" | "full" | undefined;

  // This is for add panel
  key: string; // equal type
  label: string; // equal title
};

type Icon =
  | "number"
  | "demi-ellipse"
  | "ellipse"
  | "bar-chart"
  | "square"
  | "square-2"
  | "square-3"
  | "trend"
  | "report-text"
  | "trend-number"
  | "timeframe"
  | "report-play"
  | "bar-chart-2"
  | "timeframe-warning"
  | "target";

export const widgetsSpecs: WidgetSpec[] = [
  {
    type: "nps",
    title: "NPS",
    description:
      "Measures customer loyalty by asking how likely they are to recommend your product/services on a scale of 0-10.",
    backgroundColor: "#e2d6ff",
    icon: "trend-number",
    iconSize: "md",
    height: undefined,
    width: "small",
    key: "nps",
    label: "NPS",
  },
  {
    type: "ces",
    title: "CES",
    description:
      "Evaluates the ease of customer interactions by asking how much effort was required to handle their request.",
    backgroundColor: "#e2d6ff",
    icon: "trend-number",
    iconSize: "md",
    height: undefined,
    width: "small",
    key: "ces",
    label: "CES",
  },
  {
    type: "csat",
    title: "CSAT",
    description:
      "Measures customer satisfaction on a scale (usually 1-5) after interactions or overall experience.",
    backgroundColor: "#e2d6ff",
    icon: "trend-number",
    iconSize: "sm",
    height: undefined,
    width: "small",
    key: "csat",
    label: "CSAT",
  },
  {
    type: "mau",
    title: "Users Activity Trend",
    description:
      "Tracks user engagement patterns, including login frequency and feature usage.",
    backgroundColor: "#e2d6ff",
    icon: "trend-number",
    iconSize: "sm",
    height: undefined,
    width: "small",
    key: "mau",
    label: "Users Activity Trend",
  },
  {
    type: "completionRate",
    title: "Completion rate",
    description:
      "Measures the percentage of users who fully answered to a survey.",
    backgroundColor: "#e2d6ff",
    icon: "trend-number",
    iconSize: "sm",
    height: undefined,
    width: "small",
    key: "completionRate",
    label: "Completion rate",
  },
  {
    type: "displayNumber",
    title: "Total displays",
    description:
      "Counts the number of times your survey or messages are shown to users.",
    backgroundColor: "#e2d6ff",
    icon: "trend-number",
    iconSize: "md",
    height: undefined,
    width: "small",
    key: "displayNumber",
    label: "Total displays",
  },
  {
    type: "responseNumber",
    title: "Total responses",
    description: "Measures the total number of responses received from users.",
    backgroundColor: "#e2d6ff",
    icon: "trend-number",
    iconSize: "md",
    height: undefined,
    width: "small",
    key: "responseNumber",
    label: "Total responses",
  },
  {
    type: "responseRate",
    title: "Response rate",
    description:
      "Measures the percentage of users who responded out of those who were prompted.",
    backgroundColor: "#e2d6ff",
    icon: "trend-number",
    iconSize: "md",
    height: undefined,
    width: "small",
    key: "responseRate",
    label: "Response rate",
  },
  {
    type: "activity",
    title: "Active users",
    description:
      "Tracks the number of users actively engaging with your website/application over a specific period.",
    backgroundColor: "#e2d6ff",
    icon: "square-3",
    iconSize: "sm",
    height: undefined,
    width: "full",
    key: "activity",
    label: "Active users",
  },
  {
    type: "emotions",
    title: "Emotions",
    description:
      "Measures user sentiment and emotional responses to your product or services.",
    backgroundColor: "#e2d6ff",
    icon: "target",
    iconSize: "sm",
    height: undefined,
    width: "small",
    key: "emotions",
    label: "Emotions",
  },
  {
    type: "device_type",
    title: "Devices",
    description: "Get the repartition of devices used by your users",
    backgroundColor: "#e2d6ff",
    icon: "ellipse",
    iconSize: "md",
    height: undefined,
    width: "small",
    key: "device_type",
    label: "Devices",
  },
  {
    type: "identification",
    title: "Identification",
    description: "Get the repartition of logged and anonymous users",
    backgroundColor: "#e2d6ff",
    icon: "ellipse",
    iconSize: "sm",
    height: undefined,
    width: "small",
    key: "identification",
    label: "Identification",
  },
  {
    type: "changelog",
    title: "News & changelog",
    description: "Get the latest news and updates",
    backgroundColor: "#e2d6ff",
    icon: "square-2",
    iconSize: "sm",
    height: "265px",
    width: "small",
    key: "changelog",
    label: "News & changelog",
  },
  {
    type: "help",
    title: "Help & quickstart",
    description: "",
    backgroundColor: "#e2d6ff",
    icon: "square-2",
    iconSize: "sm",
    height: undefined,
    width: "full",
    key: "help",
    label: "Help & quickstart",
  },
  {
    type: "last_surveys",
    title: "Surveys",
    description: "Show a list of the last surveys",
    backgroundColor: "#e2d6ff",
    icon: "square-2",
    iconSize: "sm",
    height: undefined,
    width: "small",
    key: "last_surveys",
    label: "Surveys",
  },
  {
    type: "entitlement_mtu",
    title: "Usage",
    description:
      "Track your Monthly Active Users usage right from your dashboard.",
    backgroundColor: "#e2d6ff",
    icon: "square-2",
    iconSize: "sm",
    height: undefined,
    width: "small",
    key: "entitlement_mtu",
    label: "Usage",
  },
  {
    type: "quote_of_the_day",
    title: "Quote of the day",
    description: "A little bit of wisdom to start your day, nothing more.",
    backgroundColor: "#e2d6ff",
    icon: "square-2",
    iconSize: "sm",
    height: "265px",
    width: "small",
    key: "quote_of_the_day",
    label: "Quote of the day",
  },
  {
    type: "free_text_block",
    title: "Free text block",
    description: "A free text block",
    backgroundColor: "#e2d6ff",
    width: "full",
    height: undefined,
    key: "free_text_block",
    label: "Free text block",
    icon: "square-2",
    iconSize: "sm",
  },
];
