<div class="indicator-with-border full-height">
  <div class="indicator-header">
    <h4>Context</h4>
  </div>
  <ul class="stats-list">
    <li class="stat-item" *ngIf="userRecord.context['locale']?.length">
      <div class="stat-item-header">
        @let emoji = getLocaleEmoji(userRecord.context["locale"]);
        <square-icon [emoji]="emoji"></square-icon>
        <div class="stat-item-data">
          <div class="stat-item-title">Locale</div>
          <div class="stat-item-value" [title]="userRecord.context['locale']">
            {{ userRecord.context["locale"] }}
          </div>
        </div>
      </div>
    </li>
    @let osTitle =
      (
        (userRecord.context["os"]?.["name"] ||
          userRecord.context["device"]?.["name"]) +
        " • " +
        userRecord.context["os"]?.["version"]
      ).trim();
    <li
      class="stat-item"
      *ngIf="userRecord.context['os'] || userRecord.context['device']"
    >
      <div class="stat-item-header">
        <square-icon icon="desktop"></square-icon>
        <div class="stat-item-data">
          <div class="stat-item-title">OS</div>
          <div class="stat-item-value" [title]="osTitle">
            {{ osTitle }}
          </div>
        </div>
      </div>
    </li>
    @let browserTitle =
      (
        userRecord.context["browser"]?.["name"] +
        " • " +
        userRecord.context["browser"]?.["version"]
      ).trim();
    <li class="stat-item" *ngIf="userRecord.context['browser']?.['name']">
      <div class="stat-item-header">
        <square-icon [useContent]="true">
          <screeb-icon size="md" icon="browser"></screeb-icon>
        </square-icon>
        <div class="stat-item-data">
          <div class="stat-item-title">Browser</div>
          <div class="stat-item-value" [title]="browserTitle">
            {{ browserTitle }}
          </div>
        </div>
      </div>
    </li>
    @let originUrl = userRecord.context["active_page"]?.["url"];
    <li class="stat-item" *ngIf="originUrl?.length">
      <div class="stat-item-header">
        <square-icon [useContent]="true">
          <screeb-icon size="md" icon="link"></screeb-icon>
        </square-icon>
        <div class="stat-item-data">
          <div class="stat-item-title">Origin URL</div>
          <div class="stat-item-value" [title]="originUrl">
            {{ originUrl }}
          </div>
        </div>
      </div>
    </li>
    <li class="stat-item" *ngIf="userRecord.started_at">
      <div class="stat-item-header">
        <square-icon [useContent]="true">
          <screeb-icon size="md" icon="session-start"></screeb-icon>
        </square-icon>
        <div class="stat-item-data">
          <div class="stat-item-title">Session start</div>
          <div
            class="stat-item-value"
            [title]="userRecord.started_at | toLocaleString"
          >
            {{
              userRecord.started_at
                | dfnsFormatDistanceToNow: { addSuffix: true }
            }}
          </div>
        </div>
      </div>
    </li>
    <li class="stat-item">
      <div class="stat-item-header">
        <square-icon [useContent]="true">
          <screeb-icon size="md" icon="events"></screeb-icon>
        </square-icon>
        <div class="stat-item-data">
          <div class="stat-item-title">Number of events</div>
          <div class="stat-item-value" [title]="userEvents.length">
            {{ userEvents.length }}
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
