import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQuery } from "models/analytics.filters.type";
import { AnalyticsResponse } from "models/analytics.model";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { SequenceDao } from "models/sequence.dao";
import { SequenceFunnel } from "models/sequence.types";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { SettingsService } from "services/settings.service";
import { UIService } from "services/ui.service";
import {
  FunnelReportAggregation,
  computeFunnelReportAggregationFromResponse,
} from "./funnel-report.aggregation";
import { FunnelReportSummary } from "./funnel-report.types";
import {
  NzBreadCrumbComponent,
  NzBreadCrumbItemComponent,
} from "ng-zorro-antd/breadcrumb";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NgIf, NgClass, NgFor } from "@angular/common";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ReadMoreComponent } from "../../../../utils/read-more/read-more.component";
import { MarkdownComponent } from "ngx-markdown";
import { FunnelGridComponent } from "../../components/funnel-grid/funnel-grid.component";
import { ErrorMessageComponent } from "../../../utils/error-message/error-message.component";
import { FunnelHelpComponent } from "../../components/funnel-help/funnel-help.component";
import { PermissionPipe } from "pipes/permission.pipe";
import { FeaturePipe } from "pipes/feature.pipe";

@Component({
  selector: "page-funnel-report",
  templateUrl: "./funnel-report.component.html",
  styleUrls: ["./funnel-report.component.scss"],
  imports: [
    NzBreadCrumbComponent,
    NzBreadCrumbItemComponent,
    RouterLink,
    NzButtonComponent,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NgIf,
    NzWaveDirective,
    ReadMoreComponent,
    NgClass,
    NgFor,
    MarkdownComponent,
    FunnelGridComponent,
    ErrorMessageComponent,
    FunnelHelpComponent,
    PermissionPipe,
    FeaturePipe,
  ],
})
export class FunnelReportPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Funnel report";
  public name = "Funnel report";

  private obs: any = null;
  public org: Org = null;
  public surveys: Survey[] = null;
  public funnel: SequenceFunnel = null;
  public surveysById: Record<UUID, Survey> = {};
  public registryEntriesEvent: RegistryEntry[] = [];
  public funnelReportAggregation: FunnelReportAggregation = [];

  public loading = true;
  public loadingPercentage = 0;
  public error: Error = null;
  public needHelpVisible = false;

  public summary: FunnelReportSummary = null;
  public summaryLoading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    private analyticsDao: AnalyticsDao,
    private settingsService: SettingsService,
    public uiService: UIService,
    public permissionsService: PermissionsService,
    private featureFlaggingService: FeatureFlaggingService,
    private sequenceDao: SequenceDao,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.funnel = data.funnel;
      this.registryEntriesEvent = data.registryEntriesEvent;
      this.surveys = data.surveys;
      this.surveysById = this.surveys.reduce(
        (surveysById, survey) => ({
          ...surveysById,
          [survey.id]: survey,
        }),
        {},
      );

      this.fetchData();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  async fetchData() {
    this.error = null;
    this.loading = true;
    this.loadingPercentage = 0;

    const endDate = new Date();

    const queries = this.funnel.funnel.steps.map(
      (_, idx) =>
        ({
          type: "respondent",
          org_id: UUID(this.org.id),
          survey_ids: [],
          filters_bool: "AND",
          filters: [],
          range: {
            field: "last_activity_at",
            start: this.org.created_at,
            end: endDate,
          },
          size: 0,
          aggregation: [
            {
              by: "by_event.sequence",
              params: {
                event_sequence_date_from: this.org.created_at,
                event_sequence_date_to: endDate,
                event_sequence_ids: this.funnel.funnel.steps
                  .slice(0, idx + 1)
                  .map(({ event_id }) => event_id),
              },
            },
          ],
          with_total: false,
        }) as AnalyticsQuery,
    );

    const responses: AnalyticsResponse[] = [];

    for (const query of queries) {
      try {
        const response = await this.analyticsDao.search(query);

        responses.push(response);
        this.loadingPercentage = (responses.length / queries.length) * 100;
      } catch (error) {
        this.error = error;
      }
    }

    const response: AnalyticsResponse = responses.reduce(
      (finalResponse, response) => {
        if (!finalResponse) {
          return response;
        }

        const aggs = finalResponse.aggregations;
        const aggsResponse = response?.aggregations ?? {};

        //aggs.doc_count += aggsResponse.doc_count;

        delete aggsResponse.doc_count;
        Object.entries(aggsResponse).forEach(([key, value]) => {
          if (!aggs[key]) {
            aggs[key] = value;
            return;
          }

          aggs[key].doc_count += value.doc_count;
        });

        finalResponse.aggregations = aggs;

        return finalResponse;
      },
      null,
    );

    this.funnelReportAggregation =
      computeFunnelReportAggregationFromResponse(response);

    const steps = this.funnel.funnel.steps.map((step) => step.event_id);
    const events = this.registryEntriesEvent
      .filter((entry) => steps.includes(entry.id as UUID))
      .sort((a, b) => steps.indexOf(a.id as UUID) - steps.indexOf(b.id as UUID))
      .map((entry) => entry.slug);

    const completeFunnelSteps = this.funnelReportAggregation.map(
      (step, idx) => {
        const total =
          idx === 0
            ? step.total
            : this.funnelReportAggregation[idx - 1].currentValue;
        const converted = (step.currentValue / total) * 100;
        const dropped = ((total - step.currentValue) / total) * 100;

        return {
          idx: idx,
          title: events[idx],
          converted: converted,
          dropped: dropped,
        };
      },
    );

    if (!this.featureFlaggingService.isFunnelSummaryAvailable()) {
      this.loading = false;
      return;
    }

    this.summaryLoading = true;
    this.sequenceDao
      .getSummary(this.org.id, completeFunnelSteps)
      .then((summary) => {
        this.summary = summary;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.summaryLoading = false;
      });

    this.loading = false;
  }

  async onDisplaySurveyClick(url: string) {
    this.settingsService.setAdminSettingsKey(
      "funnel_create_survey_from",
      "report",
    );
    this.router.navigateByUrl(url);
  }
}
