import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NzInputDirective } from "ng-zorro-antd/input";
import { FormsModule } from "@angular/forms";
import { NzRadioGroupComponent, NzRadioComponent } from "ng-zorro-antd/radio";

export type SelectTimeUnit = "min" | "hour" | "day";

export const ONE_HOUR_SECONDS = 60 * 60;
export const ONE_DAY_SECONDS = 24 * ONE_HOUR_SECONDS;

export const getTimeUnit2 = (seconds: number): SelectTimeUnit => {
  if (seconds < ONE_HOUR_SECONDS) {
    return "min";
  } else if (seconds < ONE_DAY_SECONDS) {
    if (seconds % ONE_HOUR_SECONDS) {
      return "min";
    }
    return "hour";
  }

  if (seconds % ONE_DAY_SECONDS) {
    if (seconds % ONE_HOUR_SECONDS) {
      return "min";
    }
    return "hour";
  }
  return "day";
};

export const getTimeUnit = (seconds: number): SelectTimeUnit => {
  if (seconds < ONE_HOUR_SECONDS || seconds % ONE_HOUR_SECONDS) {
    return "min";
  } else if (seconds < ONE_DAY_SECONDS || seconds % ONE_DAY_SECONDS) {
    return "hour";
  }

  return "day";
};

export const getSeconds = (time: number, unit: SelectTimeUnit): number => {
  switch (unit) {
    case "min":
      return time * 60;
    case "hour":
      return time * ONE_HOUR_SECONDS;
    case "day":
      return time * ONE_DAY_SECONDS;
  }
};

export const getTime = (seconds: number, unit: SelectTimeUnit): number => {
  switch (unit) {
    case "min":
      return Math.ceil(seconds / 60);
    case "hour":
      return Math.ceil(seconds / ONE_HOUR_SECONDS);
    case "day":
      return Math.ceil(seconds / ONE_DAY_SECONDS);
  }
};

@Component({
  selector: "drop-if-select-popover",
  template: `
    <h3>Drop configuration</h3>
    <h4>
      We'll consider that a user dropped if the delay between
      <br />
      this step and the next one is higher than
    </h4>

    <div class="time-select">
      <input
        nz-input
        type="number"
        nzSize="large"
        placeholder="12"
        (ngModelChange)="onTimeChange($event)"
        [min]="1"
        [max]="9999"
        [step]="1"
        [ngModel]="time"
      />

      <nz-radio-group [ngModel]="unit" (ngModelChange)="onUnitChange($event)">
        <label nz-radio-button nzValue="min">min</label>
        <label nz-radio-button nzValue="hour">hour</label>
        <label nz-radio-button nzValue="day">day</label>
      </nz-radio-group>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        padding: 12px;
        min-width: 450px;
      }

      :host h3 {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
        margin-bottom: 24px;
      }

      :host h2 {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
      }

      :host .time-select {
        display: flex;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
      }

      :host .time-select input {
        flex: 1;
      }

      :host nz-radio-group {
        display: flex;
      }

      :host nz-radio-group label {
        margin-left: 8px;
        box-sizing: border-box;

        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 16px;
        height: 40px;
        background: var(--screeb-color-background);
        border: 1px solid var(--screeb-color-purple-500);
        border-radius: 8px;
      }
      :host nz-radio-group label:before {
        display: none;
      }
      :host nz-radio-group label.ant-radio-button-wrapper-checked {
        background: var(--screeb-color-purple-500);
        border: 1px solid var(--screeb-color-purple-500);
        color: var(--screeb-color-white);
      }

      :host .actions {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
      }
    `,
  ],
  imports: [
    NzInputDirective,
    FormsModule,
    NzRadioGroupComponent,
    NzRadioComponent,
  ],
})
export class DropIfSelectPopoverComponent implements OnInit {
  @Input() value = 12 * 60;
  @Output() valueChange = new EventEmitter<number>();

  public unit: SelectTimeUnit = "min";
  public time = 0;

  ngOnInit(): void {
    this.unit = getTimeUnit(this.value);
    this.time = getTime(this.value, this.unit);
  }

  onTimeChange(newTime: number) {
    this.time = newTime;
    this.value = getSeconds(newTime, this.unit);
    this.valueChange.emit(this.value);
  }

  onUnitChange(unit: SelectTimeUnit) {
    this.unit = unit;
    this.value = getSeconds(this.time, unit);
    this.valueChange.emit(this.value);
  }
}
