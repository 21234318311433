export type Changelog = {
  title: string;
  thumbnail: string;
  url?: string;
  date: Date;
};

export const changelogs: Changelog[] = [
  {
    title: "New Mobile SDK",
    thumbnail: "/assets/changelogs/mobile-sdk.png",
    url: "https://changelog.screeb.app/changelogs/2023-08-08-mobile-sdk",
    date: new Date("2023-08-09"),
  },
  {
    title: "New Homepage",
    thumbnail: "/assets/changelogs/new-homepage.png",
    url: "https://changelog.screeb.app/changelogs/2023-08-23-new-homepage",
    date: new Date("2023-08-23"),
  },
  {
    title: "Recurrent Surveys",
    thumbnail: "/assets/changelogs/recurrence-if-not-answered.png",
    url: "https://changelog.screeb.app/changelogs/2024-02-21-recurring-if-not-answered",
    date: new Date("2024-02-21"),
  },
  {
    title: "Integrations Filters",
    thumbnail: "/assets/changelogs/filters-integrations.png",
    url: "https://changelog.screeb.app/changelogs/2024-04-17-filters-integrations",
    date: new Date("2024-04-17"),
  },
  {
    title: "Ignore Anonymous Users",
    thumbnail: "/assets/changelogs/ignore-anonymous-users.png",
    url: "https://changelog.screeb.app/changelogs/2024-05-07-ignore-anonymous-users",
    date: new Date("2024-05-07"),
  },
  {
    title: "Screeb Day 🎉",
    thumbnail: "/assets/changelogs/365.png",
    url: "https://screeb.app/blog/new-360-customer-feedback-platform",
    date: new Date("2024-07-15"),
  },
];
