import { Component, EventEmitter, Output } from "@angular/core";
import { PermissionsService } from "services/permissions.service";
import { NgIf } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { PermissionPipe } from "pipes/permission.pipe";

@Component({
  selector: "release-onboarding-modal",
  templateUrl: "./release-onboarding-modal.component.html",
  styleUrls: ["./release-onboarding-modal.component.scss"],
  imports: [
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    PermissionPipe,
  ],
})
export class ReleaseOnboardingModalComponent {
  @Output() onboardingClose: EventEmitter<void> = new EventEmitter();

  constructor(public permissionsService: PermissionsService) {}

  handleOnboardingClose(): void {
    this.onboardingClose.emit();
  }
}
