<button
  *ngIf="'isNotDeviceTracking' | hasFeatureFlag"
  nz-button
  nzType="default"
  nzSize="large"
  (click)="showModal()"
>
  <i nz-icon nzType="save" nzTheme="outline"></i>
  Save segment
</button>

<nz-modal
  nzTitle="Save this segment"
  [(nzVisible)]="isModalVisible"
  [nzOkLoading]="loading"
  [nzOkDisabled]="selectedUserGroups.length && !isSegmentsValid"
  nzOkType="primary"
  nzOkText="Save segment"
  (nzOnOk)="handleOk()"
  (nzOnCancel)="handleCancel()"
  [nzWidth]="600"
>
  <ng-container *nzModalContent>
    <div class="save-segment-content">
      <nz-radio-group
        [(ngModel)]="createNew"
        (ngModelChange)="onCreateNewChange($event)"
      >
        <big-radio [nzValue]="true" label="Create new segment"></big-radio>
        <big-radio
          [nzValue]="false"
          label="Select an existing segment"
        ></big-radio>
      </nz-radio-group>

      <p *ngIf="createNew">
        Add users to a segment and use it to share in-app surveys and to analyse
        your user's behaviour.
      </p>

      <p *ngIf="!createNew">
        Create new segment and use it to share in-app surveys and to analyse
        your user's behaviour.
      </p>

      <nz-input-group *ngIf="createNew" nzSize="large">
        <input
          #segmentNameInput
          autofocus
          type="text"
          nzSize="large"
          nz-input
          placeholder="Name your new segment"
          [ngModel]="''"
          (ngModelChange)="onSegmentNameChange($event)"
        />
      </nz-input-group>

      <nz-select
        *ngIf="!createNew"
        class="survey-tags-select"
        nzSize="large"
        nzPlaceHolder="Select existing segment"
        [nzOptions]="userGroupOptions"
        [ngModel]="[]"
        (ngModelChange)="onSelectedSegmentChange($event)"
      ></nz-select>

      <p class="error" *ngIf="error">{{ error }}</p>
      <p class="error" *ngIf="selectedUserGroups.length && !isSegmentsValid">
        Segments names must only contains alpha-numerical characters,
        underscores and hyphen.
      </p>
    </div>
  </ng-container>
</nz-modal>
