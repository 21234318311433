import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import {
  RegistryEntry,
  RegistryEntrySourceFormatted,
} from "models/registry.model";
import { UserEvent } from "models/user-event.model";
import { getFormattedUserGroupNameOrID } from "models/user-group.types";
import {
  UserRecord,
  UserRecordCompletionStatus,
  UserRecordHighlight,
  UserRecordSnapshot,
} from "models/user-record.model";
import { UserDao } from "models/user.dao";
import {
  aggregateUserPropertiesWithDescription,
  getFormattedUserNameOrIDByNormalizedProperty,
  getUserIcon,
  removePropertiesHavingNullParent,
  User,
  UserNormalizedProperty,
} from "models/user.model";
import { Response } from "models/response.model";
import { UUID } from "models/survey.dao.types";
import { NzTableSortOrder } from "ng-zorro-antd/table";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import { TrackingEventName } from "services/trackers.events";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { PermissionsService } from "services/permissions.service";
import { UserNormalizedEvent } from "components/user/common/user-events/user-events.component";
import rrwebPlayer from "rrweb-player";
import { Survey } from "models/survey.model";
import { UserRecordDao } from "models/user-record.dao.js";
import { emojiTranscoder } from "components/builder/components/Cards/sanitized-message/emojis.js";
import { Account } from "models/account.model.js";
import { arrayToMap } from "utils/array.js";
import { ClipboardService } from "ngx-clipboard";
import { ViewportRuler } from "@angular/cdk/scrolling";
import { NgFor, NgIf } from "@angular/common";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { TagInputComponent } from "../../utils/tag-input/tag-input.component";
import { FormsModule } from "@angular/forms";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ErrorMessageComponent } from "../../utils/error-message/error-message.component";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { ScreebIconComponent } from "../../utils/screeb-icon/screeb-icon.component";
import {
  NzTextareaCountComponent,
  NzInputDirective,
} from "ng-zorro-antd/input";
import { NzTabSetComponent, NzTabComponent } from "ng-zorro-antd/tabs";
import { TextShimmerComponent } from "../../common/text-shimmer/text-shimmer.component";
import { NzEmptyComponent } from "ng-zorro-antd/empty";
import { MarkdownComponent } from "ngx-markdown";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import { OrgAccountAvatarComponent } from "../../utils/org-account-avatar/org-account-avatar.component";
import { UserEventsComponent } from "../../user/common/user-events/user-events.component";
import { NzSelectComponent, NzOptionComponent } from "ng-zorro-antd/select";
import { UserContextComponent } from "../../user/common/user-context/user-context.component";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { UserAddToSegmentComponent } from "../../user/common/add-to-segment/add-to-segment.component";
import { WidgetGraphComponent } from "../../home/widgets/graph/graph.component";
import { UserActivityComponent } from "../../user/common/user-activity/user-activity.component";
import { UserTimelineComponent } from "../../user/common/user-timeline/user-timeline.component";
import { UserPropertiesComponent } from "../../user/common/user-properties/user-properties.component";
import { SquareIconComponent } from "../../utils/square-icon/square-icon.component";
import { TagRegistryEntrySourceComponent } from "../../common/user/tag-registry-entry-source/tag-registry-entry-source.component";
import { FormatPipeModule, FormatDistanceToNowPipeModule } from "ngx-date-fns";
import { ToLocaleStringPipe } from "pipes/to-locale-string.pipe";
import { PermissionPipe } from "pipes/permission.pipe";
import { subDays } from "date-fns/esm";

@Component({
  selector: "user-record-details-page",
  templateUrl: "./user-record-details.component.html",
  styleUrls: ["./user-record-details.component.scss"],
  imports: [
    NgFor,
    NzTagComponent,
    NzTooltipDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NgIf,
    TagInputComponent,
    FormsModule,
    NzButtonComponent,
    NzWaveDirective,
    RouterLink,
    ErrorMessageComponent,
    NzRowDirective,
    NzColDirective,
    ScreebIconComponent,
    NzTextareaCountComponent,
    NzInputDirective,
    NzTabSetComponent,
    NzTabComponent,
    TextShimmerComponent,
    NzEmptyComponent,
    MarkdownComponent,
    NzSpinComponent,
    OrgAccountAvatarComponent,
    UserEventsComponent,
    NzSelectComponent,
    NzOptionComponent,
    UserContextComponent,
    NzPopoverDirective,
    UserAddToSegmentComponent,
    WidgetGraphComponent,
    UserActivityComponent,
    UserTimelineComponent,
    UserPropertiesComponent,
    SquareIconComponent,
    TagRegistryEntrySourceComponent,
    FormatPipeModule,
    FormatDistanceToNowPipeModule,
    ToLocaleStringPipe,
    PermissionPipe,
  ],
})
export class UserRecordDetailsPageComponent
  implements PageComponentInterface, OnInit, AfterViewInit, OnDestroy
{
  public title = "Session details";
  public name = "Session details";

  private obs: any = null;

  @ViewChild("commentTextArea") textArea;

  public org: Org;
  public orgAccounts: Account[] = [];
  public orgSurveys: Survey[];
  public orgUserProperties: RegistryEntry[]; // exclude type=object
  public orgUserPropertiesById: Map<string, RegistryEntry>;
  public orgUserEvents: RegistryEntry[];
  public orgUserGroups: RegistryEntry[];
  public orgUserGroupTypes: RegistryEntry[];

  public highlights: UserRecordHighlight[] = [];
  public highlightsBarOrder: number[] = [];
  public player: rrwebPlayer = null;
  public isRecordPlaying: boolean = false;
  public isSeeking: boolean = false;
  public canSeekNext: boolean = false;
  public canSeekPrev: boolean = false;
  public isControllerHidden: boolean = false;
  private hasControllerTooltip: boolean = false;
  public playerSpeed: number = 1;
  private isFullScreen: boolean = false;
  public recordProgress: number = 0;
  public playerFormattedTime: string = "00:00";
  public playerTotalTime: string = "00:00";
  private controllerTimeout: any;
  private heatmapInstance: any;
  private heatmapPoints: any[] = [];
  public consoleEvents: {
    type: number;
    source: number;
    data: any;
    timestamp: number;
    delay: number;
    expanded?: boolean;
  }[] = [];
  public consoleFilter: string[] = [];
  public allFiltersLevels = [
    { value: "debug", label: "Debug" },
    { value: "log", label: "Log" },
    { value: "info", label: "Info" },
    { value: "warn", label: "Warn" },
    { value: "error", label: "Error" },
  ];
  public isSkeepingSpeed: number | null = null;

  @ViewChild("rrwebPlayerWrapper", { static: false, read: ElementRef })
  rrwebPlayerWrapper;

  public selectedTab: number = 0;

  public userRecord: UserRecord;
  public user: User;
  public userProperties: UserNormalizedProperty[] = [];
  public userEvents: UserNormalizedEvent[] = [];
  public userEventsTypeform: UserNormalizedEvent[] = [];
  public userGroups: RegistryEntry[] = [];
  public userResponsesPG: Response[] = [];
  public deletingByResponseId = {};

  public responsesCount = 0;
  public lastResponseDate = new Date(0);
  public expandedResponses: { [key: string]: boolean } = {};

  public loadingResponsesES = true;
  public initialFetchResponsesES = true;
  public errorResponsesES: Error;

  public loadingProperties = true;
  public initialFetchProperties = true;
  public errorProperties: Error;

  public loadingEvents = true;
  public initialFetchEvents = true;
  public errorEvents: Error;

  public loadingEventsTypeform = true;
  public initialFetchEventsTypeform = true;
  public errorEventsTypeform: Error;

  public questionOrder: NzTableSortOrder = "descend";

  public surveyIds: UUID[] = [];

  public startDate: Date;
  public endDate: Date;

  public commentModalOpenEmoji: string | true | null = null;
  public commentModalOpen: boolean = false;
  public commentTimecode: number = 0;
  public commentFormatedTimecode: string = "00:00";
  public comment: string = "";
  public isCommentLoading: boolean = false;

  public isSnapshotsLoading: boolean = true;

  public isAddingTag: boolean = false;
  public selectedAddedTags: string[] = [];

  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;
  public getUserIcon = getUserIcon;
  public getFormattedUserGroupNameOrID = getFormattedUserGroupNameOrID;
  public getFormattedUserNameOrIDByNormalizedProperty =
    getFormattedUserNameOrIDByNormalizedProperty;

  public emojiTranscoder = emojiTranscoder;

  private readonly viewportChange = this.viewportRuler
    .change(150)
    .subscribe(() => this.ngZone.run(() => this.onResize()));

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    private userDao: UserDao,
    private trackersService: TrackersService,
    private notificationHelper: NotificationHelper,
    public uiService: UIService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public entitlementService: EntitlementService,
    private userRecordDao: UserRecordDao,
    private clipboardService: ClipboardService,
    private readonly viewportRuler: ViewportRuler,
    private readonly ngZone: NgZone,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.endDate = new Date();
    this.startDate = subDays(this.endDate, 365);
    this.consoleFilter = this.allFiltersLevels.map((filter) => filter.value);
    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.orgAccounts = data.orgAccounts;
      this.userRecord = data.userRecord;
      this.orgSurveys = data.surveys;

      this.playerTotalTime = this._formatTime(this.userRecord.duration ?? 0);

      // Properly sort the user record highlights
      this.computeHighlights(this.userRecord.highlights);

      // if AI is still processing or empty, focus highlights
      if (
        this.userRecord.completion_status ===
          UserRecordCompletionStatus.HIGHLIGHTS_DONE ||
        (this.userRecord.completion_status >=
          UserRecordCompletionStatus.AI_DONE &&
          !this.userRecord.summary?.length)
      ) {
        this.selectedTab = 1;
      }

      this.userDao
        .getUser(this.org.id, this.userRecord.user_id)
        .then((user) => {
          this.user = user;

          this.orgUserProperties = data.orgProperties;
          this.orgUserPropertiesById = arrayToMap(this.orgUserProperties, "id");
          this.orgUserProperties = this.orgUserProperties.filter(
            (entry: RegistryEntry) => entry.type !== "object",
          );
          this.orgUserEvents = data.orgEvents;
          this.orgUserGroups = data.orgGroups.groups;
          this.orgUserGroupTypes = data.orgGroups.group_types;

          this.userGroups = this.user.assigned_group_ids
            .map((assigned_group_id) =>
              this.orgUserGroups.find(({ id }) => assigned_group_id === id),
            )
            .filter(Boolean);

          this.refreshData();
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._fetchSnapshots();
    }, 0);
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }

    this.viewportChange.unsubscribe();
  }

  private mergeEvents(
    registryEntriesEvent: RegistryEntry[],
    events: UserEvent[],
  ): UserNormalizedEvent[] {
    return events
      .sort(
        (event1, event2) =>
          Number(event2.triggered_at) - Number(event1.triggered_at),
      )
      .map((event) => {
        const name = registryEntriesEvent.find(
          (registryEntry) => event.name_id === registryEntry.id,
        )?.slug;

        return {
          name,
          ...event,
        };
      });
  }

  public async refreshData() {
    this.getProperties();
    this.getEvents();
  }

  public onTabChange(tabIndex: number) {
    this.selectedTab = tabIndex;
  }

  public getStartPercent(highlight: UserRecordHighlight) {
    return Math.max(
      0,
      Math.min(
        99, // Be sure that element is properly visible, when start and end = 100
        (highlight.timecode_start /
          (this.userRecord.ended_at.getTime() -
            this.userRecord.started_at.getTime())) *
          100,
      ),
    );
  }

  public getEndPercent(highlight: UserRecordHighlight) {
    return Math.max(
      1, // Be sure that element is properly visible, when start and end = 0
      Math.min(
        100,
        ((highlight.timecode_end - highlight.timecode_start) /
          (this.userRecord.ended_at.getTime() -
            this.userRecord.started_at.getTime())) *
          100,
      ),
    );
  }

  public getCommentById(id: string) {
    return this.userRecord.comments.find((comment) => comment.id === id);
  }

  // Sort hightlights
  private computeHighlights(highlights: UserRecordHighlight[]) {
    if (highlights.length === 0) {
      return;
    }
    this.highlights.push(...highlights);
    this.highlights = this.highlights.sort(
      (a, b) => b.timestamp.getTime() - a.timestamp.getTime(),
    );

    // clone order
    let i = 0;
    const tmp = [...this.highlights].map((highlight) => ({
      index: i++,
      highlight,
    }));

    // sort by duration with the longest first
    tmp.sort(
      (a, b) =>
        b.highlight.timecode_end -
        b.highlight.timecode_start -
        (a.highlight.timecode_end - a.highlight.timecode_start),
    );

    // We'll use a different order for the highlights bar in order to avoid using too much different z-index
    this.highlightsBarOrder = tmp.map((item) => item.index);
  }

  public onHighlightClick(highlight: UserRecordHighlight, _: number) {
    if (highlight.type === "response") {
      this.router.navigate([
        "/org",
        this.org.id,
        "survey",
        highlight.data,
        "stats",
        "all-responses",
      ]);
      return;
    } else if (highlight.type === "console") {
      this.selectedTab = 3;
    }
  }

  public onConsoleEventClick(index: number) {
    if (!this.consoleEvents[index]) return;
    this.consoleEvents[index].expanded = !this.consoleEvents[index].expanded;
  }

  public onTimecodeClick(highlight: UserRecordHighlight) {
    this.isControllerHidden = false;
    if (this.player) {
      this.player.goto(highlight.timecode_start);
    }
  }

  public getTimecode(timestamp: number) {
    return this._formatTime(timestamp / 1000);
  }

  public getOrgAccount(id: string) {
    return this.orgAccounts.find((account) => account.id === id);
  }

  public onCommentOpen(ev, emoji: string | true) {
    ev.stopPropagation();
    if (emoji === this.commentModalOpenEmoji) {
      this.commentModalOpen = false;
      this.commentModalOpenEmoji = null;
      this.commentTimecode = 0;
      this.commentFormatedTimecode = "00:00";
      return;
    }
    this.commentModalOpenEmoji = emoji;
    this.commentModalOpen = true;
    this.commentTimecode = Math.max(
      Math.floor(this.player.getReplayer().getCurrentTime()),
      0,
    );
    this.commentFormatedTimecode = this._formatTime(
      this.commentTimecode / 1000,
    );

    setTimeout(() => {
      this.textArea.nativeElement.focus();
    }, 0);
  }

  public onCommentKeyDown(event: KeyboardEvent) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      this.onCommentSubmit();
    }
  }

  public onPlayerControllersClick() {
    if (this.commentModalOpen) {
      this.commentModalOpen = false;
    }
  }

  public getFormattedRemainingUserGroups(groups: RegistryEntry[]) {
    return groups
      .map((group) => getFormattedUserGroupNameOrID(group))
      .join(", ");
  }

  public onCommentSubmit() {
    const trimmed = this.comment.trim();
    if (trimmed.length === 0 || trimmed.length > 500) {
      this.notificationHelper.trigger(
        "Comment must be between 1 and 500 characters.",
        null,
        "error",
      );
      return;
    }
    this.isCommentLoading = true;
    this.userRecordDao
      .createComment(
        this.org.id,
        this.userRecord.id,
        this.commentModalOpenEmoji === true ? null : this.commentModalOpenEmoji,
        trimmed,
        this.commentTimecode,
      )
      .then((comment) => {
        this.userRecord.comments.push(comment);
        this.computeHighlights([
          new UserRecordHighlight().fromJson({
            title: comment.comment,
            timecode_start: comment.timecode,
            timecode_end: comment.timecode,
            timestamp: comment.created_at,
            type: "comment",
            data: comment.id,
          }),
        ]);
        this.comment = "";
        this.commentModalOpenEmoji = null;
        this.commentFormatedTimecode = "00:00";
        this.commentTimecode = 0;
        this.commentModalOpen = false;
      })
      .catch((error) => {
        console.error(error);
        this.notificationHelper.trigger(
          "Failed to create comment, please retry.",
          null,
          "error",
        );
      })
      .finally(() => {
        this.isCommentLoading = false;
      });
  }

  private async getProperties() {
    this.loadingProperties = true;
    this.errorProperties = null;

    return this.userDao
      .getUserProperties(this.org.id, this.user.id)
      .then((properties) => {
        properties = removePropertiesHavingNullParent(
          this.orgUserPropertiesById,
          properties,
        );
        this.userProperties = aggregateUserPropertiesWithDescription(
          this.orgUserPropertiesById,
          properties,
        );
      })
      .catch((error) => {
        this.errorProperties = error;
        console.error(error);
      })
      .finally(() => {
        this.loadingProperties = false;
        this.initialFetchProperties = false;
      });
  }

  private async getEvents() {
    this.loadingEvents = true;
    this.errorEvents = null;

    // all event types except typeform
    this.userDao
      .getUserEvents(
        this.org.id,
        this.user.id,
        50,
        this.userRecord.started_at,
        this.userRecord.ended_at,
        ["track"],
        [],
        [],
        ["typeform"],
      )
      .then((data: UserEvent[]) => {
        this.userEvents = this.mergeEvents(this.orgUserEvents, data);
      })
      .catch((error) => {
        this.errorEvents = error;
        console.error(error);
      })
      .finally(() => {
        this.loadingEvents = false;
        this.initialFetchEvents = false;
      });

    // typeform
    this.userDao
      .getUserEvents(
        this.org.id,
        this.user.id,
        50,
        null,
        null,
        ["track"],
        [],
        ["typeform"],
        [],
      )
      .then((events) => {
        this.userEventsTypeform = this.mergeEvents(this.orgUserEvents, events);
      })
      .catch((error) => {
        this.errorEventsTypeform = error;
        console.error(error);
      })
      .finally(() => {
        this.loadingEventsTypeform = false;
        this.initialFetchEventsTypeform = false;
      });
  }

  public onHighlightTooltipVisibleChange(ev) {
    this.hasControllerTooltip = ev;
  }

  getTypeformEventURL(event: UserEvent) {
    const formId = event.raw_properties?.["form_response"]?.["form_id"];
    if (!formId) return "https://admin.typeform.com";

    return `https://admin.typeform.com/form/${formId}/results#responses`;
  }

  onEventClicked() {
    this.trackEvent("Respondent event clicked");
  }

  onRecordClicked() {
    this.trackEvent("Respondent record opened");
  }

  onTypeformClicked() {
    this.trackEvent("Respondent typeform clicked");
  }

  removeUserFromGroup(userGroup: RegistryEntry) {
    this.userDao
      .removeUserFromGroup(
        this.org.id,
        this.user.id,
        null,
        userGroup.parent_id,
        userGroup.title,
      )
      .then(() => {
        this.userGroups = this.userGroups.filter(
          ({ id }) => id !== userGroup.id,
        );
        this.notificationHelper.trigger(
          `Successfully removed user from segment '${userGroup.title}'!`,
          null,
          "success",
        );

        this.trackersService
          .newEventTrackingBuilder("Respondent removed from segment")
          .withOrg(this.org)
          .withUser(this.user)
          .withProps({
            segments: userGroup.slug,
            segmentType: userGroup.parent_id,
          })
          .build();
      })
      .catch(() => {
        this.notificationHelper.trigger(
          `Failed to remove user from segment '${userGroup.title}', please retry.`,
          null,
          "error",
        );
      });
  }

  onAddedToSegments(groupIds: string[]) {
    this.userGroups.push(
      ...groupIds
        .map((groupId) => this.orgUserGroups.find(({ id }) => id === groupId))
        .filter(Boolean),
    );
  }

  public getAvatarURL(userProperties: UserNormalizedProperty[]): string | null {
    return userProperties.find(
      (property: UserNormalizedProperty) => property.key === "avatar",
    )?.value as string; // this safe, we don't expect other data type for avatars
  }

  private trackEvent(eventName: TrackingEventName) {
    this.trackersService
      .newEventTrackingBuilder(eventName)
      .withOrg(this.org)
      .withUser(this.user)
      .build();
  }

  private initPlayer(events: any[]) {
    this.player = new rrwebPlayer({
      target: this.rrwebPlayerWrapper.nativeElement,
      props: {
        events,
        width: this.rrwebPlayerWrapper.nativeElement.clientWidth,
        height: 500,
        showController: false,
        autoPlay: false,
        useVirtualDom: true,
        skipInactive: true,
        inactivePeriodThreshold: 7 * 1000, // should be the same on back while calculating scores
        UNSAFE_replayCanvas: true,
        mouseTail: {
          strokeStyle: "#5e21f1",
        },
      },
    });

    this.player.getReplayer().speedService.subscribe((ev) => {
      if (ev.value === "skipping") {
        this.isSkeepingSpeed = ev.context.timer.speed;
      } else {
        this.isSkeepingSpeed = null;
      }
    });

    this._updateRecordState();
    this.player.triggerResize();
  }

  private async _fetchSnapshots() {
    this.isSnapshotsLoading = true;
    let offset = 0;
    const limit = 50;
    while (true) {
      try {
        const snapshots = await this.userRecordDao.getSnapshostsByID(
          this.org.id,
          this.userRecord.id,
          offset,
          limit,
        );
        if (snapshots.length === 0) {
          break;
        }

        const tmp = snapshots.map((s: UserRecordSnapshot) => {
          const data = s.unpackData();
          return {
            type: s.type,
            source: s.source,
            data: data,
            timestamp: new Date(s.timestamp).getTime(),
            delay: s.delay,
          };
        });

        const havePlayer = this.player !== null;

        const decoded: UserRecordSnapshot[] = [];
        for (const s of snapshots) {
          const data = s.unpackData();
          const tmp = {
            type: s.type,
            source: s.source,
            data: data,
            timestamp: new Date(s.timestamp).getTime(),
            delay: s.delay,
          } as UserRecordSnapshot;
          decoded.push(tmp);
          if (havePlayer) {
            this.player.getReplayer().addEvent(tmp as any);
          }
        }

        // If player is not already loaded, load it now
        if (!havePlayer) {
          this.initPlayer(decoded);
        }

        this.consoleEvents.push(
          ...tmp
            .filter(
              (snapshot) =>
                snapshot.type === 6 &&
                snapshot.data.plugin === "rrweb/console@1",
            )
            .map((snapshot) => {
              snapshot.data.payload.payload = snapshot.data.payload.payload.map(
                (payload) => {
                  if (typeof payload === "string") {
                    return JSON.parse(payload);
                  }
                  return payload;
                },
              );
              return snapshot;
            }),
        );

        // Sort console event, latest first
        this.consoleEvents.sort((a, b) => b.timestamp - a.timestamp);

        offset += limit;
      } catch (e) {
        console.error(e);
        break;
      }
    }

    this.isSnapshotsLoading = false;
  }

  private _computeHeatmap() {
    const canvas = document.querySelector(".heatmap") as HTMLCanvasElement;
    const rrbweb = document.querySelector(".replayer-wrapper");
    const rrbwebRect = rrbweb.getBoundingClientRect();
    const width = rrbwebRect.width;
    const height = rrbwebRect.height;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    this.heatmapInstance.resize();

    this.heatmapInstance.clear();
    for (const point of this.heatmapPoints) {
      const [x, y, value] = point;
      this.heatmapInstance.add([x * width, y * height, value]);
    }

    this.heatmapInstance.draw();
  }

  private _updateRecordState() {
    const replayer = this.player.getReplayer();
    const meta = replayer.getMetaData();
    const currentTime = Math.max(replayer.getCurrentTime(), 0);
    this.isRecordPlaying = replayer.timer.isActive();
    this.recordProgress = Math.min(1, currentTime / meta.totalTime) * 100;
    this.canSeekNext = currentTime < meta.totalTime - 5000;
    this.canSeekPrev = currentTime > 5000;
    this.playerFormattedTime = this._formatTime(
      Math.max(currentTime / 1000, 0),
    );
    this.playerTotalTime = this._formatTime(
      Math.max(this.userRecord.duration ?? 0, meta.totalTime / 1000),
    );

    requestAnimationFrame(() => {
      this._updateRecordState();
    });
  }

  private _formatTime(time: number) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    if (hours > 0) {
      return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
        seconds < 10 ? "0" : ""
      }${seconds}`;
    }
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  }

  public onPlayerToggle() {
    this.player?.toggle();
  }

  public onPlayerProgressClick(event: MouseEvent) {
    if (!this.player) {
      return;
    }

    const target = event.currentTarget as HTMLElement;
    const progressRect = target.getBoundingClientRect();
    const x = event.clientX - progressRect.left;
    let percent = x / progressRect.width;
    if (percent < 0) {
      percent = 0;
    } else if (percent > 1) {
      percent = 1;
    }

    if (this.commentModalOpen) {
      this.commentTimecode = Math.max(
        Math.floor(percent * this.userRecord.ended_at.getTime()),
        0,
      );
      this.commentFormatedTimecode = this._formatTime(
        this.commentTimecode / 1000,
      );
    }

    // With replayer we must pause, seek and then play if we want a good ui/ux
    const replayer = this.player.getReplayer();
    const time = replayer.getMetaData().totalTime * percent;
    const wasPlaying = replayer.timer.isActive();
    this.isSeeking = true;
    if (wasPlaying) {
      this.player.pause();
    }
    this.player.goto(time);
    if (wasPlaying) {
      setTimeout(() => {
        this.player.play();
        setTimeout(() => {
          this.isSeeking = false;
        }, 50);
      }, 200);
    } else {
      setTimeout(() => {
        this.isSeeking = false;
      }, 0);
    }
  }

  // Ok this is a bit hacky but it works
  // as only triggerResize is not working
  public onResize() {
    setTimeout(() => {
      const replayer = this.player?.getReplayer();
      const root = replayer?.config.root as HTMLElement;
      if (!root) {
        return;
      }
      const wrapper = this.rrwebPlayerWrapper.nativeElement as HTMLElement;
      root.style.width = wrapper.clientWidth + "px";
      root.parentElement.style.width = root.style.width;
      // @ts-ignore - not in the type
      this.player.$$set({
        width: wrapper.clientWidth,
        height: wrapper.clientHeight,
      });
      this.player.triggerResize();
      // this._computeHeatmap();
    }, 0);
  }

  public onPlayerFullScreen() {
    if (this.isFullScreen) {
      this.exitFullscreen().then(() => {
        this.player.triggerResize();
      });
    } else {
      this.enterFullscreen().then(() => {
        this.player.triggerResize();
      });
    }
  }

  public onPlayerSeekNext() {
    if (this.player) {
      this.player.goto(
        Math.max(this.player.getReplayer().getCurrentTime(), 0) + 5000,
      );
    }
  }

  public onPlayerSeekPrev() {
    if (this.player) {
      this.player.goto(
        Math.max(this.player.getReplayer().getCurrentTime(), 0) - 5000,
      );
    }
  }

  public onPlayerSpeedChange(speed: number) {
    if (this.player) {
      this.player.getReplayer().setConfig({ speed });
      this.playerSpeed = speed;
    }
  }

  public onPlayerHover(enter: boolean) {
    if (!this.player) return;
    if (enter && this.isControllerHidden) {
      this.isControllerHidden = false;

      if (this.controllerTimeout) clearTimeout(this.controllerTimeout);
      this.controllerTimeout = setTimeout(() => {
        // As we loose focus with tooltip, we should not hide controller
        // aswell when the comment modal is open
        if (!this.hasControllerTooltip && !this.commentModalOpen) {
          this.isControllerHidden = true;
        }
      }, 3500);
    } else if (!enter && !this.isControllerHidden) {
      setTimeout(() => {
        if (!this.hasControllerTooltip && !this.commentModalOpen) {
          this.isControllerHidden = true;
        }
      }, 1000);
    }
  }

  public async enterFullscreen(): Promise<void> {
    const el = document.querySelector(".rrweb-player-wrapper") as any;
    this.isFullScreen = true;
    document
      .querySelector(".rr-player")
      .setAttribute("style", "width: 100%; height: 100%;");
    if (el.requestFullscreen) {
      return el.requestFullscreen();
    } else if (el.mozRequestFullScreen) {
      /* Firefox */
      return el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      return el.webkitRequestFullscreen();
    } else if (el.msRequestFullscreen) {
      /* IE/Edge */
      return el.msRequestFullscreen();
    }
  }

  public async exitFullscreen(): Promise<void> {
    this.isFullScreen = false;
    const docAny = document as any;
    if (docAny.exitFullscreen) {
      return docAny.exitFullscreen();
    } else if (docAny.mozExitFullscreen) {
      /* Firefox */
      return docAny.mozExitFullscreen();
    } else if (docAny.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      return docAny.webkitExitFullscreen();
    } else if (docAny.msExitFullscreen) {
      /* IE/Edge */
      return docAny.msExitFullscreen();
    }
  }

  public onConfirmTag(recordID: string) {
    this.isAddingTag = false;

    this.userRecordDao
      .addTags(this.org.id, recordID, this.selectedAddedTags)
      .then(() => {
        this.notificationHelper.trigger("Tag(s) added", null, "success");
        this.userRecord.tags.push(...this.selectedAddedTags);

        this.selectedAddedTags = [];
      })
      .catch(() => {
        this.notificationHelper.trigger("Could not add tag(s)", null, "error");
      });
  }

  public handleDeleteTag(recordID: string, tag: string) {
    this.userRecordDao
      .removeTag(this.org.id, recordID, tag)
      .then(() => {
        this.notificationHelper.trigger("Tag removed", null, "success");

        this.userRecord.tags.splice(this.userRecord.tags.indexOf(tag), 1);
      })
      .catch(() => {
        this.notificationHelper.trigger("Could not remove tag", null, "error");
      });
  }

  public onCopy(event: Event, text: string) {
    const target = (event.target as HTMLElement).parentElement;
    target.classList.add("copy");
    setTimeout(() => {
      target.classList.remove("copy");
    }, 200);

    this.clipboardService.copy(text);
    event.preventDefault();
    event.stopPropagation();
  }
}
