import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import {
  UserRecord,
  UserRecordComment,
  UserRecordSnapshot,
} from "./user-record.model";
import { AnalyticsQuerySession } from "./analytics.filters.type";
import { AnalyticsResponse } from "./analytics.model";

@Injectable()
export class UserRecordDao {
  constructor(private screebApiHelper: ScreebApiHelper) {}

  public search(
    baseQuery: AnalyticsQuerySession,
    overview: boolean = false,
  ): Promise<AnalyticsResponse> {
    const workspaceID = baseQuery.org_id;

    // we won't need a deep copy here
    const query = Object.assign({}, baseQuery);
    delete query.org_id;
    delete query.survey_ids;

    const start = new Date().getTime();
    return this.screebApiHelper
      .request(
        "SEARCH",
        `/org/${workspaceID}/record${overview ? "?overview=true" : ""}`,
        { body: query },
      )
      .toPromise()
      .then((data: object[]): AnalyticsResponse => {
        const res = AnalyticsResponse.fromJson(data);

        const end = new Date().getTime();
        console.log(`Took ${res.took} ms (${end - start} ms e2e)`);

        return res;
      });
  }

  public getById(workspaceID: string, recordId: string): Promise<UserRecord> {
    return this.screebApiHelper
      .get<object>(`/org/${workspaceID}/record/${recordId}`)
      .toPromise()
      .then((data: object): UserRecord => {
        return new UserRecord().fromJson(data);
      });
  }

  public getPreviewById(
    workspaceID: string,
    recordId: string,
  ): Promise<UserRecord> {
    return this.screebApiHelper
      .get<object>(`/org/${workspaceID}/record/${recordId}/preview`)
      .toPromise()
      .then((data: object): UserRecord => {
        return new UserRecord().fromJson(data);
      });
  }

  public getSnapshostsByID(
    workspaceID: string,
    recordId: string,
    offset: number,
    limit: number,
  ): Promise<UserRecordSnapshot[]> {
    return this.screebApiHelper
      .get<object[]>(`/org/${workspaceID}/record/${recordId}/snapshots`, {
        params: {
          offset,
          limit,
        },
      })
      .toPromise()
      .then((data: object[] | null): UserRecordSnapshot[] => {
        return (data ?? []).map((snapshot: object): UserRecordSnapshot => {
          return new UserRecordSnapshot().fromJson(snapshot);
        });
      });
  }

  public createComment(
    workspaceID: string,
    recordId: string,
    emoji: string | null,
    comment: string,
    timecode: number,
  ): Promise<UserRecordComment> {
    return this.screebApiHelper
      .post<object>(`/org/${workspaceID}/record/${recordId}/comment`, {
        emoji,
        comment,
        timecode,
      })
      .toPromise()
      .then((data: object): UserRecordComment => {
        return new UserRecordComment().fromJson(data);
      });
  }

  public deleteComment(
    workspaceID: string,
    recordId: string,
    commentId: string,
  ): Promise<void> {
    return this.screebApiHelper
      .delete<object>(
        `/org/${workspaceID}/record/${recordId}/comment/${commentId}`,
      )
      .toPromise()
      .then(() => {});
  }

  public addTags(
    workspaceID: string,
    recordId: string,
    tags: string[],
  ): Promise<void> {
    return this.screebApiHelper
      .put<object>(`/org/${workspaceID}/record/${recordId}/tags`, { tags })
      .toPromise()
      .then(() => {});
  }

  public removeTag(
    workspaceID: string,
    recordId: string,
    tag: string,
  ): Promise<void> {
    return this.screebApiHelper
      .delete<object>(`/org/${workspaceID}/record/${recordId}/tags/${tag}`)
      .toPromise()
      .then(() => {});
  }

  public getSummary(workspaceID: string, ids: string[]): Promise<string> {
    return this.screebApiHelper
      .post<object>(`/org/${workspaceID}/record-summary`, { ids })
      .toPromise()
      .then((data: object): string => {
        return data["summary"];
      });
  }

  public getTags(workspaceID: string): Promise<string[]> {
    return this.screebApiHelper
      .get<object[]>(`/org/${workspaceID}/record-tags`)
      .toPromise()
      .then((data: object): string[] => {
        return data["tags"];
      });
  }
}
