import { Component, Input, OnInit } from "@angular/core";

import { GraphNode } from "components/builder/flow";
import { AnalyticsDao } from "models/analytics.dao";
import {
  AnalyticsFilter,
  AnalyticsFilterForResponse,
} from "models/analytics.filters.type";
import { ChannelDao } from "models/channel.dao";
import { Org } from "models/org.model";
import {
  RegistryEntry,
  RegistryEntrySourceFormatted,
  RegistryEntrySourceIsIntegration,
} from "models/registry.model";
import { presetsDefaultValue } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { TrackersService } from "services/trackers.service";
import { LanguageWithEmoji, UIService } from "services/ui.service";
import { CriteriaFilterStatsSurveyComponent } from "./filter-criteria.component";
import {
  fetchHiddenFieldKeys,
  fetchLanguageKeys,
  fetchTagsKeys,
  getAvailableFields,
  getOperatorOptions,
  getValueComponent,
  getValuePresetOptions,
} from "./filter-criteria-response";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import {
  NzDropdownButtonDirective,
  NzDropDownDirective,
  NzDropdownMenuComponent,
} from "ng-zorro-antd/dropdown";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NgIf } from "@angular/common";
import { FilterCriteriaFiltersComponent } from "./filters/filter-criteria-filters.component";
import { SanitizedMessageComponent } from "../../../../../builder/components/Cards/sanitized-message/sanitized-message.component";

@Component({
  selector: "survey-stats-filter-criteria-response",
  templateUrl: "./filter-criteria.component.html",
  styleUrls: ["./filter-criteria.component.scss"],
  imports: [
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzDropdownButtonDirective,
    NzDropDownDirective,
    NzIconDirective,
    NzDropdownMenuComponent,
    NgIf,
    FilterCriteriaFiltersComponent,
    SanitizedMessageComponent,
  ],
})
export class CriteriaFilterStatsResponseSurveyComponent
  extends CriteriaFilterStatsSurveyComponent
  implements OnInit
{
  @Input() public survey: Survey = null;
  @Input() public org: Org = null;
  @Input() public languages: LanguageWithEmoji[] = [];
  @Input() public registryEntriesIdentityProperty: RegistryEntry[] = [];
  @Input() public registryEntriesGroup: RegistryEntry[] = [];
  @Input() public registryEntriesEvent: RegistryEntry[] = [];
  @Input() public isDisplay = false;

  public getValuePresetOptions(f: AnalyticsFilter) {
    return getValuePresetOptions(f, this.survey, this.nodesByKey);
  }

  public hiddenFields: string[] = null;

  public title = "Filter responses";

  constructor(
    protected analyticsFilterService: AnalyticsFilterService,
    protected trackersService: TrackersService,
    protected channelDao: ChannelDao,
    protected analyticsDao: AnalyticsDao,
    protected uiService: UIService,
    protected featureFlaggingService: FeatureFlaggingService,
  ) {
    super(analyticsFilterService, channelDao, trackersService, uiService);
  }

  ngOnInit() {
    // Dirty hack but good enough for now
    if (window.location.hash === "#filter") {
      this.switchPanelVisibility(true);
    }
  }

  getOrg() {
    return this.org;
  }

  getSurvey() {
    return this.survey;
  }

  async initPanel() {
    if (this.isDisplay) {
      this.title = "Filter displays";
    }
  }

  protected closePanel() {
    super.closePanel();
  }

  protected async getAvailableFields() {
    await Promise.all([
      fetchHiddenFieldKeys(this.survey, this.analyticsDao).then(
        (hiddenFields) => {
          this.hiddenFields = hiddenFields;
        },
      ),
      fetchLanguageKeys(this.survey, this.analyticsDao, this.languages).then(
        (languages) => {
          this.availableLanguages = languages;
        },
      ),
      fetchTagsKeys(this.org, this.analyticsDao).then((tags) => {
        this.availableTags = tags;
      }),
    ]);

    const { nodes, nodesByKey, keysOptions } = getAvailableFields(
      this.survey,
      undefined,
      this.hiddenFields,
    );
    this.nodes = nodes;
    this.nodesByKey = nodesByKey;
    this.keysOptions = keysOptions;
  }

  protected getDefaultFilter(): AnalyticsFilter {
    return {
      type: "response",
      key: "source",
      operator: "in",
      values: Object.keys(RegistryEntrySourceFormatted).filter((e) => {
        if (RegistryEntrySourceIsIntegration[e]) {
          return false;
        }

        return true;
      }),
    };
  }

  /**
   * Filters CRUD / validation
   */
  public getOperatorOptions(filter: AnalyticsFilter) {
    return getOperatorOptions(
      filter,
      this.nodesByKey,
      this.registryEntriesIdentityProperty,
    );
  }

  public getValueComponent(f: AnalyticsFilterForResponse) {
    return getValueComponent(
      f,
      this.nodesByKey,
      this.registryEntriesIdentityProperty,
    );
  }

  public onPresetCompareChange(filter: AnalyticsFilter) {
    const node: GraphNode = this.nodesByKey[filter.key];
    if (!node) {
      (filter as any).values = null;
      (filter as any).action_correlation_ids = null;
      return;
    }

    if (!node.isPreset) {
      (filter as any).values = null;
      (filter as any).action_correlation_ids = null;
      return;
    }

    let values = [];
    if (node.isNPS) {
      values = presetsDefaultValue.nps;
    } else if (node.isCES) {
      values = presetsDefaultValue.ces;
    } else if (node.isCSAT) {
      values = presetsDefaultValue.csat;
    }
    // else if (n.isPMF)
    //   values = presetsDefaultValue['pmf'].buttons;

    const value = values.find((v) => v.emoji === (filter as any).value);
    if (!value || values.length === 0) {
      (filter as any).action_correlation_ids = null;
      (filter as any).values = null;
      return;
    }
  }
}
