import { Injectable } from "@angular/core";

import { ScreebApiHelper } from "helpers/screeb-api.helper";
import {
  adaptRegistryEntry,
  RegistryEntry,
  RegistryEntrySubject,
} from "./registry.model";

@Injectable()
export class RegistryDao {
  // It's not the best as the user will need to refresh the whole app to get the latest data
  // but as Screeb Admin do lot of duplicate requests, it's better to cache the data
  private cachedIdentityProperties: {
    [orgId: string]: { all: RegistryEntry[]; local: RegistryEntry[] };
  } = {};
  private cachedGroups: {
    [orgId: string]: {
      all: { group_types: RegistryEntry[]; groups: RegistryEntry[] };
      local: { group_types: RegistryEntry[]; groups: RegistryEntry[] };
    };
  } = {};
  private cachedEvents: {
    [orgId: string]: { all: RegistryEntry[]; local: RegistryEntry[] };
  } = {};
  private cachedTracks: {
    [orgId: string]: { all: RegistryEntry[]; local: RegistryEntry[] };
  } = {};
  private cachedScreens: {
    [orgId: string]: { all: RegistryEntry[]; local: RegistryEntry[] };
  } = {};

  constructor(private screebApiHelper: ScreebApiHelper) {}

  public getIdentityProperties(
    orgId: string,
    allLocations = true,
  ): Promise<RegistryEntry[]> {
    const key = allLocations ? "all" : "local";
    if (this.cachedIdentityProperties[orgId]?.[key]?.length) {
      return Promise.resolve(this.cachedIdentityProperties[orgId][key]);
    }
    return this.screebApiHelper
      .get(`/org/${orgId}/registry?include_subjects=identity.property`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          this.cachedIdentityProperties[orgId] ??= { all: [], local: [] };
          this.cachedIdentityProperties[orgId][key] = data
            .map(adaptRegistryEntry)
            .filter(({ location }) => location === "screeb" || allLocations);
          return this.cachedIdentityProperties[orgId][key];
        }
        return [];
      });
  }

  public getGroups(
    orgId: string,
    allLocations = true,
  ): Promise<{ group_types: RegistryEntry[]; groups: RegistryEntry[] }> {
    const key = allLocations ? "all" : "local";
    if (
      this.cachedGroups[orgId]?.[key]?.group_types?.length ||
      this.cachedGroups[orgId]?.[key]?.groups?.length
    ) {
      return Promise.resolve(this.cachedGroups[orgId][key]);
    }

    return this.screebApiHelper
      .get(
        `/org/${orgId}/registry?include_subjects=group_type,group&with_parents=true`,
      )
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data
            .map(adaptRegistryEntry)
            .filter(({ location }) => location === "screeb" || allLocations);
        }
        return [];
      })
      .then((registryEntries: RegistryEntry[]) => {
        this.cachedGroups[orgId] ??= {
          all: { group_types: [], groups: [] },
          local: { group_types: [], groups: [] },
        };
        this.cachedGroups[orgId][key] = {
          group_types: registryEntries.filter(
            (re) => re.subject === "group_type",
          ),
          groups: registryEntries.filter((re) => re.subject === "group"),
        };
        return this.cachedGroups[orgId][key];
      });
  }

  public getEvents(
    orgId: string,
    allLocations = true,
  ): Promise<RegistryEntry[]> {
    const key = allLocations ? "all" : "local";
    if (this.cachedEvents[orgId]?.[key]?.length) {
      return Promise.resolve(this.cachedEvents[orgId][key]);
    }

    return this.screebApiHelper
      .get(`/org/${orgId}/registry?include_subjects=track,screen`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          this.cachedEvents[orgId] ??= { all: [], local: [] };
          this.cachedEvents[orgId][key] = data
            .map(adaptRegistryEntry)
            .filter(({ location }) => location === "screeb" || allLocations);
          return this.cachedEvents[orgId][key];
        }
        return [];
      });
  }

  public getTracks(
    orgId: string,
    allLocations = true,
  ): Promise<RegistryEntry[]> {
    const key = allLocations ? "all" : "local";
    if (this.cachedTracks[orgId]?.[key]?.length) {
      return Promise.resolve(this.cachedTracks[orgId][key]);
    }

    return this.screebApiHelper
      .get(`/org/${orgId}/registry?include_subjects=track`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          this.cachedTracks[orgId] ??= { all: [], local: [] };
          this.cachedTracks[orgId][key] = data
            .map(adaptRegistryEntry)
            .filter(({ location }) => location === "screeb" || allLocations);
          return this.cachedTracks[orgId][key];
        }
        return [];
      });
  }

  public getScreens(
    orgId: string,
    allLocations = false,
  ): Promise<RegistryEntry[]> {
    const key = allLocations ? "all" : "local";
    if (this.cachedScreens[orgId]?.[key]?.length) {
      return Promise.resolve(this.cachedScreens[orgId][key]);
    }
    return this.screebApiHelper
      .get(`/org/${orgId}/registry?include_subjects=screen`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          this.cachedScreens[orgId] ??= { all: [], local: [] };
          this.cachedScreens[orgId][key] = data
            .map(adaptRegistryEntry)
            .filter(({ location }) => location === "screeb" || allLocations);
          return this.cachedScreens[orgId][key];
        }
        return [];
      });
  }

  public getBySubjectAndSlug(
    orgId: string,
    slug: string,
    includeSubject?: RegistryEntrySubject[] | null,
    excludeSubject?: RegistryEntrySubject[] | null,
  ): Promise<RegistryEntry[]> {
    includeSubject = includeSubject ?? [];
    excludeSubject = excludeSubject ?? [];

    const _includeSubject = includeSubject.join(",");
    const _excludeSubject = excludeSubject.join(",");

    return this.screebApiHelper
      .get(
        `/org/${orgId}/registry?include_subjects=${_includeSubject}&exclude_subjects=${_excludeSubject}&slug=${slug}`,
      )
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data.map(adaptRegistryEntry);
        }
        return [];
      });
  }

  public getByParentIdAndSlug(
    orgId: string,
    parentId: string,
    slug: string,
  ): Promise<RegistryEntry[]> {
    return this.screebApiHelper
      .get(`/org/${orgId}/registry?parent_id=${parentId}&slug=${slug}`)
      .toPromise()
      .then((data: object) => {
        if (Array.isArray(data)) {
          return data.map(adaptRegistryEntry);
        }
        return [];
      });
  }

  public updateFavorite(
    orgId: string,
    ids: string[],
    favorite: boolean,
  ): Promise<any> {
    return this.screebApiHelper
      .put(`/org/${orgId}/registry/${ids.join(",")}`, { favorite })
      .toPromise();
  }

  public updateHidden(
    orgId: string,
    ids: string[],
    hidden: boolean,
  ): Promise<any> {
    return this.screebApiHelper
      .put(`/org/${orgId}/registry/${ids.join(",")}`, { hidden })
      .toPromise();
  }

  public remove(orgId: string, ids: string[]): Promise<any> {
    return this.screebApiHelper
      .delete(`/org/${orgId}/registry/${ids.join(",")}`)
      .toPromise();
  }
}
