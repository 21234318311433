<utils-lateral-panel *ngIf="!isSuggesting" (onClose)="onClose.emit($event)">
  <div class="lateral-header-left">
    <button
      *ngIf="node.index > 0"
      (click)="goToPreviousNode.emit()"
      class="previous"
    >
      {{ node.index <= 0 ? "" : "<" }}
    </button>
    <button
      *ngIf="node.index === 0"
      (click)="onClose.emit($event)"
      class="previous"
    >
      <i nz-icon nzType="close"></i>
    </button>
    <card-type
      [letter]="node.name"
      [vertical]="false"
      [questionType]="
        !node.node.question.type || node.node.question.type === 'survey'
          ? node.questionType
          : node.node.question.type
      "
    ></card-type>
    <h2 class="title">{{ panelTitle }}</h2>
  </div>
  <div class="lateral-header-right">
    <div class="next">
      <button
        *ngIf="node.index < builderStore.nodes.length - 1"
        (click)="goToNextNode.emit()"
        class="next"
      >
        {{ node.index >= builderStore.nodes.length - 1 ? "" : ">" }}
      </button>
    </div>
  </div>

  <div class="lateral-body">
    <div
      class="question-mode-switch"
      *ngIf="
        clonedNode.node.question.call_to_action.type !== 'appstore_rating' &&
        clonedNode.node.question.call_to_action.type !== 'conditional' &&
        clonedNode.node.question.type === 'survey'
      "
    >
      <nz-radio-group
        [ngModel]="questionMode"
        (ngModelChange)="setQuestionMode($event)"
        nzButtonStyle="solid"
        nzSize="large"
      >
        <label nz-radio-button nzValue="text">✍️&nbsp;&nbsp;Text</label>
        <label nz-radio-button nzValue="video">📹&nbsp;&nbsp;Video</label>
      </nz-radio-group>
    </div>
    <div class="lateral-body-content">
      <!-- messages -->
      <ng-container
        *ngFor="
          let questionMessage of clonedNode.node.question.type === 'survey'
            ? clonedNode.node.question.messages
            : [];
          let msgIndex = index
        "
      >
        <div
          class="question-message question-message-type-{{
            questionMessage.type.replace('_', '-')
          }}"
        >
          <ng-container *ngIf="questionMessage.type === 'text'">
            <lateral-panel-edit-text
              [node]="clonedNode"
              [message]="questionMessage"
              [nextQuestionPickerEnabled]="
                clonedNode.node.question.call_to_action.type === 'none'
              "
              [registryEntriesIdentityProperty]="
                registryEntriesIdentityProperty
              "
              (errorChange)="
                messageErrors[msgIndex] = $event; onErrorChange($event)
              "
              [(language)]="language"
              (suggest)="isSuggesting = true"
            ></lateral-panel-edit-text>
          </ng-container>
          <ng-container *ngIf="questionMessage.type === 'video'">
            <lateral-panel-edit-video
              [node]="clonedNode"
              [message]="questionMessage"
              [nextQuestionPickerEnabled]="
                clonedNode.node.question.call_to_action.type === 'none'
              "
              (errorChange)="
                messageErrors[msgIndex] = $event; onErrorChange($event)
              "
              [(language)]="language"
              (suggest)="isSuggesting = true"
            ></lateral-panel-edit-video>
          </ng-container>
        </div>
      </ng-container>

      <!-- CTA -->
      <div
        class="question-message question-message-type-{{
          node.questionType.replace('_', '-')
        }}"
      >
        <ng-container
          *ngIf="
            clonedNode.node.question.type === 'survey' &&
            ['multiple_choice', 'pmf'].includes(
              clonedNode.node.question.call_to_action.type
            )
          "
        >
          <lateral-panel-edit-button
            [node]="clonedNode"
            (onEmojiPickerOpen)="emojiPickerConfig = $event"
            (onOptionAdded)="onOptionAdded()"
            (errorChange)="onErrorChange($event)"
            [language]="language"
            [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
          ></lateral-panel-edit-button>
        </ng-container>

        <ng-container
          *ngIf="
            clonedNode.node.question.type === 'survey' &&
            ['scoring', 'nps', 'ces', 'csat'].includes(
              clonedNode.node.question.call_to_action.type
            )
          "
        >
          <lateral-panel-edit-score
            [node]="clonedNode"
            [language]="language"
            (onEmojiPickerOpen)="emojiPickerConfig = $event"
            (onOptionAdded)="onOptionAdded()"
            (errorChange)="onErrorChange($event)"
            [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
          ></lateral-panel-edit-score>
        </ng-container>

        <ng-container
          *ngIf="
            clonedNode.node.question.type === 'survey' &&
            ['input'].includes(clonedNode.node.question.call_to_action.type) &&
            ((clonedNode.node.question.type === 'survey' &&
              clonedNode.node.question.messages?.[0]?.type !== 'video') ||
              ('video_question' | hasEntitlement))
          "
        >
          <lateral-panel-edit-input
            [node]="clonedNode"
            (errorChange)="onErrorChange($event)"
            [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
          ></lateral-panel-edit-input>
        </ng-container>

        <ng-container
          *ngIf="
            clonedNode.node.question.type === 'survey' &&
            ['link'].includes(clonedNode.node.question.call_to_action.type)
          "
        >
          <lateral-panel-edit-link
            [node]="clonedNode"
            [language]="language"
            (onOptionAdded)="onOptionAdded()"
            (onEmojiPickerOpen)="emojiPickerConfig = $event"
            (errorChange)="onErrorChange($event)"
          ></lateral-panel-edit-link>
        </ng-container>

        <ng-container
          *ngIf="
            clonedNode.node.question.type === 'survey' &&
            ['calendar'].includes(clonedNode.node.question.call_to_action.type)
          "
        >
          <lateral-panel-edit-calendar
            [node]="clonedNode"
            [language]="language"
            (errorChange)="onErrorChange($event)"
          ></lateral-panel-edit-calendar>
        </ng-container>

        <ng-container
          *ngIf="
            clonedNode.node.question.type === 'survey' &&
            ['range'].includes(clonedNode.node.question.call_to_action.type)
          "
        >
          <lateral-panel-edit-range
            [node]="clonedNode"
            (errorChange)="onErrorChange($event)"
            [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
          ></lateral-panel-edit-range>
        </ng-container>

        <ng-container
          *ngIf="
            availableQuestionTypeMessage.includes(clonedNode.node.question.type)
          "
        >
          <lateral-panel-edit-message
            [node]="clonedNode"
            (errorChange)="onErrorChange($event)"
            (openTagEditorAtIndex)="openTagEditorAtIndex.emit($event)"
          ></lateral-panel-edit-message>
        </ng-container>

        <ng-container
          *ngIf="
            clonedNode.node.question.type === 'survey' &&
            clonedNode.node.question.call_to_action.type === 'conditional'
          "
        >
          <lateral-panel-edit-conditional
            [org]="builderStore.org"
            [node]="clonedNode"
            [surveys]="surveys"
            [registryEntriesIdentityProperty]="registryEntriesIdentityProperty"
            [registryEntriesGroup]="registryEntriesGroup"
            [registryEntriesEvent]="registryEntriesEvent"
            (errorChange)="onErrorChange($event)"
          ></lateral-panel-edit-conditional>
        </ng-container>
      </div>

      <!-- Integration: Webhook, Zapier... -->
      <lateral-panel-integrations
        *ngIf="
          isIntegrationsEnabled() &&
          clonedNode.node.question.type === 'survey' &&
          (clonedNode.node.question.messages?.[0]?.type !== 'video' ||
            ('video_question' | hasEntitlement))
        "
        [org]="builderStore.org"
        [survey]="builderStore.survey"
        [node]="clonedNode.node"
        [(integrations)]="clonedNode.node.integrations"
        (validChange)="integrationsAreValid = $event"
      ></lateral-panel-integrations>

      <p class="appstoreRatingDescription" *ngIf="isCTAType('appstore_rating')">
        This will open Android or iOS App Store Rating dialog.
        <br />
        Opening is not guaranteed due to Apple and Google's rating dialog
        policy.
      </p>
    </div>
  </div>

  <div class="lateral-footer-left">
    <button
      nz-button
      nzType="default"
      nzSize="large"
      nzDanger
      (click)="remove($event)"
    >
      Delete
    </button>
  </div>
  <div class="lateral-footer-right">
    <button
      nz-button
      nzType="default"
      nzSize="large"
      (click)="onClose.emit($event)"
    >
      Cancel
    </button>
    <button
      class="ant-btn-secondary"
      nz-button
      nzType="default"
      nzSize="large"
      [disabled]="saveIsDisabled()"
      (click)="save($event)"
    >
      Ok
    </button>
  </div>
</utils-lateral-panel>

<lateral-panel-question-suggestion
  (suggestion)="onSuggested($event)"
  (onClose)="isSuggesting = false"
  *ngIf="isSuggesting"
></lateral-panel-question-suggestion>

<!-- emoji picker -->
<lateral-panel-emoji-picker-popin
  [rightPosition]="emojiPickerConfig?.positionRight"
  [emoji]="emojiPickerConfig?.action.payload.emoji"
  (onClickOutside)="emojiPickerConfig = null"
  (onEmojiSelected)="onEmojiSelected($event)"
  [canBeNull]="emojiPickerConfig?.canBeNull"
  [whitelist]="emojiPickerConfig?.whitelist"
  *ngIf="!!emojiPickerConfig"
></lateral-panel-emoji-picker-popin>
