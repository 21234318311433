import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PermissionsService } from "services/permissions.service";
import { UIService } from "services/ui.service";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { NgIf } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { PermissionPipe } from "pipes/permission.pipe";

@Component({
  selector: "survey-stats-empty-dashboard",
  templateUrl: "./empty-dashboard.component.html",
  styleUrls: ["./empty-dashboard.component.scss"],
  imports: [
    NzRowDirective,
    NzColDirective,
    NgIf,
    NzButtonComponent,
    ɵNzTransitionPatchDirective,
    PermissionPipe,
  ],
})
export class EmptyDashboardStatsSurveyComponent {
  @Input()
  public title: string;
  @Input()
  public description1: string;
  @Input()
  public description2: string;
  @Input()
  public imgPath: string;

  @Input()
  public primaryButtonTxt: string = null;
  @Output()
  public primaryButtonCTA = new EventEmitter<boolean>();
  @Input()
  public secondaryButtonTxt: string = null;
  @Output()
  public secondaryButtonCTA = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public uiService: UIService,
    public permissionsService: PermissionsService,
  ) {}

  public getBackgroundImage(): string {
    return `url('${this.imgPath}')`;
  }
}
