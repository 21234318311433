import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SettingsHeaderComponent } from "components/settings/header/header.component";
import { ScreebIconComponent } from "components/utils/screeb-icon/screeb-icon.component";
import { TagInputComponent } from "components/utils/tag-input/tag-input.component";
import { NotificationHelper } from "helpers/notification.helper";
import { WorkspaceAccountRole } from "models/account-permissions";
import { SuperOrgDao } from "models/super-org.dao";
import {
  SuperOrg,
  SuperOrgFlagEmailDomainAuth,
  SuperOrgFlagEmailDomainAuthDefaultWorkspace,
} from "models/super-org.model";
import { NzSwitchComponent } from "ng-zorro-antd/switch";
import { RoutingService } from "services/routing.service";
import { deepCopy, deepEqual } from "utils/object";
import { SettingsWorkspaceAccessComponent } from "./components/workspace-access-edit.component";

@Component({
  selector: "super-org-settings-page",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
  imports: [
    SettingsWorkspaceAccessComponent,
    NzSwitchComponent,
    TagInputComponent,
    ScreebIconComponent,
    SettingsHeaderComponent,
    FormsModule,
  ],
})
export class SuperOrgSettingsPageComponent implements OnInit, OnDestroy {
  public title = "Organization settings";
  public name = "Organization settings";

  private obs: any = null;

  public superOrg: SuperOrg = null;
  public isLoading = true;

  public emailDomainAuthSave: SuperOrgFlagEmailDomainAuth = null;
  public canSave: boolean = false;

  public workspacePermissions: WorkspaceAccountRole[] = ["analyst", "editor"]; // not adding admin for security reasons
  public workspaces = [];

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private superOrgDao: SuperOrgDao,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe(async (data) => {
      this.superOrg = data.superOrg;
      this.isLoading = false;

      if (!this.superOrg.flags.email_domain_auth) {
        this.superOrg.flags.email_domain_auth = {
          enabled: false,
          extra_domains: [],
          default_workspaces: [],
        } as SuperOrgFlagEmailDomainAuth;
      }

      this.emailDomainAuthSave = deepCopy(
        this.superOrg.flags.email_domain_auth,
      );

      this.fetchWorkspaces();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public updateDefaultWorkspaces(
    defaultWorkspaces: SuperOrgFlagEmailDomainAuthDefaultWorkspace[],
  ) {
    this.emailDomainAuthSave.default_workspaces = defaultWorkspaces;

    this.updateCanSave();
  }

  private fetchWorkspaces() {
    this.superOrgDao
      .getWorkspaces(this.superOrg.id)
      .then((workspaces) => {
        this.workspaces = workspaces;
      })
      .catch((error) => {
        this.notificationHelper.trigger(
          "Error",
          "Failed to fetch workspaces",
          "error",
          5,
        );

        console.error(error);
      });
  }

  public updateCanSave() {
    this.canSave = !deepEqual(
      this.superOrg.flags.email_domain_auth,
      this.emailDomainAuthSave,
    );

    if (!this.canSave) return;

    if (this.emailDomainAuthSave.default_workspaces.length === 0) {
      return;
    }

    this.canSave = this.emailDomainAuthSave.default_workspaces.every(
      (defaultWorkspace) => {
        return (
          this.workspaces.find(
            (workspace) => workspace.id === defaultWorkspace.workspace_id,
          ) !== undefined &&
          this.workspacePermissions.includes(defaultWorkspace.permission)
        );
      },
    );
  }

  public save() {
    if (!this.canSave) return;

    this.superOrgDao
      .updateEmailDomainAuth(this.superOrg.id, this.emailDomainAuthSave)
      .then(() => {
        this.notificationHelper.trigger(
          "Org Updated",
          "Email domain auth updated",
          "success",
          5,
        );

        this.superOrg.flags.email_domain_auth = deepCopy(
          this.emailDomainAuthSave,
        );
        this.updateCanSave();
      })
      .catch((error) => {
        this.notificationHelper.trigger(
          "Error",
          "Failed to update email domain auth",
          "error",
          5,
        );

        console.error(error);
      });
  }
}
