import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  AddonKey,
  notFreeSubscriptionOptions,
  PlanType,
} from "models/org_billing.model";
import { SettingsService } from "services/settings.service";
import { PlanTypeInfo } from "../../components/billing-plan/billing-plan.data";
import { NzCheckboxComponent } from "ng-zorro-antd/checkbox";
import { NgIf } from "@angular/common";
import { NzInputNumberComponent } from "ng-zorro-antd/input-number";
import { FormsModule } from "@angular/forms";
import { PricePipe } from "pipes/price.pipe";

@Component({
  selector: "billing-lateral-panel-addon",
  template: `
    <div class="addon" [class]="{ active: value }">
      <label
        nz-checkbox
        [(nzChecked)]="value"
        (nzCheckedChange)="onCheckChange($event)"
        (click)="qty !== undefined && addInputClick($event, true)"
      >
        <ng-container *ngIf="qty === undefined">
          {{ title }}
        </ng-container>

        <ng-container *ngIf="qty !== undefined">
          {{ prefix }}
          <nz-input-number
            [nzMin]="0"
            [nzDisabled]="qty <= 0"
            [(ngModel)]="qty"
            (click)="addInputClick($event)"
            (ngModelChange)="onQtyChange($event)"
          />
          {{ title }}
        </ng-container>
      </label>
      <span>{{ getPriceByAddonKey() | price }}/month</span>
    </div>
  `,
  styles: [
    `
      .addon {
        margin-top: 12px;
        width: 100%;
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        border: 1px solid #ceced7;
        transition: border-color 150ms ease-in-out;
        border-radius: 16px;

        &.active {
          border-color: #5e21f1;
        }

        label {
          ::ng-deep nz-input-number {
            height: 40px;
            width: 90px;
            border-radius: 8px;
            border: 1px solid #e5e5ed;
            margin: 0 8px;

            .ant-input-number-input-wrap {
              height: 100%;

              input {
                height: 100%;
                display: flex;
                align-items: center;
              }
            }
          }
        }

        ::ng-deep {
          .ant-checkbox-wrapper {
            display: flex;
            align-items: center;

            span {
              display: flex;
              align-items: center;
            }
          }

          .ant-checkbox {
            width: 24px;
            height: 24px;
            top: 0;

            &::after {
              border-radius: 50%;
            }

            .ant-checkbox-inner {
              width: inherit;
              height: inherit;
              border-radius: 50%;

              &::after {
                height: 10px;
                width: 7px;
                left: 27.5%;
              }
            }
          }
        }

        label,
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
          color: #303140;
        }

        span {
          color: #727387;
        }
      }
    `,
  ],
  styleUrls: ["../footer.component.scss"],
  imports: [
    NzCheckboxComponent,
    NgIf,
    NzInputNumberComponent,
    FormsModule,
    PricePipe,
  ],
})
export class SettingsBillingLateralPanelAddonComponent {
  @Input() addonKey: AddonKey;
  @Input() currentPlanType: PlanType;
  @Input() targetedSubscription: notFreeSubscriptionOptions;

  @Input() title: string;
  @Input() prefix: string;

  @Input() value: boolean;
  @Input() qty: number;

  @Output() checkChange = new EventEmitter<void>();
  @Output() valueChange = new EventEmitter<boolean>();
  @Output() qtyChange = new EventEmitter<number>();

  public plan: PlanTypeInfo;

  public validationLoading: boolean = false;

  constructor(private settingsService: SettingsService) {}

  public onCheckChange(newValue: boolean): void {
    if (this.qty !== undefined) {
      this.onQtyChange(newValue ? 1 : 0);
    }
    this.valueChange.emit(newValue);
    this.checkChange.emit();
  }

  public onQtyChange(newQty: number): void {
    this.qtyChange.emit(newQty);
    this.checkChange.emit();
  }

  public getPriceByAddonKey(): number {
    const planType = this.targetedSubscription.planType;
    if (planType !== "scale" && planType !== "advanced") {
      return 0;
    }

    const product =
      this.settingsService.billing.plans[planType]?.addons[this.addonKey];
    if (!product) {
      return 0;
    }

    return (
      product.per_mtu[this.targetedSubscription.mtu]?.monthly_billing_mrr ||
      product.monthly_billing_mrr
    );
  }

  public addInputClick($event: MouseEvent, justEnabled: boolean = false): void {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    let newValue = this.qty;

    if (justEnabled) {
      newValue = this.qty > 0 ? 0 : 1;
    }

    this.onQtyChange(newValue);
  }
}
