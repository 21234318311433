import { Serializable } from "./serializable.model";
import { RegistryEntrySource } from "./registry.model";
import { strFromU8, strToU8, unzlibSync } from "fflate";

export class UserRecordHighlight extends Serializable {
  constructor(
    public title?: string,
    public timecode_start?: number,
    public timecode_end?: number,
    public timestamp?: Date,
    public type?: "response" | "console" | "event" | "comment",
    public data?: string,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

export class UserRecordComment extends Serializable {
  constructor(
    public id?: string,
    public org_id?: string,
    public workspace_id?: string,
    public created_by?: string,
    public session_id?: string,
    public window_id?: string,
    public created_at?: Date,
    public emoji?: string,
    public comment?: string,
    public timecode?: number,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }
}

export enum UserRecordCompletionStatus {
  PENDING = 0,
  PROCESSING = 1,
  HIGHLIGHTS_DONE = 2,
  AI_DONE = 3,
  AI_ERROR = 4,
  TAGGING_DONE = 5,
  SMART_TAGGING_DONE = 6,
}

export class UserRecord extends Serializable {
  constructor(
    public id?: string,
    public org_id?: string,
    public workspace_id?: string,
    public user_id?: string,
    public session_id?: string,
    public window_id?: string,
    public source?: RegistryEntrySource,
    public context?: object,
    public created_at?: Date,
    public response_ids?: string[],
    public highlights?: UserRecordHighlight[],
    public tags?: string[],
    public comments?: UserRecordComment[],
    public summary?: string,
    public score?: number,
    public started_at?: Date,
    public ended_at?: Date,
    public duration?: number, // in milliseconds
    public completion_status?: UserRecordCompletionStatus,
    public seen_at?: Date,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);

    this.source = json["source"] as RegistryEntrySource;

    if (json["comments"]) {
      this.comments = json["comments"].map((c) =>
        new UserRecordComment().fromJson(c),
      );
    }

    if (json["highlights"]) {
      this.highlights = json["highlights"].map((h) =>
        new UserRecordHighlight().fromJson(h),
      );
    }

    return this;
  }
}

export class UserRecordSnapshot extends Serializable {
  constructor(
    public type?: number,
    public source?: number,
    public data?: string,
    public size_bytes?: number,
    public timestamp?: number,
    public delay?: number,
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  public unpackData(): any {
    const data = this.data
      ? JSON.parse(strFromU8(unzlibSync(strToU8(atob(this.data), true))))
      : undefined;
    // Source was removed from data to avoid useless duplication
    // Let's add it back for the player
    if (data) data.source = this.source;
    return data;
  }
}
