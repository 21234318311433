import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { AnalyticsDao } from "models/analytics.dao";
import { IndicatorStatsSurveyPageComponent } from "../indicator.component";
import { RoutingService } from "services/routing.service";
import { AnalyticsResponse } from "models/analytics.model";
import { IndustriesScores } from "resolvers/asset-industries-scores";
import {
  BarClickEvent,
  RepartitionIndicatorValues,
  RepartitionIndicatorStatsSurveyComponent,
} from "../components/repartition-indicator/repartition-indicator.component";
import { TrackersService } from "services/trackers.service";
import { ChartDataset } from "chart.js";
import { TrendIndicatorLegend } from "../components/trend-indicator/trend-indicator.config";
import {
  bucketToDatedAggregation,
  getDateAnswerValueBuckets,
  getIndustryAverageScore,
} from "../indicator.utils";
import {
  bucketToCsatAggregation,
  computeCsatHappyUsers,
  computeCsatPeriod,
  computeCsatRepartition,
  computeCsatVariationBewteenPeriod,
  CsatAggregation,
  csatEmojis,
  csatPerDateToChartDataset,
} from "./csat.aggregation";

import { Release } from "models/release.model";
import { Account } from "models/account.model";
import { UIService } from "services/ui.service";
import { EntitlementService } from "services/entitlement.service";
import { NgIf } from "@angular/common";
import { FiltersStatsSurveyComponent } from "../../filters/filters.component";
import { EmptyDashboardStatsSurveyComponent } from "../../empty-dashboard/empty-dashboard.component";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import { ErrorMessageComponent } from "../../../../../utils/error-message/error-message.component";
import { NzEmptyComponent } from "ng-zorro-antd/empty";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { SingleIndicatorStatsSurveyComponent } from "../components/single-indicator/single-indicator.component";
import { VuMeterIndicatorStatsSurveyComponent } from "../components/vu-meter-indicator/vu-meter-indicator.component";
import { TrendIndicatorStatsSurveyComponent } from "../components/trend-indicator/trend-indicator.component";

@Component({
  selector: "page-survey-stats-indicators-csat",
  templateUrl: "./csat.component.html",
  styleUrls: ["./csat.component.scss"],
  imports: [
    NgIf,
    FiltersStatsSurveyComponent,
    EmptyDashboardStatsSurveyComponent,
    NzSpinComponent,
    ErrorMessageComponent,
    NzEmptyComponent,
    NzRowDirective,
    NzColDirective,
    SingleIndicatorStatsSurveyComponent,
    VuMeterIndicatorStatsSurveyComponent,
    RepartitionIndicatorStatsSurveyComponent,
    TrendIndicatorStatsSurveyComponent,
  ],
})
export class CSATIndicatorStatsSurveyPageComponent
  extends IndicatorStatsSurveyPageComponent
  implements OnInit, OnDestroy
{
  public title = "Analyze - Client Satisfaction";
  public name = "Analyze client satisfaction";

  public csatPerDatePreviousPeriod: CsatAggregation[] = null;
  public csatPerDateCurrentPeriod: CsatAggregation[] = null;
  public csatPreviousPeriod: CsatAggregation = null;
  public csatCurrentPeriod: CsatAggregation = null;
  public csatVariation: CsatAggregation = null;
  public csatRepartition: RepartitionIndicatorValues = [];

  public industryAverageCsat = 50;

  public trendChartDataset: ChartDataset[] = [];
  public releases: Release[] = [];
  public orgAccounts: Account[] = [];
  public trendChartLegend: TrendIndicatorLegend = [
    {
      label: "Average low scores (1 to 2)",
      value: "Average low scores (1 to 2)",
      checked: true,
      color: "#F11672",
      hoverColor: "#F11672",
    },
    {
      label: "Average satisfaction",
      value: "Average satisfaction",
      checked: true,
      color: "#5E21F1",
      hoverColor: "#5E21F1",
    },
  ];

  constructor(
    router: Router,
    route: ActivatedRoute,
    routingService: RoutingService,
    analyticsDao: AnalyticsDao,
    analyticsFilterService: AnalyticsFilterService,
    trackersService: TrackersService,
    public uiService: UIService,
    public entitlementService: EntitlementService,
  ) {
    super(
      router,
      route,
      routingService,
      analyticsDao,
      analyticsFilterService,
      trackersService,
      uiService,
    );
  }

  public computeCsatHappyUsers = computeCsatHappyUsers;

  ngOnInit(): void {
    this.obs = this.route.data.subscribe((data) => {
      this.releases = data.releases;
      this.orgAccounts = data.orgAccounts;

      this.industryAverageCsat = getIndustryAverageScore(
        data.industriesScores as IndustriesScores,
        "csat",
        data.org.industry,
      );
    });

    this.init();
    this.resetFilters();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  async getResponseCallback(
    previousPeriod: AnalyticsResponse | null,
    currentPeriod: AnalyticsResponse | null,
  ) {
    this.csatPerDatePreviousPeriod = bucketToDatedAggregation(
      previousPeriod,
      (bucket) =>
        bucketToCsatAggregation(bucket, getDateAnswerValueBuckets, [
          this.survey.scenario,
        ]),
    );
    this.csatPerDateCurrentPeriod = bucketToDatedAggregation(
      currentPeriod,
      (bucket) =>
        bucketToCsatAggregation(bucket, getDateAnswerValueBuckets, [
          this.survey.scenario,
        ]),
    );

    this.trendChartDataset = csatPerDateToChartDataset(
      this.csatPerDateCurrentPeriod,
    );
    this.csatPreviousPeriod = computeCsatPeriod(this.csatPerDatePreviousPeriod);
    this.csatCurrentPeriod = computeCsatPeriod(this.csatPerDateCurrentPeriod);

    this.csatVariation = computeCsatVariationBewteenPeriod(
      this.csatPreviousPeriod,
      this.csatCurrentPeriod,
    );

    this.csatRepartition = computeCsatRepartition(
      currentPeriod,
      this.survey.scenario,
    );
  }

  public repartitionChartBarClick($event: BarClickEvent) {
    this.setFiltersToSpecificScores(
      [csatEmojis.findIndex((emoji) => emoji === $event.value.label) + 1],
      "csat",
    );
  }
}
