import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { TrackersService } from "services/trackers.service";
import { Post101Author } from "components/utils/card-101-link/card-101-link.model";
import { UIService } from "services/ui.service";
import { NgIf, NgClass } from "@angular/common";

@Component({
  selector: "card-101-link",
  templateUrl: "./card-101-link.component.html",
  styleUrls: ["./card-101-link.component.scss"],
  imports: [NgIf, NgClass],
})
export class Card101LinkComponent implements OnInit, OnChanges {
  @Input() public page: string = null;
  @Input() public title: string = null;
  @Input() public minuteRead: number = null;
  @Input() public url: string | ((currentOrgId: string) => string) = null;
  @Input() public color: string = null;
  @Input() public inverted = false;
  @Input() public author: Post101Author = null;
  @Input() public noShadow = false;
  @Input() public noIcon = false;
  @Input() public iconUrl = "/assets/icons/doc/101Icon.png";
  @Input() public preTitle: string = null;
  @Input() public description: string = null;
  @Input() public linearGradient: string = null;
  @Input() public imageLink: string = null;
  @Input() public contentAlign: "flex-start" | "center" | "flex-end" = "center";
  @Input() public backgroundAlign: "top" | "center" | "bottom" = "center";
  @Input() public backgroundSize: "cover" | "contain" = "cover";

  public currentURL: string = null;
  public currentImageURL: string = null;
  public internalLink = false;

  constructor(
    private router: Router,
    private trackersService: TrackersService,
    private uiService: UIService,
  ) {}

  ngOnInit() {
    this.currentURL = this.getUrl();
    this.currentImageURL = this.getImageUrl();
  }

  ngOnChanges() {
    const url = this.getUrl();
    this.internalLink = !!url && url.length > 0 && url[0] === "/";
    this.currentURL = url;
    this.currentImageURL = this.getImageUrl();
  }

  onClick() {
    const props = {
      page_url: window.location.pathname,
      page_title: this.page,
      title: this.title,
      redirect_url: this.getUrl(),
      color: this.color,
    };
    this.trackersService
      .newEventTrackingBuilder("Clicked on tutorial")
      .withProps(props)
      .build();

    if (this.internalLink) this.router.navigate([this.getUrl()]);
  }

  getUrl(): string | null {
    if (!this.url) return null;
    return typeof this.url === "string"
      ? this.url
      : this.url(this.uiService.currentOrgId);
  }

  public getImageUrl() {
    if (this.linearGradient) {
      return `linear-gradient(${this.linearGradient}), ${
        this.backgroundAlign
      } / ${this.backgroundSize} no-repeat${
        this.imageLink?.length ? ` url(${this.imageLink})` : ""
      }, linear-gradient(0deg, ${this.color}, ${this.color})`;
    }

    let background = "";
    if (this.imageLink?.length) {
      background += `url(${this.imageLink}) center ${this.backgroundAlign} no-repeat,`;
    }
    return background + `linear-gradient(0deg, ${this.color}, ${this.color})`;
  }
}
