/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input } from "@angular/core";
import { formatNumber } from "utils/number";
import { NgClass, NgIf } from "@angular/common";
import { NzSpinComponent } from "ng-zorro-antd/spin";

@Component({
  selector: "correlation-indicator",
  template: `
    <div class="indicator-label">{{ label }}</div>
    <div class="indicator-graphics" [ngClass]="{ loading }">
      <div class="graphics-progress" [style]="getProgressStyle()">
        <div class="graphics-progress-circle">
          <div class="graphics-progress-circle-value">
            {{ formatNumber(value, "number", 2) }}
          </div>
        </div>
      </div>
      <div class="graphics-bar-container">
        <div class="graphics-bar graphics-0" [style]="getBarStyle(0)"></div>
        <div class="graphics-bar graphics-1" [style]="getBarStyle(1)"></div>
        <div class="graphics-bar graphics-2" [style]="getBarStyle(2)"></div>
        <div class="graphics-bar graphics-3" [style]="getBarStyle(3)"></div>
        <div class="graphics-bar graphics-4" [style]="getBarStyle(4)"></div>
      </div>
      <div class="indicator-hint">{{ getIndicatorHint() }}</div>
    </div>
    <nz-spin
      class="indicator-loader"
      nzSize="large"
      nzSimple
      *ngIf="loading"
    ></nz-spin>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        position: relative;
      }
      .indicator-graphics {
        margin-top: 24px;
        width: 100%;
        transition: opacity 200ms;
      }
      .graphics-progress {
        height: 4px;
        background: var(--screeb-color-purple-500);
        position: relative;
        z-index: 1;
        transition: width 200ms;
      }
      .graphics-progress-circle {
        box-sizing: content-box;
        width: 12px;
        height: 12px;
        border: 4px solid var(--screeb-color-white);
        border-radius: 12px;
        background: var(--screeb-color-purple-500);
        position: absolute;
        right: -8px;
        top: -8px;
      }
      .graphics-progress-circle-value {
        position: absolute;
        width: 40px;
        text-align: center;
        left: -14px;
        top: -18px;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: var(--screeb-color-body-text-secondary);
      }
      .indicator-hint {
        margin-top: 4px;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: var(--screeb-color-body-text-secondary);
        width: 100%;
        text-align: center;
      }
      .graphics-bar-container {
        margin-top: -2px;
        width: 100%;
        display: flex;
      }
      .graphics-bar {
        height: 12px;
        transition: opacity 200ms;
      }
      .graphics-0 {
        background: var(--screeb-color-red-500);
      }
      .graphics-1 {
        background: var(--screeb-color-red-500);
      }
      .graphics-2 {
        background: var(--screeb-color-yellow-500);
      }
      .graphics-3 {
        background: var(--screeb-color-green-500);
      }
      .graphics-4 {
        background: var(--screeb-color-green-500);
      }
      .loading {
        opacity: 0.2;
      }
      .indicator-loader {
        margin-top: 12px;
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
  imports: [NgClass, NgIf, NzSpinComponent],
})
export class CorrelationIndicator {
  @Input() public label = "";
  @Input() public value = -0.5;
  @Input() public bounds: [number, number, number, number, number, number] = [
    -1, -0.5, -0.15, 0.15, 0.5, 1,
  ];
  @Input() public hints: [string, string, string, string, string] = [
    "Strong Negative",
    "Moderate Negative",
    "Neutral",
    "Moderate Positive",
    "Strong Positive",
  ];
  @Input() public loading = false;

  public formatNumber = formatNumber;

  getMinBound() {
    return this.bounds.reduce(
      (previousValue, currentValue) => Math.min(previousValue, currentValue),
      0,
    );
  }

  getMaxBound() {
    return this.bounds.reduce(
      (previousValue, currentValue) => Math.max(previousValue, currentValue),
      0,
    );
  }

  getInterval(index: number) {
    return this.bounds[index + 1] - this.bounds[index];
  }

  getBarStyle(index: number) {
    const min = this.getMinBound();
    const max = this.getMaxBound();
    const interval = this.getInterval(index);

    return `
      width: ${(interval * 100) / (max - min)}%;
      opacity: ${
        this.getActiveBoundIndex() === index &&
        index !== Math.round(this.bounds.length / 2) - 1 // ¯\_(ツ)_/¯
          ? 1
          : 0.3
      };
    `;
  }

  getProgressStyle() {
    return `width: ${((this.value + 1) * 100) / 2}%`;
  }

  getActiveBoundIndex() {
    const hintIndex =
      this.bounds.findIndex((bound) => {
        return this.value <= bound;
      }) ?? 0;

    return Math.max(0, hintIndex - 1);
  }

  getIndicatorHint() {
    return this.hints[this.getActiveBoundIndex()];
  }
}
