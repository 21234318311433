import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { getIndustryAverageScore } from "components/surveys/pages/stats/indicators/indicator.utils";
import { SurveyDao } from "models/survey.dao";
import { Survey } from "models/survey.model";
import { IndustriesScores } from "resolvers/asset-industries-scores";
import { UIService } from "services/ui.service";
import { NgIf, NgFor } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { RouterLink } from "@angular/router";
import { NzEmptyComponent } from "ng-zorro-antd/empty";
import { NzIconDirective } from "ng-zorro-antd/icon";
import {
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzTbodyComponent,
} from "ng-zorro-antd/table";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { IndicatorVariationComponent } from "../../../utils/indicator-variation/indicator-variation.component";
import { PermissionPipe } from "pipes/permission.pipe";

@Component({
  selector: "widget-last-surveys",
  templateUrl: "./last-surveys.component.html",
  styleUrls: ["./last-surveys.component.scss"],
  imports: [
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    RouterLink,
    NzEmptyComponent,
    NzIconDirective,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzTbodyComponent,
    NgFor,
    NzTooltipDirective,
    IndicatorVariationComponent,
    PermissionPipe,
  ],
})
export class WidgetLastSurveysComponent implements OnInit {
  public isLoading: boolean = true;

  public lastSurveys: Survey[] = [];
  public haveMore: boolean = false;
  public industryAverageResponseRate: number = 0;

  constructor(
    public uiService: UIService,
    private surveyDao: SurveyDao,
    private httpClient: HttpClient,
  ) {}

  async ngOnInit() {
    this.isLoading = true;

    try {
      const surveys = await this.surveyDao.getAllByOrgId(
        "survey",
        this.uiService.currentOrgId,
        false,
        false,
        false,
      );
      this.lastSurveys = surveys
        .sort((a, b) => +b.updated_at - +a.updated_at)
        .slice(0, 5);
      this.haveMore = surveys.length > 5;

      const scores = await this.httpClient
        .get<IndustriesScores>(`/assets/data/industries.scores.json`)
        .toPromise();

      this.industryAverageResponseRate = getIndustryAverageScore(
        scores,
        "completionRate",
        this.uiService.currentOrg.industry,
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      this.isLoading = false;
    }
  }
}
