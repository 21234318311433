import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { NgIf } from "@angular/common";
import { PageComponentInterface } from "components/PageComponentInterface";
import { NotificationHelper } from "helpers/notification.helper";
import { AccountDao } from "models/account.dao";
import {
  AccountFlagsOnboardingStatus,
  AccountJobTitlesToLabel,
} from "models/account.model";
import { OrgDao } from "models/org.dao";
import { Org } from "models/org.model";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzColDirective, NzRowDirective } from "ng-zorro-antd/grid";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { ScreebApiHelper, SessionService } from "services/auth.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import { SettingsService } from "services/settings.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";

@Component({
  selector: "page-onboarding-goal",
  templateUrl: "./onboarding-goal.component.html",
  styleUrls: [
    "./onboarding-goal.component.scss",
    "../layout/onboarding-layout.component.scss",
  ],
  imports: [
    NzRowDirective,
    NzColDirective,
    NzIconDirective,
    NgIf,
    ɵNzTransitionPatchDirective,
  ],
})
export class OnBoardingGoalPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Welcome to Screeb!";
  public name = "Onboarding goal";

  private obs: any = null;
  public org: Org = null;

  public accountJobTitles = AccountJobTitlesToLabel;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    private notificationHelper: NotificationHelper,
    private uiService: UIService,
    public sessionService: SessionService,
    public settingsService: SettingsService,
    public featureFlaggingService: FeatureFlaggingService,
    public screebApiHelper: ScreebApiHelper,
    public trackersService: TrackersService,
    public orgDao: OrgDao,
    public accountDao: AccountDao,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );
    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];

      this.onContinue();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public onContinue() {
    // Attente volontaire pour simuler une tache de fond pour l'utilisateur
    // l'interface affiche un chargement au moment de démarrer le timeout.
    setTimeout(() => {
      const trackEvent = this.trackersService
        .newEventTrackingBuilder("Screeb Onboarding Goal Completion Waiting")
        .withAccount(this.sessionService.session);

      if (this.org) {
        trackEvent.withOrg(this.org);
      }
      trackEvent.build();

      return this.onNext();
    }, 6000); // 6 seconds
  }

  onNext() {
    const hasScenarioToLoad = Boolean(
      localStorage.getItem("screeb-restore-scenario"),
    );
    const skipOnboarding =
      !this.sessionService.session.flags?.onboarding_status.includes(
        "qualification",
      ) &&
      !this.sessionService.session.flags?.onboarding_status.includes("goal");

    const isInvited = this.sessionService.isOnboardingInvited();

    const isCallOnboarding =
      this.sessionService.session.flags?.onboarding_status.includes("in-call");

    const nextStep = "setup";
    const nextOnboardingStatus = isCallOnboarding
      ? "product-tour"
      : (`${nextStep}${
          isInvited ? "-invited" : ""
        }` as AccountFlagsOnboardingStatus);

    if (skipOnboarding) {
      return this.accountDao.updateOnboardingStatus("done").then(() => {
        if (hasScenarioToLoad) {
          this.router.navigate(["org", this.org.id, "survey", "create"], {
            queryParams: {
              fromScenario: true,
            },
          });
        } else {
          this.router.navigate(["org", this.org.id]);
        }
      });
    }

    return this.accountDao
      .updateOnboardingStatus(nextOnboardingStatus)
      .then(() => {
        const trackEvent = this.trackersService
          .newEventTrackingBuilder("Screeb Onboarding Goal Done")
          .withAccount(this.sessionService.session);

        if (this.org) {
          trackEvent.withOrg(this.org);
        }
        trackEvent.build();

        if (hasScenarioToLoad && this.org) {
          this.router.navigate(["org", this.org.id, "survey", "create"], {
            queryParams: {
              fromScenario: true,
            },
          });
        } else {
          if (isInvited || isCallOnboarding || !this.org) {
            // redirect to the org overview page
            this.router
              .navigate(["overview", this.uiService.currentSuperOrgId])
              .then(() => {
                // Reload page to update billing status;
                window.location.reload();
              });

            return;
          }

          // this.router.navigate(["org", this.org.id]).then(() => {
          //   // Reload page to update billing status
          //   window.location.reload();
          // });
          this.router.navigate(["onboarding", nextStep]);
        }
      });
  }
}
