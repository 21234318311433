import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GraphNode } from "components/builder/flow";
import { ActionSkip, CTAInput, newCTASkip } from "models/survey.dao.types";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";
import { NzSelectComponent, NzOptionComponent } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { LateralPanelStepPickerComponent } from "../step-picker/step-picker.component";
import { RegistryEntry } from "models/registry.model";
import { NgIf } from "@angular/common";
import { LateralPanelVariablePickerComponent } from "../variable-picker/variable-picker.component";
import { FeaturePipe } from "pipes/feature.pipe";

@Component({
  selector: "lateral-panel-edit-input",
  templateUrl: "./edit-input.component.html",
  styleUrls: ["./edit-input.component.scss"],
  imports: [
    NgIf,
    NzSelectComponent,
    FormsModule,
    NzOptionComponent,
    LateralPanelStepPickerComponent,
    LateralPanelVariablePickerComponent,
    FeaturePipe,
  ],
})
export class LateralPanelEditInputComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() registryEntriesIdentityProperty: RegistryEntry[] = [];
  @Output() errorChange = new EventEmitter<boolean>();

  public variables: { value: string; label: string }[] = [];

  constructor(
    public builderStore: BuilderStore,
    public entitlementService: EntitlementService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public ngOnInit() {
    this.validateData();

    this.variables = this.registryEntriesIdentityProperty
      .filter((e) => e.type === "string")
      .map((entry) => ({
        value: entry.slug,
        label: entry.title,
      }));
  }

  public get cta(): CTAInput {
    const cta = this.node.node.question.call_to_action;
    if (cta.type !== "input") throw Error("unexpected action type for input");
    return cta;
  }

  public get skipAction(): ActionSkip {
    return (this.node.node.question.skip_action ??= newCTASkip());
  }

  public get variable(): string {
    return (
      (this.node.node.question.call_to_action as any)?.save_to_property || ""
    );
  }

  public set variable(variable: string) {
    (this.node.node.question.call_to_action as any).save_to_property = variable;
  }

  /**
   * Data validation
   */
  public validateData() {
    this.errorChange.emit(false);
  }
}
