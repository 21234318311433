import {
  AfterContentInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router,
  RouterOutlet,
} from "@angular/router";
import { formatDistance } from "date-fns";

import { NgClass, NgIf } from "@angular/common";
import { SurveyDao } from "models/survey.dao";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import {
  NzContentComponent,
  NzHeaderComponent,
  NzLayoutComponent,
  NzSiderComponent,
} from "ng-zorro-antd/layout";
import { AuthService, SessionService } from "services/auth.service";
import { EntitlementService } from "services/entitlement.service";
import { EventService, ScreebEventType } from "services/event.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import {
  AdminSettingsLastBannerAndModalClose,
  SettingsService,
} from "services/settings.service";
import { UIService } from "services/ui.service";
import { UpgradeAdminService } from "services/upgrade.service";
import { UpgradeBanner } from "../super-org/billing/banners/upgrade-banner.component";
import { UpgradeModal } from "../super-org/billing/banners/upgrade-modal.component";
import { LayoutBackHeaderComponent } from "./back/header/header.component";
import { LayoutBackSidebarIntegrationComponent } from "./back/sidebar-integration/sidebar-integration.component";
import { LayoutBackSidebarSettingsComponent } from "./back/sidebar-settings/sidebar-settings.component";
import { LayoutBackSidebarSharingComponent } from "./back/sidebar-sharing/sidebar-sharing.component";
import { LayoutBackSidebarStatsComponent } from "./back/sidebar-stats/sidebar-stats.component";
import { LayoutBackSidebarTemplateComponent } from "./back/sidebar-template/sidebar-template.component";
import { LayoutBackSidebarComponent } from "./back/sidebar/sidebar.component";

@Component({
  selector: "layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
  encapsulation: ViewEncapsulation.None,
  imports: [
    NgIf,
    UpgradeBanner,
    NzLayoutComponent,
    NgClass,
    UpgradeModal,
    NzSiderComponent,
    LayoutBackSidebarComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzHeaderComponent,
    LayoutBackHeaderComponent,
    LayoutBackSidebarStatsComponent,
    LayoutBackSidebarSharingComponent,
    LayoutBackSidebarSettingsComponent,
    LayoutBackSidebarIntegrationComponent,
    LayoutBackSidebarTemplateComponent,
    NzContentComponent,
    RouterOutlet,
  ],
})
export class LayoutComponent implements OnInit, AfterContentInit, OnDestroy {
  private obs = null;

  public menuCollapsed: boolean = false;
  public secondaryMenuCollapsed: boolean = false;
  public isMediumScreen: boolean = false;
  public isSmallScreen: boolean = false;

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    public authService: AuthService,
    public sessionService: SessionService,
    private eventService: EventService,
    public upgradeAdminService: UpgradeAdminService,
    public entitlementService: EntitlementService,
    public suveyDao: SurveyDao,
    public uiService: UIService,
    public settingsService: SettingsService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
  ) {}

  @HostListener("window:resize", [])
  onResize() {
    this.checkViewport();
  }

  ngOnInit() {
    this.obs = this.router.events.subscribe((e) => this.onRouterEvent(e));
    this.eventService.publish(ScreebEventType.ScreebLoaded, {});
  }

  ngAfterContentInit() {
    this.checkViewport();
  }

  ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
  }

  private checkViewport() {
    if (window.innerWidth < 1216) {
      this.menuCollapsed = true;
      this.isMediumScreen = true;

      if (window.innerWidth < 768) {
        this.secondaryMenuCollapsed = true;
        this.isSmallScreen = true;
      } else {
        this.secondaryMenuCollapsed = false;
        this.isSmallScreen = false;
      }
    } else {
      this.isMediumScreen = false;
      this.isSmallScreen = false;
      this.secondaryMenuCollapsed = false;
      this.menuCollapsed = !this.isSiderForcedOpen;
    }
  }

  private onRouterEvent(event) {
    switch (true) {
      case event instanceof NavigationEnd:
      case event instanceof NavigationCancel:
      case event instanceof NavigationError:
        this.onPageLoaded();
        break;
      default:
        break;
    }
  }

  private async onPageLoaded() {
    // Wait one tick to ensure the page is rendered and ui service is updated
    setTimeout(() => {
      this.checkViewport();
    });
  }

  public get isGlobalMenuVisible(): boolean {
    return this.sessionService.isAuth() && !this.uiService.isOnBoardingPage;
  }
  public get isSuperOrgSidebarVisible(): boolean {
    return this.sessionService.isAuth() && this.uiService.isSuperOrgPage;
  }
  public get isHeaderVisible(): boolean {
    return this.sessionService.isAuth() && !!this.uiService.currentSurvey;
  }
  public get isStatsColumnVisible(): boolean {
    return this.sessionService.isAuth() && this.uiService.isStatsPage;
  }
  public get isSharingColumnVisible(): boolean {
    return this.sessionService.isAuth() && this.uiService.isSharingPage;
  }
  public get isWorkspaceSettingsColumnVisible(): boolean {
    return (
      this.sessionService.isAuth() && this.uiService.isWorkspaceSettingsPage
    );
  }
  public get isIntegrationColumnVisible(): boolean {
    return this.sessionService.isAuth() && this.uiService.isIntegrationListPage;
  }
  public get isTemplateColumnVisible(): boolean {
    return this.sessionService.isAuth() && this.uiService.isTemplatePage;
  }
  public get isSiderForcedOpen(): boolean {
    return this.sessionService.isAuth() && this.uiService.isFullSiderPage;
  }

  public get hasSecondaryMobileTrigger(): boolean {
    return (
      this.isStatsColumnVisible ||
      this.isSharingColumnVisible ||
      this.isWorkspaceSettingsColumnVisible ||
      this.isIntegrationColumnVisible ||
      this.isTemplateColumnVisible
    );
  }

  public get hasNoSider(): boolean {
    return this.uiService.isAuthPage;
  }

  public getRemainingFreeTrialDays(): string {
    return formatDistance(
      this.uiService.currentSuperOrg?.flags?.last_trial_end_date,
      new Date(),
      { addSuffix: true },
    );
  }

  public onClickMenuCollapse() {
    this.menuCollapsed = !this.menuCollapsed;
  }

  public onClickSecondMenuCollapse() {
    this.secondaryMenuCollapsed = !this.secondaryMenuCollapsed;
  }

  onLayoutZone3Click() {
    if (!this.isSiderForcedOpen) {
      this.menuCollapsed = true;
    }
  }

  private persistTimeSinceLastBannerClose<
    K extends keyof AdminSettingsLastBannerAndModalClose,
  >(key: K) {
    this.settingsService.setAdminSettingsKey(key, new Date().getTime());
  }

  private timeSinceLastBannerClose<
    K extends keyof AdminSettingsLastBannerAndModalClose,
  >(key: K) {
    const lastBannerCloseTimeFreeTrialEnded =
      this.settingsService.getAdminSettings()[key] ?? 0;

    return new Date().getTime() - lastBannerCloseTimeFreeTrialEnded;
  }

  closeIsMTUExceeded() {
    this.persistTimeSinceLastBannerClose("last_banner_close_time_mtu_exceeded");
  }

  closeIsMTUAlmostReachActive() {
    this.persistTimeSinceLastBannerClose(
      "last_banner_close_time_mtu_almost_reached",
    );
  }

  closeIsFreeTrialActive() {
    this.persistTimeSinceLastBannerClose(
      "last_banner_close_time_free_trial_active",
    );
  }

  closeIsFreeTrialEndedModal() {
    this.persistTimeSinceLastBannerClose(
      "last_modal_close_time_free_trial_ended",
    );
  }

  shouldShowMTUExceededBanner() {
    return (
      this.entitlementService.isMTUExceeded() &&
      this.timeSinceLastBannerClose("last_banner_close_time_mtu_exceeded") >
        24 * 60 * 60 * 1000 &&
      !this.uiService.isOnBoardingPage &&
      !this.uiService.isBillingPage
    );
  }

  shouldShowMTUAlmostReachedBanner() {
    return (
      this.entitlementService.isMTUAlmostReached() &&
      this.timeSinceLastBannerClose(
        "last_banner_close_time_mtu_almost_reached",
      ) >
        24 * 60 * 60 * 1000 &&
      !this.entitlementService.isMTUExceeded() &&
      !this.uiService.isOnBoardingPage &&
      !this.uiService.isBillingPage
    );
  }

  shouldShowFreeTrialActiveBanner() {
    return (
      this.entitlementService.isTrialing() &&
      this.timeSinceLastBannerClose(
        "last_banner_close_time_free_trial_active",
      ) >
        24 * 60 * 60 * 1000 &&
      !this.uiService.isOnBoardingPage &&
      !this.uiService.isBillingPage
    );
  }

  shouldShowFreeTrialEndedModal() {
    return (
      !this.entitlementService.hasSubscribed() &&
      this.entitlementService.isFreeTrialEnded() &&
      this.entitlementService.timeSinceFreeTrialEnded() <
        60 * 24 * 60 * 60 * 1000 &&
      this.timeSinceLastBannerClose("last_modal_close_time_free_trial_ended") >
        24 * 60 * 60 * 1000 &&
      !this.uiService.isOnBoardingPage &&
      !this.uiService.isBillingPage
    );
  }
}
