<utils-lateral-panel [withPaddingTop]="false" (onClose)="onClose.emit()">
  <div class="lateral-header-left">
    <button (click)="onClose.emit()" class="close">
      <i nz-icon nzType="close" nzTheme="outline"></i>
    </button>
    <h2 class="title" *ngIf="currentMode === 'subscription'">
      🔥 Upgrade to {{ planTypeInfo.name }}
    </h2>
    <h2 class="title" *ngIf="currentMode === 'change_billing_cycle'">
      🔥 Upgrade to Annual Billing
    </h2>
    <h2 class="title" *ngIf="currentMode === 'cancel'">
      😿 Downgrade to a Free Account
    </h2>
    <h2 class="title" *ngIf="currentMode === 'activate_addon'">
      🔥 Activate Add Ons
    </h2>
    <h2 class="title" *ngIf="currentMode === 'payment_methods_list'">
      Payment methods
    </h2>
    <h2 class="title" *ngIf="currentMode === 'invoices'">Invoices</h2>
    <h2 class="title" *ngIf="currentMode === 'payment_method_edit'">
      Payment Information
    </h2>
    <h2 class="title" *ngIf="currentMode === 'address'">Billing details</h2>
    <h2 class="title" *ngIf="currentMode === 'confirm_subscription'">
      Confirm Your Subscription
    </h2>
  </div>

  <div class="lateral-body">
    <div class="upgrade-payment" *ngIf="currentMode === 'change_billing_cycle'">
      <p class="subtitle">
        Opt for an annual billing cycle and save 20% on your subscription!
        Choosing this option will renew your subscription for 12 new months.
      </p>
      <ng-container *ngTemplateOutlet="BillingCycleFormTpl"></ng-container>
      <ng-container
        *ngTemplateOutlet="BillingSubscriptionSummaryTpl"
      ></ng-container>
    </div>

    <div
      class="subscription"
      *ngIf="currentMode === 'subscription' || currentMode === 'activate_addon'"
    >
      <ng-container *ngTemplateOutlet="UpgradePlanFormTpl"></ng-container>

      <ng-container
        *ngIf="
          targetedSubscription.planType === 'scale' ||
          targetedSubscription.planType === 'advanced'
        "
      >
        <h3>Monthly Active Users</h3>
        <p>
          How many Monthly Active Users or Monthly Unique Visitors do you have?
        </p>
        <billing-subscription-mtu-slider
          [minMtu]="minMAU"
          [maxMtu]="100000"
          [planType]="targetedSubscription.planType"
          [(mtu)]="targetedSubscription.mtu"
          (mtuChange)="refreshSubscriptionOptions()"
          (onClickUp)="trackEventMTU($event)"
        ></billing-subscription-mtu-slider>
      </ng-container>

      <ng-container
        *ngIf="
          targetedSubscription.planType === 'scale' ||
          targetedSubscription.planType === 'advanced'
        "
      >
        <h3 class="addons-title">🎟️ Add Ons</h3>
        <billing-lateral-panel-addon
          *ngIf="isAddonAvailableForPlan('white-label')"
          addonKey="white-label"
          [currentPlanType]="currentSubscription.planType"
          [targetedSubscription]="targetedSubscription"
          (checkChange)="refreshSubscriptionOptions()"
          [(value)]="targetedSubscription.addons.whiteLabel"
          title="Remove 'Powered by Screeb'"
        ></billing-lateral-panel-addon>

        <billing-lateral-panel-addon
          *ngIf="isAddonAvailableForPlan('graphical-survey-customization')"
          addonKey="graphical-survey-customization"
          [currentPlanType]="currentSubscription.planType"
          [targetedSubscription]="targetedSubscription"
          (checkChange)="refreshSubscriptionOptions()"
          [(value)]="targetedSubscription.addons.graphicalSurveyCustomization"
          title="Activate Advanced Survey Customization"
        ></billing-lateral-panel-addon>

        <billing-lateral-panel-addon
          *ngIf="isAddonAvailableForPlan('response-translation')"
          addonKey="response-translation"
          [currentPlanType]="currentSubscription.planType"
          [targetedSubscription]="targetedSubscription"
          (checkChange)="refreshSubscriptionOptions()"
          [(value)]="targetedSubscription.addons.responseTranslation"
          title="Activate Response Translation"
        ></billing-lateral-panel-addon>

        <billing-lateral-panel-addon
          *ngIf="isAddonAvailableForPlan('audio-video-survey')"
          addonKey="audio-video-survey"
          [currentPlanType]="currentSubscription.planType"
          [targetedSubscription]="targetedSubscription"
          (onCheckChange)="refreshSubscriptionOptions()"
          [(value)]="targetedSubscription.addons.audioVideoSurvey"
          title="Activate Audio/Video Survey"
        ></billing-lateral-panel-addon>

        <billing-lateral-panel-addon
          *ngIf="isAddonAvailableForPlan('survey-inapp-mobile')"
          addonKey="survey-inapp-mobile"
          [currentPlanType]="currentSubscription.planType"
          [targetedSubscription]="targetedSubscription"
          (checkChange)="refreshSubscriptionOptions()"
          [(value)]="targetedSubscription.addons.surveyInAppMobile"
          title="Activate Mobile SDKs"
        ></billing-lateral-panel-addon>

        <billing-lateral-panel-addon
          addonKey="additional-workspaces"
          [currentPlanType]="currentSubscription.planType"
          [targetedSubscription]="targetedSubscription"
          (checkChange)="refreshSubscriptionOptions()"
          [value]="targetedSubscription.addons.additionalWorkspaces > 0"
          [(qty)]="targetedSubscription.addons.additionalWorkspaces"
          prefix="Add"
          title="Workspace"
        ></billing-lateral-panel-addon>

        <billing-lateral-panel-addon
          *ngIf="isAddonAvailableForPlan('iam-mfa')"
          addonKey="iam-mfa"
          [currentPlanType]="currentSubscription.planType"
          [targetedSubscription]="targetedSubscription"
          (checkChange)="refreshSubscriptionOptions()"
          [(value)]="targetedSubscription.addons.iamMFA"
          title="Activate Two Factor Authentication"
        ></billing-lateral-panel-addon>

        <ng-container *ngTemplateOutlet="BillingCycleFormTpl"></ng-container>

        <h3>Coupon</h3>
        <billing-subscription-coupon-input
          [superOrgId]="superOrg.id"
          (coupon)="
            targetedSubscription.coupon = $event; refreshSubscriptionOptions()
          "
        ></billing-subscription-coupon-input>

        <ng-container
          *ngTemplateOutlet="BillingSubscriptionSummaryTpl"
        ></ng-container>
      </ng-container>
    </div>

    <billing-lateral-panel-cancel
      *ngIf="currentMode === 'cancel'"
      [superOrg]="superOrg"
      [currentPlanType]="currentSubscription.planType"
      (onSubscriptionUpdate)="waitForSubscription()"
      (onClose)="onClose.emit()"
    ></billing-lateral-panel-cancel>

    <billing-lateral-panel-invoices
      *ngIf="currentMode === 'invoices'"
      [superOrg]="superOrg"
      [orgBillingInvoices]="orgBillingInvoices"
    ></billing-lateral-panel-invoices>

    <billing-lateral-panel-payment
      *ngIf="currentMode === 'payment_methods_list'"
      [superOrg]="superOrg"
      [(orgBilling)]="orgBilling"
      [(orgBillingPaymentMethods)]="orgBillingPaymentMethods"
      [countries]="countries"
    ></billing-lateral-panel-payment>

    <billing-lateral-panel-payment-method
      *ngIf="currentMode === 'payment_method_edit'"
      [superOrg]="superOrg"
      [orgBilling]="orgBilling"
      [orgBillingPaymentMethods]="orgBillingPaymentMethods"
      [hasNextStep]="mode.length > 1"
      (orgBillingPaymentMethodsChange)="onPaymentMethodUpdate($event)"
      (onClose)="onClose.emit()"
    ></billing-lateral-panel-payment-method>

    <billing-lateral-panel-billing-info
      *ngIf="currentMode === 'address'"
      [superOrg]="superOrg"
      [orgBilling]="orgBilling"
      [hasNextStep]="mode.length > 1"
      [countries]="countries"
      (orgBillingChange)="onBillingInfoUpdate($event)"
      (onClose)="onClose.emit()"
    ></billing-lateral-panel-billing-info>

    <div
      class="confirm-subscription"
      *ngIf="currentMode === 'confirm_subscription'"
    >
      <p class="confirm-message">
        You’re one step away from subscribing to Screeb! Let’s confirm your
        choice.
      </p>

      <h3>Your Screeb Subscription:</h3>

      <ng-container
        *ngTemplateOutlet="BillingSubscriptionSummaryTpl"
      ></ng-container>
    </div>
  </div>

  <div
    class="lateral-footer-right"
    *ngIf="
      currentMode !== 'cancel' &&
      currentMode !== 'address' &&
      currentMode !== 'payment_method_edit'
    "
  >
    <intercom-nz-button
      *ngIf="priceError"
      class="contact-us"
      title="An error occurred, please contact us"
      nzType="primary"
      nzSize="large"
    ></intercom-nz-button>
    <ng-container *ngIf="!priceError">
      <button
        class="cancel-btn"
        nz-button
        nzType="default"
        nzSize="large"
        (click)="onClose.emit()"
      >
        {{ currentMode === "invoices" ? "Close" : "Cancel" }}
      </button>
      <button
        *ngIf="currentMode === 'payment_methods_list'"
        nz-button
        nzType="primary"
        nzSize="large"
        [nzLoading]="validationLoading"
        (click)="mode = ['payment_method_edit']"
      >
        Add Payment Method
      </button>
      <button
        *ngIf="
          currentMode === 'subscription' ||
          currentMode === 'change_billing_cycle'
        "
        nz-button
        nzType="primary"
        nzSize="large"
        [nzLoading]="validationLoading"
        (click)="subscribe()"
      >
        Upgrade Now
      </button>
      <button
        *ngIf="currentMode === 'activate_addon'"
        nz-button
        nzType="primary"
        nzSize="large"
        [nzLoading]="validationLoading"
        (click)="subscribe()"
      >
        Activate Add Ons Now
      </button>
      <button
        *ngIf="currentMode === 'confirm_subscription'"
        nz-button
        nzType="primary"
        nzSize="large"
        [nzLoading]="validationLoading"
        (click)="subscribe()"
      >
        Confirm your subscription
      </button>
    </ng-container>
  </div>
</utils-lateral-panel>

<ng-template #BillingSubscriptionSummaryTpl>
  <billing-subscription-invoice-summary
    *ngIf="!priceError"
    [subscriptionOptions]="targetedSubscription"
    (onPriceUpdate)="onPriceUpdated($event)"
    (onPriceError)="priceError = true"
  ></billing-subscription-invoice-summary>
</ng-template>

<ng-template #BillingCycleFormTpl>
  <ng-container
    *ngIf="
      targetedSubscription.planType !== 'free' &&
      (currentSubscription.planType === 'free' ||
        currentSubscription.billingCycle === 'month')
    "
  >
    <h3>Billing cycle</h3>
    <nz-radio-group
      [(ngModel)]="targetedSubscription.billingCycle"
      (ngModelChange)="
        refreshSubscriptionOptions(); trackEventBillingCycle($event)
      "
      nzButtonStyle="solid"
      nzSize="large"
    >
      <big-radio [nzValue]="'month'" label="Monthly"></big-radio>
      <big-radio
        [nzValue]="'year'"
        [label]="'Yearly (-' + YearlyDiscount * 100 + '% 🥳)'"
      ></big-radio>
    </nz-radio-group>

    <div class="billing-cycle-banner {{ targetedSubscription.billingCycle }}">
      <ng-container *ngIf="targetedSubscription.billingCycle === 'year'">
        Congrats! You’re saving €{{ discount }}/month for your company! 🥳
      </ng-container>
      <ng-container *ngIf="targetedSubscription.billingCycle !== 'year'">
        You’re missing out a {{ YearlyDiscount * 100 }}% discount opportunity!
        Save money by selecting the Annual Billing Cycle now!
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #UpgradePlanFormTpl>
  <ng-container
    *ngIf="
      targetedSubscription.planType !== 'free' &&
      (currentSubscription.planType === 'free' ||
        currentSubscription.planType === 'scale')
    "
  >
    <h3>Select plan</h3>
    <nz-radio-group
      [(ngModel)]="targetedSubscription.planType"
      (ngModelChange)="
        refreshSubscriptionOptions(); trackEventBillingPlanType($event)
      "
      nzButtonStyle="solid"
      nzSize="large"
    >
      <big-radio [nzValue]="'scale'" label="Plan Scale"></big-radio>
      <big-radio [nzValue]="'advanced'" [label]="'Plan Advanced'"></big-radio>
    </nz-radio-group>
  </ng-container>
</ng-template>
