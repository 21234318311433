import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GraphNode } from "components/builder/flow";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { ScreebIconComponent } from "../../../../utils/screeb-icon/screeb-icon.component";

@Component({
  selector: "lateral-panel-edit-message",
  templateUrl: "./edit-message.component.html",
  styleUrls: ["./edit-message.component.scss"],
  imports: [
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    ScreebIconComponent,
  ],
})
export class LateralPanelEditMessageComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Output() openTagEditorAtIndex = new EventEmitter<number>();
  @Output() errorChange = new EventEmitter<boolean>();

  constructor() {}

  public ngOnInit() {
    this.validateData();
  }

  /**
   * Data validation
   */
  public validateData() {
    this.errorChange.emit(false);
  }

  public onOpenPreviewClick() {
    this.openTagEditorAtIndex.emit(this.node.index);
  }
}
