import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ChartDataset } from "chart.js";
import { Account } from "models/account.model";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import {
  AnalyticsResponse,
  AnalyticsResponseItemResponseEmotions,
} from "models/analytics.model";
import { Release } from "models/release.model";
import { SurveyDistribution } from "models/survey-distribution.model";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { IndustriesScores } from "resolvers/asset-industries-scores";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { deepCopy } from "utils/object";
import {
  RadarIndicatorLegend,
  RadarIndicatorStatsSurveyComponent,
} from "../indicators/components/radar-indicator/radar-indicator.component";
import { TrendIndicatorLegend } from "../indicators/components/trend-indicator/trend-indicator.config";
import { IndicatorStatsSurveyPageComponent } from "../indicators/indicator.component";
import {
  computeEmotionsDataset,
  getIndustryAverageScore,
} from "../indicators/indicator.utils";
import {
  OverallPerformancesAggregation as SurveyAnalysisAggregation,
  getAggregations,
  getResponseCallback,
} from "./survey-analysis.aggregation";
import { FreeTrialBannerComponent } from "../../../../super-org/billing/banners/free-trial-banner.component";
import { FiltersStatsSurveyComponent } from "../filters/filters.component";
import { NgIf } from "@angular/common";
import { ErrorMessageComponent } from "../../../../utils/error-message/error-message.component";
import { SingleIndicatorStatsSurveyComponent } from "../indicators/components/single-indicator/single-indicator.component";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { NzEmptyComponent } from "ng-zorro-antd/empty";
import { TrendIndicatorStatsSurveyComponent } from "../indicators/components/trend-indicator/trend-indicator.component";

type SurveyStats = {
  distributions: SurveyDistribution[];
  lastEdit: Date;
};

@Component({
  selector: "page-survey-stats-survey-analysis",
  templateUrl: "./survey-analysis.component.html",
  styleUrls: ["./survey-analysis.component.scss"],
  imports: [
    FreeTrialBannerComponent,
    FiltersStatsSurveyComponent,
    NgIf,
    ErrorMessageComponent,
    SingleIndicatorStatsSurveyComponent,
    RadarIndicatorStatsSurveyComponent,
    NzRowDirective,
    NzColDirective,
    NzEmptyComponent,
    TrendIndicatorStatsSurveyComponent,
  ],
})
export class OverallPerformancesStatsSurveyPageComponent
  extends IndicatorStatsSurveyPageComponent
  implements OnInit, OnDestroy
{
  public title = "Analyze - Survey analytics";
  public name = "Analyze Survey analytics";

  public overallPerformancesPerDateCurrentPeriod: SurveyAnalysisAggregation[] =
    null;
  public overallPerformancesCurrentPeriod: SurveyAnalysisAggregation = null;
  public overallPerformancesVariation: SurveyAnalysisAggregation = null;

  public industryAverageResponseRate = 0;

  public surveyStats: SurveyStats = null;

  public releases: Release[] = [];
  public orgAccounts: Account[] = [];

  private industriesScores: IndustriesScores = null;
  private orgEmotions: AnalyticsResponseItemResponseEmotions = null;
  private surveyEmotions: AnalyticsResponseItemResponseEmotions = null;
  public loadingEmotions = true;

  public emotionsChartDatasets: ChartDataset[] = [];

  public trendChartDataset: ChartDataset[] = [];
  public trendChartLegend: TrendIndicatorLegend = [
    {
      label: "",
      value: "",
      checked: true,
      color: "#ff0000",
      hoverColor: "#ff0000",
      hidden: true,
    },
    {
      label: "Completion rate",
      value: "Completion rate",
      checked: false,
      color: "#0094FF",
      hoverColor: "#0094FF",
    },
    {
      label: "Response rate",
      value: "Response rate",
      checked: true,
      color: "#1ED5A4",
      hoverColor: "#1ED5A4",
    },
    {
      label: "Answers",
      value: "Answers",
      checked: true,
      color: "#0054B6",
      hoverColor: "#0054B6",
      format: "number",
    },
    {
      label: "Displays",
      value: "Displays",
      checked: true,
      color: "#5E21F1",
      hoverColor: "#5E21F1",
      format: "number",
    },
  ];

  public emotionsChartLegend: RadarIndicatorLegend = [
    {
      label: "Survey",
      value: "Survey",
      checked: true,
      disabled: false,
      color: "#0094FF",
      hoverColor: "#0094FF",
    },
    {
      label: "Workspace",
      value: "Workspace",
      checked: true,
      disabled: false,
      color: "#1ED5A4",
      hoverColor: "#1ED5A4",
    },
    {
      label: "Industry",
      value: "Industry",
      checked: true,
      disabled: false,
      color: "#0054B6",
      hoverColor: "#0054B6",
      format: "number",
    },
  ];

  constructor(
    router: Router,
    route: ActivatedRoute,
    routingService: RoutingService,
    analyticsDao: AnalyticsDao,
    analyticsFilterService: AnalyticsFilterService,
    public featureFlaggingService: FeatureFlaggingService,
    public uiService: UIService,
    trackersService: TrackersService,
  ) {
    super(
      router,
      route,
      routingService,
      analyticsDao,
      analyticsFilterService,
      trackersService,
      uiService,
    );
  }

  ngOnInit() {
    this.obs = this.route.data.subscribe((data) => {
      const survey = data.survey as Survey;

      this.releases = data.releases;
      this.orgAccounts = data.orgAccounts;

      this.surveyStats = {
        distributions: survey.survey_distributions,
        lastEdit: survey.updated_at,
      };

      this.industriesScores = data.industriesScores as IndustriesScores;
      this.industryAverageResponseRate = getIndustryAverageScore(
        data.industriesScores as IndustriesScores,
        "completionRate",
        data.org.industry,
      );

      this.init();
      this.resetFilters();
    });
  }

  ngOnDestroy() {
    this.destroy();
  }

  isBasedOnNodes() {
    return false;
  }

  // Get average org emotions
  private async getOrgEmotions(survey: Survey) {
    return this.analyticsDao
      .search({
        org_id: survey.org_id as UUID,
        ...this.lastFilters,
        survey_ids: ["*"],
        size: 0,
        offset: 0,
        aggregation: [{ by: "by_emotions" }],
        with_total: false,
      })
      .then((response) => {
        this.orgEmotions = computeEmotionsDataset(response);
      });
  }

  private async getSurveyEmotions(survey: Survey) {
    return this.analyticsDao
      .search({
        org_id: survey.org_id as UUID,
        ...this.lastFilters,
        survey_ids: [survey.id as UUID],
        size: 0,
        offset: 0,
        aggregation: [{ by: "by_emotions" }],
        with_total: false,
      })
      .then((response) => {
        this.surveyEmotions = computeEmotionsDataset(response as any);
      });
  }

  private updateEmotionsDatasets(scores: IndustriesScores) {
    try {
      const datasets = [];
      if (this.surveyEmotions) {
        datasets.push({
          label: "Survey",
          data: [
            // Let's have a minimum of 0.25 to fix not chart when we only have one positive emotion
            Math.max(this.surveyEmotions?.anger, 0.25),
            Math.max(this.surveyEmotions?.joy, 0.25),
            Math.max(this.surveyEmotions?.fear, 0.25),
            Math.max(this.surveyEmotions?.sadness, 0.25),
          ],
          fill: true,
          borderJoinStyle: "round",
          backgroundColor: "rgba(94, 33, 241, 0.2)",
          pointRadius: 0,
        });
      } else {
        this.emotionsChartLegend.find(
          (legend) => legend.value === "Survey",
        ).disabled = true;
      }

      if (this.orgEmotions) {
        datasets.push({
          label: "Workspace",
          data: [
            // Let's have a minimum of 0.25 to fix not chart when we only have one positive emotion
            Math.max(this.orgEmotions?.anger, 0.25),
            Math.max(this.orgEmotions?.joy, 0.25),
            Math.max(this.orgEmotions?.fear, 0.25),
            Math.max(this.orgEmotions?.sadness, 0.25),
          ],
          fill: true,
          borderJoinStyle: "round",
          backgroundColor: "rgba(30, 213, 164, .5)",
          pointRadius: 0,
        });
      } else {
        this.emotionsChartLegend.find(
          (legend) => legend.value === "Workspace",
        ).disabled = true;
      }

      if (this.org.industry && scores[this.org.industry]) {
        datasets.push({
          label: "Industry",
          data: scores[this.org.industry].scores.emotions.map((e) => e * 5),
          borderDash: [2, 2],
          fill: false,
          borderJoinStyle: "round",
          borderColor: "rgb(94, 33, 241)",
          borderWidth: 1.5,
          pointRadius: 0,
        });
      } else {
        this.emotionsChartLegend.find(
          (legend) => legend.value === "Industry",
        ).disabled = true;
      }
      this.emotionsChartDatasets = datasets;
    } catch (err) {
      console.error(err);
    }
  }

  async getResponseCallback(
    previousPeriod: AnalyticsResponse | null,
    currentPeriod: AnalyticsResponse | null,
    allTimePeriod: AnalyticsResponse | null,
  ) {
    if (allTimePeriod) {
      // Get emotions based on filters
      this.loadingEmotions = true;
      Promise.all([
        this.getSurveyEmotions(this.survey),
        this.getOrgEmotions(this.survey),
      ])
        .then(() => {
          this.updateEmotionsDatasets(
            this.industriesScores as IndustriesScores,
          );
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loadingEmotions = false;
        });
    }

    const {
      overallPerformancesPerDateCurrentPeriod,
      trendChartDataset,
      overallPerformancesCurrentPeriod,
      overallPerformancesVariation,
    } = await getResponseCallback(
      previousPeriod,
      currentPeriod,
      allTimePeriod,
      this.industryAverageResponseRate,
    );

    this.overallPerformancesPerDateCurrentPeriod =
      overallPerformancesPerDateCurrentPeriod;
    this.trendChartDataset = trendChartDataset;
    this.overallPerformancesCurrentPeriod = overallPerformancesCurrentPeriod;
    this.overallPerformancesVariation = overallPerformancesVariation;
  }

  protected buildCurrentPeriodQuery(): AnalyticsQueryResponse {
    return getAggregations(deepCopy(this.lastFilters), this.getNbrDateBucket());
  }
}
