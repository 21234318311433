import { Component, Input, OnInit } from "@angular/core";
import { AddonBillingSettings, PlanType } from "models/org_billing.model";
import { SettingsService } from "services/settings.service";
import {
  AddonInfo,
  addonInfos,
  displayedAddonKeys,
} from "./billing-plan/billing-plan.data";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NgIf } from "@angular/common";
import { PricePipe } from "pipes/price.pipe";

@Component({
  selector: "billing-addon-upgrade",
  template: `
    <div class="addon">
      <div
        class="illustration"
        style="
        background-image: linear-gradient(180deg, rgba(48, 49, 64, 0.03) 0%, rgba(48, 49, 64, 0.5) 100%), url('assets/preview/{{
          img ?? addonInfo.img
        }}')
      "
      ></div>
      <div class="info">
        <span class="title">{{ name ?? addonInfo.name }}</span>
        <button
          class="get-btn"
          title="Upgrade now"
          nz-button
          nzType="primary"
          nzSize="large"
        >
          <span>
            🎟️ {{ title ?? "Activate" }}
            <span *ngIf="price !== null">
              &nbsp;({{ price | price }}/month)
            </span>
          </span>
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
        /*min-width: 400px;*/
        cursor: pointer;
      }

      .addon {
        width: 100%;
      }

      .addon .illustration {
        width: 100%;
        height: 135px;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 16px;
      }

      .addon .info {
        display: flex;
        align-items: flex-start;
        margin-top: 12px;
        flex-wrap: wrap;
        gap: 0.5rem;
        justify-content: space-between;
      }

      .addon .info .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #303140;
      }

      .addon .info .get-btn {
        height: 40px;
        background-color: #ffffff;
        padding: 6px 16px;
        border: 1px solid #e5e5ed;
        box-shadow: none;
      }

      .addon .info .get-btn span {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #727387;
      }
    `,
  ],
  imports: [
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NgIf,
    PricePipe,
  ],
})
export class SettingsBillingAddonComponent implements OnInit {
  @Input() name: string = null;
  @Input() img: string = null;
  @Input() addonKey: displayedAddonKeys = null;
  @Input() planType: PlanType;
  @Input() planMTU: number = null;
  @Input() title: string = null;

  public addonInfo: AddonInfo;
  public price: number = null;

  constructor(private settingsService: SettingsService) {}

  ngOnInit(): void {
    this.addonInfo = addonInfos[this.addonKey];

    const product: AddonBillingSettings =
      this.settingsService.billing.plans[this.planType]?.addons[this.addonKey];

    this.price =
      product?.per_mtu[this.planMTU]?.monthly_billing_mrr ??
      product?.monthly_billing_mrr ??
      null;

    // If the price is 0, we don't want to display it.
    // It might happen with custom plans, with fixed-price addons or
    // when the amount of MTUs does not have self-serve price.
    if (this.price === 0) {
      this.price = null;
    }
  }
}
