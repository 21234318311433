import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ENV } from "environment";

export interface ScreebConfig {
  env: string;
  platform: string;
  region: string;

  hostname: string;
  apiEndpoint: string;
  selfEndpoint: string;
  staticEndpoint: string;
  tagEndpoint: string;
  tagBypassEndpoint: string;
  webhookEndpoint: string;
  hostedEndpoint: string;
  rpcEndpoint: string;
  centipedeEndpoint: string;
  statusEndpoint: string;

  sessionCookieName: string;

  sentryDsn: string;

  signupInvitedOnly: boolean;

  auth0Domain: string;
  auth0ClientId: string;

  // Tracking
  intercomTagAppId: string;
  screebTagChannelId: string;
  segmentTagId: string;
  fullstoryOrgId: string;
  hubspotId: string;
  linkedinPartnerId: string;
  gtmId: string;
}

@Injectable()
class ConfigService {
  public loader?: Promise<boolean> = null;
  public config: ScreebConfig;

  public latestAndroidVersion: string = "2.1.8";
  public latestIosVersion: string = "2.1.7";

  constructor() {}

  public isProd(): boolean {
    return this.config.env === "prod";
  }

  private getConfig(envs: object): ScreebConfig {
    return {
      env: envs["ENV"],
      platform: envs["PLATFORM"],
      region: envs["REGION"],

      hostname: envs["HOSTNAME"],
      apiEndpoint: envs["API_ENDPOINT"],
      selfEndpoint: envs["SELF_ENDPOINT"],
      staticEndpoint: envs["STATIC_ENDPOINT"],
      tagEndpoint: envs["TAG_ENDPOINT"],
      tagBypassEndpoint: envs["TAG_BYPASS_ENDPOINT"],
      webhookEndpoint: envs["WEBHOOK_ENDPOINT"],
      hostedEndpoint: envs["HOSTED_PAGE_ENDPOINT"],
      rpcEndpoint: envs["RPC_ENDPOINT"],
      centipedeEndpoint: envs["CENTIPEDE_ENDPOINT"],
      statusEndpoint: envs["STATUS_ENDPOINT"],

      sessionCookieName: envs["SESSION_COOKIE_NAME"],

      sentryDsn: envs["SENTRY_DSN"],

      signupInvitedOnly: envs["SIGNUP_INVITED_ONLY"] === true,

      auth0Domain: envs["AUTH0_DOMAIN"],
      auth0ClientId: envs["AUTH0_CLIENT_ID"],

      intercomTagAppId: envs["INTERCOM_TAG_APP_ID"],
      screebTagChannelId: envs["SCREEB_TAG_CHANNEL_ID"],
      segmentTagId: envs["SEGMENT_TAG_ID"],
      fullstoryOrgId: envs["FULLSTORY_ORG_ID"],
      hubspotId: envs["HUBSPOT_ID"],
      linkedinPartnerId: envs["LINKEDIN_PARTNER_ID"],
      gtmId: envs["GTM_ID"],
    };
  }

  public async loadConfig(handler: HttpBackend): Promise<void> {
    if (
      window.location.origin === ENV["SELF_ENDPOINT"] ||
      ENV["ENV"] !== "prod"
    ) {
      this.config = this.getConfig(ENV);
      return;
    }

    // We don't want to expose the config in source code
    // so we load it from a file in the assets folder
    const host = window.location.host;
    this.config = await new HttpClient(handler)
      .get(`/assets/config/${host}.json`)
      .toPromise()
      .then((data) => this.getConfig({ ...ENV, ...data }))
      .catch((err) => {
        console.error(err);
        return this.getConfig(ENV);
      }); // Default to ENV if the file is not found
  }
}

export { ConfigService };
