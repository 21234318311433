import { Component, Input } from "@angular/core";
import { NgIf } from "@angular/common";

export type SquareIconType =
  | "bye"
  | "response"
  | "survey"
  | "star"
  | "hourglass"
  | "cursor-click"
  | "at-symbol"
  | "tag"
  | "identification"
  | "calendar";

@Component({
  selector: "square-icon",
  templateUrl: "./square-icon.component.html",
  styleUrls: ["./square-icon.component.scss"],
  imports: [NgIf],
})
export class SquareIconComponent {
  @Input()
  public icon: SquareIconType = "response";

  @Input()
  public useContent: boolean = false;

  @Input()
  public emoji: string | null = null;

  public getBackgroundColor(icon: SquareIconType) {
    const style = getComputedStyle(document.body);
    switch (icon) {
      case "star":
      case "hourglass":
      case "response":
      case "bye":
        return style.getPropertyValue("--screeb-color-purple-alpha");
      case "cursor-click":
        return style.getPropertyValue("--screeb-color-green-alpha");
      case "at-symbol":
      case "tag":
      case "identification":
      case "calendar":
        return style.getPropertyValue("--screeb-color-blue-alpha");
      default:
        return style.getPropertyValue("--screeb-color-purple-alpha");
    }
  }
}
