import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import { AnalyticsResponse } from "models/analytics.model";

import { Response } from "models/response.model";
import { UUID } from "models/survey.dao.types";
import { User } from "models/user.model";
import { RouterLink } from "@angular/router";
import { NgIf, NgFor } from "@angular/common";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import { SquareIconComponent } from "../../../utils/square-icon/square-icon.component";
import { FormatDistanceToNowPipeModule } from "ngx-date-fns";
import { ToLocaleStringPipe } from "pipes/to-locale-string.pipe";

@Component({
  selector: "user-activity",
  templateUrl: "./user-activity.component.html",
  styleUrls: ["./user-activity.component.scss"],
  imports: [
    RouterLink,
    NgIf,
    NzSpinComponent,
    NgFor,
    SquareIconComponent,
    FormatDistanceToNowPipeModule,
    ToLocaleStringPipe,
  ],
})
export class UserActivityComponent implements OnInit, OnChanges {
  @Input() public user: User;

  public userResponsesES: Response[] = [];
  public loadingResponsesES = false;
  public errorResponsesES = null;
  public initialFetchResponsesES = true;

  public responsesCount: number = 0;
  public lastResponseDate: Date = null;

  constructor(private analyticsDao: AnalyticsDao) {}

  ngOnInit(): void {
    this.fetchResponses();
  }

  ngOnChanges({ user }: SimpleChanges): void {
    if (user?.currentValue?.id !== user?.previousValue?.id) {
      this.fetchResponses();
    }
  }

  private fetchResponses() {
    this.loadingResponsesES = true;
    this.errorResponsesES = null;

    const query: AnalyticsQueryResponse = {
      type: "response",
      org_id: UUID(this.user.org_id),
      survey_ids: ["*"],
      filters: [
        {
          type: "response",
          key: "respondent_aliases",
          operator: "eq",
          value: this.user.id,
        },
      ],
      filters_bool: "AND",
      range: {
        field: "created_at",
        start: new Date(this.user.created_at),
        end: new Date(),
      },
      sort: {
        field: "response.last_answer_at",
        order: "desc",
      },
      size: 1,
    };

    return this.analyticsDao
      .search(query)
      .then((analyticsResponse) => {
        this.userResponsesES = this.formatResponses(analyticsResponse);
      })
      .catch((error) => {
        this.errorResponsesES = error;
        console.error(error);
      })
      .finally(() => {
        this.loadingResponsesES = false;
        this.initialFetchResponsesES = false;
      });
  }

  private formatResponses({
    hits: { responses, total },
  }: AnalyticsResponse): Response[] {
    if (!responses) {
      return [];
    }

    const responsesWithAnswers = responses?.filter(({ answers }) =>
      Boolean(answers.length),
    );

    if (!responsesWithAnswers) {
      return [];
    }

    this.responsesCount = total;
    this.lastResponseDate = responsesWithAnswers.reduce(
      (mostRecentDate, { last_answer_at }) =>
        mostRecentDate > last_answer_at ? mostRecentDate : last_answer_at,
      new Date(0),
    );
  }
}
