/* eslint-disable @angular-eslint/no-output-on-prefix */

import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  NzTableQueryParams,
  NzTableSortOrder,
  NzTableComponent,
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzThAddOnComponent,
  NzTbodyComponent,
  NzCellAlignDirective,
} from "ng-zorro-antd/table";

import { Router, RouterLink } from "@angular/router";
import { NotificationHelper } from "helpers/notification.helper";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import {
  AnalyticsResponse,
  AnalyticsResponseItemResponse,
  AnalyticsResponseItemResponseHiddenField2,
  getFormatedUserFromResponse,
} from "models/analytics.model";
import { Org } from "models/org.model";
import { ResponseDao } from "models/response.dao";
import { UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { getUserIcon } from "models/user.model";
import { NzSelectOptionInterface } from "ng-zorro-antd/select";
import { ClipboardService } from "ngx-clipboard";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { TrackersService } from "services/trackers.service";
import { deepCopy } from "utils/object";
import { NgFor, NgClass, NgIf } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { TagInputComponent } from "../../../../../utils/tag-input/tag-input.component";
import { FormsModule } from "@angular/forms";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { FormatRelativeToNowPipeModule } from "ngx-date-fns";
import { UppercaseFirstPipe } from "pipes/uppercase-first.pipe";
import { PermissionPipe } from "pipes/permission.pipe";

@Component({
  selector: "survey-stats-individual-responses-displays-table-single-question",
  templateUrl: "./displays-table-single-question.component.html",
  styleUrls: ["./displays-table-single-question.component.scss"],
  imports: [
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzThAddOnComponent,
    NzTbodyComponent,
    NgFor,
    NgClass,
    NzCellAlignDirective,
    RouterLink,
    NgIf,
    NzButtonComponent,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NzRowDirective,
    NzColDirective,
    NzTagComponent,
    NzTooltipDirective,
    TagInputComponent,
    FormsModule,
    NzWaveDirective,
    FormatRelativeToNowPipeModule,
    UppercaseFirstPipe,
    PermissionPipe,
  ],
})
export class DisplaysTableSingleQuestionComponent implements OnInit, OnDestroy {
  private filtersObs$: any = null;
  private lastFilters: AnalyticsQueryResponse;

  public total: number;
  public pageSize = 10;
  public pageIndex = 1;
  public responseOrder: NzTableSortOrder = "descend";
  public responses: AnalyticsResponseItemResponse[];

  public expandedResponsesById: object = {};

  public error: Error;
  public loading = true;

  private skipNextParamChange = true;

  public today = new Date();

  public isAddingTag: object = {};

  public selectedAddedTag: object = {};

  public allOrgTags: NzSelectOptionInterface[] = [];

  @Input()
  public org: Org;
  @Input()
  public survey: Survey;
  /* eslint-disable-next-line @angular-eslint/no-input-rename */
  @Input("responses")
  public prefetchedResponses: AnalyticsResponseItemResponse[];
  @Input()
  public nodeCorrelationId?: string;
  @Input()
  public noPagination = false;
  @Input()
  public getQuery: (
    pageSize: number,
    pageIndex: number,
    responseOrder: NzTableSortOrder,
    nodeCorrelationId: string,
  ) => AnalyticsQueryResponse;
  @Input()
  public getAnswers = (response: AnalyticsResponseItemResponse) =>
    response.answers;

  @Output()
  onDelete = new EventEmitter();

  public getUserIcon = getUserIcon;

  public getFormatedUserFromResponse = getFormatedUserFromResponse;

  constructor(
    public router: Router,
    private notificationHelper: NotificationHelper,
    private analyticsFilterService: AnalyticsFilterService,
    private trackersService: TrackersService,
    private responseDao: ResponseDao,
    private analyticsDao: AnalyticsDao,
    private entitlementService: EntitlementService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    private clipboardService: ClipboardService,
  ) {}

  ngOnInit() {
    if (!this.prefetchedResponses) {
      // initial fetch is done automatically with onQueryParamsChange
      this.filtersObs$ = this.analyticsFilterService
        .subscribe()
        .subscribe((filters: AnalyticsQueryResponse) => {
          this.lastFilters = deepCopy(filters);
          this.getResponses();
        });
    } else {
      this.getResponses();
    }
    this.getAllOrgTags();
  }

  ngOnDestroy() {
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  public getAnswersNodeCorrelationId(
    item: AnalyticsResponseItemResponse,
    nodeCorrelationId?: string,
  ) {
    return nodeCorrelationId ?? this.getAnswers(item)[0]?.key;
  }

  private getResponses() {
    if (this.prefetchedResponses) {
      this.total = this.prefetchedResponses.length;
      this.responses = this.prefetchedResponses;
      this.loading = false;
      return;
    }

    this.loading = true;
    this.error = null;

    this.analyticsDao
      .search(
        this.getQuery(
          this.pageSize,
          this.pageIndex,
          this.responseOrder,
          this.nodeCorrelationId,
        ),
      )
      .then((res: AnalyticsResponse) => {
        this.total = res?.hits?.total;
        this.responses = res?.hits?.responses;
      })
      .catch((err: HttpErrorResponse) => {
        this.error = err;
        console.error(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public onQueryParamsChange(params: NzTableQueryParams) {
    const pageSize = params.pageSize;
    let pageIndex = params.pageIndex;
    if (pageSize !== this.pageSize) {
      pageIndex = 1;
    }
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;

    if (this.skipNextParamChange) {
      this.skipNextParamChange = false;
    } else {
      this.getResponses();
    }
  }
  public onHiddenFieldCopy(event: Event, text: string) {
    const target = (event.target as HTMLElement).parentElement;
    target.classList.add("copy");
    setTimeout(() => {
      target.classList.remove("copy");
    }, 200);

    this.clipboardService.copy(text);
    event.preventDefault();
    event.stopPropagation();
  }

  public onHiddenFieldClick(hfPath: string, hfValue: string) {
    this.analyticsFilterService.switchFilter({
      type: "response.hidden_field",
      key: hfPath,
      operator: "eq",
      value: hfValue,
    });

    const props = {
      filter_type: "response.hidden_field",
      filter_key: hfPath,
      filter_operator: "eq",
    };

    this.trackersService
      .newEventTrackingBuilder("Switched filter")
      .withOrg(this.org)
      .withSurvey(this.survey)
      .withProps(props)
      .build();
  }

  public onUserIdentifierClick(visitorUid: string) {
    this.analyticsFilterService.switchFilter({
      type: "response",
      key: "respondent_aliases",
      operator: "eq",
      value: visitorUid,
    });

    const props = {
      filter_type: "response",
      filter_key: "respondent_aliases",
      filter_operator: "eq",
    };

    this.trackersService
      .newEventTrackingBuilder("Switched filter")
      .withOrg(this.org)
      .withSurvey(this.survey)
      .withProps(props)
      .build();
  }

  public getUserInfoFromResponseHiddenFields(
    response: AnalyticsResponseItemResponse,
  ): AnalyticsResponseItemResponseHiddenField2[] {
    return response.hidden_fields2.filter(
      (hf: AnalyticsResponseItemResponseHiddenField2): boolean => {
        return ["locale", "timezone"].includes(hf.path);
      },
    );
  }
  public getNavigationInfoFromResponseHiddenFields(
    response: AnalyticsResponseItemResponse,
  ): AnalyticsResponseItemResponseHiddenField2[] {
    return response.hidden_fields2.filter(
      (hf: AnalyticsResponseItemResponseHiddenField2): boolean => {
        return [
          "support",

          "active_page.url",
          "active_page.title",
          "active_page.domain",
          "active_page.path",

          "active_screen.name",

          "campaign.name",
          "campaign.source",
          "campaign.medium",
          "campaign.term",
          "campaign.content",

          "os.name",
          "app.version",
          "device.model",
          "device.manufacturer",

          "user_agent",
          "browser.name",
          "browser.version",

          "library.name",
          "library.version",
        ].includes(hf.path);
      },
    );
  }
  public getOtherInfoFromResponseHiddenFields(
    response: AnalyticsResponseItemResponse,
  ): AnalyticsResponseItemResponseHiddenField2[] {
    return response.hidden_fields2.filter(
      (hf: AnalyticsResponseItemResponseHiddenField2): boolean => {
        return (
          hf.subject === "identity.property" ||
          hf.subject === "survey.hidden_field"
        );
      },
    );
  }

  public getAnswerTags(item: AnalyticsResponseItemResponse) {
    const answers = this.nodeCorrelationId
      ? item.getAnswers(this.nodeCorrelationId)
      : this.getAnswers(item);

    return answers[0]?.tags ?? [];
  }

  public getAllOrgTags() {
    this.analyticsDao
      .search({
        org_id: this.org.id as UUID,
        survey_ids: ["*"],
        filters_bool: "AND",
        type: "response",
        filters: [],
        size: 0,
        offset: 0,
        range: {
          field: "created_at",
          start: this.org.created_at,
          end: new Date(),
        },
        aggregation: [{ by: "by_tags" }],
        with_total: false,
      })
      .then((response) => {
        const tags = response.aggregations.answer.tags.buckets.map(
          (tag) => tag.key,
        );

        // remove duplicate
        this.allOrgTags = tags
          .filter((item, index) => tags.indexOf(item) === index)
          .map((tag) => ({ label: tag, value: tag }));
      });
  }

  public handleDeleteTag(
    userID: string,
    responseID: string,
    answerID: string,
    tag: string,
  ) {
    this.responseDao
      .removeTagsFromAnswer(this.org.id, userID, responseID, answerID, tag)
      .then(() => {
        this.notificationHelper.trigger("Tag removed", null, "success");

        this.allOrgTags.splice(
          this.allOrgTags.indexOf({ label: tag, value: tag }),
          1,
        );

        const answer = this.getAnswerFromIDs(responseID, answerID);

        answer.tags.splice(answer.tags.indexOf(tag), 1);
      })
      .catch(() => {
        this.notificationHelper.trigger("Could not remove tag", null, "error");
      });
  }

  public onAddTag(responseId: string) {
    this.isAddingTag[responseId] = true;
  }

  public onConfirmTag(userId: string, responseId: string, answerId: string) {
    this.isAddingTag[responseId] = false;

    Promise.all(
      this.responseDao.addTagsToAnswer(
        this.org.id,
        userId,
        responseId,
        answerId,
        this.selectedAddedTag[responseId],
      ),
    )
      .then(() => {
        this.notificationHelper.trigger("Tag added", null, "success");
        const answer = this.getAnswerFromIDs(responseId, answerId);
        answer.tags.push(...this.selectedAddedTag[responseId]);

        this.selectedAddedTag = [];
      })
      .catch(() => {
        this.notificationHelper.trigger("Could not add tag", null, "error");
      });
  }

  public getAnswer(item: AnalyticsResponseItemResponse) {
    return (
      this.nodeCorrelationId
        ? item.getAnswers(this.nodeCorrelationId)
        : this.getAnswers(item)
    )?.[0];
  }

  public getAnswerFromIDs(responseID: string, answerID: string) {
    const response = this.responses.filter(
      (response) => response.id === responseID,
    )[0];

    return response.answers.filter(
      (answer) => answer.answer_id === answerID,
    )[0];
  }
}
