import { Component, Input } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";
import { NgIf, NgClass } from "@angular/common";
import { NzInputDirective } from "ng-zorro-antd/input";
import { FormsModule } from "@angular/forms";
import { TargetingRuleOperatorComponent } from "../rule-operator/rule-operator.component";
import { EntitlementPipe } from "pipes/entitlement.pipe";

@Component({
  selector: "rule-dom-element",
  templateUrl: "./rule-dom-element.component.html",
  styleUrls: ["./rule-dom-element.component.scss"],
  imports: [
    NgIf,
    NzInputDirective,
    FormsModule,
    NgClass,
    TargetingRuleOperatorComponent,
    EntitlementPipe,
  ],
})
export class TargetingRuleDOMElementComponent {
  @Input()
  public rule: TargetingRule = null;

  constructor() {}

  public checkValidQuerySelector(r: TargetingRule): boolean {
    try {
      document.querySelector(r.value.v_s);
      return true;
    } catch (e) {
      return false;
    }
  }
}
