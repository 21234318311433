import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
  HttpHandler,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { Auth0Client } from "@auth0/auth0-spa-js";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { Account } from "models/account.model";
import { EventService, ScreebEventType } from "services/event.service";

import { AccountMFAStatus } from "components/settings/account/mfa/account-mfa.type";
import {
  AccountProviderResult,
  AccountProviderToConnection,
} from "components/settings/account/provider/account-provider.type";
import { ENV } from "environment";
import { freeTrialOptions } from "models/org_billing.model";
import { ConfigService } from "./config.service";
import { TrackersService } from "./trackers.service";

@Injectable()
class SessionService {
  constructor(
    private cookieService: CookieService,
    private configService: ConfigService,
  ) {}

  public session: Account = null;

  public create(session: Account): void {
    this.session = new Account().fromJson(JSON.parse(JSON.stringify(session))); // copy
  }
  public update(account: Account): void {
    this.session = new Account().fromJson(JSON.parse(JSON.stringify(account))); // copy
  }
  public destroy(): void {
    this.session = null;
    this.configService.loader = null;
  }

  private getSessionPayload(): object {
    const rawPayload = this.cookieService.get(
      this.configService.config.sessionCookieName,
    );
    if (!rawPayload) return null;

    try {
      const payload = window.atob(rawPayload);
      return JSON.parse(payload);
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  public hasSession(): boolean {
    return !!this.cookieService.get(
      this.configService.config.sessionCookieName,
    );
  }

  public isConnectedAs(): boolean {
    if (!this.isAuth()) return false;

    const payload = this.getSessionPayload();
    return (
      !!payload && !!payload["connect_as"] && payload["connect_as"] === true
    );
  }

  public isOnboardingInvited(): boolean {
    return this.session.flags.onboarding_status.includes("invited");
  }

  public isAuthenticated(): boolean {
    return !!this.session && !!this.session.id;
  }
  // alias
  public isAuth(): boolean {
    return this.isAuthenticated();
  }
}

interface IHttpOptions {
  body?: any;
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
  // observe?: HttpObserve;
  params?:
    | HttpParams
    | {
        [param: string]:
          | string
          | number
          | boolean
          | ReadonlyArray<string | number | boolean>;
      };
  reportProgress?: boolean;
  responseType?: "arraybuffer" | "blob" | "json" | "text";
  withCredentials?: boolean;

  // not standard
  // very usefull to avoid the redirection of intercept() function
  skipIntercept?: boolean;
}

@Injectable()
class ScreebApiHelper extends HttpClient {
  constructor(
    handler: HttpHandler,
    private router: Router,
    private configService: ConfigService,
    private sessionService: SessionService,
  ) {
    super(handler);
  }

  public getApiEndpoint(): string {
    return this.configService.config.apiEndpoint;
  }

  // `first` is either method or httprequest
  request(
    first: string | HttpRequest<any>,
    url?: string,
    options: IHttpOptions = {},
  ): Observable<any> {
    function addVersion(h: HttpHeaders): HttpHeaders {
      return h.append("x-screeb-admin-version", ENV["VERSION"]);
    }

    // ensures headers properties are not null
    if (!options) options = {};
    if (!options.headers) options.headers = addVersion(new HttpHeaders());
    if (typeof first !== "string" && !first.headers)
      first = (first as HttpRequest<any>).clone({
        headers: addVersion(new HttpHeaders()),
      });

    // prepend url with api endpoint and set xhr withCredentials flag
    if (!!url && typeof first === "string")
      url = this.configService.config.apiEndpoint + url;
    if (typeof first !== "string")
      first = (first as HttpRequest<any>).clone({
        url: this.configService.config.apiEndpoint + first.url,
        withCredentials: true,
        headers: addVersion((first as HttpRequest<any>).headers),
      });
    options.withCredentials = true;

    const obs = super.request(first as any, url, options);
    return this.intercept(obs, options.skipIntercept);
  }

  private intercept(
    observable: Observable<any>,
    skipIntercept: boolean,
  ): Observable<any> {
    if (skipIntercept === true) return observable;
    return observable.pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.sessionService.destroy();
          this.router.navigate(["/" + err.status]);
          return EMPTY;
        } else {
          return throwError(() => err);
        }
      }),
    );
  }

  private addBody(options: IHttpOptions, body: any): IHttpOptions {
    if (!options) options = {};
    if (!options.body) options.body = body;
    return options;
  }

  /**
   * Constructs an observable that, when subscribed, causes the configured
   * `PATCH` request to execute on the server. See the individual overloads for
   * details on the return type.
   */
  patch<T>(url, body, options = {}): Observable<T> {
    return this.request("PATCH", url, this.addBody(options, body));
  }
  /**
   * Constructs an observable that, when subscribed, causes the configured
   * `POST` request to execute on the server. The server responds with the location of
   * the replaced resource. See the individual overloads for
   * details on the return type.
   */
  post<T>(url, body, options = {}): Observable<T> {
    return this.request("POST", url, this.addBody(options, body));
  }
  /**
   * Constructs an observable that, when subscribed, causes the configured
   * `PUT` request to execute on the server. The `PUT` method replaces an existing resource
   * with a new set of values.
   * See the individual overloads for details on the return type.
   */
  put<T>(url, body, options = {}): Observable<T> {
    return this.request("PUT", url, this.addBody(options, body));
  }
}

export type AuthStatus = "idle" | "authenticating" | "authorizing";

@Injectable()
class AuthService {
  public authStatus: AuthStatus = "idle";
  private auth0Client: Auth0Client;

  constructor(
    private translateService: TranslateService,
    private trackersService: TrackersService,
    private eventService: EventService,
    private sessionService: SessionService,
    private router: Router,
    private screebApiHelper: ScreebApiHelper,
    private configService: ConfigService,
  ) {
    setInterval(this.checkStillAuthenticated.bind(this), 60 * 60 * 1000); // refresh profile at the end

    this.auth0Client = new Auth0Client({
      domain: configService.config.auth0Domain,
      clientId: configService.config.auth0ClientId,
      authorizationParams: {
        redirect_uri: `${window.location.origin}`,
      },
      // skipRedirectCallback: window.location.pathname.includes(
      //   "/auth/oauth2/callback", // for integrations
      // ),
      // this is crazy -> we need to set a client-side duration
      // sessionCheckExpiryDays: 30,
      cacheLocation: configService.isProd() ? null : "localstorage",
    });
  }

  /**
   * Session lifecycle
   */
  private responseSession(data: object) {
    const session = new Account().fromJson(data);
    this.sessionService.create(session);
    return session;
  }
  private onLoggedIn() {
    this.eventService.publish(ScreebEventType.AuthLogin, {});
  }
  private onLoggedOut() {
    this.eventService.publish(ScreebEventType.AuthLogout, {});
  }

  public getProfile(redirectOnFailure: boolean = true): Promise<any> {
    return (
      this.screebApiHelper
        .get<object>("/account/me", <object>{ skipIntercept: true })
        // .timeout(10000)
        .toPromise()
        .then(this.responseSession.bind(this))
        .catch((err: HttpErrorResponse) => {
          if (
            !!redirectOnFailure &&
            !!err &&
            (err.status === 401 || err.status === 403)
          )
            this.logout();
          console.error(err.error);
          throw err;
        })
    );
  }

  private checkStillAuthenticated() {
    // always call getProfile()
    // this function is responsible for logout in case of 401/403
    // this function also updates user profile, based on response
    this.getProfile().catch(() => {
      // logged out from getProfile
    });
  }

  private loadFreeTrialOptionsFromQueryParams(
    q: object,
  ): freeTrialOptions | null {
    if (!q["ft_mtu"] || !q["ft_bc"]) {
      return null;
    }

    const mtu = parseInt(q["ft_mtu"]);
    const billing_cycle = q["ft_bc"];
    const plan = q["ft_plan"];

    if (billing_cycle !== "month" && billing_cycle !== "year") {
      return null;
    }

    if (plan !== "scale" && plan !== "advanced") {
      return null;
    }

    return {
      plan,
      mtu,
      billing_cycle,
    } as freeTrialOptions;
  }

  /**
   * Login + signup
   */
  public redirectOnSignup(invitationEmail: string | null, queryParams: object) {
    const state = {
      ...queryParams,
      free_trial: this.loadFreeTrialOptionsFromQueryParams(queryParams),
    };

    this.auth0Client.loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin + "/auth/authorize",
        login_hint: invitationEmail,
        prompt: "login",
        screen_hint: "signup",
      },
      appState: state,
    });
  }
  public redirectOnLogin(state: object) {
    const reconnect = state["reconnect"] === "true";
    delete state["reconnect"];

    this.auth0Client.loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin + "/auth/authorize",
        //connection: "Username-Password-Authentication",
        prompt: reconnect ? "login" : undefined,
        screen_hint:
          state["source"] === "invitation-email" ? "signup" : "login",
      },
      appState: state,
    });
  }
  public handleRedirectCallback() {
    return this.auth0Client.handleRedirectCallback().then(async (result) => {
      const state = result.appState;
      const invitationOrgToken = state["invitation"] as string | null;
      const source = state["source"] as string | null; // CRM tracking
      const mode = state["mode"] as string | null; // Signup Mode (Self serve, or guided through business call)
      const freeTrial = state["free_trial"] as freeTrialOptions | null;
      const target = state["target"] as string | null;

      const hubspotutk = this.trackersService.getHubspotVisitorId();
      const lang = this.translateService.getBrowserLang();

      this.sessionService.destroy();

      const token = await this.auth0Client.getTokenSilently();

      return this.screebApiHelper
        .post<Account>(
          "/auth/authorize",
          {
            lang,
            source,
            mode,
            free_trial: freeTrial,
            hubspotutk,
            invitation_org_token: invitationOrgToken,
          },
          <object>{
            headers: { Authorization: `Bearer ${token}` },
            skipIntercept: true,
          },
        )
        .toPromise()
        .then(this.responseSession.bind(this))
        .then(() => this.onLoggedIn())
        .then(() => {
          this.router.navigateByUrl(target ? decodeURIComponent(target) : "/");
        });
    });
  }

  public async resendEmailVerification() {
    const token = await this.auth0Client.getTokenSilently();

    return this.screebApiHelper
      .post<object>(
        "/auth/resend-email-verification",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      .toPromise();
  }

  public loginGrantCallback(token: string): Promise<any> {
    this.sessionService.destroy();

    return this.screebApiHelper
      .post<object>("/auth/grant/callback", { token })
      .toPromise()
      .then(this.responseSession.bind(this))
      .then(() => this.onLoggedIn());
  }

  /**
   * Account linking
   */
  public getLinkedAccounts() {
    return this.screebApiHelper
      .get<AccountProviderResult>(`/auth/links`)
      .toPromise();
  }

  public openAccountLinkingPopup(provider: string) {
    return this.auth0Client
      .loginWithPopup({
        authorizationParams: {
          connection: AccountProviderToConnection[provider],
          prompt: "login",
          display: "popup",
        },
      })
      .then(() => {
        return this.auth0Client.getTokenSilently({
          cacheMode: "on",
        });
      })
      .then((token) => {
        this.screebApiHelper.post(
          `/account/link/${provider}`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      });
  }

  public unlinkAccount(provider: string) {
    return this.screebApiHelper.delete(`/auth/unlink/${provider}`).toPromise();
  }

  /**
   * MFA
   */
  public getMFAs() {
    return this.screebApiHelper
      .get<AccountMFAStatus[]>(`/auth/mfa`)
      .toPromise();
  }

  public enrollMFA(): Promise<void> {
    return this.screebApiHelper
      .post<void>(`/auth/mfa/enroll`, {})
      .toPromise()
      .then(() => {
        this.auth0Client.loginWithPopup({
          authorizationParams: {
            prompt: "login",
            display: "popup",
          },
        });
      });
  }

  public deleteMFA(): Promise<void> {
    return this.screebApiHelper.post<void>(`/auth/mfa/delete`, {}).toPromise();
  }

  public setMFA(enabled: boolean): Promise<void> {
    const body = {
      enabled,
    };

    return this.screebApiHelper.patch<void>(`/auth/mfa`, body).toPromise();
  }

  /**
   * Logout
   */
  public logout(redirect: boolean = true): Promise<any> {
    return (
      this.screebApiHelper
        .delete<object>("/auth/logout")
        .toPromise()
        //.then(() => redirect && this.router.navigate(["/auth/login"]))
        .then(() => {
          // We need to reset the loader in order to force refresh the session
          this.configService.loader = null;
        })
        .then(() => this.sessionService.destroy())
        .then(() =>
          this.auth0Client.logout({ logoutParams: { federated: true } }),
        )
        .then(() => this.onLoggedOut())
        .catch(console.error)
        .then(
          () =>
            redirect &&
            this.router.navigate(["/auth/login"], {
              queryParams: {
                reconnect: true,
              },
            }),
        )
    );
  }
}

export { AuthService, ScreebApiHelper, SessionService };
