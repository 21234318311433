/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { BuilderStore } from "stores/builder.store";
import { NgClass } from "@angular/common";

@Component({
  selector: "invisible-card",
  templateUrl: "./InvisibleCard.component.html",
  styleUrls: ["./InvisibleCard.component.scss"],
  imports: [NgClass],
})
export class InvisibleCardComponent implements AfterViewInit {
  constructor(
    private el: ElementRef,
    public builderStore: BuilderStore,
  ) {}

  @Input() isSkipLine: boolean = false;
  @Input() isEven: boolean = false;
  @Input() hovered: boolean = false;

  @Output()
  onSizeUpdate = new EventEmitter<any>();

  ngAfterViewInit() {
    const element = this.el.nativeElement.querySelector(".InvisibleCard");
    if (element) {
      const clientRect = element.getBoundingClientRect();
      this.onSizeUpdate.emit({ element, clientRect });
    }
  }
}
