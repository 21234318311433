import { Component, Input } from "@angular/core";
import { NgFor, NgIf } from "@angular/common";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";

@Component({
  selector: "tags",
  templateUrl: "./tags.component.html",
  styleUrls: ["./tags.component.scss"],
  imports: [NgFor, NzTagComponent, NgIf, NzTooltipDirective],
})
export class TagsComponent {
  @Input() public tags: string[] = [];
  @Input() public maxTagsCount = 3;
  @Input() public color: string;
  @Input() public textColor: string;
  @Input() public size: "small" | "default" = "default";
}
