import { Component, Input } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";
import { EntitlementService, FeatureKey } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { NgIf, NgClass } from "@angular/common";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { FormErrorComponent } from "../../../../../../../../utils/form-error/form-error.component";
import { EntitlementPipe } from "pipes/entitlement.pipe";

@Component({
  selector: "rule-sdk-platform",
  templateUrl: "./rule-sdk-platform.component.html",
  styleUrls: ["./rule-sdk-platform.component.scss"],
  imports: [NgIf, NzTagComponent, NgClass, FormErrorComponent, EntitlementPipe],
})
export class TargetingRuleSDKPlatformComponent {
  @Input()
  public rule: TargetingRule = null;

  constructor(
    public featureFlaggingService: FeatureFlaggingService,
    private entitlementService: EntitlementService,
  ) {}

  public onPlatformChange(
    rule: TargetingRule,
    platform: string,
    checked: boolean,
    entitlement: FeatureKey,
  ) {
    // if (this.survey.type === "message") {
    //   return this.entitlementService.isAvailable("message_inapp");
    // }

    if (!this.entitlementService.isAvailable("survey_edition")) {
      return;
    }

    if (!this.entitlementService.isAvailable(entitlement)) {
      return;
    }

    if (checked) {
      rule.value.v_s_arr?.push(platform);
    } else {
      rule.value.v_s_arr = rule.value.v_s_arr?.filter((d) => d !== platform);
    }
  }
}
