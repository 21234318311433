import { Component, Input } from "@angular/core";
import { NotificationHelper } from "helpers/notification.helper";
import { ChannelType } from "models/channel.model";
import { DistributionInteraction } from "models/survey-distribution.model";
import { SurveyType } from "models/survey.model";
import { ClipboardService } from "ngx-clipboard";
import { uppercaseFirst } from "pipes/uppercase-first.pipe";
import { UIService } from "services/ui.service";
import { ScreebIconComponent } from "../../../../../utils/screeb-icon/screeb-icon.component";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { NzRadioGroupComponent } from "ng-zorro-antd/radio";
import { FormsModule } from "@angular/forms";
import { BigRadio } from "../../../../../utils/big-radio/big-radio.component";
import { Highlight } from "ngx-highlightjs";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NgIf } from "@angular/common";

@Component({
  selector: "survey-share-targeting-manual",
  templateUrl: "./manual.component.html",
  styleUrls: ["./manual.component.scss"],
  imports: [
    ScreebIconComponent,
    NzRowDirective,
    NzColDirective,
    NzRadioGroupComponent,
    FormsModule,
    BigRadio,
    Highlight,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NgIf,
  ],
})
export class ManualTargetingShareSurveyComponent {
  @Input() public surveyId: string = null;
  @Input() public surveyType: SurveyType = "survey";

  @Input() public distributionId: string = null;

  @Input() public interaction: DistributionInteraction = null;

  public multiple: boolean = true;

  constructor(
    private clipboardService: ClipboardService,
    private notificationHelper: NotificationHelper,
    public uiService: UIService,
  ) {}

  public getDocURL(channelType: ChannelType) {
    switch (channelType) {
      case "widget":
        return "https://developers.screeb.app/sdk-js/install";
      case "android":
        return "https://developers.screeb.app/sdk-android/install";
      case "ios":
        return "https://developers.screeb.app/sdk-ios/install";
      default:
        throw new Error("unexpected channel type");
    }
  }

  public getCode(channelType: ChannelType, multiple: boolean) {
    switch (channelType) {
      case "widget":
        return this.getCodeJS(multiple);
      case "android":
        return this.getCodeAndroid(multiple);
      case "ios":
        return this.getCodeIOS(multiple);
      default:
        throw new Error("unexpected channel type");
    }
  }

  private getCodeJS(multiple: boolean) {
    const params = {};
    if (!multiple) {
      params["allow_multiple_responses"] = false;
    }

    if (this.interaction === "in_page") {
      params["selectors"] = ".my-css-selector";
    }

    if (this.distributionId && this.surveyType !== "message") {
      params["distribution_id"] = this.distributionId;
    }

    const paramsString = Object.keys(params).length
      ? ", " + JSON.stringify(params, null, 2)
      : "";
    return `$screeb('${this.surveyType}.start', '${this.surveyId}'${paramsString});`;
  }

  private getCodeAndroid(multiple: boolean) {
    return `Screeb.start${uppercaseFirst(this.surveyType)}(
    ${this.surveyType}Id = "${this.surveyId}"${
      multiple ? "" : ",\n    allowMultipleResponses = false"
    }${
      this.distributionId && this.surveyType !== "message"
        ? `,\n    distributionId = "${this.distributionId}"`
        : ""
    }
)`;
  }

  private getCodeIOS(multiple: boolean) {
    return `Screeb.start${uppercaseFirst(this.surveyType)}(
    ${this.surveyType}Id: "${this.surveyId}"${
      multiple ? "" : ",\n    allowMultipleResponses: false"
    }${
      this.distributionId && this.surveyType !== "message"
        ? `,\n    distributionId: "${this.distributionId}"`
        : ""
    }
)`;
  }

  // recurrencyChange(multiple: boolean) {
  //   this.code = this.getCode(multiple);
  // }

  clipboardCopy(code: string) {
    this.clipboardService.copy(code);
    this.notificationHelper.trigger(
      "Copied to your clipboard!",
      null,
      "success",
    );
  }
}
