<!-- <upgrade-modal
  *ngIf="isMobileDistribution && !('survey_inapp_mobile' | hasEntitlement)"
  [canCancel]="false"
  [centeredModal]="true"
  [withOverlay]="true"
  [orgId]="orgId"
  upgradeType="survey_inapp_mobile"
></upgrade-modal> -->

<ul nz-menu nzMode="inline" class="screeb-menu survey-share-menu">
  <ng-container *ngIf="distributions?.length">
    <ng-container *ngFor="let distribution of distributions">
      <ng-template #menu>
        <span
          class="survey-share-menu__entry {{
            activeDistribution?.id === distribution.id ? 'active' : ''
          }}"
        >
          <screeb-icon
            *ngIf="distribution.interaction === 'in_app'"
            size="md"
            icon="desktop"
          ></screeb-icon>
          <screeb-icon
            *ngIf="distribution.interaction === 'in_page'"
            size="md"
            icon="overview"
          ></screeb-icon>
          <screeb-icon
            *ngIf="distribution.interaction === 'email'"
            size="md"
            icon="email"
          ></screeb-icon>
          <screeb-icon
            *ngIf="distribution.interaction === 'feedback_button'"
            size="md"
            icon="bye"
          ></screeb-icon>
          <screeb-icon
            *ngIf="distribution.interaction === 'link'"
            size="md"
            icon="link"
          ></screeb-icon>
          <span>{{ distribution.title }}</span>
          <span class="spacer"></span>
          <span
            (click)="$event.stopPropagation()"
            nz-popconfirm
            [nzPopconfirmTitle]="
              !distribution.enabled
                ? 'Would you like to start the ' +
                  distribution.title +
                  ' channel?'
                : 'Would you like to stop this channel?'
            "
            nzPopconfirmPlacement="bottom"
            nzCancelText="No"
            nzOkText="Yes"
            (nzOnConfirm)="toggleDistributionStatus(distribution)"
            class="survey-share-menu__entry-status survey-share-menu__entry-status--{{
              {
                running: 'enabled',
                paused: 'disabled',
                programmed: 'info',
                stopped: 'disabled',
              }[distribution.status]
            }} {{ !('survey_edition' | hasEntitlement) ? 'locked' : '' }}"
          >
            <nz-spin
              *ngIf="loading === distribution.id"
              nzSimple
              [nzSize]="'small'"
            ></nz-spin>
            <screeb-icon
              *ngIf="
                loading !== distribution.id && distribution.status === 'paused'
              "
              size="sm"
              icon="pause"
            ></screeb-icon>
            <screeb-icon
              *ngIf="
                loading !== distribution.id &&
                distribution.status === 'programmed'
              "
              size="md"
              icon="hourglass"
            ></screeb-icon>
            <screeb-icon
              *ngIf="
                loading !== distribution.id && distribution.status === 'running'
              "
              size="sm"
              icon="play"
            ></screeb-icon>
            <screeb-icon
              *ngIf="
                loading !== distribution.id && distribution.status === 'stopped'
              "
              size="sm"
              icon="stopped"
            ></screeb-icon>
            {{ distribution.statusLabel }}
          </span>
          <span
            class="survey-share-menu__entry-test-mode"
            [nz-tooltip]="testModeTooltip"
            *ngIf="
              distribution.isTestMode &&
              distribution.interaction !== 'link' &&
              distribution.interaction !== 'email' &&
              distribution.type !== 'hosted-page'
            "
          >
            <screeb-icon size="sm" icon="warning"></screeb-icon>
            <span *ngIf="distribution.title.length < 8">Test mode</span>
          </span>
        </span>
      </ng-template>
      <li
        nz-submenu
        [nzOpen]="activeDistribution?.id === distribution.id"
        [nzTitle]="menu"
      >
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a
              routerLink="/org/{{ uiService.currentOrgId }}/{{
                uiService.surveyTypePath
              }}/{{ uiService.currentSurveyId }}/share/{{
                distribution.id
              }}/diffusion"
            >
              <screeb-icon size="sm" icon="filter"></screeb-icon>
              Diffusion
            </a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a
              routerLink="/org/{{ uiService.currentOrgId }}/{{
                uiService.surveyTypePath
              }}/{{ uiService.currentSurveyId }}/share/{{
                distribution.id
              }}/design"
            >
              <screeb-icon size="sm" icon="customize"></screeb-icon>
              Design
            </a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="!uiService.isMessagePage">
            <a
              routerLink="/org/{{ uiService.currentOrgId }}/{{
                uiService.surveyTypePath
              }}/{{ uiService.currentSurveyId }}/share/{{
                distribution.id
              }}/preview"
            >
              <screeb-icon size="sm" icon="play"></screeb-icon>
              Preview
            </a>
          </li>
        </ul>
      </li>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="legacyDistributions?.length">
    <hr *ngIf="distributions?.length && !uiService.isMessagePage" />
    <p
      class="survey-share-menu__legacy-title"
      *ngIf="!uiService.isMessagePage"
      (click)="toggleLegacyDistribution()"
    >
      <span>
        <screeb-icon size="md" icon="hourglass"></screeb-icon>
        <span>Legacy channels</span>
      </span>
      <span>
        <screeb-icon
          *ngIf="!isLegacyDistributionExpanded"
          size="sm"
          icon="down"
        ></screeb-icon>
        <screeb-icon
          *ngIf="isLegacyDistributionExpanded"
          size="sm"
          icon="up"
        ></screeb-icon>
        <screeb-icon
          size="sm"
          icon="info-circle"
          nz-popover
          nzType="primary"
          nzPopoverPlacement="bottomLeft"
          [nzPopoverContent]="contentTemplate"
        ></screeb-icon>
      </span>
      <ng-template #contentTemplate>
        These methods are being deprecated.
        <br />
        Please transition to our new "In-App" channel that merge Web & Mobile
        for better performance and engagement.
        <br />
        Or the new "Link" channel to replace the legacy Hosted Page.
      </ng-template>
    </p>
    <ng-container *ngIf="isLegacyDistributionExpanded">
      <ng-container *ngFor="let distribution of legacyDistributions">
        <ng-template #menu>
          <span
            class="survey-share-menu__entry {{
              activeDistribution?.id === distribution.id ? 'active' : ''
            }}"
          >
            <screeb-icon
              *ngIf="distribution.type === 'android'"
              size="sm"
              icon="android"
            ></screeb-icon>
            <screeb-icon
              *ngIf="distribution.type === 'ios'"
              size="sm"
              icon="apple"
            ></screeb-icon>
            <screeb-icon
              *ngIf="distribution.type === 'hosted-page'"
              size="sm"
              icon="link"
            ></screeb-icon>
            <screeb-icon
              *ngIf="distribution.type === 'widget'"
              size="md"
              icon="desktop"
            ></screeb-icon>
            <span>{{ distribution.title }}</span>
            <screeb-icon
              *ngIf="
                !('survey_inapp_mobile' | hasEntitlement) &&
                (distribution.type === 'android' || distribution.type === 'ios')
              "
              size="sm"
              icon="upgrade"
            ></screeb-icon>
            <span class="spacer"></span>
            <span
              *ngIf="!isDistribAvailableInEntitlements(distribution)"
              (click)="goToBilling()"
              class="survey-share-menu__entry-status survey-share-menu__entry-status--disabled"
            >
              Upgrade
            </span>
            <span
              *ngIf="isDistribAvailableInEntitlements(distribution)"
              (click)="$event.stopPropagation()"
              nz-popconfirm
              [nzCondition]="!('survey_edition' | hasEntitlement)"
              [nzPopconfirmTitle]="
                !distribution.enabled
                  ? 'Would you like to start this channel?'
                  : 'Would you like to stop this channel?'
              "
              nzPopconfirmPlacement="bottom"
              nzCancelText="No"
              nzOkText="Yes"
              (nzOnConfirm)="toggleDistributionStatus(distribution)"
              class="survey-share-menu__entry-status survey-share-menu__entry-status--{{
                {
                  running: 'enabled',
                  paused: 'disabled',
                  programmed: 'info',
                  stopped: 'disabled',
                }[distribution.status]
              }} {{ !('survey_edition' | hasEntitlement) ? 'locked' : '' }}"
            >
              <nz-spin
                *ngIf="loading === distribution.id"
                nzSimple
                [nzSize]="'small'"
              ></nz-spin>
              <screeb-icon
                *ngIf="
                  loading !== distribution.id &&
                  distribution.status === 'paused'
                "
                size="sm"
                icon="pause"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  loading !== distribution.id &&
                  distribution.status === 'programmed'
                "
                size="md"
                icon="hourglass"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  loading !== distribution.id &&
                  distribution.status === 'running'
                "
                size="sm"
                icon="play"
              ></screeb-icon>
              <screeb-icon
                *ngIf="
                  loading !== distribution.id &&
                  distribution.status === 'stopped'
                "
                size="sm"
                icon="stopped"
              ></screeb-icon>
              {{ distribution.statusLabel }}
            </span>
            <span
              class="survey-share-menu__entry-test-mode"
              *ngIf="
                distribution.isTestMode &&
                distribution.interaction !== 'link' &&
                distribution.interaction !== 'email' &&
                distribution.type !== 'hosted-page'
              "
            >
              <screeb-icon size="sm" icon="warning"></screeb-icon>
              <span *ngIf="distribution.title.length < 8">Test mode</span>
            </span>
          </span>
        </ng-template>
        <li
          nz-submenu
          [nzOpen]="activeDistribution?.id === distribution.id"
          [nzTitle]="menu"
        >
          <ul>
            <li nz-menu-item nzMatchRouter>
              <a
                routerLink="/org/{{ uiService.currentOrgId }}/{{
                  uiService.surveyTypePath
                }}/{{ uiService.currentSurveyId }}/share/{{
                  distribution.id
                }}/diffusion"
              >
                <screeb-icon size="sm" icon="filter"></screeb-icon>
                Diffusion
              </a>
            </li>
            <li nz-menu-item nzMatchRouter>
              <a
                routerLink="/org/{{ uiService.currentOrgId }}/{{
                  uiService.surveyTypePath
                }}/{{ uiService.currentSurveyId }}/share/{{
                  distribution.id
                }}/design"
              >
                <screeb-icon size="sm" icon="customize"></screeb-icon>
                Design
              </a>
            </li>
            <li nz-menu-item nzMatchRouter *ngIf="!uiService.isMessagePage">
              <a
                routerLink="/org/{{ uiService.currentOrgId }}/{{
                  uiService.surveyTypePath
                }}/{{ uiService.currentSurveyId }}/share/{{
                  distribution.id
                }}/preview"
              >
                <screeb-icon size="sm" icon="play"></screeb-icon>
                Preview
              </a>
            </li>
          </ul>
        </li>
      </ng-container>
    </ng-container>
  </ng-container>
</ul>
<div class="spacer"></div>
<ul nz-menu nzMode="inline" class="screeb-menu help-menu">
  <ng-template #helpMenu>
    <screeb-icon
      size="sm"
      icon="question-mark"
      style="margin-right: 0.35rem"
    ></screeb-icon>
    Need help?
  </ng-template>
  <li nz-submenu [nzTitle]="helpMenu">
    <ul>
      <ng-container *ngFor="let article of posts101">
        <li nz-menu-item>
          <a
            class="help-article"
            href="{{ article.url }}"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span [innerHtml]="article.title"></span>
          </a>
        </li>
      </ng-container>
    </ul>
  </li>
</ul>
<ng-template #testModeTooltip>
  <div class="test-mode-tooltip">
    <span>⚠️ Test mode activated on this channel</span>
  </div>
</ng-template>
