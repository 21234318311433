import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  ColorPickerControl,
  ChromePickerComponent,
} from "@iplab/ngx-color-picker";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { FormsModule } from "@angular/forms";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";

const padColor = (color: string, alpha: boolean) =>
  alpha && color.length === 7 ? color + "FF" : color;

@Component({
  selector: "color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.scss"],
  imports: [
    NzPopoverDirective,
    FormsModule,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    ChromePickerComponent,
  ],
})
export class ColorPickerComponent implements OnInit {
  @Input()
  public color = "#5E21F1";
  @Input()
  public alpha = false;

  @Output()
  public colorChange: EventEmitter<string> = new EventEmitter();

  public chromeControl = new ColorPickerControl().hidePresets();

  ngOnInit(): void {
    this.chromeControl.setValueFrom(this.color);
    if (!this.alpha) {
      this.chromeControl.hideAlphaChannel();
    }
  }

  public onBlur() {
    this.color = padColor(this.color, this.alpha);
    this.colorChange.emit(this.color);
  }

  public onColorChange(color: string) {
    this.colorChange.emit(padColor(color, this.alpha));
  }
}
