/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SurveyType } from "models/survey.model";
import { NgClass } from "@angular/common";

@Component({
  selector: "add-button",
  templateUrl: "./AddButton.component.html",
  styleUrls: ["./AddButton.component.scss"],
  imports: [NgClass],
})
export class AddButtonComponent {
  constructor() {}

  @Input() public surveyType: SurveyType = "survey";
  @Input() public disabled = false;

  @Output() public onClick = new EventEmitter<Event>();
}
