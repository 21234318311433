import { TagSettings } from "./tag-settings.types";

// To avoid "TS2590: Expression produces a union type that is too complex to represent." error
// We must use an helper to merge instead of using the spread operator
export function mergeTagSettings(
  ...settings: Partial<TagSettings>[]
): TagSettings | null {
  const mergedSettings: Partial<TagSettings> = {};
  settings.forEach((setting) => {
    if (setting) {
      Object.assign(mergedSettings, setting);
    }
  });
  const validatedSettings = validateTagSettings(mergedSettings);
  return validatedSettings;
}

function validateTagSettings(
  settings: Partial<TagSettings>,
): TagSettings | null {
  return settings as TagSettings;
}

const adaptTagSettingsBackgroundSettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  switch (tagSettingsToAdapt.page_background_type) {
    case "image": {
      const { page_background_type, page_background_image_url } =
        tagSettingsToAdapt;
      return { page_background_type, page_background_image_url };
    }
    case "color": {
      const { page_background_type, page_background_color } =
        tagSettingsToAdapt;
      return { page_background_type, page_background_color };
    }
    default:
      return {};
  }
};

const adaptTagSettingsEmailsBackgroundSettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  switch (tagSettingsToAdapt.emails_page_background_type) {
    case "image": {
      const { emails_page_background_type, emails_page_background_image_url } =
        tagSettingsToAdapt;
      return { emails_page_background_type, emails_page_background_image_url };
    }
    case "color": {
      const { emails_page_background_type, emails_page_background_color } =
        tagSettingsToAdapt;
      return { emails_page_background_type, emails_page_background_color };
    }
    default:
      return {};
  }
};

const adaptTagSettingsCardIdentitySettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  const {
    cards_identity_type,
    cards_identity_fixed_name,
    cards_identity_fixed_title,
    cards_identity_fixed_avatar,
  } = tagSettingsToAdapt;
  switch (tagSettingsToAdapt.cards_identity_type) {
    case "fixed": {
      return {
        cards_identity_type,
        cards_identity_fixed_name,
        cards_identity_fixed_title,
        cards_identity_fixed_avatar,
      };
    }
    case "screeby": {
      return {
        cards_identity_type,
        cards_identity_fixed_name,
        cards_identity_fixed_title,
        cards_identity_fixed_avatar: null,
      };
    }
    case "none": {
      return {
        cards_identity_type,
        cards_identity_fixed_name: null,
        cards_identity_fixed_title: null,
        cards_identity_fixed_avatar: null,
      };
    }
    default:
      return {};
  }
};

const adaptTagSettingsFeedbackButtonIdentitySettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  const {
    feedback_button_identity_type,
    feedback_button_identity_fixed_name,
    feedback_button_identity_fixed_title,
    feedback_button_identity_fixed_avatar,
  } = tagSettingsToAdapt;
  switch (tagSettingsToAdapt.feedback_button_identity_type) {
    case "fixed": {
      return {
        feedback_button_identity_type,
        feedback_button_identity_fixed_name,
        feedback_button_identity_fixed_title,
        feedback_button_identity_fixed_avatar,
      };
    }
    case "screeby": {
      return {
        feedback_button_identity_type,
        feedback_button_identity_fixed_name,
        feedback_button_identity_fixed_title,
        feedback_button_identity_fixed_avatar: null,
      };
    }
    case "none": {
      return {
        feedback_button_identity_type,
        feedback_button_identity_fixed_name: null,
        feedback_button_identity_fixed_title: null,
        feedback_button_identity_fixed_avatar: null,
      };
    }
    default:
      return {};
  }
};

const adaptTagSettingsConversationalIdentitySettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  const {
    conversational_identity_type,
    conversational_identity_fixed_name,
    conversational_identity_fixed_title,
    conversational_identity_fixed_avatar,
  } = tagSettingsToAdapt;
  switch (tagSettingsToAdapt.conversational_identity_type) {
    case "fixed": {
      return {
        conversational_identity_type,
        conversational_identity_fixed_name,
        conversational_identity_fixed_title,
        conversational_identity_fixed_avatar,
      };
    }
    case "screeby": {
      return {
        conversational_identity_type,
        conversational_identity_fixed_name,
        conversational_identity_fixed_title,
        conversational_identity_fixed_avatar: null,
      };
    }
    case "none": {
      return {
        conversational_identity_type,
        conversational_identity_fixed_name: null,
        conversational_identity_fixed_title: null,
        conversational_identity_fixed_avatar: null,
      };
    }
    default:
      return {};
  }
};

const adaptTagSettingsMessagesIdentitySettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  const {
    messages_identity_type,
    messages_identity_fixed_name,
    messages_identity_fixed_title,
    messages_identity_fixed_avatar,
  } = tagSettingsToAdapt;
  switch (tagSettingsToAdapt.messages_identity_type) {
    case "fixed": {
      return {
        messages_identity_type,
        messages_identity_fixed_name,
        messages_identity_fixed_title,
        messages_identity_fixed_avatar,
      };
    }
    case "screeby": {
      return {
        messages_identity_type,
        messages_identity_fixed_name,
        messages_identity_fixed_title,
        messages_identity_fixed_avatar: null,
      };
    }
    case "none": {
      return {
        messages_identity_type,
        messages_identity_fixed_name: null,
        messages_identity_fixed_title: null,
        messages_identity_fixed_avatar: null,
      };
    }
    default:
      return {};
  }
};

const adaptTagSettingsEmailsIdentitySettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  const {
    emails_identity_type,
    emails_identity_fixed_name,
    emails_identity_fixed_title,
    emails_identity_fixed_avatar,
  } = tagSettingsToAdapt;
  switch (tagSettingsToAdapt.emails_identity_type) {
    case "fixed": {
      return {
        emails_identity_type,
        emails_identity_fixed_name,
        emails_identity_fixed_title,
        emails_identity_fixed_avatar,
      };
    }
    case "screeby": {
      const {
        emails_identity_type,
        emails_identity_fixed_name,
        emails_identity_fixed_title,
      } = tagSettingsToAdapt;
      return {
        emails_identity_type,
        emails_identity_fixed_name,
        emails_identity_fixed_title,
        emails_identity_fixed_avatar: null,
      };
    }
    case "none": {
      return {
        emails_identity_type,
        emails_identity_fixed_name: null,
        emails_identity_fixed_title: null,
        emails_identity_fixed_avatar: null,
      };
    }
    default:
      return {};
  }
};

const adaptTagSettingsLinkIdentitySettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  const {
    link_identity_type,
    link_identity_fixed_name,
    link_identity_fixed_title,
    link_identity_fixed_avatar,
  } = tagSettingsToAdapt;
  switch (tagSettingsToAdapt.link_identity_type) {
    case "fixed": {
      return {
        link_identity_type,
        link_identity_fixed_name,
        link_identity_fixed_title,
        link_identity_fixed_avatar,
      };
    }
    case "screeby": {
      return {
        link_identity_type,
        link_identity_fixed_name,
        link_identity_fixed_title,
        link_identity_fixed_avatar: null,
      };
    }
    case "none": {
      return {
        link_identity_type,
        link_identity_fixed_name: null,
        link_identity_fixed_title: null,
        link_identity_fixed_avatar: null,
      };
    }
    default:
      return {};
  }
};

const adaptTagSettingsInPageIdentitySettingsToApi = (
  tagSettingsToAdapt: TagSettings,
) => {
  const {
    in_page_identity_type,
    in_page_identity_fixed_name,
    in_page_identity_fixed_title,
    in_page_identity_fixed_avatar,
  } = tagSettingsToAdapt;
  switch (tagSettingsToAdapt.in_page_identity_type) {
    case "fixed": {
      return {
        in_page_identity_type,
        in_page_identity_fixed_name,
        in_page_identity_fixed_title,
        in_page_identity_fixed_avatar,
      };
    }
    case "screeby": {
      return {
        in_page_identity_type,
        in_page_identity_fixed_name,
        in_page_identity_fixed_title,
        in_page_identity_fixed_avatar: null,
      };
    }
    case "none": {
      return {
        in_page_identity_type,
        in_page_identity_fixed_name: null,
        in_page_identity_fixed_title: null,
        in_page_identity_fixed_avatar: null,
      };
    }
    default:
      return {};
  }
};

// survey settings should not contain new interactions colors
const adaptTagSettingsColorSettingsSurveyToApi = ({
  advanced,
  cards_color_primary,
  cards_color_primary_text,
  conversational_color_primary,
  conversational_color_primary_text,
  messages_color_primary,
  messages_color_primary_text,
}: TagSettings) => {
  if (advanced) {
    return {};
  }

  return {
    cards_color_primary,
    cards_color_primary_text,
    conversational_color_primary,
    conversational_color_primary_text,
    messages_color_primary,
    messages_color_primary_text,
  };
};

const adaptTagSettingsColorSettingsDistributionToApi = ({
  advanced,
  cards_color_primary,
  cards_color_primary_text,
  feedback_button_color_primary,
  feedback_button_color_primary_text,
  feedback_button_cta_color_primary,
  feedback_button_cta_color_primary_text,

  emails_color_primary,
  emails_color_primary_text,
  emails_page_background_type,
  emails_color_secondary,
  emails_background_icon,

  conversational_color_primary,
  conversational_color_primary_text,

  in_page_color_primary,
  in_page_color_primary_text,
  messages_color_primary,
  messages_color_primary_text,
  link_color_primary,
  link_color_primary_text,
}: TagSettings) => {
  // In advanced we allow to update emails and link colors, as page background
  // can also be updated and user might want to update the colors to match the new background
  if (advanced) {
    return {
      emails_color_primary,
      emails_color_primary_text,
      emails_page_background_type,
      emails_color_secondary,
      emails_background_icon,

      link_color_primary,
      link_color_primary_text,
    };
  }

  return {
    cards_color_primary,
    cards_color_primary_text,
    feedback_button_color_primary,
    feedback_button_color_primary_text,
    feedback_button_cta_color_primary,
    feedback_button_cta_color_primary_text,
    emails_color_primary,
    emails_color_primary_text,
    conversational_color_primary,
    conversational_color_primary_text,
    in_page_color_primary,
    in_page_color_primary_text,
    messages_color_primary,
    messages_color_primary_text,
    link_color_primary,
    link_color_primary_text,
  };
};

const adaptTagSettingsColorSettingsWorkspaceToApi = (
  tagSettings: TagSettings,
) => {
  // In advanced we allow to update emails and link colors, as page background
  // can also be updated and user might want to update the colors to match the new background
  // if (advanced) {
  //   return {
  //     emails_color_primary,
  //     emails_color_primary_text,
  //     link_color_primary,
  //     link_color_primary_text,
  //   };
  // }

  const {
    advanced,
    cards_color_primary,
    cards_color_primary_text,
    feedback_button_color_primary,
    feedback_button_color_primary_text,
    feedback_button_cta_color_primary,
    feedback_button_cta_color_primary_text,
    emails_color_primary,
    emails_color_primary_text,
    conversational_color_primary,
    conversational_color_primary_text,
    in_page_color_primary,
    in_page_color_primary_text,
    messages_color_primary,
    messages_color_primary_text,
    link_color_primary,
    link_color_primary_text,
  } = tagSettings;

  if (advanced) return tagSettings;

  return {
    cards_color_primary,
    cards_color_primary_text,
    feedback_button_color_primary,
    feedback_button_color_primary_text,
    feedback_button_cta_color_primary,
    feedback_button_cta_color_primary_text,
    emails_color_primary,
    emails_color_primary_text,
    conversational_color_primary,
    conversational_color_primary_text,
    in_page_color_primary,
    in_page_color_primary_text,
    messages_color_primary,
    messages_color_primary_text,
    link_color_primary,
    link_color_primary_text,
  };
};

export function resetDefaultsValuesIfNecessary(
  tagSettings: TagSettings,
): TagSettings {
  if (tagSettings.advanced) {
    return tagSettings;
  }

  return {
    ...tagSettings,

    // cards
    cards_border_radius: 16,
    cards_border_line: false,
    cards_border_line_width: 2,
    cards_border_line_color: "#303140FF",
    cards_border_shadow: true,
    cards_border_shadow_color: "#3031401A",
    cards_color_secondary: "#F3F4F9",
    cards_color_secondary_text: "#303140",
    cards_display_reduce_button: true,
    cards_font_family: "Rubik",
    cards_font_line_height: 24,
    cards_font_size: 16,
    cards_identity_avatar_format: "rounded_square",
    cards_overlay_color: "#313140A6",
    cards_overlay_visibility: "hidden",
    cards_size: 100,

    // conversational
    conversational_border_radius: 16,
    conversational_border_line: false,
    conversational_border_line_width: 2,
    conversational_border_line_color: "#303140FF",
    conversational_border_shadow: true,
    conversational_border_shadow_color: "#30314040",
    conversational_color_secondary: "#FFFFFF",
    conversational_color_secondary_text: "#303140",
    conversational_display_close_reduce: false,
    conversational_font_family: "Rubik",
    conversational_font_line_height: 24,
    conversational_font_size: 16,
    conversational_identity_avatar_format: "rounded_square",
    conversational_overlay_color: "#313140A6",
    conversational_overlay_visibility: "response",
    conversational_size: 100,

    // email
    emails_border_radius: 16,
    emails_identity_avatar_format: "rounded_square",

    // in_page
    in_page_border_radius: 16,
    in_page_border_line: false,
    in_page_border_line_width: 2,
    in_page_border_line_color: "#303140FF",
    in_page_border_shadow: true,
    in_page_border_shadow_color: "#3031401A",
    in_page_color_secondary: "#F3F4F9",
    in_page_color_secondary_text: "#303140",
    in_page_font_family: "Rubik",
    in_page_font_line_height: 24,
    in_page_font_size: 16,
    in_page_overlay_color: "#313140A6",
    in_page_overlay_visibility: "hidden",
    in_page_size: 100,

    // link
    link_border_radius: 16,
    link_border_line: false,
    link_border_line_width: 2,
    link_border_line_color: "#303140FF",
    link_border_shadow: true,
    link_border_shadow_color: "#3031401A",
    link_color_secondary: "#F3F4F9",
    link_color_secondary_text: "#303140",
    link_font_family: "Rubik",
    link_font_line_height: 24,
    link_font_size: 16,
    link_identity_avatar_format: "rounded_square",
    link_overlay_color: "#313140A6",
    link_overlay_visibility: "hidden",
    link_size: 100,

    // feedback button
    feedback_button_border_radius: 16,
    feedback_button_border_line: false,
    feedback_button_border_line_width: 2,
    feedback_button_border_line_color: "#303140FF",
    feedback_button_border_shadow: true,
    feedback_button_border_shadow_color: "#3031401A",
    feedback_button_color_secondary: "#F3F4F9",
    feedback_button_color_secondary_text: "#303140",
    feedback_button_font_family: "Rubik",
    feedback_button_font_line_height: 24,
    feedback_button_font_size: 16,
    feedback_button_identity_avatar_format: "rounded_square",
    feedback_button_size: 100,

    // feedback button cta
    feedback_button_cta_border_radius: 16,
    feedback_button_cta_border_line: false,
    feedback_button_cta_border_line_width: 2,
    feedback_button_cta_border_line_color: "#303140FF",
    feedback_button_cta_border_shadow: true,
    feedback_button_cta_border_shadow_color: "#3031401A",
    feedback_button_cta_color_secondary: "#F3F4F9",
    feedback_button_cta_color_secondary_text: "#303140",
    feedback_button_cta_font_family: "Rubik",
    feedback_button_cta_font_line_height: 24,
    feedback_button_cta_font_size: 16,

    // messages
    messages_border_radius: 16,
    messages_border_line: false,
    messages_border_line_width: 2,
    messages_border_line_color: "#303140FF",
    messages_border_shadow: true,
    messages_border_shadow_color: "#3031401A",
    messages_color_secondary: "#F3F4F9",
    messages_color_secondary_text: "#303140",
    messages_color_tertiary: "#FFFFFF",
    messages_color_tertiary_text: "#303140",
    messages_font_family: "Rubik",
    messages_font_line_height: 24,
    messages_font_size: 16,
    messages_title_font_family: "Rubik",
    messages_title_font_weight: 500,
    messages_title_font_size: 16,
    messages_identity_avatar_format: "rounded_square",
    messages_overlay_color: "#313140A6",
    messages_overlay_visibility: "always",
    messages_size: 100,
  };
}

export function adaptTagSettingsToApi(
  tagSettingsToAdapt: TagSettings,
  scope: "org" | "survey" | "distribution",
): Partial<TagSettings> | Record<string, never> {
  let tagSettings: TagSettings = { ...tagSettingsToAdapt };

  if (!tagSettings) {
    return {};
  }

  // awful, yuck!
  switch (tagSettings.page_background_type) {
    case "color":
      delete tagSettings["page_background_image_url"];
      break;
    case "image":
      delete tagSettings["page_background_color"];
      break;
  }

  switch (tagSettings.emails_page_background_type) {
    case "color":
      delete tagSettings["emails_page_background_image_url"];
      break;
    case "image":
      delete tagSettings["emails_page_background_color"];
      break;
  }

  // Identity
  tagSettings = {
    ...tagSettings,
    ...adaptTagSettingsCardIdentitySettingsToApi(tagSettings),
    ...adaptTagSettingsConversationalIdentitySettingsToApi(tagSettings),
    ...adaptTagSettingsFeedbackButtonIdentitySettingsToApi(tagSettings),
    ...adaptTagSettingsMessagesIdentitySettingsToApi(tagSettings),
    ...adaptTagSettingsEmailsIdentitySettingsToApi(tagSettings),
    ...adaptTagSettingsLinkIdentitySettingsToApi(tagSettings),
    ...adaptTagSettingsInPageIdentitySettingsToApi(tagSettings),
  };

  if (scope === "org" && !tagSettings.advanced) {
    return resetDefaultsValuesIfNecessary(tagSettings);
  }

  const {
    format,
    cards_position,
    cards_pop_sound,
    cards_display_reduce_button,
    cards_identity_type,
    cards_identity_fixed_name,
    cards_identity_fixed_title,
    cards_identity_fixed_avatar,

    conversational_position,
    conversational_pop_sound,
    conversational_identity_type,
    conversational_identity_fixed_name,
    conversational_identity_fixed_title,
    conversational_identity_fixed_avatar,

    link_position,
    link_identity_type,
    link_identity_fixed_name,
    link_identity_fixed_title,
    link_identity_fixed_avatar,

    emails_position,
    emails_identity_type,
    emails_identity_fixed_name,
    emails_identity_fixed_title,
    emails_identity_fixed_avatar,

    feedback_button_position,
    feedback_button_pop_sound,
    feedback_button_cta_format,
    feedback_button_cta_label,
    feedback_button_cta_label_icon,
    feedback_button_cta_label_icon_type,
    feedback_button_identity_type,
    feedback_button_identity_fixed_avatar,
    feedback_button_identity_fixed_name,
    feedback_button_identity_fixed_title,

    messages_pop_sound,
  } = tagSettings;

  // Split adapt in multiple steps to avoid error
  // Expression produces a union type that is too complex to represent.
  let payload = {
    format,
    cards_position,
    cards_pop_sound,
    cards_display_reduce_button,
    cards_identity_type,
    cards_identity_fixed_name,
    cards_identity_fixed_title,
    cards_identity_fixed_avatar,

    conversational_position,
    conversational_pop_sound,
    conversational_identity_type,
    conversational_identity_fixed_name,
    conversational_identity_fixed_title,
    conversational_identity_fixed_avatar,

    link_position,
    link_identity_type,
    link_identity_fixed_name,
    link_identity_fixed_title,
    link_identity_fixed_avatar,

    emails_position,
    emails_identity_type,
    emails_identity_fixed_name,
    emails_identity_fixed_title,
    emails_identity_fixed_avatar,

    feedback_button_position,
    feedback_button_pop_sound,
    feedback_button_cta_format,
    feedback_button_cta_label,
    feedback_button_cta_label_icon,
    feedback_button_cta_label_icon_type,
    feedback_button_identity_type,
    feedback_button_identity_fixed_avatar,
    feedback_button_identity_fixed_name,
    feedback_button_identity_fixed_title,

    messages_pop_sound,
  };

  // Colors
  payload = {
    ...payload,
    ...(scope === "survey"
      ? adaptTagSettingsColorSettingsSurveyToApi(tagSettings)
      : scope === "distribution"
        ? adaptTagSettingsColorSettingsDistributionToApi(tagSettings)
        : adaptTagSettingsColorSettingsWorkspaceToApi(tagSettings)),
    ...adaptTagSettingsBackgroundSettingsToApi(tagSettings),
    ...adaptTagSettingsEmailsBackgroundSettingsToApi(tagSettings),
  };

  return payload;
}
