import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Org } from "models/org.model";
import { NotificationHelper } from "helpers/notification.helper";
import { NzModalComponent } from "ng-zorro-antd/modal";
import { RouterLink } from "@angular/router";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";

@Component({
  selector: "surveys-import-modal",
  templateUrl: "./surveys-import.component.html",
  styleUrls: ["./surveys-import.component.scss"],
  imports: [
    NzModalComponent,
    RouterLink,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
  ],
})
export class SurveysImportModalComponent {
  isConfirmLoading = false;

  @Input() isOpen: boolean = false;
  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter();
  @Input() org: Org = null;

  constructor(private notificationHelper: NotificationHelper) {}

  onSubmit(): void {}

  handleCancel(): void {
    this.isOpen = false;
    this.modalOpenChange.emit(this.isOpen);
    this.isConfirmLoading = false;
  }
}
