import { Component, OnDestroy, OnInit } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { PageComponentInterface } from "components/PageComponentInterface";
import { NotificationHelper } from "helpers/notification.helper";
import {
  ChannelClientScreen,
  ChannelClientVersion,
  ChannelClientVersionMap,
} from "models/channel-screen.model";
import { ChannelDao } from "models/channel.dao";
import { ChannelTypeFormatted } from "models/channel.model";
import { Org } from "models/org.model";
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
  NzTableComponent,
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzThAddOnComponent,
  NzTbodyComponent,
  NzCellAlignDirective,
  NzTrExpandDirective,
  NzTableFixedRowComponent,
} from "ng-zorro-antd/table";
import { RoutingService } from "services/routing.service";
import { SeoService } from "services/seo.service";
import { SettingsService } from "services/settings.service";
import { arrayToSet } from "utils/array";
import { NzEmptyComponent } from "ng-zorro-antd/empty";
import { NgFor, NgIf } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { HelpPopoverComponent } from "../../../utils/help-popover/help-popover.component";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { FormatDistanceToNowPipeModule } from "ngx-date-fns";

interface ClientScreenInfo {
  channelId: string;
  expanded: boolean;
  formattedScreenNames: Array<Array<ChannelClientScreen>>;
}

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null;
  listOfFilter: NzTableFilterList | null;
  filterFn: NzTableFilterFn | undefined;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
  actionsTpl?: string;
}

@Component({
  selector: "page-client-screens-list",
  templateUrl: "./client-screens-list.component.html",
  styleUrls: ["./client-screens-list.component.scss"],
  imports: [
    NzEmptyComponent,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NgFor,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzThAddOnComponent,
    NzTbodyComponent,
    NzCellAlignDirective,
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NzTrExpandDirective,
    NzTableFixedRowComponent,
    HelpPopoverComponent,
    NzTooltipDirective,
    FormatDistanceToNowPipeModule,
  ],
})
export class ClientScreensListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Client Screens";
  public name = "Settings client screens";

  private obs: any = null;
  public clientScreenVersionMaps: ChannelClientVersionMap[] = [];
  public clientScreenVersions: ChannelClientVersion[] = [];
  public channelTypeFormatted = ChannelTypeFormatted;
  public org: Org = null;

  public clientScreenInfos: Map<string, ClientScreenInfo> = new Map();

  listOfColumns: ColumnItem[] = [
    {
      name: "App Version",
      sortOrder: null,
      sortFn: (a: ChannelClientVersion, b: ChannelClientVersion) =>
        a.app_version.localeCompare(b.app_version),
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "SDK Version",
      sortOrder: null,
      sortFn: (a: ChannelClientVersion, b: ChannelClientVersion) =>
        a.sdk_versions?.[0].localeCompare(b.sdk_versions?.[0]),
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Platform",
      sortOrder: null,
      sortFn: (a: ChannelClientVersion, b: ChannelClientVersion) =>
        a.platform.localeCompare(b.platform),
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: (list: string[], item: ChannelClientVersion) =>
        list.some((name) => item.platform.indexOf(name) !== -1),
    },
    {
      name: "Created on",
      sortOrder: "descend",
      sortFn: (a: ChannelClientVersion, b: ChannelClientVersion) =>
        a.created_at.getTime() - b.created_at.getTime(),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      listOfFilter: undefined,
      filterFn: null,
    },
  ];

  listOfNestedColumns: ColumnItem[] = [
    {
      name: "Screen Names",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: false,
      listOfFilter: undefined,
      filterFn: null,
      actionsTpl: "screenActionsTpl",
    },
    {
      name: "",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: false,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: false,
      listOfFilter: undefined,
      filterFn: null,
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private seoService: SeoService,
    private settingsService: SettingsService,
    private channelDao: ChannelDao,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.clientScreenVersionMaps = data["channelsClientScreenVersions"];

      this.clientScreenVersions = this.clientScreenVersionMaps
        .map((v) => v.versions)
        .flat();

      this.initExtendedScreenNameTable();

      this.clientScreenVersions.forEach((value) => {
        this.updateAllScreensHidden(value);
        value.created_at = new Date(value.created_at);
      });
      this.initSort();
      this.initFilters();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  initExtendedScreenNameTable() {
    const nbCols = 3;

    this.clientScreenVersionMaps.forEach((versionMap) => {
      versionMap.versions.forEach((screenVersion) => {
        const result = [];

        for (
          let index = 0;
          index < screenVersion.screens.length;
          index += nbCols
        ) {
          const chunk = screenVersion.screens.slice(index, index + nbCols);
          result.push(chunk);
        }

        this.clientScreenInfos[this.getClientScreenVersionKey(screenVersion)] =
          {
            channelId: versionMap.channelId,
            expand: false,
            formattedScreenNames: result,
          };
      });
    });
  }

  initExpandTableSwitchMap() {
    this.clientScreenVersions.forEach((value) => {
      this.clientScreenInfos[this.getClientScreenVersionKey(value)] = false;
    });
  }

  initSort() {
    this.clientScreenVersions.forEach((value) => {
      if (value.screens.length > 1) {
        value.screens.sort((a, b) => a.name.localeCompare(b.name));
      }
    });
  }

  initFilters() {
    // Platform filters
    const propertyTypes = arrayToSet(
      this.clientScreenVersions.map((v) => v.platform),
    );
    this.listOfColumns[2].listOfFilter = propertyTypes.map((platform) => {
      return { text: platform, value: platform.toString() };
    });

    // Screen Names filters
    /*const sources = arrayToSet(this.clientScreenVersions.reduce((acc: string[], p: ChannelClientVersion) => {
      acc = acc.concat(...p.screen_names);
      return acc;
    }, []));
    this.listOfColumns[2].listOfFilter = sources.map((s: string) => {
      const settings = RegistryEntrySourceFormatted[s];
      return { text: settings.title, value: s };
    });*/
  }

  onExpandTable(clientScreenVersion: ChannelClientVersion) {
    const key = this.getClientScreenVersionKey(clientScreenVersion);

    this.clientScreenInfos[key].expanded =
      !this.clientScreenInfos[key].expanded;
  }

  getClientScreenVersionKey(clientScreenVersion: ChannelClientVersion): string {
    return (
      clientScreenVersion.app_version + "##" + clientScreenVersion.platform
    );
  }

  showHideScreen(
    channelClientVersion: ChannelClientVersion,
    screenIds: string[],
    hidden: boolean,
  ) {
    const key = this.getClientScreenVersionKey(channelClientVersion);
    const screenInfos: ClientScreenInfo = this.clientScreenInfos[key];

    if (!screenInfos) {
      return false;
    }

    // when screenIds is empty, we need to update all screens
    if (screenIds.length === 0) {
      screenIds = screenInfos.formattedScreenNames.flat().map((s) => s.id);
    }

    /*
    Screens are listed by channels, so we need to update each channel
    separately. We can't update all screens at once because they may
    belong to different channels.

    each line of screenInfos.formattedScreenNames represents a channel
    We need to map the channel if to this line so we can update the right screen on the right channel
    */

    this.channelDao
      .updateChannelClientScreenHidden(
        this.org.id,
        screenInfos.channelId,
        screenIds,
        hidden,
      )
      .then(() => {
        const screens = screenInfos.formattedScreenNames.map((screens) => {
          return screens.map((screen) => {
            if (screenIds.includes(screen.id)) {
              screen.hidden = hidden;
            }
            return screen;
          });
        });

        screenInfos.formattedScreenNames = screens;
        this.updateAllScreensHidden(channelClientVersion);

        this.notificationHelper.trigger(
          "Success",
          "Screen visibility updated successfully",
          "success",
        );
      })
      .catch((error) => {
        console.error(error);
        this.notificationHelper.trigger(
          "Error",
          "An error occurred while updating the screen visibility",
          "error",
        );
      });

    return true;
  }

  public updateAllScreensHidden(channelClientVersion: ChannelClientVersion) {
    const key = this.getClientScreenVersionKey(channelClientVersion);
    const screenInfos: ClientScreenInfo = this.clientScreenInfos[key];

    if (!screenInfos) {
      channelClientVersion.screenHiddenCount = 0;
    }

    channelClientVersion.screenHiddenCount =
      channelClientVersion.screens.filter((s) => s.hidden).length;
  }
}
