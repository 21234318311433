import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";

import { NotificationHelper } from "helpers/notification.helper";
import {
  Account,
  AccountJobTitlesToLabel,
  AccountOrgRoleToLabel,
} from "models/account.model";
import { Org } from "models/org.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";
import { NzModalService } from "ng-zorro-antd/modal";
import {
  NzTableSortFn,
  NzTableSortOrder,
  NzTableComponent,
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzThAddOnComponent,
  NzCellAlignDirective,
  NzCellFixedDirective,
  NzTbodyComponent,
  NzCellEllipsisDirective,
} from "ng-zorro-antd/table";
import { SessionService } from "services/auth.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { UIService } from "services/ui.service";
import { NzColDirective, NzRowDirective } from "ng-zorro-antd/grid";
import { SingleIndicatorStatsSurveyComponent } from "../../surveys/pages/stats/indicators/components/single-indicator/single-indicator.component";
import { NgIf, NgFor } from "@angular/common";
import { OrgAccountAvatarComponent } from "../../utils/org-account-avatar/org-account-avatar.component";
import { NzSelectComponent, NzOptionComponent } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { NzFormDirective } from "ng-zorro-antd/form";
import {
  NzInputGroupComponent,
  NzInputGroupWhitSuffixOrPrefixDirective,
  NzInputDirective,
} from "ng-zorro-antd/input";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ScreebIconComponent } from "../../utils/screeb-icon/screeb-icon.component";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { AddUserModalComponent } from "./add/add.component";
import { FormatDistanceToNowPipeModule } from "ngx-date-fns";
import { SuperPermissionPipe } from "pipes/super-permission.pipe";

interface ColumnItem {
  name: string;
  sortKey?: string;
  width?: string;
  align: "left" | "right" | "center";
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null | true;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: "page-super-org-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
  imports: [
    NzColDirective,
    NzRowDirective,
    SingleIndicatorStatsSurveyComponent,
    NgIf,
    OrgAccountAvatarComponent,
    NzSelectComponent,
    FormsModule,
    NgFor,
    NzOptionComponent,
    NzFormDirective,
    NzInputGroupComponent,
    ɵNzTransitionPatchDirective,
    NzInputGroupWhitSuffixOrPrefixDirective,
    NzInputDirective,
    NzIconDirective,
    NzButtonComponent,
    NzWaveDirective,
    ScreebIconComponent,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzThAddOnComponent,
    NzCellAlignDirective,
    NzCellFixedDirective,
    NzTbodyComponent,
    NzCellEllipsisDirective,
    NzTagComponent,
    AddUserModalComponent,
    FormatDistanceToNowPipeModule,
    SuperPermissionPipe,
  ],
})
export class UsersSuperOrgPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Organization users";
  public name = "Organization users";

  private obs: any = null;
  private pobs: any = null;

  public superOrg: SuperOrg = null;
  public workspaces: Org[] = [];
  public filteredWorkspacesIDs: string[] = [];
  public accounts: Account[] = [];
  public activeAccounts: Account[] = [];
  public filteredAccounts: Account[] = [];
  public mostActive?: Account = null;
  public leastActive?: Account = null;
  public usersTags: string[] = [];
  public isLoading = true;

  public editUser: Account = null;
  public addOpened = false;
  public filteredTags: string[] = [];

  public accountJobTitlesToLabel = AccountJobTitlesToLabel;
  public accountOrgRoleToLabel = AccountOrgRoleToLabel;

  public keyword: string = null;
  public listOfColumns: ColumnItem[] = [
    {
      name: "Name",
      width: "350px",
      align: "left",
      sortOrder: "ascend",
      sortFn: (a: Account, b: Account) => a.fullname.localeCompare(b.fullname),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Email Address",
      width: "250px",
      align: "center",
      sortOrder: "ascend",
      sortFn: (a: Account, b: Account) =>
        a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Number of Workspaces",
      width: "175px",
      align: "center",
      sortOrder: "ascend",
      sortFn: (a: Account, b: Account) =>
        (a.workspaces_accesses?.length || 0) -
        (b.workspaces_accesses?.length || 0),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Job Title",
      width: "150px",
      align: "center",
      sortOrder: "ascend",
      sortFn: (a: Account, b: Account) =>
        (a.job_title || "").localeCompare(b.job_title || ""),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Role",
      width: "200px",
      align: "center",
      sortOrder: "ascend",
      sortFn: (a: Account, b: Account) =>
        (a.role || "").localeCompare(b.role || ""),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Last Activity",
      width: "185px",
      align: "center",
      sortOrder: "ascend",
      sortFn: (a: Account, b: Account) =>
        a.last_seen_at > b.last_seen_at ? 1 : -1,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private modal: NzModalService,
    private notificationHelper: NotificationHelper,
    private superOrgDao: SuperOrgDao,
    private uiService: UIService,
    public sessionService: SessionService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe(async (data) => {
      this.superOrg = data.superOrg;
      this.workspaces = data.workspaces;
      this.accounts = data.superOrgAccounts;
      this.filteredAccounts = [...this.accounts];
      this.usersTags = [
        ...new Set(
          this.accounts
            .map((e) => e.tags)
            .flat()
            .filter((e) => e?.length),
        ),
      ];
      this.refreshHeader();
      this.isLoading = false;
    });

    this.pobs = this.route.queryParams.subscribe((params) => {
      if (params.workspace_id?.length) {
        this.onWorkspacesFilterChange(params.workspace_id.split(","));
      }
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }

    if (this.pobs) {
      this.pobs.unsubscribe();
    }
  }

  public refreshHeader() {
    this.mostActive = this.accounts.reduce((prev, current) =>
      prev.last_seen_at > current.last_seen_at ? prev : current,
    );
    this.leastActive = this.accounts.reduce((prev, current) =>
      prev.last_seen_at < current.last_seen_at ? prev : current,
    );
    this.activeAccounts = this.accounts.filter((account) => account.isActive);
  }

  public onAdd() {
    this.editUser = null;
    this.addOpened = true;
  }

  public onEdit(editUser: Account = null) {
    this.editUser = editUser;
    this.addOpened = true;
  }

  public onClose(needRefetch: boolean = false) {
    if (needRefetch) {
      this.superOrgDao
        .getAllSuperOrgAccounts(this.superOrg.id)
        .then((accounts) => {
          this.accounts = accounts;
          this.onSearchChangeImmediate(this.keyword ?? "");
          this.refreshHeader();
        })
        .catch((err) => {
          console.error(err);
        });
    }

    this.addOpened = false;
  }

  public deleteMember(user: Account) {
    this.modal.warning({
      nzTitle: "Are you sure you want to delete this user?",
      nzOkText: "Remove",
      nzCancelText: "Cancel",
      nzOkType: "default",
      nzOkDanger: true,
      nzStyle: {
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
      },
      nzOnOk: () => {
        this.superOrgDao
          .deleteAccount(this.superOrg.id, user.id)
          .then(() => {
            this.notificationHelper.trigger(
              "User deleted",
              `${user.fullname} has been removed from the organization`,
              "success",
            );
            this.accounts = this.accounts.filter((u) => u.id !== user.id);
            this.filteredAccounts = this.filteredAccounts.filter(
              (u) => u.id !== user.id,
            );
            this.refreshHeader();
            this.modal.closeAll();
          })
          .catch((err) => {
            this.notificationHelper.trigger(
              "Failed to delete user",
              null,
              "error",
            );
            console.error(err);
          });
      },
    });
  }

  /**
   * Search
   */
  public onSearchChange(keyword: string) {
    this.onSearchChangeImmediate(keyword);
  }

  public onSearchChangeImmediate(keyword: string) {
    this.filteredAccounts = this.accounts.filter((user) => {
      if (this.filteredTags && this.filteredTags.length > 0) {
        if (
          !this.filteredTags.every(
            (tag) => user.tags && user.tags.includes(tag),
          )
        ) {
          return false;
        }
      }

      if (this.filteredWorkspacesIDs && this.filteredWorkspacesIDs.length > 0) {
        if (
          !this.filteredWorkspacesIDs.every((workspaceID) =>
            user.workspaces_accesses?.some(
              (wa) => wa.workspace_id === workspaceID,
            ),
          )
        ) {
          return false;
        }
      }

      if (keyword?.length) {
        return (
          user.fullname.toLowerCase().includes(keyword.toLowerCase()) ||
          user.email.toLowerCase().includes(keyword.toLowerCase())
        );
      }
      return true;
    });
  }

  public onTagsFilterChange(tags: string[]) {
    this.filteredTags = tags;
    this.onSearchChangeImmediate(this.keyword ?? "");
  }

  public onWorkspacesFilterChange(workspaces: string[]) {
    this.filteredWorkspacesIDs = workspaces;
    this.onSearchChangeImmediate(this.keyword ?? "");
  }

  /* MFA */

  public onEnforceMFA(status: boolean) {
    this.superOrgDao
      .enforceMFA(this.superOrg.id, status)
      .then(() => {
        this.superOrg.flags.mfa_enforced = status;
        this.uiService.currentSuperOrg.flags.mfa_enforced = status;
        this.notificationHelper.trigger(
          "MFA enforcement",
          `MFA enforcement has been ${status ? "enabled" : "disabled"}`,
          "success",
        );
      })
      .catch((err) => {
        this.notificationHelper.trigger("Failed to enforce MFA", null, "error");
        console.error(err);
      });
  }
}
