import { Component, Input } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";
import { NgIf, NgClass } from "@angular/common";
import {
  NzInputGroupComponent,
  NzInputGroupWhitSuffixOrPrefixDirective,
} from "ng-zorro-antd/input";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { TargetingRuleInputNumberComponent } from "../rule-input-number/rule-input-number.component";
import { EntitlementPipe } from "pipes/entitlement.pipe";

@Component({
  selector: "rule-scroll",
  templateUrl: "./rule-scroll.component.html",
  styleUrls: ["./rule-scroll.component.scss"],
  imports: [
    NgIf,
    NzInputGroupComponent,
    ɵNzTransitionPatchDirective,
    NzInputGroupWhitSuffixOrPrefixDirective,
    NgClass,
    TargetingRuleInputNumberComponent,
    EntitlementPipe,
  ],
})
export class TargetingRuleScrollComponent {
  @Input()
  public rule: TargetingRule = null;

  constructor() {}

  public checkValidValue(r: TargetingRule): boolean {
    if (r.value.v_n === null || r.value.v_n === undefined) return false;
    return true;
  }
}
