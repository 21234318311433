<section
  class="header-section"
  *ngIf="scope !== 'org' && interaction !== 'in_page'"
>
  <div class="design-switch label">
    <span>
      I want a specific design for this
      {{
        scope === "distribution"
          ? "channel"
          : typeSelected === "survey"
            ? "survey"
            : "message"
      }}
      <p>This will override the default design</p>
    </span>
    <nz-switch
      ngDefaultControl
      [ngModelOptions]="{ standalone: true }"
      [ngModel]="!!tagSettings"
      (ngModelChange)="specificDesignSwitchChange.emit($event)"
      [nzDisabled]="!('survey_edition' | hasEntitlement)"
    ></nz-switch>
  </div>
</section>

<section
  class="design-content"
  *ngIf="
    tagSettings &&
    typeSelected === 'survey' &&
    (!interaction || interaction === 'in_app')
  "
>
  <p class="label" *ngIf="scope === 'org'">Default format</p>
  <p class="label" *ngIf="scope !== 'org'">Format</p>
  <p class="label-desc">Choose the format of your survey</p>
  <nz-radio-group
    ngDefaultControl
    [ngModelOptions]="{ standalone: true }"
    [(ngModel)]="tagSettings.format"
    (ngModelChange)="formatChange($event)"
  >
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col nzLg="24" nzXXl="12">
        <big-radio
          id="survey-tag-settings-design-format-conversationnal"
          nzValue="conversationnal"
          label="Conversational"
          badgeText="Best response rate"
          [content]="formatConversationalBigRadio"
        >
          <ng-template #formatConversationalBigRadio>
            <span class="format-radio-label">
              Perfect format to engage your users in an interactive and chatty
              way.
              <br />
              Its response rate is usually between
              <strong>40%</strong>
              and
              <strong>60%</strong>
              .
            </span>
          </ng-template>
        </big-radio>
      </div>

      <div nz-col nzLg="24" nzXXl="12">
        <big-radio
          id="survey-tag-settings-design-format-cards"
          nzValue="cards"
          label="Card"
          [content]="formatCardBigRadio"
        >
          <ng-template #formatCardBigRadio>
            <span class="format-radio-label">
              More traditional format, and perfect for all kind of survey
              purposes.
              <br />
              Response rate usually between
              <strong>20%</strong>
              and
              <strong>40%</strong>
              .
            </span>
          </ng-template>
        </big-radio>
      </div>
    </div>
  </nz-radio-group>
</section>

<section class="design-info" *ngIf="tagSettings || interaction === 'in_page'">
  <div>
    <p class="label">Graphical Settings</p>
    <p
      class="label-desc"
      *ngIf="tagSettings && !(scope !== 'org' && tagSettings?.advanced)"
    >
      This is where you define how your
      {{ typeSelected === "survey" ? "surveys" : "messages" }} will look like,
      check the preview to check the result of the selected options.
    </p>
    <div
      class="label-desc no-flex"
      *ngIf="
        scope !== 'org' && (tagSettings?.advanced || interaction === 'in_page')
      "
    >
      You've set custom graphical settings for all your
      {{ typeSelected === "survey" ? "surveys" : "messages" }}.
      <br />
      If you want to change colours, fonts, etc, you have to change your global
      settings
      <a
        [routerLink]="[
          '/',
          'org',
          org.id,
          'settings',
          'customize',
          'survey',
          interaction,
        ]"
      >
        here
      </a>
      .
      <br />
      These changes will be applied to all your
      {{ typeSelected === "survey" ? "surveys" : "messages" }}.
    </div>
  </div>
  <div *ngIf="scope === 'org'">
    <div class="design-switch label">
      <span>
        Advanced customisation
        <p>Unlock more customisation options</p>
      </span>
      <nz-switch
        ngDefaultControl
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="tagSettings.advanced"
        (ngModelChange)="advancedCustomisationChanged($event)"
      ></nz-switch>
    </div>
  </div>
</section>

<nz-tabset
  *ngIf="tagSettings && typeSelected === 'survey'"
  [nzSelectedIndex]="tabSelectedIndex"
  [nzAnimated]="false"
  (nzSelectedIndexChange)="changeTab($event)"
  class="scope--{{ scope }} interaction--{{ interaction }}"
>
  <nz-tab
    nzTitle="Conversational"
    *ngIf="!interaction || interaction === 'in_app'"
  >
    <section nz-row [nzGutter]="[32, 16]">
      <!-- Conversational General -->
      <div nz-col nzSpan="24">
        <div class="label">General</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">Pop sound</div>
            <div class="play-sound-wrapper">
              <nz-select
                class="select-100"
                [nzOptions]="popSoundsOptions"
                nzSize="large"
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.conversational_pop_sound"
              ></nz-select>

              <button
                nz-button
                [disabled]="
                  tagSettings.conversational_pop_sound === 'none' ||
                  !canPlayAudio
                "
                class="play-pop-sound"
                (click)="playSound(tagSettings.conversational_pop_sound)"
              >
                <screeb-icon *ngIf="!soundPlaying" size="md" icon="play" />
                <screeb-icon *ngIf="soundPlaying" size="md" icon="pause" />
              </button>
            </div>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings?.advanced && scope === 'org'"
          >
            <div class="label-desc">Overall size</div>
            <nz-select
              class="select-100"
              [nzOptions]="sizeOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_size"
            ></nz-select>
          </div>

          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">Position</div>
            <nz-select
              class="select-100"
              [nzOptions]="conversationalPlacementOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_position"
            ></nz-select>
          </div>
          <div
            nz-col
            nzSpan="24"
            nzXl="12"
            *ngIf="tagSettings?.advanced && scope === 'org'"
          >
            <div class="label-desc">Buttons</div>
            <div class="label-switch">
              <span
                (click)="
                  tagSettings.conversational_display_close_reduce =
                    !tagSettings.conversational_display_close_reduce
                "
              >
                Display close and reduce buttons
              </span>
              <nz-switch
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.conversational_display_close_reduce"
              ></nz-switch>
            </div>
          </div>
        </section>
      </div>

      <div
        nz-col
        nzSpan="24"
        *ngIf="(scope !== 'org' && !tagSettings?.advanced) || scope === 'org'"
      >
        <hr />
        <p class="label">Colors</p>
        <section nz-row [nzGutter]="[24, 8]">
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              (scope !== 'org' && !tagSettings?.advanced) || scope === 'org'
            "
          >
            <div class="label-desc">Primary background color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidPrimaryColor(
                  tagSettings.conversational_color_primary
                ),
              }"
              (colorChange)="conversationalPrimaryColorChange($event)"
              [(color)]="tagSettings.conversational_color_primary"
            ></color-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              (scope !== 'org' && !tagSettings?.advanced) || scope === 'org'
            "
          >
            <div class="label-desc">Primary text color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidPrimaryTextColor(
                  tagSettings.conversational_color_primary_text
                ),
              }"
              [(color)]="tagSettings.conversational_color_primary_text"
            ></color-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings?.advanced && scope === 'org'"
          >
            <div class="label-desc">Secondary background color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidSecondaryColor(
                  tagSettings.conversational_color_secondary
                ),
              }"
              (colorChange)="conversationalSecondaryColorChange($event)"
              [(color)]="tagSettings.conversational_color_secondary"
            ></color-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings?.advanced && scope === 'org'"
          >
            <div class="label-desc">Secondary text color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidSecondaryTextColor(
                  tagSettings.conversational_color_secondary_text
                ),
              }"
              [(color)]="tagSettings.conversational_color_secondary_text"
            ></color-picker>
          </div>
        </section>
      </div>

      <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
        <hr />
        <p class="label">Font</p>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Family</div>
            <font-select
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_font_family"
            ></font-select>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Size</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_font_size"
              [nzMin]="1"
              [nzMax]="40"
              [nzStep]="1"
              [nzStatus]="
                isValidFontSize(tagSettings.conversational_font_size)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Line height</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_font_line_height"
              [nzMin]="1"
              [nzMax]="40"
              [nzStep]="1"
              [nzStatus]="
                isValidFontLineHeight(
                  tagSettings.conversational_font_line_height
                )
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>
        </section>
      </div>

      <!-- Conversational Border -->
      <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
        <hr />
        <p class="label">Border</p>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">Radius</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_border_radius"
              [nzMin]="0"
              [nzMax]="100"
              [nzStep]="1"
              [nzStatus]="
                isValidBorderRadius(tagSettings.conversational_border_radius)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>

          <div nz-col nzSpan="24" nzXl="12">
            <div class="label-desc">Border line</div>
            <div class="label-switch">
              <span
                (click)="
                  tagSettings.conversational_border_line =
                    !tagSettings.conversational_border_line
                "
              >
                Customize border line
              </span>
              <nz-switch
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.conversational_border_line"
              ></nz-switch>
            </div>
          </div>

          <div
            nz-col
            nzXs="24"
            nzMd="12"
            *ngIf="tagSettings.conversational_border_line"
          >
            <div class="label-desc">Line width</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_border_line_width"
              [nzMin]="1"
              [nzMax]="20"
              [nzStep]="1"
              [nzStatus]="
                isValidBorderLineWidth(
                  tagSettings.conversational_border_line_width
                )
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>

          <div
            nz-col
            nzXs="24"
            nzMd="12"
            *ngIf="tagSettings.conversational_border_line"
          >
            <div class="label-desc">Line color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidBorderLineColor(
                  tagSettings.conversational_border_line_color
                ),
              }"
              [alpha]="true"
              [(color)]="tagSettings.conversational_border_line_color"
            ></color-picker>
          </div>
        </section>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzSpan="24" nzMd="12">
            <div class="label-desc">Border shadow</div>
            <div class="label-switch">
              <span
                (click)="
                  tagSettings.conversational_border_shadow =
                    !tagSettings.conversational_border_shadow
                "
              >
                Customize border shadow
              </span>
              <nz-switch
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.conversational_border_shadow"
              ></nz-switch>
            </div>
          </div>

          <div
            nz-col
            nzXs="24"
            nzMd="12"
            *ngIf="tagSettings.conversational_border_shadow"
          >
            <div class="label-desc">Shadow color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidBorderShadowColor(
                  tagSettings.conversational_border_shadow_color
                ),
              }"
              [alpha]="true"
              [color]="tagSettings.conversational_border_shadow_color"
              (colorChange)="onBorderShadowColorChange($event)"
            ></color-picker>
          </div>
        </section>
      </div>

      <!-- Conversational Overlay -->
      <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
        <hr />
        <div class="label">Overlay</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Visibility</div>
            <nz-select
              class="select-100"
              [nzOptions]="overlayVisibilityOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_overlay_visibility"
            ></nz-select>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidOverlayColor(
                  tagSettings.conversational_overlay_color
                ),
              }"
              [alpha]="true"
              [(color)]="tagSettings.conversational_overlay_color"
            ></color-picker>
          </div>
        </section>
      </div>

      <div nz-col nzSpan="24">
        <hr />
        <div class="label">Display name, avatar and title</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">From</div>
            <nz-select
              class="select-100"
              [nzOptions]="identityTypeOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [ngModel]="tagSettings.conversational_identity_type"
              (ngModelChange)="onIdentityTypeChange($event, 'conversational')"
            ></nz-select>
          </div>
          <div
            nz-col
            nzXs="24"
            nzMd="12"
            *ngIf="tagSettings.conversational_identity_type === 'fixed'"
          >
            <div class="label-desc">Avatar</div>
            <file-image-picker
              [canDelete]="false"
              [maxSize]="maxAvatarSize"
              [loading]="loadingAvatar"
              [resize]="{ minWidth: 512, minHeight: 512 }"
              [imageUrl]="tagSettings.conversational_identity_fixed_avatar"
              (imageFileChange)="
                imageAvatarFileChange($event, 'survey', 'conversationnal')
              "
              [error]="
                !tagSettings.conversational_identity_fixed_avatar?.length
                  ? 'Required'
                  : null
              "
            ></file-image-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              tagSettings.conversational_identity_type === 'fixed' ||
              tagSettings.conversational_identity_type === 'screeby'
            "
          >
            <div class="label-desc">Name</div>
            <input
              type="text"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_identity_fixed_name"
              nzSize="large"
              nz-input
              [minLength]="1"
              [maxLength]="56"
              placeholder="Sophia"
              [ngClass]="{
                invalid: !isValidName(
                  tagSettings.conversational_identity_fixed_name
                ),
              }"
            />
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              tagSettings.conversational_identity_type === 'fixed' ||
              tagSettings.conversational_identity_type === 'screeby'
            "
          >
            <div class="label-desc">Title or job</div>
            <input
              type="text"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_identity_fixed_title"
              nzSize="large"
              nz-input
              [minLength]="3"
              [maxLength]="128"
              placeholder="Founder"
              [ngClass]="{
                invalid: !isValidTitle(
                  tagSettings.conversational_identity_fixed_title
                ),
              }"
            />
          </div>

          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              tagSettings?.advanced &&
              scope === 'org' &&
              (tagSettings.conversational_identity_type === 'fixed' ||
                tagSettings.conversational_identity_type === 'screeby')
            "
          >
            <div class="label-desc">Avatar format</div>
            <nz-select
              class="select-100"
              [nzOptions]="avatarFormatOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.conversational_identity_avatar_format"
            ></nz-select>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings?.advanced && scope === 'org'"
          ></div>
        </section>
      </div>
    </section>
  </nz-tab>

  <nz-tab nzTitle="Cards" *ngIf="!interaction || interaction === 'in_app'">
    <section nz-row [nzGutter]="[32, 16]">
      <!-- Cards General -->
      <div nz-col nzSpan="24">
        <div class="label">General</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">Pop sound</div>
            <div class="play-sound-wrapper">
              <nz-select
                class="select-100"
                [nzOptions]="popSoundsOptions"
                nzSize="large"
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.cards_pop_sound"
              ></nz-select>

              <button
                nz-button
                [disabled]="
                  tagSettings.cards_pop_sound === 'none' || !canPlayAudio
                "
                class="play-pop-sound"
                (click)="playSound(tagSettings.cards_pop_sound)"
              >
                <screeb-icon *ngIf="!soundPlaying" size="md" icon="play" />
                <screeb-icon *ngIf="soundPlaying" size="md" icon="pause" />
              </button>
            </div>
          </div>
          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">Position</div>
            <nz-select
              class="select-100"
              [nzOptions]="cardsPlacementOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_position"
            ></nz-select>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings?.advanced && scope === 'org'"
          >
            <div class="label-desc">Overall size</div>
            <nz-select
              class="select-100"
              [nzOptions]="sizeOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_size"
            ></nz-select>
          </div>
          <div
            nz-col
            nzSpan="24"
            nzXl="12"
            *ngIf="tagSettings?.advanced && scope === 'org'"
          >
            <div class="label-desc">Buttons</div>
            <div class="label-switch">
              <span
                (click)="
                  tagSettings.cards_display_reduce_button =
                    !tagSettings.cards_display_reduce_button
                "
              >
                Display reduce button
              </span>
              <nz-switch
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.cards_display_reduce_button"
              ></nz-switch>
            </div>
          </div>
        </section>
      </div>

      <!-- Cards Colors -->
      <div
        nz-col
        nzSpan="24"
        *ngIf="(scope !== 'org' && !tagSettings?.advanced) || scope === 'org'"
      >
        <hr />
        <div class="label">Colors</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              (scope !== 'org' && !tagSettings?.advanced) || scope === 'org'
            "
          >
            <div class="label-desc">Inner background color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidPrimaryColor(tagSettings.cards_color_primary),
              }"
              (colorChange)="cardsPrimaryColorChange($event)"
              [(color)]="tagSettings.cards_color_primary"
            ></color-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              (scope !== 'org' && !tagSettings?.advanced) || scope === 'org'
            "
          >
            <div class="label-desc">Inner text color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidPrimaryTextColor(
                  tagSettings.cards_color_primary_text
                ),
              }"
              [(color)]="tagSettings.cards_color_primary_text"
            ></color-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings?.advanced && scope === 'org'"
          >
            <div class="label-desc">Header background color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidSecondaryColor(
                  tagSettings.cards_color_secondary
                ),
              }"
              (colorChange)="cardsSecondaryColorChange($event)"
              [(color)]="tagSettings.cards_color_secondary"
            ></color-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="tagSettings?.advanced && scope === 'org'"
          >
            <div class="label-desc">Header text color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidSecondaryTextColor(
                  tagSettings.cards_color_secondary_text
                ),
              }"
              [(color)]="tagSettings.cards_color_secondary_text"
            ></color-picker>
          </div>
        </section>
      </div>

      <!-- Cards Font -->
      <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
        <hr />
        <div class="label">Font</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Family</div>
            <font-select
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_font_family"
            ></font-select>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Size</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_font_size"
              [nzMin]="1"
              [nzMax]="40"
              [nzStep]="1"
              [nzStatus]="
                isValidFontSize(tagSettings.cards_font_size)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Line height</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_font_line_height"
              [nzMin]="1"
              [nzMax]="40"
              [nzStep]="1"
              [nzStatus]="
                isValidFontLineHeight(tagSettings.cards_font_line_height)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>
        </section>
      </div>

      <!-- Cards Border -->
      <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
        <hr />
        <p class="label">Border</p>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">Border radius</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_border_radius"
              [nzMin]="0"
              [nzMax]="40"
              [nzStep]="1"
              [nzStatus]="
                isValidBorderRadius(tagSettings.cards_border_radius)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>

          <div nz-col nzSpan="24" nzXl="12">
            <div class="label-desc">Border line</div>
            <div class="label-switch">
              <span
                (click)="
                  tagSettings.cards_border_line = !tagSettings.cards_border_line
                "
              >
                Customize Border line
              </span>
              <nz-switch
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.cards_border_line"
              ></nz-switch>
            </div>
          </div>

          <div nz-col nzXs="24" nzMd="12" *ngIf="tagSettings.cards_border_line">
            <div class="label-desc">Border line width</div>
            <nz-input-number
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_border_line_width"
              [nzMin]="1"
              [nzMax]="20"
              [nzStep]="1"
              [nzStatus]="
                isValidBorderLineWidth(tagSettings.cards_border_line_width)
                  ? undefined
                  : 'error'
              "
            ></nz-input-number>
          </div>

          <div nz-col nzXs="24" nzMd="12" *ngIf="tagSettings.cards_border_line">
            <div class="label-desc">Border line color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidBorderLineColor(
                  tagSettings.cards_border_line_color
                ),
              }"
              [alpha]="true"
              [(color)]="tagSettings.cards_border_line_color"
            ></color-picker>
          </div>
        </section>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzSpan="24" nzMd="12">
            <div class="label-desc">Border shadow</div>
            <div class="label-switch">
              <span
                (click)="
                  tagSettings.cards_border_shadow =
                    !tagSettings.cards_border_shadow
                "
              >
                Customize border shadow
              </span>
              <nz-switch
                ngDefaultControl
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="tagSettings.cards_border_shadow"
              ></nz-switch>
            </div>
          </div>

          <div
            nz-col
            nzXs="24"
            nzMd="12"
            *ngIf="tagSettings.cards_border_shadow"
          >
            <div class="label-desc">Border shadow color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidBorderShadowColor(
                  tagSettings.cards_border_shadow_color
                ),
              }"
              [alpha]="true"
              [(color)]="tagSettings.cards_border_shadow_color"
            ></color-picker>
          </div>
        </section>
      </div>

      <!-- Cards Overlay -->
      <div
        hidden
        nz-col
        nzSpan="24"
        *ngIf="tagSettings?.advanced && scope === 'org'"
      >
        <hr />
        <div class="label">Overlay</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">Visibility</div>
            <nz-select
              class="select-100"
              [nzOptions]="overlayVisibilityOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_overlay_visibility"
            ></nz-select>
          </div>

          <div nz-col nzXs="24" nzMd="12">
            <div class="abel-desc">Color</div>
            <color-picker
              [ngClass]="{
                invalid: !isValidOverlayColor(tagSettings.cards_overlay_color),
              }"
              [alpha]="true"
              [(color)]="tagSettings.cards_overlay_color"
            ></color-picker>
          </div>
        </section>
      </div>

      <!-- Cards Display name, avatar and title -->
      <div nz-col nzSpan="24">
        <hr />
        <div class="label">Display name, avatar and title</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzMd="12">
            <div class="label-desc">From</div>
            <nz-select
              class="select-100"
              [nzOptions]="identityTypeOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [ngModel]="tagSettings.cards_identity_type"
              (ngModelChange)="onIdentityTypeChange($event, 'cards')"
            ></nz-select>
          </div>
          <div
            nz-col
            nzXs="24"
            nzMd="12"
            *ngIf="tagSettings.cards_identity_type === 'fixed'"
          >
            <div class="label-desc">Avatar</div>
            <file-image-picker
              [canDelete]="false"
              [maxSize]="maxAvatarSize"
              [loading]="loadingAvatar"
              [resize]="{ minWidth: 512, minHeight: 512 }"
              [imageUrl]="tagSettings.cards_identity_fixed_avatar"
              (imageFileChange)="
                imageAvatarFileChange($event, 'survey', 'cards')
              "
              [error]="
                !tagSettings.cards_identity_fixed_avatar?.length
                  ? 'Required'
                  : null
              "
            ></file-image-picker>
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              tagSettings.cards_identity_type === 'fixed' ||
              tagSettings.cards_identity_type === 'screeby'
            "
          >
            <div class="label-desc">Name</div>
            <input
              type="text"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_identity_fixed_name"
              nzSize="large"
              nz-input
              [minLength]="1"
              [maxLength]="56"
              placeholder="Sophia"
              [ngClass]="{
                invalid: !isValidName(tagSettings.cards_identity_fixed_name),
              }"
            />
          </div>
          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              tagSettings.cards_identity_type === 'fixed' ||
              tagSettings.cards_identity_type === 'screeby'
            "
          >
            <div class="label-desc">Title or job</div>
            <input
              type="text"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_identity_fixed_title"
              nzSize="large"
              nz-input
              [minLength]="3"
              [maxLength]="128"
              placeholder="Founder"
              [ngClass]="{
                invalid: !isValidTitle(tagSettings.cards_identity_fixed_title),
              }"
            />
          </div>

          <div
            nz-col
            nzXs="24"
            nzXl="12"
            *ngIf="
              tagSettings?.advanced &&
              scope === 'org' &&
              (tagSettings.cards_identity_type === 'fixed' ||
                tagSettings.cards_identity_type === 'screeby')
            "
          >
            <div class="label-desc">Avatar format</div>
            <nz-select
              class="select-100"
              [nzOptions]="avatarFormatOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.cards_identity_avatar_format"
            ></nz-select>
          </div>
        </section>
      </div>
    </section>
  </nz-tab>

  <nz-tab
    nzTitle="Hosted page"
    *ngIf="
      !interaction ||
      interaction === 'link' ||
      surveyDistribution?.type === 'hosted-page'
    "
  >
    <section>
      <div nz-row [nzGutter]="[32, 16]" nzAlign="bottom">
        <div nz-col nzXs="24" nzXl="12">
          <div class="label">Background</div>
          <div class="label-desc">Type of background</div>
          <nz-select
            class="select-100"
            [nzOptions]="backgroundTypeOptions"
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.page_background_type"
            (ngModelChange)="onPageBackgroundTypeChange('', $event)"
          ></nz-select>
        </div>

        <div
          nz-col
          nzAlign="bottom"
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings.page_background_type === 'color'"
        >
          <div class="label-desc">Page background color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidBackgroundColor(
                tagSettings.page_background_color
              ),
            }"
            [(color)]="tagSettings.page_background_color"
          ></color-picker>
        </div>

        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings.page_background_type === 'image'"
        >
          <div class="label-desc">Page background image</div>
          <file-image-picker
            [canDelete]="false"
            [maxSize]="maxBackgroundSize"
            [loading]="loadingBackground"
            [imageUrl]="tagSettings.page_background_image_url"
            (imageFileChange)="imageBackgroundFileChange('', $event)"
            radius="square"
          ></file-image-picker>
        </div>

        <div *ngIf="interaction === 'link'" nz-col nzXs="24" nzXl="12">
          <div class="label-desc">Survey position</div>
          <nz-select
            class="select-100"
            [nzOptions]="backgroundIconPositionOptions"
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.link_position"
          ></nz-select>
        </div>
        <div *ngIf="interaction === 'link'" nz-col nzXs="24" nzXl="12">
          <div class="label-desc">Page background icon</div>
          <file-image-picker
            [canDelete]="true"
            [maxSize]="maxBackgroundSize"
            [loading]="loadingBackgroundIcon"
            [imageUrl]="tagSettings.page_background_icon"
            (imageFileChange)="imageBackgroundIconFileChange($event)"
          ></file-image-picker>
        </div>
      </div>
    </section>
  </nz-tab>
</nz-tabset>

<section
  *ngIf="tagSettings && interaction === 'feedback_button'"
  nz-row
  [nzGutter]="[32, 16]"
>
  <div nz-col nzSpan="24" *ngIf="scope === 'org'">
    <hr style="margin-top: 24px" />
    <div class="label">
      <!-- <screeb-icon size="md" icon="desktop"></screeb-icon> -->
      Button settings
    </div>

    <div class="label-desc">
      Those settings will only be applied on the button
    </div>
  </div>

  <div nz-col nzSpan="24">
    <p class="label" *ngIf="scope === 'org'">Default format</p>
    <p class="label" *ngIf="scope !== 'org'">Format</p>
    <p class="label-desc">Choose the format of your Feedback Button</p>
    <nz-radio-group
      ngDefaultControl
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="tagSettings.feedback_button_cta_format"
      (ngModelChange)="tagSettings.feedback_button_cta_format = $event"
    >
      <div nz-row [nzGutter]="[16, 16]">
        <div nz-col nzLg="24" nzXXl="12">
          <big-radio
            id="survey-tag-settings-design-format-conversationnal"
            nzValue="label"
            label="Label"
            badgeText="Best response rate"
            [content]="formatLabelBigRadio"
          >
            <ng-template #formatLabelBigRadio>
              <span class="format-radio-label">
                More traditional format, perfect to display a professional and
                serious image.
              </span>
            </ng-template>
          </big-radio>
        </div>

        <div nz-col nzLg="24" nzXXl="12">
          <big-radio
            id="survey-tag-settings-design-format-cards"
            nzValue="avatar"
            label="Avatar"
            [content]="formatAvatarBigRadio"
          >
            <ng-template #formatAvatarBigRadio>
              <span class="format-radio-label">
                Perfect format to engage your users in an interactive and chatty
                way.
              </span>
            </ng-template>
          </big-radio>
        </div>
      </div>
    </nz-radio-group>
  </div>

  <!-- FeedbackButton CTA General -->
  <div nz-col nzSpan="24">
    <div class="label">General</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Pop sound</div>
        <div class="play-sound-wrapper">
          <nz-select
            class="select-100"
            [nzOptions]="popSoundsOptions"
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.feedback_button_pop_sound"
          ></nz-select>

          <button
            nz-button
            [disabled]="
              tagSettings.feedback_button_pop_sound === 'none' || !canPlayAudio
            "
            class="play-pop-sound"
            (click)="playSound(tagSettings.feedback_button_pop_sound)"
          >
            <screeb-icon *ngIf="!soundPlaying" size="md" icon="play" />
            <screeb-icon *ngIf="soundPlaying" size="md" icon="pause" />
          </button>
        </div>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Position</div>
        <nz-select
          class="select-100"
          [nzOptions]="feedbackButtonPlacementOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_position"
        ></nz-select>
      </div>

      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="tagSettings?.advanced && scope === 'org'"
      >
        <div class="label-desc">Overall size</div>
        <nz-select
          class="select-100"
          [nzOptions]="sizeOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_size"
        ></nz-select>
      </div>

      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="tagSettings.feedback_button_cta_format === 'label'"
      >
        <div class="label-desc">Label</div>
        <input
          type="text"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_cta_label"
          nzSize="large"
          nz-input
          [minLength]="3"
          [maxLength]="128"
          placeholder="Feedback"
          [ngClass]="{
            invalid: !isValidName(tagSettings.feedback_button_cta_label),
          }"
        />
      </div>
      <div
        *ngIf="tagSettings.feedback_button_cta_format === 'label'"
        nz-col
        nzXs="24"
        nzMd="12"
      >
        <div class="label-desc">Icon</div>
        <nz-select
          class="select-100"
          [nzOptions]="labelIconTypeFeedbackButtonOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_cta_label_icon_type"
        ></nz-select>
      </div>
      <div
        nz-col
        nzXs="24"
        nzMd="12"
        *ngIf="
          tagSettings.feedback_button_cta_format === 'label' &&
          tagSettings.feedback_button_cta_label_icon_type === 'custom' &&
          tagSettings.feedback_button_cta_format === 'label'
        "
      >
        <div class="label-desc">Icon</div>
        <file-image-picker
          class="feedback-button-icon"
          radius="square"
          [canDelete]="false"
          [maxSize]="maxAvatarSize"
          [loading]="loadingIcon"
          [resize]="{ minWidth: 512, minHeight: 512 }"
          [imageUrl]="tagSettings.feedback_button_cta_label_icon"
          (imageFileChange)="iconFeedbackButtonCTAFileChange($event)"
        ></file-image-picker>
      </div>
      <!-- feedback button CTA Display avatar -->
      <div
        *ngIf="tagSettings.feedback_button_cta_format === 'avatar'"
        nz-col
        nzXs="24"
        nzMd="12"
      >
        <div class="label-desc">From</div>
        <nz-select
          class="select-100"
          [nzOptions]="identityTypeOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [ngModel]="tagSettings.feedback_button_identity_type"
          (ngModelChange)="onIdentityTypeChange($event, 'feedback_button')"
        ></nz-select>
      </div>

      <div
        nz-col
        nzXs="24"
        nzMd="12"
        *ngIf="
          tagSettings.feedback_button_identity_type === 'fixed' &&
          tagSettings.feedback_button_cta_format === 'avatar'
        "
      >
        <div class="label-desc">Avatar</div>
        <file-image-picker
          [canDelete]="false"
          [maxSize]="maxAvatarSize"
          [loading]="loadingAvatar"
          [resize]="{ minWidth: 512, minHeight: 512 }"
          [imageUrl]="tagSettings.feedback_button_identity_fixed_avatar"
          (imageFileChange)="
            imageAvatarFileChange($event, 'survey', 'feedback_button')
          "
          [error]="
            !tagSettings.feedback_button_identity_fixed_avatar?.length
              ? 'Required'
              : null
          "
        ></file-image-picker>
      </div>
    </section>
  </div>

  <!-- feedback button CTA Colors -->
  <div
    nz-col
    nzSpan="24"
    *ngIf="(scope !== 'org' && !tagSettings?.advanced) || scope === 'org'"
  >
    <div class="label">Colors</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="(scope !== 'org' && !tagSettings?.advanced) || scope === 'org'"
      >
        <div class="label-desc">Background color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidPrimaryColor(
              tagSettings.feedback_button_cta_color_primary
            ),
          }"
          (colorChange)="feedbackButtonCTAPrimaryColorChange($event)"
          [(color)]="tagSettings.feedback_button_cta_color_primary"
        ></color-picker>
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="
          ((scope !== 'org' && !tagSettings?.advanced) || scope === 'org') &&
          tagSettings.feedback_button_cta_format === 'label'
        "
      >
        <div class="label-desc">Text color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidPrimaryTextColor(
              tagSettings.feedback_button_cta_color_primary_text
            ),
          }"
          [(color)]="tagSettings.feedback_button_cta_color_primary_text"
        ></color-picker>
      </div>
    </section>
  </div>

  <!-- feedback button CTA Font -->
  <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
    <div class="label">Font</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Family</div>
        <font-select
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_cta_font_family"
        ></font-select>
      </div>

      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Size</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_cta_font_size"
          [nzMin]="1"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidFontSize(tagSettings.feedback_button_cta_font_size)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>

      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Line height</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_cta_font_line_height"
          [nzMin]="1"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidFontLineHeight(
              tagSettings.feedback_button_cta_font_line_height
            )
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>
    </section>
  </div>

  <!-- feedback button CTA Border -->
  <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
    <p class="label">Border</p>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Border radius</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_cta_border_radius"
          [nzMin]="0"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidBorderRadius(tagSettings.feedback_button_cta_border_radius)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>

      <div nz-col nzSpan="24" nzXl="12">
        <div class="label-desc">Border line</div>
        <div class="label-switch">
          <span
            (click)="
              tagSettings.feedback_button_cta_border_line =
                !tagSettings.feedback_button_cta_border_line
            "
          >
            Customize Border line
          </span>
          <nz-switch
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.feedback_button_cta_border_line"
          ></nz-switch>
        </div>
      </div>

      <div
        nz-col
        nzXs="24"
        nzMd="12"
        *ngIf="tagSettings.feedback_button_cta_border_line"
      >
        <div class="label-desc">Border line width</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_cta_border_line_width"
          [nzMin]="1"
          [nzMax]="20"
          [nzStep]="1"
          [nzStatus]="
            isValidBorderLineWidth(
              tagSettings.feedback_button_cta_border_line_width
            )
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>

      <div
        nz-col
        nzXs="24"
        nzMd="12"
        *ngIf="tagSettings.feedback_button_cta_border_line"
      >
        <div class="label-desc">Border line color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidBorderLineColor(
              tagSettings.feedback_button_cta_border_line_color
            ),
          }"
          [alpha]="true"
          [(color)]="tagSettings.feedback_button_cta_border_line_color"
        ></color-picker>
      </div>
    </section>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzSpan="24" nzMd="12">
        <div class="label-desc">Border shadow</div>
        <div class="label-switch">
          <span
            (click)="
              tagSettings.feedback_button_cta_border_shadow =
                !tagSettings.feedback_button_cta_border_shadow
            "
          >
            Customize border shadow
          </span>
          <nz-switch
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.feedback_button_cta_border_shadow"
          ></nz-switch>
        </div>
      </div>

      <div
        nz-col
        nzXs="24"
        nzMd="12"
        *ngIf="tagSettings.feedback_button_cta_border_shadow"
      >
        <div class="label-desc">Border shadow color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidBorderShadowColor(
              tagSettings.feedback_button_cta_border_shadow_color
            ),
          }"
          [alpha]="true"
          [(color)]="tagSettings.feedback_button_cta_border_shadow_color"
        ></color-picker>
      </div>
    </section>
  </div>

  <div nz-col nzSpan="24" *ngIf="scope === 'org'">
    <hr style="margin-top: 24px" />
    <div class="label">
      <!-- <screeb-icon size="md" icon="email"></screeb-icon> -->
      Card settings
    </div>

    <!-- todo: wording -->
    <div class="label-desc">Those settings will be applied on the card</div>
  </div>

  <!-- feedback button Colors -->
  <div
    nz-col
    nzSpan="24"
    *ngIf="(scope !== 'org' && !tagSettings?.advanced) || scope === 'org'"
  >
    <div class="label">Colors</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="(scope !== 'org' && !tagSettings?.advanced) || scope === 'org'"
      >
        <div class="label-desc">Inner background color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidPrimaryColor(
              tagSettings.feedback_button_color_primary
            ),
          }"
          (colorChange)="feedbackButtonPrimaryColorChange($event)"
          [(color)]="tagSettings.feedback_button_color_primary"
        ></color-picker>
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="(scope !== 'org' && !tagSettings?.advanced) || scope === 'org'"
      >
        <div class="label-desc">Inner text color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidPrimaryTextColor(
              tagSettings.feedback_button_color_primary_text
            ),
          }"
          [(color)]="tagSettings.feedback_button_color_primary_text"
        ></color-picker>
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="tagSettings?.advanced && scope === 'org'"
      >
        <div class="label-desc">Header background color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidSecondaryColor(
              tagSettings.feedback_button_color_secondary
            ),
          }"
          (colorChange)="feedbackButtonSecondaryColorChange($event)"
          [(color)]="tagSettings.feedback_button_color_secondary"
        ></color-picker>
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="tagSettings?.advanced && scope === 'org'"
      >
        <div class="label-desc">Header text color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidSecondaryTextColor(
              tagSettings.feedback_button_color_secondary_text
            ),
          }"
          [(color)]="tagSettings.feedback_button_color_secondary_text"
        ></color-picker>
      </div>
    </section>
  </div>

  <!-- feedback button Font -->
  <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
    <div class="label">Font</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Family</div>
        <font-select
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_font_family"
        ></font-select>
      </div>

      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Size</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_font_size"
          [nzMin]="1"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidFontSize(tagSettings.feedback_button_font_size)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>

      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Line height</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_font_line_height"
          [nzMin]="1"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidFontLineHeight(tagSettings.feedback_button_font_line_height)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>
    </section>
  </div>

  <!-- feedback button Border -->
  <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
    <p class="label">Border</p>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Border radius</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_border_radius"
          [nzMin]="0"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidBorderRadius(tagSettings.feedback_button_border_radius)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>

      <div nz-col nzSpan="24" nzXl="12">
        <div class="label-desc">Border line</div>
        <div class="label-switch">
          <span
            (click)="
              tagSettings.feedback_button_border_line =
                !tagSettings.feedback_button_border_line
            "
          >
            Customize Border line
          </span>
          <nz-switch
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.feedback_button_border_line"
          ></nz-switch>
        </div>
      </div>

      <div
        nz-col
        nzXs="24"
        nzMd="12"
        *ngIf="tagSettings.feedback_button_border_line"
      >
        <div class="label-desc">Border line width</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_border_line_width"
          [nzMin]="1"
          [nzMax]="20"
          [nzStep]="1"
          [nzStatus]="
            isValidBorderLineWidth(
              tagSettings.feedback_button_border_line_width
            )
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>

      <div
        nz-col
        nzXs="24"
        nzMd="12"
        *ngIf="tagSettings.feedback_button_border_line"
      >
        <div class="label-desc">Border line color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidBorderLineColor(
              tagSettings.feedback_button_border_line_color
            ),
          }"
          [alpha]="true"
          [(color)]="tagSettings.feedback_button_border_line_color"
        ></color-picker>
      </div>
    </section>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzSpan="24" nzMd="12">
        <div class="label-desc">Border shadow</div>
        <div class="label-switch">
          <span
            (click)="
              tagSettings.feedback_button_border_shadow =
                !tagSettings.feedback_button_border_shadow
            "
          >
            Customize border shadow
          </span>
          <nz-switch
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.feedback_button_border_shadow"
          ></nz-switch>
        </div>
      </div>

      <div
        nz-col
        nzXs="24"
        nzMd="12"
        *ngIf="tagSettings.feedback_button_border_shadow"
      >
        <div class="label-desc">Border shadow color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidBorderShadowColor(
              tagSettings.feedback_button_border_shadow_color
            ),
          }"
          [alpha]="true"
          [(color)]="tagSettings.feedback_button_border_shadow_color"
        ></color-picker>
      </div>
    </section>
  </div>

  <!-- feedback button Overlay -->
  <div
    hidden
    nz-col
    nzSpan="24"
    *ngIf="tagSettings?.advanced && scope === 'org'"
  >
    <hr />
    <div class="label">Overlay</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Visibility</div>
        <nz-select
          class="select-100"
          [nzOptions]="overlayVisibilityOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_overlay_visibility"
        ></nz-select>
      </div>

      <div nz-col nzXs="24" nzMd="12">
        <div class="abel-desc">Color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidOverlayColor(
              tagSettings.feedback_button_overlay_color
            ),
          }"
          [alpha]="true"
          [(color)]="tagSettings.feedback_button_overlay_color"
        ></color-picker>
      </div>
    </section>
  </div>

  <!-- feedback button Display name and title -->
  <div nz-col nzSpan="24">
    <div class="label">Display name and title</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Name</div>
        <input
          type="text"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_identity_fixed_name"
          nzSize="large"
          nz-input
          [minLength]="1"
          [maxLength]="56"
          placeholder="Sophia"
          [ngClass]="{
            invalid: !isValidName(
              tagSettings.feedback_button_identity_fixed_name
            ),
          }"
        />
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Title or job</div>
        <input
          type="text"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.feedback_button_identity_fixed_title"
          nzSize="large"
          nz-input
          [minLength]="3"
          [maxLength]="128"
          placeholder="Founder"
          [ngClass]="{
            invalid: !isValidTitle(
              tagSettings.feedback_button_identity_fixed_title
            ),
          }"
        />
      </div>
    </section>
  </div>
</section>

<section
  *ngIf="tagSettings && interaction === 'email'"
  nz-row
  [nzGutter]="[32, 16]"
>
  <div nz-col nzSpan="24" *ngIf="scope === 'org' && tagSettings?.advanced">
    <hr style="margin-top: 24px" />
    <div class="label">
      <screeb-icon size="md" icon="email"></screeb-icon>
      Embedded settings
    </div>

    <div class="label-desc">
      Those settings will be applied inside the email and in the second phase of
      the survey
    </div>
  </div>

  <div nz-col nzSpan="24">
    <div class="label">Colors</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Background color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidPrimaryColor(tagSettings.emails_color_primary),
          }"
          (colorChange)="emailsPrimaryColorChange($event)"
          [(color)]="tagSettings.emails_color_primary"
        ></color-picker>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Text color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidPrimaryTextColor(
              tagSettings.emails_color_primary_text
            ),
          }"
          [(color)]="tagSettings.emails_color_primary_text"
        ></color-picker>
      </div>
    </section>
  </div>
  <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
    <p class="label">Border</p>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Border radius</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.emails_border_radius"
          [nzMin]="0"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidBorderRadius(tagSettings.emails_border_radius)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>
    </section>
  </div>
  <!-- </div> -->
  <!-- Emails General -->
  <div nz-col nzSpan="24" *ngIf="scope === 'org' && tagSettings?.advanced">
    <hr style="margin-top: 24px" />
    <div class="label">
      <screeb-icon size="md" icon="desktop"></screeb-icon>
      Browser settings
    </div>

    <div class="label-desc">
      Those settings will only be applied in the second phase of the survey
    </div>
  </div>
  <div nz-col nzSpan="24">
    <div class="label">Background</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Type of background</div>
        <nz-select
          class="select-100"
          [nzOptions]="backgroundTypeOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.emails_page_background_type"
          (ngModelChange)="onPageBackgroundTypeChange('emails_', $event)"
        ></nz-select>
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="tagSettings.emails_page_background_type === 'color'"
      >
        <div class="label-desc">Page background color</div>
        @let validBackgroundColor =
          isValidBackgroundColor(tagSettings.emails_page_background_color);
        <color-picker
          [ngClass]="{
            invalid: !validBackgroundColor,
          }"
          [(color)]="tagSettings.emails_page_background_color"
        ></color-picker>
      </div>

      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="tagSettings.emails_page_background_type === 'image'"
      >
        <div class="label-desc">Page background image</div>
        <file-image-picker
          [canDelete]="false"
          [maxSize]="maxBackgroundSize"
          [loading]="loadingBackground"
          [imageUrl]="tagSettings.emails_page_background_image_url"
          (imageFileChange)="imageBackgroundFileChange('emails_', $event)"
        ></file-image-picker>
      </div>

      <div *ngIf="interaction === 'email'" nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Survey position</div>
        <nz-select
          class="select-100"
          [nzOptions]="backgroundIconPositionOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.emails_position"
        ></nz-select>
      </div>
      <div *ngIf="interaction === 'email'" nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Page background icon</div>
        <file-image-picker
          [canDelete]="true"
          [maxSize]="maxBackgroundSize"
          [loading]="loadingBackgroundIcon"
          [imageUrl]="tagSettings.emails_background_icon"
          (imageFileChange)="imageEmailsBackgroundIconFileChange($event)"
        ></file-image-picker>
      </div>

      <div nz-col nzSpan="24" *ngIf="scope === 'org' && tagSettings?.advanced">
        <div class="label">General</div>
        <section nz-row [nzGutter]="[24, 8]">
          <div nz-col nzXs="24" nzXl="12">
            <div class="label-desc">Overall size</div>
            <nz-select
              class="select-100"
              [nzOptions]="sizeOptions"
              nzSize="large"
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.emails_size"
            ></nz-select>
          </div>
        </section>
      </div>
    </section>
  </div>

  <!-- Emails Colors -->
  <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
    <div class="label">Colors</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="
          tagSettings?.advanced &&
          scope === 'org' &&
          (tagSettings.emails_identity_type === 'fixed' ||
            tagSettings.emails_identity_type === 'screeby')
        "
      >
        <div class="label-desc">Header background color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidSecondaryColor(tagSettings.emails_color_secondary),
          }"
          (colorChange)="emailsSecondaryColorChange($event)"
          [(color)]="tagSettings.emails_color_secondary"
        ></color-picker>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Actions text color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidSecondaryTextColor(
              tagSettings.emails_color_secondary_text
            ),
          }"
          [(color)]="tagSettings.emails_color_secondary_text"
        ></color-picker>
      </div>
    </section>
  </div>

  <!-- Emails Font -->
  <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
    <div class="label">Font</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Family</div>
        <font-select
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.emails_font_family"
        ></font-select>
      </div>

      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Size</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.emails_font_size"
          [nzMin]="1"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidFontSize(tagSettings.emails_font_size) ? undefined : 'error'
          "
        ></nz-input-number>
      </div>

      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Line height</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.emails_font_line_height"
          [nzMin]="1"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidFontLineHeight(tagSettings.emails_font_line_height)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>
    </section>
  </div>

  <!-- Email Border -->
  <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
    <p class="label">Border</p>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzSpan="24" nzXl="12">
        <div class="label-desc">Border line</div>
        <div class="label-switch">
          <span
            (click)="
              tagSettings.emails_border_line = !tagSettings.emails_border_line
            "
          >
            Customize Border line
          </span>
          <nz-switch
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.emails_border_line"
          ></nz-switch>
        </div>
      </div>

      <div nz-col nzXs="24" nzMd="12" *ngIf="tagSettings.emails_border_line">
        <div class="label-desc">Border line width</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.emails_border_line_width"
          [nzMin]="1"
          [nzMax]="20"
          [nzStep]="1"
          [nzStatus]="
            isValidBorderLineWidth(tagSettings.emails_border_line_width)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>

      <div nz-col nzXs="24" nzMd="12" *ngIf="tagSettings.emails_border_line">
        <div class="label-desc">Border line color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidBorderLineColor(
              tagSettings.emails_border_line_color
            ),
          }"
          [alpha]="true"
          [(color)]="tagSettings.emails_border_line_color"
        ></color-picker>
      </div>
    </section>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzSpan="24" nzMd="12">
        <div class="label-desc">Border shadow</div>
        <div class="label-switch">
          <span
            (click)="
              tagSettings.emails_border_shadow =
                !tagSettings.emails_border_shadow
            "
          >
            Customize border shadow
          </span>
          <nz-switch
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.emails_border_shadow"
          ></nz-switch>
        </div>
      </div>

      <div nz-col nzXs="24" nzMd="12" *ngIf="tagSettings.emails_border_shadow">
        <div class="label-desc">Border shadow color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidBorderShadowColor(
              tagSettings.emails_border_shadow_color
            ),
          }"
          [alpha]="true"
          [(color)]="tagSettings.emails_border_shadow_color"
        ></color-picker>
      </div>
    </section>
  </div>

  <!-- Emails Display name, avatar and title -->
  <div nz-col nzSpan="24">
    <hr />
    <div class="label">Display name, avatar and title</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">From</div>
        <nz-select
          class="select-100"
          [nzOptions]="identityTypeOptionalOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [ngModel]="tagSettings.emails_identity_type"
          (ngModelChange)="onIdentityTypeChange($event, 'emails')"
        ></nz-select>
      </div>
      <div
        nz-col
        nzXs="24"
        nzMd="12"
        *ngIf="tagSettings.emails_identity_type === 'fixed'"
      >
        <div class="label-desc">Avatar</div>
        <file-image-picker
          [canDelete]="false"
          [maxSize]="maxAvatarSize"
          [loading]="loadingAvatar"
          [resize]="{ minWidth: 512, minHeight: 512 }"
          [imageUrl]="tagSettings.emails_identity_fixed_avatar"
          (imageFileChange)="imageAvatarFileChange($event, 'survey', 'emails')"
          [error]="
            !tagSettings.emails_identity_fixed_avatar?.length
              ? 'Required'
              : null
          "
        ></file-image-picker>
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="
          tagSettings.emails_identity_type === 'fixed' ||
          tagSettings.emails_identity_type === 'screeby'
        "
      >
        <div class="label-desc">Name</div>
        <input
          type="text"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.emails_identity_fixed_name"
          nzSize="large"
          nz-input
          [minLength]="1"
          [maxLength]="56"
          placeholder="Sophia"
          [ngClass]="{
            invalid: !isValidName(tagSettings.emails_identity_fixed_name),
          }"
        />
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="
          tagSettings.emails_identity_type === 'fixed' ||
          tagSettings.emails_identity_type === 'screeby'
        "
      >
        <div class="label-desc">Title or job</div>
        <input
          type="text"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.emails_identity_fixed_title"
          nzSize="large"
          nz-input
          [minLength]="3"
          [maxLength]="128"
          placeholder="Founder"
          [ngClass]="{
            invalid: !isValidTitle(tagSettings.emails_identity_fixed_title),
          }"
        />
      </div>

      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="
          tagSettings?.advanced &&
          scope === 'org' &&
          tagSettings.emails_identity_type === 'fixed'
        "
      >
        <div class="label-desc">Avatar format</div>
        <nz-select
          class="select-100"
          [nzOptions]="avatarFormatOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.emails_identity_avatar_format"
        ></nz-select>
      </div>
    </section>
  </div>
</section>

<section
  *ngIf="tagSettings && interaction === 'link'"
  nz-row
  [nzGutter]="[32, 16]"
>
  <!-- Link General -->
  <div nz-col nzSpan="24" *ngIf="scope === 'org' && tagSettings?.advanced">
    <hr style="margin-top: 24px" />
    <div class="label">General</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Overall size</div>
        <nz-select
          class="select-100"
          [nzOptions]="sizeOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.link_size"
        ></nz-select>
      </div>
    </section>
  </div>

  <!-- Link Colors -->
  <div nz-col nzSpan="24">
    <hr
      *ngIf="scope === 'org'"
      style="{{ tagSettings?.advanced ? '' : 'margin-top: 24px' }}"
    />
    <div class="label">Colors</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Background color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidColorWithAlpha(tagSettings.link_color_primary),
          }"
          (colorChange)="inPagePrimaryColorChange($event)"
          [(color)]="tagSettings.link_color_primary"
          [alpha]="true"
        ></color-picker>
      </div>
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Text color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidPrimaryTextColor(
              tagSettings.link_color_primary_text
            ),
          }"
          [(color)]="tagSettings.link_color_primary_text"
        ></color-picker>
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="
          tagSettings?.advanced &&
          scope === 'org' &&
          (tagSettings.link_identity_type === 'fixed' ||
            tagSettings.link_identity_type === 'screeby')
        "
      >
        <div class="label-desc">Header background color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidSecondaryColor(tagSettings.link_color_secondary),
          }"
          (colorChange)="linkSecondaryColorChange($event)"
          [(color)]="tagSettings.link_color_secondary"
        ></color-picker>
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="tagSettings?.advanced && scope === 'org'"
      >
        <div class="label-desc">Actions text color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidSecondaryTextColor(
              tagSettings.link_color_secondary_text
            ),
          }"
          [(color)]="tagSettings.link_color_secondary_text"
        ></color-picker>
      </div>
    </section>
  </div>

  <!-- Link Font -->
  <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
    <hr />
    <div class="label">Font</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Family</div>
        <font-select
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.link_font_family"
        ></font-select>
      </div>

      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Size</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.link_font_size"
          [nzMin]="1"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidFontSize(tagSettings.link_font_size) ? undefined : 'error'
          "
        ></nz-input-number>
      </div>

      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Line height</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.link_font_line_height"
          [nzMin]="1"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidFontLineHeight(tagSettings.link_font_line_height)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>
    </section>
  </div>

  <!-- Link Border -->
  <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
    <hr />
    <p class="label">Border</p>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Border radius</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.link_border_radius"
          [nzMin]="0"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidBorderRadius(tagSettings.link_border_radius)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>

      <div nz-col nzSpan="24" nzXl="12">
        <div class="label-desc">Border line</div>
        <div class="label-switch">
          <span
            (click)="
              tagSettings.link_border_line = !tagSettings.link_border_line
            "
          >
            Customize Border line
          </span>
          <nz-switch
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.link_border_line"
          ></nz-switch>
        </div>
      </div>

      <div nz-col nzXs="24" nzMd="12" *ngIf="tagSettings.link_border_line">
        <div class="label-desc">Border line width</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.link_border_line_width"
          [nzMin]="1"
          [nzMax]="20"
          [nzStep]="1"
          [nzStatus]="
            isValidBorderLineWidth(tagSettings.link_border_line_width)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>

      <div nz-col nzXs="24" nzMd="12" *ngIf="tagSettings.link_border_line">
        <div class="label-desc">Border line color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidBorderLineColor(
              tagSettings.link_border_line_color
            ),
          }"
          [alpha]="true"
          [(color)]="tagSettings.link_border_line_color"
        ></color-picker>
      </div>
    </section>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzSpan="24" nzMd="12">
        <div class="label-desc">Border shadow</div>
        <div class="label-switch">
          <span
            (click)="
              tagSettings.link_border_shadow = !tagSettings.link_border_shadow
            "
          >
            Customize border shadow
          </span>
          <nz-switch
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.link_border_shadow"
          ></nz-switch>
        </div>
      </div>

      <div nz-col nzXs="24" nzMd="12" *ngIf="tagSettings.link_border_shadow">
        <div class="label-desc">Border shadow color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidBorderShadowColor(
              tagSettings.link_border_shadow_color
            ),
          }"
          [alpha]="true"
          [(color)]="tagSettings.link_border_shadow_color"
        ></color-picker>
      </div>
    </section>
  </div>

  <!-- Link Display name, avatar and title -->
  <div nz-col nzSpan="24">
    <hr />
    <div class="label">Display name, avatar and title</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">From</div>
        <nz-select
          class="select-100"
          [nzOptions]="identityTypeOptionalOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [ngModel]="tagSettings.link_identity_type"
          (ngModelChange)="onIdentityTypeChange($event, 'link')"
        ></nz-select>
      </div>
      <div
        nz-col
        nzXs="24"
        nzMd="12"
        *ngIf="tagSettings.link_identity_type === 'fixed'"
      >
        <div class="label-desc">Avatar</div>
        <file-image-picker
          [canDelete]="false"
          [maxSize]="maxAvatarSize"
          [loading]="loadingAvatar"
          [resize]="{ minWidth: 512, minHeight: 512 }"
          [imageUrl]="tagSettings.link_identity_fixed_avatar"
          (imageFileChange)="imageAvatarFileChange($event, 'survey', 'link')"
          [error]="
            !tagSettings.link_identity_fixed_avatar?.length ? 'Required' : null
          "
        ></file-image-picker>
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="
          tagSettings.link_identity_type === 'fixed' ||
          tagSettings.link_identity_type === 'screeby'
        "
      >
        <div class="label-desc">Name</div>
        <input
          type="text"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.link_identity_fixed_name"
          nzSize="large"
          nz-input
          [minLength]="1"
          [maxLength]="56"
          placeholder="Sophia"
          [ngClass]="{
            invalid: !isValidName(tagSettings.link_identity_fixed_name),
          }"
        />
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="
          tagSettings.link_identity_type === 'fixed' ||
          tagSettings.link_identity_type === 'screeby'
        "
      >
        <div class="label-desc">Title or job</div>
        <input
          type="text"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.link_identity_fixed_title"
          nzSize="large"
          nz-input
          [minLength]="3"
          [maxLength]="128"
          placeholder="Founder"
          [ngClass]="{
            invalid: !isValidTitle(tagSettings.link_identity_fixed_title),
          }"
        />
      </div>

      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="
          tagSettings?.advanced &&
          scope === 'org' &&
          tagSettings.link_identity_type === 'fixed'
        "
      >
        <div class="label-desc">Avatar format</div>
        <nz-select
          class="select-100"
          [nzOptions]="avatarFormatOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.link_identity_avatar_format"
        ></nz-select>
      </div>
    </section>
  </div>

  <!-- Link Overlay -->
  <div
    hidden
    nz-col
    nzSpan="24"
    *ngIf="tagSettings?.advanced && scope === 'org'"
  >
    <hr />
    <div class="label">Overlay</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Visibility</div>
        <nz-select
          class="select-100"
          [nzOptions]="overlayVisibilityOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.link_overlay_visibility"
        ></nz-select>
      </div>

      <div nz-col nzXs="24" nzMd="12">
        <div class="abel-desc">Color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidOverlayColor(tagSettings.link_overlay_color),
          }"
          [alpha]="true"
          [(color)]="tagSettings.link_overlay_color"
        ></color-picker>
      </div>
    </section>
  </div>
</section>

<section
  *ngIf="tagSettings && interaction === 'in_page'"
  nz-row
  [nzGutter]="[32, 16]"
>
  <!-- InPage General -->
  <div nz-col nzSpan="24" *ngIf="scope === 'org' && tagSettings?.advanced">
    <div class="label">General</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Overall size</div>
        <nz-select
          class="select-100"
          [nzOptions]="sizeOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.in_page_size"
        ></nz-select>
      </div>
    </section>
  </div>

  <!-- InPage Colors -->
  <div
    nz-col
    nzSpan="24"
    *ngIf="(scope !== 'org' && !tagSettings?.advanced) || scope === 'org'"
  >
    <hr *ngIf="scope === 'org' && tagSettings?.advanced" />
    <div class="label">Colors</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="(scope !== 'org' && !tagSettings?.advanced) || scope === 'org'"
      >
        <div class="label-desc">Background color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidColorWithAlpha(tagSettings.in_page_color_primary),
          }"
          (colorChange)="inPagePrimaryColorChange($event)"
          [(color)]="tagSettings.in_page_color_primary"
          [alpha]="true"
        ></color-picker>
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="(scope !== 'org' && !tagSettings?.advanced) || scope === 'org'"
      >
        <div class="label-desc">Text color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidPrimaryTextColor(
              tagSettings.in_page_color_primary_text
            ),
          }"
          [(color)]="tagSettings.in_page_color_primary_text"
        ></color-picker>
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="
          tagSettings?.advanced &&
          scope === 'org' &&
          (tagSettings.in_page_identity_type === 'fixed' ||
            tagSettings.in_page_identity_type === 'screeby')
        "
      >
        <div class="label-desc">Header background color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidSecondaryColor(
              tagSettings.in_page_color_secondary
            ),
          }"
          (colorChange)="inPageSecondaryColorChange($event)"
          [(color)]="tagSettings.in_page_color_secondary"
        ></color-picker>
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="tagSettings?.advanced && scope === 'org'"
      >
        <div class="label-desc">Actions text color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidSecondaryTextColor(
              tagSettings.in_page_color_secondary_text
            ),
          }"
          [(color)]="tagSettings.in_page_color_secondary_text"
        ></color-picker>
      </div>
    </section>
  </div>

  <!-- InPage Font -->
  <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
    <hr />
    <div class="label">Font</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Family</div>
        <font-select
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.in_page_font_family"
        ></font-select>
      </div>

      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Size</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.in_page_font_size"
          [nzMin]="1"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidFontSize(tagSettings.in_page_font_size) ? undefined : 'error'
          "
        ></nz-input-number>
      </div>

      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Line height</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.in_page_font_line_height"
          [nzMin]="1"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidFontLineHeight(tagSettings.in_page_font_line_height)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>
    </section>
  </div>

  <!-- In Page Border -->
  <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
    <hr />
    <p class="label">Border</p>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzXl="12">
        <div class="label-desc">Border radius</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.in_page_border_radius"
          [nzMin]="0"
          [nzMax]="40"
          [nzStep]="1"
          [nzStatus]="
            isValidBorderRadius(tagSettings.in_page_border_radius)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>

      <div nz-col nzSpan="24" nzXl="12">
        <div class="label-desc">Border line</div>
        <div class="label-switch">
          <span
            (click)="
              tagSettings.in_page_border_line = !tagSettings.in_page_border_line
            "
          >
            Customize Border line
          </span>
          <nz-switch
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.in_page_border_line"
          ></nz-switch>
        </div>
      </div>

      <div nz-col nzXs="24" nzMd="12" *ngIf="tagSettings.in_page_border_line">
        <div class="label-desc">Border line width</div>
        <nz-input-number
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.in_page_border_line_width"
          [nzMin]="1"
          [nzMax]="20"
          [nzStep]="1"
          [nzStatus]="
            isValidBorderLineWidth(tagSettings.in_page_border_line_width)
              ? undefined
              : 'error'
          "
        ></nz-input-number>
      </div>

      <div nz-col nzXs="24" nzMd="12" *ngIf="tagSettings.in_page_border_line">
        <div class="label-desc">Border line color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidBorderLineColor(
              tagSettings.in_page_border_line_color
            ),
          }"
          [alpha]="true"
          [(color)]="tagSettings.in_page_border_line_color"
        ></color-picker>
      </div>
    </section>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzSpan="24" nzMd="12">
        <div class="label-desc">Border shadow</div>
        <div class="label-switch">
          <span
            (click)="
              tagSettings.in_page_border_shadow =
                !tagSettings.in_page_border_shadow
            "
          >
            Customize border shadow
          </span>
          <nz-switch
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.in_page_border_shadow"
          ></nz-switch>
        </div>
      </div>

      <div nz-col nzXs="24" nzMd="12" *ngIf="tagSettings.in_page_border_shadow">
        <div class="label-desc">Border shadow color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidBorderShadowColor(
              tagSettings.in_page_border_shadow_color
            ),
          }"
          [alpha]="true"
          [(color)]="tagSettings.in_page_border_shadow_color"
        ></color-picker>
      </div>
    </section>
  </div>

  <!-- In-page Overlay -->
  <div
    hidden
    nz-col
    nzSpan="24"
    *ngIf="tagSettings?.advanced && scope === 'org'"
  >
    <hr />
    <div class="label">Overlay</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">Visibility</div>
        <nz-select
          class="select-100"
          [nzOptions]="overlayVisibilityOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.in_page_overlay_visibility"
        ></nz-select>
      </div>

      <div nz-col nzXs="24" nzMd="12">
        <div class="abel-desc">Color</div>
        <color-picker
          [ngClass]="{
            invalid: !isValidOverlayColor(tagSettings.in_page_overlay_color),
          }"
          [alpha]="true"
          [(color)]="tagSettings.in_page_overlay_color"
        ></color-picker>
      </div>
    </section>
  </div>

  <!-- In page Display name, avatar and title -->
  <div nz-col nzSpan="24">
    <hr />
    <div class="label">Display name, avatar and title</div>
    <section nz-row [nzGutter]="[24, 8]">
      <div nz-col nzXs="24" nzMd="12">
        <div class="label-desc">From</div>
        <nz-select
          class="select-100"
          [nzOptions]="identityTypeOptionalOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [ngModel]="tagSettings.in_page_identity_type"
          (ngModelChange)="onIdentityTypeChange($event, 'in_page')"
        ></nz-select>
      </div>
      <div
        nz-col
        nzXs="24"
        nzMd="12"
        *ngIf="tagSettings.in_page_identity_type === 'fixed'"
      >
        <div class="label-desc">Avatar</div>
        <file-image-picker
          [canDelete]="false"
          [maxSize]="maxAvatarSize"
          [loading]="loadingAvatar"
          [resize]="{ minWidth: 512, minHeight: 512 }"
          [imageUrl]="tagSettings.in_page_identity_fixed_avatar"
          (imageFileChange)="imageAvatarFileChange($event, 'survey', 'in_page')"
          [error]="
            !tagSettings.in_page_identity_fixed_avatar?.length
              ? 'Required'
              : null
          "
        ></file-image-picker>
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="
          tagSettings.in_page_identity_type === 'fixed' ||
          tagSettings.in_page_identity_type === 'screeby'
        "
      >
        <div class="label-desc">Name</div>
        <input
          type="text"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.in_page_identity_fixed_name"
          nzSize="large"
          nz-input
          [minLength]="1"
          [maxLength]="56"
          placeholder="Sophia"
          [ngClass]="{
            invalid: !isValidName(tagSettings.in_page_identity_fixed_name),
          }"
        />
      </div>
      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="
          tagSettings.in_page_identity_type === 'fixed' ||
          tagSettings.in_page_identity_type === 'screeby'
        "
      >
        <div class="label-desc">Title or job</div>
        <input
          type="text"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.in_page_identity_fixed_title"
          nzSize="large"
          nz-input
          [minLength]="3"
          [maxLength]="128"
          placeholder="Founder"
          [ngClass]="{
            invalid: !isValidTitle(tagSettings.in_page_identity_fixed_title),
          }"
        />
      </div>

      <div
        nz-col
        nzXs="24"
        nzXl="12"
        *ngIf="
          tagSettings?.advanced &&
          scope === 'org' &&
          tagSettings.in_page_identity_type === 'fixed'
        "
      >
        <div class="label-desc">Avatar format</div>
        <nz-select
          class="select-100"
          [nzOptions]="avatarFormatOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.in_page_identity_avatar_format"
        ></nz-select>
      </div>
    </section>
  </div>
</section>

<ng-container *ngIf="tagSettings && typeSelected === 'message'">
  <p class="label">General</p>
  <section nz-row [nzGutter]="[32, 16]">
    <div nz-col nzXs="24" nzXl="12">
      <div class="label-desc">Pop sound</div>
      <div class="play-sound-wrapper">
        <nz-select
          class="select-100"
          [nzOptions]="popSoundsOptions"
          nzSize="large"
          ngDefaultControl
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tagSettings.messages_pop_sound"
        ></nz-select>

        <button
          nz-button
          [disabled]="
            tagSettings.messages_pop_sound === 'none' || !canPlayAudio
          "
          class="play-pop-sound"
          (click)="playSound(tagSettings.messages_pop_sound)"
        >
          <screeb-icon *ngIf="!soundPlaying" size="md" icon="play" />
          <screeb-icon *ngIf="soundPlaying" size="md" icon="pause" />
        </button>
      </div>
    </div>
    <div
      nz-col
      nzXs="24"
      nzXl="12"
      *ngIf="tagSettings?.advanced && scope === 'org'"
    >
      <div class="label-desc">Overall size</div>
      <nz-select
        class="select-100"
        [nzOptions]="sizeOptions"
        nzSize="large"
        ngDefaultControl
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="tagSettings.messages_size"
      ></nz-select>
    </div>
    <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
      <hr />
      <div class="label">Border</div>
      <section nz-row [nzGutter]="[24, 8]">
        <div nz-col nzXs="24" nzXl="12">
          <div class="label-desc">Border radius</div>
          <nz-input-number
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_border_radius"
            [nzMin]="0"
            [nzMax]="100"
            [nzStep]="1"
            [nzStatus]="
              isValidBorderRadius(tagSettings.messages_border_radius)
                ? undefined
                : 'error'
            "
          ></nz-input-number>
        </div>

        <div nz-col nzSpan="24" nzXl="12">
          <div class="label-desc">Border line</div>
          <div class="label-switch">
            <span
              (click)="
                tagSettings.messages_border_line =
                  !tagSettings.messages_border_line
              "
            >
              Customize border line
            </span>
            <nz-switch
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.messages_border_line"
            ></nz-switch>
          </div>
        </div>

        <div
          nz-col
          nzXs="24"
          nzMd="12"
          *ngIf="tagSettings.messages_border_line"
        >
          <div class="label-desc">Border line width</div>
          <nz-input-number
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_border_line_width"
            [nzMin]="1"
            [nzMax]="20"
            [nzStep]="1"
            [nzStatus]="
              isValidBorderLineWidth(tagSettings.messages_border_line_width)
                ? undefined
                : 'error'
            "
          ></nz-input-number>
        </div>

        <div
          nz-col
          nzXs="24"
          nzMd="12"
          *ngIf="tagSettings.messages_border_line"
        >
          <div class="label-desc">Border line color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidBorderLineColor(
                tagSettings.messages_border_line_color
              ),
            }"
            [alpha]="true"
            [(color)]="tagSettings.messages_border_line_color"
          ></color-picker>
        </div>
      </section>
      <section nz-row [nzGutter]="[24, 8]">
        <div nz-col nzSpan="24" nzMd="12">
          <div class="label-desc">Border shadow</div>
          <div class="label-switch">
            <span
              (click)="
                tagSettings.messages_border_shadow =
                  !tagSettings.messages_border_shadow
              "
            >
              Customize border shadow
            </span>
            <nz-switch
              ngDefaultControl
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="tagSettings.messages_border_shadow"
            ></nz-switch>
          </div>
        </div>

        <div
          nz-col
          nzXs="24"
          nzMd="12"
          *ngIf="tagSettings.messages_border_shadow"
        >
          <div class="label-desc">Border shadow color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidBorderShadowColor(
                tagSettings.messages_border_shadow_color
              ),
            }"
            [alpha]="true"
            [(color)]="tagSettings.messages_border_shadow_color"
          ></color-picker>
        </div>
      </section>
    </div>
    <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
      <hr />
      <div class="label">Overlay</div>
      <section nz-row [nzGutter]="[24, 8]">
        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Visibility</div>
          <nz-select
            class="select-100"
            [nzOptions]="messagesOverlayVisibilityOptions"
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_overlay_visibility"
          ></nz-select>
        </div>

        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidOverlayColor(tagSettings.messages_overlay_color),
            }"
            [alpha]="true"
            [(color)]="tagSettings.messages_overlay_color"
          ></color-picker>
        </div>
      </section>
    </div>
    <div nz-col nzSpan="24">
      <hr />
      <div class="label">Display name, avatar and title</div>
      <section nz-row [nzGutter]="[24, 8]">
        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">From</div>
          <nz-select
            class="select-100"
            [nzOptions]="identityTypeOptions"
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [ngModel]="tagSettings.messages_identity_type"
            (ngModelChange)="onIdentityTypeChange($event, 'message')"
          ></nz-select>
        </div>
        <div
          nz-col
          nzXs="24"
          nzMd="12"
          *ngIf="tagSettings.messages_identity_type === 'fixed'"
        >
          <div class="label-desc">Avatar</div>
          <file-image-picker
            [canDelete]="false"
            [maxSize]="maxAvatarSize"
            [loading]="loadingAvatar"
            [resize]="{ minWidth: 512, minHeight: 512 }"
            [imageUrl]="tagSettings.messages_identity_fixed_avatar"
            (imageFileChange)="imageAvatarFileChange($event, 'message')"
            [error]="
              !tagSettings.messages_identity_fixed_avatar?.length
                ? 'Required'
                : null
            "
          ></file-image-picker>
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings.messages_identity_type === 'fixed'"
        >
          <div class="label-desc">Displayed name</div>
          <input
            type="text"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_identity_fixed_name"
            nzSize="large"
            nz-input
            [minLength]="1"
            [maxLength]="56"
            placeholder="Sophia"
            [ngClass]="{
              invalid: !isValidName(tagSettings.messages_identity_fixed_name),
            }"
          />
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings.messages_identity_type === 'fixed'"
        >
          <div class="label-desc">Displayed title or job</div>
          <input
            type="text"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_identity_fixed_title"
            nzSize="large"
            nz-input
            [minLength]="3"
            [maxLength]="128"
            placeholder="Founder"
            [ngClass]="{
              invalid: !isValidTitle(tagSettings.messages_identity_fixed_title),
            }"
          />
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings?.advanced && scope === 'org'"
        >
          <div class="label-desc">Avatar format</div>
          <nz-select
            class="select-100"
            [nzOptions]="avatarFormatOptions"
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_identity_avatar_format"
          ></nz-select>
        </div>
      </section>
    </div>
  </section>

  <section nz-row [nzGutter]="[32, 16]">
    <div
      nz-col
      nzSpan="24"
      *ngIf="(scope !== 'org' && !tagSettings?.advanced) || scope === 'org'"
    >
      <hr />
      <div class="label">Colors</div>
      <section nz-row [nzGutter]="[24, 8]">
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="(scope !== 'org' && !tagSettings?.advanced) || scope === 'org'"
        >
          <div class="label-desc">Primary color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidPrimaryColor(tagSettings.messages_color_primary),
            }"
            (colorChange)="messagesPrimaryColorChange($event)"
            [(color)]="tagSettings.messages_color_primary"
          ></color-picker>
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="(scope !== 'org' && !tagSettings?.advanced) || scope === 'org'"
        >
          <div class="label-desc">Primary text color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidPrimaryTextColor(
                tagSettings.messages_color_primary_text
              ),
            }"
            [(color)]="tagSettings.messages_color_primary_text"
          ></color-picker>
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings?.advanced && scope === 'org'"
        >
          <div class="label-desc">Secondary color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidSecondaryColor(
                tagSettings.messages_color_secondary
              ),
            }"
            (colorChange)="messagesSecondaryColorChange($event)"
            [(color)]="tagSettings.messages_color_secondary"
          ></color-picker>
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings?.advanced && scope === 'org'"
        >
          <div class="label-desc">Secondary text color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidSecondaryTextColor(
                tagSettings.messages_color_secondary_text
              ),
            }"
            [(color)]="tagSettings.messages_color_secondary_text"
          ></color-picker>
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings?.advanced && scope === 'org'"
        >
          <div class="label-desc">Tertiary color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidSecondaryColor(
                tagSettings.messages_color_tertiary
              ),
            }"
            (colorChange)="messagesTertiaryColorChange($event)"
            [(color)]="tagSettings.messages_color_tertiary"
          ></color-picker>
        </div>
        <div
          nz-col
          nzXs="24"
          nzXl="12"
          *ngIf="tagSettings?.advanced && scope === 'org'"
        >
          <div class="label-desc">Tertiary text color</div>
          <color-picker
            [ngClass]="{
              invalid: !isValidTertiaryTextColor(
                tagSettings.messages_color_tertiary_text
              ),
            }"
            [(color)]="tagSettings.messages_color_tertiary_text"
          ></color-picker>
        </div>
      </section>
    </div>

    <div nz-col nzSpan="24" *ngIf="tagSettings?.advanced && scope === 'org'">
      <hr />
      <div class="label">Font</div>
      <section nz-row [nzGutter]="[24, 8]">
        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Family for titles</div>
          <font-select
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_title_font_family"
          ></font-select>
        </div>
        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Family for texts</div>
          <font-select
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_font_family"
          ></font-select>
        </div>

        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Size for titles</div>
          <nz-input-number
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_title_font_size"
            [nzMin]="1"
            [nzMax]="40"
            [nzStep]="1"
            [nzStatus]="
              isValidFontSize(tagSettings.messages_title_font_size)
                ? undefined
                : 'error'
            "
          ></nz-input-number>
        </div>
        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Size for texts</div>
          <nz-input-number
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_font_size"
            [nzMin]="1"
            [nzMax]="40"
            [nzStep]="1"
            [nzStatus]="
              isValidFontSize(tagSettings.messages_font_size)
                ? undefined
                : 'error'
            "
          ></nz-input-number>
        </div>

        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Weight for titles</div>
          <nz-input-number
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_title_font_weight"
            [nzMin]="100"
            [nzMax]="900"
            [nzStep]="100"
            [nzStatus]="
              isValidFontWeight(tagSettings.messages_title_font_weight)
                ? undefined
                : 'error'
            "
          ></nz-input-number>
        </div>
        <div nz-col nzXs="24" nzMd="12">
          <div class="label-desc">Line height for texts</div>
          <nz-input-number
            nzSize="large"
            ngDefaultControl
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tagSettings.messages_font_line_height"
            [nzMin]="1"
            [nzMax]="40"
            [nzStep]="1"
            [nzStatus]="
              isValidFontLineHeight(tagSettings.messages_font_line_height)
                ? undefined
                : 'error'
            "
          ></nz-input-number>
        </div>
      </section>
    </div>
  </section>
</ng-container>

<upgrade-modal
  [orgId]="org.id"
  [centeredModal]="true"
  [withOverlay]="true"
  (closeClick)="isUpgradeModalVisible = false"
  *ngIf="isUpgradeModalVisible"
></upgrade-modal>
