import { Injectable } from "@angular/core";

import { integrationSpecs, IntegrationType } from "models/integrations.model";
import { SessionService } from "services/auth.service";
import { UIService } from "services/ui.service";
import { EntitlementService } from "./entitlement.service";

@Injectable()
class FeatureFlaggingService {
  public disableFeatureFlags = false;

  constructor(
    private sessionService: SessionService,
    private uiService: UIService,
    private entitleService: EntitlementService,
  ) {
    this.disableFeatureFlags =
      window.localStorage.getItem(`x-screeb-feature-flags`) === "disabled";
  }

  public toggleFeatureFlags(): void {
    this.disableFeatureFlags = !this.disableFeatureFlags;
    window.localStorage.setItem(
      `x-screeb-feature-flags`,
      this.disableFeatureFlags ? "disabled" : "enabled",
    );
    window.location.reload();
  }

  private accountIsConnectAs(): boolean {
    return this.sessionService.isConnectedAs();
  }

  public accountIsScreebTeam(): boolean {
    if (!this.sessionService.session?.email) {
      return false;
    }
    // @TODO: this is not safe, since we do not check address ownership on signup
    if (this.sessionService.session?.email?.indexOf("@screeb.app") !== -1) {
      return true;
    }
    const whiltelist = [
      "michael.hirbec@gmail.com",
      "mikadublin@hotmail.com",
      "simon.robic@gmail.com",
    ];
    return whiltelist.includes(this.sessionService.session?.email);
  }

  private accountIsScreebTechTeam(): boolean {
    if (!this.accountIsScreebTeam()) {
      return false;
    }

    const techteam = [
      "martin",
      "clement",
      "samuel",
      "simon",
      "clement.chaban",
      "romain",
      "alexis",
    ].map((a) => `${a}@screeb.app`);
    return techteam.includes(
      this.sessionService.session?.email.replace(/\+[a-zA-Z0-9]+/gi, ""),
    );
  }

  public canCreateSuperOrg(): boolean {
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  public isContextualMessagesMobileEnabled(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  public isMFAEnabled(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.accountIsScreebTeam();
  }

  // funnels
  public isFunnelSummaryAvailable(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  // import surveys
  public isImportSurveysEnabled(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }

    if (
      this.uiService.currentOrgId === "819ef379-edc6-420d-b59f-eac6b129baf9" // Accor
    ) {
      return true;
    }
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  public isSaveToVariableEnabled(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  public isConditionalStepEnabled(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  public isOtherOptionEnabled(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  // theme
  public isThemeSwitchEnabled(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  // tracking
  public canBulkImportGroupAssignation(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  // stats
  public isProductMarketFitEnabled(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return false;
    // return this.accountIsScreebTechTeam();
  }

  // user
  public isDistributedMAUEnabled(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  public isDummyModeEnabled(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  public displayRuleTypeLeaveIntent(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return (
      this.sessionService.isConnectedAs() ||
      this.accountIsScreebTeam() ||
      [
        "ddb61c82-c118-402e-9020-d0bc573dda8b", // gov plus
      ].includes(this.uiService.currentOrgId)
    );
  }

  // settings
  public isDataGovernancePageEnabled(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.accountIsScreebTechTeam();
  }

  // integrations
  public displayIntegrationCard(type: IntegrationType): boolean {
    if (
      type === "typeform" &&
      [
        "5a295134-24db-437a-bfbc-41683eadb845", // miimosa
        "4510d8fb-4bbf-4da2-ab3e-c5041b0a08e6", // javelo
      ].includes(this.uiService.currentOrgId)
    ) {
      return true;
    }

    if (integrationSpecs[type].visibleInMarketplace) {
      return true;
    }

    if (this.disableFeatureFlags) {
      return false;
    }

    return this.sessionService.isConnectedAs() || this.accountIsScreebTeam();
  }

  public isIntegrationZapierAvailable(): boolean {
    return this.displayIntegrationCard("zapier");
  }

  public isNotionReplayButtonVisible(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.sessionService.isConnectedAs() || this.accountIsScreebTeam();
  }

  public isAtlassianReplayButtonVisible(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.sessionService.isConnectedAs() || this.accountIsScreebTeam();
  }

  // billing
  public displayStripeShortcut(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  // content analysis
  public hideOpenAIContentAnalysis(): boolean {
    if (this.disableFeatureFlags) {
      return false;
    }
    return (
      // opendatasoft - https://screeb.slack.com/archives/DMCJJBWUB/p1677773532209509
      this.uiService.currentOrgId === "a65a7002-1b81-4281-935f-b1086f2af0ff"
    );
  }

  public isForceDeviceTrackingEnabled(): boolean {
    return this.accountIsScreebTeam() || this.accountIsConnectAs();
  }

  public isNotDeviceTracking(): boolean {
    return (
      !this.uiService.currentOrg.settings.device_tracking_forced ||
      !this.entitleService.isAvailable("device_tracking")
    );
  }
}

export { FeatureFlaggingService };
