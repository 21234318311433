import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";

import { HttpErrorResponse } from "@angular/common/http";
import { PageComponentInterface } from "components/PageComponentInterface";
import {
  Post101,
  authors,
} from "components/utils/card-101-link/card-101-link.model";
import { startOfDay, startOfMonth } from "date-fns";
import { AnalyticsDao } from "models/analytics.dao";
import {
  AnalyticsQueryUsers,
  SortForUser,
  SortOrder,
} from "models/analytics.filters.type";
import {
  AnalyticsResponse,
  AnalyticsResponseItemUser,
  getFormattedUserNameOrID,
} from "models/analytics.model";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { getUserIcon } from "models/user.model";
import {
  NzTableQueryParams,
  NzTableSortFn,
  NzTableSortOrder,
  NzTableComponent,
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzThAddOnComponent,
  NzCellAlignDirective,
  NzCellFixedDirective,
  NzTbodyComponent,
  NzCellEllipsisDirective,
} from "ng-zorro-antd/table";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { Debounce } from "utils/debounce";
import { deepCopy } from "utils/object";
import { FreeTrialBannerComponent } from "../../super-org/billing/banners/free-trial-banner.component";
import { FiltersStatsSurveyComponent } from "../../surveys/pages/stats/filters/filters.component";
import { NgIf, NgFor } from "@angular/common";
import { ErrorMessageComponent } from "../../utils/error-message/error-message.component";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { SingleIndicatorStatsSurveyComponent } from "../../surveys/pages/stats/indicators/components/single-indicator/single-indicator.component";
import { FiltersSaveSegmentComponent } from "../../surveys/pages/stats/filters/save-segment/save-segment.component";
import { FiltersCSVExportComponent } from "../../surveys/pages/stats/filters/csv-export/csv-export.component";
import { FormsModule } from "@angular/forms";
import { NzFormDirective } from "ng-zorro-antd/form";
import {
  NzInputGroupComponent,
  NzInputGroupWhitSuffixOrPrefixDirective,
  NzInputDirective,
} from "ng-zorro-antd/input";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import { NzEmptyComponent } from "ng-zorro-antd/empty";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { FormatDistanceToNowPipeModule } from "ngx-date-fns";
import { PermissionPipe } from "pipes/permission.pipe";

interface ColumnItem {
  name: string;
  sortKey?: string;
  width?: string;
  align: "left" | "right" | "center";
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null | true;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: "user-list-page",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.scss"],
  imports: [
    FreeTrialBannerComponent,
    FiltersStatsSurveyComponent,
    NgIf,
    ErrorMessageComponent,
    NzRowDirective,
    NzColDirective,
    SingleIndicatorStatsSurveyComponent,
    FiltersSaveSegmentComponent,
    FiltersCSVExportComponent,
    FormsModule,
    NzFormDirective,
    NzInputGroupComponent,
    ɵNzTransitionPatchDirective,
    NzInputGroupWhitSuffixOrPrefixDirective,
    NzInputDirective,
    NzIconDirective,
    NzSpinComponent,
    NzEmptyComponent,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NgFor,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzThAddOnComponent,
    NzCellAlignDirective,
    NzCellFixedDirective,
    NzButtonComponent,
    RouterLink,
    NzTbodyComponent,
    NzCellEllipsisDirective,
    NzTagComponent,
    NzPopoverDirective,
    FormatDistanceToNowPipeModule,
    PermissionPipe,
  ],
})
export class UserListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Users";
  public name = "Users";

  private obs: any = null;

  public org: Org;
  public registryEntriesGroup: RegistryEntry[] = [];

  protected filtersObs$: any = null;
  public lastFilters: AnalyticsQueryUsers;

  public searching = false;
  public listLoading = false;
  public dayLoading = false;
  public monthLoading = false;
  public error: Error;
  public pageSize = 10;
  public pageIndex = 1;
  public total = 0;
  public maxOffset = 0;
  public dailyActive = 0;
  public monthlyActive = 0;
  public users: AnalyticsResponseItemUser[] = [];

  private sort?: SortForUser = {
    field: "respondent.last_activity_at",
    order: "asc",
  };

  listOfColumns: ColumnItem[] = [
    {
      name: "",
      width: "90px",
      align: "center",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: true,
    },
    {
      name: "Name or ID",
      width: "300px",
      align: "left",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: true,
    },
    {
      name: "First Seen",
      width: "150px",
      sortKey: "respondent.created_at",
      align: "center",
      sortOrder: "",
      sortFn: true,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Last Activity",
      width: "150px",
      sortKey: "respondent.last_activity_at",
      align: "center",
      sortOrder: "ascend",
      sortFn: true,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
  ];

  public posts101: Post101[] = [
    {
      color: "var(--screeb-color-purple-200)",
      title: "How to track user properties in Screeb with our Javascript SDK",
      url: "https://help.screeb.app/en/articles/8611571-all-about-users-properties",
      author: authors["Simon Robic"],
    },
    {
      color: "var(--screeb-color-info-outline)",
      title: "How to use our Segment integration to better identify your users",
      url: "https://help.screeb.app/en/articles/5299671-segment-integration-the-complete-guide",
      author: authors["Simon Robic"],
    },
    {
      color: "#FFF2D6",
      title:
        "How to use our Users report to better understand how your users are using your app (Video)",
      url: "https://help.screeb.app/en/articles/5658565-how-to-use-our-users-report-to-better-understand-how-your-users-are-using-your-app",
      author: authors["Simon Robic"],
    },
  ];

  public getUserIcon = getUserIcon;
  public getFormattedUserNameOrID = getFormattedUserNameOrID;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private analyticsDao: AnalyticsDao,
    public analyticsFilterService: AnalyticsFilterService,
    private trackersService: TrackersService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );
    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.registryEntriesGroup = data.registryEntriesGroup.groups;
      this.resetFilters();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }

    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  private resetFilters() {
    this.analyticsFilterService.reset(
      "respondent",
      this.org.id,
      [],
      this.org.created_at,
      this.org.created_at,
    );

    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQueryUsers) => {
        this.lastFilters = deepCopy(filters);

        this.getUsers();
      });
  }

  public trackingCTAVisible(): boolean {
    if (this.org.stats.total_respondents > 0) return false;
    if (this.users.length > 0) return false;
    return true;
  }

  private async getUsers() {
    if (this.listLoading) return;

    this.listLoading = true;
    this.error = null;

    if (this.pageIndex === 1 || this.dailyActive === 0) {
      this.dayLoading = true;
    }

    if (this.pageIndex === 1 || this.monthlyActive === 0) {
      this.monthLoading = true;
    }

    /**
     * Query 1: total number of users and list of users
     */
    const query = deepCopy(this.lastFilters) as AnalyticsQueryUsers;
    query.size = this.pageSize;
    query.offset = (this.pageIndex - 1) * this.pageSize;
    query.sort = deepCopy(this.sort);

    // reverse order when sorting by date, because we display "3 days ago" and not "2024-01-01"
    if (
      this.sort.field === "respondent.created_at" ||
      this.sort.field === "respondent.last_activity_at"
    ) {
      query.sort.order = this.sort.order === "asc" ? "desc" : "asc";
    }

    // UX: we want to display the list as soon as possible
    // lazy loading day/month active users and avoiding concurrent requests
    await this.analyticsDao
      .search(query)
      .then((res: AnalyticsResponse) => {
        this.total = res?.hits?.total;
        this.maxOffset = Math.min(res?.hits?.total || 0, 10000);
        this.users = res?.hits?.respondents || [];
      })
      .catch((err: HttpErrorResponse) => {
        this.error = err;
        console.error(err);
      })
      .finally(() => {
        this.listLoading = false;
        this.searching = false;
      });

    /**
     * Query 2: number of users for the last 24 hours
     */
    if (this.pageIndex === 1 || this.dailyActive === 0) {
      this.dayLoading = true;
      const lastDayQuery = deepCopy(query);
      lastDayQuery.range.start = startOfDay(new Date());
      lastDayQuery.range.end = new Date();
      lastDayQuery.offset = 0;
      lastDayQuery.size = 0;
      lastDayQuery.sort = null;

      await this.analyticsDao
        .search(lastDayQuery)
        .then((res: AnalyticsResponse) => {
          this.dailyActive = res?.hits?.total || 0;
        })
        .catch((err: HttpErrorResponse) => {
          console.error(err);
        })
        .finally(() => {
          this.dayLoading = false;
        });
    }

    /**
     * Query 3: number of users for the current month
     */
    if (this.pageIndex === 1 || this.monthlyActive === 0) {
      this.monthLoading = true;
      const lastMonthQuery = deepCopy(query);
      lastMonthQuery.range.start = startOfMonth(new Date());
      lastMonthQuery.range.end = new Date();
      lastMonthQuery.offset = 0;
      lastMonthQuery.size = 0;
      lastMonthQuery.sort = null;

      await this.analyticsDao
        .search(lastMonthQuery)
        .then((res: AnalyticsResponse) => {
          this.monthlyActive = res?.hits?.total || 0;
        })
        .catch((err: HttpErrorResponse) => {
          console.error(err);
        })
        .finally(() => {
          this.monthLoading = false;
        });
    }
  }

  private adaptSortDirection(value: string): SortOrder {
    switch (value) {
      case "descend":
        return "desc";
      case "ascend":
      default:
        return "asc";
    }
  }

  public onQueryParamsChange(params: NzTableQueryParams) {
    let { pageIndex } = params;
    const { pageSize } = params;
    const { key, value } = params.sort.find(({ value }) => Boolean(value));

    const sort = {
      field: key as "respondent.last_activity_at" | "respondent.created_at",
      order: this.adaptSortDirection(value),
    };

    if (pageSize !== this.pageSize) {
      pageIndex = 1;
    }

    const pageChange =
      pageIndex !== this.pageIndex || pageSize !== this.pageSize;
    const sortChange =
      sort.field !== this.sort.field || sort.order !== this.sort.order;

    if (!pageChange && !sortChange) return;

    this.sort = sort;

    this.pageSize = pageSize;
    this.pageIndex = pageIndex;

    this.getUsers();
  }

  public onRowClick() {
    this.trackersService
      .newEventTrackingBuilder("Respondent list row clicked")
      .withOrg(this.org)
      .withAnalyticsFilters(this.lastFilters.filters, this.lastFilters.type)
      .build();

    // this.router.navigateByUrl(`/org/${this.org.id}/people/respondent/${userId}`);
  }

  /**
   * Search
   */
  @Debounce(400)
  public onSearchChange(keyword: string) {
    // this.getUsers();
    // this.analyticsFilterService.setKeyword(keyword.trim());
    this.onSearchChangeImmediate(keyword);
  }

  // without debounce
  public onSearchChangeImmediate(keyword: string) {
    this.analyticsFilterService.setKeyword(keyword.trim());
    this.searching = true;

    this.getUsers();

    this.trackersService
      .newEventTrackingBuilder("Respondent keyword searched")
      .withOrg(this.org)
      .withProps({
        keyword: keyword.trim(),
      })
      .build();
  }
}
