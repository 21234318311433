import { Component, Input, TemplateRef } from "@angular/core";
import {
    AbstractControl,
    FormsModule,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
} from "@angular/forms";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzSwitchComponent } from "ng-zorro-antd/switch";

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SwitchComponent,
      multi: true,
    },
  ],
  selector: "switch",
  template: `
    @let checkedTpl = checkedTemplate || defaultCheckedTemplate;
    @let unCheckedTpl = unCheckedTemplate || defaultUnCheckedTemplate;
    <nz-switch
      [ngModel]="model"
      [nzCheckedChildren]="checkedTpl"
      [nzUnCheckedChildren]="unCheckedTpl"
      (ngModelChange)="onValueChange($event)"
      [disabled]="disabled"
      [nzLoading]="loading"
    ></nz-switch>
    <ng-template #defaultCheckedTemplate>
      <span nz-icon nzType="check"></span>
    </ng-template>
    <ng-template #defaultUnCheckedTemplate>
      <span nz-icon nzType="close"></span>
    </ng-template>
  `,
  styleUrls: ["./switch.component.scss"],
  imports: [NzSwitchComponent, NzIconDirective, FormsModule],
})
export class SwitchComponent {
  @Input() public model: boolean = false;
  @Input() public loading: boolean = false;

  @Input() public checkedTemplate: TemplateRef<any> | string = null;
  @Input() public unCheckedTemplate: TemplateRef<any> | string = null;

  onChange = (_: boolean) => {};
  onTouched = () => {};
  touched = false;

  disabled = false;

  constructor() {}

  public onValueChange(value: boolean): void {
    this.onChange(value);
  }

  validate(_: AbstractControl): ValidationErrors | null {
    return null;
  }

  writeValue(value: boolean) {
    this.model = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
