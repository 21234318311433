import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { ChannelType, ChannelTypeFormatted } from "models/channel.model";

import {
  DistributionInteraction,
  DistributionInteractions,
  DistributionInteractionsDescription,
  SurveyDistribution,
} from "models/survey-distribution.model";
import { Survey } from "models/survey.model";
import { UIService } from "services/ui.service";
import { NgIf, NgFor } from "@angular/common";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { TrackByPropertyDirective } from "../../../../helpers/track-by-property";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { FeaturePipe } from "pipes/feature.pipe";

@Component({
  selector: "page-survey-share-redirect",
  templateUrl: "./share-redirect.component.html",
  styleUrls: ["./share-redirect.component.scss"],
  imports: [
    NgIf,
    ScreebIconComponent,
    NgFor,
    NzTooltipDirective,
    RouterLink,
    TrackByPropertyDirective,
    NzButtonComponent,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    FeaturePipe,
  ],
})
export class ShareRedirectSurveyPageComponent implements OnInit, OnDestroy {
  private obs: any = null;

  public orgId: string = null;
  private surveyId: string = null;
  public survey: Survey = null;
  public distributions: SurveyDistribution[] = [];
  public legacyDistributions: SurveyDistribution[] = [];

  public loaded = false;
  public isEditMessage = false;

  public channelTypeFormatted = ChannelTypeFormatted;
  public distributionInteractions = DistributionInteractions;
  public distributionInteractionsDescription =
    DistributionInteractionsDescription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public uiService: UIService,
  ) {}

  public ngOnInit() {
    this.obs = this.route.data.subscribe((data) => {
      this.survey = data["survey"];

      this.orgId = this.route.snapshot.params["org_id"];
      this.surveyId = this.route.snapshot.params["survey_id"];
      const distributions = this.uiService.currentSurveyDistributions;
      this.distributions = distributions?.filter(
        (d: SurveyDistribution) => !d.type,
      );
      this.legacyDistributions = distributions?.filter(
        (d: SurveyDistribution) => d.type,
      );

      // Check if a distribution have been edited
      const noDistributionsEditedSoFar = distributions?.every(
        (distribution) => {
          if (
            Number(distribution.updated_at) !== Number(distribution.created_at)
          ) {
            return false;
          }

          return distribution.targeting_rules?.every(
            (targeting_rule) =>
              Number(targeting_rule.created_at) ===
              Number(distribution.created_at),
          );
        },
      );

      if (noDistributionsEditedSoFar || !distributions?.length) {
        this.loaded = true;
        return;
      }

      // Find the first enabled distribution
      let distrib = distributions.find((d: SurveyDistribution) => d.enabled);

      // if no survey is enabled, redirect to last edited distribution
      if (!distrib) {
        const sortedDistribs = [...distributions].sort(
          (a, b) => Number(b.updated_at) - Number(a.updated_at),
        );
        distrib = sortedDistribs[0];
      }

      const isMessagePage = this.router.isActive(
        `/org/${this.orgId}/message/${this.surveyId}/edit`,
        false,
      );

      if (isMessagePage) {
        if (distrib?.targeting_rules?.find((r) => r.type === "url")) {
          this.router.navigate(
            [
              "org",
              this.orgId,
              "message",
              this.surveyId,
              "edit",
              distrib.channel_id,
              distrib.type,
            ],
            {
              replaceUrl: true,
            },
          );
        } else {
          this.loaded = true;
        }
        return;
      }

      this.router.navigate(
        [
          `/org/${this.orgId}/${this.uiService.surveyTypePath}/${this.surveyId}/share/${distrib.id}`,
        ],
        {
          replaceUrl: true,
        },
      );
    });
  }

  public ngOnDestroy() {
    if (this.obs) this.obs.unsubscribe();
  }

  public getByInteraction(
    interaction: DistributionInteraction,
  ): SurveyDistribution | undefined {
    return this.distributions.find(
      (d: SurveyDistribution) => d.interaction === interaction,
    );
  }

  public getTitle(interaction: DistributionInteraction) {
    switch (interaction) {
      case "in_app":
        return "Proactive In-App Survey";
      case "in_page":
        return "In-Page Embedded Survey";
      case "feedback_button":
        return "Feedback Button";
      case "email":
        return "Email Survey";
      case "link":
        return "Link Survey";
    }
  }

  public getImage(
    interaction: DistributionInteraction,
    active: boolean = false,
  ) {
    switch (interaction) {
      case "in_app":
        return `/assets/survey-interactions/proactive-${active ? "activated" : "desactivated"}.png`;
      case "in_page":
        return `/assets/survey-interactions/in-page-survey-${active ? "activated" : "desactivated"}.png`;
      case "feedback_button":
        return `/assets/survey-interactions/feedback-button-${active ? "activated" : "desactivated"}.png`;
      case "email":
        return `/assets/survey-interactions/email-survey-${active ? "activated" : "desactivated"}.png`;
      case "link":
        return `/assets/survey-interactions/link-survey-${active ? "activated" : "desactivated"}.png`;
    }
  }

  public getLink(channelType: ChannelType) {
    const distribution = this.uiService.currentSurveyDistributions?.find(
      ({ type }) => type === channelType,
    );
    if (!distribution) return null;

    if (
      this.router.isActive(
        `/org/${this.orgId}/message/${this.surveyId}/edit`,
        false,
      )
    ) {
      return `/org/${this.orgId}/message/${this.surveyId}/edit/${distribution.channel_id}/${distribution.type}`;
    }
    return `/org/${this.orgId}/${this.uiService.surveyTypePath}/${this.surveyId}/share/${distribution?.id}`;
  }
}
