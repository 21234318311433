import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpProgressEvent,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UploadDao } from "models/upload.dao";
import {
  MediaUploadCanceler,
  MediaUploader,
  PreSignedUrlResult,
  QuestionVideoUpload,
  UploadData,
  UploadResult,
  UploadType,
  UploadTypesData,
} from "models/upload.types";
import { Subject, combineLatestWith, scan, share } from "rxjs";
import { map } from "rxjs/operators";

export const VideoMIMETypeToExt = {
  "video/mp4": "mp4",
  "video/webm": "webm",
  "video/quicktime": "mov",
  "video/x-msvideo": "avi",
  "video/x-ms-wmv": "wmv",
  "video/x-flv": "flv",
};

export interface ETag {
  id: number;
  value: string;
}

export interface UploadState {
  id?: number;
  progress: number;
  state: "PENDING" | "IN_PROGRESS" | "DONE" | "ERROR";
}

export type IdentifiedUploadResult = UploadResult & {
  id: string;
};

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(
  event: HttpEvent<unknown>,
): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress ||
    event.type === HttpEventType.UploadProgress
  );
}

@Injectable()
class MediaUploadService {
  private onStart$: Subject<string>;
  private onProgress$: Subject<UploadState>;
  private onComplete$: Subject<IdentifiedUploadResult>;

  // Store uploadIdq of current Uploads
  private currentUploads: UploadData[] = [];

  constructor(
    private uploadDao: UploadDao,
    private httpClient: HttpClient,
  ) {
    this.onStart$ = new Subject<string>();
    this.onProgress$ = new Subject<UploadState>();
    this.onComplete$ = new Subject<IdentifiedUploadResult>();
  }

  subscribeStart() {
    return this.onStart$.asObservable().pipe(
      map((data) => data),
      share(),
    );
  }

  subscribeProgress() {
    return this.onProgress$.asObservable().pipe(
      map((data) => data),
      share(),
    );
  }

  subscribeComplete() {
    return this.onComplete$.asObservable().pipe(
      map((data) => data),
      share(),
    );
  }

  private completeMultipartUpload<T extends UploadType>(
    uploadId: string,
    eTags: ETag[],
    type: T,
    data: UploadTypesData<T>,
    filename: string,
    instanceId: string,
  ) {
    switch (type) {
      case "survey_question":
        this.uploadDao
          .confirmSurveyFileMultipartUpload(
            data.orgId,
            (data as QuestionVideoUpload).surveyId,
            filename,
            uploadId,
            eTags,
          )
          .then((res) => {
            this.onComplete$.next({
              public_url: res.public_url,
              filename: res.filename,
              video_id: res.video_id,
              id: instanceId,
            });
          });
        return;
      default:
        return;
    }
  }

  private uploadMedia(
    file: File,
    instanceId: string,
    preSignedUrls: string[],
    uploadId: string,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.onStart$.next(uploadId);
      const blobs: Blob[] = [];
      const chunkSize = file.size / preSignedUrls.length;
      const eTags: ETag[] = [];
      const updateUploadState = (
        upload: UploadState,
        event: HttpEvent<unknown>,
      ): UploadState => {
        if (isHttpProgressEvent(event)) {
          return {
            ...upload,
            progress: event.total
              ? Math.round((100 * event.loaded) / event.total)
              : upload.progress,
            state: "IN_PROGRESS",
          };
        }
        if (isHttpResponse(event)) {
          // If we get a HTTP Response, it means the upload is complete
          // We need to get the ETag generated to identify the uploaded part
          if (event.status !== 200) {
            reject(event);
            return {
              ...upload,
              progress: 0,
              state: "ERROR",
            };
          }
          eTags.push({
            id: upload.id,
            value: JSON.parse(event.headers.get("etag")),
          });
          return {
            ...upload,
            progress: 100,
            state: "DONE",
          };
        }
        return upload;
      };

      // Split file into chunks of size CHUNK_SIZE
      for (let start = 0; start < file.size; start += chunkSize) {
        const chunk = file.slice(start, start + chunkSize);

        blobs.push(chunk);
      }

      // Parallel upload of each file part to each presigned url.
      // We also listen for upload events to follow progress
      const reqObs = preSignedUrls.map((preSignedUrl, idx) =>
        this.httpClient.put<void>(preSignedUrl, blobs[idx], {
          reportProgress: true,
          observe: "events",
          headers: new HttpHeaders({
            "Content-Type": file.type,
          }),
        }),
      );

      // Map upload events into more readable Objects
      // We need to keep track of the order of file part upload,
      // so AWS S3 can group all part in the right order to recreate the file
      const o = reqObs.map((o, idx) => {
        const initialState: UploadState = {
          id: idx + 1,
          state: "PENDING",
          progress: 0,
        };

        return o.pipe(scan(updateUploadState.bind(this), initialState));
      });

      // Combine all uploadStates into one to keep track of all upload into one progress bar
      const combinedStates = o.reduce((prev, curr) => {
        return prev.pipe(
          combineLatestWith(curr),
          map(([progress, status]) => {
            if (progress.state === "ERROR") {
              return progress;
            }
            return {
              ...progress,
              progress: progress.progress + status.progress,
              state:
                progress.state !== "DONE" || status.state === "IN_PROGRESS"
                  ? "IN_PROGRESS"
                  : "DONE",
            };
          }),
        );
      });

      // Update UI for each update on one of the uploads
      const subscription = combinedStates.subscribe((uploadState) => {
        if (
          this.currentUploads.find((upload) => upload.uploadId === uploadId) ===
            undefined ||
          uploadState.state === "ERROR"
        ) {
          this.onComplete$.next(null);
          subscription.unsubscribe();
          return;
        }

        uploadState.progress = Math.round(
          uploadState.progress / preSignedUrls.length,
        );
        this.onProgress$.next(uploadState);

        // If upload is Fully Completed, submit summary to Server to Complete Multipart Upload
        if (uploadState.state === "DONE") {
          const data = this.currentUploads.find(
            (upload) => upload.uploadId === uploadId,
          );

          if (!data) {
            throw new Error(
              "Failed to complete Upload. UploadID not recognized.",
            );
          }
          this.completeMultipartUpload(
            uploadId,
            eTags,
            data.type,
            data.data,
            data.filename,
            instanceId,
          );
          resolve();
          subscription.unsubscribe();
          this.currentUploads = this.currentUploads.filter(
            (up) => up.uploadId !== uploadId,
          );
        }
      });
    });
  }

  /**
   * @description Upload file using Multipart Process
   * @param instanceId used to identify upload update
   * @param orgId Org ID
   * @param file media to Upload
   * @param type type of upload
   * @param data data required for upload
   */
  uploadMultipart: MediaUploader = (
    type,
    data,
    file,
    instanceId,
  ): Promise<void> => {
    return new Promise((resolve) => {
      switch (type) {
        case "survey_question":
          this.uploadDao
            .getSurveyFilePreSignedUrl(
              data.orgId,
              (data as QuestionVideoUpload).surveyId,
              file.type,
              file.size,
              file.name,
              type,
            )
            .then(resolve);
      }
    }).then((preSignedResult: PreSignedUrlResult) => {
      this.currentUploads.push({
        uploadId: preSignedResult.upload_id,
        filename: preSignedResult.file_name,
        type: type,
        data: data,
      });
      return this.uploadMedia(
        file,
        instanceId,
        preSignedResult.public_urls,
        preSignedResult.upload_id,
      );
    });
  };

  cancelUpload: MediaUploadCanceler = (type, data, uploadId: string) => {
    const upload = this.currentUploads.find(
      (upload) => upload.uploadId === uploadId,
    );

    if (!upload) {
      return;
    }

    new Promise((resolve) => {
      switch (type) {
        case "survey_question":
          this.uploadDao
            .abortSurveyFileMultipartUpload(
              data.orgId,
              (data as QuestionVideoUpload).surveyId,
              upload.filename,
              uploadId,
            )
            .then(resolve);
      }
    }).then(() => {
      this.currentUploads = this.currentUploads.filter(
        (up) => up.uploadId !== uploadId,
      );
    });
  };
}

export { MediaUploadService };
