import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IntegrationAtlassianProject } from "models/integration.dao";
import { Integration } from "models/integrations.model";
import { PermissionsService } from "services/permissions.service";
import { UIService } from "services/ui.service";
import { NzModalComponent } from "ng-zorro-antd/modal";
import { NgIf, NgFor } from "@angular/common";
import { RouterLink } from "@angular/router";
import { NzSelectComponent, NzOptionComponent } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { PermissionPipe } from "pipes/permission.pipe";

@Component({
  selector: "forward-atlassian-modal",
  templateUrl: "./forward-atlassian-modal.component.html",
  styleUrls: ["./forward-atlassian-modal.component.scss"],
  imports: [
    NzModalComponent,
    NgIf,
    RouterLink,
    NzSelectComponent,
    FormsModule,
    NgFor,
    NzOptionComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    PermissionPipe,
  ],
})
export class ForwardAtlassianModalComponent {
  @Input() isOpen: boolean = false;
  @Input() integration: Integration;
  @Input() projects: IntegrationAtlassianProject[] = [];
  @Input() projectId: string = null;
  @Output() projectChange: EventEmitter<string> = new EventEmitter();
  @Output() sendTo: EventEmitter<void> = new EventEmitter();
  @Output() modalClose: EventEmitter<void> = new EventEmitter();

  constructor(
    public permissionsService: PermissionsService,
    public uiService: UIService,
  ) {}

  handleCancel(): void {
    this.isOpen = false;
    this.modalClose.emit();
  }

  onProjectChange(projectId: string) {
    this.projectId = projectId;
    this.projectChange.emit(projectId);
  }

  public send() {
    if (!this.projectId) {
      return;
    }
    this.sendTo.emit();
    this.handleCancel();
  }
}
