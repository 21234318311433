import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CheckboxSelectItem } from "components/utils/checkbox-select/checkbox-select.component";
import { RegistryEntry, sortRegistryEntries } from "models/registry.model";
import { UUID } from "models/survey.dao.types";
import { CheckboxSelectComponent } from "../../../utils/checkbox-select/checkbox-select.component";
import { NgIf } from "@angular/common";
import { TagsComponent } from "../../../utils/tags/tags.component";

export const adaptUserEventsToCheckboxSelectItems = (
  registryEntriesEvent: RegistryEntry[],
): CheckboxSelectItem[] =>
  registryEntriesEvent.map((registryEntryEvent) => ({
    key: registryEntryEvent.id,
    label:
      (registryEntryEvent.favorite ? "⭐️ " : "") + registryEntryEvent.slug,
  }));

@Component({
  selector: "funnel-add-event-popover",
  template: `
    <h3>Add event(s)</h3>
    <checkbox-select
      [items]="availableEvents"
      [selectedItems]="value"
      (selectedItemsChange)="valueChange.emit($event)"
      placeholder="Search events by name"
    ></checkbox-select>

    <div class="events-tags-container">
      <div class="events-tags-label">
        <span *ngIf="!value.length">No event selected</span>
        <span *ngIf="value.length">{{ value.length }} event(s) selected</span>
      </div>
      <tags [maxTagsCount]="5" [tags]="getSelectedEventsLabels()"></tags>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        padding: 12px;
        width: 600px;
      }

      :host h2 {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
        margin-bottom: 8px;
      }

      .events-tags-container {
        display: flex;
        align-items: flex-start;
      }

      .events-tags-label {
        min-width: max-content;
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: var(--screeb-color-body-text-secondary);
        margin-right: 16px;
      }
    `,
  ],
  imports: [CheckboxSelectComponent, NgIf, TagsComponent],
})
export class FunnelAddEventPopoverComponent implements OnInit {
  @Input() value: UUID[] = [];
  @Input() public registryEntriesEvent: RegistryEntry[] = [];

  @Output() valueChange = new EventEmitter<UUID[]>();

  public availableEvents: CheckboxSelectItem[] = [];

  getSelectedEventsLabels() {
    return this.value
      .map((id) => this.availableEvents.find(({ key }) => key === id)?.label)
      .filter(Boolean);
  }
  ngOnInit(): void {
    this.availableEvents = adaptUserEventsToCheckboxSelectItems(
      this.registryEntriesEvent.sort(sortRegistryEntries),
    );
  }
}
