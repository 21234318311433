import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { ChartDataset } from "chart.js";

import { PageComponentInterface } from "components/PageComponentInterface";
import {
  bucketToDatedAggregation,
  computeVariation,
  getDateAnswerValueBuckets,
  getIndustryAverageScore,
  SimpleBucket,
} from "components/surveys/pages/stats/indicators/indicator.utils";
import {
  bucketToNpsAggregation,
  computeNpsPeriod,
  NpsAggregation,
} from "components/surveys/pages/stats/indicators/nps/nps.aggregation";
import {
  differenceInDays,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMinutes,
  subMonths,
  subWeeks,
} from "date-fns";
import { Account } from "models/account.model";
import { AnalyticsDao } from "models/analytics.dao";
import {
  AnalyticsQueryResponse,
  AnalyticsQueryUsers,
} from "models/analytics.filters.type";
import { UUID } from "models/survey.dao.types";
import { IndustriesScores } from "resolvers/asset-industries-scores";
import { SessionService } from "services/auth.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { formatNumber } from "utils/number";

import { NgClass, NgFor, NgIf } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { RANDOM_MSGS } from "components/home/widgets/quote-of-the-day/quote-of-the-day.component";
import { NotificationHelper } from "helpers/notification.helper";
import { Org } from "models/org.model";
import { SuperOrgDao } from "models/super-org.dao";
import { SuperOrg } from "models/super-org.model";
import { SurveyDao } from "models/survey.dao";
import { Survey } from "models/survey.model";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzCheckboxComponent } from "ng-zorro-antd/checkbox";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import {
  NzDropDownDirective,
  NzDropdownMenuComponent,
} from "ng-zorro-antd/dropdown";
import { NzFormDirective } from "ng-zorro-antd/form";
import { NzColDirective, NzRowDirective } from "ng-zorro-antd/grid";
import { NzIconDirective } from "ng-zorro-antd/icon";
import {
  NzInputDirective,
  NzInputGroupComponent,
  NzInputGroupWhitSuffixOrPrefixDirective,
} from "ng-zorro-antd/input";
import { NzMenuDirective, NzMenuItemComponent } from "ng-zorro-antd/menu";
import { NzModalService } from "ng-zorro-antd/modal";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { NzProgressComponent } from "ng-zorro-antd/progress";
import { NzOptionComponent, NzSelectComponent } from "ng-zorro-antd/select";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import {
  NzCellAlignDirective,
  NzCellFixedDirective,
  NzTableCellDirective,
  NzTableComponent,
  NzTableSortFn,
  NzTableSortOrder,
  NzTbodyComponent,
  NzThAddOnComponent,
  NzTheadComponent,
  NzThMeasureDirective,
  NzTrDirective,
} from "ng-zorro-antd/table";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { ScreebFormatPipe } from "pipes/format.pipe";
import { SuperPermissionPipe } from "pipes/super-permission.pipe";
import { UIService } from "services/ui.service";
import { Debounce } from "utils/debounce";
import { CreateOrgModalComponent } from "../../org/create/create.component";
import { SingleIndicatorStatsSurveyComponent } from "../../surveys/pages/stats/indicators/components/single-indicator/single-indicator.component";
import { ErrorMessageComponent } from "../../utils/error-message/error-message.component";
import { MiniTrendIndicatorComponent } from "../../utils/mini-trend-indicator/mini-trend-indicator";
import { OrgAccountAvatarListComponent } from "../../utils/org-account-avatar-list/org-account-avatar-list.component";
import { OrgAccountAvatarComponent } from "../../utils/org-account-avatar/org-account-avatar.component";
import { ScreebIconComponent } from "../../utils/screeb-icon/screeb-icon.component";
import { BannerComponent } from "../billing/banners/banner.component";
import { FreeTrialBannerComponent } from "../billing/banners/free-trial-banner.component";

const MINI_TREND_GRAPH_MAX_BUCKETS = 30;

interface ColumnItem {
  key: string;
  name: string;
  sortKey?: string;
  width?: string;
  align: "left" | "right" | "center";
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null | true;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: "page-super-org-overview",
  templateUrl: "./overview.component.html",
  styleUrls: ["./overview.component.scss"],
  imports: [
    FreeTrialBannerComponent,
    OrgAccountAvatarComponent,
    OrgAccountAvatarListComponent,
    NzRowDirective,
    NzColDirective,
    NgClass,
    SingleIndicatorStatsSurveyComponent,
    MiniTrendIndicatorComponent,
    NgIf,
    ErrorMessageComponent,
    NzSelectComponent,
    FormsModule,
    NgFor,
    NzOptionComponent,
    NzFormDirective,
    NzInputGroupComponent,
    ɵNzTransitionPatchDirective,
    NzInputGroupWhitSuffixOrPrefixDirective,
    NzInputDirective,
    NzIconDirective,
    NzButtonComponent,
    NzWaveDirective,
    BannerComponent,
    RouterLink,
    NzTooltipDirective,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzThAddOnComponent,
    NzCellAlignDirective,
    NzCellFixedDirective,
    NzPopoverDirective,
    ScreebIconComponent,
    NzCheckboxComponent,
    NzTbodyComponent,
    NzTagComponent,
    NzProgressComponent,
    NzSpinComponent,
    NzDropDownDirective,
    NzDropdownMenuComponent,
    NzMenuDirective,
    NzMenuItemComponent,
    CreateOrgModalComponent,
    ScreebFormatPipe,
    SuperPermissionPipe,
  ],
})
export class OverviewSuperOrgPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Organization overview";
  public name = "Organization overview";

  private obs: any = null;

  public error: Error;
  public loadings = {
    getNps: true,
    getNewRespondents: true,
    getCurrentActiveRespondents: true,
    getDailyActiveRespondents: true,
    getWeeklyActiveRespondents: true,
    getMonthlyActiveRespondents: true,
    getActivityTrendEvents: true,
  };

  public superOrg: SuperOrg = null;
  public workspaces: Org[] = [];
  public workspacesTags: string[] = [];
  public surveysByWorkspace: { [workspaceId: string]: Survey[] } = {};
  public filteredWorkspaces: Org[] = [];
  public superOrgAccounts: Account[] = [];

  public modalOpened = false;
  public editWorkspace: Org | null = null;

  public canCreateMoreWorkspaces = false;

  public superOrgHasRespondents = true;
  public currentActiveSurveyByWorkspace = {};
  public responseSurveyRatebyWorkspace = {};
  public industryAverageNps = 50;
  public industryAverageResponseRate = 0;
  public npsAggregation: NpsAggregation = null;
  public npsAggregationPerDate: NpsAggregation[] = null;
  public npsAggregationDiff = 0;
  public currentActiveRespondents = 0;
  public newRespondents = 0;
  public newRespondentsDiff = 0;
  public dailyActiveRespondents = 0;
  public dailyActiveRespondentsDiff = 0;
  public weeklyActiveRespondents = 0;
  public weeklyActiveRespondentsDiff = 0;
  public monthlyActiveRespondents = 0;
  public monthlyActiveRespondentsDiff = 0;

  public npsDatasets: ChartDataset[] = [];
  public activityDatasets: ChartDataset[] = [];

  public helloMessage: string = "";
  public formattedDiffIndustryAverageNps: string = "";

  public quoteOfTheDay =
    RANDOM_MSGS[new Date().getDate() % RANDOM_MSGS.length].split(" — ");
  public industriesScores: IndustriesScores;

  private loaderStatus: object = {};
  public currentWorkspaceActionDropDown: string = null;

  public keyword: string = null;
  public filteredTags: string[] = [];
  public mandatoryColumns: ColumnItem[] = [
    {
      key: "name",
      name: "Name",
      width: "200px",
      align: "left",
      sortOrder: "ascend",
      sortFn: (a: Org, b: Org) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
  ];

  public columnPickerVisible: boolean = false;
  public allColumns: ColumnItem[] = [
    {
      key: "mtu",
      name: "MAU",
      width: "150px",
      align: "left",
      sortOrder: "",
      sortFn: (a: Org, b: Org) =>
        (a.stats?.current_month_respondents || 0) -
        (b.stats?.current_month_respondents || 0),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      key: "active_surveys",
      name: "Currently Active Surveys",
      width: "200px",
      align: "center",
      sortOrder: "",
      sortFn: (a: Org, b: Org) =>
        this.currentActiveSurveyByWorkspace[a.id] -
        this.currentActiveSurveyByWorkspace[b.id],
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      key: "survey_display",
      name: "Survey display",
      width: "150px",
      align: "center",
      sortOrder: "",
      sortFn: (a: Org, b: Org) =>
        a.stats.total_survey_responses - b.stats.total_survey_responses,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      key: "survey_response",
      name: "Survey Responses",
      width: "150px",
      align: "center",
      sortOrder: "",
      sortFn: (a: Org, b: Org) =>
        a.stats.total_survey_responses_started -
        b.stats.total_survey_responses_started,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      key: "survey_respondent",
      name: "Survey Respondents",
      width: "150px",
      align: "center",
      sortOrder: "",
      sortFn: (a: Org, b: Org) =>
        a.stats.total_respondents - b.stats.total_respondents,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      key: "avg_response_rate",
      name: "Average response rate",
      width: "185px",
      align: "center",
      sortOrder: "",
      sortFn: (a: Org, b: Org) =>
        this.responseSurveyRatebyWorkspace[a.id] -
        this.responseSurveyRatebyWorkspace[b.id],
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      key: "workspace_users",
      name: "Workspace users",
      width: "185px",
      align: "center",
      sortOrder: "",
      sortFn: (a: Org, b: Org) =>
        this.getWorkspaceUsersCount(a) - this.getWorkspaceUsersCount(b),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
  ];

  public displayedColumnsKeys = [
    "mtu",
    "active_surveys",
    "survey_display",
    "avg_response_rate",
  ];

  public displayedColumns: ColumnItem[] = [
    ...this.mandatoryColumns,
    ...this.allColumns.filter((column) =>
      this.displayedColumnsKeys.includes(column.key),
    ),
  ];

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private modalService: NzModalService,
    private analyticsDao: AnalyticsDao,
    public sessionService: SessionService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    private notificationHelper: NotificationHelper,
    public uiService: UIService,
    private surveyDao: SurveyDao,
    private superOrgDao: SuperOrgDao,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.helloMessage = this.getHelloMessage();

    // Reuse last displayed columns
    try {
      const tmpStorage = window.localStorage.getItem(
        "overviewSuperOrgDisplayedColumnsKeys",
      );
      if (tmpStorage) {
        this.displayedColumnsKeys = JSON.parse(tmpStorage);
      }
    } catch (err) {
      console.error(err);
    }

    this.obs = this.route.data.subscribe(async (data) => {
      this.industriesScores = data.industriesScores as IndustriesScores;
      this.superOrg = data.superOrg;
      this.superOrgAccounts = data.superOrgAccounts;
      this.refreshWorkspaces(data.workspaces);
    });
  }

  public getWorkspaceUsersCount(workspace: Org): number {
    return this.superOrgAccounts.filter((account) =>
      account.workspaces_accesses.some((w) => w.workspace_id === workspace.id),
    ).length;
  }

  public refreshWorkspaces(workspaces: Org[]) {
    this.workspaces = workspaces.map((f: any) => {
      f.userCount = this.getWorkspaceUsersCount(f);
      f.sliderMin = formatNumber(
        f.stats?.current_month_respondents || 0,
        "number",
        1,
        "auto",
        false,
      );
      f.sliderMax = formatNumber(
        this.superOrg.entitlements.mtu_mode !== "auto"
          ? f.entitlements?.max_mtu || 0
          : this.superOrg.entitlements?.max_mtu || 0,
        "number",
        1,
        "auto",
        false,
      );
      return f;
    });
    this.workspacesTags = [
      ...new Set(
        this.workspaces
          .map((e) => e.tags)
          .flat()
          .filter((e) => e?.length),
      ),
    ];
    this.filteredWorkspaces = this.workspaces;

    this.canCreateMoreWorkspaces =
      this.superOrg.entitlements.max_workspaces === -1 ||
      this.workspaces.length + 1 <= this.superOrg.entitlements.max_workspaces;

    // As super org doesn't have industry, we take the first workspace's industry
    const firstIndustry = this.filteredWorkspaces.find(
      (workspace) => workspace.industry,
    )?.industry;

    this.industryAverageNps = getIndustryAverageScore(
      this.industriesScores,
      "nps",
      firstIndustry,
    );
    this.industryAverageResponseRate = getIndustryAverageScore(
      this.industriesScores,
      "completionRate",
      firstIndustry,
    );

    this.onSearchChangeImmediate(this.keyword ?? "");
    this.getStats().then(() => {
      this.formattedDiffIndustryAverageNps =
        this.getFormattedDiffIndustryAverageNps();
    });
  }

  public onClose(needRefetch: boolean = false) {
    if (needRefetch) {
      this.uiService
        .fetchEverything(undefined, true)
        .then(() => {
          this.refreshWorkspaces(this.uiService.orgWorkspaces);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    this.modalOpened = false;
  }

  public onAdd() {
    this.editWorkspace = null;
    this.modalOpened = true;
  }

  public onEditWorkspace(workspace: Org) {
    this.editWorkspace = workspace;
    this.modalOpened = true;
  }

  public isInWorkspace(workspace: Org): boolean {
    return this.uiService.orgs.some((org) => org.id === workspace.id);
  }

  public get isInAtLeastOneWorkspace(): boolean {
    return this.uiService.orgs.length > 0;
  }

  private async getStats() {
    this.error = null;

    try {
      this.superOrgHasRespondents = this.superOrg.stats.total_respondents > 0;

      await this.getNps();
      await this.getNewRespondents();
      await this.getCurrentActiveRespondents();
      await this.getDailyActiveRespondents();
      await this.getWeeklyActiveRespondents();
      await this.getMonthlyActiveRespondents();
      await this.getWorkspacesSurveys();
      await this.getActivityTrendEvents();

      /* await Promise.all([
        this.getNps(),
        this.getNewRespondents(),
        this.getCurrentActiveRespondents(),
        this.getDailyActiveRespondents(),
        this.getWeeklyActiveRespondents(),
        this.getMonthlyActiveRespondents(),
        this.getWorkspacesSurveys(),
        this.getActivityTrendEvents(),
      ]); */
    } catch (err) {
      this.error = err;
      console.error(err);
    }
  }

  protected getNbrDateBucket(): number {
    const nbrBuckets =
      differenceInDays(new Date(), this.superOrg.created_at) + 1;

    if (nbrBuckets > MINI_TREND_GRAPH_MAX_BUCKETS) {
      return MINI_TREND_GRAPH_MAX_BUCKETS;
    }

    return nbrBuckets;
  }

  private async getNpsForRange(workspaceId: string, start: Date, end: Date) {
    const query: AnalyticsQueryResponse = {
      org_id: UUID(workspaceId),
      survey_ids: ["*"],
      filters_bool: "AND",
      type: "response",
      filters: [],
      range: {
        start,
        end,
        field: "created_at",
      },
      having_answer_only: true,
      aggregation: [
        {
          by: "by_date",
          params: {
            date_histogram_min_interval: "day",
            date_histogram_buckets: this.getNbrDateBucket(),
            // we have to change the sign of timezone, because Javascript sucks
            // this is pure shit
            // https://stackoverflow.com/questions/55564508/pie-chart-js-display-a-no-data-held-message
            date_histogram_timezone_offset: -new Date().getTimezoneOffset(),
          },
        },
        {
          by: "by_answer.value",
          params: {
            cta_type: "nps",
          },
        },
      ],
      size: 10,
      with_total: false,
    };

    const response = await this.analyticsDao.search(query, true);

    const surveys = Object.values(this.surveysByWorkspace).flat();
    const npsAggregationPerDate = bucketToDatedAggregation(
      response,
      (buckets) =>
        bucketToNpsAggregation(
          buckets,
          getDateAnswerValueBuckets,
          surveys.map(({ scenario }) => scenario),
        ),
    );

    const npsAggregation = computeNpsPeriod(npsAggregationPerDate);

    return {
      npsAggregation,
      npsAggregationPerDate,
    };
  }

  private getNpsDatasets(npsAggregationPerDate: NpsAggregation[]) {
    return [
      {
        yAxisID: "y",
        data: npsAggregationPerDate.map(({ score, date }) => ({
          x: +date,
          y: score,
        })),
      },
      {
        yAxisID: "y",
        data: [
          {
            x: +npsAggregationPerDate[0]?.date || +new Date(),
            y: this.industryAverageNps,
          },
          {
            x:
              +npsAggregationPerDate[npsAggregationPerDate.length - 1]?.date ||
              +new Date(),
            y: this.industryAverageNps,
          },
        ],
        borderColor: "rgba(94, 33, 241, 0.5)",
        borderWidth: 1,
        borderDash: [4, 8],
        borderDashOffset: 0,
        fill: false,
      },
    ];
  }

  @Debounce(200)
  private async getNps() {
    this.loadings.getNps = true;

    const npsAggregations = [];
    const npsAggregationsPerDate = [];
    const npsAggregationDiffs = [];
    for (const workspace of this.workspaces) {
      const { npsAggregation, npsAggregationPerDate } =
        await this.getNpsForRange(
          workspace.id,
          this.getStartDate(),
          new Date(),
        );
      const { npsAggregation: npsAggregationDiff } = await this.getNpsForRange(
        workspace.id,
        subMonths(this.getStartDate(), 1),
        this.getStartDate(),
      );
      npsAggregations.push(npsAggregation);
      npsAggregationsPerDate.push(npsAggregationPerDate);
      npsAggregationDiffs.push(npsAggregationDiff);
    }

    this.npsAggregation = npsAggregations.reduce(
      (acc, cur: NpsAggregation) => ({
        score: acc.score + cur.score,
        detractors: acc.detractors + cur.detractors,
        promoters: acc.promoters + cur.promoters,
        passives: acc.passives + cur.passives,
        total: acc.total + cur.total,
      }),
      {
        score: 0,
        detractors: 0,
        promoters: 0,
        passives: 0,
        total: 0,
      },
    );
    this.npsAggregation.score /= npsAggregations.length;

    let npsAggregationDiffScore = npsAggregationDiffs.reduce(
      (acc, cur: NpsAggregation) => {
        return acc.score + cur.score;
      },
      0,
    );
    npsAggregationDiffScore /= npsAggregationDiffs.length;
    this.npsAggregationDiff = computeVariation(
      npsAggregationDiffScore,
      this.npsAggregation.score,
    );

    // Sum aggregations per date
    const npsAggregationsPerDateObj: { [date: string]: NpsAggregation } = {};
    const npsAggregationsPerDateCount: { [date: string]: number } = {};
    for (const tmp of npsAggregationsPerDate) {
      for (const npsAggregation of tmp) {
        const { date } = npsAggregation;
        if (!npsAggregationsPerDateObj[date]) {
          npsAggregationsPerDateObj[date] = {
            score: 0,
            detractors: 0,
            promoters: 0,
            passives: 0,
            total: 0,
            date: null,
          };
          npsAggregationsPerDateCount[date] = 0;
        }
        npsAggregationsPerDateObj[date].score += npsAggregation.score;
        npsAggregationsPerDateObj[date].detractors += npsAggregation.detractors;
        npsAggregationsPerDateObj[date].promoters += npsAggregation.promoters;
        npsAggregationsPerDateObj[date].passives += npsAggregation.passives;
        npsAggregationsPerDateObj[date].total += npsAggregation.total;
        npsAggregationsPerDateCount[date] += 1;
      }
    }

    // Compute average
    const npsAggregationPerDate = [];
    for (const [date, npsAggregation] of Object.entries(
      npsAggregationsPerDateObj,
    )) {
      npsAggregationPerDate.push({
        date: new Date(date),
        score: npsAggregation.score / npsAggregationsPerDateCount[date],
        detractors: npsAggregation.detractors,
        promoters: npsAggregation.promoters,
        passives: npsAggregation.passives,
        total: npsAggregation.total,
      });
    }

    // Sort by date
    npsAggregationPerDate.sort((a, b) => +a.date - +b.date);

    this.npsDatasets = this.getNpsDatasets(npsAggregationPerDate);
    this.loadings.getNps = false;
  }

  private getRespondentsCountQuery(
    workspaceID: UUID,
    start: Date,
    field: "last_activity_at" | "created_at",
    end = new Date(),
  ): AnalyticsQueryUsers {
    return {
      type: "respondent",
      org_id: workspaceID,
      survey_ids: ["*"],
      identified_only: false,
      filters_bool: "AND",
      filters: [],
      range: {
        start,
        end,
        field,
      },
      size: 0,
    };
  }

  public onColumnPicker(change: boolean) {
    this.columnPickerVisible = change;
  }

  public onColumnToggle(key: string, change: boolean) {
    if (change) {
      this.displayedColumnsKeys.push(key);
    } else {
      this.displayedColumnsKeys = this.displayedColumnsKeys.filter(
        (columnKey) => columnKey !== key,
      );
    }

    this.displayedColumns = [
      ...this.mandatoryColumns,
      ...this.allColumns.filter((column) =>
        this.displayedColumnsKeys.includes(column.key),
      ),
    ];

    window.localStorage.setItem(
      "overviewSuperOrgDisplayedColumnsKeys",
      JSON.stringify(this.displayedColumnsKeys),
    );
  }

  private async getNewRespondents() {
    if (!this.superOrgHasRespondents) {
      this.loadings.getNewRespondents = false;
      return;
    }

    this.loadings.getNewRespondents = true;

    const startDate = this.getStartDate();
    const responses = await Promise.all(
      this.workspaces.map((workspace) =>
        this.analyticsDao.search(
          this.getRespondentsCountQuery(
            UUID(workspace.id),
            startDate,
            "created_at",
          ),
          true,
        ),
      ),
    );

    const responsesDiff = await Promise.all(
      this.workspaces.map((workspace) =>
        this.analyticsDao.search(
          this.getRespondentsCountQuery(
            UUID(workspace.id),
            subMonths(startDate, 1),
            "created_at",
            startDate,
          ),
          true,
        ),
      ),
    );

    // Sum all responses
    this.newRespondents = responses.reduce((acc, cur) => {
      return acc + cur.hits.total;
    }, 0);

    const responseDiffTotal = responsesDiff.reduce((acc, cur) => {
      return acc + cur.hits.total;
    }, 0);

    this.newRespondentsDiff = Math.round(
      computeVariation(responseDiffTotal, this.newRespondents),
    );
    this.loadings.getNewRespondents = false;
  }

  private async getCurrentActiveRespondents() {
    if (!this.superOrgHasRespondents) {
      this.loadings.getCurrentActiveRespondents = false;
      return;
    }

    this.loadings.getCurrentActiveRespondents = true;

    const responses = await Promise.all(
      this.workspaces.map((workspace) =>
        this.analyticsDao.search(
          this.getRespondentsCountQuery(
            UUID(workspace.id),
            subMinutes(new Date(), 5),
            "last_activity_at",
          ),
          true,
        ),
      ),
    );

    this.currentActiveRespondents = responses.reduce((acc, cur) => {
      return acc + cur.hits.total;
    }, 0);
    this.loadings.getCurrentActiveRespondents = false;
  }

  private async getDailyActiveRespondents() {
    if (!this.superOrgHasRespondents) {
      this.loadings.getDailyActiveRespondents = false;
      return;
    }

    this.loadings.getDailyActiveRespondents = true;

    const startDate = startOfDay(new Date());
    const responses = await Promise.all(
      this.workspaces.map((workspace) =>
        this.analyticsDao.search(
          this.getRespondentsCountQuery(
            UUID(workspace.id),
            startDate,
            "last_activity_at",
          ),
          true,
        ),
      ),
    );
    const responsesDiff = await Promise.all(
      this.workspaces.map((workspace) =>
        this.analyticsDao.search(
          this.getRespondentsCountQuery(
            UUID(workspace.id),
            subDays(startDate, 1),
            "last_activity_at",
            startDate,
          ),
          true,
        ),
      ),
    );

    this.dailyActiveRespondents = responses.reduce((acc, cur) => {
      return acc + cur.hits.total;
    }, 0);

    const responseDiffTotal = responsesDiff.reduce((acc, cur) => {
      return acc + cur.hits.total;
    }, 0);
    this.dailyActiveRespondentsDiff = Math.round(
      computeVariation(responseDiffTotal, this.dailyActiveRespondents),
    );
    this.loadings.getDailyActiveRespondents = false;
  }

  private async getWeeklyActiveRespondents() {
    if (!this.superOrgHasRespondents) {
      this.loadings.getWeeklyActiveRespondents = false;
      return;
    }

    this.loadings.getWeeklyActiveRespondents = true;

    const startDate = startOfWeek(new Date());
    const responses = await Promise.all(
      this.workspaces.map((workspace) =>
        this.analyticsDao.search(
          this.getRespondentsCountQuery(
            UUID(workspace.id),
            startDate,
            "last_activity_at",
          ),
          true,
        ),
      ),
    );
    const responsesDiff = await Promise.all(
      this.workspaces.map((workspace) =>
        this.analyticsDao.search(
          this.getRespondentsCountQuery(
            UUID(workspace.id),
            subWeeks(startDate, 1),
            "last_activity_at",
            startDate,
          ),
          true,
        ),
      ),
    );

    this.weeklyActiveRespondents = responses.reduce((acc, cur) => {
      return acc + cur.hits.total;
    }, 0);

    const responseDiffTotal = responsesDiff.reduce((acc, cur) => {
      return acc + cur.hits.total;
    }, 0);

    this.weeklyActiveRespondentsDiff = Math.round(
      computeVariation(responseDiffTotal, this.weeklyActiveRespondents),
    );
    this.loadings.getWeeklyActiveRespondents = false;
  }

  private async getWorkspacesSurveys() {
    const surveysByWorkspace = await Promise.all(
      this.workspaces.map((workspace) => {
        return this.surveyDao.getAllByOrgId(
          "survey",
          workspace.id,
          true,
          false,
          true,
        );
      }),
    );

    this.currentActiveSurveyByWorkspace = {};
    this.surveysByWorkspace = {};
    for (let i = 0; i < this.workspaces.length; i += 1) {
      this.surveysByWorkspace[this.workspaces[i].id] = surveysByWorkspace[i];
      this.currentActiveSurveyByWorkspace[this.workspaces[i].id] =
        surveysByWorkspace[i].filter(({ survey_distributions }) =>
          survey_distributions?.some(({ enabled }) => enabled),
        ).length;
      this.responseSurveyRatebyWorkspace[this.workspaces[i].id] = 0.0;
      for (let j = 0; j < surveysByWorkspace[i].length; j += 1) {
        this.responseSurveyRatebyWorkspace[this.workspaces[i].id] +=
          surveysByWorkspace[i][j].stats.response_rate;
      }

      if (this.responseSurveyRatebyWorkspace[this.workspaces[i].id] > 0.0) {
        this.responseSurveyRatebyWorkspace[this.workspaces[i].id] =
          this.responseSurveyRatebyWorkspace[this.workspaces[i].id] /
          surveysByWorkspace[i].length;
      }
    }
  }

  private async getMonthlyActiveRespondents() {
    if (!this.superOrgHasRespondents) {
      this.loadings.getMonthlyActiveRespondents = false;
      return;
    }

    this.loadings.getMonthlyActiveRespondents = true;

    const startDate = this.getStartDate();
    const responses = await Promise.all(
      this.workspaces.map((workspace) =>
        this.analyticsDao.search(
          this.getRespondentsCountQuery(
            UUID(workspace.id),
            startDate,
            "last_activity_at",
          ),
          true,
        ),
      ),
    );
    const responsesDiff = await Promise.all(
      this.workspaces.map((workspace) =>
        this.analyticsDao.search(
          this.getRespondentsCountQuery(
            UUID(workspace.id),
            subMonths(startDate, 1),
            "last_activity_at",
            startDate,
          ),
          true,
        ),
      ),
    );

    this.monthlyActiveRespondents = responses.reduce((acc, cur) => {
      return acc + cur.hits.total;
    }, 0);

    const responseDiffTotal = responsesDiff.reduce((acc, cur) => {
      return acc + cur.hits.total;
    }, 0);

    this.monthlyActiveRespondentsDiff = Math.round(
      computeVariation(responseDiffTotal, this.monthlyActiveRespondents),
    );
    this.loadings.getMonthlyActiveRespondents = false;
  }

  getStartDate() {
    return startOfMonth(new Date());
  }

  getActivityDatasets(buckets: SimpleBucket[]) {
    return [
      {
        yAxisID: "y",
        data: buckets.map(({ key_as_string, doc_count }) => ({
          x: +new Date(key_as_string),
          y: doc_count,
        })),
      },
    ];
  }

  private async getActivityTrendEvents() {
    if (!this.superOrgHasRespondents) {
      this.loadings.getActivityTrendEvents = false;
      return;
    }

    this.loadings.getActivityTrendEvents = true;

    const responses = [];
    for (const workspace of this.workspaces) {
      const query: AnalyticsQueryUsers = {
        type: "respondent",
        org_id: UUID(workspace.id),
        survey_ids: ["*"],
        filters_bool: "AND",
        filters: [],
        identified_only: false,
        size: 0,
        range: {
          start: this.getStartDate(),
          end: new Date(),
          field: "last_activity_at",
        },
        aggregation: [
          {
            by: "by_event.date",
            params: {
              date_histogram_min_interval: "day",
              date_histogram_buckets: this.getNbrDateBucket(),
              // we have to change the sign of timezone, because Javascript sucks
              // this is pure shit
              // https://stackoverflow.com/questions/55564508/pie-chart-js-display-a-no-data-held-message
              date_histogram_timezone_offset: -new Date().getTimezoneOffset(),
            },
          },
        ],
      };

      const response = await this.analyticsDao.search(query, true);
      responses.push(response);
    }

    const buckets = [];
    for (const response of responses) {
      buckets.push(...(response.aggregations?.event?.date?.buckets ?? []));
    }

    const mergedBucketsByKey = {};
    for (const bucket of buckets) {
      if (!mergedBucketsByKey[bucket.key]) {
        mergedBucketsByKey[bucket.key] = bucket;
      } else {
        mergedBucketsByKey[bucket.key].doc_count += bucket.doc_count;
      }
    }

    // Sort buckets by key, lower first
    const ordered = Object.keys(mergedBucketsByKey)
      .sort()
      .reduce((obj, key) => {
        obj[key] = mergedBucketsByKey[key];
        return obj;
      }, {});

    this.activityDatasets = this.getActivityDatasets(Object.values(ordered));
    this.loadings.getActivityTrendEvents = false;
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  private getHelloMessage = () => {
    const hours = new Date().getHours();

    if (hours >= 18 || hours < 5) {
      return "Good evening";
    } else if (hours >= 5 && hours < 12) {
      return "Good morning";
    } else {
      return "Good afternoon";
    }
  };

  private getFormattedDiffIndustryAverageNps() {
    return formatNumber(
      (this.npsAggregation?.score || 0) - this.industryAverageNps,
      "points",
      0,
      "force",
    );
  }

  public getAverageDiffColorClass(): string {
    const diff = this.npsAggregation.score - this.industryAverageNps;
    if (Math.round(diff) > 0) {
      return "green";
    } else if (Math.round(diff) < 0) {
      return "red";
    }
    return "grey";
  }

  /**
   * Search
   */
  public onSearchChange(keyword: string) {
    this.onSearchChangeImmediate(keyword);
  }

  public onSearchChangeImmediate(keyword: string) {
    this.filteredWorkspaces = this.workspaces.filter((workspace) => {
      if (this.filteredTags && this.filteredTags.length > 0) {
        if (
          !this.filteredTags.every(
            (tag) => workspace.tags && workspace.tags.includes(tag),
          )
        ) {
          return false;
        }
      }

      if (keyword?.length) {
        return workspace.name.toLowerCase().includes(keyword.toLowerCase());
      }
      return true;
    });
  }

  public onTagsFilterChange(tags: string[]) {
    this.filteredTags = tags;
    this.onSearchChangeImmediate(this.keyword ?? "");
  }

  public reload() {
    window.location.reload();
  }

  /**
   * Dropdown
   */
  public setCurrentWorkspaceActionDropDown(
    workspaceId: string,
    opened: boolean,
  ) {
    if (!opened) {
      workspaceId = null;
    }
    this.currentWorkspaceActionDropDown = workspaceId;
  }

  private setWorkspaceActionLoaderStatus(
    workspaceId: string,
    statusId: string,
    status: boolean,
  ) {
    if (!this.loaderStatus[workspaceId]) {
      this.loaderStatus[workspaceId] = {};
    }

    this.loaderStatus[workspaceId][statusId] = status;
  }

  public getWorkspaceActionLoaderStatus(
    workspaceId: string,
    statusId: string,
  ): boolean {
    return this.loaderStatus?.[workspaceId]?.[statusId] === true;
  }

  public onDelete(workspace: Org) {
    this.setWorkspaceActionLoaderStatus(workspace.id, "deleting", true);

    new Promise((resolve) => {
      let msgPrefix = "";
      if (workspace.stats.total_survey_responses_started === 1) {
        msgPrefix = `1 response will be removed.<br>`;
      } else if (workspace.stats.total_survey_responses_started > 1) {
        msgPrefix = `${workspace.stats.total_survey_responses_started} responses will be removed.<br>`;
      }

      this.modalService.warning({
        nzTitle: "Do you really want to hurt me? 🎶",
        nzContent: msgPrefix + "This operation cannot be undone.",
        nzStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
        },
        nzMaskClosable: true,
        nzCloseOnNavigation: false,
        nzOkType: "default",
        nzOkDanger: true,
        nzOkText: "Confirm",
        nzCancelText: "Cancel",
        nzOnOk: () => resolve(true),
        nzOnCancel: () => resolve(false),
      });
    })
      .then((remove: boolean) => {
        if (!remove) {
          return;
        }

        return this.superOrgDao
          .deleteWorkspace(workspace.super_org_id, workspace.id)
          .then(() => this.uiService.fetchEverything(undefined, true))
          .then(() => {
            this.refreshWorkspaces(this.uiService.orgWorkspaces);
          });
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      })
      .then(() => {
        this.setWorkspaceActionLoaderStatus(workspace.id, "deleting", false);
      });
  }
}
