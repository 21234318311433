import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";

import { SessionService } from "./auth.service";
import { EventService } from "./event.service";

import { ENV } from "environment";
import { ScreebConfig } from "./config.service";

export function startLogging(config: ScreebConfig) {
  if (!config.sentryDsn) {
    return;
  }

  Sentry.init({
    dsn: config.sentryDsn,
    release: `admin@${ENV["VERSION"]}`,
    environment: ENV["ENV"],
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });
}

export function handleError(config: ScreebConfig, error: Error) {
  if (!config?.sentryDsn) {
    return;
  }

  Sentry.createErrorHandler({
    showDialog: false,
  }).handleError(error);
}

@Injectable()
export class LoggerService {
  constructor(
    private sessionService: SessionService,
    private eventService: EventService,
  ) {}

  public load() {
    this.refreshAccount();

    this.eventService.subscribeAll(() => {
      this.refreshAccount();
    });
  }

  private refreshAccount() {
    const session = this.sessionService.session;

    if (this.sessionService.isAuth() && !!session) {
      Sentry.setUser({
        email: session.email,
        id: session.id,
        firstname: session.firstname,
        lastname: session.lastname,
      });
    } else {
      Sentry.setUser(null);
    }
  }
}
