import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { Org } from "models/org.model";
import { getFormattedUserGroupNameOrID } from "models/user-group.types";
import {
  RegistryEntry,
  RegistryEntrySourceFormatted,
} from "models/registry.model";
import {
  NzSelectItemInterface,
  NzSelectComponent,
  NzOptionComponent,
} from "ng-zorro-antd/select";
import {
  NzTableSortFn,
  NzTableSortOrder,
  NzTableComponent,
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzThAddOnComponent,
  NzCellAlignDirective,
  NzTbodyComponent,
} from "ng-zorro-antd/table";
import { EntitlementService } from "services/entitlement.service";
import { RoutingService } from "services/routing.service";
import { arrayToSet } from "utils/array";
import { PermissionsService } from "services/permissions.service";
import { NotificationHelper } from "helpers/notification.helper";
import { RegistryDao } from "models/registry.dao";
import { NzModalService } from "ng-zorro-antd/modal";
import { HttpErrorResponse } from "@angular/common/http";
import { FreeTrialBannerComponent } from "../../super-org/billing/banners/free-trial-banner.component";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { FormsModule } from "@angular/forms";
import { NzFormDirective } from "ng-zorro-antd/form";
import {
  NzInputGroupComponent,
  NzInputGroupWhitSuffixOrPrefixDirective,
  NzInputDirective,
} from "ng-zorro-antd/input";
import { NzEmptyComponent } from "ng-zorro-antd/empty";
import { NzTagComponent } from "ng-zorro-antd/tag";
import {
  NzDropDownDirective,
  NzDropdownMenuComponent,
} from "ng-zorro-antd/dropdown";
import { NzMenuDirective, NzMenuItemComponent } from "ng-zorro-antd/menu";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { FormatDistanceToNowPipeModule } from "ngx-date-fns";
import { PermissionPipe } from "pipes/permission.pipe";
import { FeaturePipe } from "pipes/feature.pipe";

interface ColumnItem {
  name: string;
  sortKey?: string;
  width?: number;
  align: "left" | "right" | "center";
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null | true;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: "segment-list-page",
  templateUrl: "./segment-list.component.html",
  styleUrls: ["./segment-list.component.scss"],
  imports: [
    FreeTrialBannerComponent,
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NzSelectComponent,
    FormsModule,
    NgFor,
    NzOptionComponent,
    NzFormDirective,
    NzInputGroupComponent,
    NzInputGroupWhitSuffixOrPrefixDirective,
    NzInputDirective,
    NzEmptyComponent,
    RouterLink,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzThAddOnComponent,
    NzCellAlignDirective,
    NzTbodyComponent,
    NzTagComponent,
    NzDropDownDirective,
    NgClass,
    NzDropdownMenuComponent,
    NzMenuDirective,
    NzMenuItemComponent,
    NzTooltipDirective,
    FormatDistanceToNowPipeModule,
    PermissionPipe,
    FeaturePipe,
  ],
})
export class SegmentListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Segments";
  public name = "Segments";

  private obs: any = null;

  public org: Org;

  public registryEntriesGroupType: RegistryEntry[];
  public registryEntriesGroup: RegistryEntry[];
  public filteredUserGroups: RegistryEntry[];

  public segmentsSources: NzSelectItemInterface[] = [];

  public selectedSegmentSources: string[] = [];
  public keyword = "";

  public pageSize = 10;
  public pageIndex = 1;
  public maxOffset = 0;

  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;
  public getFormattedUserGroupNameOrID = getFormattedUserGroupNameOrID;

  public currentSegmentActionDropDown: string = null;
  private loaderStatus: object = {};

  public listOfColumns: ColumnItem[] = [
    {
      name: "Segments",
      align: "left",
      sortKey: "userGroup.name",
      width: 200,
      sortOrder: "",
      sortFn: (a: RegistryEntry, b: RegistryEntry) =>
        a.title.localeCompare(b.title),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Last assignation",
      sortKey: "userGroup.updated_at",
      align: "center",
      width: 100,
      sortOrder: "descend",
      sortFn: (a: RegistryEntry, b: RegistryEntry) =>
        new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NzModalService,
    private routingService: RoutingService,
    private registryDao: RegistryDao,
    private notificationHelper: NotificationHelper,
    public permissionsService: PermissionsService,
    public entitlementService: EntitlementService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.registryEntriesGroup = data.registryEntriesGroup.groups;
      this.registryEntriesGroupType = data.registryEntriesGroup.group_types;
      this.segmentsSources = arrayToSet(
        this.registryEntriesGroup.flatMap(({ sources }) => sources),
      ).map((source) => ({
        nzValue: source,
        nzLabel: this.registryEntrySourceFormatted[source].title,
      }));

      this.dofilterUsersGroups(this.keyword, this.selectedSegmentSources);
    });
  }

  dofilterUsersGroups(raw: string, segmentSources: string[]) {
    this.filteredUserGroups = this.registryEntriesGroup.filter((userGroup) => {
      const keywords = raw
        .toLowerCase()
        .split(" ")
        .filter((k) => k.trim().length > 0);

      const name = getFormattedUserGroupNameOrID(userGroup).toLowerCase();
      return keywords.every((part) => name.includes(part));
    });

    if (segmentSources.length) {
      this.filteredUserGroups = this.filteredUserGroups.filter(({ sources }) =>
        sources.some((source) =>
          segmentSources.some((segmentSource) => segmentSource === source),
        ),
      );
    }
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  createSegment() {
    this.router.navigate(["/org", this.org.id, "people", "respondent"]);
  }

  public setCurrentSegmentActionDropDown(groupId: string, opened: boolean) {
    if (!opened) {
      groupId = null;
    }
    this.currentSegmentActionDropDown = groupId;
  }

  private setSegmentActionLoaderStatus(
    groupId: string,
    statusId: string,
    status: boolean,
  ) {
    if (!this.loaderStatus[groupId]) {
      this.loaderStatus[groupId] = {};
    }

    this.loaderStatus[groupId][statusId] = status;
  }

  public getSegmentActionLoaderStatus(
    groupId: string,
    statusId: string,
  ): boolean {
    return this.loaderStatus?.[groupId]?.[statusId] === true;
  }

  public onFavorite(group: RegistryEntry, favorite: boolean) {
    return this.registryDao
      .updateFavorite(this.org.id, [group.id], favorite)
      .then(() => {
        this.notificationHelper.trigger(
          "Segment have been updated",
          null,
          "success",
        );

        // basically, we order by favorite first, but here, we don't reorder after group update
        this.registryEntriesGroup = this.registryEntriesGroup.map(
          (registryEntry) => {
            if (registryEntry.id === group.id) {
              registryEntry.favorite = favorite;
            }
            return registryEntry;
          },
        );
        this.filteredUserGroups = this.filteredUserGroups.map(
          (registryEntry) => {
            if (registryEntry.id === group.id) {
              registryEntry.favorite = favorite;
            }
            return registryEntry;
          },
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Could not updated user segment",
          null,
          "error",
        );
      });
  }

  public onDelete(group: RegistryEntry) {
    this.setSegmentActionLoaderStatus(group.id, "deleting", true);

    new Promise((resolve) => {
      this.modalService.warning({
        nzTitle: "Do you really want to hurt me? 🎶",
        nzContent: "This operation cannot be undone.",
        nzStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
        },
        nzMaskClosable: true,
        nzCloseOnNavigation: false,
        nzOkType: "default",
        nzOkDanger: true,
        nzOkText: "Confirm",
        nzCancelText: "Cancel",
        nzOnOk: () => resolve(true),
        nzOnCancel: () => resolve(false),
      });
    })
      .then((remove: boolean) => {
        if (!remove) {
          return;
        }

        return this.registryDao.remove(group.tenant_id, [group.id]).then(() => {
          this.registryEntriesGroup = this.registryEntriesGroup.filter(
            (s: RegistryEntry) => s.id !== group.id,
          );
          this.filteredUserGroups = this.filteredUserGroups.filter(
            (s: RegistryEntry) => s.id !== group.id,
          );

          this.notificationHelper.trigger(
            "Segment successfully deleted.",
            null,
            "success",
          );
        });
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      })
      .then(() => {
        this.setSegmentActionLoaderStatus(group.id, "deleting", false);
      });
  }
}
