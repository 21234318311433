import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  MessageText,
  SurveyLanguages,
  getI18nTextLabelTranslation,
  setI18nTextLabelTranslation,
} from "models/survey.dao.types";
import { BuilderStore } from "stores/builder.store";
import { randomString } from "utils/random";
import { BigCardValidatorFactory } from "../../BuilderLayout/Validators/CardValidator";

import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { LanguageSelect } from "../../../../utils/language-select/language-select.component";
import { FormsModule } from "@angular/forms";
import { NgClass, NgIf } from "@angular/common";
import {
  parseContent,
  SanitizedMessageComponent,
} from "../../Cards/sanitized-message/sanitized-message.component";
import { FormErrorComponent } from "../../../../../utils/form-error/form-error.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { LateralPanelStepPickerComponent } from "../step-picker/step-picker.component";
import { RegistryEntry } from "models/registry.model";
import { UploadDao } from "models/upload.dao";
import { stripHtml } from "../../Cards/sanitized-message/sanitizer";
import { ConfigService } from "services/config.service";

@Component({
  selector: "lateral-panel-edit-text",
  templateUrl: "./edit-text.component.html",
  styleUrls: ["./edit-text.component.scss"],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    NzPopoverDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    LanguageSelect,
    FormsModule,
    NgClass,
    SanitizedMessageComponent,
    FormErrorComponent,
    NzButtonComponent,
    NzWaveDirective,
    NgIf,
    LateralPanelStepPickerComponent,
  ],
})
export class LateralPanelEditTextComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() message: MessageText = null;
  @Input() nextQuestionPickerEnabled: boolean = false;
  @Input() language: SurveyLanguages = "en";
  @Input() registryEntriesIdentityProperty: RegistryEntry[] = [];

  @Output() suggest = new EventEmitter<unknown>();
  @Output() languageChange = new EventEmitter<SurveyLanguages>();
  @Output() errorChange = new EventEmitter<boolean>();

  public uniqHash: string;
  public variables: { value: string; label: string }[] = [];
  public textErrors: string[] = [];
  public baseFileUrl = "";

  constructor(
    private uploadDao: UploadDao,
    private configService: ConfigService,
    public builderStore: BuilderStore,
  ) {}

  ngOnInit() {
    this.uniqHash = randomString(5);
    if (this.message.type !== "text") throw Error("unexpected message type");

    this.validateData();

    this.variables = this.registryEntriesIdentityProperty.map((entry) => ({
      value: entry.slug,
      label: entry.title,
    }));

    this.baseFileUrl = `${this.configService.config.staticEndpoint}/channels/${this.builderStore.survey.org_id}/surveys/${this.builderStore.survey.id}/questions`;
  }

  public get text(): string {
    return decode(
      getI18nTextLabelTranslation(
        this.message.text,
        this.language,
        this.language,
      ) || "",
    );
  }

  public set text(text: string) {
    setI18nTextLabelTranslation(this.message.text, text, this.language);
  }

  public getMessagePlaceholder(): string | null {
    if (
      this.getLength(this.text) < 1 &&
      this.language !== this.builderStore.survey.scenario.default_language
    ) {
      return "Missing translation";
    }
    return "";
  }

  /**
   * Data validation
   */
  public validateData() {
    const { errors } = BigCardValidatorFactory.getValidatorFromNode(this.node)(
      this.node,
      this.language,
      this.builderStore.survey.scenario.default_language,
    );

    [this.textErrors] = errors;

    this.errorChange.emit(this.textErrors.length > 0);
  }

  getLength(text: string) {
    return stripHtml(
      parseContent(text, {
        styling: true,
        CR: false,
        nativeCR: false,
        links: true,
      }),
    ).length;
  }

  onTextChange(event: CustomEvent<string>) {
    this.text = event.detail;
    this.validateData();
  }

  public uploadFile = async (
    file: File,
  ): Promise<{ url: string; alt: string }> => {
    const response = await this.uploadDao.uploadSurvey(
      this.builderStore.survey.org_id,
      this.builderStore.survey.id,
      file,
    );

    return {
      url: response.filename,
      alt: file.name,
    };
  };
}
