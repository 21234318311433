import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { NotificationHelper } from "helpers/notification.helper";
import { IntegrationType } from "models/integrations.model";
import {
  decodeState,
  OAuth2Service,
  OAuth2State,
} from "services/oauth2.service";
import { RoutingService } from "services/routing.service";
import { NgIf } from "@angular/common";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import { ErrorMessageComponent } from "../../../../utils/error-message/error-message.component";

@Component({
  selector: "page-auth-callback-oauth2",
  templateUrl: "./oauth2-callback.component.html",
  styleUrls: ["../../common.scss", "./oauth2-callback.component.scss"],
  imports: [NgIf, NzSpinComponent, ErrorMessageComponent],
})
export class Oauth2CallbackComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Authenticating Screeb 🚀";
  public name = "OAuth Callback";

  private sub: any = null;
  private obs2: any = null;

  public loading = false;
  public error: Error = null;

  public code: string = null;
  public state: OAuth2State = null;
  public integrationSlug: IntegrationType;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    private notificationHelper: NotificationHelper,
    private oauth2Service: OAuth2Service,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.error = null;
    this.loading = false;

    this.obs2 = this.route.paramMap.subscribe((params) => {
      this.integrationSlug = params.get("integration_slug") as IntegrationType;
    });

    this.sub = this.route.queryParams.subscribe((queryParams) => {
      this.fetchDataFromQueryParams(queryParams);
      this.grant();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }

    this.obs2?.unsubscribe();
  }

  private fetchDataFromQueryParams(queryParams: object) {
    this.code = queryParams["code"];
    this.state = decodeState(queryParams["state"]);

    if (!this.code) {
      this.error = new Error("Missing oauth2 code");
    } else if (!this.state.org_id) {
      this.error = new Error("Missing org id");
    } else if (!this.state.integration_type) {
      this.error = new Error("Missing integration name");
    } else if (!this.state.scopes) {
      this.error = new Error("Missing oauth2 scopes");
    }
  }

  private grant() {
    if (this.error) {
      console.error(this.error);
      return;
    }

    this.loading = true;

    const orgId = this.state.org_id;
    const type = this.state.integration_type;

    this.oauth2Service
      .grantAuthorization(orgId, type, this.code, this.state.scopes)
      .then(() => {
        this.redirect();
      })
      .catch((err) => {
        console.error(err);
        this.notificationHelper.trigger(
          err.error ?? "Failed to connecto to " + this.state.integration_type,
          null,
          "error",
        );
        this.redirect();
      })
      // .catch((error) => (this.error = error.error))
      .finally(() => (this.loading = false));
  }

  public redirect() {
    const orgId = this.state.org_id;
    const type = this.state.integration_type;

    if (orgId && type)
      this.router.navigate(["org", orgId, "integrations", type]);
  }
}
