/* eslint-disable @angular-eslint/no-output-on-prefix */
import { CdkOverlayOrigin } from "@angular/cdk/overlay";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SurveyLanguages } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { UIService } from "services/ui.service";
import { BuilderStore } from "stores/builder.store";
import { FlowTagComponent } from "../FlowTag/FlowTag.component";
import { NgIf } from "@angular/common";
import { ScenarioHistoryComponent } from "../scenario-history/scenario-history.component";
import { LanguageSelect } from "../../../utils/language-select/language-select.component";
import { FormsModule } from "@angular/forms";
import { ScenarioTranslateButtonComponent } from "./components/scenario-translate-button/scenario-translate-button.component";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";

@Component({
  selector: "checkpoint",
  templateUrl: "./Checkpoint.component.html",
  styleUrls: ["./Checkpoint.component.scss"],
  imports: [
    FlowTagComponent,
    NgIf,
    ScenarioHistoryComponent,
    LanguageSelect,
    FormsModule,
    ScenarioTranslateButtonComponent,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
  ],
})
export class CheckpointComponent {
  constructor(
    public builderStore: BuilderStore,
    public featureFlaggingService: FeatureFlaggingService,
    public uiService: UIService,
  ) {}

  @Output() onClick = new EventEmitter<any>();
  @Output() public defaultLanguageChange: EventEmitter<SurveyLanguages> =
    new EventEmitter();
  @Output() public messageURLChange: EventEmitter<void> = new EventEmitter();
  @Output() public surveyChange: EventEmitter<Survey> = new EventEmitter();
  @Output() public scenarioHistoryChange: EventEmitter<void> =
    new EventEmitter();
  @Output() public scenarioTranslated: EventEmitter<void> = new EventEmitter();
  @Output() public currentLanguageChange: EventEmitter<SurveyLanguages> =
    new EventEmitter();

  @Input() isLoading = false;
  @Input() surveyTags: string[] = [];
  @Input() defaultLanguageWarning = false;
  @Input() currentLanguageWarning = false;
  @Input() openOnInit = false;
  @Input() messageURL: string = "";

  public translationPanelOpened = false;

  public internalOnClick(origin: CdkOverlayOrigin) {
    this.onClick.emit(origin);
  }
}
