import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { NgIf } from "@angular/common";
import { PageComponentInterface } from "components/PageComponentInterface";
import { AccountDao } from "models/account.dao";
import { Org } from "models/org.model";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { NzColDirective, NzRowDirective } from "ng-zorro-antd/grid";
import { SessionService } from "services/auth.service";
import { RoutingService } from "services/routing.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import { ScreebIconComponent } from "../../utils/screeb-icon/screeb-icon.component";

@Component({
  selector: "page-onboarding-book-demo",
  templateUrl: "./onboarding-book-demo.component.html",
  styleUrls: [
    "./onboarding-book-demo.component.scss",
    "../layout/onboarding-layout.component.scss",
  ],
  imports: [
    NzRowDirective,
    NzColDirective,
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    ScreebIconComponent,
  ],
})
export class OnBoardingBookDemoPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Welcome to Screeb!";
  public name = "Onboarding book demo";

  private obs: any = null;
  public org: Org = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    public sessionService: SessionService,
    private trackersService: TrackersService,
    public accountDao: AccountDao,
    private uiService: UIService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );
    this.obs = this.route.data.subscribe((data) => {
      this.org = data["org"];
    });

    window["Calendly"].initInlineWidget({
      url: "https://calendly.com/simonrobic/30min?hide_gdpr_banner=1&primary_color=5e21f1",
      parentElement: document.querySelector(".calendly-inline-widget"),
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  onSkip() {
    const trackEvent = this.trackersService
      .newEventTrackingBuilder("Screeb Onboarding Book Demo Skipped")
      .withAccount(this.sessionService.session);

    if (this.org) {
      trackEvent.withOrg(this.org);
    }
    trackEvent.build();

    return this.goToNextStep();
  }

  goToNextStep() {
    return this.accountDao.updateOnboardingStatus("product-tour").then(() => {
      if (this.org) {
        this.router.navigate(["org", this.org.id]).then(() => {
          // Reload page to update billing status
          window.location.reload();
        });
      } else {
        this.router.navigate(["redirect-home"]).then(() => {
          // Reload page to update billing status
          window.location.reload();
        });
      }
    });
  }
}
