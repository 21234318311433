import { HttpClient } from "@angular/common/http";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ChartDataset } from "chart.js";
import { RadarIndicatorLegend } from "components/surveys/pages/stats/indicators/components/radar-indicator/radar-indicator.component";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsResponseItemResponseEmotions } from "models/analytics.model";
import { UUID } from "models/survey.dao.types";
import { IndustriesScores } from "resolvers/asset-industries-scores";
import { UIService } from "services/ui.service";
import { RadarIndicatorStatsSurveyComponent } from "../../../surveys/pages/stats/indicators/components/radar-indicator/radar-indicator.component";
import { computeEmotionsDataset } from "components/surveys/pages/stats/indicators/indicator.utils";

@Component({
  selector: "widget-emotions",
  templateUrl: "./emotions.component.html",
  styleUrls: ["./emotions.component.scss"],
  imports: [RadarIndicatorStatsSurveyComponent],
})
export class WidgetEmotionsComponent implements OnInit, OnChanges {
  @Input() startDate: Date;
  @Input() endDate: Date;

  public isLoading: boolean = true;

  private orgEmotions: AnalyticsResponseItemResponseEmotions = null;
  public emotionsChartDatasets: ChartDataset[] = [];
  public emotionsChartLegend: RadarIndicatorLegend = [
    {
      label: "Workspace",
      value: "Workspace",
      checked: true,
      disabled: false,
      color: "#1ED5A4",
      hoverColor: "#1ED5A4",
    },
    {
      label: "Industry",
      value: "Industry",
      checked: true,
      disabled: false,
      color: "#0054B6",
      hoverColor: "#0054B6",
      format: "number",
    },
  ];

  constructor(
    public uiService: UIService,
    private httpClient: HttpClient,
    private analyticsDao: AnalyticsDao,
  ) {}

  ngOnInit() {
    this.reload();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.startDate?.currentValue !== changes.startDate?.previousValue ||
      changes.endDate?.currentValue !== changes.endDate?.previousValue
    ) {
      this.reload();
    }
  }

  async reload() {
    this.isLoading = true;

    try {
      const scores = await this.httpClient
        .get<IndustriesScores>(`/assets/data/industries.scores.json`)
        .toPromise();

      await this.getOrgEmotions();
      await this.updateEmotionsDatasets(scores);
    } catch (error) {
      console.log("error", error);
    } finally {
      this.isLoading = false;
    }
  }

  // Get average org emotions
  private async getOrgEmotions() {
    return this.analyticsDao
      .search({
        org_id: this.uiService.currentOrgId as UUID,
        survey_ids: ["*"],
        filters_bool: "AND",
        type: "response",
        filters: [],
        size: 0,
        offset: 0,
        with_total: false,
        range: {
          start: this.startDate,
          end: this.endDate,
          field: "created_at",
        },
        aggregation: [{ by: "by_emotions" }],
      })
      .then((response) => {
        if (!response.aggregations) {
          return;
        }

        this.orgEmotions = computeEmotionsDataset(response);
      });
  }

  private updateEmotionsDatasets(scores: IndustriesScores) {
    const datasets = [];
    if (this.orgEmotions) {
      datasets.push({
        label: "Workspace",
        data: [
          // Let's have a minimum of 0.25 to fix not chart when we only have one positive emotion
          Math.max(this.orgEmotions?.anger, 0.25),
          Math.max(this.orgEmotions?.joy, 0.25),
          Math.max(this.orgEmotions?.fear, 0.25),
          Math.max(this.orgEmotions?.sadness, 0.25),
        ],
        fill: true,
        borderJoinStyle: "round",
        backgroundColor: "rgba(30, 213, 164, .5)",
        pointRadius: 0,
      });
    } else {
      this.emotionsChartLegend.find(
        (legend) => legend.value === "Workspace",
      ).disabled = true;
    }

    if (
      this.uiService.currentOrg.industry &&
      scores[this.uiService.currentOrg.industry]
    ) {
      datasets.push({
        label: "Industry",
        data: scores[this.uiService.currentOrg.industry].scores.emotions.map(
          (e) => e * 5,
        ),
        borderDash: [2, 2],
        fill: false,
        borderJoinStyle: "round",
        borderColor: "rgb(94, 33, 241)",
        borderWidth: 1.5,
        pointRadius: 0,
      });
    } else {
      this.emotionsChartLegend.find(
        (legend) => legend.value === "Industry",
      ).disabled = true;
    }
    this.emotionsChartDatasets = datasets;
  }
}
