import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";

import { RegistryEntrySourceFormatted } from "models/registry.model";
import { UserRecord } from "models/user-record.model";
import { UIService } from "services/ui.service";
import {
  NzTableQueryParams,
  NzTableSortFn,
  NzTableSortOrder,
  NzTableComponent,
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzThAddOnComponent,
  NzCellAlignDirective,
  NzTbodyComponent,
  NzCellEllipsisDirective,
} from "ng-zorro-antd/table";
import {
  AnalyticsQuerySession,
  AnalyticsQueryUsers,
  SortForSession,
  SortOrder,
} from "models/analytics.filters.type";
import { UUID } from "models/survey.dao.types";
import { Org } from "models/org.model";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { deepCopy } from "utils/object";
import { Debounce } from "utils/debounce";
import { AnalyticsResponseItemUser } from "models/analytics.model";
import { UserRecordDao } from "models/user-record.dao";
import { intervalToDuration } from "date-fns";
import { emojiTranscoder } from "components/builder/components/Cards/sanitized-message/emojis";
import { User } from "models/user.model";
import { NgIf, NgFor, DecimalPipe } from "@angular/common";
import { ErrorMessageComponent } from "../../../utils/error-message/error-message.component";
import { FiltersStatsSurveyComponent } from "../../../surveys/pages/stats/filters/filters.component";
import {
  NzInputGroupComponent,
  NzInputGroupWhitSuffixOrPrefixDirective,
  NzInputDirective,
} from "ng-zorro-antd/input";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { FormsModule } from "@angular/forms";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { UserRecordPreviewComponent } from "../../../user-record/list/user-record-preview/user-record-preview.component";
import { TextShimmerComponent } from "../../../common/text-shimmer/text-shimmer.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { NzEmptyComponent } from "ng-zorro-antd/empty";
import { RouterLink } from "@angular/router";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { TagsComponent } from "../../../utils/tags/tags.component";
import { UserSimpleListComponent } from "../user-simple-list/user-simple-list.component";
import {
  FormatDistanceToNowPipeModule,
  FormatDurationPipeModule,
} from "ngx-date-fns";
import { MarkdownComponent } from "ngx-markdown";
import { NzOptionComponent, NzSelectComponent } from "ng-zorro-antd/select";

interface ColumnItem {
  name: string;
  sortKey?: string;
  width?: string;
  align: "left" | "right" | "center";
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null | true;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: "user-records",
  templateUrl: "./user-records.component.html",
  styleUrls: ["./user-records.component.scss"],
  imports: [
    NgIf,
    ErrorMessageComponent,
    FiltersStatsSurveyComponent,
    NzInputGroupComponent,
    ɵNzTransitionPatchDirective,
    NzInputGroupWhitSuffixOrPrefixDirective,
    NzInputDirective,
    FormsModule,
    NzIconDirective,
    NgFor,
    UserRecordPreviewComponent,
    TextShimmerComponent,
    NzButtonComponent,
    NzTagComponent,
    NzEmptyComponent,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzThAddOnComponent,
    NzCellAlignDirective,
    RouterLink,
    NzTbodyComponent,
    ScreebIconComponent,
    NzCellEllipsisDirective,
    NzTooltipDirective,
    TagsComponent,
    UserSimpleListComponent,
    DecimalPipe,
    FormatDistanceToNowPipeModule,
    FormatDurationPipeModule,
    MarkdownComponent,
    NzSelectComponent,
    NzOptionComponent,
  ],
})
export class UserRecordsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public org: Org;
  @Input() public user: User | null = null;
  @Input() public pageSize = 20;

  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;
  public emojiTranscoder = emojiTranscoder;

  protected filtersObs$: any = null;
  public lastFilters: AnalyticsQuerySession;

  public keyword = "";
  public searching = false;
  public loading = true;
  public isUsersLoading = true;

  public error: Error;
  public pageIndex = 1;
  public maxOffset = 0;
  public sessions: UserRecord[] = [];
  public suggestedSessionIDs: string[] = [null, null];
  public users: Record<string, AnalyticsResponseItemUser> = {};

  public isSummaryLoading = true;
  public summaryTrimLength = 450;
  public summary: string | null = null;
  public summaryError: Error;
  public viewMoreSummary = false;

  public tagsLoading = false;
  public sessionTags: string[] = [];
  public filteredTags: string[] = null;

  constructor(
    public uiService: UIService,
    private analyticsDao: AnalyticsDao,
    public analyticsFilterService: AnalyticsFilterService,
    private userRecorDao: UserRecordDao,
  ) {}

  private sort?: SortForSession = {
    field: "session.started_at",
    order: "desc",
  };

  public listOfColumns: ColumnItem[] = [
    {
      name: "",
      width: "88px",
      align: "center",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: true,
    },
    {
      name: "Highlights",
      width: "275px",
      align: "left",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: true,
    },
    {
      name: "User",
      width: "200px",
      align: "left",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: [],
      filterMultiple: false,
    },
    {
      name: "Duration",
      width: "150px",
      sortKey: "session.duration",
      align: "left",
      sortOrder: "",
      sortFn: true,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Date and device",
      width: "150px",
      sortKey: "session.started_at",
      align: "left",
      sortOrder: "descend",
      sortFn: true,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
    {
      name: "Pertinence",
      width: "75px",
      sortKey: "session.score",
      align: "left",
      sortOrder: "",
      sortFn: true,
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
    },
  ];

  ngOnInit() {
    if (this.user) {
      this.listOfColumns = this.listOfColumns.filter((c) => c.name !== "User");
    }

    this.resetFilters();
  }

  ngOnChanges({ org }: SimpleChanges) {
    if (
      org &&
      org.currentValue?.id &&
      org.currentValue?.id !== org.previousValue?.id
    ) {
      this.resetFilters();
    }
  }

  ngOnDestroy() {
    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  private resetFilters() {
    this.analyticsFilterService.reset(
      "session",
      this.org.id,
      [],
      this.org.created_at,
      this.org.created_at,
    );

    this.filtersObs$?.unsubscribe();
    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQuerySession) => {
        this.lastFilters = deepCopy(filters);

        this.getSessions();
      });
  }

  // Fetch session tags when nz-select is opened
  public onFetchTags() {
    if (this.sessionTags.length) {
      return;
    }

    this.tagsLoading = true;
    this.userRecorDao
      .getTags(this.org.id)
      .then((tags) => {
        this.sessionTags = tags;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.tagsLoading = false;
      });
  }

  public onQueryParamsChange(params: NzTableQueryParams) {
    let { pageIndex } = params;
    const { pageSize } = params;
    const { key, value } = params.sort.find(({ value }) => Boolean(value));

    const sort = {
      field: key as SortForSession["field"],
      order: this.adaptSortDirection(value),
    };

    if (pageSize !== this.pageSize) {
      pageIndex = 1;
    }

    const pageChange =
      pageIndex !== this.pageIndex || pageSize !== this.pageSize;
    const sortChange =
      sort.field !== this.sort.field || sort.order !== this.sort.order;

    if (!pageChange && !sortChange) return;

    this.sort = sort;
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;

    this.getSessions();
  }

  private adaptSortDirection(value: string): SortOrder {
    switch (value) {
      case "descend":
        return "desc";
      case "ascend":
      default:
        return "asc";
    }
  }

  @Debounce(250)
  private getSessions() {
    this.loading = true;
    this.error = null;

    const query = deepCopy(this.lastFilters) as AnalyticsQuerySession;
    query.range.start = this.org.created_at;
    query.range.end = new Date();
    query.size = this.pageSize;
    query.offset = (this.pageIndex - 1) * this.pageSize;
    query.sort = deepCopy(this.sort);

    if (this.keyword?.length) {
      query.filters.push({
        type: "session",
        key: "summary",
        operator: "contains",
        value: this.keyword,
      });
    }

    if (this.user?.id) {
      query.filters.push({
        type: "session",
        key: "user_id",
        operator: "eq",
        value: this.user.id,
      });
    }

    if (this.filteredTags?.length) {
      query.filters.push({
        type: "session",
        key: "tags",
        operator: "intersect",
        values: this.filteredTags,
      });
    }

    // Avoid fetching suggested sessions if already fetched
    const suggestedIds = this.suggestedSessionIDs.filter((d) => d);
    query.with_suggested = !suggestedIds.length || this.pageIndex === 1;
    if (query.with_suggested) {
      this.suggestedSessionIDs = [null, null];
    }

    return this.analyticsDao
      .search(query)
      .then((res) => {
        this.maxOffset = res?.hits?.total || 0;
        this.sessions = res?.hits?.sessions || [];
        if (query.with_suggested) {
          this.suggestedSessionIDs = res?.hits?.suggested_session_ids || [];
        }
        this.loading = false;

        const userIDs = this.sessions.map((r) => r.user_id);

        this.getUsersByIds(userIDs);

        if (this.summary === null || this.pageIndex === 1) {
          this.getSummary();
        }
      })
      .catch((error) => {
        // this.errorRecords = error;
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private async getUsersByIds(ids: string[]) {
    // Filters ids already fetched
    const idsToFetch = Array.from(new Set(ids)).filter((id) => !this.users[id]);
    if (idsToFetch.length === 0) {
      return;
    }

    this.isUsersLoading = true;
    const query: AnalyticsQueryUsers = {
      org_id: UUID(this.org.id),
      survey_ids: ["*"],
      filters_bool: "OR",
      type: "respondent",
      filters: idsToFetch.map((id) => ({
        type: "respondent",
        key: "aliases",
        operator: "eq",
        value: id,
      })),
      range: {
        start: this.org.created_at,
        end: new Date(),
        field: "created_at",
      },
      identified_only: false,
      size: idsToFetch.length,
      with_total: false,
    };
    const response = await this.analyticsDao.search(query);
    for (const user of response.hits.respondents ?? []) {
      this.users[user.id] = user;
    }
    this.isUsersLoading = false;
  }

  public onSearchChange() {
    this.getSessions();
  }

  public onTagsFilterChange(tags: string[]) {
    this.filteredTags = tags;

    this.getSessions();
  }

  public trackByFn(index: number, _: UserRecord) {
    return index;
  }

  public getSummary() {
    this.summaryError = null;

    const ids = this.suggestedSessionIDs.filter((d) => d);

    if (!ids.length) {
      this.isSummaryLoading = false;
      this.summary = null;
      return;
    }

    this.isSummaryLoading = true;
    this.userRecorDao
      .getSummary(this.org.id, ids)
      .then((summary) => {
        this.summary = summary;
      })
      .catch((error) => {
        this.summaryError = error;
      })
      .finally(() => {
        this.isSummaryLoading = false;
      });
  }

  public duration(seconds: number) {
    return intervalToDuration({ start: 0, end: seconds * 1000 });
  }

  public getComment(session: UserRecord, id: string) {
    return session.comments.find((h) => h.id === id);
  }
}
