import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { NotificationHelper } from "helpers/notification.helper";
import { SurveyDistributionDao } from "models/survey-distribution.dao";
import {
  SurveyDistribution,
  SurveyDistributionStatus,
} from "models/survey-distribution.model";
import { EntitlementService } from "services/entitlement.service";
import { UIService } from "services/ui.service";
import { NgIf, NgFor } from "@angular/common";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { ScreebIconComponent } from "../../../../../utils/screeb-icon/screeb-icon.component";
import { NzModalComponent, NzModalContentDirective } from "ng-zorro-antd/modal";
import { TrackByPropertyDirective } from "../../../../../../helpers/track-by-property";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { PermissionPipe } from "pipes/permission.pipe";
import { EntitlementPipe } from "pipes/entitlement.pipe";

@Component({
  selector: "survey-distributions-status",
  templateUrl: "./survey-distributions-status.component.html",
  styleUrls: ["./survey-distributions-status.component.scss"],
  imports: [
    NgIf,
    NzTooltipDirective,
    ScreebIconComponent,
    NgFor,
    NzModalComponent,
    NzModalContentDirective,
    TrackByPropertyDirective,
    NzPopoverDirective,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    NzButtonComponent,
    RouterLink,
    PermissionPipe,
    EntitlementPipe,
  ],
})
export class SurveyDistributionsStatusComponent implements OnInit {
  @Input() public title: string = "";
  @Input() public distributions: SurveyDistribution[] = [];

  public haveOneWithTestMode: boolean = false;
  public commonStatus: SurveyDistributionStatus = "stopped";
  public commonStatusLabel: string = "Stopped";
  public runningDistributions: SurveyDistribution[] = [];
  public distributionStatusLoading: { [key: string]: boolean } = {};

  public isModalVisible = false;

  constructor(
    public router: Router,
    private surveyDistributionDao: SurveyDistributionDao,
    public uiService: UIService,
    private notificationHelper: NotificationHelper,
    private entitlementService: EntitlementService,
  ) {}

  ngOnInit() {
    this.setupCommonStatus();
  }

  private setupCommonStatus(): void {
    this.haveOneWithTestMode = this.distributions.some((d) => d.isTestMode);
    this.runningDistributions = this.distributions.filter(
      (d) => d.status === "running" || d.status === "programmed",
    );

    const haveOneRunning = this.runningDistributions.length > 0;
    const haveOneScheduled =
      !haveOneRunning &&
      this.distributions.some((d) => d.status === "programmed");
    const haveOnePaused =
      !haveOneRunning &&
      !haveOneScheduled &&
      this.distributions.some((d) => d.status === "paused");

    if (haveOneRunning) {
      this.commonStatus = "running";
      this.commonStatusLabel = "Running";
    } else if (haveOneScheduled) {
      this.commonStatus = "programmed";
      this.commonStatusLabel = "Scheduled";
    } else if (haveOnePaused) {
      this.commonStatus = "paused";
      this.commonStatusLabel = "Paused";
    } else {
      this.commonStatus = "stopped";
      this.commonStatusLabel = "Stopped";
    }
  }

  public handleOpen(): void {
    if (!this.entitlementService.isAvailable("survey_edition")) return;
    this.isModalVisible = true;
  }

  public handleClose(): void {
    this.isModalVisible = false;
  }

  public onSurveyStatusChange(
    event: Event,
    distrib: SurveyDistribution,
    enable: boolean,
  ) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    if (!this.entitlementService.isAvailable("survey_edition")) return;

    this.distributionStatusLoading[distrib.id] = true;

    this.surveyDistributionDao
      .updateStatus(
        this.uiService.currentOrg.id,
        distrib.survey_id,
        distrib.id,
        enable,
        distrib.selector_css,
      )
      .then((res: SurveyDistribution) => {
        distrib.enabled = res.enabled;
        this.distributions = this.distributions.map((d) => {
          if (d.id === distrib.id) {
            d.enabled = res.enabled;
            return d;
          }
          return d;
        });
        this.setupCommonStatus();
      })
      .then(() => this.uiService.fetchEverything())
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      })
      .finally(() => {
        this.distributionStatusLoading[distrib.id] = false;
      });
  }

  public isDistribAvailableInEntitlements(
    distribution: SurveyDistribution,
  ): boolean {
    if (this.uiService.currentSurvey?.type === "message") {
      return this.entitlementService.isAvailable("message_inapp");
    }

    if (
      ["widget", "hosted-page"].includes(distribution.type) &&
      !this.entitlementService.isAvailable("survey_inapp_desktop")
    ) {
      return false;
    }

    if (
      ["android", "ios"].includes(distribution.type) &&
      !this.entitlementService.isAvailable("survey_inapp_mobile")
    ) {
      return false;
    }

    return true;
  }

  public goToBilling() {
    this.router.navigate([
      "/overview/" + this.uiService.currentSuperOrgId + "/billing",
    ]);
  }
}
