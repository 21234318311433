import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from "@angular/core";
import { NgTemplateOutlet, NgIf } from "@angular/common";
import { ɵEmptyOutletComponent } from "@angular/router";

@Component({
  selector: "read-more",
  templateUrl: "./read-more.component.html",
  styleUrls: ["./read-more.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet, ɵEmptyOutletComponent, NgIf],
})
export class ReadMoreComponent {
  @Input()
  public header: TemplateRef<any> | string;

  @Input()
  public content: TemplateRef<any> | string;

  @Input()
  public closedHeight: number = 100;

  @Input()
  public loading: boolean = false;

  public readModeOpen = false;

  constructor() {}
}
