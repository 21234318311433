import { Component, Input, OnInit } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";
import { onOrRuleChange } from "components/surveys/pages/share/targeting/advanced/components/utils/rule-change";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { TargetingRuleOrNeitherComponent } from "../rule-or-neither/rule-or-neither.component";
import { NzInputDirective } from "ng-zorro-antd/input";
import { FormsModule } from "@angular/forms";
import { EntitlementPipe } from "pipes/entitlement.pipe";

const REGEXP_VALIDATION_URL =
  /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}(:\d*)?\b([-a-zA-Z0-9@:%_\+.~#?&//=!]*)$/g;

@Component({
  selector: "rule-url",
  templateUrl: "./rule-url.component.html",
  styleUrls: ["./rule-url.component.scss"],
  imports: [
    NgIf,
    NgFor,
    TargetingRuleOrNeitherComponent,
    NzInputDirective,
    FormsModule,
    NgClass,
    EntitlementPipe,
  ],
})
export class TargetingRuleUrlComponent implements OnInit {
  @Input() public rule: TargetingRule = null;

  public onOrRuleChange = onOrRuleChange;

  constructor() {}

  ngOnInit() {
    const last = this.rule.value.v_s_arr.length - 1;
    if (this.rule.value.v_s_arr[last].length > 0) {
      this.rule.value.v_s_arr.push("");
    }
  }

  public checkValidUrlRule(r: TargetingRule, index: number): boolean {
    if (index > 0 && r.value.v_s_arr[index].trim().length === 0) return true;

    if (["equal", "not equal"].includes(r.operator)) {
      return !!r.value.v_s_arr[index]?.match(REGEXP_VALIDATION_URL);
    } else if (r.operator === "regex") {
      // check that the regex is valid
      try {
        new RegExp(r.value.v_s_arr[index]);
      } catch (e) {
        console.error(e);
        return false;
      }
    }

    return true;
  }

  public trackByFn(index: any) {
    return index;
  }
}
