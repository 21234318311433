import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { UIService } from "services/ui.service";

@Component({
  selector: "page-survey-stats",
  template: "",
})
export class StatsSurveyPageComponent implements OnInit {
  private obs: any = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public uiService: UIService,
  ) {}

  public ngOnInit() {
    const orgId = this.route.snapshot.params["org_id"];
    const surveyId = this.route.snapshot.params["survey_id"];
    this.router.navigate(
      [
        "org",
        orgId,
        this.uiService.surveyTypePath,
        surveyId,
        "stats",
        "overall-performances",
      ],
      { replaceUrl: true },
    );
  }
}
