<div class="free-text-block-container" [ngClass]="editing ? 'editing' : ''">
  <div class="free-text-block-container-editing" *ngIf="editing">
    <markdown-editor
      placeholder="Enter your text"
      [(value)]="text"
      (change)="textChange.emit($event.detail)"
      class="free-text-block-container-input"
    >
      <div class="free-text-block-container-text" slot="render">
        <sanitized-message-component
          [content]="text"
          [options]="{
            links: true,
            styling: true,
            emojiTranscoder: true,
            CR: true,
          }"
        ></sanitized-message-component>
      </div>
    </markdown-editor>
  </div>
  <div class="free-text-block-container-text" *ngIf="!editing" slot="render">
    <sanitized-message-component
      [content]="text"
      [options]="{
        links: true,
        styling: true,
        emojiTranscoder: true,
        CR: true,
      }"
    ></sanitized-message-component>
  </div>
</div>
