import { Component, Input } from "@angular/core";
import { formatNumber, NumberFormatType } from "utils/number";
import { NgIf, NgClass } from "@angular/common";

@Component({
  selector: "indicator-variation",
  template: `
    <span
      class="indicator-variation"
      *ngIf="variation !== null && variation !== undefined"
      [ngClass]="getDiffColorClass()"
    >
      <i *ngIf="round(variation) !== 0"></i>
      <i *ngIf="round(variation) === 0">=</i>

      {{ isFinite(variation) ? getFormattedDiff() : "inf." }}
    </span>
  `,
  styles: [
    `
      .indicator-variation {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
      }

      .grey {
        color: var(--screeb-color-grey-700);
        fill: var(--screeb-color-grey-700);
      }

      .green {
        color: var(--screeb-color-success-active);
        fill: var(--screeb-color-success-active);
      }

      .red {
        color: var(--screeb-color-error);
        fill: var(--screeb-color-error);
      }
    `,
  ],
  imports: [NgIf, NgClass],
})
export class IndicatorVariationComponent {
  @Input() public variation = 0;
  @Input() public format: NumberFormatType = "percent";
  @Input() public sign: "auto" | "force" | "hide" = "auto";
  @Input() public maximumFractionDigits = 0;

  public getFormattedDiff() {
    return formatNumber(
      this.variation,
      this.format,
      this.maximumFractionDigits,
      this.sign,
    );
  }

  public isFinite(nbr: number): boolean {
    return isFinite(nbr);
  }

  public round(nbr: number): number {
    return Math.round(nbr);
  }

  public getDiffColorClass(): string {
    if (Math.round(this.variation) > 0) {
      return "green";
    } else if (Math.round(this.variation) < 0) {
      return "red";
    }
    return "grey";
  }
}
