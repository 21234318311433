import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
} from "@angular/router";
import { ApplicationLoadService } from "services/app.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";

@Injectable()
export class CanCreateSuperOrgGuard implements CanActivate, CanActivateChild {
  constructor(
    private applicationLoadService: ApplicationLoadService,
    private featureFlaggingService: FeatureFlaggingService,
  ) {}

  canActivate(_: ActivatedRouteSnapshot): Promise<boolean> {
    return this.applicationLoadService.loader().then(async () => {
      return this.featureFlaggingService.canCreateSuperOrg();
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.canActivate(route);
  }
}
