import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GraphNode } from "components/builder/flow";
import { AdvancedTargetingShareSurveyComponent } from "components/surveys/pages/share/targeting/advanced/advanced.component";
import { Org } from "models/org.model";
import { RegistryEntry } from "models/registry.model";
import { Survey } from "models/survey.model";
import { TargetingRule } from "models/targeting-rule.model";
import { LateralPanelStepPickerComponent } from "../step-picker/step-picker.component";
import { NgFor } from "@angular/common";
import { SanitizedMessageComponent } from "../../Cards/sanitized-message/sanitized-message.component";

@Component({
  selector: "lateral-panel-edit-conditional",
  templateUrl: "./edit-conditional.component.html",
  styleUrls: ["./edit-conditional.component.scss"],
  imports: [
    AdvancedTargetingShareSurveyComponent,
    LateralPanelStepPickerComponent,
    NgFor,
    SanitizedMessageComponent,
  ],
})
export class LateralPanelEditConditionalComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() public org: Org;
  @Input() public surveys: Survey[];
  @Input() public registryEntriesIdentityProperty: RegistryEntry[];
  @Input() public registryEntriesGroup: RegistryEntry[];
  @Input() public registryEntriesEvent: RegistryEntry[];

  @Output() public errorChange = new EventEmitter<boolean>();

  constructor() {}

  public ngOnInit() {
    this.validateData();

    if (
      this.node.node.question.call_to_action.type === "conditional" &&
      this.node.node.question.call_to_action.conditional.rules.length === 0
    ) {
      this.node.node.question.call_to_action.conditional.rules.push(
        new TargetingRule(),
      );
    }
  }

  public onRulesChange(rules: TargetingRule[]) {
    if (this.node.node.question.call_to_action.type === "conditional") {
      this.node.node.question.call_to_action.conditional.rules = rules;
      this.updateDescription(rules);
    }
  }

  updateDescription(rules: TargetingRule[]) {
    const description = rules.length
      ? `${rules.length} rule${rules.length === 1 ? "" : "s"} defined:\n - ${rules.map((rule) => rule.getTitle()).join("\n - ")}`
      : "⚠️ No rules defined";

    this.node.description = description;
    this.node.node.question.description = description;
  }

  /**
   * Data validation
   */
  public validateData() {
    this.errorChange.emit(false);
  }
}
