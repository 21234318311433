import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { Release } from "models/release.model";
import { ReleaseDao } from "models/release.dao";
import { Org } from "models/org.model";
import { NotificationHelper } from "helpers/notification.helper";
import { NzModalComponent } from "ng-zorro-antd/modal";
import { FormsModule } from "@angular/forms";
import {
  NzFormDirective,
  NzFormItemComponent,
  NzFormLabelComponent,
  NzFormControlComponent,
} from "ng-zorro-antd/form";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { NzInputDirective } from "ng-zorro-antd/input";
import { ScreebDatePickerComponent } from "../../utils/date-picker/screeb-date-picker.component";
import { NzSelectComponent, NzOptionComponent } from "ng-zorro-antd/select";
import { NgFor, NgIf } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";

type ReleaseCreateFormValidator = {
  name: "error" | "";
  description: "error" | "";
  version: "error" | "";
  date: "error" | "";
};

@Component({
  selector: "release-create-modal",
  templateUrl: "./release-create.component.html",
  styleUrls: ["./release-create.component.scss"],
  imports: [
    NzModalComponent,
    FormsModule,
    NzFormDirective,
    NzRowDirective,
    NzFormItemComponent,
    NzColDirective,
    NzFormLabelComponent,
    NzFormControlComponent,
    NzInputDirective,
    ScreebDatePickerComponent,
    NzSelectComponent,
    NgFor,
    NzOptionComponent,
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
  ],
})
export class ReleaseCreateModalComponent implements OnChanges {
  isConfirmLoading = false;
  formValidator: ReleaseCreateFormValidator = {
    name: "",
    description: "",
    version: "",
    date: "",
  };

  @Input() isOpen: boolean = false;
  @Input() canCancel: boolean = true;
  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter();
  @Output() releaseSubmitted: EventEmitter<Release> = new EventEmitter();
  @Input() release: Release = null;
  @Input() tagOptions: string[] = [];
  @Input() org: Org = null;

  private savedRelease: Release = null;

  constructor(
    private releaseDao: ReleaseDao,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnChanges() {
    if (this.release === null || !this.release.id) {
      this.release = new Release().fromJson({
        name: "",
        version: "",
        description: "",
        released_at: new Date(),
        tags: [],
      });
    }

    this.savedRelease = new Release().fromJson(this.release);
  }

  private validateTextInput(input?: string, minLength: number = -1) {
    if (!input) return "error";
    return minLength < 0 || input.length >= minLength ? "" : "error";
  }

  private validateForm() {
    this.formValidator = {
      name: this.validateTextInput(this.release.name, 1),
      description: this.validateTextInput(this.release.description),
      version: this.validateTextInput(this.release.version, 1),
      date: this.release.released_at ? "" : "error",
    };

    return !Object.values(this.formValidator).find((v) => v === "error");
  }

  isFormValid(): boolean {
    return (
      Object.keys(this.release).filter((key) => {
        if (Array.isArray(this.release[key])) {
          return (
            this.release[key].toString() !== this.savedRelease[key].toString()
          );
        }
        return this.release[key] !== this.savedRelease[key];
      }).length > 0
    );
  }

  public onDateValueChanged(value: Date) {
    this.release.released_at = value;
  }

  private createRelease() {
    this.isConfirmLoading = true;

    this.releaseDao
      .create(
        this.org.id,
        this.release.name,
        this.release.description,
        this.release.version,
        this.release.tags,
        this.release.released_at,
      )
      .then((release) => {
        this.notificationHelper.trigger("Release Created", null, "success");
        this.isOpen = false;
        this.releaseSubmitted.emit(release);
        this.modalOpenChange.emit(this.isOpen);
      })
      .catch((err) => {
        console.error(err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      })
      .finally(() => {
        this.isConfirmLoading = false;
      });
  }

  private updateRelease() {
    this.isConfirmLoading = true;

    this.releaseDao
      .update(
        this.org.id,
        this.release.id,
        this.release.name,
        this.release.description,
        this.release.version,
        this.release.tags,
        this.release.released_at,
      )
      .then((release) => {
        this.notificationHelper.trigger("Release Updated", null, "success");
        this.isOpen = false;
        this.releaseSubmitted.emit(release);
        this.modalOpenChange.emit(this.isOpen);
      })
      .catch((err) => {
        console.error(err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      })
      .finally(() => {
        this.isConfirmLoading = false;
      });
  }

  onSubmit(): void {
    if (!this.validateForm()) return;

    if (this.release.id) {
      this.updateRelease();
    } else {
      this.createRelease();
    }
  }

  handleCancel(): void {
    if (!this.canCancel) return;

    this.isOpen = false;
    this.modalOpenChange.emit(this.isOpen);
    this.isConfirmLoading = false;
  }
}
