import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Org } from "models/org.model";
import { OrgFlagsHomeGridRange } from "models/org.model";
import { CTAType } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { NgClass, NgIf } from "@angular/common";
import { ScreebIconComponent } from "../../utils/screeb-icon/screeb-icon.component";
import { WidgetGraphComponent } from "./graph/graph.component";
import { WidgetActivityComponent } from "./activity/activity.component";
import { WidgetEmotionsComponent } from "./emotions/emotions.component";
import { WidgetDeviceTypeComponent } from "./device-type/device-type.component";
import { WidgetIdentificationComponent } from "./identification/identification.component";
import { WidgetChangelogComponent } from "./changelog/changelog.component";
import { WidgetLastSurveysComponent } from "./last-surveys/last-surveys.component";
import { WidgetMAUUsageComponent } from "./mtu-usage/mau-usage.component";
import { WidgetQuoteOfTheDayComponent } from "./quote-of-the-day/quote-of-the-day.component";
import { HelpHomeComponent } from "./help/help.component";
import { FreeTextBlockComponent } from "../../utils/free-text-block/free-text-block.component";
import { subDays } from "date-fns/esm";

@Component({
  selector: "widget-wrapper",
  templateUrl: "./widget-wrapper.component.html",
  styleUrls: ["./widget-wrapper.component.scss"],
  imports: [
    NgClass,
    NgIf,
    ScreebIconComponent,
    WidgetGraphComponent,
    WidgetActivityComponent,
    WidgetEmotionsComponent,
    WidgetDeviceTypeComponent,
    WidgetIdentificationComponent,
    WidgetChangelogComponent,
    WidgetLastSurveysComponent,
    WidgetMAUUsageComponent,
    WidgetQuoteOfTheDayComponent,
    HelpHomeComponent,
    FreeTextBlockComponent,
  ],
})
export class WidgetWrapperComponent implements OnChanges {
  @Output() deleteClicked = new EventEmitter<CTAType>();

  @Input() range: OrgFlagsHomeGridRange;
  @Input() editing: boolean;
  @Input() widget: {
    id: string;
    type: string;
    w: string;
    h: string;
    text: string | undefined;
  };

  @Input() org: Org;
  @Input() orgHasRespondents: boolean;
  @Input() surveys: Survey[];

  @Output() widgetChange: EventEmitter<{
    id: string;
    type: string;
    w: string;
    h: string;
    text: string | undefined;
  }> = new EventEmitter();

  public startDate: Date;
  public endDate: Date;

  constructor() {
    this.setupDates();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.range?.currentValue !== changes.range?.previousValue) {
      this.setupDates();
    }
  }

  private setupDates() {
    switch (this.range) {
      case "day":
        // 24 hours back
        this.endDate = new Date();
        this.startDate = subDays(this.endDate, 1);
        break;
      case "week":
        // 7 days back
        this.endDate = new Date();
        this.startDate = subDays(this.endDate, 7);
        break;

      case "year":
        // 365 days back
        this.endDate = new Date();
        this.startDate = subDays(this.endDate, 365);
        break;
      case "quarter":
        // 90 days back
        this.endDate = new Date();
        this.startDate = subDays(this.endDate, 90);
        break;
      case "month":
      default:
        // 30 days back
        this.endDate = new Date();
        this.startDate = subDays(this.endDate, 30);
        break;
    }
  }
}
