/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { integrationUpdate } from "components/integration/settings/integration-settings.component";

import { autoTips } from "helpers/form-errors.helper";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";
import { NgIf } from "@angular/common";
import { IntegrationSettingsOAuthComponent } from "../../common/oauth/integration-settings-oauth.component";

@Component({
  selector: "integration-settings-notion",
  templateUrl: "./notion-settings.component.html",
  styleUrls: ["./notion-settings.component.scss"],
  imports: [NgIf, IntegrationSettingsOAuthComponent],
})
export class IntegrationSettingsNotionComponent implements OnInit {
  @Input() public org: Org = null;
  @Input() public integration: Integration = null;

  @Output() public valid = new EventEmitter<boolean>();
  @Output("onSettingsChange") public settings =
    new EventEmitter<integrationUpdate>();

  public validateForm: FormGroup = null;
  public autoTips = autoTips;

  constructor() {}

  ngOnInit(): void {}

  public validateSettings() {}
}
