import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import {
  CardContainer,
  CardContainerType,
} from "components/builder/components/BuilderLayout/Models";
import { Column } from "components/builder/page-builder.component";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import { AnalyticsResponse } from "models/analytics.model";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { RoutingService } from "services/routing.service";
import { TrackersService } from "services/trackers.service";
import { LanguageWithEmoji, UIService } from "services/ui.service";
import { BuilderStore } from "stores/builder.store";
import { formatNumber } from "utils/number";
import { deepCopy } from "utils/object";
import { IndicatorStatsSurveyPageComponent } from "../indicators/indicator.component";
import { SimpleBucket, computePercentage } from "../indicators/indicator.utils";
import {
  OverallPerformancesAggregation,
  getAggregations,
  getResponseCallback,
} from "../survey-analysis/survey-analysis.aggregation";
import { FiltersStatsSurveyComponent } from "../filters/filters.component";
import { NzRowDirective, NzColDirective } from "ng-zorro-antd/grid";
import { SingleIndicatorStatsSurveyComponent } from "../indicators/components/single-indicator/single-indicator.component";
import { NgFor, NgIf } from "@angular/common";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import { BuilderLayoutComponent } from "../../../../builder/components/BuilderLayout/BuilderLayout.component";
import { DraggableZoneDirective } from "../../../../builder/draggable-zone.directive";

type FlowAnalysisStep = {
  responsesCount: number;
  color: string;
  variation?: number;
};

@Component({
  selector: "page-survey-stats-survey-breakdown",
  templateUrl: "./survey-breakdown.component.html",
  styleUrls: ["./survey-breakdown.component.scss"],
  imports: [
    FiltersStatsSurveyComponent,
    NzRowDirective,
    NzColDirective,
    SingleIndicatorStatsSurveyComponent,
    NgFor,
    NgIf,
    NzSpinComponent,
    BuilderLayoutComponent,
    DraggableZoneDirective,
  ],
})
export class SurveyBreakdownStatsSurveyPageComponent
  extends IndicatorStatsSurveyPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Analyze - Survey breakdown";
  public name = "Analyze survey breakdown";

  public overallPerformancesPerDateCurrentPeriod: OverallPerformancesAggregation[] =
    null;
  public overallPerformancesCurrentPeriod: OverallPerformancesAggregation =
    null;
  public overallPerformancesVariation: OverallPerformancesAggregation = null;

  public columnsWithCards: Column[] = [];
  public flattenCards: CardContainer[] = [];

  public legendSteps: FlowAnalysisStep[] = [];

  public loadingGraph = false;

  public formatNumber = formatNumber;

  public languages: LanguageWithEmoji[] = [];

  @ViewChild("legendStepsContainer") legendStepsContainer: ElementRef;
  @ViewChild("topIndicatorsContainer") topIndicatorsContainer: ElementRef;

  constructor(
    router: Router,
    route: ActivatedRoute,
    routingService: RoutingService,
    analyticsFilterService: AnalyticsFilterService,
    trackersService: TrackersService,
    analyticsDao: AnalyticsDao,
    public uiService: UIService,
    public builderStore: BuilderStore,
  ) {
    super(
      router,
      route,
      routingService,
      analyticsDao,
      analyticsFilterService,
      trackersService,
      uiService,
    );
  }

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.loadingGraph = true;

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.survey = data.survey;
      this.languages =
        this.uiService.languagesAndCountries.surveyLanguagesWithEmojis;

      this.builderStore.initBuilder(
        this.org,
        this.survey,
        [],
        this.languages,
        [],
        () => undefined,
        true,
        true,
      );

      this.init();
      this.resetFilters();

      this.filtersObs$ = this.analyticsFilterService
        .subscribe()
        .subscribe((filters: AnalyticsQueryResponse) => {
          this.lastFilters = deepCopy(filters);
          this.getPaths();
        });
    });
  }

  ngOnDestroy() {
    this.destroy();
    this.builderStore.resetBuilder();

    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  isBasedOnNodes() {
    return false;
  }

  refreshCards(
    answersPath: SimpleBucket[] = [],
    questionsPath: SimpleBucket[] = [],
  ) {
    const cards = this.builderStore.refreshCards(answersPath, questionsPath);
    if (!cards) {
      this.loadingGraph = false;
      return;
    }

    this.columnsWithCards = cards.columnsWithCards;
    this.flattenCards = cards.flattenCards;
    this.legendSteps = this.computeLegendSteps(this.columnsWithCards);

    this.loadingGraph = false;
  }

  computeLegendStepColor(variation: number) {
    const style = window.getComputedStyle(document.body);
    if (variation >= 60) {
      return style.getPropertyValue("--screeb-color-success-outline");
    } else if (variation >= 40) {
      return style.getPropertyValue("--screeb-color-warning-outline");
    } else {
      return style.getPropertyValue("--screeb-color-error-outline");
    }
  }

  computeLegendSteps(columnsWithCards: Column[]): FlowAnalysisStep[] {
    return columnsWithCards
      .map((columnWithCards) => {
        return {
          responsesCount: columnWithCards.cards.reduce(
            (responseCount, card) => {
              switch (card.component) {
                case CardContainerType.BigCard:
                  return responseCount + card.pathCount;
                default:
                  return responseCount;
              }
            },
            0,
          ),
          variation: 0,
          color: "transparent",
        };
      })
      .map((step, index, steps) => {
        const variation = steps[index + 1]
          ? computePercentage(
              steps[index + 1].responsesCount,
              step.responsesCount,
            )
          : undefined;

        return {
          ...step,
          variation: variation,
          color: this.computeLegendStepColor(variation ?? 0),
        };
      });
  }

  public getQuery() {
    const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;

    query.aggregation = [{ by: "by_path" }];
    query.size = 0;

    return query;
  }

  public async getPaths() {
    const query = this.getQuery();
    query.with_total = false;
    query.with_inserted = false;
    query.with_not_started = true;
    query.having_answer_only = true;

    const {
      aggregations: {
        answers_path: { buckets: answersPath },
        questions_path: { buckets: questionsPath },
      },
    } = await this.analyticsDao.search(query);

    this.refreshCards(answersPath, questionsPath);
  }

  protected buildCurrentPeriodQuery(): AnalyticsQueryResponse {
    return getAggregations(deepCopy(this.lastFilters), this.getNbrDateBucket());
  }

  async getResponseCallback(
    previousPeriod: AnalyticsResponse | null,
    currentPeriod: AnalyticsResponse | null,
    allTimePeriod: AnalyticsResponse | null,
  ) {
    const {
      overallPerformancesPerDateCurrentPeriod,
      overallPerformancesCurrentPeriod,
      overallPerformancesVariation,
    } = await getResponseCallback(
      previousPeriod,
      currentPeriod,
      allTimePeriod,
      0,
    );

    this.overallPerformancesPerDateCurrentPeriod =
      overallPerformancesPerDateCurrentPeriod;
    this.overallPerformancesCurrentPeriod = overallPerformancesCurrentPeriod;
    this.overallPerformancesVariation = overallPerformancesVariation;
  }

  shouldDisplayVariation(variation: number) {
    return !isNaN(variation) && variation !== undefined && variation !== null;
  }

  onBuilderScroll($event: { x: number; y: number }) {
    this.legendStepsContainer.nativeElement.style.transform = `translate(${-$event.x}px)`;
    this.topIndicatorsContainer.nativeElement.style.height = `${Math.max(
      0,
      110 - $event.y,
    )}px`;
  }
}
