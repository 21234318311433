<div
  [class]="withPaddingTop ? ' lateral-panel-padding-top' : ''"
  class="lateral-panel"
  cdkScrollable
>
  <div class="lateral-panel-inner" cdkScrollable>
    <header class="lateral-header">
      <div class="left">
        <ng-content select=".lateral-header-left"></ng-content>
      </div>
      <div class="right">
        <ng-content select=".lateral-header-right"></ng-content>
      </div>
    </header>

    <div class="lateral-body">
      <ng-content select=".lateral-body"></ng-content>

      <footer class="panel-button-container">
        <div class="left group">
          <ng-content select=".lateral-footer-left"></ng-content>
        </div>
        <div class="right group">
          <ng-content select=".lateral-footer-right"></ng-content>
        </div>
      </footer>
    </div>
  </div>
</div>

<!-- transparent overlay -->
<div class="lateral-panel-backdrop" (click)="onClose.emit($event)"></div>
