<div
  class="cols indicator-container {{ size }} {{ theme }} {{
    imageSrc?.length ? 'have-img' : ''
  }}"
>
  <div class="col col-1">
    <div *ngIf="title" class="row-1">
      <span class="title" [ngClass]="{ 'title-bold': boldTitle }">
        <sanitized-message-component
          [content]="title"
          [options]="{
            styling: true,
            emojiTranscoder: true,
          }"
        ></sanitized-message-component>
      </span>
    </div>
    <text-shimmer
      [lines]="1"
      [fontSize]="size === 'small' ? 16 : size === 'medium' ? 14 : 32"
      *ngIf="loading"
    ></text-shimmer>
    <ng-container *ngIf="!loading">
      <div *ngIf="value !== undefined && value !== null" class="row-2">
        <span class="value" [ngStyle]="{ color: color }">
          {{ formattedValue }}
        </span>
        <span
          *ngIf="loadingDiff"
          class="diff"
          [ngClass]="diffColorClass"
          [ngStyle]="{ color: color }"
        >
          <text-shimmer [lines]="1" [fontSize]="14"></text-shimmer>
        </span>
        <span
          *ngIf="
            diffPercent !== null && diffPercent !== undefined && !loadingDiff
          "
          class="diff"
          nzTooltipTitle="Compared to the previous period"
          nz-tooltip
          [ngClass]="diffColorClass"
          [ngStyle]="{ color: color }"
        >
          <i
            [ngStyle]="{ 'background-color': color }"
            *ngIf="roundedDiffPercent !== 0"
          ></i>
          <i *ngIf="roundedDiffPercent === 0">=</i>

          {{ isFiniteDiff ? formatedDiff : "inf." }}
        </span>
        <span
          *ngIf="
            titleLink !== null &&
            titleLink !== undefined &&
            link !== null &&
            link !== undefined
          "
        >
          <a
            [ngClass]="{ 'title-link-bold': boldLink }"
            routerLink="{{ link }}"
          >
            {{ titleLink }}
          </a>
        </span>
      </div>
      <div *ngIf="value === undefined || value === null" class="row-2">
        <ng-content></ng-content>
      </div>

      <div *ngIf="isStatus" class="row-2 channels">
        <survey-distributions-status [title]="title" [distributions]="value" />
      </div>
    </ng-container>
  </div>

  <div *ngIf="!!imageSrc" class="col col-2" style="margin-left: auto">
    <img [src]="imageSrc" alt="" />
  </div>
  <div
    *ngIf="diffIndustryAverage !== null && diffIndustryAverage !== undefined"
    class="row-2"
    class="industryAverage"
  >
    <span [ngClass]="averageDiffColorClass">
      {{ formattedDiffIndustryAverage }}
    </span>
    <span>compared to your industry</span>
  </div>
</div>
