import { Component, Input } from "@angular/core";
import { TargetingRule } from "models/targeting-rule.model";
import { NgIf, NgClass } from "@angular/common";
import {
  NzInputGroupComponent,
  NzInputGroupWhitSuffixOrPrefixDirective,
} from "ng-zorro-antd/input";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { TargetingRuleInputNumberComponent } from "../rule-input-number/rule-input-number.component";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { EntitlementPipe } from "pipes/entitlement.pipe";

@Component({
  selector: "rule-recurrence",
  templateUrl: "./rule-recurrence.component.html",
  styleUrls: ["./rule-recurrence.component.scss"],
  imports: [
    NgIf,
    NzInputGroupComponent,
    ɵNzTransitionPatchDirective,
    NzInputGroupWhitSuffixOrPrefixDirective,
    NgClass,
    TargetingRuleInputNumberComponent,
    NzIconDirective,
    NzTooltipDirective,
    EntitlementPipe,
  ],
})
export class TargetingRuleRecurrenceComponent {
  @Input()
  public ruleRecurrence: TargetingRule = null;
  @Input()
  public ruleIfAnswered: TargetingRule = null;

  constructor() {}

  public checkValidValue(): boolean {
    if (
      (this.ruleRecurrence?.value?.v_n === null ||
        this.ruleRecurrence?.value?.v_n === undefined) &&
      (this.ruleIfAnswered?.value?.v_n === null ||
        this.ruleIfAnswered?.value?.v_n === undefined)
    ) {
      return false;
    }
    return true;
  }
}
