import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { NzCardComponent } from "ng-zorro-antd/card";

export const RANDOM_MSGS = [
  "Keep calm and collect feedback.",
  "A user interface is like a joke: if you have to explain it, it's not that good.",
  "We all need people who can give us feedback. That's how we improve. — Bill Gates",
  "I just want my customers to be happy. I never really thought about business. — Martin Ohannessian, Cofounder of Le Petit Ballon",
  "The important thing is not to stop questioning. — Albert Einstein",
  'I don\'t wanna "build software" as much as build understanding and express it in software. — Jessica Joy Kerr, Software Developer & Symmathecist',
  "Problems are a feature. They’re the opportunity to see how we can productively move forward. — Seth Godin",
  "The technology that most disrupts established industries is speed. The speed of connection to peers, to suppliers and most of all, to customers. — Seth Godin",
  "It's not anyone else responsability to show us the truth. It's our responsability to find it. We do that by asking good questions. — Rob Fitzpatrick",
  "Focusing on growth can be an unhealthy obsession. Build a tight customer feedback loop instead and the growth will come. — Justin Kan, Twitch Cofounder",
  "The best product managers change their minds as they learn. — Craig Daniel, Former VP Product at Drift",
  "The thing I have noticed is when the anecdotes and the data disagree, the anecdotes are usually right. — Jeff Bezos",
  "A lot of internal debates could be quickly settled with a simple survey.",
  "Asking questions is the daily routine of great PMs. — Artabaz Shams, VP Product at Blablacar",
  "Your most unhappy customers are your greatest source of learning. — Bill Gates",
  "The customer’s perception is your reality. — Kate Zabriskie",
  "Customer feedback is the breakfast of champions. — Ken Blanchard",
  "Customers don't expect you to be perfect. They do expect you to fix things when they go wrong. — Donald Porter",
  "Customer experience better be at the top of your list when it comes to priorities in your organization. Customer experience is the new marketing. — Steve Cannon",
  "We see our customers as invited guests to a party, and we are the hosts. It’s our job every day to make every important aspect of the customer experience a little bit better. — Jeff Bezos",
  "Feedback is the breakfast of champions. — Ken Blanchard",
  "In the world of Internet Customer Service, it’s important to remember your competitor is only one mouse click away. — Doug Warner",
  "The goal as a company is to have customer service that is not just the best but legendary. — Sam Walton",
  "To keep a customer demands as much skill as to win one. — American Proverb",
  "In a world where competition is everywhere, listening to your customers is crucial. — Guillaume Bourdin",
  "Only 1 / 5 respondent gives fournished and detailed enough feedback that can be expoitable, that is why it is important to collect contextual feedback. — as per Forrester Research",
  "Companies spend on average between 10k€ to 50k€ to deploy an insight solution, but it is 50% of the time non-exploitable. — as per McKinsey",
];

@Component({
  selector: "widget-quote-of-the-day",
  templateUrl: "./quote-of-the-day.component.html",
  styleUrls: ["./quote-of-the-day.component.scss"],
  imports: [NzCardComponent],
})
export class WidgetQuoteOfTheDayComponent implements AfterViewInit, OnDestroy {
  public quoteOfTheDay =
    RANDOM_MSGS[new Date().getDate() % RANDOM_MSGS.length].split(" — ");

  private rs: ResizeObserver | null = null;

  constructor() {}

  ngAfterViewInit(): void {
    this.resizeText({
      element: document.querySelector(".qotd-body"),
    });

    // listen for parent container resize
    this.rs = new ResizeObserver(() => {
      this.resizeText({
        element: document.querySelector(".qotd-body"),
      });
    });
    this.rs.observe(document.querySelector(".qotd-container"));
  }

  ngOnDestroy(): void {
    this.rs?.disconnect();
  }

  isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight;

  resizeText = ({
    element,
    elements = null,
    minSize = 10,
    maxSize = 512,
    step = 1,
    unit = "px",
  }) => {
    (elements || [element]).forEach((el) => {
      let i = minSize;
      let overflow = false;

      const parent = el.parentNode;

      while (!overflow && i < maxSize) {
        el.style.fontSize = `${i}${unit}`;
        overflow = this.isOverflown(parent);

        if (!overflow) i += step;
      }

      // revert to last state where no overflow happened
      el.style.fontSize = `${i - step - step}${unit}`;
    });
  };
}
