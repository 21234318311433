import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  ActionSkip,
  CTARange,
  SurveyLanguages,
  getI18nTextLabelTranslation,
  newCTASkip,
  setI18nTextLabelTranslation,
} from "models/survey.dao.types";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";
import { SanitizedMessageComponent } from "../../Cards/sanitized-message/sanitized-message.component";
import { NgIf } from "@angular/common";
import { LateralPanelEmojiPickerPopinComponent } from "../emoji-picker-popin/emoji-picker-popin.component";
import { ScreebIconComponent } from "../../../../utils/screeb-icon/screeb-icon.component";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { FormsModule } from "@angular/forms";
import { LateralPanelStepPickerComponent } from "../step-picker/step-picker.component";
import { RegistryEntry } from "models/registry.model";
import { LateralPanelVariablePickerComponent } from "../variable-picker/variable-picker.component";
import { FeaturePipe } from "pipes/feature.pipe";

@Component({
  selector: "lateral-panel-edit-range",
  templateUrl: "./edit-range.component.html",
  styleUrls: ["./edit-range.component.scss"],
  imports: [
    SanitizedMessageComponent,
    NgIf,
    LateralPanelEmojiPickerPopinComponent,
    ScreebIconComponent,
    NzPopoverDirective,
    FormsModule,
    LateralPanelStepPickerComponent,
    LateralPanelVariablePickerComponent,
    FeaturePipe,
  ],
})
export class LateralPanelEditRangeComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() public language: SurveyLanguages = "en";
  @Input() registryEntriesIdentityProperty: RegistryEntry[] = [];
  @Output() errorChange = new EventEmitter<boolean>();

  public emojiPickerVisible = false;
  public emojiPickerPositionTop = 0;
  public emojiPickerPositionRight = 0;

  public variables: { value: string; label: string }[] = [];

  constructor(
    public builderStore: BuilderStore,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public ngOnInit() {
    if (!["range"].includes(this.node.node.question.call_to_action.type)) {
      throw Error("unexpected action type for range");
    }

    this.validateData();

    this.variables = this.registryEntriesIdentityProperty
      .filter((e) => e.type === "string")
      .map((entry) => ({
        value: entry.slug,
        label: entry.title,
      }));
  }

  public get cta(): CTARange {
    const cta = this.node.node.question.call_to_action;
    if (!["range"].includes(cta.type)) {
      throw Error("unexpected action type for range");
    }
    return cta as CTARange;
  }

  public get skipAction(): ActionSkip {
    return (this.node.node.question.skip_action ??= newCTASkip());
  }

  /**
   * Emoji picker
   */
  public openEmojiPicker(btnElement: HTMLElement) {
    const buttonRect = btnElement.getBoundingClientRect();

    this.emojiPickerPositionTop = buttonRect.top + window.pageYOffset - 20;
    this.emojiPickerPositionRight =
      window.innerWidth - buttonRect.left + window.pageXOffset + 10;
    this.emojiPickerVisible = true;
  }

  public getLabelLegendMin(): string {
    const cta = this.node.node.question.call_to_action as CTARange;
    return decode(
      getI18nTextLabelTranslation(
        cta.legend_min || {},
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelLegendMin(text: string) {
    const cta = this.node.node.question.call_to_action as CTARange;
    cta.legend_min ??= {};
    setI18nTextLabelTranslation(cta.legend_min, text, this.language);
  }

  public getLabelLegendMax(): string {
    const cta = this.node.node.question.call_to_action as CTARange;
    return decode(
      getI18nTextLabelTranslation(
        cta.legend_max || {},
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelLegendMax(text: string) {
    const cta = this.node.node.question.call_to_action as CTARange;
    cta.legend_max ??= {};
    setI18nTextLabelTranslation(cta.legend_max, text, this.language);
  }

  public get variable(): string {
    return (
      (this.node.node.question.call_to_action as any)?.save_to_property || ""
    );
  }

  public set variable(variable: string) {
    (this.node.node.question.call_to_action as any).save_to_property = variable;
  }

  /**
   * Data validation
   */
  public validateData() {
    this.errorChange.emit(false);
  }
}
