import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

import { ENV } from "environment";
import { ConfigService } from "services/config.service";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { TrackersService } from "services/trackers.service";
import { UIService } from "services/ui.service";
import {
  NzTableComponent,
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzCellFixedDirective,
  NzTbodyComponent,
  NzCellAlignDirective,
} from "ng-zorro-antd/table";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NgFor, NgIf } from "@angular/common";
import { NzInputDirective } from "ng-zorro-antd/input";
import { FormsModule } from "@angular/forms";
import { NzIconDirective } from "ng-zorro-antd/icon";

type Parameter = {
  key: string;
  value: string;
};

@Component({
  selector: "url-builder",
  templateUrl: "./url-builder.component.html",
  styleUrls: ["./url-builder.component.scss"],
  imports: [
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzCellFixedDirective,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    NzTbodyComponent,
    NgFor,
    NzCellAlignDirective,
    NzInputDirective,
    FormsModule,
    NgIf,
    NzIconDirective,
  ],
})
export class URLBuilderComponent implements OnInit, OnChanges {
  @Input() public url: string;
  @Input() public baseUrl: string;

  @Output() public urlChange = new EventEmitter<string>();

  public parameters: Parameter[] = [
    {
      key: "respondent_id",
      value: "",
    },
  ];

  constructor(
    public uiService: UIService,
    public trackersService: TrackersService,
    public featureFlaggingService: FeatureFlaggingService,
    public entitlementService: EntitlementService,
    private configService: ConfigService,
  ) {}

  ngOnInit() {
    this.onUpdateParameter();
  }

  ngOnChanges({ baseUrl }: SimpleChanges) {
    if (baseUrl && baseUrl.currentValue !== baseUrl.previousValue) {
      this.baseUrl = baseUrl.currentValue;
      this.parameters = [
        {
          key: "respondent_id",
          value: "",
        },
      ];
      this.onUpdateParameter();
    }
  }

  private getURL(): string {
    let newUrl = this.baseUrl;
    let parameters = Array.from(this.parameters); // copy

    // on development environment, we need to indicate the path of the javascript tag
    if (ENV["ENV"] !== "prod") {
      parameters = parameters.filter(
        (parameter) => parameter.key !== "hostname",
      );
      parameters.push({
        key: "hostname",
        value: this.configService.config.tagEndpoint,
      });
    }

    const qs = parameters
      .filter((parameter) => Boolean(parameter.value))
      .map((parameter) => `${parameter.key}=${parameter.value}`);
    if (qs.length > 0) newUrl += `?${qs.join("&")}`;

    return newUrl;
  }

  public async onUpdateParameter() {
    this.url = this.getURL();
    this.urlChange.emit(this.url);
  }

  public onAddParameter() {
    const parameter: Parameter = {
      key: "",
      value: "",
    };
    this.parameters.push(parameter);
    this.parameters = Array.from(this.parameters);
    this.onUpdateParameter();
  }

  public removeParameter(i: number) {
    this.parameters.splice(i, 1);
    this.parameters = Array.from(this.parameters);
    this.onUpdateParameter();
  }

  public ensureValidParameterKey(parameter: Parameter) {
    if (!parameter.key) return;

    // remove non-alphanumeric chars (permit - and _)
    setTimeout(() => {
      parameter.key = parameter.key.replace(/[^A-Za-z0-9_-]/g, "");
    }, 50);
  }
}
