import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotificationHelper } from "helpers/notification.helper";

import {
  Integration,
  IntegrationSpec,
  integrationSpecs,
  IntegrationType,
} from "models/integrations.model";
import { Org } from "models/org.model";
import { OAuth2Service } from "services/oauth2.service";
import { NgIf } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { NzIconDirective } from "ng-zorro-antd/icon";

type IntegrationOAuthMode = "2LO" | "3LO";

@Component({
  selector: "integration-settings-oauth",
  templateUrl: "./integration-settings-oauth.component.html",
  styleUrls: ["./integration-settings-oauth.component.scss"],
  imports: [
    NgIf,
    NzButtonComponent,
    ɵNzTransitionPatchDirective,
    NzWaveDirective,
    NzIconDirective,
  ],
})
export class IntegrationSettingsOAuthComponent implements OnInit {
  @Input() mode: IntegrationOAuthMode = "3LO";
  @Input() org: Org = null;
  @Input() integration: Integration = null;
  @Input() confirmMessage: string = null;
  @Input() iconName: string = null;

  // 2LO
  @Input() public client_id: string = null;
  @Input() public client_secret: string = null;

  public integrationSlug: IntegrationType = null;
  public integrationSpec: IntegrationSpec = null;
  public authorizeURL = null;

  constructor(
    private route: ActivatedRoute,
    private oauth2Service: OAuth2Service,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit() {
    this.integrationSlug = this.integration?.type;
    this.iconName = this.iconName ?? this.integrationSlug;
    this.integrationSpec = integrationSpecs[this.integrationSlug];

    if (this.mode === "3LO") this.getAuthorizeURL();
  }

  public checkCredentials() {
    this.oauth2Service
      .verifyCredentials(
        this.org.id,
        this.integration.type,
        this.client_id,
        this.client_secret,
      )
      .then(() => {
        this.integration.auth_ok = true;

        this.notificationHelper.trigger(
          "Success",
          "Credentials verified.",
          "success",
        );
      })
      .catch(() => {
        this.notificationHelper.trigger(
          "Error",
          "Credentials could not be verified. Please check your credentials and try again.",
          "error",
        );
      });
  }

  public getAuthorizeURL() {
    this.oauth2Service
      .getAuthorizeURL(this.org.id, this.integrationSlug)
      .then((url) => (this.authorizeURL = url));
  }

  public disconnect() {
    this.oauth2Service
      .revoke(this.org.id, this.integrationSlug)
      .then(() => {
        this.integration.auth_ok = false;
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err.error);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Could not revoke access to integration",
          null,
          "error",
        );
      });
  }
}
