import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { NotificationHelper } from "helpers/notification.helper";
import { OrgDao } from "models/org.dao";

import { getRegistryEntriesPath, RegistryEntry } from "models/registry.model";

import { RoutingService } from "services/routing.service";
import { UIService } from "services/ui.service";
import { arrayToMap, chunk } from "utils/array";
import { SelectorUserPropertiesComponent } from "../../common/user/selector-user-properties/selector-user-properties.component";
import { NzInputDirective } from "ng-zorro-antd/input";
import { FormsModule } from "@angular/forms";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";

@Component({
  selector: "segment-bulk-import-page",
  templateUrl: "./segment-bulk-import.component.html",
  styleUrls: ["./segment-bulk-import.component.scss"],
  imports: [
    SelectorUserPropertiesComponent,
    NzInputDirective,
    FormsModule,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
  ],
})
export class SegmentBulkImportPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Segment bulk import";
  public name = "Segment bulk import";

  private obs: any = null;

  public registryEntriesGroupType: RegistryEntry[];
  public registryEntriesGroup: RegistryEntry[];
  public registryEntriesIdentityProperty: RegistryEntry[];
  public registryEntriesIdentityPropertyById: Map<string, RegistryEntry> =
    new Map();
  public registryEntriesIdentityPropertyScreeb: RegistryEntry[] = [];
  public registryEntriesIdentityPropertyPathsById: Map<string, string[]> =
    new Map();

  public currentUserGroup: RegistryEntry = null;

  public selectedPropertyKeyId: string = null;
  public values: string = null;

  public loading: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public notificationHelper: NotificationHelper,
    public uiService: UIService,
    public orgDao: OrgDao,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );
    this.obs = this.route.data.subscribe((data) => {
      this.registryEntriesGroup = data.registryEntriesGroup.groups;
      this.registryEntriesGroupType = data.registryEntriesGroup.group_types;
      this.registryEntriesIdentityProperty =
        data.registryEntriesIdentityProperty;
      this.registryEntriesIdentityPropertyById = arrayToMap(
        this.registryEntriesIdentityProperty,
        "id",
      );
      this.registryEntriesIdentityPropertyScreeb =
        this.registryEntriesIdentityProperty.filter(
          ({ location }) => location === "screeb",
        );
      this.registryEntriesIdentityPropertyById = arrayToMap(
        this.registryEntriesIdentityProperty,
        "id",
      );

      this.registryEntriesIdentityPropertyPathsById = getRegistryEntriesPath(
        this.registryEntriesIdentityProperty,
      );

      this.currentUserGroup = data.registryEntriesGroup.groups.find(
        (userGroup) =>
          userGroup.id === this.route.snapshot.params["segment_id"],
      );

      if (!this.currentUserGroup) {
        this.router.navigate(["/404"]);
      }
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  async import() {
    const propertyType = this.registryEntriesIdentityPropertyById.get(
      this.selectedPropertyKeyId,
    ).type;
    if (propertyType !== "string") {
      this.notificationHelper.trigger(
        "We support only string properties for now",
        null,
        "error",
      );
      return;
    }

    const lines = this.values
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line.length > 0);

    const batches = chunk(lines, 1000);
    let count = 0;
    this.loading = true;

    try {
      for (const batch of batches) {
        const response = await this.orgDao.assignGroupByProperty(
          this.uiService.currentOrgId,
          this.currentUserGroup.id,
          this.selectedPropertyKeyId,
          batch,
        );
        count += response["assigned"];
      }
    } catch (err) {
      console.error(err.error);
      this.notificationHelper.trigger(
        err?.error?.message ?? "Could not assign group to users",
        null,
        "error",
      );
      this.loading = false;
      return;
    }

    this.loading = false;
    this.notificationHelper.trigger(
      `Successfully assigned group to ${count} users`,
      null,
      "success",
    );
  }
}
