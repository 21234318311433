<div class="date-range" nzTrigger="click" [ngClass]="theme">
  <i class="date-range-icon" nz-icon nzType="calendar"></i>
  <nz-range-picker
    [nzRanges]="ranges"
    [ngModel]="selectedDates"
    (ngModelChange)="onChange($event)"
    [nzDisabledDate]="isDisabledDate"
    nzSize="large"
    nzFormat="dd-MM-yyyy"
    [nzAllowClear]="false"
  ></nz-range-picker>
</div>
