import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  ActivatedRoute,
  Router,
  RouterLinkActive,
  RouterLink,
} from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";
import { Org } from "models/org.model";
import { SurveyDao } from "models/survey.dao";
import {
  DefaultMessageName,
  DefaultSurveyName,
  Survey,
} from "models/survey.model";
import { combineLatest } from "rxjs";
import { SessionService } from "services/auth.service";
import { RoutingService } from "services/routing.service";
import { SettingsService } from "services/settings.service";

import { HttpErrorResponse } from "@angular/common/http";
import { NotificationHelper } from "helpers/notification.helper";
import { Account } from "models/account.model";
import { Channel, ChannelTypeFormatted } from "models/channel.model";
import { Sequence } from "models/sequence.types";
import {
  DistributionInteraction,
  DistributionInteractions,
  DistributionInteractionsFormatted,
  SurveyDistribution,
} from "models/survey-distribution.model";
import { NzModalService } from "ng-zorro-antd/modal";
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
  NzTableComponent,
  NzTheadComponent,
  NzTrDirective,
  NzTableCellDirective,
  NzThMeasureDirective,
  NzThAddOnComponent,
  NzCellAlignDirective,
  NzCellFixedDirective,
  NzTbodyComponent,
} from "ng-zorro-antd/table";
import { EntitlementService } from "services/entitlement.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { UIService } from "services/ui.service";
import { formatNumber } from "utils/number";
import { simpleFTS } from "utils/search";
import { FreeTrialBannerComponent } from "../../../super-org/billing/banners/free-trial-banner.component";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { UpgradeModal } from "../../../super-org/billing/banners/upgrade-modal.component";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";
import { NzSelectComponent, NzOptionComponent } from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { TrackByPropertyDirective } from "../../../../helpers/track-by-property";
import {
  NzInputGroupComponent,
  NzInputGroupWhitSuffixOrPrefixDirective,
  NzInputDirective,
} from "ng-zorro-antd/input";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { NzEmptyComponent } from "ng-zorro-antd/empty";
import { TagsComponent } from "../../../utils/tags/tags.component";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { SurveyDistributionsStatusComponent } from "./components/survey-distributions-status/survey-distributions-status.component";
import { OrgAccountAvatarComponent } from "../../../utils/org-account-avatar/org-account-avatar.component";
import {
  NzDropDownDirective,
  NzDropdownMenuComponent,
} from "ng-zorro-antd/dropdown";
import { NzMenuDirective, NzMenuItemComponent } from "ng-zorro-antd/menu";
import { SurveysImportModalComponent } from "../import/surveys-import.component";
import { DuplicateSurveyModalComponent } from "./components/duplicate-modal/duplicate-survey-modal.component";
import { FormatDistanceToNowPipeModule } from "ngx-date-fns";
import { ScreebFormatPipe } from "pipes/format.pipe";
import { PermissionPipe } from "pipes/permission.pipe";
import { EntitlementPipe } from "pipes/entitlement.pipe";
import { FeaturePipe } from "pipes/feature.pipe";

interface ColumnItem {
  name: string;
  width: number;
  align: "left" | "right" | "center";
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn | null;
  listOfFilter: NzTableFilterList | null;
  filterFn: NzTableFilterFn | undefined;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: "page-survey-list",
  templateUrl: "./survey-list.component.html",
  styleUrls: ["./survey-list.component.scss"],
  imports: [
    FreeTrialBannerComponent,
    NgIf,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    UpgradeModal,
    ScreebIconComponent,
    NzSelectComponent,
    FormsModule,
    NgFor,
    NzOptionComponent,
    TrackByPropertyDirective,
    NzInputGroupComponent,
    NzInputGroupWhitSuffixOrPrefixDirective,
    NzInputDirective,
    NzIconDirective,
    NzEmptyComponent,
    NzTableComponent,
    NzTheadComponent,
    NzTrDirective,
    NzTableCellDirective,
    NzThMeasureDirective,
    NzThAddOnComponent,
    NzCellAlignDirective,
    NzCellFixedDirective,
    NzTbodyComponent,
    RouterLinkActive,
    RouterLink,
    TagsComponent,
    NzTagComponent,
    SurveyDistributionsStatusComponent,
    OrgAccountAvatarComponent,
    NzDropDownDirective,
    NgClass,
    NzDropdownMenuComponent,
    NzMenuDirective,
    NzMenuItemComponent,
    SurveysImportModalComponent,
    DuplicateSurveyModalComponent,
    FormatDistanceToNowPipeModule,
    ScreebFormatPipe,
    PermissionPipe,
    EntitlementPipe,
    FeaturePipe,
  ],
})
export class SurveyListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "List all surveys";
  public name = "List surveys";

  public ready = false;
  public pathPrefix = "survey";

  private obs: any = null;
  public org: Org = null;
  public surveys: Survey[] = [];
  public channels: Channel[] = [];
  public surveyTags: string[] = [];
  public orgAccounts: Account[] = [];
  public sequences: Sequence[] = [];

  public ChannelTypeFormatted = ChannelTypeFormatted;

  public filteredTags: string[] = null;
  public filteredStatus: string = null;
  public filteredSequences: string[] = null;
  public filteredSurveys: Survey[] = [];
  public filteredInteractions: DistributionInteraction[] = [];
  public keywords = "";

  public importModalOpened = false;
  public upgradeModalOpened = true; // always true at start, but will appear if entitlement is missing

  public currentSurveyActionDropDown: string = null;
  public currentSurveyNameEditing: string = null;
  private loaderStatus: object = {};

  public distributionStatusLoading: object = {};

  public workspaces: Org[] = [];
  public duplicateModalOpened = false;
  public duplicateTargetWorkspaceId: string = null;
  private surveyToDuplicate: Survey = null;

  public formatNumber = formatNumber;
  public interactions = DistributionInteractions;
  public distributionInteractionsFormatted = DistributionInteractionsFormatted;

  @Output()
  public collapseMenu: EventEmitter<any> = new EventEmitter();

  @ViewChild("renameInput") renameInput: ElementRef;

  listOfColumns: ColumnItem[] = [
    {
      name: "Survey",
      width: 170,
      align: "left",
      sortOrder: null,
      sortFn: (a: Survey, b: Survey) => a.title.localeCompare(b.title),
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Status",
      width: 100,
      align: "left",
      sortOrder: undefined,
      sortFn: undefined,
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Displays",
      width: 100,
      align: "center",
      sortOrder: null,
      sortFn: (a: Survey, b: Survey) =>
        a.stats.response_total - b.stats.response_total,
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Response Rate",
      width: 150,
      align: "center",
      sortOrder: null,
      sortFn: (a: Survey, b: Survey) =>
        a.stats.response_rate - b.stats.response_rate,
      sortDirections: ["ascend", "descend"],
      filterMultiple: true,
      listOfFilter: undefined,
      filterFn: null,
    },
    {
      name: "Last Edit",
      width: 130,
      align: "left",
      sortOrder: "descend",
      sortFn: (a: Survey, b: Survey) =>
        a.updated_at.getTime() - b.updated_at.getTime(),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      listOfFilter: undefined,
      filterFn: null,
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private modalService: NzModalService,
    public settingsService: SettingsService,
    public sessionService: SessionService,
    private notificationHelper: NotificationHelper,
    public uiService: UIService,
    public surveyDao: SurveyDao,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public entitlementService: EntitlementService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = combineLatest(this.route.data, this.route.queryParams).subscribe(
      (d) => {
        const data = d[0];

        this.org = data.org;
        this.uiService.isMessagePage = this.router.isActive(
          `/org/${this.org.id}/message`,
          false,
        );
        if (this.uiService.isMessagePage) {
          this.title = "List all messages";
          this.name = "List messages";
          this.pathPrefix = "message";
          this.listOfColumns = this.listOfColumns.map((column) => {
            if (column.name === "Survey") {
              column.name = "Message";
            } else if (column.name === "Response Rate") {
              column.name = "Interaction rate";
            }
            return column;
          });
        }

        this.surveys = this.alterSurveyDistributions(data.surveys);
        this.workspaces = this.uiService.orgWorkspaces;
        this.surveyTags = data.surveyTags;
        this.channels = data.channels;
        this.orgAccounts = data.orgAccounts;
        this.sequences = data.sequences;
        this.filteredSurveys = [].concat(this.surveys);
        this.ready = true;
        this.distributionStatusLoading = {};

        this.duplicateTargetWorkspaceId = this.org.id;

        this.surveys.map(
          (survey) =>
            (survey.updated_by_account = this.orgAccounts.find(
              ({ id }) => id === survey.updated_by,
            )),
        );
      },
    );
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  // Let's order the distributions by type and filter distributions for messages
  private alterSurveyDistributions(surveys: Survey[]): Survey[] {
    const order = {
      widget: 1,
      android: 2,
      ios: 3,
      "hosted-page": 4,
    };

    return surveys.map((survey: Survey) => {
      // For a message, only show edited distributions
      if (survey.type === "message") {
        survey.survey_distributions = survey.survey_distributions.filter(
          (d) => Number(d.updated_at) !== Number(d.created_at),
        );
      } else {
        survey.survey_distributions.sort(
          (a: SurveyDistribution, b: SurveyDistribution) => {
            return order[a.type] - order[b.type];
          },
        );
      }
      return survey;
    });
  }

  public getChannelById(id: string): Channel | null {
    return this.channels.find((channel) => id === channel.id);
  }

  public onTagsFilterChange(tags: string[]) {
    this.filteredTags = tags;

    this.filterSurveys();
  }

  public onStatusFilterChange(value: string) {
    this.filteredStatus = value;

    this.filterSurveys();
  }

  public onSequencesFilterChange(value: string[]) {
    this.filteredSequences = value;

    this.filterSurveys();
  }

  public onInteractionsFilterChange(value: DistributionInteraction[]) {
    this.filteredInteractions = value;

    this.filterSurveys();
  }

  public onDupicateSelectWorkspace(survey: Survey) {
    this.surveyToDuplicate = survey;
    this.duplicateModalOpened = true;
  }

  public onDuplicateCancel() {
    this.duplicateModalOpened = false;
    this.surveyToDuplicate = null;
  }

  public onDuplicateSurvey() {
    const survey = this.surveyToDuplicate;

    this.setSurveyActionLoaderStatus(survey.id, "duplicating", true);

    const title = survey.title;

    this.surveyDao
      .duplicate(
        survey.org_id,
        this.duplicateTargetWorkspaceId,
        this.uiService.surveyType,
        title,
        survey.id,
      )
      .then((newSurvey: Survey) => {
        this.setSurveyActionLoaderStatus(survey.id, "duplicating", false);
        return newSurvey;
      })
      .then((newSurvey: Survey) => {
        this.uiService.fetchEverything();
        return newSurvey;
      })
      .then((newSurvey: Survey) => {
        this.router.navigate(
          [
            "org",
            newSurvey.org_id,
            this.uiService.surveyTypePath,
            newSurvey.id,
          ],
          {},
        );
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
        this.setSurveyActionLoaderStatus(survey.id, "duplicating", false);
      })
      .finally(() => {
        this.duplicateModalOpened = false;
        this.surveyToDuplicate = null;
      });
  }

  public onDelete(survey: Survey) {
    this.setSurveyActionLoaderStatus(survey.id, "deleting", true);

    new Promise((resolve) => {
      let msgPrefix = `<b>${survey.title}</b> will be removed.<br>`;
      if (survey.stats.response_total === 1) {
        msgPrefix += `<b>1 response</b> will be removed.<br>`;
      } else if (survey.stats.response_total > 1) {
        msgPrefix += `<b>${survey.stats.response_total} responses</b> will be removed.<br>`;
      }

      this.modalService.warning({
        nzTitle: "Do you really want to hurt me? 🎶",
        nzContent: msgPrefix + "<br>This operation cannot be undone.",
        nzStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "center",
        },
        nzMaskClosable: true,
        nzCloseOnNavigation: false,
        nzOkType: "default",
        nzOkDanger: true,
        nzOkText: "Confirm",
        nzCancelText: "Cancel",
        nzOnOk: () => resolve(true),
        nzOnCancel: () => resolve(false),
      });
    })
      .then((remove: boolean) => {
        if (!remove) {
          return;
        }

        return this.surveyDao.delete(survey.org_id, survey.id).then(() => {
          this.surveys = this.surveys.filter((s: Survey) => s.id !== survey.id);
          if (
            this.surveys.length === 0 &&
            this.permissionsService.isAllowed("survey.create")
          ) {
            this.router.navigate(
              ["org", survey.org_id, this.uiService.surveyTypePath, "create"],
              {},
            );
          } else {
            this.filterSurveys();
            this.uiService.fetchEverything();
          }
        });
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        this.notificationHelper.trigger(
          err?.error?.message ?? "Error",
          null,
          "error",
        );
      })
      .then(() => {
        this.setSurveyActionLoaderStatus(survey.id, "deleting", false);
      });
  }

  public onRename(survey: Survey) {
    this.currentSurveyNameEditing = survey.id;
    this.currentSurveyActionDropDown = null;
    setTimeout(() => {
      this.renameInput.nativeElement.focus();
      this.renameInput.nativeElement.select();
    }, 100);
  }

  private setSurveyActionLoaderStatus(
    surveyId: string,
    statusId: string,
    status: boolean,
  ) {
    if (!this.loaderStatus[surveyId]) {
      this.loaderStatus[surveyId] = {};
    }

    this.loaderStatus[surveyId][statusId] = status;
  }

  public getSurveyActionLoaderStatus(
    surveyId: string,
    statusId: string,
  ): boolean {
    return this.loaderStatus?.[surveyId]?.[statusId] === true;
  }

  public isSurveyTitleValid(name: string) {
    return name.trim().length > 2;
  }

  public stopRenameSurvey() {
    this.currentSurveyNameEditing = null;
  }

  public onRenameInputValidate(event: KeyboardEvent, survey: Survey) {
    const newTitle = (event.target as HTMLInputElement).value;

    if (!this.isSurveyTitleValid(newTitle)) {
      return;
    }

    return this.surveyDao
      .updateTitleAndTags(this.org.id, survey.id, newTitle, survey.tags)
      .then(async () => {
        survey.title = newTitle;
        await this.uiService.fetchEverything();
        this.stopRenameSurvey();
      })
      .catch((err: HttpErrorResponse) => {
        console.error(err);
        throw err;
      });
  }

  public setCurrentSurveyActionDropDown(surveyId: string, opened: boolean) {
    if (!opened) {
      surveyId = null;
    }
    this.currentSurveyActionDropDown = surveyId;
  }

  getSurveySequenceTags(survey: Survey) {
    if (!survey.sequence_id) {
      return "<No sequence>";
    }

    return (
      this.sequences.find(({ id }) => id === survey.sequence_id)?.title ??
      "<Deleted sequence>"
    );
  }

  /**
   * Search
   */
  public onSearchChange() {
    this.filterSurveys();
  }

  private filterSurveys(): void {
    this.filteredSurveys = this.surveys.filter((survey: Survey) => {
      const runningDistributions = survey.survey_distributions.filter(
        (d) => d.status === "running" || d.status === "programmed",
      );

      const haveOneRunning = runningDistributions.length > 0;
      const haveOneScheduled =
        !haveOneRunning &&
        survey.survey_distributions.some((d) => d.status === "programmed");
      const haveOnePaused =
        !haveOneRunning &&
        !haveOneScheduled &&
        survey.survey_distributions.some((d) => d.status === "paused");

      let commonStatus = "stopped";

      if (haveOneRunning) {
        commonStatus = "running";
      } else if (haveOneScheduled) {
        commonStatus = "programmed";
      } else if (haveOnePaused) {
        commonStatus = "paused";
      }

      const statusOk =
        this.filteredStatus !== null && this.filteredStatus !== undefined
          ? commonStatus === this.filteredStatus
          : true;
      const tagOk = this.filteredTags?.length
        ? this.filteredTags.every((tag) => survey.tags.includes(tag))
        : true;
      const sequenceOk = this.filteredSequences?.length
        ? this.filteredSequences.every(
            (sequenceId) => survey.sequence_id === sequenceId,
          )
        : true;
      const interactionOk = this.filteredInteractions?.length
        ? this.filteredInteractions.some((interaction) =>
            survey.survey_distributions.some((d) => {
              let _interaction = d.interaction;
              if (!_interaction) {
                if (d.type === "hosted-page") {
                  _interaction = "link";
                } else {
                  _interaction = "in_app";
                }
              }
              return (
                (_interaction === interaction && d.status === "running") ||
                d.status === "programmed"
              );
            }),
          )
        : true;

      return (
        simpleFTS(survey.title, this.keywords) &&
        statusOk &&
        tagOk &&
        sequenceOk &&
        interactionOk
      );
    });
  }

  onSurveyCreateTemplate() {
    this.router.navigate([
      "org",
      this.org.id,
      this.uiService.surveyTypePath,
      "create",
    ]);
  }

  onSurveyCreate(withAI: boolean = false) {
    this.surveyDao
      .create(
        this.org.id,
        this.uiService.surveyType,
        this.uiService.isMessagePage ? DefaultMessageName : DefaultSurveyName,
        this.org.survey_languages[0] ?? null,
      )
      .then(async (survey: Survey) => {
        await this.uiService.fetchEverything();
        return survey;
      })
      .then((survey: Survey) => {
        if (withAI) {
          this.router.navigate(
            [`/org/${survey.org_id}/${this.pathPrefix}/${survey.id}/edit`],
            { queryParams: { ai: true } },
          );
        } else {
          this.router.navigate([
            `/org/${survey.org_id}/${this.pathPrefix}/${survey.id}/edit`,
          ]);
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.trigger(
          err?.error?.message ??
            `Failed to create ${this.uiService.isMessagePage ? "message" : "survey"}`,
          null,
          "error",
        );
        console.error(err.error);
      });
  }

  public onSurveysImport() {
    this.importModalOpened = true;
  }
}
