/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import {
  I18nTextLabel,
  getI18nTextLabelTranslation,
} from "models/survey.dao.types";
import { BuilderStore } from "stores/builder.store";
import {
  OnClickEvent,
  ActionDotComponent,
} from "../ActionDot/ActionDot.component";
import { SmallCardsTypes } from "../Helpers";
import { NgClass, NgSwitch, NgSwitchCase, NgIf } from "@angular/common";
import { SanitizedMessageComponent } from "../sanitized-message/sanitized-message.component";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { ScreebFormatPipe } from "pipes/format.pipe";

@Component({
  selector: "small-card",
  templateUrl: "./SmallCard.component.html",
  styleUrls: ["./SmallCard.component.scss"],
  imports: [
    NgClass,
    NgSwitch,
    NgSwitchCase,
    SanitizedMessageComponent,
    NgIf,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    ActionDotComponent,
    ScreebFormatPipe,
  ],
})
export class SmallCardComponent implements AfterViewInit {
  @Input() emoji?: string | null = null;
  @Input() label?: I18nTextLabel;
  @Input() multiple?: boolean = false;
  @Input() pathCount?: number;
  @Input() skipPathCount?: number;
  @Input() haveNext?: boolean;
  @Input() haveSkip?: boolean;
  @Input() haveError?: boolean;

  @Output() onSizeUpdate = new EventEmitter<any>();
  @Output() onActionDotClick = new EventEmitter<ElementRef>();
  @Output() onClick = new EventEmitter<Event>();

  constructor(
    private el: ElementRef,
    public builderStore: BuilderStore,
  ) {}

  ngAfterViewInit() {
    const element = this.el.nativeElement.querySelector(".SmallCard");
    if (element) {
      const clientRect = element.getBoundingClientRect();
      this.onSizeUpdate.emit({ element, clientRect });
    }
  }

  public get type(): SmallCardsTypes {
    const hasLabel = this.label && Object.keys(this.label).length;

    if (hasLabel) {
      return SmallCardsTypes.EmojiAndText;
    } else {
      return SmallCardsTypes.Emoji;
    }
  }

  actionDotClick({ event, origin }: OnClickEvent) {
    event.stopPropagation();
    this.onActionDotClick.emit(origin);
  }

  public getTranslatedLabel(): string {
    return (
      getI18nTextLabelTranslation(
        this.label,
        this.builderStore.currentLanguage,
        this.builderStore.survey.scenario.default_language,
      ) || ""
    );
  }
}
