/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import {
  entitlementToIllustration,
  FeatureKey,
} from "services/entitlement.service";
import { NgIf, NgClass } from "@angular/common";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";

@Component({
  selector: "upgrade-modal",
  template: `
    <div
      class="upgrade-overlay"
      [ngClass]="position"
      *ngIf="withOverlay"
      (click)="canCancel && closeClick.emit()"
    ></div>
    <div
      [class]="'upgrade-modal' + ' ' + position"
      [ngClass]="{ centeredModal, withOverlay }"
      [style]="offsetY ? { top: offsetY + 'px' } : {}"
    >
      <div class="upgrade-title">{{ title }}</div>
      <img
        alt="Upgrade"
        class="upgrade-illustration"
        src="/assets/illustrations{{ illustration }}"
      />
      <div class="upgrade-description">
        {{ text }}
      </div>
      <div class="upgrade-actions">
        <button
          nz-button
          nzType="primary"
          nzSize="large"
          nzShape="round"
          (click)="upgradeClick(); closeClick.emit()"
        >
          {{ ctaText }}
        </button>

        <button
          *ngIf="withOverlay && canCancel"
          class="upgrade-close-button"
          nz-button
          nzType="link"
          (click)="closeClick.emit()"
        >
          Not yet
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .upgrade-overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.45);
        z-index: 800;
        animation: shadeAppear 100ms;
      }
      .upgrade-overlay.absolute {
        position: absolute;
      }
      .upgrade-modal {
        width: 360px;
        display: flex;
        flex-direction: column;
        padding: 24px;
        background: var(--screeb-color-background);
        box-shadow: 0px 8px 24px rgba(48, 49, 64, 0.1);
        border-radius: 16px;
        z-index: 801;
        animation: shadeAppearUp 300ms;
      }
      .upgrade-title {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        color: var(--screeb-color-body-text);
      }
      .upgrade-illustration {
        margin-top: 24px;
        width: auto;
        min-height: 155px;
      }
      .upgrade-description {
        font-family: "Rubik";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: var(--screeb-color-body-text);
        margin-top: 24px;
        text-align: center;
      }
      .upgrade-actions {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .centeredModal {
        position: absolute;
        left: 50%;
        margin-left: -180px;
        z-index: 800;
      }
      .withOverlay {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-left: -180px;
        margin-top: -200px;
        z-index: 800;
      }
      .withOverlay.absolute {
        position: absolute;
      }
      .upgrade-close-button {
        margin-top: 8px;
        color: var(--screeb-color-body-text-secondary);
      }

      @keyframes shadeAppear {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }

      @keyframes shadeAppearUp {
        from {
          opacity: 0;
          transform: translateY(-10px);
        }

        to {
          opacity: 1;
          transform: none;
        }
      }
    `,
  ],
  imports: [
    NgIf,
    NgClass,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
  ],
})
export class UpgradeModal implements OnInit {
  @Input() public offsetY = 0;
  @Input() public centeredModal = false;
  @Input() public withOverlay = false;
  @Input() public orgId: string = null;
  @Input() public title = "Upgrade your account!";
  @Input() public text =
    "You need to upgrade your account to unlock that feature!";
  @Input() public ctaText = "Upgrade my account";
  @Input() public upgradeType: FeatureKey = null;
  @Input() public position: "fixed" | "absolute" = "fixed";
  @Input() public canCancel = true;

  @Output() public closeClick: EventEmitter<object> = new EventEmitter();

  public illustration = "/gauge-product-value.svg";

  constructor(private router: Router) {}

  ngOnInit() {
    if (this.upgradeType !== null) {
      this.getIllustration();
    }
  }

  upgradeClick() {
    this.router.navigate(["org", this.orgId, "settings", "billing"]);
  }

  getIllustration() {
    const visual = entitlementToIllustration[this.upgradeType];

    if (visual !== undefined) {
      this.text = visual.text;
      this.illustration = visual.illustration;
    }
  }
}
