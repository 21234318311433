import { arrayToKV, arrayToMap } from "utils/array";
import { ChannelType } from "./channel.model";
import { WorkspaceRegion } from "./org.model";

export type RegistryEntrySubject =
  | "context.property"
  | "ping"
  | "identity"
  | "identity.property"
  | "group_type"
  | "group_type.property"
  | "group"
  | "track"
  | "track.property"
  | "screen"
  | "screen.property"
  | "survey"
  | "survey.hidden_field";

export type RegistryEntrySource =
  | "sdk-js"
  | "sdk-android"
  | "sdk-ios"
  | "hosted-page"
  | "screeb-admin"
  | "segment"
  | "rudderstack"
  | "contentsquare"
  | "typeform"
  | "getfeedback"
  | "amplitude";

export type RegistryEntryLocation = "screeb" | "device";

type registryEntrySourceSpec = {
  title: string;
  filterTitle?: string;
  color: string;
  targetingCategoryVisibleOnEmpty: boolean;
};

export const RegistryEntrySourceFormatted: {
  [key in RegistryEntrySource]: registryEntrySourceSpec;
} = {
  "sdk-js": {
    title: "Javascript Tag",
    filterTitle: "Web",
    color: "#5E21F1",
    targetingCategoryVisibleOnEmpty: false,
  },
  "sdk-android": {
    title: "Android SDK",
    filterTitle: "Android",
    color: "#3DDC84",
    targetingCategoryVisibleOnEmpty: false,
  },
  "sdk-ios": {
    title: "iOS SDK",
    filterTitle: "iOS",
    color: "#A3AAAE",
    targetingCategoryVisibleOnEmpty: false,
  },
  "hosted-page": {
    title: "Link",
    filterTitle: "Link",
    color: "#502C9E",
    targetingCategoryVisibleOnEmpty: false,
  },
  "screeb-admin": {
    title: "Manual",
    color: "#FFB546",
    targetingCategoryVisibleOnEmpty: false,
  },
  segment: {
    title: "Segment",
    color: "#00c898",
    targetingCategoryVisibleOnEmpty: true,
  },
  rudderstack: {
    title: "RudderStack",
    color: "#01006F",
    targetingCategoryVisibleOnEmpty: false,
  },
  contentsquare: {
    title: "Contentsquare",
    color: "#9AA0EE",
    targetingCategoryVisibleOnEmpty: false,
  },
  typeform: {
    title: "Typeform",
    color: "#292929",
    targetingCategoryVisibleOnEmpty: false,
  },
  getfeedback: {
    title: "GetFeedback",
    color: "#FFB546",
    targetingCategoryVisibleOnEmpty: false,
  },
  amplitude: {
    title: "Amplitude",
    color: "#502C9E",
    targetingCategoryVisibleOnEmpty: false,
  },
};

export const ChannelTypeToRegistryEntrySource: {
  [key in ChannelType]: RegistryEntrySource;
} = {
  widget: "sdk-js",
  android: "sdk-android",
  ios: "sdk-ios",
  "hosted-page": "hosted-page",
};

export const sortedRegistryEntrySources = [
  "sdk-js",
  "sdk-android",
  "sdk-ios",
  "hosted-page",
  "screeb-admin",
  "segment",
  "rudderstack",
  "contentsquare",
  "typeform",
  "getfeedback",
  "amplitude",
];

export type RegistryEntryType =
  | "string"
  | "number"
  | "time"
  | "bool"
  | "object";

export const RegistryEntrySourceIsIntegration: {
  [key in RegistryEntrySource]: boolean;
} = {
  "sdk-js": false,
  "sdk-android": false,
  "sdk-ios": false,
  "hosted-page": false,
  "screeb-admin": true,
  segment: true,
  rudderstack: true,
  contentsquare: true,
  typeform: true,
  getfeedback: true,
  amplitude: true,
};

export const RegistryEntryTypeFormatted: {
  [key in RegistryEntryType]: { title: string };
} = {
  string: {
    title: "String",
  },
  number: {
    title: "Number",
  },
  time: {
    title: "Time",
  },
  bool: {
    title: "Boolean",
  },
  object: {
    title: "Object",
  },
};

// type RegistryEntryValidator = "email" | "url";

export type RegistryEntry = {
  id: string;

  // immutable
  tenant_id: string;
  tenant_region: WorkspaceRegion;
  subject: RegistryEntrySubject;
  slug: string;
  parent_id: string | null;
  type: RegistryEntryType | null;
  location: RegistryEntryLocation;
  // validators: RegistryEntryValidator[];
  removable: boolean;

  // mutable
  title: string;
  sources: RegistryEntrySource[];
  first_at: Date | null;
  last_at: Date | null;

  // settings
  favorite: boolean;
  hidden: boolean;
  properties?: object;

  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  deletion_step: "marked-for-deletion" | null;

  // computed
  parent?: RegistryEntry | null;
  children?: RegistryEntry[];
};

// Here, we return ids to remove, instead of new array, because we let the reponsability of the deletion to the caller.
// For exemple, it would allow us to preserve items order.
// export function listRegistryEntriesToRemoveRecursively(
export function removeRegistryEntriesRecursivelyAndPreserveOrder(
  registryEntries: RegistryEntry[],
  registryEntryIdsToRemove: string[],
): RegistryEntry[] {
  const registryEntryIdToParentId = arrayToKV(
    registryEntries,
    "id",
    "parent_id",
  );

  return registryEntries.filter((item) => {
    let itemId = item.id;

    while (itemId) {
      if (registryEntryIdsToRemove.includes(itemId)) {
        return false;
      }

      // next iteration, we will check parent is not in the list of items to remove
      itemId = registryEntryIdToParentId.get(itemId);
    }

    return true;
  });
}

export function getRegistryEntryPath(
  registryEntry: RegistryEntry,
  registryEntriesById: Map<string, RegistryEntry>,
): string[] {
  let path = [];

  // Wonderful, i just found a perfect opportunity to run a "do-while" \o/.
  // I can retire now.
  // [[ drop the mic ]]
  do {
    path = [registryEntry.slug].concat(path);
    registryEntry = registryEntriesById.get(registryEntry.parent_id);
  } while (registryEntry?.parent_id);

  return path;
}

export function getRegistryEntriesPath(
  all: RegistryEntry[],
): Map<string, string[]> {
  const registryEntriesById = arrayToMap(all, "id");

  return all.reduce(
    (
      acc: Map<string, string[]>,
      curr: RegistryEntry,
    ): Map<string, string[]> => {
      acc[curr.id] = getRegistryEntryPath(curr, registryEntriesById);
      return acc;
    },
    new Map(),
  );
}

export function getRegistryEntriesTopLevel(
  all: RegistryEntry[],
): RegistryEntry[] {
  const registryEntriesById = arrayToMap(all, "id");

  return all.filter((item: RegistryEntry): boolean => {
    return !(item.parent_id && registryEntriesById.get(item.parent_id));
  });
}

export function sortRegistryEntries(a: RegistryEntry, b: RegistryEntry) {
  if (a.favorite !== b.favorite) {
    return Number(b.favorite) - Number(a.favorite);
  }
  return a.slug.localeCompare(b.slug);
}

export function adaptRegistryEntry(json: object): RegistryEntry {
  return {
    id: json["id"],

    tenant_id: json["tenant_id"],
    tenant_region: json["tenant_region"],
    subject: json["subject"] as RegistryEntrySubject,
    slug: json["slug"],
    parent_id: json["parent_id"],
    type: json["type"] as RegistryEntryType | null,
    location: json["location"] as RegistryEntryLocation,
    removable: json["removable"],

    title: json["title"],
    sources: json["sources"].map((source) => source as RegistryEntrySource),
    first_at: json["first_at"] ? new Date(json["first_at"]) : null,
    last_at: json["last_at"] ? new Date(json["last_at"]) : null,

    favorite: json["favorite"] ?? false,
    hidden: json["hidden"] ?? false,
    properties: json["subject"] === "group" ? json["properties"] : null,

    created_at: new Date(json["created_at"]),
    updated_at: new Date(json["updated_at"]),
    deleted_at: json["deleted_at"] ? new Date(json["deleted_at"]) : null,

    deletion_step: json["deletion_step"],

    // computed
    parent: json["parent"] ? adaptRegistryEntry(json["parent"] || {}) : null,
    children: json["children"]?.map((child) => adaptRegistryEntry(child)),
  };
}
