import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NzTableSortOrder } from "ng-zorro-antd/table";

import { PageComponentInterface } from "components/PageComponentInterface";
import { GraphNode, ScenarioGraphBuilder } from "components/builder/flow";
import { AnalyticsDao } from "models/analytics.dao";
import { AnalyticsQueryResponse } from "models/analytics.filters.type";
import { Channel } from "models/channel.model";
import { Integration } from "models/integrations.model";
import { Org } from "models/org.model";
import { SurveyScenario, UUID } from "models/survey.dao.types";
import { Survey } from "models/survey.model";
import { AnalyticsFilterService } from "services/analytics-filters.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { RoutingService } from "services/routing.service";
import { UIService } from "services/ui.service";
import { deepCopy } from "utils/object";
import {
  QuestionDetails,
  QuestionsTableComponent,
} from "./questions-table/questions-table.component";
import { SurveyDao } from "models/survey.dao";
import { NotificationHelper } from "helpers/notification.helper";
import { FiltersStatsSurveyComponent } from "../filters/filters.component";
import { ScreebIconComponent } from "../../../../utils/screeb-icon/screeb-icon.component";
import { TagSettingsTranslationComponent } from "../../../../common/customize/tag-settings/component/tag-settings-translation/tag-settings-translation.component";
import { NgIf } from "@angular/common";
import { BarChartSingleQuestionComponent } from "./bar-chart-single-question/bar-chart-single-question.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { AnswersTableSingleQuestionComponent } from "./answers-table-single-question/answers-table-single-question.component";
import { RangeSingleQuestionComponent } from "./range-single-question/range-single-question.component";
import { ScenarioHistoryComponent } from "components/builder/components/scenario-history/scenario-history.component";

@Component({
  selector: "page-survey-stats-all-responses",
  templateUrl: "./all-responses.component.html",
  styleUrls: ["./all-responses.component.scss"],
  imports: [
    FiltersStatsSurveyComponent,
    ScreebIconComponent,
    TagSettingsTranslationComponent,
    QuestionsTableComponent,
    NgIf,
    BarChartSingleQuestionComponent,
    NzButtonComponent,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    AnswersTableSingleQuestionComponent,
    RangeSingleQuestionComponent,
    ScenarioHistoryComponent,
  ],
})
export class AllResponsesStatsSurveyPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Analyze - All responses";
  public name = "Analyze all responses";

  private obs: any = null;

  private filtersObs$: any = null;
  private lastFilters: AnalyticsQueryResponse;

  public org: Org = null;
  public channel: Channel[] = null;
  public survey: Survey = null;

  public integrations: Integration[] = [];
  public expandedNodes: { [key: string]: boolean } = {};
  public expandedResponses: { [key: string]: boolean } = {};
  public nodes: QuestionDetails[] = [];

  public responsesCount: { [key: string]: number } = {};
  public questionOrder: NzTableSortOrder = "ascend";

  public translationEnabled = true;
  public translationEnabledDiff = true;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private analyticsFilterService: AnalyticsFilterService,
    public featureFlaggingService: FeatureFlaggingService,
    public uiService: UIService,
    private analyticsDao: AnalyticsDao,
    private surveyDao: SurveyDao,
    private notificationHelper: NotificationHelper,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.filtersObs$ = this.analyticsFilterService
      .subscribe()
      .subscribe((filters: AnalyticsQueryResponse) => {
        this.lastFilters = deepCopy(filters);
        this.resetQuestionList();
      });

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.survey = data.survey;
      this.integrations = data.integrations;

      this.translationEnabled = this.survey.translation_enabled ?? true;
      this.translationEnabledDiff = this.translationEnabled;

      this.resetFilters();
      this.resetQuestionList();
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }

    if (this.filtersObs$) {
      this.filtersObs$.unsubscribe();
    }
  }

  public toggleTranslations(value: boolean) {
    this.translationEnabled = value;

    this.surveyDao
      .updateTranslation(
        this.uiService.currentOrgId,
        this.uiService.currentSurveyId,
        value,
      )
      .then((newSurvey) => {
        this.uiService.currentSurvey = newSurvey;
        this.notificationHelper.trigger(
          "Survey translation settings saved",
          null,
          "success",
        );
      })
      .catch((err) => {
        console.error(err);
        this.notificationHelper.trigger(
          "An error occurred while saving the setting",
          null,
          "error",
        );
      });
  }

  private resetFilters() {
    this.analyticsFilterService.reset(
      "response",
      this.org.id,
      [this.survey.id],
      this.survey.created_at,
    );
  }

  private resetQuestionList() {
    if (!this.survey) {
      return;
    }

    // list questions with CTA -> remove simple text messages
    const flow = new ScenarioGraphBuilder(this.survey.scenario);
    const nodes = flow
      .getNodeGraph()
      .flat()
      .filter((n: GraphNode) => n.hasCTA);

    // nodes = nodes.sort(Question.compare); // determinate response column order
    this.nodes = nodes
      .map((q) => this.getQuestionDetails(q))
      .filter((q) => !!q);

    this.getResponsesCount(nodes.map(({ correlationId }) => correlationId));
  }

  private getQuestionDetails(node: GraphNode): QuestionDetails {
    return {
      survey: this.survey,
      _node: node,
      node: {
        id: node.node.id,
        correlationId: node.node.correlation_id,
        type: node.questionType,
        letter: node.name,
        text: node.description,
      },
      index: node.index,
    } as QuestionDetails;
  }

  public onSortChange(colName: string, order: NzTableSortOrder) {
    const multiply = order === "ascend" ? 1 : -1;
    this.nodes.sort((a: QuestionDetails, b: QuestionDetails): number => {
      return (a._node.index - b._node.index) * multiply;
    });
  }

  public getQuery(
    pageSize: number,
    pageIndex: number,
    responseOrder: NzTableSortOrder,
    nodeCorrelationId: string,
  ): AnalyticsQueryResponse {
    const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;

    query.question_ids = nodeCorrelationId ? [UUID(nodeCorrelationId)] : [];
    query.size = pageSize;
    query.offset = (pageIndex - 1) * pageSize;
    query.sort = {
      field: "response.last_answer_at",
      order: responseOrder === "ascend" ? "asc" : "desc",
    };

    return query;
  }

  public getCardType(node: GraphNode) {
    if (node.isCES) {
      return "[CES] ";
    }
    if (node.isCSAT) {
      return "[CSAT] ";
    }
    if (node.isNPS) {
      return "[NPS] ";
    }

    return "";
  }

  private async getResponsesCount(ids: UUID[]) {
    if (ids.length === 0) {
      return {};
    }

    const query = deepCopy(this.lastFilters) as AnalyticsQueryResponse;

    query.question_ids = ids;
    query.size = 0;
    query.aggregation = [
      { by: "by_question_correlation_id", params: { answer_ids: ids } },
    ];
    query.having_answer_only = true;
    query.with_total = false;

    const response = await this.analyticsDao.search(query);

    this.responsesCount = this.nodes.reduce((memo, node) => {
      return {
        ...memo,
        [node.node.correlationId]:
          response.aggregations?.answer?.answer?.answer?.buckets.find(
            ({ key }) => key === node.node.correlationId,
          )?.answer_count?.doc_count || 0,
      };
    }, {});
  }

  public onScenarioChange(scenario: SurveyScenario) {
    this.survey.scenario = scenario;
    this.resetQuestionList();
  }
}
