/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  CdkDragDrop,
  moveItemInArray,
  CdkDropList,
  CdkDrag,
  CdkDragPlaceholder,
  CdkDragHandle,
} from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GraphNode } from "components/builder/flow";
import { decode } from "html-entities";
import {
  ActionScoring,
  ActionSkip,
  CTAScoringAny,
  SurveyLanguages,
  UUID,
  getI18nTextLabelTranslation,
  newCTASkip,
  setI18nTextLabelTranslation,
} from "models/survey.dao.types";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { BuilderStore } from "stores/builder.store";
import { uuidv4 } from "utils/uuid";
import { BuilderLateralPanelEmojiPickerConfig } from "../builder-lateral-panel.component";
import {
  NzTableComponent,
  NzTbodyComponent,
  NzTrDirective,
  NzTableCellDirective,
} from "ng-zorro-antd/table";
import { NgFor, NgIf } from "@angular/common";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { NzIconDirective } from "ng-zorro-antd/icon";
import { SanitizedMessageComponent } from "../../Cards/sanitized-message/sanitized-message.component";
import { LateralPanelStepPickerComponent } from "../step-picker/step-picker.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ScreebIconComponent } from "../../../../utils/screeb-icon/screeb-icon.component";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { FormsModule } from "@angular/forms";
import { NzSwitchComponent } from "ng-zorro-antd/switch";
import { NzTooltipDirective } from "ng-zorro-antd/tooltip";
import { RegistryEntry } from "models/registry.model";
import { LateralPanelVariablePickerComponent } from "../variable-picker/variable-picker.component";
import { FeaturePipe } from "pipes/feature.pipe";

const RANDOM_EMOJI = ["💪", "🥰", "😎", "🥳", "🤯", "😝", "👏", "🤘", "👌"];

@Component({
  selector: "lateral-panel-edit-score",
  templateUrl: "./edit-score.component.html",
  styleUrls: ["./edit-score.component.scss"],
  imports: [
    NzTableComponent,
    NzTbodyComponent,
    CdkDropList,
    NgFor,
    NzTrDirective,
    CdkDrag,
    CdkDragPlaceholder,
    NzTableCellDirective,
    NgIf,
    ɵNzTransitionPatchDirective,
    NzIconDirective,
    CdkDragHandle,
    SanitizedMessageComponent,
    LateralPanelStepPickerComponent,
    NzButtonComponent,
    NzWaveDirective,
    ScreebIconComponent,
    NzPopoverDirective,
    FormsModule,
    NzSwitchComponent,
    NzTooltipDirective,
    LateralPanelVariablePickerComponent,
    FeaturePipe,
  ],
})
export class LateralPanelEditScoreComponent implements OnInit {
  @Input() node: GraphNode = null;
  @Input() public language: SurveyLanguages = "en";
  @Input() registryEntriesIdentityProperty: RegistryEntry[] = [];

  @Output() onEmojiPickerOpen =
    new EventEmitter<BuilderLateralPanelEmojiPickerConfig>();
  @Output() onOptionAdded = new EventEmitter();
  @Output() errorChange = new EventEmitter<boolean>();

  public variables: { value: string; label: string }[] = [];

  constructor(
    public builderStore: BuilderStore,
    public featureFlaggingService: FeatureFlaggingService,
  ) {}

  public ngOnInit() {
    if (
      !["scoring", "nps", "ces", "csat"].includes(
        this.node.node.question.call_to_action.type,
      )
    )
      throw Error("unexpected action type for scoring");

    this.validateData();

    this.variables = this.registryEntriesIdentityProperty
      .filter((e) => e.type === "string")
      .map((entry) => ({
        value: entry.slug,
        label: entry.title,
      }));
  }

  public get cta(): CTAScoringAny {
    const cta = this.node.node.question.call_to_action;
    if (!["scoring", "nps", "ces", "csat"].includes(cta.type))
      throw Error("unexpected action type for scoring");
    return cta as CTAScoringAny;
  }

  public get skipAction(): ActionSkip {
    return (this.node.node.question.skip_action ??= newCTASkip());
  }

  public setIsResponsive(responsive: boolean) {
    this.cta.responsive = responsive;
    this.validateData();
  }

  /**
   * Options
   */
  public addOption() {
    if (this.node.isPreset) throw Error("cannot add score in preset");

    // default values
    const value = this.cta.scores.length > 0 ? this.cta.scores.length : 0;
    const emoji = this.getRandomEmoji();

    this.cta.scores.push({
      id: uuidv4() as UUID,
      correlation_id: uuidv4() as UUID,
      type: "scoring",
      payload: {
        emoji,
        value,
      },
    });

    this.onOptionAdded.emit();
    this.validateData();
  }
  public removeOption(index: number) {
    if (this.node.isPreset) throw Error("cannot remove score in preset");

    if (this.cta.scores.length <= 1) return;
    this.cta.scores.splice(index, 1);

    this.reOrderScores();

    this.validateData();
  }
  private getRandomEmoji(): string {
    return RANDOM_EMOJI[Math.floor(Math.random() * RANDOM_EMOJI.length)];
  }

  /**
   * Emoji picker
   */
  public openEmojiPicker(
    buttonWithEmoji: ActionScoring,
    btnElement: HTMLElement,
  ) {
    const buttonRect = btnElement.getBoundingClientRect();
    this.onEmojiPickerOpen.emit({
      action: buttonWithEmoji,
      positionTop: buttonRect.top + window.pageYOffset - 20,
      positionRight:
        window.innerWidth - buttonRect.left + window.pageXOffset + 10,
      canBeNull: false,
      whitelist: this.getEmojiWhitelist(buttonWithEmoji),
    });
  }

  private getEmojiWhitelist(buttonWithEmoji: ActionScoring): string[] {
    if (this.node.isCSAT) {
      switch (buttonWithEmoji.payload.value) {
        case 1:
          return ["😡", "🤬", "1️⃣", "⭐️"];
        case 2:
          return ["😒", "😔", "2️⃣", "⭐️"];
        case 3:
          return ["😐", "3️⃣", "⭐️"];
        case 4:
          return ["😄", "😃", "😀", "4️⃣", "⭐️"];
        case 5:
          return ["😍", "🥰", "🤩", "5️⃣", "⭐️"];
      }
    }
    return [];
  }

  public getLabelLegendMin(): string {
    const cta = this.node.node.question.call_to_action as CTAScoringAny;
    return decode(
      getI18nTextLabelTranslation(
        cta.legend_min || {},
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelLegendMin(text: string) {
    const cta = this.node.node.question.call_to_action as CTAScoringAny;
    cta.legend_min ??= {};
    setI18nTextLabelTranslation(cta.legend_min, text, this.language);
  }

  public getLabelLegendMax(): string {
    const cta = this.node.node.question.call_to_action as CTAScoringAny;
    return decode(
      getI18nTextLabelTranslation(
        cta.legend_max || {},
        this.language,
        this.language,
      ) || "",
    );
  }

  public setLabelLegendMax(text: string) {
    const cta = this.node.node.question.call_to_action as CTAScoringAny;
    cta.legend_max ??= {};
    setI18nTextLabelTranslation(cta.legend_max, text, this.language);
  }

  /**
   * Data validation
   */
  public validateData() {
    this.errorChange.emit(false);
  }

  reOrderScores() {
    this.cta.scores.forEach((score, i) => {
      score.payload.value = i;
    });
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.cta.scores, event.previousIndex, event.currentIndex);
    this.reOrderScores();
  }

  public isFullOfSameEmoji(scores: ActionScoring[]): boolean {
    const emojis = new Set(scores.map((s) => s.payload.emoji));
    return emojis.size === 1;
  }

  public get variable(): string {
    return (
      (this.node.node.question.call_to_action as any)?.save_to_property || ""
    );
  }

  public set variable(variable: string) {
    (this.node.node.question.call_to_action as any).save_to_property = variable;
  }
}
