import { Component, Input } from "@angular/core";
import {
  RegistryEntry,
  RegistryEntrySource,
  RegistryEntrySourceFormatted,
  sortRegistryEntries,
  sortedRegistryEntrySources,
} from "models/registry.model";
import { TargetingRule } from "models/targeting-rule.model";
import { NgIf, NgClass, NgFor } from "@angular/common";
import {
  NzSelectComponent,
  NzOptionGroupComponent,
  NzOptionComponent,
} from "ng-zorro-antd/select";
import { FormsModule } from "@angular/forms";
import { EntitlementPipe } from "pipes/entitlement.pipe";

@Component({
  selector: "rule-user-event-sequence",
  templateUrl: "./rule-user-event-sequence.component.html",
  styleUrls: ["./rule-user-event-sequence.component.scss"],
  imports: [
    NgIf,
    NzSelectComponent,
    NgClass,
    FormsModule,
    NgFor,
    NzOptionGroupComponent,
    NzOptionComponent,
    EntitlementPipe,
  ],
})
export class TargetingRuleUserEventSequenceComponent {
  @Input() public rule: TargetingRule = null;
  @Input() public registryEntriesEvent: RegistryEntry[];
  @Input() public invalid: boolean = false;
  @Input() public disabled: boolean = false;

  @Input() public eventIds: string;

  public sortedRegistryEntrySources = sortedRegistryEntrySources;
  public registryEntrySourceFormatted = RegistryEntrySourceFormatted;

  constructor() {}

  public getEventNamesBySource(source: RegistryEntrySource): RegistryEntry[] {
    return (
      this.registryEntriesEvent
        // .filter((prop) => !prop.hidden)
        .filter((prop) => {
          return prop.sources.includes(source);
        })
        .sort(sortRegistryEntries)
    );
  }

  public isValid() {
    return this.rule.value.v_s_arr.length >= 1;
  }
}
