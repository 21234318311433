import { SquareIconType } from "components/utils/square-icon/square-icon.component";
import { Serializable } from "models/serializable.model";
import { arrayToKV, arrayToMap } from "utils/array";
import { asciiSum } from "utils/string";
import {
  RegistryEntry,
  RegistryEntrySource,
  RegistryEntryType,
  getRegistryEntryPath,
} from "./registry.model";
import {
  UserProperty,
  UserPropertyWithDescription,
} from "./user-properties.model";

export type UserNormalizedPropertyValue =
  | { type: "string"; value: string }
  | { type: "time"; value: Date }
  | { type: "number"; value: number }
  | { type: "boolean"; value: boolean }
  | { type: "object"; value: null }
  | { type: "unknown"; value: undefined };

export type UserNormalizedPropertyValueType =
  UserNormalizedPropertyValue["value"];
export type UserNormalizedPropertyType = UserNormalizedPropertyValue["type"];

export type UserNormalizedProperty = {
  key: string;
  icon: SquareIconType;
  sources: RegistryEntrySource[];
  count?: number;
} & UserNormalizedPropertyValue;

export const USER_PROPERTY_EMAIL = "email";
export const USER_PROPERTY_NAME = "name";
export const USER_PROPERTY_USERNAME = "username";
export const USER_PROPERTY_FIRST_NAME = "first_name";
export const USER_PROPERTY_LAST_NAME = "last_name";
export const USER_PROPERTY_FIRSTNAME = "firstname";
export const USER_PROPERTY_LASTNAME = "lastname";

export class User extends Serializable {
  constructor(
    public id?: string,
    public org_id?: string,

    public last_activity_at?: Date | null,

    public created_at?: Date,
    public updated_at?: Date,

    // join
    public assigned_group_ids?: string[],
    public aliases?: string[],
    public is_anonymous?: boolean | null,
    public properties: Record<string, any>[] = [],
    public group_ids: string[] = [],
  ) {
    super();
  }

  public fromJson(json: object) {
    super.fromJson(json);
    return this;
  }

  public getAvatarURL(): string {
    return this.properties?.["avatar"];
  }
}

export const animalIcons = [
  "assets/icons/users/animals/SVG/Group 1.svg",
  "assets/icons/users/animals/SVG/Group 1-1.svg",
  "assets/icons/users/animals/SVG/Group 1-2.svg",
  "assets/icons/users/animals/SVG/Group 1-3.svg",
  "assets/icons/users/animals/SVG/Group 1-4.svg",
  "assets/icons/users/animals/SVG/Group 1-5.svg",
  "assets/icons/users/animals/SVG/Group 1-6.svg",
  "assets/icons/users/animals/SVG/Group 1-7.svg",
  "assets/icons/users/animals/SVG/Group 2.svg",
  "assets/icons/users/animals/SVG/Group 2-1.svg",
  "assets/icons/users/animals/SVG/Group 2-2.svg",
  "assets/icons/users/animals/SVG/Group 2-3.svg",
  "assets/icons/users/animals/SVG/Group 2-4.svg",
  "assets/icons/users/animals/SVG/Group 2-5.svg",
  "assets/icons/users/animals/SVG/Group 2-6.svg",
  "assets/icons/users/animals/SVG/Group 2-7.svg",
  "assets/icons/users/animals/SVG/Group 3.svg",
  "assets/icons/users/animals/SVG/Group 3-1.svg",
  "assets/icons/users/animals/SVG/Group 3-2.svg",
  "assets/icons/users/animals/SVG/Group 3-3.svg",
  "assets/icons/users/animals/SVG/Group 3-4.svg",
  "assets/icons/users/animals/SVG/Group 3-5.svg",
  "assets/icons/users/animals/SVG/Group 3-6.svg",
  "assets/icons/users/animals/SVG/Group 3-7.svg",
  "assets/icons/users/animals/SVG/Group 4.svg",
  "assets/icons/users/animals/SVG/Group 4-1.svg",
  "assets/icons/users/animals/SVG/Group 4-2.svg",
  "assets/icons/users/animals/SVG/Group 4-3.svg",
  "assets/icons/users/animals/SVG/Group 4-4.svg",
  "assets/icons/users/animals/SVG/Group 4-5.svg",
  "assets/icons/users/animals/SVG/Group 4-6.svg",
  "assets/icons/users/animals/SVG/Group 4-7.svg",
  "assets/icons/users/animals/SVG/Group 5.svg",
  "assets/icons/users/animals/SVG/Group 5-1.svg",
  "assets/icons/users/animals/SVG/Group 5-2.svg",
  "assets/icons/users/animals/SVG/Group 5-3.svg",
  "assets/icons/users/animals/SVG/Group 5-4.svg",
  "assets/icons/users/animals/SVG/Group 5-5.svg",
  "assets/icons/users/animals/SVG/Group 5-6.svg",
  "assets/icons/users/animals/SVG/Group 5-7.svg",
  "assets/icons/users/animals/SVG/Group 8.svg",
  "assets/icons/users/animals/SVG/Group 9.svg",
  "assets/icons/users/animals/SVG/Group 10.svg",
  "assets/icons/users/animals/SVG/Group 11.svg",
  "assets/icons/users/animals/SVG/Group 12.svg",
  "assets/icons/users/animals/SVG/Group 13.svg",
  "assets/icons/users/animals/SVG/Group 15.svg",
  "assets/icons/users/animals/SVG/Group 16.svg",
];

export const getUserIcon = function (
  userId: string | null,
  avatarURL: string | null,
): string {
  return (
    avatarURL ?? animalIcons[asciiSum(userId || "default") % animalIcons.length]
  );
};

export const getUserMainAlias = (
  userId: string,
  aliases: string[],
): string | null => {
  return aliases.find((alias: string) => alias !== userId);
};

export function getNameByCascadingProperties({
  firstName,
  lastName,
  name,
  username,
  email,
  alias,
  id,
}: {
  firstName?: UserNormalizedPropertyValueType;
  lastName?: UserNormalizedPropertyValueType;
  name?: UserNormalizedPropertyValueType;
  username?: UserNormalizedPropertyValueType;
  email?: UserNormalizedPropertyValueType;
  alias?: string;
  id: string;
}): string {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  if (name) {
    return String(name);
  }

  if (username) {
    return String(username);
  }

  if (email) {
    return String(email);
  }

  if (firstName) {
    return String(firstName);
  }

  if (lastName) {
    return String(lastName);
  }

  if (alias) {
    return alias;
  }

  return id;
}

const getProperty = (
  properties: UserNormalizedProperty[],
  propertyKey: string,
): UserNormalizedPropertyValue["value"] =>
  properties
    .find(({ key }) => key.toLowerCase() === propertyKey.toLowerCase())
    ?.value.toString();

// TODO: this is really messy, refacto this with a proper API and maybe merge some types between analytics and this
export const getFormattedUserNameOrIDByNormalizedProperty = (
  id: string,
  aliases: string[],
  properties: UserNormalizedProperty[],
) => {
  const firstName =
    getProperty(properties, USER_PROPERTY_FIRST_NAME) ??
    getProperty(properties, USER_PROPERTY_FIRSTNAME);
  const lastName =
    getProperty(properties, USER_PROPERTY_LAST_NAME) ??
    getProperty(properties, USER_PROPERTY_LASTNAME);
  const name = getProperty(properties, USER_PROPERTY_NAME);
  const username = getProperty(properties, USER_PROPERTY_USERNAME);
  const email = getProperty(properties, USER_PROPERTY_EMAIL);
  const mainAlias = getUserMainAlias(id, aliases);

  return getNameByCascadingProperties({
    firstName,
    lastName,
    name,
    username,
    email,
    alias: mainAlias,
    id,
  });
};

const getIcon = (
  propertyKey: string,
  type: UserNormalizedPropertyType,
): SquareIconType => {
  switch (propertyKey) {
    case USER_PROPERTY_FIRST_NAME:
    case USER_PROPERTY_LAST_NAME:
    case USER_PROPERTY_NAME:
      return "identification";
    case USER_PROPERTY_EMAIL:
      return "at-symbol";
    default:
  }

  switch (type) {
    case "time":
      return "calendar";
    default:
      return "tag";
  }
};

const getPropertyType = ({
  value_string,
  value_date,
  value_numeric,
  value_bool,
}: UserProperty): UserNormalizedPropertyValue => {
  if (value_string !== null && value_string !== undefined) {
    return {
      type: "string",
      value: value_string,
    };
  }
  if (value_date !== null && value_date !== undefined) {
    return {
      type: "time",
      value: value_date,
    };
  }
  if (value_numeric !== null && value_numeric !== undefined) {
    return {
      type: "number",
      value: value_numeric,
    };
  }
  if (value_bool !== null && value_bool !== undefined) {
    return {
      type: "boolean",
      value: value_bool,
    };
  }
  return {
    type: "unknown",
    value: undefined,
  };
};

const getPropertyValueAndType = (
  value: any,
  type: RegistryEntryType,
): UserNormalizedPropertyValue => {
  switch (type) {
    case "string":
      return {
        type: "string",
        value,
      };
    case "time":
      return {
        type: "time",
        value: new Date(value),
      };
    case "number":
      return {
        type: "number",
        value: parseFloat(value),
      };
    case "bool":
      return {
        type: "boolean",
        value: Boolean(value),
      };
    case "object":
      return {
        type: "object",
        value: null,
      };
  }

  return {
    type: "unknown",
    value: undefined,
  };
};

// like below, but input is different
const getNormalizedProperty =
  (registryEntriesById: Map<string, RegistryEntry>) =>
  (property: UserProperty): UserNormalizedProperty => {
    let propertyType = getPropertyType(property); // @TODO: use registryEntry.type instead ?
    const registryEntry = registryEntriesById.get(property.key_id);
    const icon = getIcon(registryEntry?.slug, propertyType.type); // @TODO: should use the path instead of slug, since "avatar" might be a nested property

    if (!registryEntry) {
      return {
        ...propertyType,
        key: "<unknown>",
        icon,
        sources: property?.source ? [property.source] : registryEntry?.sources,
        count: property.count,
      };
    }

    if (propertyType.type === "unknown" && registryEntry.type === "object") {
      propertyType = { type: "object", value: undefined };
    }

    return {
      ...propertyType,
      key: getRegistryEntryPath(registryEntry, registryEntriesById)?.join("."),
      icon,
      sources: property?.source ? [property.source] : registryEntry.sources,
      count: property.count,
    };
  };

// like above, but input is different
const getNormalizedPropertyWithDescription =
  (registryEntriesById: Map<string, RegistryEntry>) =>
  (property: UserPropertyWithDescription): UserNormalizedProperty => {
    let propertyType = getPropertyValueAndType(property.value, property.type); // @TODO: use registryEntry.type instead ?
    const registryEntry = registryEntriesById.get(property.registry_id);
    const icon = getIcon(registryEntry?.slug, propertyType.type); // @TODO: should use the path instead of slug, since "avatar" might be a nested property

    if (!registryEntry) {
      return {
        ...propertyType,
        key: "<unknown>",
        icon,
        sources: property?.source ? [property.source] : registryEntry?.sources,
        count: property.count,
      };
    }

    if (propertyType.type === "unknown" && registryEntry.type === "object") {
      propertyType = { type: "object", value: undefined };
    }

    return {
      ...propertyType,
      key: getRegistryEntryPath(registryEntry, registryEntriesById)?.join("."),
      icon,
      sources: property?.source ? [property.source] : registryEntry.sources,
      count: property.count,
    };
  };

// like below, but input is different
export const aggregateUserProperties = (
  registryEntries: Map<string, RegistryEntry>,
  userProperties: UserProperty[],
): UserNormalizedProperty[] => {
  return userProperties.map(getNormalizedProperty(registryEntries));
};

// like above, but input is different
export const aggregateUserPropertiesWithDescription = (
  registryEntries: Map<string, RegistryEntry>,
  userProperties: UserPropertyWithDescription[],
): UserNormalizedProperty[] => {
  return userProperties.map(
    getNormalizedPropertyWithDescription(registryEntries),
  );
};

// Properties has been flatten. So we have to check if parent are not null.
export const removePropertiesHavingNullParent = (
  registryEntries: Map<string, RegistryEntry>,
  userProperties: UserPropertyWithDescription[],
): UserPropertyWithDescription[] => {
  const registryEntryIdToParentId = arrayToKV(
    Array.from(registryEntries.values()),
    "id",
    "parent_id",
  );
  const userPropertiesByRegistryId = arrayToMap(userProperties, "registry_id");

  return userProperties.filter((item) => {
    while (item) {
      const parentId = registryEntryIdToParentId.get(item.registry_id);
      if (!registryEntries.get(parentId)) {
        // it means ot was the top level property
        return true;
      }

      item = userPropertiesByRegistryId.get(parentId);
    }

    // it means a parent property is null
    return false;
  });
};
