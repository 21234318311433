import { Location, NgIf, NgFor } from "@angular/common";
import { Component } from "@angular/core";
import { Router, RouterLinkActive, RouterLink } from "@angular/router";

import { Post101 } from "components/utils/card-101-link/card-101-link.model";
import { NotificationHelper } from "helpers/notification.helper";
import { AuthService, SessionService } from "services/auth.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { UIService } from "services/ui.service";
import {
  NzMenuDirective,
  NzMenuItemComponent,
  NzSubMenuComponent,
} from "ng-zorro-antd/menu";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { ScreebIconComponent } from "../../../utils/screeb-icon/screeb-icon.component";
import { PermissionPipe } from "pipes/permission.pipe";
import { EntitlementPipe } from "pipes/entitlement.pipe";
import { FeaturePipe } from "pipes/feature.pipe";

const getParams = (url: string) =>
  Object.fromEntries(
    // @ts-ignore (fucked up native types, searchParams should be typed as iterator)
    new URL(`a:/${url}`).searchParams,
  );

@Component({
  selector: "layout-back-sidebar-stats",
  templateUrl: "./sidebar-stats.component.html",
  styleUrls: ["./sidebar-stats.component.scss"],
  imports: [
    NzMenuDirective,
    NzMenuItemComponent,
    ɵNzTransitionPatchDirective,
    RouterLinkActive,
    RouterLink,
    ScreebIconComponent,
    NgIf,
    NzSubMenuComponent,
    NgFor,
    PermissionPipe,
    EntitlementPipe,
    FeaturePipe,
  ],
})
export class LayoutBackSidebarStatsComponent {
  public searchParams: { [key: string]: string };

  public posts101: Post101[] = [
    {
      color: "var(--screeb-color-primary-outline)",
      title:
        "The longest feedback are often the most valuable ones. <b>Read your longest feedback here!</b>",
      url: null,
    },
    {
      color: "var(--screeb-color-info-outline)",
      title:
        "Discover how to <b>connect Screeb to Productboard</b> to act more easily on your feedback.",
      url: "https://help.screeb.app/en/articles/5341915-how-to-connect-screeb-to-productboard",
    },
    {
      color: "var(--screeb-color-success-outline)",
      title:
        "How To Get A Great Response Rate For Your In-App Surveys - The Complete Guide",
      url: "https://screeb.app/blog/how-to-get-a-great-response-rate-for-your-in-app-surveys-the-complete-guide/",
    },
    // {
    //   color: "var(--screeb-color-warning-outline)",
    //   title:
    //     "How to <b>use segments to identify insightful feedback?</b> (Video)",
    //   url: "https://help.screeb.app/en/articles/5385254-how-to-use-segments-to-identify-insightful-feedback",
    // },
  ];

  constructor(
    public router: Router,
    public location: Location,
    public authService: AuthService,
    public sessionService: SessionService,
    public notificationHelper: NotificationHelper,
    public uiService: UIService,
    public featureFlaggingService: FeatureFlaggingService,
  ) {
    // Keep query params through every reports
    this.searchParams = getParams(this.location.path());
    this.location.onUrlChange((url) => (this.searchParams = getParams(url)));
    this.posts101[0].url = `/org/${this.uiService.currentOrgId}/survey/${this.uiService.currentSurveyId}/stats/content-analysis`;
  }
}
