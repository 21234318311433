import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";

import { PageComponentInterface } from "components/PageComponentInterface";

import { Org } from "models/org.model";

import { AnalyticsFilterService } from "services/analytics-filters.service";
import { FeatureFlaggingService } from "services/feature-flagging.service";
import { PermissionsService } from "services/permissions.service";
import { RoutingService } from "services/routing.service";
import { LanguageWithEmoji, UIService } from "services/ui.service";
import { RegistryEntry } from "models/registry.model";
import { NgIf } from "@angular/common";
import { BadgeComponent } from "../../../utils/badge/badge.component";
import { ScreebIconComponent } from "../../utils/screeb-icon/screeb-icon.component";
import { NzButtonComponent } from "ng-zorro-antd/button";
import { NzWaveDirective } from "ng-zorro-antd/core/wave";
import { ɵNzTransitionPatchDirective } from "ng-zorro-antd/core/transition-patch";
import { UserRecordsComponent } from "../../user/common/user-records/user-records.component";
import { EntitlementPipe } from "pipes/entitlement.pipe";

@Component({
  selector: "user-record-list-page",
  templateUrl: "./user-record-list.component.html",
  styleUrls: ["./user-record-list.component.scss"],
  imports: [
    NgIf,
    BadgeComponent,
    ScreebIconComponent,
    NzButtonComponent,
    NzWaveDirective,
    ɵNzTransitionPatchDirective,
    RouterLink,
    UserRecordsComponent,
    EntitlementPipe,
  ],
})
export class UserRecordListPageComponent
  implements PageComponentInterface, OnInit, OnDestroy
{
  public title = "Sessions replay";
  public name = "Sessions replay";

  private obs: any = null;
  public org: Org;
  public languages: LanguageWithEmoji[] = [];
  public registryEntriesIdentityProperty: RegistryEntry[] = [];
  public registryEntriesGroup: RegistryEntry[] = [];
  public registryEntriesEvent: RegistryEntry[] = [];

  public keyword = "";
  public searching = false;
  public loading = true;
  public isUsersLoading = true;

  public sessionReplayEnabled: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private routingService: RoutingService,
    public analyticsFilterService: AnalyticsFilterService,
    public featureFlaggingService: FeatureFlaggingService,
    public permissionsService: PermissionsService,
    public uiService: UIService,
  ) {}

  ngOnInit() {
    this.routingService.onPageChange(
      this.name,
      this.title,
      this.route.snapshot.data,
      true,
    );

    this.obs = this.route.data.subscribe((data) => {
      this.org = data.org;
      this.registryEntriesGroup = data.registryEntriesGroup.groups;
      this.languages =
        this.uiService.languagesAndCountries.surveyLanguagesWithEmojis;
      this.registryEntriesIdentityProperty =
        data.registryEntriesIdentityProperty.filter(
          (entry: RegistryEntry) => entry.type !== "object",
        );
      this.registryEntriesEvent = data.registryEntriesEvent;

      this.sessionReplayEnabled =
        this.org.settings?.session_replay_enabled &&
        this.uiService.currentSuperOrg.entitlements.session_recording_enabled;
    });
  }

  ngOnDestroy() {
    if (this.obs) {
      this.obs.unsubscribe();
    }
  }

  public joinWaitingList() {
    // @ts-ignore
    window.$screeb("survey.start", "d5f95caa-9099-4a58-a997-ebd11e23b28a");
  }
}
