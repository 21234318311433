<label for="message-{{ uniqHash }}-textarea" class="lateral-panel-subtitle">
  <div class="left">
    {{ nextQuestionPickerEnabled ? "Message" : "Question" }}
    <span
      class="help"
      nz-popover
      [nzPopoverContent]="variablesHelpTemplate"
      nzPopoverPlacement="leftTop"
    >
      <i nz-icon nzType="question-circle" nzTheme="twotone"></i>
    </span>
  </div>

  <div class="right">
    <language-select
      class="language-select"
      name="defaultLanguage"
      ngDefaultControl
      [(ngModel)]="language"
      [languages]="builderStore.availableLanguages"
      [defaultLanguage]="builderStore.survey.scenario.default_language"
      (ngModelChange)="languageChange.emit($event)"
      (click)="$event.preventDefault(); $event.stopPropagation()"
      [showInfo]="true"
    ></language-select>
    <button
      id="question-suggestion-gpt3-cta"
      nz-button
      title="Get question suggestions"
      nzType="primary"
      (click)="suggest.emit()"
    >
      ✨
    </button>
  </div>

  <ng-template #variablesHelpTemplate>
    <div>
      <p>Make your questions more engaging by:</p>
      <ul>
        <li>
          <a
            href="https://help.screeb.app/en/articles/4998477-how-to-use-variables-in-my-surveys"
            target="_blank"
            rel="noopener"
          >
            <i nz-icon nzType="link"></i>
            user properties personalization (firstname, account information...)
          </a>
        </li>
        <li>
          <a
            href="https://help.screeb.app/en/articles/4998628-how-to-use-formatting-in-your-questions"
            target="_blank"
            rel="noopener"
          >
            <i nz-icon nzType="link"></i>
            text formatting (bold, italic...)
          </a>
        </li>
        <li>
          <a
            href="https://help.screeb.app/en/articles/4998628-how-to-use-formatting-in-your-questions"
            target="_blank"
            rel="noopener"
          >
            <i nz-icon nzType="link"></i>
            links
          </a>
        </li>
      </ul>
    </div>
  </ng-template>
</label>

<markdown-editor
  id="message-{{ uniqHash }}-textarea"
  name="message-{{ uniqHash }}-textarea"
  [variables]="variables"
  [placeholder]="getMessagePlaceholder()"
  [ngClass]="{ invalid: textErrors.length > 0 }"
  [value]="text"
  [uploadFile]="uploadFile"
  (change)="onTextChange($event)"
>
  <div slot="render">
    <sanitized-message-component
      [content]="text"
      [options]="{
        links: true,
        styling: true,
        emojiTranscoder: true,
        CR: true,
        files: true,
        baseFileUrl: baseFileUrl,
      }"
    ></sanitized-message-component>
  </div>
</markdown-editor>

<div
  class="length-indicator"
  [ngClass]="{ 'max-length-error': !getLength(text) || getLength(text) > 250 }"
>
  {{ getLength(text) }}/250
</div>
<utils-form-error [errors]="textErrors"></utils-form-error>

<ng-container *ngIf="nextQuestionPickerEnabled">
  <label class="lateral-panel-subtitle">Next question</label>
  <lateral-panel-step-picker
    [routing]="node.node.routing"
    [chainableNodeIds]="node.chainableNodeIds"
  ></lateral-panel-step-picker>
</ng-container>
