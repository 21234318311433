<div nz-row class="page-container">
  <div nz-col [nzXs]="24" [nzMd]="12" class="column-container">
    <div class="inner-left">
      <div class="welcome-header">
        <img src="/assets/logo-purple-500.svg" alt="Screeb logo" />
        <ng-container *ngIf="org && org.logo">
          <span>x</span>
          <img [src]="org.logo" [alt]="org.name" />
        </ng-container>
      </div>

      <div class="hold-on-container">
        <h1 class="main-title">
          <span>Hold tight 🚀</span>
        </h1>
        <div class="main-description no-margin">
          <span>We're setting everything up for in</span>
          <span class="emphasis">&nbsp;Screeb</span>
        </div>
        <p class="alt-description">We should be ready in a few seconds...</p>
        <span
          nz-icon
          nzType="loading"
          class="circle-loader"
          nzTheme="outline"
        ></span>
      </div>
    </div>
  </div>

  <div nz-col [nzXs]="24" [nzMd]="12" class="column-container column-right">
    <img
      class="hold-tight-img"
      src="/assets/illustrations/setting-up.png"
      alt="hold-tight"
    />
  </div>
</div>
